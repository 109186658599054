import { useTranslate } from '@fe-monorepo/hooks';
import { OrderQueryResponse, ShopProductsModel, ShopWishlistInput, WishlistData, WishlistMutationResponse } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import { t } from 'i18next';
import { ReactNode, forwardRef } from 'react';
import { useSelector } from 'react-redux';

import ProductListWithTitle from '../../../ProductDetails/ProductListWithTitle';

interface RecommendationProps {
  related_products?: ShopProductsModel[];
  more_products?: ShopProductsModel[];
  wishlistData: WishlistData[] | undefined;
  checkIsSuccess?: (errorMessage: string) => void;
  addToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  removeToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  getAllWishlist: (getAllWishlistInput: { last_cursor: string; direction: string }) => Promise<OrderQueryResponse | undefined>;
}

const RecommendationSection = forwardRef<HTMLDivElement, RecommendationProps>((props, similarProductsRef) => {
  const { translate } = useTranslate();
  const titleSimilarProducts: ReactNode = (
    <p className="text-secondary text-title sm:text-bigTitle 4K:text-huge 8K:text-fourKTitle">{translate('product_similar_products')}</p>
  );

  const titleMoreFromStc: ReactNode = (
    <div className="items-center gap-12 sm:flex 4K:gap-40 8K:gap-72">
      <p className="text-secondary text-title sm:text-bigTitle 4K:text-huge 8K:text-fourKTitle">{translate('shop_more_from')}</p>
      <div className="flex items-center justify-center gap-12 4K:gap-40 8K:gap-72">
        <p className="text-sunset text-title sm:text-bigTitle 4K:text-huge 8K:text-fourKTitle">{t('pageTitle_stcPlay')}</p>
      </div>
    </div>
  );

  const language = useSelector((state: RootState) => state.app.language);

  return (
    <section>
      {props.related_products && props.related_products.length > 0 && (
        <ProductListWithTitle
          ref={similarProductsRef}
          listOfProducts={props.related_products}
          title={titleSimilarProducts}
          language={language}
          isRecommendation={true}
          checkIsSuccess={props.checkIsSuccess}
          additionalStyles="pt-[5rem]"
          wishlistData={props.wishlistData}
          addToWishList={props.addToWishList}
          removeToWishList={props.removeToWishList}
          getAllWishlist={props.getAllWishlist}
        />
      )}

      {props.more_products && props.more_products.length > 0 && (
        <ProductListWithTitle
          listOfProducts={props.more_products}
          title={titleMoreFromStc}
          language={language}
          isRecommendation={true}
          checkIsSuccess={props.checkIsSuccess}
          additionalStyles="pt-[5rem]"
          wishlistData={props.wishlistData}
          addToWishList={props.addToWishList}
          removeToWishList={props.removeToWishList}
          getAllWishlist={props.getAllWishlist}
        />
      )}
    </section>
  );
});

export default RecommendationSection;
