import { ModerationStatuses } from '@fe-monorepo/helper';

import LazyImage from '../Media/LazyImage';

interface ThumbnailProps {
  status?: string;
  url?: string;
  children?: JSX.Element;
}

const BitThumbnail = (props: ThumbnailProps) => {
  switch (props?.status) {
    case ModerationStatuses.reject:
    case ModerationStatuses.inactive:
      return <div className="relative w-full h-full aspect-[9/16] rounded-md bg-black">{props?.children}</div>;
    case ModerationStatuses.pending:
    default:
      return (
        <LazyImage img={props?.url} imgStyle="w-full h-full aspect-[9/16] object-cover object-center rounded-md" divStyle="relative">
          {props?.children}
        </LazyImage>
      );
  }
};

export default BitThumbnail;
