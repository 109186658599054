import { IconNames } from '@fe-monorepo/helper';
import { RootState } from '@fe-monorepo/store';
import { getStyle } from '@fe-monorepo/themes';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import { isSafari } from 'react-device-detect';
import { useDropzone } from 'react-dropzone';
import { useSelector } from 'react-redux';

interface FileInputProps {
  type?: 'image';
  label?: ReactNode;
  showFileName?: boolean;
  imgContainerStyle?: string;
  imgStyle?: string;
  uploadContainerStyle?: string;
  displayContainerStyle?: string;
  resetButtonStyle?: string;
  iconStyle?: string;
  labelClassName?: string;
  buttonLabel?: ReactNode;
  resetIcon?: IconNames;
  setValue?: (file: any) => void;
  setReset?: (reset: boolean, id: string) => void;
  initFile?: any;
  fileId?: string;
}

const FileInput = ({
  type,
  label,
  buttonLabel,
  setValue,
  labelClassName,
  imgContainerStyle,
  imgStyle,
  uploadContainerStyle,
  displayContainerStyle,
  resetButtonStyle,
  iconStyle,
  showFileName = true,
  resetIcon,
  setReset,
  initFile,
  fileId,
}: FileInputProps) => {
  const themes = useSelector((state: RootState) => state.app.themes);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [image, setImage] = useState<string | ArrayBuffer | null | undefined>();
  const [file, setFile] = useState<File>();
  useEffect(() => {
    if (initFile) {
      if (typeof initFile !== 'string' && typeof initFile !== 'undefined') {
        const reader = new FileReader();
        reader.onload = event => {
          setImage(event?.target?.result);
        };
        reader.readAsDataURL(initFile);
        reader.onload = function () {
          setImage(reader.result);
        };
      } else {
        setImage(initFile);
      }
    } else {
      setImage(null);
    }
  }, [fileId, initFile]);

  const onDrop = useCallback((acceptedFiles: Array<File>) => {
    const file = new FileReader();

    file.readAsDataURL(acceptedFiles[0]);
    setFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: type === 'image' ? { 'image/jpeg': [], 'image/png': [] } : undefined,
  });

  const onReset = useCallback(() => {
    const current = fileInputRef.current;
    if (current) {
      current.value = '';
      setImage(null);
      setFile(undefined);
    }
    setReset && fileId && setReset(true, fileId);
  }, [fileInputRef, initFile]);

  useEffect(() => {
    setValue && setValue(file);
  }, [file]);

  return (
    <div className="relative">
      {image && (
        <div className={`${displayContainerStyle ?? ''} flex gap-[1.5rem] items-center`}>
          {/* Added an onClick event here that will allow the users to edit the photos */}
          {type === 'image' && (
            <Image
              onClick={() => fileInputRef.current && fileInputRef.current.click()}
              img={image}
              imgStyle={`${imgStyle ?? ''} w-full h-full`}
              divStyle={`${imgContainerStyle ?? ''} w-full h-full max-w-[6.75rem] max-h-[4rem] 
                object-cover overflow-hidden cursor-pointer`}
            />
          )}
          {showFileName && (
            <span style={{ color: getStyle(themes).textColor }} className="flex-1 font-regular text-fs-body-small text-left rtl:text-right">
              {file?.name}
            </span>
          )}
          <button className={resetButtonStyle ?? ''} type="button" onClick={onReset}>
            <ResponsiveIcon
              name={resetIcon ? resetIcon : IconNames.deleteOutline}
              baseWidth={20}
              baseHeight={20}
              iconClasses={`${iconStyle ?? ''} fill-sunset`}
            />
          </button>
        </div>
      )}
      <div
        className={`${uploadContainerStyle ?? ''} flex flex-col w-full items-center gap-[0.5rem] py-[1.5rem] rounded-[0.25rem] relative ${
          image ? 'hidden' : ''
        }`}
        {...getRootProps()}
      >
        {label && <span className={`${labelClassName ?? ''} font-regular text-fs-body-small text-zinc-600`}>{label}</span>}
        <input
          {...getInputProps()}
          multiple={false}
          type="file"
          accept={type === 'image' ? 'image/*' : undefined}
          ref={fileInputRef}
          onChange={e => {
            const files = e.target.files;
            if (files && files[0]) {
              let reader = new FileReader();
              reader.onload = event => setImage(event?.target?.result);
              reader.readAsDataURL(files[0]);
              setFile(files[0]);
            }
          }}
          className="absolute w-0 h-0 touch-events-none opacity-0"
        />
        <button
          onClick={() => isSafari && fileInputRef.current && fileInputRef.current?.click()}
          type="button"
          className="font-regular text-fs-body text-sunset z-[1]"
        >
          {buttonLabel ?? 'Browse files'}
        </button>
        <div className="absolute top-0 w-full h-full z-0" onClick={() => isSafari && fileInputRef.current && fileInputRef.current?.click()}>
          <svg width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              className="[stroke-dasharray:8px] 2K:[stroke-dasharray:14px] 4K:[stroke-dasharray:21.33px] 8k:[stroke-dasharray:42.67px]"
              x="0.0313rem"
              y="0.0313rem"
              width="98%"
              height="99%"
              rx="0.2188rem"
              fill="transparent"
              stroke="#B9B9BA"
              stroke-width="0.0625rem"
              stroke-linecap="square"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default FileInput;
