import { gql } from '@apollo/client';

export const STREAMS_QUERY = {
  getStreams: gql`
    query GetStreams {
      GetStreams {
        is_successful
        error_code
        error_msg
        app_code
        response {
          channel_id
          sort_id
          name_ar
          name_en
          url_gen_ar
          url_ios_ar
          url_and_ar
          url_gen_en
          url_ios_en
          url_and_en
          img_web_ar
          img_app_ar
          img_web_en
          img_app_en
        }
      }
    }
  `,
};
