import React from "react";

function DownArrowGray() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <rect x="0.5" y="0.5" width="35" height="35" rx="1.5" stroke="#454547" />
      <path
        d="M18 23.0624L9 14.0624L11.1 11.9624L18 18.8624L24.9 11.9624L27 14.0624L18 23.0624Z"
        fill="#8B8A8C"
      />
    </svg>
  );
}

export default DownArrowGray;
