import React from "react";

function VideoViewsIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M6 14.8457V4.3457L14.25 9.5957L6 14.8457ZM7.5 12.1082L11.4375 9.5957L7.5 7.0832V12.1082Z"
        fill="white"
      />
    </svg>
  );
}

export default VideoViewsIcon;
