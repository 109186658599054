import { gql } from '@apollo/client';

export const START_MUTATION = {
  startTournament: gql`
    mutation startTournament($details: StartInput) {
      startTournament(details: $details) {
        is_successful
        error_msg
        error_code
      }
    }
  `,
};
