export const SEARCH_TABS = {
  ALL: 'all',
  PROFILES: 'profiles',
  HASHTAGS: 'hashtags',
  CHALLENGES: 'challenges',
  VIDEOS: 'videos',
};

export const EVENTS = {
  LOGIN_CLICK: 'login_click',
  VIEW_IMPRESSION: 'view_click',
  CONTENT_REPLAY: 'video_replay',
  CONTENT_PLAY: 'video_play',
  CONTENT_STOP_PLAY: 'video_Stop_Play',
  CONTENT_LIKE: 'content_like',
  CONTENT_UNLIKE: 'content_unlike',
  CONTENT_SHARE: 'content_share',
  CONTENT_PAUSE: 'video_pause',
  CONTENT_RESUME: 'video_resume',
  THUMBNAIL_CLICK: 'thumbnail_click',
  USER_FOLLOW: 'user_follow',
  USER_UNFOLLOW: 'user_unfollow',
  CONTENT_SWIPE: 'content_swipe',
};

export const CONTENT_TYPE = {
  VIDEO: 'video',
  CHALLENGE: 'challenge',
  PROFILE: 'profile',
  HASHTAG: 'hashtag',
  SHARE: 'share',
  FAVOURITE: 'favourite',
  ACTIVITY_TIMELINE: 'activity-timeline',
  ORIGINAL_VIDEO: 'original_video',
  SOUND: 'sound',
};

export const TOAST_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
};

export const STATUS = {
  VIDEO_ALREADY_REPOSTED: 'VIDEO_ALREADY_REPOSTED',
};
