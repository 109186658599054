import { IconNames } from '@fe-monorepo/helper';
import { useToastMessage, useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import ToastMessage from '@fe-web/Atoms/ToastMessage';
import useTheme from '@fe-web/hooks/useTheme';

const DisabledReturnButton = () => {
  const { translate } = useTranslate();
  const { isDarkTheme } = useTheme();
  const { errorToastMessage } = useToastMessage();

  function showErrorMsg() {
    errorToastMessage({
      message: (
        <ToastMessage
          type="error"
          icon={<ResponsiveIcon className="fill-red" name={IconNames.dangerousIcon} baseWidth={30} baseHeight={30} />}
        >
          {translate('connect.oops')}{' '}
          <p className="text-fs-body-small not-italic font-regular leading-5">{translate('toast.error.returnExpired')}</p>
        </ToastMessage>
      ),
    });
  }
  return (
    <div
      data-tooltip-id="my-tooltip"
      data-tooltip-content={translate('shop.cart.order_main.has_expired')}
      data-tooltip-place="top"
      onClick={showErrorMsg}
    >
      <div className="cursor-default mt-[0.5rem] flex items-center justify-center gap-[0.5rem] px-[1.5rem] py-[.5rem] bg-[#E8E8E8] dark:bg-white/10">
        <p className={`text-fs-body not-italic font-medium leading-6 ${isDarkTheme ? 'text-[#D1D0D1]' : 'text-[#080308]/30'}`}>
          {translate('shop.cart.returns_or_exchange.return_items_cta')}
        </p>
      </div>
    </div>
  );
};

export default DisabledReturnButton;
