import { IconNames, MINI_GAME_LINKS, MINI_GAME_USER_PROFILES } from '@fe-monorepo/helper';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Modal from '@fe-web/Templates/Modal/Modal';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useSelector } from 'react-redux';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  gameID: string;
}

const MiniGameIFrameModal = ({ isOpen, gameID, handleClose }: Props) => {
  const isMobile = useMobileDetect();
  const user = useSelector((state: RootState) => state.user.userContext);
  const selectedLanguage = useSelector((state: RootState) => state.app.language);
  const theme = useSelector((state: RootState) => state.app.themes);

  return (
    <Modal backdropClassName="bg-primary/[1]" open={isOpen}>
      <div className="SD:w-[85%] h-[85%] mt-[4rem] w-full bg-primary">
        <div className="w-full h-full inline-flex flex-row items-start gap-[1rem]">
          <div className="ltr:ml-auto rtl:mr-auto w-full h-full">
            <iframe
              allow="web-share"
              className="w-full h-full SD:rounded-[0.5rem] border-[0.0625rem] border-secondary/5"
              src={`${MINI_GAME_LINKS.miniGameBaseURL}/?lang=${selectedLanguage}&gameId=${gameID}&stcPlayId=${
                user.username || MINI_GAME_USER_PROFILES.GUEST
              }&theme=${theme}`}
            />
          </div>
          <div
            onClick={() => handleClose()}
            className="
              absolute top-0 ltr:right-0 rtl:left-0 ltr:pr-[1rem] rtl:pl-[1rem] ltr:ml-auto rtl:mr-auto pt-[1.25rem]
              SD:static SD:ltr:pr-0 SD:rtl:pl-0 cursor-pointer SD:pt-0
              dark:hover:fill-zinc-300 dark:hover:text-zinc-300 hover:fill-neutral-600 hover:text-neutral-600 
              fill-secondary/70 text-secondary/70 inline-flex flex-row items-center gap-[0.5rem]
              text-nowrap"
          >
            {!isMobile && <p className="font-regular text-fs-body">Quit game</p>}
            <ResponsiveIcon name={IconNames.close} baseHeight={14} baseWidth={14} />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default MiniGameIFrameModal;
