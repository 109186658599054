import { BrazeCustomEvents, IconNames, MixPanelEvents } from '@fe-monorepo/helper';
import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { OrderQueryResponse, ShopWishlistInput, WishlistMutationResponse } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { AnimatePresence, motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';

const AnimatedResponsiveIcon = motion(ResponsiveIcon);

interface WishListInput {
  isWishlist?: boolean;
  product_code: string;
  className?: string;
  checkIsSuccess?: (errorMessage: string, value: boolean) => void;
  addToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  removeToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  getAllWishlist: (getAllWishlistInput: { last_cursor: string; direction: string }) => Promise<OrderQueryResponse | undefined>;
  productName: string;
  brand: string;
}

const WishList = ({
  isWishlist,
  product_code,
  className,
  checkIsSuccess,
  getAllWishlist,
  addToWishList,
  removeToWishList,
  productName,
  brand,
}: WishListInput) => {
  const [isLiked, setLiked] = useState<boolean>();
  const [isLikeAnimationDone, setLikeAnimationDone] = useState<boolean>(true);
  const { isLoggedIn } = useUserProfile();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { translate } = useTranslate();
  const isRTL = useSelector((state: RootState) => state.app.isRTL);

  useEffect(() => {
    //set the state liked, based on isWishlist props
    setLiked(isWishlist);
  }, [isWishlist]);

  const toggleLike = useCallback(
    (event: any) => {
      if (isLikeAnimationDone) {
        event.stopPropagation();
        if (!isLiked) {
          addToWishList({ product_code: product_code })
            .then(response => {
              if (response?.shopWishlistAdd?.is_successful) {
                mixpanelHelper.trackEvent(MixPanelEvents.productAddedToWishlist, {
                  'Product Name': productName,
                  'Product Code': product_code,
                  Brand: brand,
                });

                brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.ProductAddedToWishlist, {
                  'Product Name': productName,
                  'Product Code': product_code,
                  Brand: brand,
                });

                getAllWishlist({ direction: '', last_cursor: '' });
              }
            })
            .catch(error => {
              checkIsSuccess && checkIsSuccess(translate('wishlist.error_msg') ?? '', false);
            });
        } else {
          removeToWishList({ product_code: product_code })
            .then(response => {
              if (response?.shopWishlistRemove?.is_successful) {
                getAllWishlist({ direction: '', last_cursor: '' });
                checkIsSuccess && checkIsSuccess(translate('wishlist.success_msg') ?? '', true);
              } else {
                checkIsSuccess && checkIsSuccess(translate('wishlist.error_msg') ?? '', false);
              }
            })
            .catch(error => {
              checkIsSuccess && checkIsSuccess(translate('wishlist.error_msg') ?? '', false);
            });
        }
        setLiked(!isLiked);
      }
    },
    [isLiked, isLikeAnimationDone],
  );

  const logInNavigate = (event: any) => {
    event.stopPropagation();
    navigate(`${AppRoutes.authSignIn}${pathname ? `?redirect_url=${pathname}` : ''}`);
  };

  return (
    <div
      className={`absolute z-20 hover:cursor-pointer ${className ?? `${isRTL ? 'left-[.75rem]' : 'right-[.75rem]'} top-[.75rem]`}`}
      onClick={isLoggedIn ? toggleLike : logInNavigate}
    >
      <div className="relative">
        <ResponsiveIcon className="fill-sunset" name={IconNames.heartOutline1} baseHeight={30} baseWidth={30} />

        {isLiked && isLoggedIn && (
          <AnimatePresence>
            <AnimatedResponsiveIcon
              initial={{ scale: 0 }}
              animate={{ scale: [0, 1.25, 1] }}
              exit={{ scale: [1, 1.25, 0] }}
              transition={{ duration: 0.2 }}
              onAnimationStart={() => setLikeAnimationDone(false)}
              onAnimationComplete={() => setLikeAnimationDone(true)}
              className="absolute top-[.2rem] right-[.2rem] fill-sunset stroke-sunset"
              name={IconNames.heartFill}
              baseHeight={23}
              baseWidth={23}
            />
          </AnimatePresence>
        )}
      </div>
    </div>
  );
};

export default WishList;
