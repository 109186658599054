import { useTranslate } from '@fe-monorepo/hooks';

export type StatusCode =
  | 'action_required'
  | 'pending_clarification'
  | 'rejected'
  | 'return_rejected'
  | 'rejected_by_stcplay'
  | 'rejected_by_channels'
  | 'rejected_by_dal'
  | 'undergoing_inspection'
  | 'refunded'
  | 'refund_accepted'
  | 'accepted'
  | 'return_accepted'
  | 'in_transit'
  | 'return_dispatched'
  | 'submitted'
  | 'partially'
  | 'pending'
  | 'closed'
  | 'failed_screening'
  | 'shipment_created'
  | 'under_process';

type ReturnStatus = {
  return_status_code: StatusCode;
  return_status_name: string | null;
};

type Item = {
  status: StatusCode;
};

type ReturnStatusText = {
  statusName: string;
  statusCode: string;
};

// Function to get the return status of items
export function GetReturnStatus(items: Item[]): ReturnStatusText {
  // Use the translate function from the useTranslate hook
  const { translate } = useTranslate();

  // Define an array of return statuses
  const returnStatus: ReturnStatus[] = [
    // Each return status has a code and a name
    // The name is a translated string
    {
      return_status_code: 'action_required',
      return_status_name: translate('returns_action_required'),
    },
    {
      return_status_code: 'pending_clarification',
      return_status_name: translate('returns_action_required'),
    },
    {
      return_status_code: 'rejected',
      return_status_name: translate('returns_action_rejected'),
    },
    {
      return_status_code: 'rejected_by_stcplay',
      return_status_name: translate('returns_action_rejected'),
    },
    {
      return_status_code: 'rejected_by_channels',
      return_status_name: translate('returns_action_rejected'),
    },
    {
      return_status_code: 'rejected_by_dal',
      return_status_name: translate('returns_action_rejected'),
    },
    {
      return_status_code: 'return_rejected',
      return_status_name: translate('returns_action_rejected'),
    },
    {
      return_status_code: 'closed',
      return_status_name: translate('returns_action_rejected'),
    },
    {
      return_status_code: 'undergoing_inspection',
      return_status_name: translate('returns_action_under_process'),
    },
    {
      return_status_code: 'under_process',
      return_status_name: translate('returns_action_under_process'),
    },
    {
      return_status_code: 'refunded',
      return_status_name: translate('returns_action_refunded'),
    },
    {
      return_status_code: 'refund_accepted',
      return_status_name: translate('returns_action_refunded'),
    },
    {
      return_status_code: 'accepted',
      return_status_name: translate('returns_action_approved'),
    },
    {
      return_status_code: 'return_accepted',
      return_status_name: translate('returns_action_approved'),
    },
    {
      return_status_code: 'in_transit',
      return_status_name: translate('returns_action_in_transit'),
    },
    {
      return_status_code: 'return_dispatched',
      return_status_name: translate('returns_action_in_transit'),
    },
    {
      return_status_code: 'submitted',
      return_status_name: translate('returns_action_in_submitted'),
    },
    {
      return_status_code: 'shipment_created',
      return_status_name: translate('returns_action_in_submitted'),
    },
    {
      return_status_code: 'pending',
      return_status_name: translate('returns_action_in_pending'),
    },
  ];

  // Create an array named 'partially'
  const partially = [
    {
      // Each object in the array has a property 'partially_name'
      // The value of 'partially_name' is a translated string
      partially_name: translate('returns_action_in_partially'),
    },
  ];

  // Use the reduce function to count the number of items with each status
  const statusCounts = items.reduce((counts, item) => {
    // By default, use the item's status as the key
    let status_key = item.status;

    // If the item's status is one of the "rejected" statuses, use 'rejected' as the key
    if (
      item.status === 'rejected_by_stcplay' ||
      item.status === 'rejected_by_channels' ||
      item.status === 'rejected_by_dal' ||
      item.status === 'return_rejected' ||
      item.status === 'closed' ||
      item.status === 'failed_screening'
    ) {
      status_key = 'rejected';
    }
    // If the item's status is 'action_required' or 'pending_clarification', use 'action_required' as the key
    else if (item.status === 'action_required' || item.status === 'pending_clarification') {
      status_key = 'action_required';
    }
    // If the item's status is 'accepted' or 'return_accepted', use 'accepted' as the key
    else if (item.status === 'accepted' || item.status === 'return_accepted') {
      status_key = 'accepted';
    }
    // If the item's status is 'refunded' or 'refund_accepted', use 'refunded' as the key
    else if (item.status === 'refunded' || item.status === 'refund_accepted') {
      status_key = 'refunded';
    }
    // If the item's status is 'in_transit' or 'return_dispatched', use 'in_transit' as the key
    else if (item.status === 'in_transit' || item.status === 'return_dispatched') {
      status_key = 'in_transit';
    } else if ( item.status === 'shipment_created') {
      status_key = 'submitted';
    }

    // If the key doesn't exist in the counts object yet, initialize it with 0
    // Then increment the count for the key
    counts[status_key] = (counts[status_key] || 0) + 1;

    // Return the updated counts object to be used as the accumulator in the next iteration
    return counts;
  }, {} as { [key in StatusCode]: number }); // Initialize the counts object as an empty object

  // Check if all items have the same status
  const allSameStatus = Object.keys(statusCounts).length === 1;

  // If all items have the same status
  if (allSameStatus) {
    // Find the status object in the returnStatus array that matches the status of the first item
    const status = returnStatus.find(r => r.return_status_code === items[0].status);
    // If a matching status object is found
    if (status) {
      // Return an object with the status name and status code
      return { statusName: status[`return_status_name`], statusCode: status[`return_status_code`] } as ReturnStatusText;
    }
  }
  {
    // Define an array of status codes in order of priority
    const statusPriorities: StatusCode[] = [
      'action_required',
      'rejected',
      'undergoing_inspection',
      'refunded',
      'accepted',
      'in_transit',
      'pending',
    ];

    // Initialize variables for the result and resultCode
    let result;
    let resultCode;

    // Iterate over the statusPriorities array
    for (const status of statusPriorities) {
      // If the current status exists in the statusCounts object
      if (statusCounts[status]) {
        // Find the status object in the returnStatus array that matches the current status
        const statusObj = returnStatus.find(r => r.return_status_code === status);

        // If a matching status object is found
        if (statusObj) {
          // Get the status text and the partially text
          const statusText = statusObj[`return_status_name`];
          const partiallyText = partially[0][`partially_name`];

          // If the status text exists
          if (statusText) {
            // Set the result to be the status text with the first letter capitalized, followed by the partially text
            result = `${statusText.charAt(0).toUpperCase() + statusText.slice(1)} ${partiallyText}`;

            // Set the resultCode to be the status code of the status object
            resultCode = statusObj[`return_status_code`];
          }

          // Break the loop as we've found a matching status
          break;
        }
      }
    }

    // Return an object with the status name and status code
    return { statusName: result, statusCode: resultCode } as ReturnStatusText;
  }
}


export const getReturnStatusStyles = (code: string) : string => {
  let colorClasses = 'border-moonlight/[0.4] bg-moonlight/[0.08] text-moonlight';
  switch (code) {
    //This is for green color (Accepted and Refunded)
    case "accepted":
    case "passed_screening":
    case "return_accepted":
    case "refunded":
    case "refund_accepted":
      colorClasses = 'border-[#108715]/[0.4] bg-[#108715]/[0.08] text-[#108715]';
    break;
    //This is for red color (Rejected and Action Required)
    case "rejected":
    case "rejected_by_stcplay":
    case "rejected_by_channels":
    case "rejected_by_dal":
    case "failed_screening":
    case "return_rejected":
    case "closed":
    case "pending_clarification":
    case "action_required":
      colorClasses = 'border-[#E03030]/[0.4] bg-[#E03030]/[0.08] text-[#E03030]';
    break;

  }
  return colorClasses;
}