import { IMAGE_ALT_LABELS, IconNames, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { Oval } from 'react-loader-spinner';

import FileInput from './FileInput';

interface PhotosProps {
  imgUrl?: string;
  name?: string;
  reason?: string;
  imageFiles?: any[];
  deleteFileById: (activate: boolean, product_code: string, optionIndex: number, fileId: string) => void;
  addFileToProduct: (
    reset: boolean,
    product_code: string,
    file: File,
    optionIndex: number,
    index: number,
    trackingNumber: string | undefined,
  ) => void;
  code: string;
  index: number;
  cols: number;
  showLowerBorder?: boolean;
  isLoading?: boolean;
  handleImageRender?: (fileRender: string | ArrayBuffer | null | undefined) => void;
  trackingNumber?: string;
}

const Photos = ({
  imgUrl,
  name,
  reason,
  imageFiles,
  deleteFileById,
  addFileToProduct,
  code,
  index,
  showLowerBorder,
  isLoading,
  trackingNumber,
}: PhotosProps) => {
  const { translate } = useTranslate();
  const isMobile = useMobileDetect();

  return (
    <div className="inline-flex flex-col gap-[1.5rem]">
      <div className="inline-flex flex-row gap-[1rem]">
        <div>
          <Image
            img={imgUrl}
            alt={translate(IMAGE_ALT_LABELS.return_photos_product_image as TxKeyPath) ?? ''}
            divStyle="rounded-[3px] inline-flex justify-center items-center h-[6rem] w-[6rem] bg-neutral-900-white/5"
            imgStyle=""
          />
        </div>
        <div className="mdMaxxS:justify-between pt-[0.25rem] inline-flex flex-col gap-[1rem]">
          <div className={`${isMobile ? 'ellipsis-multiline' : ''} font-medium text-fs-body-large text-secondary h-[3.25rem] SD:h-auto`}>
            {name}
          </div>
          <div className="font-regular text-fs-body text-secondary">
            <span className="font-medium">{translate('shop.cart.returns_or_exchange.reason')}: </span>
            {reason}
          </div>
        </div>
      </div>
      <div className="SD:ml-[7rem] SD:mt-[-2.25rem] inline-flex flex-col gap-[1rem] w-fit">
        <div className="grid SD:grid-cols-${cols} grid-cols-4 gap-x-[1rem] gap-y-[2rem]">
          {imageFiles?.map((fileInput, i) => {
            return (
              <div id={fileInput?.id} key={fileInput?.id || i}>
                <FileInput
                  initFile={fileInput?.file}
                  key={code + i}
                  type="image"
                  buttonLabel={<ResponsiveIcon name={IconNames.uploadImageSunset} baseHeight={24} baseWidth={24} />}
                  setValue={file => addFileToProduct(false, code, file, index, i, trackingNumber)}
                  imgContainerStyle="SD:!w-[5rem] SD:!h-[5rem] !w-full !h-[5rem] !overflow-visible"
                  imgStyle="SD:!w-[5rem] SD:!h-[5rem] !w-full !h-[5rem]"
                  uploadContainerStyle="SD:!w-[5rem] SD:!h-[5rem] !w-full !h-[4.75rem] justify-center"
                  displayContainerStyle="relative"
                  resetButtonStyle="absolute text-white top-[-0.4375rem] right-[-0.4375rem] w-[1.25rem] h-[1.25rem] bg-black z-[1]"
                  iconStyle="!fill-white"
                  showFileName={false}
                  resetIcon={IconNames.close1}
                  fileId={fileInput?.id || i}
                  setReset={(reset, id) => {
                    deleteFileById(reset, code, index, id);
                  }}
                />
              </div>
            );
          })}
        </div>
        <div className="font-regular text-fs-body-small text-zinc-600-400">{translate('shop.cart.returns_or_exchange.upload_minimum')}</div>
      </div>
      {showLowerBorder && <div className="w-full bg-secondary/10 border-t-[0.0625rem] border-secondary/10 mb-[1.5rem]" />}
      {isLoading && (
        <div className="absolute top-[40%] left-[45%] origin-[-50%_-50%] ml-auto mr-auto z-[9999]">
          <div className="modal-overlay fixed inset-0 bg-primary opacity-30"></div>
          <Oval
            height={80}
            width={80}
            color="#E95F2A"
            secondaryColor="#E95F2A"
            visible={true}
            ariaLabel="oval-loading"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </div>
  );
};

export default Photos;
