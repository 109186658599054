import { IconNames, ModerationStatuses } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';

import ResponsiveIcon from '../Icon/ResponsiveIcon';

interface ModerationProps {
  status?: string;
  className?: string;
}
const ModerationStatus = (props: ModerationProps) => {
  const { translate } = useTranslate();
  const { className = '', status } = props;

  switch (status) {
    case ModerationStatuses.pending:
    case ModerationStatuses.pending1:
      return (
        <div className={`absolute flex items-center justify-center w-full h-full text-white ${className}`}>
          <div className="flex flex-col items-center">
            <ResponsiveIcon
              name={IconNames.processing}
              baseHeight={24}
              baseWidth={24}
              className="flex items-center justify-center w-[3rem] h-[3rem] text-white bg-primary bg-opacity-70 rounded-full"
            />
            <div className="mt-[0.5rem]">{`${translate('profile.bits.approving')}...`}</div>
          </div>
        </div>
      );
    case ModerationStatuses.accepted:
      return (
        <div className={`absolute flex items-center justify-center w-full h-full text-white ${className}`}>
          <div className="flex flex-col items-center">
            <ResponsiveIcon
              name={IconNames.bitsApproved}
              baseHeight={24}
              baseWidth={24}
              className="flex items-center justify-center w-[3rem] h-[3rem] text-white bg-black bg-opacity-70 rounded-full"
            />
            <div className="mt-[0.5rem]">{`${translate('profile.bits.approved')}...`}</div>
          </div>
        </div>
      );
    case ModerationStatuses.reject:
    case ModerationStatuses.inactive:
    case ModerationStatuses.rejected:
      return (
        <div className={`absolute flex items-center justify-center w-full h-full text-white ${className}`}>
          <div className="flex flex-col items-center">
            <ResponsiveIcon
              name={IconNames.rejected}
              baseHeight={24}
              baseWidth={24}
              className="flex items-center justify-center w-[3rem] h-[3rem] text-white bg-primary bg-opacity-70 rounded-full"
            />
            <div className="mt-[0.5rem]">{`${translate('profile.bits.rejected')}...`}</div>
          </div>
        </div>
      );
    default:
      return null;
  }
};

export default ModerationStatus;
