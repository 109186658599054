import { useLazyQuery, useMutation } from '@apollo/client';
import { ORDER_MUTATION, WISHLIST_MUTATION, WISHLIST_QUERY } from '@fe-monorepo/data-access';
import {
  AddProductInput,
  GetAllWishlistInput,
  OrderMutationResponse,
  OrderQueryResponse,
  RemoveProductInput,
  SelectProductsInput,
  ShopWishlistInput,
  WishlistData,
  WishlistMutationResponse,
} from '@fe-monorepo/models';
import { AppDispatch, RootState } from '@fe-monorepo/store';
import { insertAllWishlist, insertSelectedWishlist, removeSelectedWishlist } from 'libs/store/src/lib/shopSlice/wishlist';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAppState } from '../useAppState/useAppState';
import { useInvoice } from './useInvoice';

const useProduct = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { changeWishlistLoadingState } = useAppState();
  const invoiceNumber = useSelector((state: RootState) => state.cart.invoice?.invoice_number);
  const { getInvoice } = useInvoice(true);
  const [isAddProductLoading, setIsAddProductLoading] = useState<boolean>(false);
  const [isRemoveProductLoading, setIsRemoveProductLoading] = useState<boolean>(false);
  const [isSelectProductLoading, setIsSelectProductLoading] = useState<boolean>(false);
  const [isWishListLoading, setIsWishListLoading] = useState<boolean>(false);
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>('');
  const [wishlistData, setWishlistData] = useState<WishlistData[] | undefined>();

  const [addProductGql, { data: AddProductData, error: AddProductError }] = useMutation<OrderMutationResponse>(ORDER_MUTATION.addProduct, {
    errorPolicy: 'all',
  });

  const [removeProductGql, { data: RemoveProductData }] = useMutation<OrderMutationResponse>(ORDER_MUTATION.removeProduct, {
    errorPolicy: 'all',
  });

  const [selectProductsGql, { data: SelectProductsData }] = useMutation<OrderMutationResponse>(ORDER_MUTATION.selectProducts, {
    errorPolicy: 'all',
  });

  const [addToWishListGql, { data: AddToWishListData }] = useMutation<WishlistMutationResponse>(WISHLIST_MUTATION.shopWishlistAdd, {
    errorPolicy: 'all',
  });

  const [removeToWishListGql, { data: RemoveToWishListData }] = useMutation<WishlistMutationResponse>(
    WISHLIST_MUTATION.shopWishlistRemove,
    {
      errorPolicy: 'all',
    },
  );

  const [getAllWishlistGql, { data: GetAllWishlistData }] = useLazyQuery<OrderQueryResponse, GetAllWishlistInput>(
    WISHLIST_QUERY.getAllWishlist,
    {
      errorPolicy: 'all',
    },
  );

  const addProduct = async (addProductInput: AddProductInput) => {
    setIsAddProductLoading(true);
    const { data } = await addProductGql({ variables: { details: addProductInput } });
    setIsAddProductLoading(false);
    return data?.addProduct;
  };

  const removeProduct = async (removeProductInput: RemoveProductInput) => {
    setIsRemoveProductLoading(true);
    const { data } = await removeProductGql({ variables: { details: removeProductInput } });
    setIsRemoveProductLoading(false);
    return data?.removeProduct;
  };

  const selectProducts = async (selectProductsInput: SelectProductsInput) => {
    setIsSelectProductLoading(true);
    const { data } = await selectProductsGql({ variables: { details: selectProductsInput } });
    setIsSelectProductLoading(false);
    return data?.selectProducts;
  };

  const addToWishList = async (shopWishlistInput: ShopWishlistInput) => {
    setIsWishListLoading(true);
    const { data } = await addToWishListGql({ variables: { details: shopWishlistInput } });
    setIsWishListLoading(false);
    return data;
  };

  const removeToWishList = async (shopWishlistInput: ShopWishlistInput) => {
    setIsWishListLoading(true);
    const { data } = await removeToWishListGql({ variables: { details: shopWishlistInput } });
    setIsWishListLoading(false);
    return data;
  };

  const getAllWishlist = async (getAllWishlistInput: GetAllWishlistInput['details']) => {
    setIsWishListLoading(true);
    const { data } = await getAllWishlistGql({ variables: { details: getAllWishlistInput } });
    setIsWishListLoading(false);
    return data;
  };

  const fetchWishlist = () => {
    getAllWishlist({ direction: '', last_cursor: '' }).then(response => {
      if (response?.getAllWishlist?.is_successful) {
        setIsSuccessful(true);
        dispatch(insertAllWishlist(response?.getAllWishlist?.data));
      } else {
        setIsSuccessful(false);
        setErrorMessage(response?.getAllWishlist?.error_msg);
      }
      dispatch(removeSelectedWishlist());
      changeWishlistLoadingState(false);
    });
  };

  const saveWishlist = (isFetch: boolean, isAdd?: boolean, productCode?: string) => {
    dispatch(insertSelectedWishlist(productCode ?? ''));
    changeWishlistLoadingState(true);
    if (isFetch) {
      fetchWishlist();
    } else {
      if (isAdd && productCode) {
        addToWishList({ product_code: productCode }).then(response => {
          if (response?.shopWishlistAdd?.is_successful) {
            fetchWishlist();
            setIsSuccessful(true);
          } else {
            setIsSuccessful(false);
            changeWishlistLoadingState(false);
            setErrorMessage(response?.shopWishlistAdd?.error_msg);
          }
        });
      } else if (!isAdd && productCode) {
        removeToWishList({ product_code: productCode }).then(response => {
          if (response?.shopWishlistRemove?.is_successful) {
            fetchWishlist();
            setIsSuccessful(true);
          } else {
            setIsSuccessful(false);
            changeWishlistLoadingState(false);
            setErrorMessage(response?.shopWishlistRemove?.error_msg);
          }
        });
      }
    }
  };

  useEffect(() => {
    if (SelectProductsData?.selectProducts?.is_successful && invoiceNumber) {
      getInvoice({ details: { invoice_number: invoiceNumber || '' } });
    }
  }, [SelectProductsData]);

  useEffect(() => {
    const response = AddToWishListData?.shopWishlistAdd;
    if (response) {
      setIsSuccessful(response.is_successful);
      setErrorMessage(response.error_msg);
    }
  }, [AddToWishListData]);

  useEffect(() => {
    const response = RemoveToWishListData?.shopWishlistRemove;
    if (response) {
      setIsSuccessful(response.is_successful);
      setErrorMessage(response.error_msg);
    }
  }, [RemoveToWishListData]);

  useEffect(() => {
    const response = GetAllWishlistData?.getAllWishlist;
    if (response) {
      setWishlistData(GetAllWishlistData.getAllWishlist?.data);
      setIsSuccessful(GetAllWishlistData.getAllWishlist?.is_successful);
      setErrorMessage(GetAllWishlistData.getAllWishlist?.error_msg);
    }
  }, [GetAllWishlistData]);

  return {
    isSuccessful,
    errorMessage,
    isAddProductLoading,
    isRemoveProductLoading,
    isSelectProductLoading,
    isWishListLoading,
    addProductData: AddProductData?.addProduct,
    removeProductData: RemoveProductData?.removeProduct,
    SelectProductsData: SelectProductsData?.selectProducts,
    addToWishListData: AddToWishListData,
    removeToWishListData: RemoveToWishListData,
    wishlistData,
    addProduct,
    removeProduct,
    selectProducts,
    addToWishList,
    removeToWishList,
    getAllWishlist,
    saveWishlist,
  };
};

export { useProduct };
