import { useTranslate } from '@fe-monorepo/hooks';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../app/app.routes.enum';
import LeftArrowIcon from '../assets/icons/LeftArrowIcon';
import RightArrowIcon from '../assets/icons/RightArrowIcon';
import Bottom from '../components/Layouts/Bottom';
import CreateContentSidebar from '../components/Layouts/Sidebar/Content';
import CreatePost from '../components/Post/CreatePost';

const CreateContent = () => {
  const defaultLanguage = localStorage.getItem('defaultLanguage') ?? 'en';
  const { translate } = useTranslate();
  const navigate = useNavigate();

  return (
    <>
      <div className="full-sec">
        <div className="left-sec">
          <CreateContentSidebar />
        </div>
        <div className="right-sec creat-right-sec">
          <div className="full-box create-full-box">
            <div className="back-header">
              <ul className="list">
                <li className="back-btn">
                  <button className="back-btn-a" onClick={() => navigate(`${AppRoutes.bitsPlay}/bits`)}>
                    {defaultLanguage === 'en' ? <LeftArrowIcon /> : <RightArrowIcon />}
                    {translate('quickplay.back-btn')}
                  </button>
                </li>
                <li className="common-heading">{translate('quickplay.text-createContent')}</li>
              </ul>
            </div>
            <CreatePost />
          </div>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default CreateContent;
