import { useLazyQuery, useMutation } from '@apollo/client';
import { CIRCUIT_QUERY, EWC_BANNER_QUERY, STREAMS_QUERY } from '@fe-monorepo/data-access';
import {
  CircuitBannerInput,
  CircuitMatchInput,
  CircuitMatchResponse,
  CircuitTournamentInput,
  CircuitTournamentResponse,
  TMSGetAllCircuitPredictionsInput,
  TMSGetAllCircuitPredictionsResponse,
  TMSGetCircuitPredictionRankingResponse,
  TMSGetCircuitPredictionWeeksResponse,
  TMSGetCircuitStandingRankingsInput,
  TMSGetCircuitStandingRankingsResponse,
  TMSgetCircuitPredictionRankingsInput,
  TmsSubmitPredictionInput,
  TmsSubmitPredictionResponse,
} from '@fe-monorepo/models';

export const useCircuit = () => {
  const [getAllCircuitTournamentGql] = useLazyQuery<CircuitTournamentResponse>(CIRCUIT_QUERY.getAllCircuitTournament, {
    errorPolicy: 'all',
  });

  const [getEWCBannersGql] = useLazyQuery<any>(EWC_BANNER_QUERY.getEWCBanner, {
    errorPolicy: 'all',
  });

  const [getAllStreamsGql] = useLazyQuery<any>(STREAMS_QUERY.getStreams, {
    errorPolicy: 'all',
  });

  const [getAllCircuitMatchGql] = useLazyQuery<CircuitMatchResponse>(CIRCUIT_QUERY.getAllCircuitMatch, {
    errorPolicy: 'all',
  });

  const [submitPredictionGql] = useMutation<TmsSubmitPredictionResponse>(CIRCUIT_QUERY.submitPrediction, {
    errorPolicy: 'all',
  });

  const [getAllPredictionsGql] = useLazyQuery<TMSGetAllCircuitPredictionsResponse>(CIRCUIT_QUERY.getAllCircuitPredictions, {
    errorPolicy: 'all',
  });

  const [getCircuitPredictionRankingsGql] = useLazyQuery<TMSGetCircuitPredictionRankingResponse>(
    CIRCUIT_QUERY.getCircuitPredictionRankings,
    {
      errorPolicy: 'all',
    },
  );

  const [getCircuitPredictionWeeksGql] = useLazyQuery<TMSGetCircuitPredictionWeeksResponse>(CIRCUIT_QUERY.getCircuitPredictionWeeks, {
    errorPolicy: 'all',
  });

  const [getCircuitStandingRankingsGql] = useLazyQuery<TMSGetCircuitStandingRankingsResponse>(CIRCUIT_QUERY.getCircuitStandingRankings, {
    errorPolicy: 'all',
  });

  const getAllCircuitTournament = async (params: CircuitTournamentInput) => {
    const { data } = await getAllCircuitTournamentGql({
      variables: { details: params },
    });
    return data?.getAllCircuitTournament;
  };

  const getEWCBanners = async (params: CircuitBannerInput) => {
    const { data } = await getEWCBannersGql({
      variables: { details: params },
    });
    return data.EWCBanner;
  };

  const getAllStreams = async () => {
    const { data } = await getAllStreamsGql();
    return data.GetStreams;
  };

  const getAllCircuitMatch = async (params: CircuitMatchInput) => {
    const { data } = await getAllCircuitMatchGql({
      variables: { details: params },
    });
    return data?.getAllCircuitMatch;
  };

  const submitPrediction = async (body: TmsSubmitPredictionInput) => {
    const { data } = await submitPredictionGql({
      variables: { details: body },
    });
    return data?.submitPrediction;
  };

  const getAllPredictions = async (body: TMSGetAllCircuitPredictionsInput) => {
    const { data } = await getAllPredictionsGql({
      variables: { details: body },
    });

    return data?.getAllCircuitPredictions;
  };

  const getStandingRankings = async (body: TMSGetCircuitStandingRankingsInput) => {
    const { data } = await getCircuitStandingRankingsGql({
      variables: { details: body },
    });
    return data?.getCircuitStandingRankings;
  };

  const getPredictionRankings = async (body: TMSgetCircuitPredictionRankingsInput) => {
    const { data } = await getCircuitPredictionRankingsGql({
      variables: { details: body },
    });

    return data?.getCircuitPredictionRankings;
  };

  const getPredictionWeeks = async () => {
    const { data } = await getCircuitPredictionWeeksGql();

    return data?.getCircuitPredictionWeeks;
  };

  return {
    getAllCircuitTournament,
    getAllCircuitMatch,
    submitPrediction,
    getAllPredictions,
    getPredictionRankings,
    getPredictionWeeks,
    getStandingRankings,
    getEWCBanners,
    getAllStreams,
  };
};
