import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import quickplay from 'quickplay-shorts-js';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import RepostFilledIcon from '../../../../assets/icons/RepostFilledIcon';
import RepostGrayIcon from '../../../../assets/icons/RepostGrayIcon';
import { useNotification } from '../../../../contexts/Notification';
import CustomModal from '../../../common/CustomModal/CustomModal';

const feedModule = new quickplay.QuickPlayFeedModule();

const getRepostPayloadData = (video: any) => {
  return {
    userId: video.userId,
    contentType: video.contentType,
    taggedUsers: video.taggedUsers,
    s3Url: video.url,
    contentUrl: video.contentUrls,
    title: video.title,
    videoCoverImage: video.thumbnailUrl,
    thumbnailUrl: video.thumbnailUrl,
    description: video.description,
    follow: false,
    type: 'repost',
    categoryId: video.categoryId,
    categoryName: video.categoryId,
    soundId: video.soundId,
    filterId: '',
    filterDisplayName: '',
    metaKeys: '',
    businessId: '',
    projectId: '',
    loc: [],
    lang: [],
    labels: [],
    question: '',
    options: [
      {
        title: '',
      },
    ],
    localisedTitle: video.localisedTitle,
    localisedDescription: video.localisedDescription,
    repost: true,
    originalVideoId: video.videoId,
    commentEnabled: video.commentEnabled,
    likeEnabled: video.likeEnabled,
    shareEnabled: video.shareEnabled,
    soundName: video.soundName,
  };
};

export default function RepostModal({ show, onHide, video }: any) {
  const navigate = useNavigate();

  const [isReposting, setIsReposting] = useState(false);
  const [isRepostingWithThoughts, setIsRepostingWithThoughts] = useState(false);

  const { showNotification } = useNotification();

  const handleRepost = async () => {
    setIsReposting(true);
    const repostPayloadData = getRepostPayloadData(video);
    try {
      const {
        data: { success },
      } = await feedModule.uploadContent(repostPayloadData);
      if (success) {
        onHide();
        setIsReposting(false);
        showNotification({
          title: 'Repost',
          subTitle: 'Repost has been successfully',
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleRepostWithThoughts = () => {
    setIsRepostingWithThoughts(true);
    sessionStorage.setItem('videoUrl', JSON.stringify([video.url]));
    navigate(`${AppRoutes.bits}/create-post-page-02?repost=true`);
    setIsRepostingWithThoughts(false);
  };

  return (
    <>
      <CustomModal isOpen={show} close={onHide}>
        <div className="repost_actions">
          <button className="repost_actions-btn" onClick={handleRepostWithThoughts}>
            <div className="btn-wrap">
              <span>
                <RepostFilledIcon />
              </span>
              <div>{isRepostingWithThoughts ? 'Reposting With Thoughts...' : 'Repost with your thoughts'}</div>
            </div>
          </button>
          <button className="repost_actions-btn" onClick={handleRepost}>
            <div className="btn-wrap">
              <span>
                <RepostGrayIcon />
              </span>
              <div>{isReposting ? 'Reposting...' : 'Repost'}</div>
            </div>
          </button>
        </div>
      </CustomModal>
    </>
  );
}
