import { Card, ImageOnly } from '@braze/web-sdk';
import { BannerItemsData, BrazeContentCardSliderBanner } from '@fe-monorepo/models';
import PreviewSlider from '@fe-web/Organisms/PreviewSlider';
import { Banner } from '@fe-web/types/bannerTypes';

interface HeroSectionProps {
  shopPageBrazeSliders: Card[] | ImageOnly[];
  spectatorBanners: BannerItemsData[];
  isFromBraze: boolean;
}

const HeroSection = (props: HeroSectionProps) => {
  const { shopPageBrazeSliders, spectatorBanners, isFromBraze } = props;

  const mapBrazeSlidersToBanners = (brazeSliders: (Card | ImageOnly)[]): Banner[] => {
    return brazeSliders.map(slider => {
      const { extras, id, url } = slider as ImageOnly;
      const {
        web_media_url_ar,
        web_media_url_en,
        mobile_media_url_ar,
        mobile_media_url_en,
        name_ar,
        name_en,
        reference_type,
        reference_value,
        is_external,
      } = extras as unknown as BrazeContentCardSliderBanner;

      return {
        id,
        url,
        mobile_media_url_ar,
        mobile_media_url_en,
        web_media_url_ar,
        web_media_url_en,
        name_ar,
        name_en,
        reference_type,
        reference_value,
        is_external,
      };
    });
  };

  const bannersFromBraze = mapBrazeSlidersToBanners(shopPageBrazeSliders);
  const bannersToShow: Banner[] = isFromBraze ? bannersFromBraze : spectatorBanners;

  return (
    <>
      {bannersToShow?.length > 0 && (
        <PreviewSlider banners={bannersToShow} isTransparent={true} isFromBraze={isFromBraze} brazeCards={shopPageBrazeSliders} />
      )}
    </>
  );
};

export default HeroSection;
