import React from 'react';

interface MessageContentProps {
  greetings?: string;
}

const Greetings: React.FC<MessageContentProps> = ({ greetings }) => (
  <div className="flex flex-col h-full w-full gap-2 items-center justify-center overflow-auto text-center text-gray-500 font-regular leading-tight text-fs-body-small">
    <span role="img" aria-label="Waving hand emoji">
      👋
    </span>
    <span className="max-w-[10rem] w-full text-fs-caption text-neutral-400-zinc-500 font-regular">{greetings}</span>
  </div>
);

export default Greetings;
