import { useTranslate } from '@fe-monorepo/hooks';
import { InvoiceProduct, ShipmentsModel } from '@fe-monorepo/models';
import Products from '@fe-web/Organisms/OrderProduct/OrderProduct';
import { SHOP_PAGES } from '@fe-web/constant/constants';

interface digitalProducts {
  products: InvoiceProduct[];
  language: 'en' | 'ar';
  isRTL?: boolean;
  digitalShipments: ShipmentsModel[];
  resend: () => void;
  orderStatus?: string;
}

const DigitalDelivery = ({ products, language, isRTL, resend, digitalShipments, orderStatus }: digitalProducts) => {
  const { translate } = useTranslate();
  const showSendAsGiftAndDetails = SHOP_PAGES.myorders === window.location.pathname;
  return (
    <>
      <div className="font-medium text-fs-subtitle text-medium text-secondary-100">
        <p>{translate('shop.cart.order_main.digital_products')}</p>
      </div>
      <Products
        products={products}
        language={language}
        showCaption={true}
        showDigitalProductTag={false}
        isRTL={isRTL}
        showSendAsGiftAndDetails={showSendAsGiftAndDetails}
        digitalShipments={digitalShipments}
        resend={resend}
        orderStatus={orderStatus}
      />
    </>
  );
};

export default DigitalDelivery;
