// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { automaticallyShowInAppMessages, initialize, isPushSupported } from '@braze/web-sdk';
import { BrazeCustomEvents, MixPanelCustomEvents, Translation, languageMapping } from '@fe-monorepo/helper';
import { persistor, store } from '@fe-monorepo/store';
import { getEnvironment } from '@fe-web/constant/environment';
import { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ParallaxProvider } from 'react-scroll-parallax';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { PersistGate } from 'redux-persist/integration/react';

import { LoginModalProvider } from '../quickplay-app/contexts/LoginModal';
import '../styles.scss';
import AnimatedRoutes from './AnimatedRoutes';
import brazeHelper from './helpers/brazeHelper';
import mixpanelHelper from './helpers/mixpanelHelper';
import { BitsProvider } from './hooks/useBits/BitsProvider';
import { ConnectProvider } from './hooks/useConnect';
import { NotificationProvider } from './hooks/useNotification';
import ErrorPageWrapper from './pages/ErrorPages/ErrorPageWrapper';
import { AppProvider, CustomApolloProvider } from './providers/AppContext';
import { BrazeContentProvider } from './providers/BrazeContentProvider';
import { ConvivaAppProvider } from './providers/ConvivaAppContext';
import SessionManager from './providers/SessionManager';

const { brazeWebAPIKey, brazeWebBaseURL, brazeWebSafariPushID, appEnvironment, gtmID } = getEnvironment();

TagManager.initialize({
  gtmId: gtmID ?? '',
});

const isDebug = ['development', 'localhost', 'staging'].includes(appEnvironment ?? '');

initialize(brazeWebAPIKey ?? '', {
  enableLogging: isDebug,
  baseUrl: brazeWebBaseURL ?? '',
  safariWebsitePushId: brazeWebSafariPushID ?? '',
  requireExplicitInAppMessageDismissal: true,
});
automaticallyShowInAppMessages();
isPushSupported();

export function App() {
  const language = store.getState().app.language;
  const [lang, setLanguage] = useState(store.getState().app.language);

  useEffect(() => {
    Translation(language);
  }, [language]);

  useEffect(() => {
    const eventPayload = {
      'Session ID': `session_${new Date().getTime()}`,
      Language: languageMapping[language],
    };
    mixpanelHelper.trackEvent(MixPanelCustomEvents.AppOpen, eventPayload);
    brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.AppOpen, eventPayload);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const unsubscribe = store.subscribe(() => {
      setLanguage(store.getState().app.language);
    });
    // Clean up the subscription when the component unmounts
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <StoreProvider store={store}>
      <ToastContainer toastClassName="my-toast-container" rtl={lang === 'ar'} />
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename="/">
          <AppProvider>
            <LoginModalProvider>
              <CustomApolloProvider>
                <BitsProvider showToastMessage={console.log}>
                  <ParallaxProvider>
                    <BrazeContentProvider>
                      <ConvivaAppProvider>
                        <NotificationProvider>
                          <ConnectProvider>
                            <SessionManager>
                              <HelmetProvider>
                                <ErrorPageWrapper>
                                  <AnimatedRoutes />
                                </ErrorPageWrapper>
                              </HelmetProvider>
                            </SessionManager>
                          </ConnectProvider>
                        </NotificationProvider>
                      </ConvivaAppProvider>
                    </BrazeContentProvider>
                  </ParallaxProvider>
                </BitsProvider>
              </CustomApolloProvider>
            </LoginModalProvider>
          </AppProvider>
        </BrowserRouter>
      </PersistGate>
    </StoreProvider>
  );
}

export default App;
