import gql from 'graphql-tag';

export const NETWORK_QUERY = {
  crmNetworkGetAll: gql`
    query crmNetworkGetAll($details: CrmNetworkGetAllInput) {
      crmNetworkGetAll(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          gaming {
            social_network_code
            social_network_account
            sort_id
          }
          social {
            social_network_code
            social_network_account
            sort_id
          }
        }
      }
    }
  `,
};
