import { ConnectConfigType } from '@fe-monorepo/models';

const checkConnectPermission = (connectConfig: ConnectConfigType | null) => {
  const showConnectScreen = connectConfig?.enabled && connectConfig?.screenVisible;
  const showChatPreference = connectConfig?.enabled && connectConfig?.chatPreferenceVisible;
  const showConnectHelp = connectConfig?.enabled && connectConfig?.helpVisible;

  return { showConnectScreen, showChatPreference, showConnectHelp };
}

export { checkConnectPermission };
