// @ts-nocheck
import { BrazeCustomEvents, MixPanelEvents } from '@fe-monorepo/helper';
import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import VerifyPhoneModal from '@fe-web/Molecules/SuperPlus/VerifyPhoneModal';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { Menu, Transition } from '@headlessui/react';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import Hls from 'hls.js';
import quickplay from 'quickplay-shorts-js';
import { Fragment, forwardRef, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import ErrorInfo from '../../assets/icons/ErrorInfo';
import PauseIcon from '../../assets/icons/PauseIcon';
import PlayIcon from '../../assets/icons/PlayIcon';
import SoundWave from '../../assets/icons/SoundWave';
import { EVENTS } from '../../constants';
import { useLoginModalContext } from '../../contexts/LoginModal';
import useAnalytics from '../../hooks/useAnalytics';
import useElementOnScreen from '../../hooks/useElementOnScreen';
import useIsMobile from '../../hooks/useIsMobile';
import useLikedVideo from '../../hooks/useLikedVideo';
import useSound from '../../hooks/useSound';
import Report from '../Feed/Report';
import FullScreenMode from '../FullScreenMode';
import MessageIcon from './Icons/MessageIcon';
import Mute from './Icons/Mute';
import OptionsIcon from './Icons/Options';
import ReportIcon from './Icons/Report';
import ShareIcon from './Icons/Share';
import Unmute from './Icons/Unmute';
import ViewsIcon from './Icons/ViewsIcon';
import CommentButton from './components/Buttons/Comment';
import LikeButton from './components/Buttons/Like';
import RepostButton from './components/Buttons/Repost';
import ShareButton from './components/Buttons/Share';
import Comments from './components/Comments';
import VideoDescription from './components/Description';
import RepostModal from './components/Repost/Modal';
import Share from './components/Share';
import UserInfo from './components/UserInfo';
import { convertKToNumeric, formatLargeNumber, getTimeAgo, parseDescription } from './helpers';

const activityTimelineModule = new quickplay.QuickPlayActivityTimeline();
const authModule = new quickplay.QuickPlayAuthModule();
const userModule = new quickplay.QuickPlayUserModule();

const PlayPauseButtons = ({ className, handlePlayAndPause, isPlaying, showControls }: any) => (
  <button
    className={className}
    onClick={handlePlayAndPause}
    style={{
      opacity: showControls || !isPlaying ? 1 : 0,
    }}
  >
    {isPlaying ? <PlayIcon /> : <PauseIcon />}
  </button>
);

const VideoItem = forwardRef(
  ({ video, setVideos, setCurrentVideoIndex, allVideoRefs, deeplinkData, verticalPlayerRef, followingClicked }: any, ref: any) => {
    const {
      indexNo,
      id,
      url,
      playing: isPlaying,
      muted: isMuted,
      thumbnailUrl,
      user,
      title,
      description = '',
      taggedUsers,
      commentCount,
      likeCount,
      shareCount,
      products,
      repost = false,
      repostCount,
      soundId,
      viewsCount,
      videoId,
      status,
      createdTimestamp,
      hashtags,
    } = video;
    const isMobile = useIsMobile();
    const [commentCounts, setCommentCounts] = useState(commentCount);
    const [shareCounts, setShareCounts] = useState(shareCount);
    const { userId, fullName, profileImageUrl, userName } = user ?? {};
    const [showControls, setShowControls] = useState(false);
    const [showComments, setShowComments] = useState(isMobile ? false : true);
    const [showShare, setShowShare] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [isFollowing, setIsFollowing] = useState(false);
    const [isLikingVideo, setIsLikingVideo] = useState(false);
    const [showReportModal, setShowReportModal] = useState(false);
    const [showCommentReportModal, setShowCommentReportModal] = useState(false);
    const [showLike, setShowLike] = useState(false);
    const [showRepost, setShowRepost] = useState(false);
    const [videoLikeCount, setVideoLikeCount] = useState(likeCount);
    const [commentPostId, setCommentPostId] = useState('');
    const [commentExpand, setCommentExpand] = useState(false);
    const divRef: any = useRef(null);
    const [elementHeight, setElementHeight] = useState(0);
    const { translate } = useTranslate();
    const { pathname } = useLocation();
    const [showHighlightedComment, setShowHighlightedComment] = useState(false);
    const hls = useRef<Hls>();
    const [viewTriggered, setViewTriggered] = useState(false);
    const { setShowLoginModal } = useLoginModalContext();
    const navigate = useNavigate();
    const { trackEvent } = useAnalytics();
    const parsedDescription = parseDescription(description, taggedUsers);
    const userData = JSON.parse(localStorage.getItem('userData') || '{}');
    const videoRef = ref;
    const videoControls = useRef<HTMLDivElement>(null);
    const options = {
      root: document.getElementsByClassName('player_wrapper')[0],
      rootMargin: '0px',
      threshold: 0.5,
    };
    const { sound }: any = useSound(soundId as string);
    const isVisible = useElementOnScreen(options, videoRef);
    const language = useSelector((state: RootState) => state.app.language);
    const hardPopup = useSelector((state: RootState) => state.app.hardPopup);
    const hardPopupEnabled = hardPopup?.enabled ?? false;
    const { isVideoLiked, isLoading: isCheckingForLikedVideo, setIsVideoLiked } = useLikedVideo(videoId);
    const showLikeButton = video?.likeEnabled !== false;
    const [isVerifyOpen, setIsVerifyOpen] = useState(false);
    const { user: currentUser, isLoggedIn } = useUserProfile();
    const isPhoneNotAdded = !currentUser?.mobile;
    const isPhoneNotVerified = currentUser?.mobile && !currentUser?.is_mobile_verified;
    const videoTimeAgo = getTimeAgo(createdTimestamp, language);
    const [progress, setProgress] = useState(0);
    const [videoIsPlaying, setVideoIsPlaying] = useState(isPlaying);
    const [videoPlaybackTime, setVideoPlaybackTime] = useState(0);

    // Calculate Comment List Height Dynamically - Start //
    const updateHeight = () => {
      if (divRef.current) {
        const contentHeight = divRef.current.scrollHeight;
        setElementHeight(contentHeight);
      }
    };

    useEffect(() => {
      // Calculate height after component mounts
      updateHeight();

      // Update height when the window is resized
      window.addEventListener('resize', updateHeight);

      return () => {
        window.removeEventListener('resize', updateHeight);
      };
    }, []);

    useEffect(() => {
      // Any time the content inside the div changes, update the height
      updateHeight();
    });
    // Calculate Comment List Height Dynamically - End //

    // Video Progess Bar Start //
    useEffect(() => {
      const videoElement = videoRef.current;

      if (!videoElement) return;

      const updateProgress = () => {
        if (videoElement && videoElement.duration) {
          const progressValue = (videoElement.currentTime / videoElement.duration) * 100;
          setProgress(progressValue);
        }
      };

      const handlePlay = () => setVideoIsPlaying(true);
      const handlePause = () => setVideoIsPlaying(false);
      const handleEnded = () => {
        setVideoIsPlaying(false);
        setProgress(100); // Set to 100% when the video ends
      };

      videoElement.addEventListener('timeupdate', updateProgress);
      videoElement.addEventListener('play', handlePlay);
      videoElement.addEventListener('pause', handlePause);
      videoElement.addEventListener('ended', handleEnded);

      return () => {
        videoElement.removeEventListener('timeupdate', updateProgress);
        videoElement.removeEventListener('play', handlePlay);
        videoElement.removeEventListener('pause', handlePause);
        videoElement.removeEventListener('ended', handleEnded);
      };
    }, []);
    // Video Progess Bar End //

    const updateVideos = (type: string, status: boolean) => {
      setVideos((videos: any) => {
        const newVideos = videos.map((video: any) => {
          if (video.id === id) {
            video[type] = status;
          }
          return video;
        });
        return newVideos;
      });
    };

    // useEffect(() => {
    //   if (viewTriggered) {
    //     activityTimelineModule.activityTimelineView({ assetId: videoId, duration: videoPlaybackTime }).then((response: any) => {
    //       const { status } = response ?? {};
    //       if (status === 200) {
    //         trackEvent({
    //           event: EVENTS.VIEW_IMPRESSION,
    //           content: video,
    //         })
    //           .then(res => {
    //             const eventPayload = {
    //               'Bit ID': videoId,
    //               'Bit Title': title,
    //               'Viewer Username': currentUser?.username ?? 'GUEST',
    //               'Bit Publisher Username': userName,
    //             };
    //             mixpanelHelper.trackEvent(MixPanelEvents.bitViewed, eventPayload);
    //             brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.BitViewed, eventPayload);
    //           })
    //           .catch(error => {
    //             console.error('Error tracking event:', error);
    //           });
    //       }
    //     });
    //   }
    // }, [viewTriggered]);

    useEffect(() => {
      const videoElement = videoRef.current;
      if (!videoElement) return;

      const handleTimeUpdate = () => {
        const tolerance = 0.3;
        let playbackTime = parseFloat(videoElement.currentTime.toFixed(2));
        let playbackDuration = parseFloat(videoElement.duration.toFixed(2));
        let playDifference = parseFloat(Math.abs(playbackTime - playbackDuration).toFixed(1));
        setVideoPlaybackTime(playbackTime);

        // if (Number(playbackTime) >= 3 && !viewTriggered) {
        //   setViewTriggered(true);
        // }
        // if (Number(playbackTime) < 3) {
        //   setViewTriggered(false);
        // }

        if (playDifference <= tolerance) {
          trackEvent({
            event: EVENTS.CONTENT_REPLAY,
            content: video,
          });

          activityTimelineModule.activityTimelineView({ assetId: videoId, duration: playbackDuration }).then((response: any) => {
            const { status } = response ?? {};
            if (status === 200) {
              trackEvent({
                event: EVENTS.VIEW_IMPRESSION,
                content: video,
              })
                .then(res => {
                  const eventPayload = {
                    'Bit ID': videoId,
                    'Bit Title': title,
                    'Viewer Username': currentUser?.username ?? 'GUEST',
                    'Bit Publisher Username': userName,
                  };
                  mixpanelHelper.trackEvent(MixPanelEvents.bitViewed, eventPayload);
                  brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.BitViewed, eventPayload);
                })
                .catch(error => {
                  console.error('Error tracking event:', error);
                });
            }
          });
        }
      };

      videoElement.addEventListener('timeupdate', handleTimeUpdate);

      // Check file extension to determine video type
      const fileExtension = url?.split('.').pop()?.toLowerCase();

      if (fileExtension === 'm3u8') {
        // HLS video
        if (Hls.isSupported()) {
          // Initialize HLS.js
          hls.current = new Hls();
          hls.current.loadSource(url);
          hls.current.attachMedia(videoElement);

          // Cleanup
          return () => {
            if (hls.current) {
              hls.current.destroy();
            }
          };
        } else {
          console.error('HLS is not supported in this browser.');
        }
      } else if (['mp4', 'mov', 'm4v'].includes(fileExtension)) {
        // MP4 video
        videoElement.src = url;
      } else {
        console.error('Unsupported video format.');
      }
    }, [url, videoRef]);

    const toggleReportModal = () => {
      setShowReportModal(prev => !prev);
    };

    const toggleCommentReportModal = () => {
      setShowCommentReportModal(prev => !prev);
    };

    useEffect(() => {
      if (video.deeplinkData?.commentId) {
        setShowHighlightedComment(true);
        setShowComments(true);
        videoRef.current.classList.add('video-player-radius');
        videoControls.current?.classList.add('video-player-radius');
      } else {
        setShowHighlightedComment(false);
      }
    }, [video.deeplinkData?.commentId]);

    useEffect(() => {
      if (Object.keys(userData).length !== 0) {
        let followingList: any = localStorage.getItem('following');
        if (followingList) {
          followingList = JSON.parse(followingList || '[]');
          if (followingList.includes(userId)) {
            setIsFollowing(true);
          } else {
            setIsFollowing(false);
          }
        }
      }
      if (videoRef.current) {
        if (isVisible) {
          setCurrentVideoIndex(indexNo);
          videoRef.current?.play().catch((error: any) => {
            // Handle error
            console.error('Autoplay error:', error);
          });
          updateVideos('playing', true);
          trackEvent({
            event: EVENTS.CONTENT_PLAY,
            content: video,
          });
        } else {
          videoRef.current?.pause();
          updateVideos('playing', false);
        }
      }
    }, [isVisible]);

    const isLoggedin = async () => {
      const accessToken = await new quickplay.QuickPlayAuthModule().getAccessToken();
      if (accessToken) {
        return true;
      } else {
        return false;
      }
    };

    // Play & Pause Handler - Start //
    const handlePlayAndPause = () => {
      const allVideos = document.getElementsByClassName('video_player');
      if (allVideos.length > 0) {
        const allVideoElements = Array.from(allVideos);
        allVideoElements.forEach((video: any) => {
          if (video.id !== videoRef.current?.id) {
            video.pause();
            setVideos((videos: any) => {
              const newVideos = videos.map((video: any) => {
                if (video.id !== videoRef.current?.id) {
                  video.playing = false;
                }
                return video;
              });
              return newVideos;
            });
          }
        });
      }
      if (videoRef.current?.paused) {
        videoRef.current?.play().catch((error: any) => {
          console.error('Autoplay error:', error);
        });
        updateVideos('playing', true);
        trackEvent({
          event: EVENTS.CONTENT_RESUME,
          content: video,
        });
      } else {
        videoRef.current?.pause();
        updateVideos('playing', false);
        let playbackTime = parseFloat(videoRef.current?.currentTime.toFixed(2));
        trackEvent({
          event: EVENTS.CONTENT_PAUSE,
          content: {
            ...video,
            timeElapsed: playbackTime || 0,
          },
        });
        if (playbackTime >= 3) {
          activityTimelineModule.activityTimelineView({ assetId: videoId, duration: playbackTime }).then((response: any) => {
            const { status } = response ?? {};
            if (status === 200) {
              trackEvent({
                event: EVENTS.VIEW_IMPRESSION,
                content: video,
              })
                .then(res => {
                  const eventPayload = {
                    'Bit ID': videoId,
                    'Bit Title': title,
                    'Viewer Username': currentUser?.username ?? 'GUEST',
                    'Bit Publisher Username': userName,
                  };
                  mixpanelHelper.trackEvent(MixPanelEvents.bitViewed, eventPayload);
                  brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.BitViewed, eventPayload);
                })
                .catch(error => {
                  console.error('Error tracking event:', error);
                });
            }
          });
        }
      }
    };
    // Play & Pause Handler - End //

    // Mute & Unmute Handler - Start //
    const handleMuteUnmute = () => {
      if (videoRef?.current) {
        if (isMuted) {
          videoRef.current.muted = false;
          updateVideos('muted', false);
        } else {
          videoRef.current.muted = true;
          updateVideos('muted', true);
        }
      }
    };
    // Mute & Unmute Handler - End //

    // On Mouse Hover Show Video Controls //
    const handleMouseOver = () => {
      setShowControls(true);
    };

    // On Mouse Out Hide Video Controls //
    const handleMouseOut = () => {
      setShowControls(false);
    };

    // Follow & Unfollow Event Handler - Start //
    const handleFollowEvent = async (event: any, userId: any, followStatus: boolean) => {
      trackEvent({
        event: isFollowing ? EVENTS.USER_UNFOLLOW : EVENTS.USER_FOLLOW,
        content: { user },
      });
      try {
        if (!isLoggedIn) {
          setShowLoginModal(true);
          return;
        }
        const accessToken = await authModule.getAccessToken();
        if (accessToken === '' || accessToken === undefined || accessToken === null) {
          setShowLoginModal(true);
          return;
        }
        let followingList: any = localStorage.getItem('following');
        followingList = JSON.parse(followingList || '[]');
        const formData = {
          followingId: userId,
          isFollow: followStatus,
        };
        userModule.followUser(formData);
        if (followStatus) {
          followingList.push(userId);
          localStorage.setItem('following', JSON.stringify(followingList));
          setIsFollowing(true);
        } else {
          const newFollowing = followingList.filter((id: any) => id !== userId);
          localStorage.setItem('following', JSON.stringify(newFollowing));
          setIsFollowing(false);
        }
      } catch (error) {
        console.error(error);
      }
    };
    // Follow & Unfollow Event Handler - End //

    // Like & Unlike Event Handler - Start //
    const handleLikeEvent = async (e: any, videoId: string) => {
      e.preventDefault();
      setIsLikingVideo(true);
      const selfTarget = e.currentTarget;
      const likeElement: any = document.getElementById('like-count-' + videoId);
      const videoLikedByMe = selfTarget.classList.contains('active');
      const isUserLoggedIn = await isLoggedin();
      if (!isUserLoggedIn) {
        setShowLoginModal(true);
        setIsLikingVideo(false);
        return;
      }
      if (isVideoLiked) {
        const userPostedComment = await activityTimelineModule.activityTimelineUnLike({
          assetId: videoId,
        });
        const { status } = userPostedComment ?? {};
        if (status === 200) {
          trackEvent({
            event: EVENTS.CONTENT_UNLIKE,
            content: video,
          });
          selfTarget.classList.remove('active');
          let likeNumber: any = convertKToNumeric(likeElement.innerHTML);
          likeNumber--;
          likeNumber = likeNumber >= 0 ? likeNumber : 0;
          const likeCount = formatLargeNumber(likeNumber);
          likeElement.innerHTML = likeCount;
          setVideoLikeCount(likeCount);
          setIsVideoLiked(false);
          setIsLikingVideo(false);
        }
      } else {
        activityTimelineModule.activityTimelineLike({ assetId: videoId }).then((response: any) => {
          const { status } = response ?? {};
          if (status === 200) {
            trackEvent({
              event: EVENTS.CONTENT_LIKE,
              content: video,
            })
              .then(res => {
                const eventPayload = {
                  'Bit ID': videoId,
                  'Bit Title': title,
                  Username: currentUser?.username,
                  'Bits Publisher Username': userName,
                };
                mixpanelHelper.trackEvent(MixPanelEvents.bitsLiked, eventPayload);
                brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.BitsLiked, eventPayload);
              })
              .catch(error => {
                console.error('Error tracking event:', error);
              });
            selfTarget.classList.add('active');
            let likeNumber: any = convertKToNumeric(likeElement.innerHTML);
            likeNumber++;
            const likeCount = formatLargeNumber(likeNumber);
            likeElement.innerHTML = likeCount;
            setVideoLikeCount(likeCount);
            setIsVideoLiked(true);
            setIsLikingVideo(false);
          }
        });
      }
    };
    // Like & Unlike Event Handler - End //

    // Repost Modal
    const toggleRepostModal = () => {
      setShowRepost(prev => !prev);
    };

    // Reposted Video - Redirectio to Original Video //
    const reDirectToOriginalVideo = () => {
      trackEvent({
        event: EVENTS.VIEW_IMPRESSION,
        content: video,
      });
      navigate(`${AppRoutes.bitsPlay}/content/${video.videoId}`);
    };

    // Redirect to Sound Detail Page //
    const handleClickOnSound = () => {
      navigate(`${AppRoutes.bits}/sound-track/${soundId}`);
    };

    const stringifyCircular = obj => {
      const seen = new WeakSet();
      return JSON.stringify(obj, (key, value) => {
        if (typeof value === 'object' && value !== null) {
          if (seen.has(value)) {
            return '[Circular]';
          }
          seen.add(value);
        }
        return value;
      });
    };

    const isWindow = obj => {
      return obj != null && obj === obj.window;
    };

    const isReactElement = obj => {
      return typeof obj === 'object' && obj !== null && obj.$$typeof === Symbol.for('react.element');
    };

    const redirectToChat = () => {
      navigate(AppRoutes.messages + `/${user.userName}/${0}`);
    };

    // Convert array to string if parsedDescription is an array
    const videoDescription = Array.isArray(parsedDescription)
      ? parsedDescription.join(' ') // Join array elements into a single string with spaces
      : typeof parsedDescription === 'string'
      ? parsedDescription
      : isReactElement(parsedDescription)
      ? parsedDescription // Return React element directly
      : typeof parsedDescription === 'object' && parsedDescription !== null && !isWindow(parsedDescription)
      ? stringifyCircular(parsedDescription) // Convert object to string with circular reference handling
      : '';

    return (
      <div className={`player_item_wrapper ${status !== 'ACTIVE' ? 'pointer-events-none' : ''}`}>
        <div id={`content-video-${video._id}`} className="player_item" onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
          <video
            id={`video_player_${id}`}
            ref={videoRef}
            className="video_player"
            src={url}
            poster={thumbnailUrl}
            controls={false}
            muted={isMuted}
            preload="metadata"
            playsInline
            autoPlay={!hardPopupEnabled && isPlaying}
            loop
          >
            <source src={`${url}#t=0.5`} type="video/mp4" />
          </video>
          <PlayPauseButtons
            className="play_button"
            handlePlayAndPause={handlePlayAndPause}
            isPlaying={isPlaying}
            showControls={showControls}
          />
          <div
            className={`video_controls ${products?.length > 0 && 'video_controls_translateX'}`}
            style={{
              opacity: showControls || !isPlaying ? 1 : 0,
            }}
            ref={videoControls}
          >
            <div className="video_views">
              <ViewsIcon />
              <span className="video_views_count">{formatLargeNumber(viewsCount)}</span>
            </div>
            <PlayPauseButtons
              className="play_pause_button button_sm"
              handlePlayAndPause={handlePlayAndPause}
              isPlaying={isPlaying}
              showControls={showControls}
            />
            <button className="mute_unmute_button button_sm" onClick={handleMuteUnmute}>
              {isMuted ? <Mute /> : <Unmute />}
            </button>
            <FullScreenMode playerRef={verticalPlayerRef} />
          </div>
          <UserInfo
            showFollowButton={!followingClicked}
            setShowMore={setShowMore}
            showMore={showMore}
            handleFollow={handleFollowEvent}
            isFollowing={isFollowing}
            video={video}
            showComments={showComments}
            repost={repost}
            products={products}
          />
          <div className="video-progress-container">
            <div className="video-progress-bar" style={{ width: `${progress}%` }} />
          </div>
          {/* Mobile View Post Icons */}
          <div
            className={`post_icons ${products?.length > 0 ? 'post_icons_responsive' : ''} ${
              showComments ? `post_active post_icon_rtl ${products?.length > 0 ? 'icons-transform' : ''}` : ''
            } ${isMobile ? 'show_post_icons' : ''}`}
          >
            <LikeButton
              video={video}
              onClick={handleLikeEvent}
              isLikingVideo={isLikingVideo}
              videoLikeCount={videoLikeCount}
              isVideoLiked={isVideoLiked}
              isCheckingForLikedVideo={isCheckingForLikedVideo}
            />
            <CommentButton
              setCommentExpand={setCommentExpand}
              videoControls={videoControls}
              videoCommentCount={commentCounts}
              video={video}
              videoRef={videoRef}
              setShowComments={setShowComments}
            />
            <ShareButton video={video} shareCount={shareCounts} setShowShare={setShowShare} />
            <RepostButton video={video} toggleRepostModal={toggleRepostModal} setIsVerifyOpen={setIsVerifyOpen} />
            <button className="mute_unmute_button" onClick={handleMuteUnmute}>
              {isMuted ? <Mute /> : <Unmute />}
            </button>
            <div className="misc_options_wrapper">
              <Menu as="div" className="relative inline-block text-left">
                <Menu.Button className="misc_options_rotate">
                  <OptionsIcon />
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="contextMenu absolute cursor-pointer directionRtl">
                    {/* Report Icon */}
                    <Menu.Item>
                      <Link
                        to="#"
                        onClick={async event => {
                          event.preventDefault();
                          const isUserLoggedIn = await isLoggedin();
                          if (isUserLoggedIn) {
                            toggleReportModal();
                          } else {
                            setShowLoginModal(true);
                          }
                        }}
                        className="reply_btn reply_actions block"
                      >
                        <div className="btn-wrap">
                          <ReportIcon />
                          <p>{translate('quickplay.report')}</p>
                        </div>
                      </Link>
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
          {status !== 'ACTIVE' && (
            <div className="player_overlay">
              <div className="toastContainer static">
                <div className="toast-container">
                  <ErrorInfo />
                  <div className="toast-body">Video Not Available</div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className={`video_expanded ${showComments ? 'video_expanded_visible' : 'video_expanded_hidden'}`}>
          <div className="video_expanded_head" ref={divRef}>
            <div className="user_info">
              <div className={`top_info ${showComments ? 'pr_6' : ''}`}>
                <Link to={`/users/${userId}`} onClick={e => e.preventDefault()}>
                  <div className="img_name">
                    <img
                      src={profileImageUrl ? profileImageUrl : 'https://bits-assets.stag.stcplay.gg/Profile.png'}
                      alt={`${fullName} profile pic`}
                      onClick={() => {
                        navigate(`/users/${userId}`);
                      }}
                    />
                    <div className="user_profile">
                      <h4
                        className="user_name"
                        onClick={() => {
                          navigate(`/users/${userId}`);
                        }}
                      >
                        {fullName}
                      </h4>
                      <h6 className="full_name">
                        <span
                          onClick={() => {
                            navigate(`/users/${userId}`);
                          }}
                        >
                          {userName}
                        </span>
                        {!followingClicked && (
                          <>
                            {userData?.userId !== userId && !isFollowing && (
                              <>
                                <div className="custom-dot">.</div>
                                <button className="follow_btn" onClick={() => handleFollowEvent(video, user.userId, true)}>
                                  {translate('quickplay.follow-btn')}
                                </button>
                              </>
                            )}
                          </>
                        )}
                      </h6>
                    </div>
                  </div>
                </Link>
                {/* Desktop View Icons */}
                <div className="misc_options_wrapper">
                  <Menu as="div" className="relative inline-block text-left">
                    <Menu.Button>
                      <OptionsIcon />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="contextMenu contextMenuArabic absolute right-0 origin-top-left rounded-md cursor-pointer directionRtl">
                        {/* Share Icon */}
                        {(video?.shareEnabled || video?.shareEnabled === true) && (
                          <Menu.Item>
                            <Link
                              to="#"
                              onClick={async event => {
                                event.preventDefault();
                                setShowShare(prev => !prev);
                              }}
                              className="reply_btn reply_actions block"
                            >
                              <div className="btn-wrap">
                                <ShareIcon />
                                <p>{translate('quickplay.label-share')}</p>
                              </div>
                            </Link>
                          </Menu.Item>
                        )}
                        {/* Report Icon */}
                        <Menu.Item>
                          <Link
                            to="#"
                            onClick={async event => {
                              event.preventDefault();
                              if (isLoggedIn) {
                                toggleReportModal();
                              } else {
                                setShowLoginModal(true);
                              }
                            }}
                            className="reply_btn reply_actions block"
                          >
                            <div className="btn-wrap">
                              <ReportIcon />
                              <p>{translate('quickplay.report')}</p>
                            </div>
                          </Link>
                        </Menu.Item>
                        {/* Message User Icon */}
                        <Menu.Item>
                          <Link
                            to="#"
                            onClick={async event => {
                              event.preventDefault();
                              if (isLoggedIn) {
                                redirectToChat();
                              } else {
                                setShowLoginModal(true);
                              }
                            }}
                            className="reply_btn reply_actions block"
                          >
                            <div className="btn-wrap">
                              <MessageIcon />
                              <p>{translate('quickplay.label-message-user')}</p>
                            </div>
                          </Link>
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
              <VideoDescription
                video={video}
                showComments={showComments}
                showMore={showMore}
                setShowMore={setShowMore}
                hashtags={hashtags}
              />
            </div>
            {soundId && (
              <div className="profile_thumbnail">
                <button onClick={soundId ? handleClickOnSound : () => {}} className="tagged-sound">
                  {sound?.thumbnail && <img src={sound?.thumbnail} alt={`${sound?.name}-thumbnail`} />}
                  {!sound?.thumbnail && (
                    <>
                      <div className="icon">
                        <SoundWave />
                      </div>
                      <div></div>
                    </>
                  )}
                  <p>
                    {sound?.artist} <span className="custom-dot">.</span> {sound?.name}
                  </p>
                </button>
              </div>
            )}
            <div className="vertical_comment">
              <div className="flex items-center justify-start gap-16">
                <LikeButton
                  video={video}
                  onClick={handleLikeEvent}
                  isLikingVideo={isLikingVideo}
                  videoLikeCount={videoLikeCount}
                  isVideoLiked={isVideoLiked}
                  isCheckingForLikedVideo={isCheckingForLikedVideo}
                />
                <CommentButton
                  setCommentExpand={setCommentExpand}
                  videoControls={videoControls}
                  videoCommentCount={commentCounts}
                  video={video}
                  videoRef={videoRef}
                />
                <ShareButton video={video} shareCount={shareCounts} setShowShare={setShowShare} />
                <RepostButton video={video} toggleRepostModal={toggleRepostModal} setIsVerifyOpen={setIsVerifyOpen} />
              </div>
              <div className="timeStamp">{videoTimeAgo}</div>
            </div>
          </div>
          <Comments
            video={video}
            commentCount={commentCounts}
            setCommentCount={setCommentCounts}
            setShowComments={setShowComments}
            videoRef={videoRef}
            videoControls={videoControls}
            setShowCommentReportModal={setShowCommentReportModal}
            setCommentPostId={setCommentPostId}
            commentExpand={commentExpand}
            elementHeight={elementHeight}
          />
        </div>
        {showComments && <div className="mobile-backdrop"></div>}
        <RepostModal show={showRepost} onHide={toggleRepostModal} video={video} />
        <Share video={video} setShareCount={setShareCounts} show={showShare} handleClose={() => setShowShare(false)} />
        {showReportModal && (
          <Report
            onHide={toggleReportModal}
            show={showReportModal}
            type="video"
            payloadData={{ userId, assetId: video.videoId }}
            handleClose={toggleReportModal}
            classes="report-modal"
            title={translate('quickplay.label-report-bit')}
            subtitle={translate('quickplay.label-report-bit-description')}
            video={video}
          />
        )}
        {showCommentReportModal && (
          <Report
            show={showCommentReportModal}
            onHide={toggleCommentReportModal}
            type="comment"
            payloadData={{
              userId,
              assetId: video.videoId,
              postId: commentPostId,
            }}
            handleClose={toggleCommentReportModal}
            classes="report-modal"
            title={translate('quickplay.label-report-comment')}
            subtitle={translate('quickplay.label-report-comment-description')}
            video={video}
          />
        )}
        {isVerifyOpen && (
          <VerifyPhoneModal
            type={isPhoneNotAdded ? 'add' : isPhoneNotVerified ? 'verify' : null}
            open={isVerifyOpen}
            onClose={() => setIsVerifyOpen(false)}
          />
        )}
      </div>
    );
  },
);

export default VideoItem;
