import {
  CaptionedImage,
  Card,
  Genders,
  changeUser,
  getUser,
  openSession,
  requestContentCardsRefresh,
  subscribeToContentCardsUpdates,
} from '@braze/web-sdk';
import { genderMapping } from '@fe-monorepo/helper';
import { useAccount, useInvoice, useOrder, useUserProfile } from '@fe-monorepo/hooks';
import {
  ConsentStatus,
  RootState,
  clearCart,
  resetErrorObject,
  setCookieConsent,
  setFingerPrint,
  setInvoice,
  setPersona,
  setShowCookieConsent,
  setThemes,
  store,
  useAppDispatch,
} from '@fe-monorepo/store';
import Loading from '@fe-web/Templates/Loading';
import { generateFingerprint } from 'apps/fe-web/helper/fingerprint';
import { AnimatePresence } from 'framer-motion';
import { BrazeFeedType } from 'libs/models/src/lib/braze';
import moment from 'moment';
import { Suspense, lazy, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { trackEncounteredError, webAnalytics } from '../../helper/webAnalytics';
import DeepLink from '../quickplay-app/components/DeepLink';
import VerticalPlayer from '../quickplay-app/components/VerticalPlayer';
import SoundTrack from '../quickplay-app/components/VerticalPlayer/components/SoundTrack';
import ChallengeDetail from '../quickplay-app/pages/ChallengeDetail';
import ContentDetailPage from '../quickplay-app/pages/ContentDetail';
import CreateContent from '../quickplay-app/pages/CreatePost';
import CreateContentStepTwo from '../quickplay-app/pages/CreatePostStepTwp';
import QPDiscover from '../quickplay-app/pages/Discover';
import HashtagDetail from '../quickplay-app/pages/HashtagDetail';
import LeaderboardDetail from '../quickplay-app/pages/LeaderboardDetail';
import PostCreateStepOnePage from '../quickplay-app/pages/PostCreateStepOne';
import PostCreateStepThreePage from '../quickplay-app/pages/PostCreateStepThree';
import PostCreateStepTwoPage from '../quickplay-app/pages/PostCreateStepTwo';
import { AppRoutes } from './app.routes.enum';
import EmptyPage from './components/Atoms/Empty/EmptyPage';
import NewFooter from './components/Organisms/ShopFooter/Footer';
import convivaHelper from './helpers/convivaHelper';
import mixpanelHelper from './helpers/mixpanelHelper';
import useCookieConsent from './hooks/useCookieConsent';
import usePageLayout from './hooks/usePageLayout';
import useTheme, { theme } from './hooks/useTheme';
import CallbackPage from './pages/CallbackPage';
import FullViewConvo from './pages/Connect/FullViewMessage/FullViewConvo';
import FullViewEmpty from './pages/Connect/FullViewMessage/FullViewEmpty';
import DashboardScreenLayout from './pages/DashboardScreen/Layout';
import MiniGamesDetailsPage from './pages/MiniGames/MiniGamesPages/MiniGamesDetailsPage';
import MiniGamesLandingPage from './pages/MiniGames/MiniGamesPages/MiniGamesLandingPage';
import FullViewNotification from './pages/NotificationCenter/FullViewNotification/FullViewNotification';
import Matchmaking from './pages/PlayPages/Matchmaking';
import PlayerWrapper from './pages/Player/PlayerWrapper';
import SearchPage from './pages/Search';
import CartPage from './pages/ShopPages/CartPage';
import MyOrders from './pages/ShopPages/OrderPage/MyOrders';
import ProductDetailedPage from './pages/ShopPages/ProductDetailedPage/ProductDetailedPage';
import Return from './pages/ShopPages/ReturnOrExchangePage/Return';
import ReturnConfirmation from './pages/ShopPages/ReturnOrExchangePage/ReturnConfirmation';
import ShopHome from './pages/ShopPages/ShopHome';
import ShopLayout from './pages/ShopPages/ShopLayout';
import ShopListing from './pages/ShopPages/ShopListing/ShopListing';
import BlockedAccounts from './pages/UserSettings/BlockedAccounts/BlockedAccounts';
import GenderSettings from './pages/UserSettings/Gender/GenderSettings';
import PhoneNumber from './pages/UserSettings/PhoneNumber/PhoneNumber';

const Home = lazy(() => import('./pages/DashboardScreen/Home'));
const HelpAndSupport = lazy(() => import('./pages/HelpAndSupport'));
const QuestionsSection = lazy(() => import('./pages/Help/QuestionsSection'));
const AuthenticationScreen = lazy(() => import('./pages/AuthenticationScreen/AuthenticationScreen'));
const ShopCategoryPage = lazy(() => import('./pages/ShopMainPage/ShopCategoryPage'));
const UserSettings = lazy(() => import('./pages/UserSettings'));
const MyWallet = lazy(() => import('./pages/UserSettings/MyWallet/MyWallet'));
const MyProfile = lazy(() => import('./pages/UserSettings/MyProfile/MyProfile'));
const Location = lazy(() => import('./pages/UserSettings/Location'));
const Notifications = lazy(() => import('./pages/UserSettings/Notifications'));
const Security = lazy(() => import('./pages/UserSettings/Security'));
const TournamentDetailPage = lazy(() => import('./pages/PlayPages/TournamentDetailPage'));
const PlayLandingPage = lazy(() => import('./pages/PlayPages/PlayLandingPage'));
const ProfilePage = lazy(() => import('./pages/ProfilePage/ProfilePage'));
const Country = lazy(() => import('./pages/UserSettings/LocationAndDisplay/Country'));
const Currency = lazy(() => import('./pages/UserSettings/LocationAndDisplay/Currency'));
const Language = lazy(() => import('./pages/UserSettings/LocationAndDisplay/Language'));
const Timezone = lazy(() => import('./pages/UserSettings/LocationAndDisplay/Timezone'));
const AccountEmail = lazy(() => import('./pages/UserSettings/Account/Email/ChangeEmailPage'));
const AccountPassword = lazy(() => import('./pages/UserSettings/Account/Password/ChangePasswordPage'));
const SavedAddressesMainpage = lazy(() => import('./pages/UserSettings/SavedAddresses/SavedAddressesMainPage'));
const AddSavedAddress = lazy(() => import('./pages/UserSettings/SavedAddresses/AddSavedAddress'));
const ContactUs = lazy(() => import('./pages/ContactUs'));
const ChatPreferences = lazy(() => import('./pages/UserSettings/ChatPreferences/ChatPreferencesPage'));
const NotFoundPage = lazy(() => import('./pages/ErrorPages/NotFoundPage'));

const RegistrationForm = lazy(() => import('./pages/RegistrationForm'));
const GenGPage = lazy(() => import('./pages/GenG'));

//NewFooterPages
const Returns = lazy(() => import('./pages/ShopFooterPages/Returns'));
const WishListOverview = lazy(() => import('./pages/ShopPages/WishList/WishListOverview'));

const EwcPage = lazy(() => import('./pages/EwcPage'));

// const BitsDrafts = lazy(() => import('./pages/ProfilePage/Bits/Drafts/DraftsPage'));

moment.updateLocale('en', {
  // Specify the callback function for
  // customizing the values
  meridiem: (hour, minute, isLowercase) => {
    if (hour >= 12) return isLowercase ? 'pm' : 'PM';
    else return isLowercase ? 'am' : 'AM';
  },
});

const filterAndSortBrazeCardsByFeedType = (cards: Card[], feedType: BrazeFeedType): Card[] => {
  return cards
    .filter(card => card.extras?.feed_type === feedType)
    .sort((a, b) => {
      const sortA = a.extras?.sort_id ? Number(a.extras.sort_id) : 0;
      const sortB = b.extras?.sort_id ? Number(b.extras.sort_id) : 0;
      return sortA - sortB;
    });
};

const PrivateRoute = ({ children }: { children: JSX.Element }) => {
  const token = useSelector((state: RootState) => state.user.userContext.token);
  return token ? (
    <Suspense fallback={<Loading />}>{children}</Suspense>
  ) : (
    <Suspense>
      <Navigate to="/home" />
    </Suspense>
  );
};

const AnimatedRoutes = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { setTheme } = useTheme();
  const { getInvoice, createInvoice } = useInvoice(false);
  const { cancelOrder } = useOrder();
  const { isLoggedIn, isAppLoaded: isAppLoadedProfile } = useUserProfile();
  const { getUserProfile } = useAccount();
  const errorObject = useSelector((state: RootState) => state.app.errorObject);
  const token = useSelector((state: RootState) => state.user.userContext.token);
  const lang = useSelector((state: RootState) => state.app.language);
  const invoiceInfo = useSelector((state: RootState) => state.cart.invoice);
  const lmd_code = useSelector((state: RootState) => state.app.lmdCode);
  const themes = useSelector((state: RootState) => state.app.themes);

  const redirectToEWC = useSelector((state: RootState) => state.app.redirectToEWC);
  const [isAppLoaded, setIsAppLoaded] = useState<boolean>(false);

  const [homePageBrazeCarouselCards, setHomePageBrazeCarouselCards] = useState<Card[] | CaptionedImage[]>([]);

  const { direction, language, handleLanguageToggle } = usePageLayout();
  const { getConsent } = useCookieConsent();
  const showCookieConsent = useSelector((state: RootState) => state.app.showCookieConsent);

  useEffect(() => {
    subscribeToContentCardsUpdates(event => {
      if (event?.cards?.length) {
        setHomePageBrazeCarouselCards(filterAndSortBrazeCardsByFeedType(event.cards, 'home_page_carousel'));
      }
    });

    requestContentCardsRefresh();
  }, []);

  useEffect(() => {
    const htmlElement = document.documentElement;

    if (htmlElement.dir !== direction) {
      htmlElement.setAttribute('dir', direction);
    }

    if (htmlElement.lang !== language) {
      htmlElement.setAttribute('lang', language);
    }
  }, [direction, language]);

  useEffect(() => {
    const fetchUserProfile = async () => {
      const res = await getUserProfile();
      if (res?.data?.getUserProfileInfo?.data?.uuid) {
        const { data } = res.data.getUserProfileInfo;
        mixpanelHelper.setIdentity(data.uuid);
        convivaHelper.setIdentity(data.uuid);
        changeUser(data.uuid);
        mixpanelHelper.setUserProperties({
          $name: data.display_name,
          $avatar: data.avatar_url,
          Username: data.username,
          'Is Email Verified': Boolean(data.is_email_verified),
          'Is Mobile Verified': Boolean(data.is_mobile_verified),
        });
        if (data.email) {
          getUser()?.setEmail(data.email);
        }
        if (data.mobile) {
          getUser()?.setPhoneNumber(`+${data.mobile}`);
        }
        getUser()?.setCustomUserAttribute('Is Email Verified', Boolean(data.is_email_verified));
        getUser()?.setCustomUserAttribute('Is Mobile Verified', Boolean(data.is_mobile_verified));
        if (data.birthdate) {
          const date = new Date(data.birthdate);
          const year = date.getUTCFullYear();
          const month = date.getUTCMonth() + 1;
          const day = date.getUTCDate();
          getUser()?.setDateOfBirth(year, month, day);
        }
        if (data.preferred_language) {
          getUser()?.setLanguage(data.preferred_language);
        }
        if (data.gender_code) {
          getUser()?.setGender(genderMapping[data.gender_code] as Genders);
        }
        if (data.country_code) {
          getUser()?.setCountry(data.country_code);
        }
      }
    };

    if (isAppLoadedProfile) {
      if (isLoggedIn) {
        fetchUserProfile();
      } else {
        convivaHelper.setIdentity('GUEST');
      }
      openSession();
    }
  }, [isAppLoadedProfile]);

  useEffect(() => {
    if (!lang) return;
    moment.locale(lang === 'en' ? 'en' : 'ar-mine');
    if (lang === 'ar') {
      moment.defineLocale('ar-mine', {
        parentLocale: 'ar',
        preparse: (string: string) => string,
        postformat: (string: string) => string,
      });
    }
  }, [lang]);

  useEffect(() => {
    dispatch(setPersona(token ? 'existing' : 'guest'));
  }, [token]);

  useEffect(() => {
    webAnalytics({ name: 'screen_view', properties: {} });
  }, [location]);

  useEffect(() => {
    if (errorObject) {
      trackEncounteredError(errorObject);
      dispatch(resetErrorObject());
    }
  }, [errorObject]);

  useEffect(() => {
    if (
      lmd_code.lmd_code &&
      token &&
      !isAppLoaded &&
      !location.pathname.includes('payment') &&
      !location.pathname.includes('successful') &&
      !location.pathname.includes('failed')
    ) {
      setIsAppLoaded(true);
      if (invoiceInfo) {
        getInvoice({
          details: {
            invoice_number: invoiceInfo.invoice_number,
          },
        }).then(response => {
          if (response?.data?.info) {
            if (response.data.info.status_code === 'pending' && response.data.info.is_checkout === 1) {
              cancelOrder({ invoice_number: response.data.info.invoice_number }).then(cancelresp => {
                if (cancelresp?.is_successful) {
                  const new_invoice = {
                    ...response.data.info,
                  };
                  new_invoice.is_checkout = 0;
                  dispatch(setInvoice(new_invoice));
                }
              });
            } else if (response.data.info.status_code !== 'pending' && lmd_code?.lmd_code && response.data.info.is_checkout === 1) {
              createInvoice({
                lmd_code: lmd_code.lmd_code,
              }).then(response2 => {
                if (response2?.is_successful && response2?.data?.invoice_number) {
                  getInvoice({
                    details: {
                      invoice_number: response2.data.invoice_number,
                    },
                  }).then(invoiceresponse => {
                    if (invoiceresponse?.data?.info) {
                      dispatch(setInvoice(invoiceresponse.data.info));
                    }
                  });
                }
              });
            } else if (response.data.info.status_code === 'pending') {
              dispatch(setInvoice(response.data.info));
            }
          }
        });
      } else {
        if (!lmd_code.lmd_code) return;
        createInvoice({
          lmd_code: lmd_code.lmd_code,
        }).then(response2 => {
          if (response2?.is_successful && response2?.data?.invoice_number) {
            getInvoice({
              details: {
                invoice_number: response2.data.invoice_number,
              },
            }).then(invoiceresponse => {
              if (invoiceresponse?.data?.info) {
                dispatch(setInvoice(invoiceresponse.data.info));
              }
            });
          }
        });
      }
    }
  }, [isAppLoaded, lmd_code.lmd_code, token]);

  const fingerprint = store.getState().app.fingerPrint;

  useEffect(() => {
    const storeFingerprint = async () => {
      if (fingerprint) return;
      const fingerprintHex = await generateFingerprint();
      if (fingerprintHex) {
        dispatch(setFingerPrint(fingerprintHex));
      }
    };

    storeFingerprint();
  }, []);
  const currentCookieConsent = getConsent() as ConsentStatus;
  const currentTheme = (window.localStorage.getItem('theme') as theme) ?? theme.light;
  const consentSpecificTheme = currentCookieConsent === 'accepted' ? currentTheme : theme.light;
  const themeUpdateRef = useRef(0);
  useEffect(() => {
    dispatch(setCookieConsent(currentCookieConsent));
    dispatch(setShowCookieConsent(currentCookieConsent !== 'accepted'));

    setTheme(consentSpecificTheme);
    dispatch(setThemes(consentSpecificTheme));
  }, []);

  useEffect(() => {
    if (showCookieConsent) {
      dispatch(clearCart());
      localStorage.removeItem('cardImages_');
      localStorage.removeItem('selectedCountry');
      localStorage.removeItem('selectedTimezone');
      localStorage.removeItem('selectedCurrency');
      handleLanguageToggle('en');
    }
  }, [showCookieConsent]);

  const shouldUpdateTheme =
    (location.pathname.includes('shop') && !location.pathname.includes('wishlist') && !location.pathname.includes('shopping')) ||
    location.pathname.includes('payment');

  useEffect(() => {
    if (themeUpdateRef.current !== 0) {
      setTheme(themes as theme);
    }
    themeUpdateRef.current = themeUpdateRef.current + 1;
  }, [shouldUpdateTheme]);
  return (
    <AnimatePresence>
      <Routes location={location} key={location.pathname.includes('auth') ? 'auth' : location.pathname}>
        <Route path="/" element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            index={true}
            element={
              <Suspense fallback={<Loading />}>
                {redirectToEWC ? <EwcPage /> : <Home carouselCards={homePageBrazeCarouselCards} />}
              </Suspense>
            }
          />

          {/* Home section */}
          <Route
            path={AppRoutes.home}
            element={
              <Suspense fallback={<Loading />}>
                <Home carouselCards={homePageBrazeCarouselCards} />
              </Suspense>
            }
          />
        </Route>

        {/* Quickplay routes start */}
        <Route path={AppRoutes.bits} element={<DashboardScreenLayout footer={null} isFullScreen={true} />}>
          <Route path="" element={<VerticalPlayer />} />
          <Route path="discover" element={<QPDiscover />} />
          <Route path="create-post" element={<CreateContent />} />
          <Route path="create-post-page-02" element={<CreateContentStepTwo />} />
          <Route path="hashtag/:hashtagName" element={<HashtagDetail />} />
          <Route path="challenge/:challengeName" element={<ChallengeDetail />} />
          <Route path="feed" element={<DeepLink />} />
          <Route path="create-post/step-1" element={<PostCreateStepOnePage />} />
          <Route path="create-post/step-2" element={<PostCreateStepTwoPage />} />
          <Route path="create-post/step-3" element={<PostCreateStepThreePage />} />
          <Route path="sound-track/:soundId" element={<SoundTrack />} />
          <Route path="challengeleaderboard/:challengeId" element={<LeaderboardDetail />} />
          <Route path="content/:contentId" element={<ContentDetailPage />} />
        </Route>
        {/* Quickplay routes end */}

        {/* Quickplay routes without footer start */}
        <Route path={AppRoutes.bitsPlay} element={<DashboardScreenLayout footer={null} isFullScreen={true} />}>
          <Route path="content/:contentId" element={<ContentDetailPage />} />
        </Route>
        {/* Quickplay routes without footer end */}

        {/* Shop section */}
        <Route path={AppRoutes.shop} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            path=""
            element={
              <Suspense>
                <ShopLayout />
              </Suspense>
            }
          >
            <Route
              index={true}
              element={
                <Suspense>
                  <ShopHome />
                </Suspense>
              }
            />
            <Route
              path="brandlist"
              element={
                <Suspense>
                  <ShopCategoryPage />
                </Suspense>
              }
            />
            <Route
              path="cart"
              element={
                <Suspense>
                  <CartPage />
                </Suspense>
              }
            />
            <Route
              path="wishlist"
              element={
                <Suspense>
                  <PrivateRoute>
                    <WishListOverview />
                  </PrivateRoute>
                </Suspense>
              }
            />
          </Route>
        </Route>
        {/* Messages */}
        <Route path={AppRoutes.messages} element={<DashboardScreenLayout className="min-h-0" />}>
          <Route
            index={true}
            element={
              <PrivateRoute>
                <FullViewEmpty />
              </PrivateRoute>
            }
          />
          <Route
            path=":conversationId/:conversationType"
            element={
              <PrivateRoute>
                <FullViewConvo />
              </PrivateRoute>
            }
          />
          <Route
            path=":conversationId" //NEW CHAT FULL VIEW
            element={
              <PrivateRoute>
                <FullViewConvo />
              </PrivateRoute>
            }
          />
        </Route>
        {/* Notifications */}
        <Route path={AppRoutes.notifications} element={<DashboardScreenLayout className="min-h-0" />}>
          <Route
            path=""
            element={
              <PrivateRoute>
                <FullViewNotification />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path={AppRoutes.settingsRoot} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            element={
              <Suspense>
                <UserSettings />
              </Suspense>
            }
          >
            <Route
              path="my-profile"
              element={
                <PrivateRoute>
                  <MyProfile />
                </PrivateRoute>
              }
            />
            <Route
              path="gender"
              element={
                <PrivateRoute>
                  <GenderSettings />
                </PrivateRoute>
              }
            />
            <Route
              path="my-orders"
              element={
                <PrivateRoute>
                  <MyOrders />
                </PrivateRoute>
              }
            />
            <Route
              path="my-returns"
              element={
                <PrivateRoute>
                  <MyOrders />
                </PrivateRoute>
              }
            />
            <Route
              path="language"
              element={
                <Suspense fallback={<Loading />}>
                  <Language />
                </Suspense>
              }
            />
            <Route
              path="country"
              element={
                <Suspense fallback={<Loading />}>
                  <Country />
                </Suspense>
              }
            />
            <Route
              path="timezone"
              element={
                <PrivateRoute>
                  <Timezone />
                </PrivateRoute>
              }
            />
            <Route
              path="currency"
              element={
                <Suspense fallback={<Loading />}>
                  <Currency />
                </Suspense>
              }
            />
            <Route
              path="wallet"
              element={
                <PrivateRoute>
                  <MyWallet />
                </PrivateRoute>
              }
            />
            <Route
              path="email"
              element={
                <PrivateRoute>
                  <AccountEmail />
                </PrivateRoute>
              }
            />
            <Route
              path="password"
              element={
                <PrivateRoute>
                  <AccountPassword />
                </PrivateRoute>
              }
            />
            <Route
              path="phone-number"
              element={
                <PrivateRoute>
                  <PhoneNumber />
                </PrivateRoute>
              }
            />
            <Route
              path="language"
              element={
                <Suspense fallback={<Loading />}>
                  <Language />
                </Suspense>
              }
            />
            <Route
              path="country"
              element={
                <Suspense fallback={<Loading />}>
                  <Country />
                </Suspense>
              }
            />
            <Route
              path="timezone"
              element={
                <Suspense fallback={<Loading />}>
                  <Timezone />
                </Suspense>
              }
            />
            <Route
              path="currency"
              element={
                <Suspense fallback={<Loading />}>
                  <Currency />
                </Suspense>
              }
            />
            <Route
              path="my-profile"
              element={
                <Suspense>
                  <MyProfile />
                </Suspense>
              }
            />
            <Route
              path="location"
              element={
                <Suspense>
                  <Location />
                </Suspense>
              }
            />
            <Route
              path="notifications"
              element={
                <Suspense>
                  <Notifications />
                </Suspense>
              }
            />
            <Route
              path="2fa"
              element={
                <PrivateRoute>
                  <Security />
                </PrivateRoute>
              }
            />
            <Route
              path={AppRoutes.savedaddressRoot}
              element={
                <PrivateRoute>
                  <SavedAddressesMainpage />
                </PrivateRoute>
              }
            />
            <Route
              path="chat-preferences"
              element={
                <PrivateRoute>
                  <ChatPreferences />
                </PrivateRoute>
              }
            />
            {/* This is a temporary fix for the pathing, need to find a way to nest this inside savedaddresses */}
            <Route
              path={`${AppRoutes.savedaddressRoot}/add`}
              element={
                <PrivateRoute>
                  <AddSavedAddress />
                </PrivateRoute>
              }
            />
            {/* This is a temporary fix for the pathing, need to find a way to nest this inside savedaddresses */}
            <Route
              path={`${AppRoutes.savedaddressRoot}/edit/:addressId`}
              element={
                <PrivateRoute>
                  <AddSavedAddress />
                </PrivateRoute>
              }
            />
            <Route
              path="blocked-accounts"
              element={
                <PrivateRoute>
                  <BlockedAccounts />
                </PrivateRoute>
              }
            />
          </Route>
        </Route>
        {/* For the Return or Exchange Page */}
        <Route path={AppRoutes.myorders + '/return/:invoiceNumber'} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            index={true}
            element={
              <PrivateRoute>
                <Return />
              </PrivateRoute>
            }
          />
          <Route
            path="confirmation"
            element={
              <PrivateRoute>
                <ReturnConfirmation />
              </PrivateRoute>
            }
          />
        </Route>
        <Route path={AppRoutes.shop} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            path=""
            element={
              <Suspense>
                <ShopLayout />
              </Suspense>
            }
          >
            <Route
              index={true}
              element={
                <Suspense>
                  <ShopListing />
                </Suspense>
              }
            />
            <Route
              path=":collectionCode"
              index={true}
              element={
                <Suspense>
                  <ShopListing />
                </Suspense>
              }
            />
            <Route path=":collectionCode/:productID" element={<ProductDetailedPage />} />
          </Route>
        </Route>
        <Route path={AppRoutes.shop} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            path=""
            element={
              <Suspense>
                <ShopLayout />
              </Suspense>
            }
          >
            <Route
              index={true}
              element={
                <Suspense>
                  <ShopListing />
                </Suspense>
              }
            />
            <Route
              path=":collectionCode"
              index={true}
              element={
                <Suspense>
                  <ShopListing />
                </Suspense>
              }
            />
            <Route path=":collectionCode/:productID" element={<ProductDetailedPage />} />
          </Route>
        </Route>
        <Route path={AppRoutes.users} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            index={true}
            element={
              <Suspense fallback={<Loading />}>
                <NotFoundPage hasHeader={false} hasFooter={false} />
              </Suspense>
            }
          />
          <Route
            path=":username"
            element={
              <Suspense fallback={<Loading />}>
                <ProfilePage />
              </Suspense>
            }
          />
          {/* <Route
            path=":username/bits/drafts"
            element={
              <Suspense fallback={<Loading />}>
                <BitsDrafts />
              </Suspense>
            }
          /> */}
        </Route>

        {/* Tournaments section */}
        <Route path={AppRoutes.ewc} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            index={true}
            element={
              <Suspense fallback={<Loading />}>
                <EwcPage />
              </Suspense>
            }
          />
        </Route>

        <Route path={AppRoutes.play} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            path="tournaments/create"
            element={
              <Suspense>
                <Navigate to="/*" />
              </Suspense>
            }
          />

          <Route
            path="tournaments/edit"
            element={
              <Suspense>
                <Navigate to="/*" />
              </Suspense>
            }
          />

          <Route
            path="tournaments/:tournamentID"
            element={
              <Suspense fallback={<Loading />}>
                <TournamentDetailPage />
              </Suspense>
            }
          />

          <Route
            path=""
            element={
              <Suspense fallback={<Loading />}>
                <Navigate to={AppRoutes.play + '/tournaments'} />
              </Suspense>
            }
          />

          <Route
            path="tournaments"
            element={
              <Suspense fallback={<Loading />}>
                <PlayLandingPage />
              </Suspense>
            }
          />

          <Route
            path="matchmaking"
            element={
              <Suspense fallback={<Loading />}>
                <Matchmaking />
              </Suspense>
            }
          />

          <Route
            path="games"
            element={
              <Suspense fallback={<Loading />}>
                <MiniGamesLandingPage />
              </Suspense>
            }
          />
          <Route
            path="games/:miniGameID"
            element={
              <Suspense fallback={<Loading />}>
                <MiniGamesDetailsPage />
              </Suspense>
            }
          />
        </Route>

        <Route path={AppRoutes.video} element={<PlayerWrapper />} />
        <Route path={AppRoutes.callback} element={<CallbackPage />} />

        {/* Auth section */}
        <Route
          path={AppRoutes.auth}
          element={
            <Suspense fallback={<Loading />}>
              <AuthenticationScreen />
            </Suspense>
          }
        />

        <Route path={AppRoutes.search} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            index={true}
            element={
              <Suspense fallback={<Loading />}>
                <SearchPage />
              </Suspense>
            }
          />
        </Route>

        <Route path={AppRoutes.help} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            // index={true}
            path=""
            element={
              <Suspense fallback={<Loading />}>
                <HelpAndSupport />
              </Suspense>
            }
          >
            <Route
              path=":categoryId"
              element={
                <Suspense fallback={<Loading />}>
                  <QuestionsSection />
                </Suspense>
              }
            />
          </Route>
        </Route>

        <Route path={AppRoutes.contactUS} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            index={true}
            element={
              <Suspense fallback={<Loading />}>
                <ContactUs />
              </Suspense>
            }
          />
        </Route>
        <Route path={AppRoutes.userProfile} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            index={true}
            element={
              <Suspense fallback={<Loading />}>
                <EmptyPage />
              </Suspense>
            }
          />
          <Route
            path="settings"
            element={
              <Suspense>
                <UserSettings />
              </Suspense>
            }
          />
        </Route>

        {/* Registration form */}
        <Route path={AppRoutes.registrationForm} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            index={true}
            element={
              <Suspense fallback={<Loading />}>
                <RegistrationForm />
              </Suspense>
            }
          />
        </Route>

        {/* Gen G Landing Page */}
        <Route path={AppRoutes.genGLandingPage} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            index={true}
            element={
              <Suspense fallback={<Loading />}>
                <GenGPage />
              </Suspense>
            }
          />
        </Route>

        {/* Shop Footer Section */}
        <Route path={'/'} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            path={AppRoutes.returns}
            element={
              <Suspense fallback={<Loading />}>
                <Returns />
              </Suspense>
            }
          />
        </Route>

        {/* Msc section */}
        <Route
          path="*"
          index
          element={
            <Suspense>
              <NotFoundPage />
            </Suspense>
          }
        />
        {/* for callback url or payment integration*/}
        <Route path={AppRoutes.cartCallback} element={<DashboardScreenLayout footer={<NewFooter />} />}>
          <Route
            path=""
            element={
              <Suspense>
                <ShopLayout />
              </Suspense>
            }
          >
            <Route
              index={true}
              element={
                <Suspense>
                  <CartPage />
                </Suspense>
              }
            />
          </Route>
        </Route>
      </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoutes;
