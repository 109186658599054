import { IMAGES } from '@fe-monorepo/assets';
import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Modal from '@fe-web/Templates/Modal/Modal';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import useOutsideClick from '@fe-web/hooks/useOutsideClick';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';

interface GuestModalProps {
  isOpen: boolean;
  onNotNow: () => void;
  onSignIn: () => void;
}

const GuestModal = (props: GuestModalProps) => {
  const { isOpen, onNotNow, onSignIn } = props;
  const { translate } = useTranslate();
  const themes = useSelector((state: RootState) => state.app.themes);
  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  const isMobile = useMobileDetect();
  const guestReminder = useSelector((state: RootState) => state.app.guestReminder);

  const onClose = () => {
    if (onNotNow instanceof Function) {
      onNotNow();
    }
  };
  const elementRef = useOutsideClick(() => {
    onClose();
  });

  if (!guestReminder) {
    return null;
  }

  const { title_ar, title_en, body_ar, body_en } = guestReminder;

  return (
    <Modal open={isOpen} backdropClassName="backdrop-blur">
      <div
        ref={ref => (elementRef.current = ref)}
        className="flex flex-col items-center
          w-[90%] h-[31.25rem] SD:w-[36.75rem] SD:h-[30rem]  
        bg-[#4F008C] self-center px-[2rem] py-[1.5rem] rounded-[0.5rem] relative"
      >
        <div className="absolute right-[2rem]">
          <ResponsiveIcon
            name={IconNames.close}
            baseWidth={15}
            baseHeight={15}
            className="z-20 flex fill-white hover:cursor-pointer"
            onClick={onClose}
          />
        </div>
        <div className="mt-[2rem]">
          <LazyLoadImage src={IMAGES.EwcDontBeAStrangerImage.toString()} className="max-h-[10rem]" />
        </div>
        <div className="flex flex-col w-full pt-[12px] pb-[2rem] gap-[1.5rem] text-center">
          <h1 className="text-white font-medium text-fs-subtitle SD:text-fs-big-title">{isRTL ? title_ar : title_en}</h1>
          <p className="text-white font-regular text-fs-body-large self-center SD:w-[25.9375rem]">{isRTL ? body_ar : body_en}</p>
        </div>
        <div className="flex flex-col-reverse w-full md:flex-row justify-center gap-[1.25rem] pt-[1rem] pb-[2.5rem]">
          <Button
            text={translate('tournament_status_bar.notnow')}
            style={`text-base not-italic max-h-[2.5rem] border-[0.063rem] border-white font-medium
              ${isMobile ? 'w-full px-[1rem] py-[0.5rem]' : 'w-fit px-[1.5rem] py-[0.5rem]'}  
              gap-[0.5rem] z-1
              text-white mb-[0px]
              xsMax:mb-[0px] items-end
              rounded-[0.188rem] 
            rounded-[0.188rem]`}
            normalStyle={`
              ${isRTL ? 'before:bg-[#4F008C] after:bg-sunset' : 'after:bg-[#4F008C] before:bg-sunset'}
            hover:border-sunset`}
            onClick={onClose}
          />
          <Button
            text={translate('common_signin_card')}
            style={`text-base not-italic max-h-[2.5rem] font-medium
              ${isMobile ? 'w-full px-[1rem] py-[0.5rem]' : 'w-fit px-[1.5rem] py-[0.5rem]'}  gap-[0.5rem] z-1
              text-white border-sunset border-[0.063rem] mb-[0px]
              xsMax:mb-[0px] items-end
            rounded-[0.188rem] rounded-[0.188rem]`}
            disabledStyle={`${themes === 'dark' ? 'bg-[#2E2D30] border-[#2E2D30]' : 'bg-black10'} `}
            normalStyle={`
            ${isRTL ? 'before:bg-sunset after:bg-[#4F008C]' : 'after:bg-sunset before:bg-[#4F008C]'}
            hover:border-white`}
            onClick={onSignIn}
          />
        </div>
      </div>
    </Modal>
  );
};

export default GuestModal;
