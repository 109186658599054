import { IMAGES } from '@fe-monorepo/assets';
import { IMAGE_ALT_LABELS, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';

import Image from '../../../components/Atoms/Media/Image';

interface TailoredSectionProps {
  classNames?: string;
  hasTitle?: boolean;
  isMobile?: boolean;
}

const TailoredSection = ({ classNames, hasTitle, isMobile }: TailoredSectionProps) => {
  const { translate } = useTranslate();

  return (
    <div className={`flex flex-col items-start gap-[2.5rem] ${classNames} ${isMobile && 'grid grid-cols-2'}`}>
      {hasTitle && (
        <div className={`flex w-full justify-between items-baseline ${isMobile && 'col-span-2'}`}>
          <p className="inline-flex items-center font-medium text-fs-big-title text-secondary gap-x-24">
            {translate('tailored_section.tailoredHeader')}
          </p>
        </div>
      )}
      <div className={`flex flex-start gap-[2.5rem] w-full text-black/[.70] ${isMobile && 'grid grid-cols-2 col-span-2'}`}>
        <div className="flex flex-col items-start gap-[0.5rem] basis-[25%] flex-[0_0_0%] pl-0 ">
          <Image
            alt={translate(IMAGE_ALT_LABELS.shop_tailored_delivery_icon as TxKeyPath) ?? ''}
            img={IMAGES.Icon_Shipping}
            divStyle="bg-tail"
            imgStyle="h-[2.5rem] w-[2.5rem]"
          />
          <p className="text-fs-body-large font-medium text-secondary mt-[0.5rem]">
            {translate('tailored_section.delivery.delivery_header')}
          </p>
          <p className="text-fs-body font-regular text-secondary">
            {translate('tailored_section.delivery.enjoy')}{' '}
            <span className="text-purple">{translate('tailored_section.delivery.delivery_oneday')}</span>{' '}
            {translate('tailored_section.delivery.fromstcplay')}{' '}
            <span className="text-purple">{translate('tailored_section.delivery.freedelivery')}</span>,{' '}
            {translate('tailored_section.delivery.and')}{' '}
            <span className="text-purple">{translate('tailored_section.delivery.pickup')}</span>
          </p>
        </div>
        <div className="flex flex-col items-start gap-[0.5rem] basis-[20%] flex-[0_0_0%] pl-0 ">
          <Image
            alt={translate(IMAGE_ALT_LABELS.shop_tailored_installments as TxKeyPath) ?? ''}
            img={IMAGES.Icon_Bill_v2}
            divStyle="bg-tail"
            imgStyle="h-[2.5rem] w-[2.5rem]"
          />
          <p className="text-fs-body-large font-medium text-secondary mt-[0.5rem]">
            {translate('tailored_section.installment.installment_title')}
          </p>
          <p className="text-fs-body font-regular text-secondary">{translate('tailored_section.installment.installment_text')}</p>
        </div>
        <div className="flex flex-col items-start gap-[0.5rem] basis-[25%] flex-[0_0_0%] pl-0 ">
          <Image
            alt={translate(IMAGE_ALT_LABELS.shop_tailored_returns as TxKeyPath) ?? ''}
            img={IMAGES.Icon_Box}
            divStyle="bg-tail"
            imgStyle="h-[2.5rem] w-[2.5rem]"
          />
          <p className="text-fs-body-large font-medium text-secondary mt-[0.5rem]">{translate('tailored_section.returns.returns_title')}</p>
          <p className="text-fs-body font-regular text-secondary">
            {translate('tailored_section.returns.returns_free')}{' '}
            <span className="text-purple">{translate('tailored_section.returns.returns_trans')}</span>{' '}
            {translate('tailored_section.returns.returns_end')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TailoredSection;
