enum CommonEvents {
  AppOpen = 'App Open',
  PageView = 'Page View',

  // Shop start
  ProductViewed = 'Product Viewed',
  ProductBuyNow = 'Product Buy Now',
  ProductAddedToCart = 'Product Added To Cart',
  CartViewed = 'Cart Viewed',
  CartError = 'Cart Error',
  DeliveryAndPayment = 'Delivery & Payment',
  ProductAddedToWishlist = 'Product Added To Wishlist',
  AddressOnDeliveryAndPaymentPage = 'Address On Delivery & Payment Page',
  OrderPlaced = 'Order Placed',
  LookForSimilarProducts = 'Look For Similar Products',
  LearnMoreDelivery = 'Learn More (Delivery)',
  LearnMoreReturns = 'Learn More (Returns)',
  AddQuantity = 'Add Quantity',
  RemoveQuantity = 'Remove Quantity',
  PaymentMethodSelected = 'Payment Method Selected',
  OrderSubmitted = 'Order Submitted',
  CouponApplied = 'Coupon Applied',
  ProductQuantityAddedToCart = 'Product Quantity Added To Cart',
  ProductQuantityRemovedFromCart = 'Product Quantity Removed From Cart',
  // Shop end

  // Games start
  GameViewed = 'Game Viewed',
  StartedMiniGame = 'Game Play Started',
  ClosedMiniGame = 'Game Play Closed',
  GetMatched = 'Get Matched',
  MatchedGamer = 'Matched Gamer',
  MatchedRejected = 'Matched Rejected',
  // Games end

  // Bits start
  BitViewed = 'Bit Viewed',
  BitCreationStarted = 'Bit Creation Started',
  BitSubmitted = 'Bit Submitted',
  BitsLiked = 'Bits Liked',
  BitsCommented = 'Bits Commented',
  BitsShared = 'Bits Shared',
  BitsReported = 'Bits Reported',
  // Bits end

  // Auth start
  SignupStarted = 'Sign Up Started',
  SignupMethodSubmitted = 'Signup Method Submitted',
  SignUpUsernameSubmitted = 'Sign Up Username Submitted',
  OtpSubmitted = 'OTP Submitted',
  LoginStarted = 'Login Started',
  LoginSubmitted = 'Login Submitted',
  // Auth end
}

export enum BrazeOnlyEvents {
  ScreenView = 'screen_view',
  ClickedAppLink = 'clicked_app_link',
  SignUp = 'signup',
  FailedSignUp = 'failed_signup',
  ConfirmedOTP = 'confirmed_otp',
  FailedOTP = 'failed_otp',
  Login = 'login',
  FailedLogin = 'failed_login',
  Search = 'search',
  SubscribedNewsLetter = 'subscribed_newsletter',
  ViewShopItemsList = 'view_items_list',
  ViewShopItem = 'view_item',
  AddedItemToCart = 'add_to_cart',
  ViewedCart = 'view_cart',
  RemovedItemFromCart = 'remove_from_cart',
  BeginsCheckout = 'begin_checkout',
  AddedAddress = 'add_shipping_info',
  AddedPaymentDetails = 'add_payment_info',
  PurchaseSuccessful = 'purchase',
  PurchaseFailed = 'failed_purchase',
  RequestForReturn = 'refund',
  ErrorEncountered = 'encountered_error',
  ViewedTournament = 'viewed_tournament',
  JoinedTournament = 'joined_tournament',
  CompletedTournament = 'completed_tournament',
  StartedMatchmaking = 'started_matchmaking',
  FoundMatch = 'found_match',
  FailedMatchmaking = 'failed_matchmaking',
  SelectedMiniGame = 'selected_minigame',
  EndedMiniGame = 'ended_minigame',
}

export enum MixPanelOnlyEvents {
  DeleteAccount = 'Delete User Account',
  GenGSignIn = 'Gen. G Sign-In',
  GenGFormSubmit = 'Gen. G Form Submitted',
  GenGBannerClick = 'Banner Click',
  WatchNow = 'Watch Now - Stream Tab',
  StreamPlay = 'Stream Play',
  BannersButtonCTA = 'Banners / Button CTA',
  ConsentPopupWindowViewed = 'Consent Popup Window Viewed',
  AcceptAndContinueButton = 'Accept And Continue Button',
  ConsentRejected = 'Consent Rejected',
  BannerViewed = 'Banner Viewed',
  CTAClickedByProduct = 'CTA Clicked (By Product)',
  CTAClickedByCategories = 'CTA Clicked (By Categories)',
  ShopSearchPerformed = 'Shop Search Performed',
  ProductClickedViaBannerOrSlide = 'Product Clicked (via Banner or Slide)',
  ProductViewedViaBanner = 'Product Viewed via Banner',
  NoResultsFound = 'No Results Found',
  RefundRequested = 'Refund Requested',

  // Connect Events
  ConnectPageView = 'Connect Page Opened',
  ChatCreationStarted = 'Chat Creation Started',
  NewChatCreated = 'New Chat Created',
  NewMessageEvent = 'New Chat Message',
  ChatWindowOpened = 'Existed Chat Window Opened',
  //Cookies
  CookiesPopupAcceptAll = 'Cookies Pop Up - Accept All',
  CookiesPopupRejectAll = 'Cookies Pop Up - Reject All',
}

export const BrazeCustomEvents = { ...CommonEvents, ...BrazeOnlyEvents };
export const MixPanelCustomEvents = { ...CommonEvents, ...MixPanelOnlyEvents };
