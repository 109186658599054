import { gql } from '@apollo/client';

export const REPORT_MUTATION = {
  createReport: gql`
    mutation createReport($details: CreateReportInput) {
      createReport(details: $details) {
        is_successful
        error_msg
        app_code
        error_code
      }
    }
  `,
};
