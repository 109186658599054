import { gql } from '@apollo/client';

export const RETURN_DATA_MUTATION = {
  omsCreateReturn: gql`
    mutation omsCreateReturn($details: OmsReturnCreateInput) {
      omsCreateReturn(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          return_number
        }
      }
    }
  `,
};
