import { FilterModel, GiftCardInfoModel, WalletModel, WalletTransactionListModel } from '@fe-monorepo/models';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type SliceState = {
  userWallet: WalletModel;
  walletHistoryList: WalletTransactionListModel[];
  giftCardInfo: GiftCardInfoModel;
  filterWallet: FilterModel;
  error: string | undefined;
};

const userWallet = {
  entity_type: '',
  wallet_number: '',
  total_balance: '',
  created_at: '',
  error: '',
};
const filterData = { period: null, from: null, to: null, amounts: { min: '', max: '' }, types: {} };

const giftCardInfo = { amount: 0, status_code: '', expiration_date: '' };

const initialState: SliceState = {
  userWallet: userWallet,
  filterWallet: filterData,
  walletHistoryList: [],
  giftCardInfo: giftCardInfo,
  error: '',
};

export const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    clearWallet: () => {
      return initialState;
    },
    setWallet: (state, action: PayloadAction<WalletModel>) => {
      state.userWallet = action.payload;
      return state;
    },

    setFilterData: (state, action: PayloadAction<FilterModel>) => {
      state.filterWallet = { ...state.filterWallet, ...action.payload };
      return state;
    },
    resetFilterData: state => {
      state.filterWallet = initialState.filterWallet;
      return state;
    },
    setWalletHistoryList: (state, action: PayloadAction<{ data: WalletTransactionListModel[]; isLoadMore?: boolean }>) => {
      const { data, isLoadMore } = action.payload;
      let payload;
      if (isLoadMore) {
        payload = state.walletHistoryList.concat(data);
        state.walletHistoryList = payload;
      } else {
        state.walletHistoryList = data;
      }
      return state;
    },
    setGiftCardInfo: (state, action: PayloadAction<GiftCardInfoModel>) => {
      state.giftCardInfo = action.payload;
      return state;
    },
    setError: (state, action: PayloadAction<string | undefined>) => {
      state.error = action.payload;
      return state;
    },
  },
});

export const { setWallet, clearWallet, setFilterData, resetFilterData, setWalletHistoryList, setGiftCardInfo, setError } =
  walletSlice.actions;
