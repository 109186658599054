import { useMutation } from '@apollo/client';
import { CHECKIN_MUTATION } from '@fe-monorepo/data-access';
import { useEffect, useState } from 'react';

import { CheckInTournamentParamsModel } from './type';

export const useCheckInTournament = () => {
  const [isSuccessful, setStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [checkInTournament, { data: checkInTournamentResponse, error: errorCheckInTournament }] = useMutation(
    CHECKIN_MUTATION.checkInTournament,
    {
      errorPolicy: 'all',
    },
  );

  useEffect(() => {
    if (checkInTournamentResponse) {
      if (checkInTournamentResponse.checkInTournament.is_successful) {
        setStatus(checkInTournamentResponse.checkInTournament.is_successful);
      }
      if (checkInTournamentResponse.checkInTournament.error_msg) {
        setErrorMessage(checkInTournamentResponse.checkInTournament.error_msg);
      }
      setStatus(checkInTournamentResponse.checkInTournament.is_successful);
    }
    setIsLoading(false);
  }, [checkInTournamentResponse]);

  useEffect(() => {
    if (errorCheckInTournament) {
      setError(errorCheckInTournament.message);
    }
    setIsLoading(false);
  }, [errorCheckInTournament]);

  const checkInTheTournament = async (params: CheckInTournamentParamsModel) => {
    setIsLoading(true);
    const response = await checkInTournament({ variables: { details: params } });
    setIsLoading(false);
    return response;
  };

  return {
    checkInTheTournament,
    isSuccessful,
    isLoading,
    error,
    errorMessage,
  };
};
