function NotificationIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
      <g id="Group_24041" data-name="Group 24041" transform="translate(-6623 -2086)">
        <rect
          id="Rectangle_12670"
          data-name="Rectangle 12670"
          width="32"
          height="32"
          transform="translate(6623 2086)"
          fill="var(--primary-black)"
          opacity="0"
        />
        <path
          id="Path_16180"
          data-name="Path 16180"
          d="M22.571,19.143h2.286v2.286H2V19.143H4.286v-8a9.143,9.143,0,1,1,18.286,0Zm-2.286,0v-8a6.857,6.857,0,1,0-13.714,0v8ZM10,23.714h6.857V26H10Z"
          transform="translate(6626 2088)"
          fill="var(--primary-black)"
        />
      </g>
    </svg>
  );
}

export default NotificationIcon;
