import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShopBannerModel } from '@fe-monorepo/models';

export const bannerSlice = createSlice({
    name: 'banner',
    initialState: {
       heroBanner: [] as ShopBannerModel[],
    },
    reducers: {
        setHeroBanner: (state, action: PayloadAction<ShopBannerModel[]>) => {
            state.heroBanner = action.payload;
        },
    },
});

export const { setHeroBanner } = bannerSlice.actions;
