import { BitsModel, SearchData, ShopModel, StreamsModel, TeamsModel, TournamentModel, UserSearchModel } from '@fe-monorepo/models';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';


type SearchStateSliceState = {
    shop: SearchData<ShopModel>;
    users: SearchData<UserSearchModel>;
    teams: SearchData<TeamsModel>;
    tournaments: SearchData<TournamentModel>;
    bits: SearchData<BitsModel>;
    streams: SearchData<StreamsModel>;
  };

export const searchSlice = createSlice({
  name: 'search',
  initialState: {
    shop: {
        total: 0,
        result: [],
    },
    users: {
        total: 0,
        result: [],
    },
    teams: {
        total: 0,
        result: [],
    },
    tournaments: {
        total: 0,
        result: [],
    },
    bits: {
        total: 0,
        result: [],
    },
    streams: {
        total: 0,
        result: [],
    }
  } as unknown as SearchStateSliceState,
  reducers: {
    setShop: (state, action: PayloadAction<SearchData<ShopModel>>) => {
     state.shop = action.payload
    },
    setUsers: (state, action: PayloadAction<SearchData<UserSearchModel>>) => {
       state.users = action.payload
    },
    setTournaments: (state, action: PayloadAction<SearchData<TournamentModel>>) => {
      state.tournaments = action.payload
    },
    setTeams: (state, action: PayloadAction<SearchData<TeamsModel>>) => {
       state.teams = action.payload
    },
    setBits: (state, action: PayloadAction<SearchData<BitsModel>>) => {
       state.bits = action.payload
    },
    setStreams: (state, action: PayloadAction<SearchData<StreamsModel>>) => {
       state.streams = action.payload
    }
  }
});

export const { setShop, setUsers, setTournaments, setTeams, setBits, setStreams } = searchSlice.actions;
