import { gql } from '@apollo/client';

export const BIT_QUERY = {
  getBitCategories: gql`
    query fetchBitCategories {
      getBitCategories {
        error_msg
        error_code
        is_successful
        data {
          id
          code
          name_en
          name_ar
        }
      }
    }
  `,

  getAllBits: gql`
    query fetchBits($details: BitsAllInput) {
      getAllBits(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          id
          url
          views
          number_of_likes
          bit_description
          bit_name
          category_code
          category_id
          category_name_en
          category_name_ar
        }
      }
    }
  `,
};
