import { gql } from '@apollo/client';

export const EWC_BANNER_QUERY = {
  getEWCBanner: gql`
    query fetchEWCBanner($details: TmsFetchEWCBannerInput) {
      EWCBanner(details: $details) {
        is_successful
        error_code
        error_msg
        app_code
        response {
          name_en
          name_ar
          banner_type
          expire_on
          content_ar
          content_en
          cta_ar
          cta_en
          reference_type
          reference_id
          reference_value
          is_active
          banner_items {
            name_ar
            name_en
            reference_type
            reference_value
            web_media_url_ar
            web_media_url_en
            mobile_media_url_ar
            mobile_media_url_en
          }
        }
      }
    }
  `,
};
