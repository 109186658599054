import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import { useSelector } from 'react-redux';

interface SideBarChatEmptyProps {
  isFromSearchbar?: boolean;
  onClickNewChat?: () => void;
  hasError?: boolean;
  onRefreshClick?: () => void;
}

const SideBarChatEmpty = ({ isFromSearchbar, onClickNewChat, hasError, onRefreshClick }: SideBarChatEmptyProps) => {
  const { translate } = useTranslate();
  const isRTL = useSelector((state: RootState) => state.app.isRTL);

  const onNewChatClick = () => {
    onClickNewChat && onClickNewChat();
  };

  return (
    <div className="flex flex-col justify-center items-center h-full gap-[1.25rem] p-[1rem]">
      <div className="flex flex-col gap-[.25rem] font-regular text-fs-body-small text-secondary/70 text-center">
        {hasError ? (
          <>
            <p>{translate('connect.error.failed_to_load')}</p>
            <p>{translate('connect.error.try_again')}</p>
          </>
        ) : (
          <>
            <p>{isFromSearchbar ? translate('chat.sidebar.search.empty_text_1') : translate('chat.sidebar.empty_text_1')}</p>
            <p>{isFromSearchbar ? translate('chat.sidebar.search.empty_text_2') : translate('chat.sidebar.empty_text_2')}</p>
          </>
        )}
      </div>
      {hasError ? (
        <p className="text-fs-body font-regular text-sunset cursor-pointer" onClick={onRefreshClick && onRefreshClick}>
          {translate('action_refresh')}
        </p>
      ) : (
        !isFromSearchbar && (
          <Button
            text={translate('chat.sidebar.new_chat')}
            style={`
              text-fs-body rounded-md font-medium h-[2.5rem] border-[0.063rem] border-sunset mt-[0.5rem]
              gap-[0.5rem] z-1 
              text-white mb-[0px] 
              xsMax:mb-[0px] items-end
              rounded-sm hover:text-sunset
              w-full
              SD:w-[75%]
              ${isRTL ? `after:bg-white100 before:bg-sunset` : `before:bg-white100 after:bg-sunset`}
              dark:${isRTL ? `after:bg-white100 before:bg-sunset` : `before:bg-white100 after:bg-sunset`}`}
            onClick={onNewChatClick}
          />
        )
      )}
    </div>
  );
};

export default SideBarChatEmpty;
