import { IMAGES } from '@fe-monorepo/assets';
import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { OrderQueryResponse, ShopProductsModel, ShopWishlistInput, WishlistData, WishlistMutationResponse } from '@fe-monorepo/models';
import EmptyState from '@fe-web/Atoms/Empty/EmptyState';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { ReactNode, forwardRef } from 'react';

import ScrollBar from '../../components/Molecules/ScrollBar/ScrollBar';
import ProductListingCard from '../../components/Organisms/Product/ProductListingCard';
import Container from '../../components/Templates/Container';

interface ProductListWithTitleProps {
  title: ReactNode;
  listOfProducts?: ShopProductsModel[];
  language: 'en' | 'ar';
  isRecommendation?: boolean;
  wishlistData: WishlistData[] | undefined;
  checkIsSuccess?: (errorMessage: string) => void;
  additionalStyles?: string;
  addToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  removeToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  getAllWishlist: (getAllWishlistInput: { last_cursor: string; direction: string }) => Promise<OrderQueryResponse | undefined>;
}

const ProductListWithTitle = forwardRef<HTMLDivElement, ProductListWithTitleProps>((props, ref) => {
  const { translate } = useTranslate();
  return (
    <div ref={ref} className={`${props.additionalStyles ? props.additionalStyles : 'py-50'} 4K:py-100 8K:py-240`}>
      <Container>
        <p className="flex items-center justify-between">
          <span className="font-medium 4K:py-100 8K:py-200">{props?.title}</span>
        </p>
      </Container>

      {props?.listOfProducts && props?.listOfProducts?.length > 0 ? (
        <ScrollBar
          containerClassName="flex gap-12 4K:gap-80 8K:gap-160 py-20 px-20 md:px-64 lg:px-120 4xl:px-160 5xl:px-320 8xl:px-655 h-full !w-full"
          leftArrowClassName="hidden sm:flex bg-gradient-to-r from-primary/90 from-20% via-60% via-primary/60 to-transparent ps-[77px] rtl:pe-[77px]"
          rightArrowClassName="hidden sm:flex bg-gradient-to-l from-primary/90 from-20% via-60% via-primary/60 to-transparent pe-[77px] rtl:ps-[77px]"
        >
          {props?.listOfProducts?.map((product, index) => {
            const isWishList = props.wishlistData
              ? props.wishlistData.find(item => item.product_code === product.product_code)
                ? true
                : false
              : false;

            return (
              <div className="min-w-full max-w-full SD:min-w-[23.5%]" key={product.product_code}>
                <ProductListingCard
                  layout={'grid'}
                  product={product}
                  collectionCode={product?.brand?.collection_code ?? ''}
                  isMobile={false}
                  language={props.language}
                  isWishlist={isWishList}
                  isRecommendation={props.isRecommendation}
                  checkIsSuccess={props.checkIsSuccess}
                  addToWishList={props.addToWishList}
                  removeToWishList={props.removeToWishList}
                  getAllWishlist={props.getAllWishlist}
                />
              </div>
            );
          })}
        </ScrollBar>
      ) : (
        <EmptyState
          message={translate('empty_state_no_product_yet')}
          icon={
            <ResponsiveIcon className={`stroke-secondary fill-primary`} name={IconNames?.searchOutline} baseHeight={90} baseWidth={90} />
          }
          img={IMAGES.EmptyStates.toString()}
          divClass="pl-[7.5rem] pt-[1rem]"
        />
      )}
    </div>
  );
});

export default ProductListWithTitle;
