import {
  ContentCardsDataType,
  FeatureFlag,
  FirebaseSettingsModel,
  LMDModel,
  MarketingConfigType,
  ResponseKeyModel,
  TNCConfigType,
  ThemeConfigType,
} from '@fe-monorepo/models';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type ErrorObject = {
  APIName: string;
  errorCode: string;
  errorMessage: string;
};

export enum PageErrorTypes {
  BAD_REQUEST = '400',
  NOT_FOUND = '404',
  SERVER_ERROR = '500',
  RELOAD_REQUIRED = '408',
  SERVER_DOWN = '521',
  MAINTENANCE = '503',
  OFFLINE = '504',
  SESSION_EXPIRED = '1010',
}

export type MatchMakingPreference = {
  reference_game_code: string;
  reference_platform_code: string;
  description?: string;
  reference_lang_code: string;
};

export type MatchMakingCurrentPref = MatchMakingPreference | undefined;

export type UseDialogTogglePropsIsOpen = {
  [key: string]: boolean;
};

export type GENG_CONFIG_TYPE = {
  hide_gen_g_banner?: boolean;
  geng_mobile_banner?: string;
  geng_web_banner?: string;
  geng_redirect_url?: string;
};
export type ConsentStatus = 'accepted' | 'rejected' | null;


export type AppStateSliceState = {
  language: 'en' | 'ar';
  selectedLanguage: string;
  country: string;
  timezone: string;
  currency: string;
  currencyRate: number;
  currencyOBJ: { symbol: string; rate: number; code: string };
  themes: string;
  prevThemes: string;
  isRTL: boolean;
  firstInstall: boolean;
  isLoading: boolean;
  isWishlistLoading: boolean;
  isValidated: boolean;
  authToken: string;
  headerColorChange: boolean;
  isServerError: boolean;
  isSessionExpired: boolean;
  settingsSwitchData: { [key: string]: boolean };
  featureFlags: FeatureFlag[];
  isUserSettingsOpen: boolean;
  shopCurrency: string;
  isGuestLoginUser: boolean;
  paymentoption: string;
  lmdCode: LMDModel;
  responseReference: ResponseKeyModel;
  isMatchMakingFormReset: boolean;
  currentMatchMakingPref: MatchMakingCurrentPref;
  fingerPrint: string;
  firebaseToken: string;
  firebaseSettings: FirebaseSettingsModel;
  firebaseMessagingToken: string;
  advertisingID: string;

  errorObject: ErrorObject | null;
  verifyDialogOpen: boolean;
  isDialogOpen: UseDialogTogglePropsIsOpen;
  isDrawerOpen: boolean;
  recentSearchIsOpen: boolean;
  recentSearchRevalidateData: boolean;
  searchInputValue: string;
  searchInputEnableRecentSearch: boolean;
  showSearchInput: boolean;
  shopActiveTab: number;
  shopActiveFilter: string;
  show_apple_pay: boolean;
  gen_g_configs: GENG_CONFIG_TYPE;
  themeConfig: ThemeConfigType | null;
  contentCardsData: ContentCardsDataType | null;
  tncConfig: TNCConfigType | null;
  marketingConfig: MarketingConfigType | null;
  isEWCShopHidden: boolean;
  isEWCTabDefault: boolean;
  isGenGBannerHidden: boolean;
  isEWCWatchTabHidden: boolean;
  gengMobileBanner: string;
  isEWCSectionHidden: boolean | null;
  isEWCStayTunedSectionHidden: boolean | null;
  redirectToEWC: boolean | null;
  verifyModalData: {
    isOpen: boolean;
    isEmail?: boolean;
  };
  isMobileTabLoading: boolean;
  isPasswordSettingsHidden: boolean;
  hide2FA: boolean;
  otpResendTimer: number;
  otpBlockedTimer: number;
  isSocialLoginInProgress: boolean
  cookieConsent: ConsentStatus;
  showCookieConsent: boolean;
};

export const appStateSlice = createSlice({
  name: 'appState',
  initialState: {
    language: 'en',
    selectedLanguage: 'English',
    themes: 'light',
    prevThemes: '',
    country: 'SA',
    timezone: 'GMT+03:00',
    currency: 'SR',
    currencyRate: 1,
    currencyOBJ: {
      symbol: 'SR',
      rate: 1,
      code: 'SAR',
    },
    isRTL: false,
    firstInstall: true,
    isValidated: false,
    isLoading: false,
    isWishlistLoading: false,
    authToken: '',
    headerColorChange: true,
    isServerError: false,
    isSessionExpired: false,
    settingsSwitchData: { theme: false },
    featureFlags: [],
    isUserSettingsOpen: false,
    shopCurrency: 'SR',
    isGuestLoginUser: false,
    paymentoption: '',
    lmdCode: {
      city: '',
      lmd_code: '',
      region: '',
    },
    responseReference: {},
    isMatchMakingFormReset: false,
    currentMatchMakingPref: undefined,
    fingerPrint: '',
    firebaseToken: '',
    //appContext: '',
    firebaseSettings: {
      hide_for_version: 0,
      hide_talk: 0,
      hide_tms: 0,
      in_use: 1,
      is_recaptcha_active: 0,
      min_version: 1,
      show_apple_pay: 1,
      show_tabby: 1,
      isShopHidden: false,
      isShowAppMaintenance: false,
      is_offline: 0,
      is_under_maintenance: 0,
      hide_gen_g_banner: true,
      geng_mobile_banner: '',
      geng_web_banner: '',
      geng_redirect_url: '',
    },
    firebaseMessagingToken: '',
    advertisingID: '',
    errorObject: null,
    verifyDialogOpen: false,
    isDialogOpen: {},
    isDrawerOpen: false,
    recentSearchIsOpen: false,
    recentSearchRevalidateData: false,
    searchInputValue: '',
    searchInputEnableRecentSearch: true,
    showSearchInput: false,
    shopActiveTab: 1,
    shopActiveFilter: '',
    show_apple_pay: false,
    gen_g_configs: {
      hide_gen_g_banner: true,
      geng_mobile_banner: '',
      geng_web_banner: '',
      geng_redirect_url: '',
    },
    themeConfig: null,
    contentCardsData: null,
    tncConfig: null,
    marketingConfig: null,
    isEWCShopHidden: false,
    isEWCTabDefault: false,
    isGenGBannerHidden: true,
    isEWCWatchTabHidden: false,
    gengMobileBanner: '',
    isEWCSectionHidden: false,
    isEWCStayTunedSectionHidden: false,
    redirectToEWC: true,
    verifyModalData: {
      isOpen: false,
      isEmail: false,
    },
    isMobileTabLoading: true,
    isPasswordSettingsHidden: false,
    hide2FA: false,
    otpResendTimer: 60,
    otpBlockedTimer: 900,
    isSocialLoginInProgress: false,
    cookieConsent: null,
    showCookieConsent: false,
  } as AppStateSliceState,
  reducers: {
    setPreferences: (state, action: PayloadAction<AppStateSliceState>) => {
      state.country = action.payload.country ? action.payload.country : 'SA';
      state.timezone = action.payload.timezone ? action.payload.timezone : 'GMT+03:00';
    },
    clearPreferences: state => {
      state.country = 'SA';
      state.timezone = 'GMT+03:00';
    },
    setLanguage: (state, action: PayloadAction<'en' | 'ar'>) => {
      state.language = action.payload;
    },
    setCountry: (state, action: PayloadAction<string>) => {
      state.country = action.payload;
    },
    setCurrency: (state, action: PayloadAction<AppStateSliceState>) => {
      state.currency = action.payload.currency;
      state.currencyRate = action.payload.currencyRate;

      state.currencyOBJ = {
        symbol: action.payload.currencyOBJ.symbol,
        rate: action.payload.currencyOBJ.rate,
        code: action.payload.currencyOBJ.code,
      };
    },
    setTimezone: (state, action: PayloadAction<string>) => {
      state.timezone = action.payload;
    },
    setSelectedLanguage: (state, action: PayloadAction<string>) => {
      state.selectedLanguage = action.payload;
    },
    setThemes: (state, action: PayloadAction<string>) => {
      state.themes = action.payload;
    },
    setPreviousThemes: (state, action: PayloadAction<string>) => {
      state.prevThemes = action.payload;
    },
    setRTLStatus: (state, action: PayloadAction<boolean>) => {
      state.isRTL = action.payload;
    },
    setInstallStatus: (state, action: PayloadAction<boolean>) => {
      state.firstInstall = action.payload;
    },
    setValidateAccount: (state, action: PayloadAction<boolean>) => {
      state.isValidated = action.payload;
    },
    setLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setWishlistLoadingState: (state, action: PayloadAction<boolean>) => {
      state.isWishlistLoading = action.payload;
    },
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.authToken = action.payload;
    },
    setHeaderColorChange: (state, action: PayloadAction<boolean>) => {
      state.headerColorChange = action.payload;
    },
    setServerErrorState: (state, action: PayloadAction<boolean>) => {
      state.isServerError = action.payload;
    },
    setSessionExpiredState: (state, action: PayloadAction<boolean>) => {
      state.isSessionExpired = action.payload;
    },
    setSettingsSwitchStatus: (state, action: PayloadAction<AppStateSliceState['settingsSwitchData']>) => {
      state.settingsSwitchData = { ...state.settingsSwitchData, ...action.payload };
    },
    setFeatureFlags: (state, action: PayloadAction<FeatureFlag[]>) => {
      state.featureFlags = action.payload;
    },
    setIsUserSettingsOpen: (state, action: PayloadAction<boolean>) => {
      state.isUserSettingsOpen = action.payload;
    },
    setShopCurrency: (state, action: PayloadAction<string>) => {
      state.shopCurrency = action.payload;
    },
    setIsGuestUser: (state, action: PayloadAction<boolean>) => {
      state.isGuestLoginUser = action.payload;
    },
    setPaymentOption: (state, action: PayloadAction<string>) => {
      state.paymentoption = action.payload;
    },
    setLMDCode: (state, action: PayloadAction<LMDModel>) => {
      state.lmdCode = action.payload;
    },
    setResponseReference: (state, action: PayloadAction<ResponseKeyModel>) => {
      state.responseReference = action.payload;
    },
    clearResponseReference: state => {
      state.responseReference = {};
    },
    updateMatchmakingFormReset: (state, action: PayloadAction<boolean>) => {
      state.isMatchMakingFormReset = action.payload;
    },
    setMatchmakingPreference: (state, action: PayloadAction<MatchMakingCurrentPref>) => {
      state.currentMatchMakingPref = action.payload;
    },
    setFingerPrint: (state, action: PayloadAction<string>) => {
      state.fingerPrint = action.payload;
    },
    setFirebaseToken: (state, action: PayloadAction<string>) => {
      state.firebaseToken = action.payload;
    },
    setFirebaseSettings: (state, action: PayloadAction<FirebaseSettingsModel>) => {
      state.firebaseSettings = action.payload;
    },
    setFirebaseMessagingToken: (state, action: PayloadAction<string>) => {
      state.firebaseMessagingToken = action.payload;
    },
    setAdvertisingID: (state, action: PayloadAction<string>) => {
      state.advertisingID = action.payload;
    },

    setErrorObject: (state, action: PayloadAction<ErrorObject>) => {
      state.errorObject = action.payload;
    },

    resetErrorObject: (state, action?: PayloadAction) => {
      state.errorObject = null;
    },
    setIsDialogOpen: (state, action: PayloadAction<UseDialogTogglePropsIsOpen>) => {
      state.isDialogOpen = { ...state.isDialogOpen, ...action.payload };
    },
    setIsDrawerOpen: (state, action: PayloadAction<boolean>) => {
      state.isDrawerOpen = action.payload;
    },
    setVerifyDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.verifyDialogOpen = action.payload;
    },
    setRecentSearchOpen: (state, action: PayloadAction<boolean>) => {
      state.recentSearchIsOpen = action.payload;
    },
    setRecentSearchRevalidateData: (state, action: PayloadAction<boolean>) => {
      state.recentSearchRevalidateData = action.payload;
    },
    setSearchInputValue: (state, action: PayloadAction<string>) => {
      state.searchInputValue = action.payload;
    },
    setSearchInputEnableRecentSearch: (state, action: PayloadAction<boolean>) => {
      state.searchInputEnableRecentSearch = action.payload;
    },
    setShowSearchInput: state => {
      state.showSearchInput = !state.showSearchInput;
    },
    toggleShowSearchInput: (state, action: PayloadAction<boolean>) => {
      state.showSearchInput = action.payload;
    },
    setShopActiveFilter: (state, action: PayloadAction<string>) => {
      state.shopActiveFilter = action.payload;
    },
    setShopActiveTab: (state, action: PayloadAction<number>) => {
      state.shopActiveTab = action.payload;
    },
    setShowApplePay: (state, action: PayloadAction<boolean>) => {
      state.show_apple_pay = action.payload;
    },
    setGenGConfigs: (state, action: PayloadAction<GENG_CONFIG_TYPE>) => {
      state.gen_g_configs = action.payload;
    },
    setThemeConfig: (state, action: PayloadAction<ThemeConfigType | null>) => {
      state.themeConfig = action.payload;
    },
    setContentCardsData: (state, action: PayloadAction<ContentCardsDataType | null>) => {
      state.contentCardsData = action.payload;
    },
    setTNCConfig: (state, action: PayloadAction<TNCConfigType | null>) => {
      state.tncConfig = action.payload;
    },
    setMarketingConfig: (state, action: PayloadAction<MarketingConfigType | null>) => {
      state.marketingConfig = action.payload;
    },
    setIsEWCShopHidden: (state, action: PayloadAction<boolean>) => {
      state.isEWCShopHidden = action.payload;
    },
    setIsEWCTabDefault: (state, action: PayloadAction<boolean>) => {
      state.isEWCTabDefault = action.payload;
    },
    setIsGenGBannerHidden: (state, action: PayloadAction<boolean>) => {
      state.isGenGBannerHidden = action.payload;
    },
    setGenGMobileBanner: (state, action: PayloadAction<string>) => {
      state.gengMobileBanner = action.payload;
    },
    setEWCwatchTabHidden: (state, action: PayloadAction<boolean>) => {
      state.isEWCWatchTabHidden = action.payload;
    },
    setIsEWCSectionHidden: (state, action: PayloadAction<boolean>) => {
      state.isEWCSectionHidden = action.payload;
    },
    setIsEWCStayTunedSectionHidden: (state, action: PayloadAction<boolean>) => {
      state.isEWCStayTunedSectionHidden = action.payload;
    },
    setRedirectToEWC: (state, action: PayloadAction<boolean>) => {
      state.redirectToEWC = action.payload;
    },
    setVerifyModalData: (state, action: PayloadAction<{ isOpen: boolean; isEmail?: boolean }>) => {
      state.verifyModalData = { isOpen: action.payload.isOpen, isEmail: action.payload.isEmail };
    },
    setIsMobileTabLoading: (state, action: PayloadAction<boolean>) => {
      state.isMobileTabLoading = action.payload;
    },
    setIsPasswordSettingsHidden: (state, action: PayloadAction<boolean>) => {
      state.isPasswordSettingsHidden = action.payload;
    },
    setHide2FA: (state, action: PayloadAction<boolean>) => {
      state.hide2FA = action.payload;
    },
    setOtpResendTimer: (state, action: PayloadAction<number>) => {
      state.otpResendTimer = action.payload;
    },
    setOtpBlockedTimer: (state, action: PayloadAction<number>) => {
      state.otpBlockedTimer = action.payload;
    },
    setIsSocialLoginInProgress: (state, action: PayloadAction<boolean>) => {
      state.isSocialLoginInProgress = action.payload;
    },
    setCookieConsent: (state, action: PayloadAction<ConsentStatus>) => {
      state.cookieConsent = action.payload;
    },
    setShowCookieConsent: (state, action: PayloadAction<boolean>) => {
      state.showCookieConsent = action.payload;
    }
  },
});

export const {
  setErrorObject,
  resetErrorObject,
  setPreferences,
  clearPreferences,
  setLanguage,
  setCountry,
  setCurrency,
  setTimezone,
  setSelectedLanguage,
  setThemes,
  setPreviousThemes,
  setRTLStatus,
  setLoadingState,
  setWishlistLoadingState,
  setInstallStatus,
  setAuthToken,
  setValidateAccount,
  setHeaderColorChange,
  setServerErrorState,
  setSessionExpiredState,
  setSettingsSwitchStatus,
  setFeatureFlags,
  setIsUserSettingsOpen,
  setShopCurrency,
  setIsGuestUser,
  setPaymentOption,
  setLMDCode,
  setResponseReference,
  clearResponseReference,
  updateMatchmakingFormReset,
  setMatchmakingPreference,
  setFingerPrint,
  setFirebaseToken,
  setFirebaseSettings,
  setFirebaseMessagingToken,
  setAdvertisingID,
  setIsDialogOpen,
  setIsDrawerOpen,
  setVerifyDialogOpen,
  setRecentSearchOpen,
  setRecentSearchRevalidateData,
  setSearchInputValue,
  setSearchInputEnableRecentSearch,
  setShowSearchInput,
  toggleShowSearchInput,
  setShopActiveFilter,
  setShopActiveTab,
  setShowApplePay,
  setGenGConfigs,
  setThemeConfig,
  setContentCardsData,
  setTNCConfig,
  setMarketingConfig,
  setGenGMobileBanner,
  setIsEWCShopHidden,
  setIsEWCTabDefault,
  setIsGenGBannerHidden,
  setEWCwatchTabHidden,
  setIsEWCSectionHidden,
  setIsEWCStayTunedSectionHidden,
  setRedirectToEWC,
  setVerifyModalData,
  setIsMobileTabLoading,
  setIsPasswordSettingsHidden,
  setHide2FA,
  setOtpResendTimer,
  setOtpBlockedTimer,
  setIsSocialLoginInProgress,
  setCookieConsent,
  setShowCookieConsent,
} = appStateSlice.actions;
