import { useTranslate } from '@fe-monorepo/hooks';
import { useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

export default function VerticalPlayerProducts({ show, products }: any) {
  const { translate } = useTranslate();
  const productContainerRef = useRef<HTMLDivElement | null>(null);

  // Handler for the wheel event to scroll horizontally with both vertical and horizontal scrolling
  const handleScroll = (e: WheelEvent) => {
    if (productContainerRef.current) {
      e.preventDefault(); // Prevent the default scroll behavior

      // Horizontal scrolling (Touchpad or mouse horizontal scroll)
      if (e.deltaX !== 0) {
        productContainerRef.current.scrollLeft += e.deltaX;
      }
      // Vertical scrolling (Touchpad or mouse wheel scroll)
      else if (e.deltaY !== 0) {
        productContainerRef.current.scrollLeft += e.deltaY;
      }
    }
  };

  // Adding and cleaning up the event listener
  useEffect(() => {
    const container = productContainerRef.current;
    if (container) {
      container.addEventListener('wheel', handleScroll);
    }

    // Cleanup the event listener on component unmount
    return () => {
      if (container) {
        container.removeEventListener('wheel', handleScroll);
      }
    };
  }, []);

  return (
    <div
      className="product_container"
      style={{
        height: show ? '65px' : 0,
      }}
      ref={productContainerRef}
    >
      {products?.map((product: any) => (
        <div className="product_wrapper" key={product._id}>
          <div className="product_info">
            <div className="left">
              <img src={product.imageUrl} alt={`${product.productId}-logo`}></img>
            </div>
            <div className="right">
              <h6>{product.productName}</h6>
              <Link to={product.shoppableLink} target="_blank" className="product-link">
                {translate('quickplay.label-buy')}
              </Link>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
