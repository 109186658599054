import { useLazyQuery } from '@apollo/client';
import { CONTENT_QUERY } from '@fe-monorepo/data-access';
import { ShopContentModel } from '@fe-monorepo/models';
import { addShopContent, useAppDispatch } from '@fe-monorepo/store';
import { useEffect, useState } from 'react';

import { useFetchQuery } from '../useFetchQuery';

interface ShopContentResponse {
  getShopLanding: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: ShopContentModel[];
  };
}

export const useShopContent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const getShopLandingResponse = useFetchQuery<ShopContentModel[]>(CONTENT_QUERY.getShopLanding, []);
  const dispatch = useAppDispatch();
  const [getShopInfo, { data: content, error: infoError }] = useLazyQuery<ShopContentResponse>(CONTENT_QUERY.getShopLanding, {
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (getShopLandingResponse.data) {
      const response = getShopLandingResponse.data;
      if (response) {
        dispatch(addShopContent(response));
      }
    }
    setIsLoading(false);
  }, [getShopLandingResponse.data]);

  const getShopLanding = async () => {
    setIsLoading(true);
    return getShopLandingResponse.fetch();
  };

  const getAllContent = async () => {
    const { data } = await getShopInfo();
    return data?.getShopLanding;
  };

  return {
    getShopLanding,
    isLoading,
    setIsLoading,
    getShopLandingResponse,
    getAllContent,
    content,
  };
};
