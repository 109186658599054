export enum ResponseKeyValue {
    TWO_FA_Enable = 'twoFAEnable',
    TWO_FA_Disable = 'twoFADisable',
    CHANGE_EMAIL = 'changeEmail',
    CHANGE_PASSWORD = 'changePassword',
    CHANGE_MOBILE_NUMBER = 'changeMobileNumber',
}

export enum OTP_TYPE {
    EMAIL = 'email',
    MOBILE = 'mobile',
    SMS = 'sms',
}
