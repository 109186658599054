import { IconNames, Logger, ZEGO_CLOUD_CONFIG, firebaseIOSConfig, generateGroupId } from '@fe-monorepo/helper';
import { useConnect, useToastMessage, useTranslate } from '@fe-monorepo/hooks';
import { Chat, ChatEventType, ChatType, UserModel } from '@fe-monorepo/models';
import { RootState, setUser, useAppDispatch } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import ToastMessage from '@fe-web/Atoms/ToastMessage';
import _ from 'lodash';
import moment from 'moment';
import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useContext, useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { shallowEqual, useSelector } from 'react-redux';
import ZIM, {
  ZIMConversation,
  ZIMConversationChangeInfo,
  ZIMConversationDeleteConfig,
  ZIMConversationDeletedResult,
  ZIMConversationListQueriedResult,
  ZIMConversationNotificationStatus,
  ZIMConversationNotificationStatusSetResult,
  ZIMConversationQueriedResult,
  ZIMConversationType,
  ZIMConversationUnreadMessageCountClearedResult,
  ZIMEventOfGroupMemberInfoUpdatedResult,
  ZIMEventOfGroupMemberStateChangedResult,
  ZIMEventOfGroupStateChangedResult,
  ZIMGroupAdvancedConfig,
  ZIMGroupAvatarUrlUpdatedResult,
  ZIMGroupCreatedResult,
  ZIMGroupDismissedResult,
  ZIMGroupInfo,
  ZIMGroupInfoQueriedResult,
  ZIMGroupLeftResult,
  ZIMGroupMemberCountQueriedResult,
  ZIMGroupMemberInfoQueriedResult,
  ZIMGroupMemberKickedResult,
  ZIMGroupMemberListQueriedResult,
  ZIMGroupMemberNicknameUpdatedResult,
  ZIMGroupMemberQueryConfig,
  ZIMGroupMemberRoleUpdatedResult,
  ZIMGroupNameUpdatedResult,
  ZIMGroupOwnerTransferredResult,
  ZIMGroupUsersInvitedResult,
  ZIMMediaMessageBase,
  ZIMMediaMessageSentResult,
  ZIMMessage,
  ZIMMessageBase,
  ZIMMessageDeleteConfig,
  ZIMMessageDeletedResult,
  ZIMMessageQueriedResult,
  ZIMMessageReceiptInfo,
  ZIMMessageReceiptsReadSentResult,
  ZIMMessageRevokeConfig,
  ZIMMessageRevokedResult,
  ZIMMessageSendConfig,
  ZIMMessageSentResult,
  ZIMRevokeMessage,
  ZIMUserAvatarUrlUpdatedResult,
  ZIMUsersInfoQueriedResult,
  ZIMUsersInfoQueryConfig,
} from 'zego-zim-web';
import { ZPNs, ZPNsConfig } from 'zego-zpns-web';

import useOnlineDetector from '../useOnlineDetector';

interface UseConnectProvider {
  zim?: ZIM;
  openChats?: Chat[];
  conversationList?: ZIMConversation[];
  receivedPeerMessages?: ZIMMessage[];
  receivedGroupMessages?: ZIMMessage[];
  messageReceiptChangedList?: ZIMMessageReceiptInfo[] | undefined;
  messageRevokedList?: ZIMRevokeMessage[] | undefined;
  clearChatRes?: ZIMMessageDeletedResult[] | undefined;
  groupNameRes?: ZIMGroupNameUpdatedResult[] | undefined;
  queryMembersRes?: ZIMGroupMemberListQueriedResult[] | undefined;
  inviteUsersRes?: ZIMGroupUsersInvitedResult[] | undefined;
  setInviteUsersRes?: Dispatch<SetStateAction<ZIMGroupUsersInvitedResult[] | undefined>>;
  setQueryMembersRes?: Dispatch<SetStateAction<ZIMGroupMemberListQueriedResult[] | undefined>>;
  setGroupNameRes?: Dispatch<SetStateAction<ZIMGroupNameUpdatedResult[] | undefined>>;
  setClearChatRes?: Dispatch<SetStateAction<ZIMMessageDeletedResult[] | undefined>>;
  setConversationList?: Dispatch<SetStateAction<ZIMConversation[] | undefined>>;
  groupMemberStateChangedList?: ZIMEventOfGroupMemberStateChangedResult[] | undefined;
  queryHistoryMessage?: (conversationID: string, conversationType: ZIMConversationType) => Promise<ZIMMessageQueriedResult | undefined>;
  queryConversationList?: (nextConversation?: ZIMConversation) => Promise<ZIMConversationListQueriedResult | undefined>;
  createGroup?: (groupInfo: ZIMGroupInfo, userIDs: string[], config?: ZIMGroupAdvancedConfig) => Promise<ZIMGroupCreatedResult | undefined>;
  openChat?: (chat: Chat, isRemoveNew?: boolean) => void;
  closeChat?: (number: string) => void;
  closeAllChat?: () => void;
  sendMessage?: (
    message: ZIMMessageBase,
    conversationID: string,
    conversationType: ZIMConversationType,
    config: ZIMMessageSendConfig,
  ) => Promise<ZIMMessageSentResult | undefined>;
  sendMediaMessage?: (
    message: ZIMMediaMessageBase,
    conversationID: string,
    conversationType: ZIMConversationType,
    config: ZIMMessageSendConfig,
  ) => Promise<ZIMMediaMessageSentResult | undefined>;
  deleteMessages?: (
    messageList: ZIMMessage[],
    conversationID: string,
    conversationType: ZIMConversationType,
    config: ZIMMessageDeleteConfig,
  ) => Promise<ZIMMessageDeletedResult | undefined>;
  deleteAllMessage?: (
    conversationID: string,
    conversationType: ZIMConversationType,
    config: ZIMMessageDeleteConfig,
  ) => Promise<ZIMMessageDeletedResult | undefined>;
  deleteConversation?: (
    conversationID: string,
    conversationType: ZIMConversationType,
    config: ZIMConversationDeleteConfig,
  ) => Promise<ZIMConversationDeletedResult | undefined>;
  queryGroupInfo?: (groupId: string) => Promise<ZIMGroupInfoQueriedResult | undefined>;
  updateGroupName?: (groupName: string, groupId: string) => Promise<ZIMGroupNameUpdatedResult | undefined>;
  queryGroupMemberList?: (groupID: string, config: ZIMGroupMemberQueryConfig) => Promise<ZIMGroupMemberListQueriedResult | undefined>;
  queryGroupMemberInfo?: (userID: string, groupID: string) => Promise<ZIMGroupMemberInfoQueriedResult | undefined>;
  setGroupMemberNickname?: (
    nickname: string,
    forUserID: string,
    groupID: string,
  ) => Promise<ZIMGroupMemberNicknameUpdatedResult | undefined>;
  setGroupMemberRole?: (role: number, forUserID: string, groupID: string) => Promise<ZIMGroupMemberRoleUpdatedResult> | undefined;
  transferGroupOwner?: (toUserID: string, groupID: string) => Promise<ZIMGroupOwnerTransferredResult | undefined>;
  queryGroupMemberCount?: (groupID: string) => Promise<ZIMGroupMemberCountQueriedResult | undefined>;
  queryUsersInfo?: (userIDs: string[], config: ZIMUsersInfoQueryConfig) => Promise<ZIMUsersInfoQueriedResult | undefined>;
  queryConversation?: (conversationID: string, conversationType: ZIMConversationType) => Promise<ZIMConversationQueriedResult | undefined>;
  setReceivedPeerMessages?: Dispatch<SetStateAction<ZIMMessage[] | undefined>>;
  setReceivedGroupMessages?: Dispatch<SetStateAction<ZIMMessage[] | undefined>>;
  leaveGroup?: (groupID: string) => Promise<ZIMGroupLeftResult | undefined>;
  queryConversationNotificationStatus?: (
    status: ZIMConversationNotificationStatus,
    conversationID: string,
    conversationType: number,
  ) => Promise<ZIMConversationNotificationStatusSetResult | void> | undefined;
  clearConversationUnreadMessageCount?: (
    conversationID: string,
    conversationType: ZIMConversationType,
  ) => Promise<ZIMConversationUnreadMessageCountClearedResult | undefined>;
  dismissGroup?: (groupID: string) => Promise<ZIMGroupDismissedResult | undefined>;
  updateUserAvatarUrl?: (userAvatarUrl: string) => Promise<ZIMUserAvatarUrlUpdatedResult | undefined>;
  updateGroupAvatarUrl?: (groupAvatarUrl: string, groupID: string) => Promise<ZIMGroupAvatarUrlUpdatedResult | undefined>;
  kickGroupMembers?: (userIDs: string[], groupID: string) => Promise<ZIMGroupMemberKickedResult | undefined>;
  sendMessageReceipt?: (
    messageList: ZIMMessage[],
    conversationID: string,
    conversationType: ZIMConversationType,
  ) => Promise<ZIMMessageReceiptsReadSentResult>;
  setMessageReceiptChangedList?: Dispatch<SetStateAction<ZIMMessageReceiptInfo[] | undefined>>;
  revokeMessage?: (message: ZIMMessage, config: ZIMMessageRevokeConfig) => Promise<ZIMMessageRevokedResult | undefined>;
  setMessageRevokedList?: Dispatch<SetStateAction<ZIMRevokeMessage[] | undefined>>;
  inviteUsersIntoGroup?: (userIDs: string[], groupID: string) => Promise<ZIMGroupUsersInvitedResult | undefined>;
  sendMessageReceiptsRead?: (
    messageList: ZIMMessage[],
    conversationID: string,
    conversationType: ZIMConversationType,
  ) => Promise<ZIMMessageReceiptsReadSentResult | undefined>;
  setGroupMemberStateChangedList?: Dispatch<SetStateAction<ZIMEventOfGroupMemberStateChangedResult[] | undefined>>;
  groupAvatarChangeID?: string;
  groupAvatarChange?: boolean;
  setBlockTrigger?: Dispatch<SetStateAction<boolean>>;
  blockTrigger?: boolean;
  groupMemberInfoUpdatedList?: ZIMEventOfGroupMemberInfoUpdatedResult[] | undefined;
  setGroupMemberInfoUpdatedList?: Dispatch<SetStateAction<ZIMEventOfGroupMemberInfoUpdatedResult[] | undefined>>;
  notificationSidebarStatus?: boolean;
  setNotificationSidebarStatus?: Dispatch<SetStateAction<boolean>>;
  hasError?: boolean;
  refreshLogin?: () => void;
  isZegoLoading?: boolean;
  conversationListModified?: ZIMConversation[] | undefined;
  isConversationListLoaded?: boolean;
}
const environment = process.env.NX_APP_ENVIRONMENT ? process.env.NX_APP_ENVIRONMENT : 'development';

const ConnectContext = createContext<UseConnectProvider>({});
ZIM.create({ appID: ZEGO_CLOUD_CONFIG.APP_ID });
const zim = ZIM.getInstance();
// logLevel = 'debug' | 'info' | 'warn' | 'error' | 'report' | 'disable'
if (environment === 'production') {
  zim.setLogConfig({
    logLevel: 'disable',
  });
}

const zpnsConfig: ZPNsConfig = {
  apiKey: firebaseIOSConfig.apiKey,
  authDomain: firebaseIOSConfig.authDomain,
  projectId: firebaseIOSConfig.projectId,
  storageBucket: firebaseIOSConfig.storageBucket,
  messagingSenderId: firebaseIOSConfig.messagingSenderId,
  appId: firebaseIOSConfig.appId,
  measurementId: firebaseIOSConfig.measurementId ?? '',
  vapidKey: firebaseIOSConfig.vapidKey || '',
};

// Check if the browser supports service workers
if ('serviceWorker' in navigator && !isMobile) {
  ZPNs.getInstance().register(zpnsConfig, zim);
} else {
  console.warn('Service Workers not available (private mode or unsupported browser).');
}
interface ConnectProviderProps {
  children: ReactNode;
}

export const ConnectProvider = ({ children }: ConnectProviderProps) => {
  const user = useSelector((state: RootState) => state.user.userContext, shallowEqual);
  const dispatch = useAppDispatch();
  //get the isValid state from the useSelector
  const token = useSelector((state: RootState) => state.user.userContext.token, shallowEqual);
  const { generateZegoToken } = useConnect();
  const { GROUP_MEMBER_COUNT } = ZEGO_CLOUD_CONFIG;

  const [zegoToken, setZegoToken] = useState<string>();
  const [openChats, setOpenChats] = useState<Chat[]>();
  const [conversationList, setConversationList] = useState<ZIMConversation[]>();
  const [conversationListModified, setConversationListModified] = useState<ZIMConversation[]>();
  const [receivedPeerMessages, setReceivedPeerMessages] = useState<ZIMMessage[]>();
  const [receivedGroupMessages, setReceivedGroupMessages] = useState<ZIMMessage[]>();
  const { translate } = useTranslate();
  const { errorToastMessage, successToastMessage } = useToastMessage();
  const [closeToast, setCloseToast] = useState<boolean>(false);
  const [messageReceiptChangedList, setMessageReceiptChangedList] = useState<ZIMMessageReceiptInfo[]>();
  const [messageRevokedList, setMessageRevokedList] = useState<ZIMRevokeMessage[]>();
  const [clearChatRes, setClearChatRes] = useState<ZIMMessageDeletedResult[]>();
  const [groupNameRes, setGroupNameRes] = useState<ZIMGroupNameUpdatedResult[]>();
  const [groupMemberStateChangedList, setGroupMemberStateChangedList] = useState<ZIMEventOfGroupMemberStateChangedResult[]>();
  const [groupAvatarChange, setGroupAvatarChange] = useState<boolean>(false);
  const [groupAvatarChangeID, setGroupAvatarChangeID] = useState<string>();
  const [blockTrigger, setBlockTrigger] = useState<boolean>(false);
  const [groupMemberInfoUpdatedList, setGroupMemberInfoUpdatedList] = useState<ZIMEventOfGroupMemberInfoUpdatedResult[]>();
  const [queryMembersRes, setQueryMembersRes] = useState<ZIMGroupMemberListQueriedResult[]>();
  const [inviteUsersRes, setInviteUsersRes] = useState<ZIMGroupUsersInvitedResult[]>();
  const [notificationSidebarStatus, setNotificationSidebarStatus] = useState<boolean>(false);
  const [isOnQueryConversationListError, setIsOnQueryConversationListError] = useState<boolean>(false);
  const [isOnLogInError, setIsOnLogInError] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [isZegoLoading, setIsZegoLoading] = useState<boolean>();
  const isOnline = useOnlineDetector();
  const [isConversationListLoaded, setIsConversationLoaded] = useState<boolean>();
  const { log, error } = Logger();

  const queryHistoryMessage = async (conversationID: string, conversationType: ZIMConversationType) => {
    try {
      const response = await zim?.queryHistoryMessage(conversationID, conversationType, { count: 30, reverse: true });
      return response;
    } catch (err) {
      console.error('history message', err);
      // Return if the error is handled in `zegoErrorHandler`
      zegoErrorHandler(err);
      throw err;
    }
  };

  const queryConversationList = useCallback(
    async (nextConversation?: ZIMConversation) => {
      try {
        const response = await zim?.queryConversationList({ count: 10, nextConversation });
        setConversationList(prevConversationList => {
          let newConversationList = response?.conversationList;

          if (nextConversation && prevConversationList?.length) {
            newConversationList = [
              ...prevConversationList,
              ...newConversationList.filter(convo => !prevConversationList?.find(item => item.conversationID === convo.conversationID)),
            ];
          }
          return newConversationList;
        });
        setIsConversationLoaded(true);

        return response;
      } catch (err) {
        // Return if the error is handled in `zegoErrorHandler`
        if (!!zegoErrorHandler(err)) return;
        setIsOnQueryConversationListError(true);
      }
    },
    [setConversationList, setIsOnQueryConversationListError],
  );

  /*
   *   Parameters:
   *     conversationType:
   *        0 - Private Chat
   *        2 - Group Chat
   */

  const queryConversation = async (conversationID: string, conversationType: ZIMConversationType) => {
    try {
      return await zim?.queryConversation(conversationID, conversationType);
    } catch (err) {
      console.error(err);
      zegoErrorHandler(err);
      throw err;
    }
  };

  const openChat = (chat: Chat, isRemoveNew?: boolean) => {
    let newOpenChats = openChats;

    if (!newOpenChats?.map(chat => chat.conversationID).includes(chat.conversationID)) newOpenChats = [...(newOpenChats || []), chat];

    if (isRemoveNew) newOpenChats = [...(newOpenChats || [])?.filter(chat => chat.type != ChatType.NEW)];

    if (newOpenChats.length > 3) {
      newOpenChats = newOpenChats.splice(newOpenChats.length - 3, newOpenChats.length);
    }

    setOpenChats(newOpenChats);
  };

  const closeChat = (id: string) => {
    setOpenChats(openChats?.filter(chat => chat.conversationID != id));
  };

  const closeAllChat = () => {
    setOpenChats([]);
  };

  const createGroup = async (groupInfo: ZIMGroupInfo, userIDs: string[], config?: ZIMGroupAdvancedConfig) => {
    try {
      return await zim?.createGroup(groupInfo, userIDs, config);
    } catch (err) {
      console.error(err);
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const updateLastMessage = useCallback(
    (conversationID: string, message: ZIMMessage) => {
      //check first if conversationID is present on the conversation list if not then push the conversation to the list
      const isConversationIDFound = conversationList?.find(convo => convo.conversationID === conversationID);
      if (!isConversationIDFound) {
        queryConversation(conversationID, message.conversationType)
          .then(response => {
            log('updateLastMessage queryConversation response >>>', response);
            if (response?.conversation) {
              setConversationList(prevConvoList => {
                const convoList = prevConvoList;
                if (!convoList?.find(convo => convo.conversationID === conversationID)) {
                  convoList?.push(response.conversation);
                }
                return convoList;
              });
            }
          })
          .catch(err => {
            error('updateLastMessage queryConversation error >>>', err);
          });
      } else {
        const isTheSameMessage = conversationList?.find(
          convo => convo.conversationID === conversationID && _.isEqual(convo.lastMessage, message),
        );
        if (!isTheSameMessage) {
          setConversationList(prevConversationList => {
            const convoList = prevConversationList?.map(convo => {
              if (convo.conversationID === conversationID) {
                convo.lastMessage = message;
              }
              return convo;
            });
            return convoList;
          });
        }
      }
    },
    [conversationList, setConversationList],
  );

  const sendMessage = async (
    message: ZIMMessageBase,
    conversationID: string,
    conversationType: ZIMConversationType,
    config: ZIMMessageSendConfig,
  ) => {
    try {
      const result = await zim?.sendMessage(message, conversationID, conversationType, config);
      if (result) {
        updateLastMessage(conversationID, result.message); // call the method to update the conversation list for send message
      }
      return result;
    } catch (err: any) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err, true)) return;
    }
  };

  const sendMediaMessage = async (
    message: ZIMMediaMessageBase,
    conversationID: string,
    conversationType: ZIMConversationType,
    config: ZIMMessageSendConfig,
  ) => {
    try {
      const result = await zim?.sendMediaMessage(message, conversationID, conversationType, config);

      if (result) {
        updateLastMessage(conversationID, result.message); // call the method to update the conversation list for send message
      }
      return result;
    } catch (err: any) {
      console.error(err, err?.code);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err, true)) return;
    }
  };

  const deleteAllMessage = async (conversationID: string, conversationType: ZIMConversationType, config: ZIMMessageDeleteConfig) => {
    try {
      const deleteAllRes = await zim?.deleteAllMessage(conversationID, conversationType, config);
      setClearChatRes(prevState => [...(prevState || []), ...[deleteAllRes]]);
      return deleteAllRes;
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const deleteMessages = async (
    messageList: ZIMMessage[],
    conversationID: string,
    conversationType: ZIMConversationType,
    config: ZIMMessageDeleteConfig,
  ) => {
    try {
      const response = await zim?.deleteMessages(messageList, conversationID, conversationType, config);
      successToastMessage({
        message: (
          <ToastMessage
            type="success"
            icon={<ResponsiveIcon className="fill-green" name={IconNames.checkCircleOutline} baseWidth={24} baseHeight={24} />}
          >
            {translate('msg_success')}
            <span className="font-regular">{translate('toast.success.delete_message')}</span>
          </ToastMessage>
        ),
        className: 'flex gap-[0.5rem] w-[36.75rem] max-w-full h-[3rem]',
        useDefaultClassNames: false,
        closeToast: false,
      });
      return response;
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
      errorToastMessage({
        message: (
          <ToastMessage type="error">
            {translate('common_error')}
            <span className="font-regular">{translate('toast.error.delete_message')}</span>
          </ToastMessage>
        ),
        className: 'flex gap-[0.5rem] w-[36.75rem] max-w-full h-[3rem]',
        useDefaultClassNames: false,
        closeToast: false,
      });
      throw err;
    }
  };

  const revokeMessage = async (message: ZIMMessage, config: ZIMMessageRevokeConfig) => {
    try {
      const response = await zim?.revokeMessage(message, config);
      successToastMessage({
        message: (
          <ToastMessage
            type="success"
            icon={<ResponsiveIcon className="fill-green" name={IconNames.checkCircleOutline} baseWidth={24} baseHeight={24} />}
          >
            {translate('msg_success')}
            <span className="font-regular">{translate('toast.success.delete_message')}</span>
          </ToastMessage>
        ),
        className: 'flex gap-[0.5rem] w-[36.75rem] max-w-full h-[3rem]',
        useDefaultClassNames: false,
        closeToast: false,
      });
      return response;
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
      errorToastMessage({
        message: (
          <ToastMessage type="error">
            {translate('common_error')}
            <span className="font-regular">{translate('toast.error.delete_message')}</span>
          </ToastMessage>
        ),
        className: 'flex gap-[0.5rem] w-[36.75rem] max-w-full h-[3rem]',
        useDefaultClassNames: false,
        closeToast: false,
      });
      throw err;
    }
  };

  const deleteConversation = async (conversationID: string, conversationType: ZIMConversationType, config: ZIMConversationDeleteConfig) => {
    try {
      const result = await zim?.deleteConversation(conversationID, conversationType, config);
      log('delete Conversation >>>>', { conversationID: result?.conversationID, conversationType: result?.conversationType });
      if (result?.conversationID) {
        setConversationList(prevConversationList => {
          const convoList = prevConversationList?.filter(convo => convo.conversationID !== result?.conversationID);
          return convoList;
        });
      }

      return result;
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const queryGroupInfo = async (groupId: string) => {
    try {
      return await zim?.queryGroupInfo(groupId);
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const updateGroupName = async (groupName: string, groupId: string) => {
    try {
      const updateGroupNameRes = await zim?.updateGroupName(groupName, groupId);
      setGroupNameRes(prevState => [...(prevState || []), ...[updateGroupNameRes]]);

      return updateGroupNameRes;
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  /*
   *   Parameters:
   *       config:
   *           count - count of member to be returned by the API
   *           nextFlag - (For checking on the zego documentation)
   */

  const queryGroupMemberList = async (groupID: string, config: ZIMGroupMemberQueryConfig) => {
    try {
      const queryMembersRes = await zim?.queryGroupMemberList(groupID, config);
      setQueryMembersRes(prevState => [...(prevState || []), ...[queryMembersRes]]);
      return queryMembersRes;
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const queryGroupMemberInfo = async (userID: string, groupID: string) => {
    try {
      return await zim?.queryGroupMemberInfo(userID, groupID);
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const setGroupMemberNickname = async (nickname: string, forUserID: string, groupID: string) => {
    try {
      return await zim?.setGroupMemberNickname(nickname, forUserID, groupID);
    } catch (err) {
      error('', err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  /*
   *   Role:
   *       1 - Owner/Admin
   *       3 - Member/Regular User
   *
   */
  const setGroupMemberRole = (role: number, forUserID: string, groupID: string) => {
    try {
      return zim?.setGroupMemberRole(role, forUserID, groupID).then(res => {
        if (res && res.role === 1) {
          log('set as admin >>>', res.role);
        } else if (res && res.role === 3) {
          log('removed as admin >>>', res.role);
        }
        return res;
      });
    } catch (err) {
      error('', err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const transferGroupOwner = async (toUserID: string, groupID: string) => {
    try {
      return await zim?.transferGroupOwner(toUserID, groupID);
    } catch (err) {
      error('', err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const queryGroupMemberCount = async (groupID: string) => {
    try {
      return await zim?.queryGroupMemberCount(groupID);
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const queryUsersInfo = async (userIDs: string[], config: ZIMUsersInfoQueryConfig) => {
    try {
      return await zim?.queryUsersInfo(userIDs, config);
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const leaveGroup = async (groupID: string) => {
    try {
      return await zim?.leaveGroup(groupID);
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const dismissGroup = async (groupID: string) => {
    try {
      return await zim?.dismissGroup(groupID);
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const updateUserAvatarUrl = async (userAvatarUrl: string) => {
    try {
      return await zim?.updateUserAvatarUrl(userAvatarUrl);
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };
  //status
  //1 - Notify
  //2 - Do not disturb
  //only applicable to group chat
  const queryConversationNotificationStatus = (
    status: ZIMConversationNotificationStatus,
    conversationID: string,
    conversationType: number,
  ) => {
    try {
      setCloseToast(true);
      return zim
        ?.setConversationNotificationStatus(status, conversationID, conversationType)
        .then((res: ZIMConversationNotificationStatusSetResult) => {
          if (res?.conversationID && status === 2) {
            // mute
            setConversationList(prevConversationList => {
              const convoList = prevConversationList?.map(convo => {
                if (convo.conversationID === conversationID) {
                  convo.notificationStatus = status;
                }
                return convo;
              });
              return convoList;
            });
            setCloseToast(false);
            errorToastMessage({
              message: <ToastMessage type="error">{translate('connect.chat_muted')}</ToastMessage>,
              closeToast: closeToast,
            });
            return res;
          } else if (res?.conversationID && status === 1) {
            //unmute
            setConversationList(prevConversationList => {
              const convoList = prevConversationList?.map(convo => {
                if (convo.conversationID === conversationID) {
                  convo.notificationStatus = status;
                }
                return convo;
              });
              return convoList;
            });
            setCloseToast(false);
            successToastMessage({
              message: (
                <ToastMessage
                  icon={<ResponsiveIcon className="!fill-green" name={IconNames.icon_toast_exclamation} baseWidth={30} baseHeight={30} />}
                  type="success"
                >
                  {translate('connect.chat_unmuted')}
                </ToastMessage>
              ),
              closeToast: closeToast,
            });
            return res;
          } else {
            //error api
            setCloseToast(false);
            errorToastMessage({
              message: <ToastMessage type="error">{translate('msg_failed_generic_try_again')}</ToastMessage>,
              closeToast: closeToast,
            });
            return res;
          }
        })
        .catch((err: any) => {
          setCloseToast(false);
          errorToastMessage({
            message: <ToastMessage type="error">{translate('msg_failed_generic_try_again')}</ToastMessage>,
            closeToast: closeToast,
          });
          console.error(err);
        });
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const clearConversationUnreadMessageCount = async (conversationID: string, conversationType: ZIMConversationType) => {
    try {
      return await zim?.clearConversationUnreadMessageCount(conversationID, conversationType);
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const updateGroupAvatarUrl = async (groupAvatarUrl: string, groupID: string) => {
    try {
      return await zim?.updateGroupAvatarUrl(groupAvatarUrl, groupID);
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const kickGroupMembers = async (userIDs: string[], groupID: string) => {
    try {
      return await zim?.kickGroupMembers(userIDs, groupID);
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  const inviteUsersIntoGroup = async (userIDs: string[], groupID: string) => {
    try {
      const result = await zim?.inviteUsersIntoGroup(userIDs, groupID);
      setInviteUsersRes(prevState => [...(prevState || []), ...[result]]);
      return result;
    } catch (err) {
      console.error(err);
      // Return if the error is handled in `zegoErrorHandler`
      if (!!zegoErrorHandler(err)) return;
    }
  };

  // Original location for process
  // generate zego token
  const generateToken = async () => {
    const response = await generateZegoToken();
    if (response?.is_successful && response.data.token) {
      dispatch(
        setUser({
          zegoToken: response.data.token,
        } as UserModel),
      );
      const zegoToken = response.data.token;
      setZegoToken(zegoToken);
      return zegoToken;
    }
  };

  const onConvesationChanged = (infoList: ZIMConversationChangeInfo[]) => {
    log('onConvesationChanged >>>', infoList);
    infoList.forEach(info => {
      switch (info?.event) {
        case 0: // Added
          log('Conversation Added');
          const isConversationIDFound = conversationList?.find(convo => convo.conversationID === info.conversation.conversationID);
          if (!isConversationIDFound) {
            setConversationList(prevConvoList => {
              const convoList = prevConvoList;
              if (!convoList?.find(convo => convo.conversationID === info.conversation.conversationID)) {
                convoList?.push(info.conversation);
              }
              return convoList;
            });
          }
          break;
        case 3: // Deleted
          log('Conversation Deleted');
          setConversationList(prevConversationList => {
            const convoList = prevConversationList?.filter(convo => convo.conversationID !== info.conversation.conversationID);
            return convoList;
          });
          break;
        case 1: // Updated
          log('Conversation Updated');
          setConversationList(prevConversationList => {
            const convoList = prevConversationList?.map(convo => {
              if (convo.conversationID === info.conversation.conversationID) {
                return info.conversation;
              } else {
                return convo;
              }
            });
            return convoList;
          });
          break;
        case 3: //Disabled
          //TODO: Need update if we have requirement for disabled conversation
          break;
      }
    });
  };

  const updateDetailsOnGroupChanges = (groupId: string, value: string, key: string) => {
    setConversationList(prevValues => {
      const convoList = prevValues?.map(convo => {
        if (convo.conversationID === groupId) {
          switch (key) {
            case 'conversationAvatarUrl':
              convo.conversationAvatarUrl = value;
              setGroupAvatarChangeID(groupId);
              setGroupAvatarChange(groupAvatarChange => !groupAvatarChange);
              break;
            case 'conversationName':
              convo.conversationName = value;
              break;
          }
        }
        return convo;
      });
      return convoList;
    });
  };

  const sendMessageReceipt = async (messageList: ZIMMessage[], conversationID: string, conversationType: ZIMConversationType) => {
    return await zim.sendMessageReceiptsRead(messageList, conversationID, conversationType);
  };

  useEffect(() => {
    if (token && user.username) {
      if (!user.zegoToken) {
        generateToken();
      } else {
        setZegoToken(user.zegoToken);
      }
    } else if (token === '' && !!zim) {
      closeAllChat?.();
      setConversationList([]);
      setZegoToken('');
      zim.logout();
    }
  }, [token, user.username]);

  const login = useCallback(
    async (zegoToken: string | undefined, action?: string) => {
      const userInfo = {
        userID: user.username,
        userName: user.display_name,
        userAvatarUrl: user.avatar_url ?? '',
      };

      setIsZegoLoading(true);

      try {
        if (!zegoToken) return;
        await zim.login(userInfo, zegoToken);

        log('successful login');
        !!zim && action !== 'reconnect' && (await queryConversationList());
        !!zim && action !== 'reconnect' && (await updateUserAvatarUrl(user.avatar_url ?? ''));
      } catch (err) {
        error('login error >>>', err);
        setIsZegoLoading(false);
        // Return if the error is handled in `zegoErrorHandler`
        if (!!zegoErrorHandler(err)) return;
        setIsOnLogInError(true);
      }
      setIsZegoLoading(false);
    },
    [zegoToken],
  );

  const refreshLogin = async () => {
    try {
      if (isOnQueryConversationListError) {
        await queryConversationList();
      } else if (isOnLogInError) {
        if (!zegoToken) {
          await generateToken();
        } else {
          await login(zegoToken);
        }
      }
      setIsOnLogInError(false);
      setIsOnQueryConversationListError(false);
    } catch (err) {
      setIsOnLogInError(true);
      setIsOnQueryConversationListError(true);
      console.error(err);
    }
  };

  const zegoErrorHandler = async (err: any, showDefaultMessage = false) => {
    let title = translate('error_system_error');
    let description = translate('error_system_error_description');

    switch (err?.code) {
      case 6000214: // ZIMErrorCode.MessageModuleFileSizeInvalid
        title = translate('error_max_file_size_title') as string;
        description = translate('error_max_file_size_description') as string;
        break;
      case 6000213: // ZIMErrorCode.MessageModuleFileTypeUnsupported
        title = translate('error_invalid_format') as string;
        description = translate('error_invalid_format_description') as string;
        break;
      case 6000106: // Token has expired. Please call the renewToken method to renew the Token and try again.
        await renewToken();
        return true;
    }

    if (showDefaultMessage) {
      errorToastMessage({
        message: (
          <ToastMessage type="error">
            {title}
            <span className="font-regular">{description}</span>
          </ToastMessage>
        ),
        className: 'flex gap-[0.5rem] w-[36.75rem] max-w-full',
        useDefaultClassNames: false,
        closeToast: closeToast,
      });
    }
  };

  const renewToken = async () => {
    setIsZegoLoading(true);
    try {
      // You can call the renewToken method to renew the token.
      // To generate a new Token, refer to the Prerequisites.
      const token = await generateToken();
      await zim.renewToken(token as string);
    } catch {}
    setIsZegoLoading(false);
  };

  useEffect(() => {
    login(zegoToken);
  }, [zegoToken]);

  useEffect(() => {
    zim.on('error', function (zego, errorInfo) {
      error('error code >>>', errorInfo.code);
      error('error message >>>', errorInfo.message);
    });

    zim.on('connectionStateChanged', function (zego, { state, event, extendedData }) {
      log('connectionStateChanged state >>>', { state: state, event: event, extendedData: extendedData });
      if (state == 0 && event == 3) {
        if (user.zegoToken) login(zegoToken, 'reconnect');
      }
    });

    zim.on('conversationChanged', function (zego, { infoList }) {
      log(' conversationChanged >>>>', infoList);
      onConvesationChanged(infoList);
    });

    zim.on('tokenWillExpire', renewToken);

    zim.on('groupAvatarUrlUpdated', (zego, { groupAvatarUrl, groupID }) => {
      log('groupAvatarUrlUpdated groupAvatarUrl >>>>', { groupAvatarUrl: groupAvatarUrl, groupID: groupID });
      updateDetailsOnGroupChanges(groupID, groupAvatarUrl, 'conversationAvatarUrl');
    });

    zim.on('groupNameUpdated', (zego, { groupName, groupID }) => {
      log('groupNameUpdated groupName >>>>', { groupName: groupName, groupID: groupID });
      updateDetailsOnGroupChanges(groupID, groupName, 'conversationName');
    });
  }, []);

  useEffect(() => {
    zim.on('receivePeerMessage', function (zego, { messageList, fromConversationID }) {
      log('receivePeerMessage messageList', { messageList: messageList, fromConversationID: fromConversationID });
      const lastMessage = messageList[messageList.length - 1]; // the last index of the messageList is the last message
      const lastConvoMessage = lastMessage;
      setReceivedPeerMessages(prevReceivedPeerMessages => {
        return [...(prevReceivedPeerMessages || []), ...messageList];
      });
      updateLastMessage(fromConversationID, lastConvoMessage);
    });

    zim.on('receiveGroupMessage', function (zego, { messageList, fromConversationID }) {
      const lastMessage = messageList[messageList.length - 1]; // the last index of the messageList is the last message
      const lastConvoMessage = lastMessage;
      setReceivedGroupMessages(prevReceivedGroupMessages => {
        return [...(prevReceivedGroupMessages || []), ...messageList];
      });
      updateLastMessage(fromConversationID, lastConvoMessage);
    });

    zim.on('messageReceiptChanged', function (_, data) {
      setMessageReceiptChangedList(prevMessageReceptChangedList => {
        return [...(prevMessageReceptChangedList || []), ...data.infos];
      });
    });

    // Receive the message recalling callback.
    zim.on('messageRevokeReceived', function (zim, { messageList }) {
      setMessageRevokedList(prevMessageRevokedList => {
        return [...(prevMessageRevokedList || []), ...messageList];
      });
    });
  }, []);

  const generateMessageForKickLeftAndRemoveUser = (userId: string, event: number, username: string, action: string) => {
    let message = translate('chat.you_left_group') as string;
    switch (event) {
      case 4: //KickedOut
        if (user.username === userId) {
          message = translate('chat.admin_removed_you') as string;
        } else {
          message = translate('chat.admin_removed_someone', { username: userId }) as string;
        }
        break;
      case 2:
        if (user.username !== userId && action === ChatEventType.LEFT_GROUP) {
          message = translate('chat.someone_left_chat', { username }) as string;
        } else if (action === ChatEventType.DISMISS_GROUP) {
          message = translate('chat.admin_removed_you') as string;
        }
        break;
    }
    return message;
  };

  const onGroupStateChanged = useCallback(
    (_: ZIM, { state, event, operatedInfo, groupInfo }: ZIMEventOfGroupStateChangedResult) => {
      log('groupStateChanged state >>>>', { state: state, event: event, operatedInfo: operatedInfo, groupInfo: groupInfo });
      if (event === 2) {
        const groupDataFromList = conversationList?.filter(conversation => conversation.conversationID === groupInfo.baseInfo.groupID);
        const dateNow = moment();
        const extendedDataAction = { action: ChatEventType.DISMISS_GROUP };

        const userLeftMessage: ZIMMessage = {
          localMessageID: generateGroupId(15, 'leftLocalMessageID'),
          messageID: generateGroupId(15, 'leftMessageID'), //MessageContent.tsx will depend on this for styling
          senderUserID: operatedInfo.userID,
          timestamp: dateNow.valueOf(),
          conversationID: groupInfo.baseInfo.groupID,
          conversationType: (groupDataFromList && groupDataFromList[0].type) || 2,
          direction: 1,
          sentStatus: 1,
          orderKey:
            (groupDataFromList && groupDataFromList[0].lastMessage?.orderKey && groupDataFromList[0].lastMessage?.orderKey + 100) || 100000,
          conversationSeq: 100,
          isUserInserted: false,
          isBroadcastMessage: false,
          receiptStatus: 0,
          reactions: [],
          type: 1,
          message: generateMessageForKickLeftAndRemoveUser(operatedInfo.userID, event, operatedInfo.userName, ChatEventType.DISMISS_GROUP),
          extendedData: JSON.stringify(extendedDataAction),
        };
        setReceivedGroupMessages(prevReceivedGroupMessages => {
          return [...(prevReceivedGroupMessages || []), userLeftMessage];
        });
        updateLastMessage(groupInfo.baseInfo.groupID, userLeftMessage);
      }
    },
    [conversationList],
  );

  useEffect(() => {
    zim.on('groupMemberStateChanged', (zego, data) => {
      if (data.event === 2 || data.event === 4) {
        data.userList.forEach(userLeft => {
          if ((data.event === 2 && userLeft.userID !== user.username) || data.event === 4) {
            const groupDataFromList = conversationList?.filter(conversation => conversation.conversationID === data.groupID);
            const dateNow = moment();
            const extendedDataAction = { action: data.event === 2 ? ChatEventType.LEFT_GROUP : ChatEventType.KICKED_OUT };
            const userLeftMessage: ZIMMessage = {
              localMessageID: generateGroupId(15, 'leftLocalMessageID'),
              messageID: generateGroupId(15, 'leftMessageID'), //MessageContent.tsx will depend on this for styling
              senderUserID: userLeft.userID,
              timestamp: dateNow.valueOf(),
              conversationID: data.groupID,
              conversationType: (groupDataFromList && groupDataFromList[0].type) || 2,
              direction: 1,
              sentStatus: 1,
              orderKey:
                (groupDataFromList && groupDataFromList[0].lastMessage?.orderKey && groupDataFromList[0].lastMessage?.orderKey + 100) ||
                100000,
              conversationSeq: 100,
              isUserInserted: false,
              isBroadcastMessage: false,
              receiptStatus: 0,
              reactions: [],
              type: 1,
              message: generateMessageForKickLeftAndRemoveUser(
                userLeft.userID,
                data.event,
                userLeft.userName,
                data.event === 2 ? ChatEventType.LEFT_GROUP : ChatEventType.KICKED_OUT,
              ),
              extendedData: JSON.stringify(extendedDataAction),
            };
            setReceivedGroupMessages(prevReceivedGroupMessages => {
              return [...(prevReceivedGroupMessages || []), userLeftMessage];
            });
            updateLastMessage(data.groupID, userLeftMessage);
          }
        });
        const updateMembersList = async () => {
          await queryGroupMemberList(data.groupID, { count: GROUP_MEMBER_COUNT, nextFlag: 0 });
        };
        updateMembersList();
      }
      setGroupMemberStateChangedList(prevGroupMemberStateChangedList => {
        return [...(prevGroupMemberStateChangedList || []), data];
      });
    });
    zim.on('groupStateChanged', onGroupStateChanged);
    zim.on('groupMemberInfoUpdated', (zim, data) => {
      setGroupMemberInfoUpdatedList(prevGroupMemberInfoUpdatedList => [...(prevGroupMemberInfoUpdatedList || []), data]);
    });
  }, []);

  useEffect(() => {
    setHasError(isOnLogInError || isOnQueryConversationListError);
  }, [isOnLogInError, isOnQueryConversationListError]);

  useEffect(() => {
    // Shows the error display when the page is offline
    if (!isOnline) {
      setIsOnLogInError(true);
    }
  }, [isOnline]);

  useEffect(() => {
    const initConversationListModified = async () => {
      const newConversationListModified = [...(conversationList || [])];
      if (!newConversationListModified) return conversationListModified;
      for (let i = 0; newConversationListModified.length > i; i++) {
        let memberRole: number | undefined = 0;
        if (newConversationListModified[i].type === 2 && newConversationListModified[i].lastMessage?.type === 31) {
          try {
            const lastMessage = newConversationListModified[i].lastMessage as ZIMRevokeMessage;
            const response = await queryGroupMemberInfo?.(lastMessage.operatedUserID, newConversationListModified[i].conversationID);
            memberRole = response?.userInfo.memberRole;
          } catch {}
        }
        let extendedData;
        if (newConversationListModified[i]?.lastMessage?.extendedData) {
          try {
            extendedData = JSON.parse(newConversationListModified[i]?.lastMessage?.extendedData + '');
          } catch {}
        }
        if (newConversationListModified[i] && newConversationListModified[i].lastMessage) {
          newConversationListModified[i].lastMessage!.message =
            newConversationListModified[i]?.lastMessage?.type === 1 // text message
              ? extendedData && extendedData.action === ChatEventType.NEW_JOINED && extendedData.message && extendedData.data
                ? translate(extendedData.message, { users: extendedData.data }) + ''
                : '' + newConversationListModified[i]?.lastMessage?.message
              : newConversationListModified[i]?.lastMessage?.type === 11 // image message
              ? '' + translate('connect.sent_image') // TODO: This is temporary text to display. Waiting for the prod team to confirm
              : newConversationListModified[i]?.lastMessage?.type === 31 && newConversationListModified[i].type === 2 && memberRole === 1
              ? '' + translate('chat.admin_removed_message')
              : '' + translate('connect.chat_cleared');
        }
      }
      setConversationListModified(() => newConversationListModified);
    };
    initConversationListModified();
  }, [conversationList]);

  return (
    <ConnectContext.Provider
      value={{
        zim,
        openChats,
        conversationList,
        receivedPeerMessages,
        receivedGroupMessages,
        messageReceiptChangedList,
        messageRevokedList,
        clearChatRes,
        groupNameRes,
        inviteUsersRes,
        queryMembersRes,
        groupMemberStateChangedList,
        groupAvatarChangeID,
        groupAvatarChange,
        groupMemberInfoUpdatedList,
        notificationSidebarStatus,
        blockTrigger,
        hasError,
        isZegoLoading,
        conversationListModified,
        isConversationListLoaded,
        setGroupNameRes,
        setInviteUsersRes,
        setQueryMembersRes,
        setClearChatRes,
        setConversationList,
        queryHistoryMessage,
        queryConversationList,
        openChat,
        closeChat,
        closeAllChat,
        createGroup,
        sendMessage,
        sendMediaMessage,
        deleteMessages,
        deleteAllMessage,
        deleteConversation,
        queryGroupInfo,
        updateGroupName,
        queryGroupMemberList,
        queryGroupMemberInfo,
        setGroupMemberNickname,
        setGroupMemberRole,
        transferGroupOwner,
        queryGroupMemberCount,
        queryUsersInfo,
        queryConversation,
        setReceivedPeerMessages,
        setReceivedGroupMessages,
        leaveGroup,
        queryConversationNotificationStatus,
        clearConversationUnreadMessageCount,
        dismissGroup,
        updateUserAvatarUrl,
        updateGroupAvatarUrl,
        kickGroupMembers,
        sendMessageReceipt,
        setMessageReceiptChangedList,
        revokeMessage,
        setMessageRevokedList,
        inviteUsersIntoGroup,
        setGroupMemberStateChangedList,
        setGroupMemberInfoUpdatedList,
        setNotificationSidebarStatus,
        setBlockTrigger,
        refreshLogin,
      }}
    >
      {children}
    </ConnectContext.Provider>
  );
};

export const useConnectProvider = (): UseConnectProvider => {
  const context = useContext(ConnectContext);
  if (!context) throw 'Please use a provider to use this hook';
  return context;
};
