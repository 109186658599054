import { IMAGES } from '@fe-monorepo/assets';
import { useCountdownDay } from '@fe-monorepo/hooks';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import { useMemo } from 'react';

import Image from '../../../components/Atoms/Media/Image';
import CCardImgOnlyDynamic from './CCardImgOnlyDynamic';

interface Props {
  bannerType: string;
  title: string;
  subTitle?: string;
  img?: any;
  bannerImg?: string;
  card_type?: string;
  timer?: string;
}

const CCardImgOnly = (props: Props) => {
  const expirationDate = useMemo(() => {
    const date = new Date();
    const expiryDate = new Date();
    expiryDate.setDate(date.getDate() + 5);
    return expiryDate;
  }, []);
  const { remainingDay } = useCountdownDay(expirationDate.toString());

  return (
    <div className="CCardImgOnly rounded-[8px] w-full hover:cursor-pointer">
      {props.bannerType === 'product-banner' ? (
        <div className="bg-[#2388FF] rounded-[8px] w-full hover:cursor-pointer text-white aspect-[282/424]">
          <div className="flex flex-col items-start flex-[1_0_0%] p-[1.5rem]">
            <div className="relative w-full">
              <div className="absolute top-0 left-0 bg-[#171619] border-solid border-[1px] border-white/[.90]">
                <p className="text-fs-body-small py-[0.25rem] px-[0.5rem] text-white font-medium ">-15%</p>
              </div>
              <Image img={IMAGES.arrow_xup_yright_white} divStyle="absolute top-0 right-0" imgStyle={`object-cover w-[2rem] h-[2rem]`} />
              <Image img={IMAGES.Card_Controller} divStyle="pt-[1.25rem] pb-[.5rem]" imgStyle={`object-cover w-full`} />
            </div>
            <div className="flex flex-col gap-[0.25rem] leading-[1.25rem] md:text-[4rem] w-full">
              <p className="px-[.125rem] py-[0rem] gap-[.5rem] rounded-[.125rem] text-white text-fs-body-small text-opacity-70 ">Sony</p>
              <p className="font-medium truncate text-fs-body-small">Dual Sense Edge Wireless Controller</p>
            </div>
            <div className="flex items-center gap-[.75rem] self-stretch text-black leading-[1.5rem] w-full py-[.25rem]">
              <CurrencyText className={'font-medium text-fs-body-large'} currency={879} />

              <CurrencyText className={'line-through font-regular text-opacity-70 text-fs-body text-secondary'} currency={1199} />
            </div>
            <div className="flex flex-col items-center gap-[.5rem] w-full mt-[1.75rem]">
              <div className="grid grid-cols-4 items-center gap-[.75rem]">
                <div className="flex flex-col items-center gap-[.625rem] flex-[1_0_0%] ">
                  <p className="font-medium text-fs-title tracking-[-0.02]">{remainingDay?.days}</p>
                  <p className="text-fs-caption font-regular">Days</p>
                </div>
                <div className="flex flex-col items-center gap-[.625rem] flex-[1_0_0%] ">
                  <p className="font-medium text-fs-title tracking-[-0.02]">{remainingDay?.hours}</p>
                  <p className="text-fs-caption font-regular">Hours</p>
                </div>
                <div className="flex flex-col items-center gap-[.625rem] flex-[1_0_0%] ">
                  <p className="font-medium text-fs-title tracking-[-0.02]">{remainingDay?.minutes}</p>
                  <p className="text-fs-caption font-regular">Minutes</p>
                </div>
                <div className="flex flex-col items-center gap-[.625rem] flex-[1_0_0%] ">
                  <p className="font-medium text-fs-title tracking-[-0.02]">{remainingDay?.seconds}</p>
                  <p className="text-fs-caption font-regular">Seconds</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <CCardImgOnlyDynamic
          bannerType={props.bannerType}
          subTitle={props.subTitle}
          title={props.title}
          img={props.img}
          bannerImg={props.bannerImg}
          card_type={props.card_type}
          timer={props.timer}
        />
      )}
    </div>
  );
};

export default CCardImgOnly;
