import { RankModel } from "@fe-monorepo/models";
import { useFetchQuery } from "../useFetchQuery";
import { TMS_RANK_QUERY } from '@fe-monorepo/data-access';

type RankInput = {
    tournament_id: string,
    page_number?: number,
}
  
type RankResponse = {
    final_standing : RankModel[]
}

const defaultValue: RankResponse = {
    final_standing : []
}


export const useRanking = () => {
  const getTournmentRanking = useFetchQuery<RankResponse, RankInput>(TMS_RANK_QUERY.tmsRankFinalStanding, defaultValue);
    return {
        getTournmentRanking
    }
}