import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { Player } from 'fl-player-interface';
import React, { useEffect, useRef } from 'react';

interface QualityPopUpProps {
  onClose: () => void;
  playerObject: Player;
  selectedQuality: string;
  setSelectedQuality: any;
}

const QualityPopUp = ({ onClose, playerObject, selectedQuality, setSelectedQuality }: QualityPopUpProps) => {
  const popUpRef = useRef<HTMLDivElement>(null);
  const { translate } = useTranslate();

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popUpRef.current && !popUpRef.current.contains(event.target as Node)) {
        onClose();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  const handleQualityChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const currentQuality = e.target.value;
    const bitratesDivider = Math.floor(playerObject.availableBitrates.length / 2);
    switch (currentQuality?.toUpperCase()) {
      case 'LOW':
        playerObject.maxBitrate = playerObject.availableBitrates[0];
        setSelectedQuality('LOW');
        break;
      case 'MEDIUM':
        playerObject.maxBitrate = playerObject.availableBitrates[bitratesDivider];
        setSelectedQuality('MEDIUM');
        break;
      case 'HIGH':
        playerObject.maxBitrate = playerObject.availableBitrates[playerObject.availableBitrates.length - 1];
        setSelectedQuality('HIGH');
        break;
      default:
        playerObject.maxBitrate = Infinity;
        setSelectedQuality('AUTO');
    }
  };

  return (
    <div className="z-10 h-[13rem] text-white flex items-end m-auto flex-col justify-between" ref={popUpRef}>
      <div className="w-[8.2rem] h-[10rem] bg-black-70 rounded-2xl flex justify-center items-center">
        <div>
          <ResponsiveIcon
            className="fill-primary flex justify-end cursor-pointer"
            name={IconNames.close}
            baseHeight={10}
            baseWidth={10}
            onClick={onClose}
          />
          <form className="flex flex-col justify-between items-start gap-10">
            {['auto', 'low', 'medium', 'high'].map((item, idx) => {
              const translationKey: any = 'ewc.Player.' + item;
              return (
                <>
                  <div key={idx}>
                    <input
                      type="radio"
                      id={item}
                      name="quality"
                      value={item}
                      checked={selectedQuality.toUpperCase() === item.toUpperCase()}
                      onChange={handleQualityChange}
                    />
                    <label className="ml-[0.875rem] cursor-pointer" htmlFor={item}>
                      {translate(translationKey)}
                    </label>
                  </div>
                </>
              );
            })}
          </form>
        </div>
      </div>
    </div>
  );
};

export default QualityPopUp;
