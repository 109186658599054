import { gql } from '@apollo/client';

export const MINI_GAMES_QUERY = {
  TmsGetAllMiniGames: gql`
    query TmsGetAllMiniGames($details: TmsGetAllMiniGamesInput) {
      TmsGetAllMiniGames(details: $details) {
        is_successful
        error_code
        error_msg
        app_code
        data {
          count
          data {
            name_ar
            name_en
            mini_game_code
            description_ar
            description_en
            img
            sort_id
            last_cursor
          }
        }
      }
    }
  `,

  TmsGetMiniGameInfo: gql`
    query TmsGetMiniGameInfo($details: TmsGetMiniGameInfoInput) {
      TmsGetMiniGameInfo(details: $details) {
        is_successful
        error_code
        error_msg
        app_code
        data {
          name_ar
          name_en
          mini_game_code
          description_ar
          description_en
          img
          sort_id
        }
      }
    }
  `,

  TmsGetMiniGameHighlighted: gql`
    query TmsGetMiniGameHighlighted {
      TmsGetMiniGameHighlighted {
        is_successful
        error_code
        error_msg
        app_code
        data {
          name_ar
          name_en
          mini_game_code
          description_ar
          description_en
          img
        }
      }
    }
  `,

  TmsGetMiniGameLeaderboard: gql`
    query TmsGetMiniGameLeaderboard($details: TmsGetMiniGameLeaderboardInput) {
      TmsGetMiniGameLeaderboard(details: $details) {
        is_successful
        error_code
        error_msg
        app_code
        data {
          user_rank {
            totalWins
            userName
            profileImage
            rank
          }
          leaderboard {
            display_name
            username
            rank
            total_wins
            avatar_url
          }
        }
      }
    }
  `,
};
