export const Logger = () => {
  const environment = process.env.NX_APP_ENVIRONMENT ? process.env.NX_APP_ENVIRONMENT : 'development';

  const info = (message?: string, logValue?: any) => {
    if (environment === 'production' || (!message && !logValue)) return;
    if (!message || message === '') return console.info(`console.info: `, logValue);
    if (!logValue) return console.info(`${message}`);
    return console.info(`${message}: `, logValue);
  };

  const log = (message?: string, logValue?: any) => {
    if (environment === 'production' || (!message && !logValue)) return;
    if (!message || message === '') return console.log(`console.log: `, logValue);
    if (!logValue) return console.log(`${message}`);
    return console.log(`${message}: `, logValue);
  };

  const debug = (message?: string, logValue?: any) => {
    if (environment === 'production' || (!message && !logValue)) return;
    if (!message || message === '') return console.debug(`console.debug: `, logValue);
    if (!logValue) return console.debug(`${message}`);
    return console.debug(`${message}: `, logValue);
  };

  const warn = (message?: string, logValue?: any) => {
    if (environment === 'production' || (!message && !logValue)) return;
    if (!message || message === '') return console.warn(`console.warn: `, logValue);
    if (!logValue) return console.warn(`${message}`);
    return console.warn(`${message}: `, logValue);
  };

  const error = (message?: string, logValue?: any) => {
    if (environment === 'production' || (!message && !logValue)) return;
    if (!message || message === '') return console.error(`console.error: `, logValue);
    if (!logValue) return console.error(`${message}`);
    return console.error(`${message}: `, logValue);
  };

  return {
    info,
    log,
    debug,
    warn,
    error,
  };
};
