/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from '@apollo/client';
import { FEATURE_FLAG_QUERY } from '@fe-monorepo/data-access';
import { FeatureFlag } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useAppState } from '../useAppState/useAppState';

export const useFeatureFlag = () => {
  const { changeFeatureFlags } = useAppState();

  const [fetchFeatureFlags, { data: featureFlagsResponse, error: featureFlagsError }] = useLazyQuery(FEATURE_FLAG_QUERY.getFeatureFlags, {
    errorPolicy: 'all',
  });
  const featureFlags = useSelector((state: RootState) => state.app.featureFlags);

  useEffect(() => {
    if (featureFlagsResponse) {
      changeFeatureFlags(featureFlagsResponse?.getFeatureFlags?.data || []);
    }
  }, [featureFlagsResponse]);

  const getFeatureFlags = async () => {
    await fetchFeatureFlags();
  };

  const getFlagByKey = (flagKey: string) => {
    const flag = featureFlags?.find((flag: FeatureFlag) => flag.key === flagKey);
    if (!flag) {
      return false;
    }
    return flag.value;
  };

  return {
    getFeatureFlags,
    getFlagByKey,
  };
};
