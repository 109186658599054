import { convertGMTToIana, getCurrentUserTimeZone } from '@fe-monorepo/helper';
import { RootState } from '@fe-monorepo/store';
import { parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { useSelector } from 'react-redux';

export enum CTATypes {
  SOON = 'soon',
  NOW = 'now',
  WATCH = 'watch',
  NONE = 'none',
}

export const useMatchCTA = (dateTime: string, url?: string) => {
  const preferred_timezone = useSelector((state: RootState) => state.user.userContext.preferred_timezone);

  const finalTimezone = preferred_timezone ? convertGMTToIana(preferred_timezone) : getCurrentUserTimeZone();

  const parsedDate = parseISO(dateTime);
  const zonedDate = toZonedTime(parsedDate, finalTimezone);

  const currentTime = toZonedTime(new Date(), finalTimezone).getTime();
  let ctaType: CTATypes = CTATypes.NONE;
  const startTimestamp = zonedDate.getTime();
  const seventyTwoHoursInMillis = 72 * 60 * 60 * 1000;

  if (currentTime < startTimestamp - seventyTwoHoursInMillis) {
    ctaType = CTATypes.SOON;
  } else if (currentTime < startTimestamp) {
    ctaType = CTATypes.NOW;
  } else if (currentTime >= startTimestamp && !!url?.length) {
    ctaType = CTATypes.WATCH;
  }

  return {
    ctaType,
  };
};
