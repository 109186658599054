import { Oval } from 'react-loader-spinner';

interface LoadingScreenProps {
  isLoading: boolean;
}

const LoadingScreen = ({ isLoading }: LoadingScreenProps) => (
  <>
    {isLoading && (
      <div className="fixed inset-0 flex items-center justify-center z-[9999]">
        <div className="modal-overlay fixed inset-0 bg-primary opacity-30"></div>
        <Oval
          height={80}
          width={80}
          color="#E95F2A"
          secondaryColor="#E95F2A"
          visible={true}
          ariaLabel="oval-loading"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      </div>
    )}
  </>
);

export default LoadingScreen;
