import { LottieIconAnimations } from '@fe-monorepo/assets';
import { IconNames, TxKeyPath } from '@fe-monorepo/helper';
import { useGetRetailInfo, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { OrderReturnModel, ReturnItemStatus, ReturnNumModel } from '@fe-monorepo/models';
import { StepModel } from '@fe-web/Molecules/TrackingBar/types';
import YellowMessage from '@fe-web/Molecules/YellowMessage';
import Modal from '@fe-web/Templates/Modal/Modal';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import { useEffect, useState } from 'react';

import { GetReturnStatus, StatusCode } from '../../../../../lib/GetReturnStatus';
import { generateRandomId } from '../../../../../lib/helper';
import ReturnsDetailsItems from '../component/ReturnsItem';
import EditPhotos from './EditPhotos';
import ReturnProductDetails from './ReturnProductDetails';

interface OrdersProps {
  order: OrderReturnModel;
  isHeaderDark?: boolean;
  showDetails: (invoice: string) => void;
  language: 'en' | 'ar';
  id: number;
}

interface ProductForEditPhotos {
  images: any[];
  product_code: string;
  imageUrl: string;
  name: string;
  reason: string;
  reason_code: string;
  return_product_id: string;
}

const ReturnData = ({ order, showDetails, language, id }: OrdersProps) => {
  const isMobile = useMobileDetect();
  const [detailsVisible, setDetailsVisible] = useState(false);
  const { translate } = useTranslate();
  const [showPlus, setShowPlus] = useState(true);
  const [openEditPhotosModal, setOpenEditPhotosModal] = useState<boolean>(false);
  const { user } = useUserProfile();
  const [returnOrdersDetails, setReturnOrdersDetails] = useState<ReturnNumModel[] | null>([]);
  const [slicedList, setSlicedlist] = useState<StepModel[]>([]);
  const { getRetailInfo, getRetailInfoData } = useGetRetailInfo();

  interface StepModelRetruns {
    label: TxKeyPath;
    icon?: IconNames;
    labelArgs?: string[];
    date?: Date | string;
    lottieicon?: string;
    onClick?: () => void;
  }
  const { return_number, total_amount, created_at, updated_at, media } = order;

  const date = new Date(updated_at);

  const steps: StepModelRetruns[] = [
    {
      label: 'shop.cart.stepper.requested',
      icon: IconNames.emailChecked,
      lottieicon: LottieIconAnimations.Icon_Email,
      date: date.toISOString(),
    },
    {
      label: 'shop.cart.stepper.under_process',
      icon: IconNames.approved,
      lottieicon: LottieIconAnimations.Icon_HourGlass,
      date: date.toISOString(),
    },
    {
      label: 'shop.cart.stepper.in_transit',
      icon: IconNames.deliveryTruck,
      lottieicon: LottieIconAnimations.Icon_VanReturn,
      date: date.toISOString(),
    },
    {
      label: 'shop.cart.stepper.under_process',
      icon: IconNames.approved,
      lottieicon: LottieIconAnimations.Icon_HourGlass,
      date: date.toISOString(),
    },
    {
      label: 'shop.cart.stepper.refunded',
      icon: IconNames.deliveryTruck,
      lottieicon: LottieIconAnimations.Icon_Cash,
      date: date.toISOString(),
    },
  ];

  const subData: StepModelRetruns[] = [
    {
      label: 'shop.cart.stepper.rejected1',
      icon: IconNames.shoppingCart,
      lottieicon: LottieIconAnimations.Icon_Rejected,
      date: date.toISOString(),
    },
    {
      label: 'shop.cart.stepper.approved1',
      icon: IconNames.approved,
      lottieicon: LottieIconAnimations.Icon_approved,
      date: date.toISOString(),
    },
    {
      label: 'shop.cart.stepper.in_transit',
      icon: IconNames.deliveryTruck,
      lottieicon: LottieIconAnimations.Icon_VanReturn,
      date: date.toISOString(),
    },
    {
      label: 'shop.cart.stepper.action_required',
      icon: IconNames.deliveryTruck,
      lottieicon: LottieIconAnimations.Icon_VanReturn,
      date: date.toISOString(),
    },
    {
      label: 'shop.cart.stepper.approved2',
      icon: IconNames.approved,
      lottieicon: LottieIconAnimations.Icon_approved,
      date: date.toISOString(),
    },
  ];

  const languages = usePageLayout();
  const isEnglish = languages?.language === 'en';

  const [productForEditPhotos, setProductForEditPhotos] = useState<ProductForEditPhotos>({
    images: [
      ...Array(4).map(() => {
        file: '';
      }),
    ],
    product_code: '',
    imageUrl: '',
    name: '',
    reason: '',
    reason_code: '',
    return_product_id: '',
  });

  useEffect(() => {
    if (getRetailInfoData?.data) {
      setReturnOrdersDetails(getRetailInfoData.data);
    } else {
      setReturnOrdersDetails(null);
    }
  }, [getRetailInfoData]);

  let productsData: any[] = [];

  if (returnOrdersDetails) {
    productsData = Array.isArray(returnOrdersDetails) ? returnOrdersDetails : [returnOrdersDetails];
  }

  const productDataDetails = productsData?.map(item => item.products);

  const handleDetailsButtonClick = () => {
    if (showPlus) {
      mixpanelHelper.trackPageView('RETURN DETAILS PAGE', 'my_orders_return_tab_rdp', window.location.pathname, document.referrer);
      brazeHelper.trackPageView('RETURN DETAILS PAGE', 'my_orders_return_tab_rdp', window.location.pathname, document.referrer);
      showDetails(order.return_number);
      if (user?.token) {
        getRetailInfo({
          return_number: return_number,
        });
      }
    }
    setDetailsVisible(!detailsVisible);
    setShowPlus(!showPlus);
  };

  const yellowMessage = (id: number) => {
    if (randomStep === 2) {
      return <YellowMessage message={translate('shop.cart.yellow_message.pickup') ?? ''} />;
    } else if (id === 6) {
      return (
        <YellowMessage
          message={
            translate(order.items.length === 1 ? 'shop.cart.yellow_message.reject_one_item' : 'shop.cart.yellow_message.reject', {
              reason: '',
            }) ?? ''
          }
          hoverText={translate('common_contactUs') ?? ''}
          navigateUrl={AppRoutes.contactUS}
        />
      );
    } else if (id === 8) {
      return (
        <YellowMessage
          message={translate('shop.cart.yellow_message.reject_b') ?? ''}
          hoverText={translate('common_contactUs') ?? ''}
          navigateUrl={AppRoutes.contactUS}
        />
      );
    } else if (id === 11) {
      return <YellowMessage message={translate('shop.cart.yellow_message.action_required') ?? ''} />;
    } else if (id === 43) {
      return <YellowMessage message={translate('shop.cart.yellow_message.approved_final') ?? ''} />;
    } else if (id === 26) {
      return <YellowMessage message={translate('shop.cart.yellow_message.approved_partially') ?? ''} />;
    }
  };

  const statusCode = order.items.map(item => item.status_code);
  const statusAction = order.items.map(item => item.status_code);

  const randomStep = id < 5 ? id : id % 5;

  const stepCheck = randomStep <= 1 ? 2 : randomStep + 1;

  useEffect(() => {
    if (id === 6 || id === 8) {
      id === 8 && steps.splice(1, 1, subData[1]);
      setSlicedlist([...steps.slice(0, stepCheck - 1), subData[0]]);
    } else if (id === 43) {
      let tempSteps = [...steps];
      tempSteps = [...tempSteps.slice(0, stepCheck - 1), subData[4]];
      tempSteps.splice(1, 1, subData[1]);
      setSlicedlist([...tempSteps]);
    } else if (randomStep > 1) {
      steps.splice(1, 1, subData[1]);
      if (randomStep > 3) {
        steps.splice(3, 1, subData[1]);
      }
      if (randomStep > 2) {
        steps.splice(2, 1, subData[2]);
      }
      setSlicedlist(steps.slice(0, stepCheck));
    } else if (id === 11) {
      setSlicedlist([...steps.slice(0, stepCheck - 1), subData[3]]);
    } else if (id === 26) {
      setSlicedlist([...steps.slice(0, stepCheck - 1), subData[1]]);
    } else {
      setSlicedlist(steps.slice(0, stepCheck));
    }
  }, []);

  const handleEditPhotosBtn = (images: string[], product_code: string, name: string, imgUrl: string, reason: string) => {
    const formatImages = images.map(item => {
      return { file: item, id: generateRandomId(5) };
    });
    setProductForEditPhotos({
      images: formatImages,
      product_code: product_code,
      name: name,
      imageUrl: imgUrl,
      reason: reason,
      reason_code: '',
      return_product_id: '',
    });
    setOpenEditPhotosModal(true);
  };

  type ReasonCode =
    | 'changed_their_mind'
    | 'damaged_item'
    | 'missing_parts_or_accessories'
    | 'different_order'
    | 'item_not_as_described'
    | 'defective_item';
  type Language = 'ar' | 'en';

  type ReturnReason = {
    return_reason_code: ReasonCode;
    return_reason_ar: string;
    return_reason_en: string;
  };

  const returnReasons: ReturnReason[] = [
    {
      return_reason_code: 'changed_their_mind',
      return_reason_ar: 'قمت بتغيير رأيي',
      return_reason_en: 'I changed my mind',
    },
    {
      return_reason_code: 'damaged_item',
      return_reason_ar: 'استلمت منتج تالف',
      return_reason_en: 'I received a damaged item',
    },
    {
      return_reason_code: 'missing_parts_or_accessories',
      return_reason_ar: 'استلمت منتج يحتوي على أجزاء أو ملحقات مفقودة',
      return_reason_en: 'I received an item with missing parts or accessories',
    },
    {
      return_reason_code: 'different_order',
      return_reason_ar: 'استلمت منتج مختلف عما تم طلبه',
      return_reason_en: 'I received an item different from what was ordered',
    },
    {
      return_reason_code: 'item_not_as_described',
      return_reason_ar: 'استلمت منتج يختلف عما هو مذكور في وصف المنتج',
      return_reason_en: 'I received an item that is not as described',
    },
    {
      return_reason_code: 'defective_item',
      return_reason_ar: 'استلمت منتج وبه خلل',
      return_reason_en: 'I received a defective item',
    },
  ];

  function getReturnReason(reason_code: ReasonCode, language: Language): string | undefined {
    const reason = returnReasons.find(r => r.return_reason_code === reason_code);
    return reason ? reason[`return_reason_${language}`] : undefined;
  }

  const reasonCode = productsData?.map(item => item.products.map((item: { return_reason_code: any }) => item.return_reason_code)).flat();
  const returnProductID = productsData?.map(item => item.products.map((item: { return_product_id: any }) => item.return_product_id)).flat();
  type Item = {
    status: StatusCode;
  };

  type StatusCodeEmpty = 'open' | 'action_required' | 'complete';

  type ReturnStatusEmpty = {
    return_status_code: StatusCodeEmpty;
    return_status_name: string | null;
  };

  const returnStatusEmpty: ReturnStatusEmpty[] = [
    {
      return_status_code: 'action_required',
      return_status_name: translate('returns_action_required'),
    },
    {
      return_status_code: 'complete',
      return_status_name: translate('returns_action_approved'),
    },
    {
      return_status_code: 'open',
      return_status_name: translate('returns_action_in_submitted'),
    },
  ];

  function translateStatus(return_status_code: string): string {
    return translate(`${ReturnItemStatus[return_status_code as keyof typeof ReturnItemStatus]}`) ?? '';
  }

  function checkStatus(items: any) {
    if (items.length === 0) {
      const statusObject = returnStatusEmpty.find(status => status.return_status_code === order.status_code);
      if (statusObject) {
        return {
          statusName: statusObject.return_status_name,
          statusCode: statusObject.return_status_code,
        };
      }
    }
  }

  const itemsArray: Item[] = statusCode.map(status => ({ status: status as StatusCode }));
  const status = itemsArray.length > 0 ? GetReturnStatus(itemsArray) : checkStatus(itemsArray);

  return (
    <>
      <div className="details__div mb-[2rem] SD:mb-0 flex flex-col col-span-5">
        <ReturnsDetailsItems
          idinfo={return_number}
          productCount={order.media.length}
          createdAtdate={created_at}
          updatedAtdate={updated_at}
          total={Number(total_amount)}
          status={status?.statusName ?? ''}
          status_code={status?.statusCode ?? ''}
          products={media}
          handleDetailsButtonClick={handleDetailsButtonClick}
          showPlus={showPlus}
          isMobile={isMobile}
        />
      </div>
      {detailsVisible && (
        <>
          {yellowMessage(id)}
          <div className={`flex flex-col justify-end col-span-4 SD:col-span-3`}>
            {isMobile && <span className="border-b-[.25px] border-secondary/10 col-span-5"></span>}
            <ReturnProductDetails
              products={
                productDataDetails?.flat().map(product => {
                  return {
                    name: isEnglish ? product.name_en : product.name_ar,
                    quantity: 1,
                    price: Number(product.amount),
                    image: product.product_media,
                    totalPrice: Number(product.amount),
                    media: [...product.return_media],
                    product_code: product.return_reason_code,
                    status_code: status?.statusCode || '',
                    status: translateStatus(product.status_code),
                    item_status_code: product.status_code,
                    productID: product.return_product_id,
                    reason: getReturnReason(product.return_reason_code, language) as string,
                  };
                }) || []
              }
              totalAmount={productsData?.reduce((total, product) => total + Number(product.total_amount), 0) || 0}
              isMobile={isMobile}
              onClickEditPhotos={handleEditPhotosBtn}
            />
          </div>
        </>
      )}
      <Modal open={openEditPhotosModal}>
        <EditPhotos
          close={() => setOpenEditPhotosModal(false)}
          product={productForEditPhotos}
          setOpenEditPhotosModal={setOpenEditPhotosModal}
          reason_code={reasonCode[0] as string}
          return_product_id={returnProductID[0] as string}
        />
      </Modal>
    </>
  );
};

export default ReturnData;
