import { UserModel } from '@fe-monorepo/models';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// This is a type over interface else it causes errors in store.ts
type SliceState = {
  userContext: UserModel;
  persona?: 'guest' | 'new' | 'existing' | '';
  token: string;
  isUserNameUpdateRequired?: boolean;
};

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userContext: {
      uuid: '',
      isTokenVerified: false,
      token: '',
      display_name: '',
      username: '',
      email: '',
      mobile_code: '',
      mobile: '',
      gamer_type: '',
      avatar_url: '',
      cover_banner_url: '',
      bio: '',
      provider: '',
      gender_code: '',
      is_official_account: 0,
      points: 0,
      is_2FA_required: 0,
      is_email_verified: 0,
      is_mobile_verified: 0,
      referral_code: '',
      preferred_language: '',
      preferred_theme: '',
      preferred_timezone: '',
      preferred_2FA_channel: 'email',
      country_code: '',
      reference_wallet_id: '',
      total_followers: 0,
      total_following: 0,
      password_updated_at: '',
      mobile_updated_at: '',
      email_updated_at: '',
      birthdate: '',
      preferred_currency: '',
      preferred_country: '',
      userId: '',
      firstName: '',
      lastName: '',
      identifier: '',
      is_matchmaking_enabled: 0,
      viewer_is_following: 0,
      viewer_is_follower: 0,
      preferred_private_chat_code: '',
      preferred_private_chat_name_ar: '',
      preferred_private_chat_name_en: '',
      preferred_group_chat_code: '',
      preferred_group_chat_name_ar: '',
      preferred_group_chat_name_en: '',
      is_read_status_enabled: 1,
      privacy_consent: true,
      marketing_consent: true,
    },
    persona: 'guest',
  } as SliceState,
  reducers: {
    setUser: (state, action: PayloadAction<UserModel>) => {
      state.userContext = {
        ...state.userContext,
        ...action.payload,
      };
    },
    clearUser: state => {
      state.userContext = {
        uuid: '',
        isTokenVerified: false,
        token: '',
        display_name: '',
        username: '',
        email: '',
        mobile_code: '',
        mobile: '',
        gamer_type: '',
        avatar_url: '',
        cover_banner_url: '',
        bio: '',
        provider: '',
        gender_code: '',
        is_official_account: 0,
        points: 0,
        is_2FA_required: 0,
        is_email_verified: 0,
        is_mobile_verified: 0,
        referral_code: '',
        preferred_language: '',
        preferred_theme: '',
        preferred_timezone: '',
        preferred_currency: '',
        preferred_country: '',
        preferred_2FA_channel: 'email',
        country_code: '',
        reference_wallet_id: '',
        total_followers: 0,
        total_following: 0,
        password_updated_at: '',
        mobile_updated_at: '',
        email_updated_at: '',
        birthdate: '',
        userId: '',
        firstName: '',
        lastName: '',
        identifier: '',
        viewer_is_following: 0,
        viewer_is_follower: 0,
        preferred_private_chat_code: '',
        preferred_private_chat_name_ar: '',
        preferred_private_chat_name_en: '',
        preferred_group_chat_code: '',
        preferred_group_chat_name_ar: '',
        preferred_group_chat_name_en: '',
        is_read_status_enabled: 1,
        privacy_consent: true,
        marketing_consent: true,
      };
      state.token = '';
    },
    updateUser: (state, action: PayloadAction<UserModel>) => {
      state.userContext = {
        ...state.userContext,
        ...action.payload,
      };
    },
    setToken: (state, action: PayloadAction<SliceState['token']>) => {
      const token = action.payload;
      state.userContext = {
        ...state.userContext,
        token,
      };
    },
    verifiyToken: (state, action: PayloadAction<UserModel>) => {
      const isTokenVerified = action.payload.isTokenVerified;
      state.userContext.isTokenVerified = isTokenVerified;
    },
    setPersona: (state, action: PayloadAction<SliceState['persona']>) => {
      state.persona = action.payload;
    },
    setUserNameUpdateRequired: (state, action: PayloadAction<SliceState['isUserNameUpdateRequired']>) => {
      state.isUserNameUpdateRequired = action.payload;
    },
    update2FAChannel: (state, action: PayloadAction<string>) => {
      state.userContext = { ...state.userContext, preferred_2FA_channel: action.payload };
    },
    update2FARequired: (state, action: PayloadAction<number>) => {
      state.userContext = { ...state.userContext, is_2FA_required: action.payload };
    },
  },
});

export const { setUser, clearUser, updateUser, setPersona, setToken, setUserNameUpdateRequired, update2FAChannel, update2FARequired } =
  userSlice.actions;
