import { RootState, setSearchInputEnableRecentSearch, setSearchInputValue } from '@fe-monorepo/store';
import { useDispatch, useSelector } from 'react-redux';

const useSearchInput = () => {
  const dispatch = useDispatch();
  const searchInputEnableRecentSearch = useSelector((state: RootState) => state.app.searchInputEnableRecentSearch);
  const searchInputValue = useSelector((state: RootState) => state.app.searchInputValue);

  return {
    value: searchInputValue,
    setValue: (value: string) => dispatch(setSearchInputValue(value)),
    enableRecentSearch: searchInputEnableRecentSearch,
    setEnableRecentSearch: (value: boolean) => dispatch(setSearchInputEnableRecentSearch(value)),
  };
};

export default useSearchInput;
