export interface UserListItemModel {
    avatar?: string;
    username?: string;
    displayName?: string;
}

export enum ChatType {
    NEW = 0,
    EXISTING = 1,
}

export interface Chat {
    type: ChatType;
    conversationID?: string;
    conversationType?: number;
}

export enum ChatEventType {
    NEW_JOINED = 'new_joined',
    SET_ADMIN = 'set_admin',
    LEFT_GROUP = 'left_group',
    DISMISS_GROUP = 'dismiss_group',
    KICKED_OUT = 'kicked_out',
    LEFT_AS_ADMIN = 'left_as_admin',
    MESSAGE_REVOKED_BY_ADMIN = 'message_revoked_by_admin'
}
