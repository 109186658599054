import { IMAGES } from '@fe-monorepo/assets';
import { IMAGE_ALT_LABELS, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import PrimaryButton from '@fe-web/Atoms/Buttons/PrimaryButton';
import Image from '@fe-web/Atoms/Media/Image';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';

import { getReturnStatusStyles } from '../../../../../lib/GetReturnStatus';

interface Product {
  name: string;
  quantity: number;
  price: number;
  totalPrice: number;
  image?: any;
  media: string[];
  product_code: string;
  reason: string;
  status: string;
  status_code: string;
  productID: string;
  item_status_code: string;
}

interface ReturnProductDetailsProps {
  products: Product[];
  totalAmount: number;
  isMobile?: boolean;
  onClickEditPhotos?: (images: string[], product_code: string, name: string, imgUrl: string, reason: string) => void;
}

const ReturnProductDetails = ({ products, totalAmount, isMobile, onClickEditPhotos }: ReturnProductDetailsProps) => {
  const { translate } = useTranslate();

  return (
    <div className="flex flex-col text-secondary my-[2rem] SD:mt-[0rem]">
      <div className="flex flex-row mb-[1.5rem]">
        <h3 className="text-fs-body-large font-medium">{translate('shop.cart.order_main.products')}</h3>
      </div>
      <div className="flex flex-col gap-[1.5rem] divide-y divide-secondary/[0.1]">
        {products?.map(
          ({ image, name, quantity, price, totalPrice, media, product_code, reason, status, productID, item_status_code }, index) => {
            const colorClasses = getReturnStatusStyles(item_status_code);
            const isRejected =
              item_status_code === 'rejected_by_stcplay' ||
              item_status_code === 'rejected_by_channels' ||
              item_status_code === 'rejected_by_dal' ||
              item_status_code === 'closed' ||
              item_status_code === 'failed_screening' ||
              item_status_code === 'return_rejected' ||
              item_status_code === 'rejected' ||
              item_status_code === 'action_required' ||
              item_status_code === 'pending_clarification';
            return (
              <>
                <div className="flex flex-col gap-[1.5rem] mb-[.5rem]" key={index}>
                  <div className={`flex flex-row justify-between gap-[1rem] items-center ${index > 0 ? ' mt-[2rem]' : ''}`}>
                    <div className="flex flex-col">
                      <span className="text-secondary opacity-[0.70] font-regular text-fs-body-small">{translate('returns_ID')}</span>
                      <span className="text-secondary font-regular text-fs-body-small">{productID}</span>
                    </div>
                    <div>
                      <span
                        className={`py-[0.125rem] px-[0.5rem] border-[0.063rem] rounded-[0.125rem] font-medium text-fs-body-small truncate ${colorClasses}`}
                      >{`${status}`}</span>
                    </div>
                  </div>
                  <div className="flex flex-row gap-[1rem]">
                    <Image
                      img={image}
                      alt={translate(IMAGE_ALT_LABELS.return_product_thumbnail as TxKeyPath) ?? ''}
                      divStyle="w-[3.25rem] h-[3.25rem] bg-whiteSmoke rounded-[0.25rem] overflow-hidden"
                      imgStyle="w-full h-full object-contain"
                      fallback={
                        <Image
                          img={IMAGES.Product_Placeholder}
                          alt={translate(IMAGE_ALT_LABELS.return_product_thumbnail as TxKeyPath) ?? ''}
                          divStyle="w-[3.25rem] h-[3.25rem] bg-whiteSmoke rounded-[0.25rem] overflow-hidden"
                          imgStyle="w-full h-full object-contain"
                        />
                      }
                    />
                    <div className="flex flex-1 flex-col gap-[0.75rem]">
                      <div className="flex flex-col gap-[0.5rem]">
                        <p className="font-medium text-fs-body-small">{name}</p>
                        <div className="flex justify-between">
                          <p className="font-regular text-fs-caption">
                            {quantity} x <CurrencyText currency={price} />
                          </p>
                          <p className={`font-medium text-fs-body-small ${isRejected ? 'text-secondary/[0.42]' : 'text-secondary'}`}>
                            <CurrencyText currency={totalPrice} />
                          </p>
                        </div>
                      </div>
                      <p className="font-regular text-fs-caption">
                        <strong>{translate('shop.cart.returns_or_exchange.reason')}:</strong> {reason}
                      </p>
                      <div className="flex flex-wrap gap-[0.75rem]">
                        {media?.map(image => (
                          <Image
                            img={image}
                            alt={translate(IMAGE_ALT_LABELS.return_product_uploaded_image as TxKeyPath) ?? ''}
                            divStyle="w-[2.5rem] h-[2.5rem] bg-whiteSmoke rounded-[0.25rem] overflow-hidden"
                            imgStyle="w-full h-full object-contain"
                            fallback={
                              <Image
                                img={IMAGES.Product_Placeholder}
                                alt={translate(IMAGE_ALT_LABELS.return_product_uploaded_image as TxKeyPath) ?? ''}
                                divStyle="w-[2.5rem] h-[2.5rem] bg-whiteSmoke rounded-[0.25rem] overflow-hidden"
                                imgStyle="w-full h-full object-contain"
                              />
                            }
                          />
                        ))}
                      </div>
                      {!isMobile && (item_status_code === 'action_required' || item_status_code === 'submitted') && (
                        <div>
                          <PrimaryButton
                            action={() => {
                              onClickEditPhotos && onClickEditPhotos(media, product_code, name, image, reason);
                            }}
                            text={translate('shop.returns_page.edit_photos') ?? ''}
                            className={`px-[1.5rem] py-[0.5rem] text-fs-body`}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {isMobile && (item_status_code === 'action_required' || item_status_code === 'submitted') && (
                  <PrimaryButton
                    action={() => {
                      onClickEditPhotos && onClickEditPhotos(media, product_code, name, image, reason);
                    }}
                    text={translate('shop.returns_page.edit_photos') ?? ''}
                    className={`px-[1.5rem] py-[0.5rem] text-fs-body`}
                  />
                )}
              </>
            );
          },
        )}
        <div className="flex pt-[2rem] pr-[1rem]">
          <div className="flex-1 flex flex-col gap-[0.125rem]">
            <p className="font-medium text-fs-body-small">{translate('shop.cart.returns_or_exchange.total_for')}</p>
            <p className="font-regular text-fs-caption text-neutral-400-zinc-500 pr-[1rem]">
              {translate('shop.cart.returns_or_exchange.total_for_caption')}
            </p>
          </div>
          {totalAmount && (
            <p className="font-medium text-fs-body-large">
              <CurrencyText currency={totalAmount} />
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReturnProductDetails;
