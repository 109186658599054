import { RootState } from '@fe-monorepo/store';
import { t } from 'i18next';
import { useSelector } from 'react-redux';

import LookSimilarButton from './LookSimiliarButton';

interface Props {
  navigateToSimilarProducts: () => void;
}
const OutOfStockSection = (props: Props) => {
  const isRTL = useSelector((state: RootState) => state.app.isRTL);

  return (
    <div className="w-full SD:mt-[65px] 2K:mt-[115.55px] 4K:mt-[173.33px] 8K:mt-[346.66px]">
      <p className="font-medium text-secondary responsive-text-subtitle">{t('shop.productPage.outOfStock.message')}</p>

      <p className="text-secondary font-regular responsive-text-body mt-16 2K:mt-[28.44px] 4K:mt-[42.66px] 8K:mt-[85.33px]">
        {t('shop.productPage.outOfStock.notAvailableInYourArea')}
      </p>

      <LookSimilarButton dir={!isRTL ? 'rtl' : 'ltr'} action={props.navigateToSimilarProducts} />
    </div>
  );
};

export default OutOfStockSection;
