import { useShopInfo } from '@fe-monorepo/hooks';
import { MenuItem } from '@fe-monorepo/models';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from '../../../../../app/app.routes.enum';

// Define the props interface
interface ProductComponentProps {
  brandName: string;
  ctaLink: string;
  productName: string;
  price: number;
  sellingPrice: number;
  vatIncluded: any;
  collection_code?: string;
  isTaxable: number;
}

type ProductInfo = {
  code: string;
  name_ar: string;
  name_en: string;
};

type SimplifiedShopCategory = {
  menu_code: string;
  name_en: string;
  name_ar: string;
  collection_id: number;
  collection_code: string;
};

const defaultValue = {
  menu_code: '',
  name_en: '',
  name_ar: '',
  menu_items: [],
};

const useAllShopCategories = () => {
  const { getAll, info, SHOP_MENU_CODE } = useShopInfo();

  const [value, setValue] = useState<SimplifiedShopCategory[]>([]);

  // Turns the categories tree into an array
  const flattenCategoriesTree = (shopCategories: MenuItem[]): SimplifiedShopCategory[] => {
    // Base case
    if (shopCategories.length === 0) {
      return [];
    }

    const mainCategories: SimplifiedShopCategory[] = shopCategories.flatMap(category => {
      const { name_ar, name_en, menu_code, collection_id, collection_code } = category;

      const shopCategory: SimplifiedShopCategory = {
        name_ar,
        name_en,
        menu_code,
        collection_id,
        collection_code,
      };

      // Recursive case
      return [shopCategory, ...flattenCategoriesTree(category.menu_items ?? [])];
    });

    return mainCategories;
  };

  useEffect(() => {
    getAll({ menu_code: SHOP_MENU_CODE.SHOP_PAGE });
  }, []);

  useEffect(() => {
    if (info?.data && info?.data.menu_items) {
      const allShopCategories = flattenCategoriesTree(info?.data.menu_items);

      setValue(allShopCategories);
    }
  }, [info]);

  return value;
};

const ProductComponent: React.FC<ProductComponentProps> = ({
  brandName,
  ctaLink,
  productName,
  price,
  sellingPrice,
  vatIncluded,
  collection_code,
  isTaxable,
}) => {
  const { productID, collectionCode } = useParams();
  const allShopCategories = useAllShopCategories();
  const [shopCategory, setShopCategory] = useState<SimplifiedShopCategory | undefined>();
  const navigate = useNavigate();
  const hasDiscount = useMemo(() => sellingPrice < price, [sellingPrice, price]);

  const translate = useTranslation();
  const { t } = useTranslation();
  const language = translate.i18n.language;
  const [selectedVariantIndex, setSelectedVariantIndex] = useState<number | null>(null);
  const handleClick = (index: number) => {
    if (selectedVariantIndex === index) {
      setSelectedVariantIndex(null);
    } else {
      setSelectedVariantIndex(index);
    }
  };

  const handleBrandLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    if (routes) {
      navigate(routes);
    }
  };

  useEffect(() => {
    let shopCategory: SimplifiedShopCategory | undefined;

    if (collectionCode) {
      shopCategory = allShopCategories.find(subCategory => subCategory.collection_code === collectionCode);
    }

    setShopCategory(shopCategory);
  }, [collectionCode, allShopCategories]);

  const routes = AppRoutes.shop + `/c-${collection_code ? collection_code : shopCategory?.collection_code}/`;

  return (
    <div className="flex flex-col w-588">
      <div className="flex flex-col product-info gap-12 2K:gap-[21.33px] 4K:gap-32 8K:gap-64">
        <span onClick={handleBrandLinkClick} className="flex items-center text-orange-500 cursor-pointer hover:underline ">
          <h2 className="font-regular responsive-text-body">{brandName}</h2>
        </span>

        <p className="col-span-4 mb-24 font-medium text-title text-secondary SD:responsive-text-bigTitle">{productName}</p>
      </div>

      <div className="items-baseline flex flex-row gap-16 2K:gap-[28px] 4K:gap-[42px] 8K:gap-81">
        <div className="vat-details flex w-588 items-baseline gap-16 2K:gap-[28px] 4K:gap-[42px] 8K:gap-81">
          <p
            className={`whitespace-nowrap responsive-text-title font-medium tracking--0.64px ${
              hasDiscount ? 'text-red' : 'text-secondary'
            }`}
          >
            <CurrencyText currency={hasDiscount ? sellingPrice : price} />
          </p>

          {hasDiscount && (
            <p className="whitespace-nowrap line-through font-regular responsive-text-bodyLarge text-secondary/[0.42]">
              <CurrencyText currency={price} />
            </p>
          )}
        </div>

        {/* If it doesn't fit on the screen, put it on another line */}
        {isTaxable === 1 && <p className="font-regular responsive-text-caption text-secondary/[0.7] whitespace-nowrap">{vatIncluded}</p>}
      </div>
    </div>
  );
};

export default ProductComponent;
