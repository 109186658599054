import { useMutation } from '@apollo/client';
import { REPORT_MUTATION } from '@fe-monorepo/data-access';
import { useEffect, useState } from 'react';

import { CreateReportParamsModel } from './type';

export const useReport = () => {
  const [isSuccessful, setStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [errorMSg, setErrorMsg] = useState<string>('');

  const [createReport, { data: createReportResponse, error: errorCreateReport }] = useMutation(REPORT_MUTATION.createReport, {
    errorPolicy: 'all',
  });

  useEffect(() => {
    if (createReportResponse) {
      if (createReportResponse.createReport.is_successful) {
        setStatus(createReportResponse.createReport.is_successful);
      }
      if (createReportResponse.createReport.error_msg) {
        setErrorMsg(createReportResponse.createReport.error_msg);
      }
      setStatus(createReportResponse.createReport.is_successful);
    }
    setIsLoading(false);
  }, [createReportResponse]);

  useEffect(() => {
    if (errorCreateReport) {
      setError(errorCreateReport.message);
    }
    setIsLoading(false);
  }, [errorCreateReport]);

  const createTheReport = async (params: CreateReportParamsModel) => {
    const { data } = await createReport({ variables: { details: params } });
    setIsLoading(true);
    return data;
  };

  return {
    createTheReport,
    isSuccessful,
    isLoading,
    error,
    errorMSg,
  };
};
