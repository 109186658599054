import { IMAGES } from '@fe-monorepo/assets';
import { RootState } from '@fe-monorepo/store';
import Image from '@fe-web/Atoms/Media/Image';
import { TimerSection } from '@fe-web/Atoms/TimerSection/TimerSection';
import { useSelector } from 'react-redux';

interface Props {
  bannerType: string;
  title: string;
  subTitle?: string;
  img: any;
  bannerImg?: string;
  card_type?: string;
  timer?: string;
}

const CCardImgOnlyDynamic = (props: Props) => {
  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  return props.subTitle && props.title && props.img ? (
    props.bannerType === 'banner-with-photo' ? (
      <div className="bg-darkPurple rounded-[0.5rem] w-full hover:cursor-pointer text-white aspect-[282/424]">
        <div className="flex flex-col items-start flex-[1_0_0%] p-[1.5rem] h-full justify-between">
          <div className="relative flex flex-row justify-between w-full whitespace-normal">
            <p className="self-center text-fs-body font-regular">{props.subTitle}</p>
            <Image
              img={IMAGES.arrow_xup_yright_white}
              divStyle="h-[2rem] w-[2rem]"
              imgStyle={`${isRTL && `rotate-[270deg]`} object-cover w-full h-full`}
            />
          </div>
          <Image img={props.img} divStyle="self-center w-[70%] h-auto" imgStyle={`object-cover w-full`} />
          <div className="flex flex-col gap-[0.25rem] leading-[2.25rem] w-full">
            <p className="leading-[3.25rem] text-fs-title font-medium truncate">{props.title}</p>
          </div>
        </div>
      </div>
    ) : props.bannerType === 'banner-with-person-photo' ? (
      <div className="bg-moonlight rounded-[0.5rem] w-full hover:cursor-pointer text-white aspect-[282/424] relative">
        <div className="flex flex-col items-start flex-[1_0_0%] p-[1.5rem] h-full justify-between">
          <div className="relative flex flex-row justify-between w-full whitespace-normal">
            <p className="self-center text-fs-body font-regular">{props.subTitle}</p>
            <Image
              img={IMAGES.arrow_xup_yright_white}
              divStyle="h-[2rem] w-[2rem]"
              imgStyle={`${isRTL && `rotate-[270deg]`} object-cover w-full h-full`}
            />
          </div>
          <Image img={props.img} divStyle="self-center w-[14.625rem] h-[21.5rem] absolute top-[5rem]" imgStyle={`h-full w-full`} />
          <div className="flex flex-col gap-[0.25rem] leading-[2.25rem] w-full">
            <p className="leading-[3.25rem] text-fs-title font-medium truncate">{props.title}</p>
          </div>
        </div>
      </div>
    ) : props.bannerType === 'small-gif-banner' ? (
      <div className="bg-yellow rounded-[0.5rem] w-full hover:cursor-pointer text-white w-[282px] h-[26.5rem] ">
        <div className="flex flex-col items-start flex-[1_0_0%] p-[1.5rem] h-[26.5rem]">
          <div className="relative flex flex-row w-full justify-between whitespace-normal pb-[8.75rem]">
            <p className="self-center text-fs-body font-regular text-black100">{props.subTitle}</p>
            <Image
              img={IMAGES.arrow_xup_yright_black}
              divStyle="h-[2rem] w-[2rem]"
              imgStyle={`${isRTL && `rotate-[270deg]`} object-cover w-full h-full`}
            />
          </div>
          <Image img={props.img} divStyle=" w-[7.5rem] h-[7.5rem]" imgStyle={`object-cover w-full`} />
          <div className="flex flex-col gap-[0.25rem] leading-[2.25rem] w-full pt-[2rem]">
            <p className="leading-[3.25rem] text-fs-title font-medium text-black100 truncate">{props.title}</p>
          </div>
        </div>
      </div>
    ) : (
      <div className="relative bg-black100 rounded-[0.5rem] w-full hover:cursor-pointer text-white aspect-[282/424]">
        <div className="flex flex-col items-start flex-[1_0_0%] p-[1.5rem] h-full justify-between">
          <div className="relative flex flex-row justify-between w-full whitespace-normal">
            <p className="self-center text-fs-body font-regular">{props.subTitle}</p>
            <Image
              img={IMAGES.arrow_xup_yright_white}
              divStyle="h-[2rem] w-[2rem]"
              imgStyle={`${isRTL && `rotate-[270deg]`} object-cover w-full h-full`}
            />
          </div>
          <Image img={props.img} divStyle="self-center w-full h-full top-0 absolute" imgStyle={`h-full w-full`} />
          <div className="flex flex-col gap-[0.25rem] leading-[2.25rem] w-full">
            <p className="leading-[3.25rem] text-fs-title font-medium truncate">{props.title}</p>
          </div>
        </div>
      </div>
    )
  ) : props.bannerImg ? (
    <div className="relative rounded-[0.5rem] w-full hover:cursor-pointer text-white aspect-[282/424]">
      <div className="flex flex-col items-start flex-[1_0_0%] p-[1.5rem] h-full justify-between">
        <Image
          img={props.bannerImg}
          divStyle="self-center w-full h-full top-0 absolute rounded-[0.5rem]"
          imgStyle={`h-full w-full object-fit`}
        />
        {props.card_type === 'timer' && props.timer ? <TimerSection timer={props.timer} /> : null}
      </div>
    </div>
  ) : (
    <div className="relative bg-black100 rounded-[0.5rem] w-full hover:cursor-pointer text-white aspect-[282/424]">
      <div className="flex flex-col items-start flex-[1_0_0%] p-[1.5rem] h-full justify-between">
        <div className="relative flex flex-row justify-between w-full whitespace-normal"></div>
      </div>
    </div>
  );
};

export default CCardImgOnlyDynamic;
