import { useState, useEffect } from 'react';
import { MATCHES_GETINFO_QUERY } from '@fe-monorepo/data-access';
import { useLazyQuery } from '@apollo/client';
import { GetMatchInfoParams, GetMatchInfoResponse } from './types';

export const useGetMatchInfo = () => {
    const [isSuccessful, setStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [getMatchInfoGql, { data: infoDataResult }] = useLazyQuery<GetMatchInfoResponse>(MATCHES_GETINFO_QUERY.getMatchInfo, {
        errorPolicy: 'all',
    });

    const getInfo = async (infoTournamentResult: GetMatchInfoParams) => {
        const { data } = await getMatchInfoGql({
            variables: {
                details: infoTournamentResult,
            },
        });
        return data?.getMatchInfo;
    };

    useEffect(() => {
        if (infoDataResult) {
            if (infoDataResult?.getMatchInfo?.is_successful) {
                setStatus(infoDataResult?.getMatchInfo?.is_successful);
            }
            if (infoDataResult?.getMatchInfo?.error_msg) {
                setErrorMessage(infoDataResult?.getMatchInfo?.error_msg);
            }
            setStatus(infoDataResult?.getMatchInfo?.is_successful);
        }
        setIsLoading(false);
    }, [infoDataResult]);

    return { getInfo, getInfoData: infoDataResult?.getMatchInfo, isSuccessful, isLoading, errorMessage };
};
