import { LottieIconAnimations } from '@fe-monorepo/assets';
import { IconNames, getOrderSummaryTotal } from '@fe-monorepo/helper';
import { useGetProducts, useTranslate } from '@fe-monorepo/hooks';
import { InvoiceModel, OrderFilteredModel, ResendCodeInput } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import OrderSummary from '@fe-web/Molecules/OrderSummary';
import Stepper from '@fe-web/Molecules/TrackingBar/Stepper';
import { StepModel } from '@fe-web/Molecules/TrackingBar/types';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { getEnvironment } from 'libs/data-access/src/graphql/apiEnvironments';
import moment from 'moment';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

import DigitalDelivery from '../component/DigitalDelivery';
import DisabledReturnButton from '../component/DisabledReturnButton';
import PhysicalProducts from '../component/PhysicalProducts';
import ProductDetailsItems from '../component/ProductsItem';
import RecipientsDetails from '../component/RecipientsDetails';
import DiscountUsed from '../component/SeachDetails/Component/DiscountUsed';
import OrderPageSummary from '../component/SeachDetails/Component/OrderPageSummary';
import { StyledSideSection } from './OrderPage.styled';

interface OrdersProps {
  order: OrderFilteredModel;
  ordersInvoice: InvoiceModel | undefined;
  isHeaderDark?: boolean;
  showDetails: (invoice: string) => void;
  downloadInvoice: (envCode: string, invoice_number: string) => string;
  resend: (invoice: ResendCodeInput) => void;
  language: 'en' | 'ar';
}

enum ORDER_STATUS {
  pending = 'shop.cart.stepper.cart',
  received = 'shop.cart.stepper.cart',
  order_placed = 'shop.cart.stepper.cart',
  partially_delivered = 'shop.cart.stepper.delivery_payment',
  complete = 'shop.cart.stepper.delivered',
  delivered = 'shop.cart.stepper.delivered',
}

enum PAYMENT_METHODS_LABEL {
  payfort_redirection = 'settingPage_creditCard',
  apple_pay = 'shop.cart.payment_method.payment_header_applepay',
  stcpay = 'shop.cart.payment_method.payment_header_stcpay',
  stcplay = 'shop.cart.order_summary.stc_play_wallet',
  tabby = 'shop.cart.payment_method.payment_tabby',
}

const OrderData = ({ order, ordersInvoice, isHeaderDark, showDetails, downloadInvoice, resend, language }: OrdersProps) => {
  const isMobile = useMobileDetect();
  const [detailsVisible, setDetailsVisible] = useState(false);
  const { translate } = useTranslate();
  const discountCodeTitle = translate('shop.cart.order_main.disc_code_used');
  const { dir } = useHeaderState();
  const { envCode } = getEnvironment();
  const [showPlus, setShowPlus] = useState(true);
  const [isReturnButtonVisible, setIsReturnButtonVisible] = useState<boolean>(false);
  const [showDisabledReturnButton, setShowDisabledReturnButton] = useState<boolean>(false);
  const [isReturnCheckLoading, setIsReturnCheckLoading] = useState<boolean>(false);
  const { getProduct, getProductData } = useGetProducts();
  const [isAllProductsReturnRequested, setIsAllProductsReturnRequested] = useState<boolean>(false);

  const navigate = useNavigate();

  const steps: StepModel[] = [
    {
      label: 'shop.cart.stepper.cart',
      icon: IconNames.shoppingCart,
      lottieicon: LottieIconAnimations.Icon_Cart,
    },
    {
      label: 'shop.cart.stepper.delivery_payment',
      icon: IconNames.deliveryTruck,
      lottieicon: LottieIconAnimations.Icon_Van,
    },
    {
      label: 'shop.cart.stepper.delivered',
      icon: IconNames.iconCircleGrayChecked,
      lottieicon: LottieIconAnimations.Icon_Checked,
    },
  ];

  const isRtl = useSelector((state: RootState) => state.app.isRTL);
  const { invoice_number, status_code, amount_grand_total, updated_at, product } = order;
  const digital_products = ordersInvoice?.products?.filter(item => item.is_digital === 1);
  const physical_products = ordersInvoice?.products?.filter(item => item.is_digital === 0);
  const currStep =
    status_code === 'pending' || status_code === 'received' || status_code === 'order_placed' ? 0 : status_code === 'complete' ? 2 : 1;
  const discountCode = '';
  const walletToUse = useMemo(
    () => (ordersInvoice?.wallet_discount ? ordersInvoice?.wallet_discount : 0),
    [ordersInvoice?.wallet_discount],
  );

  const { TOTAL, VAT, DELIVERY, PROMO } = getOrderSummaryTotal(ordersInvoice);

  const total = ordersInvoice?.amount_grand_total ?? 0;
  const discount = ordersInvoice?.amount_discount ?? 0;

  const physicalShipments = ordersInvoice?.shipments?.filter(item => !item.is_digital && item.products);
  const digitalShipments = ordersInvoice?.shipments?.filter(item => item.is_digital === 1);
  const ORDER_SUMMARY_LIST: [string | ReactNode, string | ReactNode][] = [
    [translate('shop.cart.order_summary.subtotal'), <CurrencyText currency={TOTAL} />],
    [translate('shop.cart.order_summary.vat'), <CurrencyText currency={VAT} />],
    [
      translate('shop.cart.order_summary.delivery'),
      DELIVERY > 0 ? <CurrencyText currency={DELIVERY} /> : <span className="text-green">{translate('shop.cart.order_summary.free')}</span>,
    ],
  ];

  if (PROMO) {
    ORDER_SUMMARY_LIST.push([
      <span className="text-sunset">{translate('shop.cart.order_summary.promo_code')}</span>,
      <CurrencyText className="text-sunset" currency={PROMO} />,
    ]);
  }

  if (discount > 0) {
    ORDER_SUMMARY_LIST.push([
      <span className={`text-sunset ${discount === 0 || undefined ? 'hidden' : 'block'}`}>
        {translate('shop.cart.order_summary.discount_code')}
      </span>,
      <span className={`${discount === 0 || undefined ? 'hidden' : 'block'}`}>
        {discount && <CurrencyText className="text-sunset" currency={discount} />}
      </span>,
    ]);
  }

  if (walletToUse < 0) {
    ORDER_SUMMARY_LIST.push([
      <span className={` ${walletToUse === 0 ? 'hidden' : 'block'} `}>
        <ResponsiveIcon name={IconNames.stcPlay} baseWidth={48} baseHeight={20} className="fill-purple dark:fill-white100 mr-[0.25rem]" />
        {translate('shop.cart.stc_wallet.title')}
      </span>,
      <p className={`${walletToUse === 0 ? 'hidden' : 'block'}`}>
        {walletToUse && <CurrencyText className="text-sunset" currency={walletToUse} />}
      </p>,
    ]);
  }

  const handleTopButton = () => {};
  const handleBottomButton = () => {
    if (envCode && ordersInvoice?.invoice_number) {
      window.open(downloadInvoice(envCode, ordersInvoice?.invoice_number));
    }
  };
  const handleDetailsButtonClick = () => {
    if (showPlus) {
      mixpanelHelper.trackPageView('ORDER DETAILS PAGE', 'my_orders_order_tab_odp', window.location.pathname, document.referrer);
      brazeHelper.trackPageView('ORDER DETAILS PAGE', 'my_orders_order_tab_odp', window.location.pathname, document.referrer);
      showDetails(order.invoice_number);
    }
    if (invoice_number) {
      getProduct({
        invoice_number: invoice_number as string,
      });
    }
    setDetailsVisible(!detailsVisible);
    setShowPlus(!showPlus);
  };
  const onResendEmail = () => {
    //Need to add the Resend CTA functionality under Recipients
    resendCodeDigitalProducts();
  };

  const resendCodeDigitalProducts = () => {
    if (ordersInvoice?.invoice_number) {
      resend({
        invoice_number: ordersInvoice?.invoice_number,
      });
    }
  };

  useEffect(() => {
    const isWithinReturnPeriod = ordersInvoice?.shipments.some(shipment => {
      if (shipment?.delivered_at) {
        const deliveryDate = moment(shipment.delivered_at);
        const threeDaysLater = moment(deliveryDate).add(7, 'days');

        return moment().isBetween(deliveryDate, threeDaysLater);
      }
    });

    const isGreaterThanThreeDays = ordersInvoice?.shipments.some(shipment => {
      if (shipment?.delivered_at) {
        const deliveryDate = new Date(shipment?.delivered_at);
        const threeDaysPeriod = new Date(deliveryDate.setDate(deliveryDate.getDate() + 7));
        const today = new Date();
        if (today > threeDaysPeriod) {
          return true;
        } else {
          return false;
        }
      }
    });

    if ((status_code === 'complete' || status_code === 'delivered') && isWithinReturnPeriod) {
      setIsReturnButtonVisible(true);
      //TODO: check if the order is still within the 3 days period
      setShowDisabledReturnButton(false);
    }
    if (isGreaterThanThreeDays) {
      setIsReturnButtonVisible(false);
      setShowDisabledReturnButton(true);
    }
    if (isAllProductsReturnRequested) {
      setIsReturnButtonVisible(false);
      setShowDisabledReturnButton(true);
    }
  }, [status_code, ordersInvoice, isAllProductsReturnRequested]);

  function handleReturnButton() {
    //redirect to the return page
    navigate('return/' + ordersInvoice?.invoice_number);
  }

  //create a function that will get the ordersInvoice?.invoice_number and check the products array if all the products are digital, if all the products are digital, return true, if not return false
  function checkIfAllProductsAreDigital() {
    if (ordersInvoice?.products) {
      const isAllProductsDigital = ordersInvoice?.products.every(product => product.is_digital === 1);
      if (isAllProductsDigital) {
        return true;
      } else {
        return false;
      }
    }
  }

  useEffect(() => {
    if (!getProductData) return;
    //loop into the getProductData?.data?.list and check if all the values of serials array specifically the is_return_requested are all 1
    let isAllProductsReturnRequested = false;
    isAllProductsReturnRequested =
      getProductData?.data?.list && getProductData.data.list.length > 0
        ? getProductData.data.list.every(product => product.serials?.every(serial => serial.is_return_requested === '1'))
        : false;
    setIsAllProductsReturnRequested(isAllProductsReturnRequested);
  }, [getProductData]);

  const ordersButtons = (
    <StyledSideSection>
      <div className={`orderpage__btn mdMaxS:text-[12px] gap-24`}>
        <Button
          text={translate('shop.cart.order_main.order_again') ?? ''}
          style={`
            text-fs-body rounded-md font-medium h-[2.5rem] mt-[0.5rem]
            min-w-[139px] w-full gap-[0.5rem] z-1 
            text-white100 border-[0.063rem] border-sunset mb-[0px] 
            xsMax:mb-[0px] items-end
            rounded-sm ${
              isHeaderDark
                ? dir === 'ltr'
                  ? `after:bg-sunset before:bg-white100`
                  : `before:bg-sunset after:bg-white100`
                : `${dir === 'ltr' ? `after:bg-sunset before:bg-white100` : `before:bg-sunset after:bg-white100`}
                    hover:border-[0.063rem] hover:border-sunset`
            } 
            hover:text-sunsetText rounded-sm`}
          disabled={false}
          onClick={handleTopButton}
        />
        <Button
          text={translate('shop.cart.order_main.dl_invoice') ?? ''}
          style={`
            text-fs-body rounded-md font-medium h-[2.5rem] border-[0.063rem] border-sunset mt-[0.5rem]
            w-fit gap-[0.5rem] z-1 
            text-white100 text-sunsetText mb-[0px] 
            xsMax:mb-[0px] items-end w-full
            rounded-sm ${
              isHeaderDark
                ? dir === 'ltr'
                  ? `after:bg-white100 before:bg-sunset`
                  : `before:bg-white100 after:bg-sunset`
                : `${dir === 'ltr' ? `after:bg-white100 before:bg-sunset` : `before:bg-white100 after:bg-sunset`}`
            } 
            hover:text-white100 rounded-sm`}
          disabled={false}
          onClick={handleBottomButton}
        />
        {/* Enabled Return Button */}
        {isReturnButtonVisible && !isReturnCheckLoading && !checkIfAllProductsAreDigital() && !isAllProductsReturnRequested && (
          <Button
            text={translate('shop.cart.returns_or_exchange.return_items_cta')}
            style={`w-full mt-[0.5rem] text-fs-body not-italic font-medium leading-6 px-[1.5rem] py-[0.5rem] text-primary border-[1px] border-secondary rounded-sm ${
              isHeaderDark
                ? dir === 'ltr'
                  ? `after:bg-secondary before:bg-primary`
                  : `before:bg-secondary after:bg-primary`
                : `${dir === 'ltr' ? `after:bg-secondary before:bg-primary` : `before:bg-secondary after:bg-primary`}
                  hover:-border[1px] hover:border-sunset`
            } hover:text-secondary rounded-sm`}
            disabled={false}
            onClick={() => handleReturnButton()}
          />
        )}
        {/* Disabled Return Button */}
        {showDisabledReturnButton && !isReturnCheckLoading && !checkIfAllProductsAreDigital() && <DisabledReturnButton />}
        {!isAllProductsReturnRequested && (
          <Tooltip
            id="my-tooltip"
            events={['hover']}
            style={{ position: 'absolute', zIndex: 9999999 }}
            className="bg-secondary text-primary opacity-[30] px-[1rem] py-[1rem]"
          />
        )}
      </div>
    </StyledSideSection>
  );

  return (
    <>
      <div className="details__div mb-[2rem] SD:mb-0 flex flex-col col-span-5">
        <ProductDetailsItems
          idinfo={invoice_number}
          orderdate={updated_at}
          total={amount_grand_total}
          status={translate(ORDER_STATUS[status_code as keyof typeof ORDER_STATUS]) ?? ''}
          products={product}
          handleDetailsButtonClick={handleDetailsButtonClick}
          showPlus={showPlus}
          isMobile={isMobile}
        />
      </div>

      {detailsVisible && (
        <>
          <Stepper
            currentStep={currStep}
            steps={steps}
            isRTL={isRtl}
            isVertical={isMobile}
            hasDescriptionIcon={true}
            hasEstimateText={false}
            isDateUnder={false}
            verticalHeight="h-[3rem]"
            isLineDashed={false}
            containerStyle="flex flex-col col-span-3 mdMaxM:col-span-4"
            dateFormatInput={{ vertical: 'd mmm yyyy', horizontal: 'd mmm yyyy HH:MM' }}
            isCenter={false}
            title={{
              hasTitle: true,
              titleStyle: 'text-black dark:text-white font-medium text-fs-body-large SD:text-fs-subtitle',
              titleText: translate('common_status') ?? '',
            }}
            gridLength={{ column: 'grid-cols-3', row: 'grid-rows-3' }}
            fontStyle="text-fs-caption font-regular"
            stepperIcon={{
              default: IconNames.iconCircleGray,
              done: IconNames.iconCircleFilled,
              filled: IconNames.iconcirclefilledv2,
            }}
          />
          <div className="flex flex-col justify-end col-span-4 mb-[2rem] SD:col-span-3">
            {digital_products && digital_products?.length > 0 ? (
              <DigitalDelivery
                products={digital_products}
                language={language}
                isRTL={isRtl}
                digitalShipments={digitalShipments || []}
                orderStatus={status_code}
                resend={resendCodeDigitalProducts}
              />
            ) : null}
            {physicalShipments?.length === 0 && physical_products && physical_products?.length > 0 && (
              <PhysicalProducts
                products={physical_products}
                language={language}
                isRTL={isRtl}
                statuslabel={translate(ORDER_STATUS[status_code as keyof typeof ORDER_STATUS]) ?? ''}
                user=""
                currpackagenum={1}
              />
            )}
            {physicalShipments &&
              physicalShipments?.length > 0 &&
              physicalShipments?.map((shipment, index) => {
                const products_sku = shipment?.products?.map(product => product?.sku);
                const product_list = physical_products?.filter(product => products_sku?.includes(product?.sku));

                return (
                  <PhysicalProducts
                    key={shipment.reference_order_number}
                    products={product_list || []}
                    language={language}
                    isRTL={isRtl}
                    statuslabel={translate(ORDER_STATUS[status_code as keyof typeof ORDER_STATUS]) ?? ''}
                    itemstatuslabel={shipment ? shipment[`shipment_status_${language}`] : ''}
                    user={''}
                    packagenum={product_list?.length}
                    currpackagenum={index}
                    hasShipments={true}
                    index={index}
                  />
                );
              })}
            <RecipientsDetails
              sellerName={ordersInvoice?.billing_name}
              sellerEmail={ordersInvoice?.billing_email}
              shippingName={ordersInvoice?.shipping_name}
              shippingNumber={ordersInvoice?.shipping_mobile}
              shippingAddress={ordersInvoice?.shipping_address}
              shippingCity={ordersInvoice?.shipping_city}
              onResendEmail={onResendEmail}
              paymentMethod={ordersInvoice?.payment_method_code ? translate(PAYMENT_METHODS_LABEL[ordersInvoice?.payment_method_code]) : ''}
              hasDigitalProducts={digital_products && digital_products.length > 0}
              orderStatus={status_code}
            />
          </div>
          <div className="flex flex-col col-span-4 items-start gap-[1.25rem] SD:col-span-1">
            {/* TODO: Replace the data inputs from the actual orders */}
            <OrderSummary
              items={[
                ORDER_SUMMARY_LIST,
                [
                  [
                    translate('shop.cart.order_summary.total'),
                    <CurrencyText className="font-medium text-fs-body-large" currency={total} />,
                  ],
                ],
              ]}
            />
            {discountCode && <DiscountUsed title={discountCodeTitle} code={discountCode} />}
            <OrderPageSummary buttons={ordersButtons} summaryDisclaimerText={translate('shop.cart.order_main.disclaimer')} />
          </div>
        </>
      )}
    </>
  );
};

export default OrderData;
