import { useLazyQuery, useMutation } from '@apollo/client';
import { PROMO_INFO_QUERY, PROMO_WALLET_APPLY_MUTATION } from '@fe-monorepo/data-access';
import { PROMO_ELIGIBLE_BRANDS } from '@fe-monorepo/helper';
import { InvoiceProduct } from '@fe-monorepo/models';
import { useState } from 'react';

import { useTranslate } from '../useTranslate';

export type PROMO_CODE_ITEM = {
  name?: string;
  code: string;
  percentage: number;
  start_date: string;
  end_date: string;
  name_en: string;
  name_ar: string;
  collection_code: string;
};

interface GetPromoInfoResponse {
  getPromoInfo: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    response: PROMO_CODE_ITEM;
  };
}

export type ApplyPromoInput = {
  invoice_number: string;
  promo_code?: string;
  wallet_number?: string;
  amount?: string;
};

interface GetPromoInfoInput {
  promo_code: string;
}

export interface ResponseType {
  type: 'success' | 'error' | null;
  text: string | null;
}

interface ApplyResponse {
  is_successful: boolean;
  error_code: string | null;
  error_msg: string | null;
}

interface ApplyResponseGroup {
  promo_code_applied_successfully: ApplyResponse;
  wallet_discount_applied_successfully: ApplyResponse;
}

interface ApplyPromoResponse {
  applyPromoWallet: {
    is_successful: boolean;
    error_code: string | null;
    error_msg: string | null;
    response: ApplyResponseGroup;
  };
}

const RESPONSE_TYPE_MAP: any = {
  applied_on_lg_products: {
    type: 'success',
    field: 'shop.cart.promo_code.applied_on_lg_products',
  },
  expired_promo_code: {
    type: 'error',
    field: 'shop.cart.promo_code.expired_promo_code',
  },
  invalid_promo_code: {
    type: 'error',
    field: 'shop.cart.promo_code.invalid_promo_code',
  },
  this_code_is_for_lg_products_only: {
    type: 'error',
    field: 'shop.cart.promo_code.this_code_is_for_lg_products_only',
  },
};

const DEFAULT_RESPONSE_TEXT = {
  type: null,
  text: null,
};

const usePromoCode = (cartItems: InvoiceProduct[], updateCartItems: (items: InvoiceProduct[], isClearWallet?: boolean) => void) => {
  const { translate } = useTranslate();

  const [responseText, setResponseText] = useState<ResponseType>(DEFAULT_RESPONSE_TEXT);
  const [appliedPromo, setAppliedPromo] = useState<PROMO_CODE_ITEM | null>(null);

  const [getPromoInfo] = useLazyQuery<GetPromoInfoResponse>(PROMO_INFO_QUERY.getPromoInfo, {
    errorPolicy: 'all',
  });

  const [applyPromoMutation, { data: applyPromoResponse, error: errorApplyPromo }] = useMutation<ApplyPromoResponse>(
    PROMO_WALLET_APPLY_MUTATION.promoWalletApplyMutation,
    {
      errorPolicy: 'all',
    },
  );

  const fetchPromoInfo = async (details: GetPromoInfoInput) => {
    const { data } = await getPromoInfo({ variables: { details: details } });
    return data;
  };

  const setPromoInfoResponse = (type: string) => {
    setResponseText({
      type: RESPONSE_TYPE_MAP[type].type ?? null,
      text: translate(RESPONSE_TYPE_MAP[type]?.field),
    });
  };

  const applyPromo = async (promo: string) => {
    const { validPromo, promoDetails } = await validatePromo(promo);
    if (validPromo) {
      setAppliedPromo(promoDetails?.response ?? null);
      updateInvoice(promoDetails?.response?.percentage ?? 0, true);
    }
  };

  const clearPromo = async () => {
    if (appliedPromo) {
      updateInvoice(0, false);
      setAppliedPromo(null);
      setResponseText(DEFAULT_RESPONSE_TEXT);
    }
  };

  const applyInvoicePromo = (params: ApplyPromoInput) => {
    return applyPromoMutation({
      variables: { details: params },
    });
  };

  const validatePromo = async (promo: string) => {
    const promoRes = await fetchPromoInfo({ promo_code: promo });
    let validPromo = false;
    if (promoRes?.getPromoInfo) {
      const details = promoRes?.getPromoInfo;
      if (details?.is_successful) {
        // Checking Expiry
        const currentTime = new Date();
        const startDate = new Date(details?.response?.start_date);
        const endDate = new Date(details?.response?.end_date);
        const isValid = currentTime >= startDate && currentTime <= endDate;
        if (isValid) {
          const atLeastOneItemMatches = cartItems.some(item => PROMO_ELIGIBLE_BRANDS.includes(item.brand.collection_code.toLowerCase()));
          if (atLeastOneItemMatches) {
            setPromoInfoResponse('applied_on_lg_products');
            validPromo = true;
          } else {
            setPromoInfoResponse('this_code_is_for_lg_products_only');
          }
        } else {
          setPromoInfoResponse('expired_promo_code');
        }
      } else {
        setPromoInfoResponse('invalid_promo_code');
      }
      return {
        validPromo,
        promoDetails: details,
      };
    } else {
      setPromoInfoResponse('invalid_promo_code');
      return {
        validPromo,
        promoDetails: null,
      };
    }
  };

  const updateInvoice = (promoPercent: number, isClearWallet: boolean) => {
    const cartItemArray = [...cartItems];
    const updatedCartItems = cartItemArray.map(item => {
      if (PROMO_ELIGIBLE_BRANDS.includes(item?.brand?.collection_code.toLowerCase()) && promoPercent > 0) {
        const discount = (item?.subtotal * promoPercent) / 100;
        return {
          ...item,
          promo_applied_price: item?.subtotal - discount,
        };
      } else {
        if (promoPercent <= 0 && item?.promo_applied_price) {
          delete item.promo_applied_price;
        }
        return item;
      }
    });

    // Update the state with the updated cart items
    updateCartItems(updatedCartItems ?? [], isClearWallet);
  };

  const applyPromoAfterCheckout = async (payload: {
    invoice_number: string;
    promo_code?: string;
    wallet_number?: string;
    amount?: string;
  }) => {
    return applyInvoicePromo(payload)
      .then(({ data }) => {
        const resp = data?.applyPromoWallet;
        if (resp?.is_successful) {
          if (resp?.response?.promo_code_applied_successfully?.is_successful === false) {
            return {
              is_successful: false,
              message: translate('shop.cart.promo_applied.failed_promo_apply'),
            };
          } else if (resp?.response?.wallet_discount_applied_successfully?.is_successful === false) {
            return {
              is_successful: false,
              message: translate('shop.cart.promo_applied.failed_wallet_apply'),
            };
          } else if (payload.promo_code && payload.wallet_number) {
            return {
              is_successful: true,
              message: translate('shop.cart.promo_applied.success'),
            };
          } else if (payload.promo_code) {
            return {
              is_successful: true,
              message: translate('shop.cart.promo_applied.success_promo_apply'),
            };
          } else {
            return {
              is_successful: true,
              message: translate('shop.cart.promo_applied.success_wallet_apply'),
            };
          }
        } else {
          return {
            is_successful: false,
            message: translate('shop.cart.promo_applied.failure'),
          };
        }
      })
      .catch(err => {
        console.log(err);
        return {
          is_successful: false,
          message: translate('shop.cart.promo_applied.failure'),
        };
      });
  };

  return {
    fetchPromoInfo,
    promoResponse: responseText,
    appliedPromo,
    applyPromo,
    clearPromo,
    applyPromoAfterCheckout,
  };
};

export { usePromoCode };
