import { useTranslate } from '@fe-monorepo/hooks';
import { ContentModel, SearchData, UserSearchModel } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import { useSelector } from 'react-redux';

import LoadMore from '../../../components/Atoms/Load/LoadMore';
import { SearchTab } from '../../Search';
import SearchSectionResult from '../Components/SearchSectionResult';
import UserResult from './UserResult';

// TODO: this should be a list element
const UserResults = (props: {
  users?: UserSearchModel[] | ContentModel[];
  shouldShowFollow: boolean;
  isMobile?: boolean;
  selectedTab: string;
}) => (
  <ul className={`h-fit list-none ${props.isMobile ? 'divide-y divide-black10 dark:divide-white10' : ''}`}>
    {props?.users?.slice(0, props.selectedTab === 'all' ? 5 : props.users.length).map((user, index) => {
      return (
        <UserResult
          key={index}
          shouldShowFollow={props.shouldShowFollow}
          user={user as UserSearchModel}
          isMobile={props.isMobile}
          isOnAllTab={props.selectedTab === 'all'}
        />
      );
    })}
  </ul>
);

interface SearchUserResultProps {
  userData?: SearchData<UserSearchModel>;
  isLoading: boolean;
  tabData?: ContentModel[];
  selectedTab: string;
  loadMore: () => void;
  setSelectedTab: (key: SearchTab) => void;
  isMobile?: boolean;
}

const SearchUserResult = (props: SearchUserResultProps) => {
  const token = useSelector((state: RootState) => state?.user.userContext?.token);
  const { translate } = useTranslate();
  const isLoggedIn = !['', null, undefined].includes(token);

  const SectionData = () => {
    const isUserTab = props?.selectedTab === 'user';
    const users = isUserTab ? props?.tabData : props?.userData?.result;
    return (
      <>
        <UserResults users={users} shouldShowFollow={isUserTab && isLoggedIn} isMobile={props.isMobile} selectedTab={props.selectedTab} />
        {isUserTab && <LoadMore isLoading={props?.isLoading} loadMore={props?.loadMore} />}
      </>
    );
  };

  return (
    <SearchSectionResult
      selectedTab={props.selectedTab}
      isLoading={props.isLoading}
      sectionTitle={translate('top_tab_users') ?? ''}
      totalResults={props?.userData?.total}
      SectionData={<SectionData />}
      selectTab={() => props?.setSelectedTab('user')}
      isMobile={props.isMobile}
    />
  );
};

export default SearchUserResult;
