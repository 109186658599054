import React from "react";

function VideoMuteIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.5 7.99974V12.9997H5.83333L10 17.1664V3.83307L5.83333 7.99974H2.5ZM13.75 10.4997C13.75 9.02474 12.9 7.75807 11.6667 7.14141V13.8497C12.9 13.2414 13.75 11.9747 13.75 10.4997ZM11.6667 3.19141V4.90807C14.075 5.62474 15.8333 7.85807 15.8333 10.4997C15.8333 13.1414 14.075 15.3747 11.6667 16.0914V17.8081C15.0083 17.0497 17.5 14.0664 17.5 10.4997C17.5 6.93307 15.0083 3.94974 11.6667 3.19141Z"
        fill="white"
      />
    </svg>
  );
}

export default VideoMuteIcon;
