import React from "react";

function VideoFullScreenIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.83334 12.167H4.16667V16.3337H8.33334V14.667H5.83334V12.167ZM4.16667 8.83366H5.83334V6.33366H8.33334V4.66699H4.16667V8.83366ZM14.1667 14.667H11.6667V16.3337H15.8333V12.167H14.1667V14.667ZM11.6667 4.66699V6.33366H14.1667V8.83366H15.8333V4.66699H11.6667Z"
        fill="white"
      />
    </svg>
  );
}

export default VideoFullScreenIcon;
