import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';

const ActionButton = ({ text, icon, onClick }: { text?: string; icon?: IconNames; onClick?: () => void }) => {
  return (
    <button type="button" className="inline-flex items-center gap-x-8" onClick={onClick}>
      {icon && <ResponsiveIcon name={icon} baseWidth={16} baseHeight={16} iconClasses="fill-sunset" />}
      {text && <span className="text-bodySmall text-sunset font-regular SD:hidden">{text}</span>}
    </button>
  );
};
export default ActionButton;
