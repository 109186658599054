import { useTranslate } from '@fe-monorepo/hooks';

import Spinner from '../../../components/Atoms/Load/Spinner';

interface SearchSectionProps {
  selectedTab: string;
  isLoading: boolean;
  sectionTitle: string;
  totalResults?: number;
  SectionData: React.ReactNode;
  selectTab: () => void;
  isMobile?: boolean;
}

const SearchSectionResult = ({
  sectionTitle,
  selectedTab,
  isLoading,
  totalResults,
  SectionData,
  selectTab,
  isMobile,
}: SearchSectionProps) => {
  const { translate } = useTranslate();
  return (
    <div className="flex flex-col">
      {(selectedTab === 'all' || isMobile) && (
        <div className={`flex items-center justify-between ${selectedTab === 'all' ? 'mb-[.5rem]' : ''}`}>
          <div
            className={`flex flex-row justify-between gap-[.25rem] text-secondary
              ${isMobile ? 'text-fs-body-small font-regular text-secondary/70' : 'text-fs-subtitle font-medium'}`}
          >
            <p>{sectionTitle}</p>
            <p>
              {isLoading ? <Spinner className={`text-secondary/30 items-center ${isMobile ? 'h-[.5rem]' : 'h-[1rem]'}`} /> : totalResults}
            </p>
          </div>

          {/*SEE ALL CTA */}
          <h1
            className={`font-regular cursor-pointer whitespace-nowrap text-sunset underline underline-offset-[.25rem] ${
              isMobile ? 'text-fs-body-small' : 'text-fs-body'
            }`}
            onClick={selectTab}
          >
            {translate('common_see_all')}
          </h1>
        </div>
      )}
      {SectionData}
    </div>
  );
};

export default SearchSectionResult;
