import { differenceInSeconds, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

export interface DayModel {
  days: string;
  hours: string;
  minutes: string;
  seconds: string;
  expired: boolean;
}

export interface CountdownModel {
  remainingDay: DayModel;
  isValid: boolean;
  isStarted: boolean;
}

export const useCountdownDay = (targetDate: string, timezone?: string): CountdownModel => {
  const target = useMemo(() => {
    const parsedDate = parseISO(targetDate || '');
    return timezone ? toZonedTime(parsedDate, timezone) : parsedDate;
  }, [targetDate, timezone]);

  const calculateTimeLeft = useCallback((): DayModel => {
    const now = timezone ? toZonedTime(new Date(), timezone) : new Date();
    const diffInSeconds = differenceInSeconds(target, now);

    if (diffInSeconds <= 0) {
      return { days: '00', hours: '00', minutes: '00', seconds: '00', expired: true };
    }

    const days = Math.floor(diffInSeconds / (3600 * 24));
    const hours = Math.floor((diffInSeconds % (3600 * 24)) / 3600);
    const minutes = Math.floor((diffInSeconds % 3600) / 60);
    const seconds = diffInSeconds % 60;

    return {
      days: String(days).padStart(2, '0'),
      hours: String(hours).padStart(2, '0'),
      minutes: String(minutes).padStart(2, '0'),
      seconds: String(seconds).padStart(2, '0'),
      expired: false,
    };
  }, [target, timezone]);

  const [remainingDay, setRemainingDay] = useState<DayModel>(calculateTimeLeft);
  const [isValid, setIsValid] = useState<boolean>(() => !isNaN(target.getTime()));
  const [isStarted, setIsStarted] = useState<boolean>(() => new Date() >= target);
  const isStartedRef = useRef<boolean>(isStarted);

  useEffect(() => {
    const interval = setInterval(() => {
      const newTimeLeft = calculateTimeLeft();
      setRemainingDay(newTimeLeft);
      const nowStarted = new Date() >= target;
      if (nowStarted !== isStartedRef.current) {
        isStartedRef.current = nowStarted;
        setIsStarted(nowStarted);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [calculateTimeLeft, target]);

  useEffect(() => {
    setIsValid(!isNaN(target.getTime()));
    const nowStarted = new Date() >= target;
    isStartedRef.current = nowStarted;
    setIsStarted(nowStarted);
  }, [target]);

  return { remainingDay, isValid, isStarted };
};
