import { persistReducer, persistStore, REHYDRATE, PERSIST } from 'redux-persist';
import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import rootReducer from './reducers';
import { storage } from './storage';

const persistConfig = {
    key: 'stc-play',
    storage,
};

const persistedReducers = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
    reducer: persistedReducers,
    middleware: getDefaultMiddleware => {
        const defaultMiddleware = getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: {
                ignoredActions: [REHYDRATE, PERSIST],
            },
        });
      
        return defaultMiddleware;
    },
});

export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
