import { TxKeyPath } from '@fe-monorepo/helper';
import { TOptions } from 'i18next';
import { useTranslation } from 'react-i18next';

export const useTranslate = () => {
    const { t } = useTranslation();
    const translate = (key: TxKeyPath, options?: TOptions) => {
        const updatedOptions: TOptions = {
            ...(options || {}),
            defaultValue: '',
        };
        return key ? t(key, updatedOptions) : null;
    };

    return { translate };
};
