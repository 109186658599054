import { formatNumberWithCommasAndDecimal } from '@fe-monorepo/helper';
import { DiscountModel, InvoiceProduct, ProductUnavailableCode } from '@fe-monorepo/models';
import {
  AppDispatch,
  RootState,
  addCartItem,
  addDiscount as addDiscountAction,
  clearCart,
  decreaseQuanity,
  increaseQuantity,
  removeAllDiscounts as removeAllDiscountsAction,
  removeCartItem,
  removeDiscount as removeDiscountAction,
  setDeliveryFee as setDeliveryFeeAction,
  setInvoice,
  setStcWalletToUse as setStcWalletToUseAction,
  updateCartItemStock,
} from '@fe-monorepo/store';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslate } from '../useTranslate';
import { useUserProfile } from '../useUserProfile';
import { useCheckProductAvailability } from './useCheckProductAvailability';
import { useInvoice } from './useInvoice';
import { useOrder } from './useOrder';
import { useProduct } from './useProduct';

// TODO: This is for testing purposes only
// Remote this in production
const TEMP_CODES: DiscountModel[] = [
  { code: 'GGWP20', amount: 200 },
  { code: 'GGWP21', amount: 300 },
  { code: 'GGWP22', amount: 400 },
  { code: 'GGWP23', amount: 500 },
  { code: 'GGWP24', amount: 1000 },
  { code: 'GGWP25', amount: 1100, isConsumed: true },
  { code: 'GGWP26', amount: 1200, isConsumed: true },
  { code: 'GGWP27', amount: 600 },
];

export const useCart = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { translate } = useTranslate();
  const language = useSelector((state: RootState) => state.app.language);
  const itemsInCart = useSelector((state: RootState) => state.cart.items);
  const deliveryFee = useSelector((state: RootState) => state.cart.deliveryFee);
  const discounts = useSelector((state: RootState) => state.cart.discounts);
  const { isLoggedIn } = useUserProfile();
  const { invoiceInfo, getInvoice, clearInvoice } = useInvoice(true);
  const { addProduct, removeProduct, selectProducts } = useProduct();
  const { checkProductAvailability } = useCheckProductAvailability();
  const { cancelOrder } = useOrder();
  const lmd_code = useSelector((state: RootState) => state.app.lmdCode);

  const [productError, setProductError] = useState<{ errorMessage: string; errorCode: string; productCode: string } | null>(null);

  const maxDiscounts = useMemo(() => (isLoggedIn ? 5 : 1), [isLoggedIn]);
  const totalDiscounts = useMemo(() => {
    let sum = 0;
    discounts?.map(({ amount }) => (sum += amount));
    return sum;
  }, [discounts]);
  const stcWalletToUse = useSelector((state: RootState) => state.cart.stcWalletToUse);
  const subtotal = useMemo(
    () =>
      isLoggedIn || (invoiceInfo && !isLoggedIn)
        ? invoiceInfo?.products?.reduce((total, item) => total + item.grand_total, 0) || 0
        : itemsInCart.reduce((total, item) => total + item.grand_total, 0) || 0,
    [isLoggedIn, itemsInCart, invoiceInfo?.products],
  );
  const total = useMemo(() => subtotal + (deliveryFee ?? 0) - (totalDiscounts || 0), [subtotal, deliveryFee, totalDiscounts]);
  const subtotalFormat = useMemo(
    () => translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(subtotal)),
    [subtotal, language],
  );
  const deliveryFeeFormat = useMemo(
    () => (deliveryFee ? translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(deliveryFee)) : ''),
    [deliveryFee, language],
  );
  const totalDiscountsFormat = useMemo(
    () => (totalDiscounts ? translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(totalDiscounts)) : ''),
    [totalDiscounts, language],
  );
  const stcWalletToUseFormat = useMemo(
    () => (stcWalletToUse ? translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(stcWalletToUse)) : ''),
    [stcWalletToUse, language],
  );
  const totalFormat = useMemo(
    () => (total ? translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(total)) : ''),
    [total, language],
  );

  const totalProductCount = useMemo(
    () =>
      isLoggedIn || (invoiceInfo && !isLoggedIn)
        ? invoiceInfo?.products?.reduce((total, item) => total + item.qty, 0) || 0
        : itemsInCart.reduce((total, item) => total + item.qty, 0) || 0,
    [isLoggedIn, itemsInCart, invoiceInfo],
  );
  const totalSelectedProductsCount = useMemo(
    () => invoiceInfo?.products?.reduce((total, item) => total + (item.is_active ? item.qty : 0), 0),
    [invoiceInfo],
  );
  const [unavailableProductCodes, setUnavailableProductCodes] = useState<string[]>([]);

  const addToCart = (item: InvoiceProduct) => {
    dispatch(addCartItem({ ...item, qty: item.qty || 1 }));
  };

  const removeToCart = async (itemName: string, variantId: number) => {
    if (
      (isLoggedIn || (invoiceInfo && !isLoggedIn)) &&
      invoiceInfo &&
      invoiceInfo?.is_checkout === 1 &&
      invoiceInfo.status_code === 'pending'
    ) {
      await cancelOrder({ invoice_number: invoiceInfo.invoice_number });
    }
    dispatch(removeCartItem({ product_code: itemName, variant_id: variantId }));
  };

  const clearItems = async () => {
    if (!isLoggedIn) {
      dispatch(clearCart());
      return;
    }
    const invoiceNumber = invoiceInfo?.invoice_number;
    const lmdCode = invoiceInfo?.lmd_code;
    if (typeof invoiceNumber !== 'string') return;
    if (invoiceInfo?.is_checkout === 1 && invoiceInfo.status_code === 'pending') {
      await cancelOrder({ invoice_number: invoiceInfo.invoice_number });
    }
    clearInvoice({ invoice_number: invoiceNumber, lmd_code: lmdCode }).then(response => {
      if (response?.is_successful) {
        getInvoice({ details: { invoice_number: invoiceNumber } });
      }
    });
  };

  const increase = async (productCode: string, variantId?: number) => {
    setProductError(null);

    if ((isLoggedIn || (!isLoggedIn && invoiceInfo)) && variantId) {
      const invoiceNumber = invoiceInfo?.invoice_number;

      if (typeof invoiceNumber !== 'string') return;

      if (invoiceInfo?.is_checkout === 1 && invoiceInfo.status_code === 'pending') {
        await cancelOrder({ invoice_number: invoiceInfo.invoice_number });
      }

      addProduct({
        invoice_number: invoiceNumber,
        variant_id: variantId,
        product_code: productCode,
        qty: 1,
      }).then(response => {
        if (response?.is_successful) {
          setUnavailableProductCodes(prevState => {
            const updatedState = prevState.filter(item => item !== productCode);
            return updatedState;
          });
          getInvoice({ details: { invoice_number: invoiceNumber } });
        } else if (!response?.is_successful && response?.error_code === ProductUnavailableCode) {
          if (invoiceInfo) {
            const new_invoice = {
              ...invoiceInfo,
            };
            new_invoice.products = new_invoice.products?.map(item => {
              const new_prod = {
                ...item,
              };
              if (new_prod.product_code === productCode) {
                new_prod.qty += 1;
              }
              return new_prod;
            });
            dispatch(setInvoice(new_invoice));
          }
          setUnavailableProductCodes(prevState => {
            const updatedState = [...prevState];
            if (!updatedState.includes(productCode)) {
              updatedState.push(productCode);
            }
            return updatedState;
          });
        }
        if (response?.is_successful && !isLoggedIn && invoiceInfo) {
          dispatch(increaseQuantity({ product_code: productCode, variant_id: variantId }));
        }

        if (!response?.is_successful && response?.error_code && response?.error_msg) {
          setProductError({ errorCode: response?.error_code, errorMessage: response?.error_msg, productCode });
        }
      });
    } else if (!isLoggedIn) {
      const cartItem = itemsInCart.find(item => item.product_code === productCode);
      if (cartItem) {
        const qty = cartItem.qty + 1;
        checkProductAvailability({
          product_code: productCode,
          variant_id: cartItem.variant_id.toString(),
          lmd_code: lmd_code.lmd_code,
          qty: qty,
        }).then(response => {
          if (response?.isProductAvailable.is_successful) {
            dispatch(increaseQuantity({ product_code: productCode, variant_id: variantId ?? 0 }));
            setUnavailableProductCodes(prevState => {
              const updatedState = prevState.filter(item => item !== productCode);
              return updatedState;
            });
          } else {
            dispatch(updateCartItemStock({ product_code: productCode, in_stock: 0, variant_id: variantId ?? 0 }));
            setUnavailableProductCodes(prevState => {
              const updatedState = [...prevState];
              if (!updatedState.includes(productCode)) {
                updatedState.push(productCode);
              }
              return updatedState;
            });
          }

          if (
            !response?.isProductAvailable.is_successful &&
            response?.isProductAvailable.error_code &&
            response?.isProductAvailable.error_msg
          ) {
            setProductError({
              errorCode: response.isProductAvailable.error_code,
              errorMessage: response.isProductAvailable.error_msg,
              productCode,
            });
          }
        });
      }
    }
  };

  const processDecrementQty = async (productCode: string, variantId: number, action: string) => {
    if (invoiceInfo?.is_checkout === 1 && invoiceInfo.status_code === 'pending') {
      await cancelOrder({ invoice_number: invoiceInfo.invoice_number });
    }
    const invoiceNumber = invoiceInfo?.invoice_number;
    if (typeof invoiceNumber !== 'string') return;
    removeProduct({
      invoice_number: invoiceNumber,
      variant_id: variantId,
      product_code: productCode,
      qty: 1,
    }).then(response => {
      if (response?.is_successful && !isLoggedIn && invoiceInfo) {
        if (action === 'remove') {
          dispatch(removeCartItem({ product_code: productCode, variant_id: variantId }));
        } else {
          dispatch(decreaseQuanity({ product_code: productCode, variant_id: variantId }));
        }
      }
      if (response?.is_successful) {
        getInvoice({ details: { invoice_number: invoiceNumber } });
      }
    });
  };
  const decrease = (productCode: string, variantId?: number) => {
    setProductError(null);

    if ((isLoggedIn || (!isLoggedIn && invoiceInfo)) && variantId) {
      const invoiceNumber = invoiceInfo?.invoice_number;

      if (typeof invoiceNumber !== 'string') return;
      const cartItem = invoiceInfo?.products.find(item => item.product_code === productCode);
      if (cartItem && cartItem.qty > 1) {
        const qty = cartItem.qty;
        checkProductAvailability({
          product_code: productCode,
          variant_id: cartItem.variant_id.toString(),
          lmd_code: lmd_code.lmd_code,
          qty: qty,
        }).then(response => {
          if (response?.isProductAvailable.is_successful) {
            setUnavailableProductCodes(prevState => {
              const updatedState = prevState.filter(item => item !== productCode);
              return updatedState;
            });
            processDecrementQty(productCode, variantId, 'decrease');
          } else if (invoiceInfo) {
            const new_invoice = {
              ...invoiceInfo,
            };
            new_invoice.products = new_invoice.products?.map(item => {
              const new_prod = {
                ...item,
              };
              if (new_prod.product_code === productCode) {
                new_prod.qty -= 1;
              }
              return new_prod;
            });
            dispatch(setInvoice(new_invoice));
            setUnavailableProductCodes(prevState => {
              const updatedState = prevState.filter(item => item !== productCode);
              return updatedState;
            });
          }

          if (
            !response?.isProductAvailable.is_successful &&
            response?.isProductAvailable.error_code &&
            response?.isProductAvailable.error_msg
          ) {
            setProductError({
              errorCode: response?.isProductAvailable.error_code,
              errorMessage: response?.isProductAvailable.error_msg,
              productCode,
            });
          }
        });
      } else {
        processDecrementQty(productCode, variantId, 'decrease');
      }
    } else if (!isLoggedIn) {
      const cartItem = itemsInCart.find(item => item.product_code === productCode);
      if (cartItem) {
        if (cartItem.qty > 1) {
          const qty = cartItem.qty - 1;
          checkProductAvailability({
            product_code: productCode,
            variant_id: cartItem.variant_id.toString(),
            lmd_code: lmd_code.lmd_code,
            qty: qty,
          }).then(response => {
            if (response?.isProductAvailable.is_successful) {
              dispatch(decreaseQuanity({ product_code: productCode, variant_id: variantId ?? 0 }));
              setUnavailableProductCodes(prevState => {
                const updatedState = prevState.filter(item => item !== productCode);
                return updatedState;
              });
            } else {
              dispatch(updateCartItemStock({ product_code: productCode, in_stock: 0, variant_id: variantId ?? 0 }));
            }

            if (
              !response?.isProductAvailable.is_successful &&
              response?.isProductAvailable.error_code &&
              response?.isProductAvailable.error_msg
            ) {
              setProductError({
                errorCode: response?.isProductAvailable.error_code,
                errorMessage: response?.isProductAvailable.error_msg,
                productCode,
              });
            }
          });
        } else {
          dispatch(removeCartItem({ product_code: productCode, variant_id: variantId ?? 0 }));
        }
      }
    }
  };

  const remove = (productCode: string, variantId?: number, qty?: number) => {
    if ((isLoggedIn || (!isLoggedIn && invoiceInfo)) && variantId && qty) {
      const invoiceNumber = invoiceInfo?.invoice_number;

      if (typeof invoiceNumber !== 'string') return;
      processDecrementQty(productCode, variantId, 'remove');
    } else if (!isLoggedIn) {
      dispatch(removeCartItem({ product_code: productCode, variant_id: variantId ?? 0 }));
    }
  };

  const addDiscount = (code: string) => {
    const discount = TEMP_CODES.find(discountCode => discountCode.code === code);
    if (!discount) return;
    dispatch(addDiscountAction(discount));
  };

  const removeDiscount = (code: string) => {
    dispatch(removeDiscountAction(code));
  };

  const removeAllDiscounts = () => {
    dispatch(removeAllDiscountsAction());
  };

  const setDeliveryFee = (deliveryFee: number) => {
    dispatch(setDeliveryFeeAction(deliveryFee));
  };

  const removeDeliveryFee = () => {
    dispatch(setDeliveryFeeAction(undefined));
  };

  const setStcWalletToUse = (amount: number) => {
    dispatch(setStcWalletToUseAction(amount));
  };

  const removeStcWalletToUse = () => {
    dispatch(setStcWalletToUseAction(undefined));
  };

  const validateCode = (code: string): [boolean, string] | [boolean] => {
    const discountCode = TEMP_CODES.find(discountCode => discountCode.code === code);
    if (!discountCode) {
      return [false, translate('error.invalidCode') ?? ''];
    }
    if (discountCode.isConsumed) {
      return [false, translate('error.codeAlreadyUsed') ?? ''];
    }
    return [true];
  };

  const selectCartProducts = async (orderProductIds: number[]) => {
    const invoiceNumber = invoiceInfo?.invoice_number;
    if (typeof invoiceNumber !== 'string') return;
    if (invoiceInfo?.is_checkout === 1 && invoiceInfo.status_code === 'pending') {
      await cancelOrder({ invoice_number: invoiceInfo.invoice_number });
    }
    selectProducts({ invoice_number: invoiceNumber, order_product_ids: orderProductIds }).then(response => {
      if (response?.is_successful) {
        getInvoice({ details: { invoice_number: invoiceNumber } });
      }
    });
  };

  useEffect(() => {
    removeAllDiscounts();
    removeStcWalletToUse();
  }, [isLoggedIn]);

  return {
    itemsInCart,
    total,
    subtotal,
    discounts,
    totalDiscounts,
    stcWalletToUse,
    subtotalFormat,
    deliveryFeeFormat,
    totalDiscountsFormat,
    stcWalletToUseFormat,
    totalFormat,
    deliveryFee,
    maxDiscounts,
    totalProductCount,
    totalSelectedProductsCount,
    addToCart,
    removeToCart,
    selectCartProducts,
    clearItems,
    increase,
    decrease,
    remove,
    addDiscount,
    removeDiscount,
    removeAllDiscounts,
    setDeliveryFee,
    removeDeliveryFee,
    setStcWalletToUse,
    removeStcWalletToUse,
    validateCode,
    unavailableProductCodes,
    productError,
    setProductError,
  };
};
