import { IMAGES } from '@fe-monorepo/assets';
import { BrazeCustomEvents, EventObject, IMAGE_ALT_LABELS, MixPanelEvents, TxKeyPath } from '@fe-monorepo/helper';
import { MatchmakingProvider, useTmsMatchmakingData, useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import InputField from '@fe-web/Molecules/InputFields/InputField/InputField';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';

import { webAnalytics } from '../../../../../../helper/webAnalytics';
import { authInputFieldStyleObj } from '../../CreateTournament/newTournamentStyles';
import Search from './Search/Search';
import Language from './SquareRadio/Language';
import Platform from './SquareRadio/Platform';
import MatchmakingResults from './results/MatchmakingResults';

const LoggedInMatchmaking = () => {
  const { translate } = useTranslate();
  const themes = useSelector((state: RootState) => state.app.themes);
  const isRtl = useSelector((state: RootState) => state.app.isRTL);
  const username = useSelector((state: RootState) => state.user.userContext.username);
  const [game, setGame] = useState<string>();
  const [gamePlatform, setGamePlatform] = useState<string>('');
  const [language, setLanguage] = useState<string>('');
  const [matchmakingDescription, setMatchmakingDescription] = useState<string>();
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [onLanding, setOnLanding] = useState<boolean>(true);
  const { btnClass } = usePageLayout();
  const { types, getTypes } = useTmsMatchmakingData();
  const [selectedGame, setSelectedGame] = useState<string>('');

  const submitForm = () => {
    if (isFormValid) {
      if (game && gamePlatform && language) {
        const startMatchmakingEvent: EventObject = {
          name: 'started_matchmaking',
          properties: {
            game_type: game,
            game_platform: gamePlatform,
            game_language: language,
          },
        };
        webAnalytics(startMatchmakingEvent);
        const platformName =
          types?.getTypes?.data?.platforms.find(platform => platform.platform_code === gamePlatform)?.platform_name_en ?? '';
        const languageName = types?.getTypes?.data?.language.find(lang => lang.lang_code === language)?.name_en ?? '';
        mixpanelHelper.trackEvent(MixPanelEvents.getMatched, {
          Platform: platformName,
          Language: languageName,
          Username: username,
          'Game Name': selectedGame,
        });
        brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.GetMatched, {
          Platform: platformName,
          Language: languageName,
          Username: username,
          'Game Name': selectedGame,
        });
      }
      setOnLanding(false);
    }
  };

  useEffect(() => {
    getTypes();
  }, []);

  useEffect(() => {
    if (language && gamePlatform && game) {
      setIsFormValid(true);
    } else {
      setIsFormValid(false);
    }
  }, [language, gamePlatform, game]);

  return (
    <div>
      {onLanding ? (
        <>
          <h1 className="font-medium text-fs-big-title text-secondary mt-[2rem]">{translate('play.matchmaking.get_matched')}</h1>
          <div id="INNER_CONTAINER" className="grid xl:grid-cols-2 gap-[3.75rem] mt-[2rem] SD:mt-[2.5rem]">
            <div
              id="FORM_SECTION"
              className="flex flex-col gap-[2rem] border-zinc-800-gray-200 rounded-lg p-[1rem] SD:p-[1.5rem] border-[0.0625rem]"
            >
              <Search setGame={setGame} setSelectedGame={setSelectedGame} selectedGame={selectedGame} />
              <Platform setGamePlatform={setGamePlatform} gamePlatform={gamePlatform} platformData={types?.getTypes.data.platforms} />
              <div className="flex flex-col gap-[0.25rem]">
                <h2 className="font-medium text-secondary text-fs-body-large">
                  {translate('create_tournament_page.tournament_4_additional_information')}
                </h2>
                <p className="font-regular text-fs-body-small text-zinc-600-400">{translate('play.matchmaking.info')}</p>
              </div>
              <InputField
                label={translate('play.matchmaking.description_optional') ?? ''}
                formPlaceHolder={translate('play.matchmaking.start_typing') ?? ''}
                isSecret={false}
                hasFocused={false}
                isUserAllowedToType={(value: string) => true}
                retrieveValue={(value: any) => setMatchmakingDescription(value)}
                style={authInputFieldStyleObj}
                existingValue={matchmakingDescription}
              />
              <Language setGameLanguage={setLanguage} gameLanguage={language} languageData={types?.getTypes.data.language} />
              <div className="flex items-end justify-end">
                <Button
                  text={`${translate('play.matchmaking.im_ready') ?? ''}`}
                  action={() => submitForm()}
                  style="
                    w-full SD:w-fit
                    min-w-[6.5rem]
                    h-[2.75rem]
                    px-[1.5rem]
                    py-[0.5rem]
                    border-[0.063rem]
                    rounded-[0.175rem] 
                    text-fs-body
                    font-medium
                    text-white100
                    whitespace-nowrap"
                  normalStyle={btnClass}
                  disabledStyle={`${themes === 'dark' ? `bg-[#2E2D30] border-[#2E2D30]` : `bg-black10 border-0`} `}
                  disabled={!isFormValid}
                />
              </div>
            </div>

            <div id="IMAGE_CARD_SECTION" className="hidden xl:block relative">
              <div className="h-full rounded-lg z-1 gap-[1.5rem] flex flex-col w-[28.125rem] absolute">
                <h2 className="text-fs-body-large font-medium text-secondary w-[22.875rem]">
                  {translate('create_tournament_page.tournament_image_header')}
                </h2>
                <ol
                  type="1"
                  className="text-neutral-400-zinc-500 font-regular gap-[1rem] text-fs-body !list-decimal px-[1rem] w-[16.375rem]"
                >
                  <li className="pb-[1rem] pl-[1rem]">{translate('create_tournament_page.tournament_step_1')}</li>
                  <li className="pb-[1rem] pl-[1rem]">{translate('create_tournament_page.tournament_step_2')}</li>
                  <li className="pb-[1rem] pl-[1rem]">{translate('create_tournament_page.tournament_step_3')}</li>
                </ol>
              </div>
              <LazyLoadImage
                src={IMAGES.MatchmakingLoggedInImage.toString()}
                alt={translate(IMAGE_ALT_LABELS.matchmaking_image_side_banner as TxKeyPath) ?? ''}
                className={`w-[17.0625rem] h-[30.125rem] bottom-0 ${isRtl ? 'left-[2.5rem] scale-x-[-1]' : 'right-[2.5rem]'} absolute`}
              />
            </div>
          </div>
        </>
      ) : (
        <MatchmakingProvider
          createInput={{
            reference_game_code: game ?? '',
            reference_platform_code: gamePlatform ?? '',
            description: matchmakingDescription ?? undefined,
            reference_lang_code: language ?? '',
          }}
        >
          <MatchmakingResults
            goBack={(reset = false) => {
              if (reset) {
                setLanguage('');
                setGame(undefined);
                setMatchmakingDescription(undefined);
                setGamePlatform('');
                setSelectedGame('');
              }
              setOnLanding(true);
            }}
          />
        </MatchmakingProvider>
      )}
    </div>
  );
};

export default LoggedInMatchmaking;
