import { useLazyQuery } from '@apollo/client';
import { TMS_BRACKET_QUERY } from '@fe-monorepo/data-access';
import { useState } from 'react';

import { TmsTournamentBracketGetAllInput, TmsTournamentBracketResponse } from './type';

export const useBracket = () => {
  const [isGetAllLoading, setIsGetAllLoading] = useState<boolean>();
  const [getAllGql, { data: getAllData }] = useLazyQuery<TmsTournamentBracketResponse>(TMS_BRACKET_QUERY.tmsBracketGetAll, {
    errorPolicy: 'all',
  });

  const getAll = async (details: TmsTournamentBracketGetAllInput) => {
    setIsGetAllLoading(true);
    const { data } = await getAllGql({ variables: { details } });
    setIsGetAllLoading(true);
    return data;
  };

  return { getAll, getAllData: getAllData?.tmsBracketGetAll?.data, isGetAllLoading };
};
