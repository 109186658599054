import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { FaqCategoryModel } from '@fe-monorepo/models';

export const faqCategoriesSlice = createSlice({
    name: 'faqCategories',
    initialState: {
       categoryData: [] as FaqCategoryModel[],
    },
    reducers: {
        setFqaCategories: (state, action: PayloadAction<FaqCategoryModel[]>) => {
            state.categoryData = action.payload;
        },
    },
});

export const { setFqaCategories } = faqCategoriesSlice.actions;
