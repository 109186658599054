import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import { useNavigate } from 'react-router-dom';

interface SideBarHeaderProps {
  isChatEmpty: boolean;
  showFullViewIcon: boolean;
  isRTL: boolean;
  onClickNewChat?: () => void;
  closeSidebar?: () => void;
}

const SideBarHeader = ({ isChatEmpty, showFullViewIcon, isRTL, onClickNewChat, closeSidebar }: SideBarHeaderProps) => {
  const { translate } = useTranslate();
  const navigate = useNavigate();

  const onSettingClick = () => {
    navigate(AppRoutes.chat_preferences);
  };

  const onMaximizeChatClick = () => {
    navigate(AppRoutes.messages);
  };

  return (
    <div className="flex flex-row justify-between">
      <p className="text-fs-body-large text-secondary font-medium">{translate('chat.sidebar.chats')}</p>
      <div className="flex flex-row gap-[1rem] hover:cursor-pointer">
        <ResponsiveIcon
          name={IconNames.gearIcon}
          baseHeight={20}
          baseWidth={20}
          iconClasses="dark:fill-white fill-black hover:fill-secondary/70 dark:hover:fill-white/50"
          onClick={onSettingClick}
        />
        {showFullViewIcon && (
          <ResponsiveIcon
            name={IconNames.icon_chat_maximize}
            baseHeight={20}
            baseWidth={20}
            iconClasses="dark:fill-white fill-black hover:fill-secondary/70 dark:hover:fill-white/50"
            onClick={onMaximizeChatClick}
          />
        )}
        <ResponsiveIcon
          name={IconNames.icon_chat_new}
          baseHeight={20}
          baseWidth={20}
          iconClasses="dark:fill-white fill-black hover:fill-secondary/70 dark:hover:fill-white/50"
          onClick={() => onClickNewChat && onClickNewChat()}
        />
        <ResponsiveIcon
          name={IconNames.close1}
          baseHeight={20}
          baseWidth={20}
          iconClasses="dark:fill-white fill-black hover:fill-secondary/70 dark:hover:fill-white/50"
          onClick={() => closeSidebar && closeSidebar()}
        />
      </div>
    </div>
  );
};

export default SideBarHeader;
