import { IMAGE_ALT_LABELS, IconNames, TxKeyPath } from '@fe-monorepo/helper';
import { MATCH_EVENTS, useMatchmakingProvider, useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import PopoverAbstract from '@fe-web/Molecules/Popover/PopoverAbstract';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../../../app.routes.enum';
import { BACKGROUNDS } from './GradientBackground';

interface PlayerSelectionItemProps {
  avatar_url?: string;
  username?: string;
  matchmaking_gamer_id?: number;
  animation?: string;
}

const PlayerSelectionItem = ({
  avatar_url,
  username,
  matchmaking_gamer_id,
  animation = MATCH_EVENTS.INITIAL,
}: PlayerSelectionItemProps) => {
  const isRtl = useSelector((state: RootState) => state.app.isRTL);
  const { translate } = useTranslate();
  const { isActionDisabled, unfollow, block } = useMatchmakingProvider();
  const [currentAnimation, setCurrentAnimation] = useState(animation);
  const navigate = useNavigate();
  useEffect(() => {
    setCurrentAnimation(MATCH_EVENTS.INITIAL);
  }, [matchmaking_gamer_id]);

  useEffect(() => {
    setCurrentAnimation(animation);
  }, [animation]);
  return (
    <motion.div
      initial={MATCH_EVENTS.INITIAL}
      animate={currentAnimation}
      variants={{
        initial: {
          opacity: 1,
          translateX: '0%',
        },
        follow: {
          opacity: [0, 0, 1],
          translateX: isRtl ? ['0%', '-100%'] : ['-100%', '0%'],
        },
        unfollow: {
          opacity: [1, 0, 0],
          translateX: isRtl ? ['-100%', '0%'] : ['0%', '-100%'],
        },
      }}
      transition={{ duration: currentAnimation === MATCH_EVENTS.INITIAL ? 0.0001 : 1 }}
      className="relative py-[1rem] overflow-x-clip"
    >
      <div className="flex gap-[1rem] items-center justify-between">
        <div className="flex gap-[1rem] items-center">
          <div className="h-40 w-40 bg-gray-200-zinc-700 border border-gray-200-zinc-700 rounded-full overflow-hidden">
            <Image
              img={avatar_url}
              alt={translate(IMAGE_ALT_LABELS.matchmaking_recently_followed_avatar as TxKeyPath) ?? ''}
              divStyle="h-40 w-40 bg-black/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden"
              imgStyle=""
              fallback={
                <ResponsiveIcon name={IconNames.avatar} baseHeight={16} baseWidth={16} iconClasses="h-full w-full p-[0.5rem] fill-gray" />
              }
            />
          </div>
          <HoverText
            className="text-otherGray font-regular text-fs-body-small"
            hover={{ color: 'text-otherGray' }}
            alwaysShowUnderline={false}
            underlineStyle="z-1 static bg-otherGray h-[0.0625rem]"
            text={`@${username}`}
            onClick={() => navigate(`${AppRoutes.users}/${username}`)}
          />
        </div>
        <PopoverAbstract disabled={isActionDisabled}>
          <ul className="text-secondary font-regular text-fs-body py-[0.5rem]">
            <li className="py-[0.5rem] px-[1rem]">
              <button
                disabled={isActionDisabled}
                onClick={() => {
                  unfollow && unfollow(username, matchmaking_gamer_id, 800);
                  setCurrentAnimation(MATCH_EVENTS.UNFOLLOW);
                }}
              >
                {translate('action_unfollow')}
              </button>
            </li>
            <li className="py-[0.5rem] px-[1rem]">
              <button
                disabled={isActionDisabled}
                onClick={() => {
                  block && block(username, matchmaking_gamer_id, 800);
                  setCurrentAnimation(MATCH_EVENTS.UNFOLLOW);
                }}
              >
                {translate('action_block')}
              </button>
            </li>
          </ul>
        </PopoverAbstract>
        <motion.div
          className="absolute top-[100%] left-[-100%] bg-green h-[0.125rem] w-full"
          initial={MATCH_EVENTS.INITIAL}
          animate={currentAnimation}
          variants={{
            initial: {
              left: '-100%',
              opacity: 0,
            },
            follow: {
              left: ['-100%', '100%'],
              opacity: 1,
            },
            unfollow: {
              left: ['100%', '-100%'],
              opacity: 1,
            },
          }}
          transition={{
            duration: currentAnimation === MATCH_EVENTS.INITIAL ? 0.0001 : 2,
            delay: currentAnimation === MATCH_EVENTS.INITIAL ? 0 : 1,
            ease: 'linear',
          }}
        ></motion.div>
        <div className="absolute top-[100%] h-[3.5rem] w-full overflow-hidden">
          <motion.div
            className="absolute h-[400%] w-full bottom-0"
            initial={MATCH_EVENTS.INITIAL}
            animate={currentAnimation}
            variants={{
              follow: {
                background: [BACKGROUNDS.ACCEPT, BACKGROUNDS.ACCEPT, BACKGROUNDS.ACCEPT, BACKGROUNDS.NO_BACKGROUND],
                scale: [1, 1, 1, 1, 0.5, 0],
                opacity: [0, 1, 1],
              },
              initial: {
                background: BACKGROUNDS.NO_BACKGROUND,
                opacity: 0,
              },
            }}
            transition={{
              duration: currentAnimation === MATCH_EVENTS.INITIAL ? 0.0001 : 2,
              delay: currentAnimation === MATCH_EVENTS.INITIAL ? 0 : 1,
              ease: 'linear',
            }}
          ></motion.div>
        </div>
      </div>
    </motion.div>
  );
};

export default PlayerSelectionItem;
