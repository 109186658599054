import { DiscountModel, InvoiceModel, InvoiceProduct } from '@fe-monorepo/models';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface cartState {
    items: InvoiceProduct[];
    invoiceNumber?: string;
    deliveryFee?: number;
    discounts?: DiscountModel[];
    stcWalletToUse?: number;
    invoice?: InvoiceModel;
    lmdCode?: string;
}

const initialState: cartState = {
    items: [],
};

interface UpdateItemInput {
    product_code: string;
    variant_id: number;
}

interface UpdateInStockInput extends UpdateItemInput {
    in_stock: number;
}

const computeSubtotal = (itemsInCart: InvoiceProduct) => {
    if (itemsInCart?.lowest_selling_price && itemsInCart?.lowest_actual_cost) {
        const discount = itemsInCart?.lowest_selling_price < itemsInCart?.lowest_actual_cost;
        if (discount) {
            itemsInCart.discount_price = (itemsInCart?.lowest_actual_cost - itemsInCart?.lowest_selling_price) * itemsInCart.qty;
            itemsInCart.subtotal = (itemsInCart?.lowest_selling_price * itemsInCart.qty);
            itemsInCart.grand_total = (itemsInCart?.lowest_selling_price * itemsInCart.qty);
            itemsInCart.unit_price_grand_total = (itemsInCart?.lowest_actual_cost * itemsInCart.qty);
        } else {
            itemsInCart.subtotal = (itemsInCart?.lowest_actual_cost) * itemsInCart.qty;
            itemsInCart.grand_total = (itemsInCart?.lowest_actual_cost) * itemsInCart.qty;
            itemsInCart.unit_price_grand_total = (itemsInCart?.lowest_actual_cost) * itemsInCart.qty;
        }
    }
    return itemsInCart;
};

export const cartSlice = createSlice({
    name: 'cart',
    initialState,
    reducers: {
        addCartItem: (state, action: PayloadAction<InvoiceProduct>) => {
            let itemsInCart = state.items?.find(item => item.product_code === action.payload.product_code && item.variant_id === action.payload.variant_id);

            if (itemsInCart) {
                itemsInCart.qty = itemsInCart.qty + action.payload.qty;
                itemsInCart = computeSubtotal(itemsInCart);
            } else {
                state.items.push({ ...action.payload, qty: action.payload.qty ?? 1 });
            }
        },
        removeCartItem: (state, action: PayloadAction<UpdateItemInput>) => {
            state.items = state.items.filter(item => (item.product_code !== action.payload.product_code || (item.product_code === action.payload.product_code && item.variant_id !== action.payload.variant_id)));
        },
        clearCart: state => {
            state.items = [];
        },
        increaseQuantity: (state, action: PayloadAction<UpdateItemInput>) => {
            let increase = state.items.find(item => item.product_code === action.payload.product_code && item.variant_id === action.payload.variant_id);
            if (increase) {
                increase.qty += 1;
                increase = computeSubtotal(increase);
            }
        },
        decreaseQuanity: (state, action: PayloadAction<UpdateItemInput>) => {
            let decrease = state.items.find(item => item.product_code === action.payload.product_code && item.variant_id === action.payload.variant_id);
            if (decrease && decrease.qty > 1) {
                decrease.qty -= 1;
                decrease = computeSubtotal(decrease);
            }
        },
        setDeliveryFee: (state, action: PayloadAction<number | undefined>) => {
            state.deliveryFee = action.payload;
        },
        setStcWalletToUse: (state, action: PayloadAction<number | undefined>) => {
            state.stcWalletToUse = action.payload;
        },
        addDiscount: (state, action: PayloadAction<DiscountModel>) => {
            if (!state.discounts) {
                state.discounts = [];
            }

            if (state.discounts?.find(({ code }) => code === action.payload.code)) {
                return;
            }

            state.discounts?.push(action.payload);
        },

        clearInvoice: state => {
            return { ...initialState, lmdCode: state.lmdCode };
        },

        removeDiscount: (state, action: PayloadAction<string>) => {
            if (!state.discounts) {
                state.discounts = [];
            }
            state.discounts = state.discounts?.filter(discount => discount.code != action.payload);
        },

        removeAllDiscounts: state => {
            state.discounts = [];
        },
        setInvoice: (state, action: PayloadAction<InvoiceModel | undefined>) => {
            state.invoice = action.payload;
        },
        updateCartItemStock: (state, action: PayloadAction<UpdateInStockInput>) => {
            let itemsInCart = state.items?.find(item => item.product_code === action.payload.product_code && item.variant_id === action.payload.variant_id);
            if (itemsInCart) {
                itemsInCart.in_stock = action.payload.in_stock;
            }
        },
        setCart: (state, action: PayloadAction<InvoiceProduct[] | undefined>) => {
            state.items = action.payload ? action.payload : [];
        },
    },
});

export const {
    addCartItem,
    clearInvoice,
    removeCartItem,
    clearCart,
    increaseQuantity,
    decreaseQuanity,
    setDeliveryFee,
    setStcWalletToUse,
    addDiscount,
    removeDiscount,
    removeAllDiscounts,
    setInvoice,
    updateCartItemStock,
    setCart,
} = cartSlice.actions;
