import { useTranslate } from '@fe-monorepo/hooks';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import RightArrowIcon from 'apps/fe-web/src/quickplay-app/assets/icons/RightArrowIcon';
import SoundWave from 'apps/fe-web/src/quickplay-app/assets/icons/SoundWave';
import VideoLikeIcon from 'apps/fe-web/src/quickplay-app/assets/icons/VideoLikeIcon';
import VideoViewsIcon from 'apps/fe-web/src/quickplay-app/assets/icons/VideoViewsIcon';
import { CONTENT_TYPE } from 'apps/fe-web/src/quickplay-app/constants';
import { useNavigate, useParams } from 'react-router-dom';

import LeftArrowIcon from '../../../../assets/icons/LeftArrowIcon';
import useSound from '../../../../hooks/useSound';
import Bottom from '../../../Layouts/Bottom';
import { formatLargeNumber } from '../../helpers';
import './SoundTrack.css';

const LIMIT = 6;

function SoundTrack() {
  const { soundId } = useParams();
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const defaultLanguage = localStorage.getItem('i18nextLng');

  const { sound, videos: videosBySound }: any = useSound(soundId as string);

  const handleContentDetail = (video: any, index: number) => {
    navigate(`${AppRoutes.bitsPlay}/content/${soundId}?contentType=${CONTENT_TYPE.SOUND}&clickedVideoId=${video.videoId}`);
  };

  return (
    <>
      <div className="full-sec">
        <div className="h-full">
          <div className="mainpage">
            <div className="mainpage-head">
              <div className="w-full  px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655 profile-head-back">
                <div className="back-btn">
                  <button type="button" className="back-btn-a" onClick={() => navigate(-1)}>
                    {defaultLanguage === 'en' ? <LeftArrowIcon /> : <RightArrowIcon />}
                    {translate('quickplay.back-btn')}
                  </button>
                </div>
                <button onClick={() => {}} className="tagged-sound mb-0">
                  {sound?.thumbnail && <img src={sound?.thumbnail} alt={`${sound?.name}-thumbnail`} />}
                  {!sound?.thumbnail && (
                    <>
                      <div className="icon">
                        <SoundWave />
                      </div>
                      <div></div>
                    </>
                  )}
                  <p>
                    {sound.artist} <span className="custom-dot">.</span> {sound.name}
                  </p>
                </button>
                <div></div>
              </div>
            </div>

            <div className="w-full h-full px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655 mainpage-body">
              <div className="flex flex-col gap-y-[2rem] justify-center items-center">
                <div className="grid grid-cols-12 gap-y-[2rem] gap-x-[1rem]" id="tab1">
                  {videosBySound?.map((video: any, index: number) => {
                    let thumbnailUrls = video.thumbnailUrls ? video.thumbnailUrls[0] : video.thumbnailUrl;
                    if (video.contentType === 'video') {
                      return (
                        <>
                          <div className="col-span-6 lg:col-span-2" key={video.videoId}>
                            <div className="relative cursor-pointer" id={video.videoId}>
                              {/* Video Image */}
                              <img
                                src={thumbnailUrls}
                                alt=""
                                className="w-full h-full aspect-[9/16] object-cover object-center rounded-md"
                                onClick={() => handleContentDetail(video, index)}
                              />

                              {/* Video Views & Like Count */}
                              <div className="video_detail">
                                <div className="video_info videoViews">
                                  <VideoViewsIcon />
                                  <span className="video_info--count">{formatLargeNumber(video.viewsCount)}</span>
                                </div>

                                <div className="video_info videoLike">
                                  <VideoLikeIcon />
                                  <span className="video_info--count">{formatLargeNumber(video.likeCount)}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Bottom />
    </>
  );
}

export default SoundTrack;
