import { useLazyQuery } from '@apollo/client';
import { RETURN_DATA_QUERY } from '@fe-monorepo/data-access';
import { useEffect, useState } from 'react';

import { GetAllReturnsInfoParamsModel, GetAllReturnsInfoResponse } from './type';

export const useGetAllReturnsInfo = () => {
  const [isSuccessful, setStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [getAllReturnsInfoGql, { data: infoDataResult }] = useLazyQuery<GetAllReturnsInfoResponse>(RETURN_DATA_QUERY.omsGetAllReturn, {
    errorPolicy: 'all',
  });

  const getAllReturnsInfo = async (getAllReturnsInfoResult: GetAllReturnsInfoParamsModel) => {
    const { data } = await getAllReturnsInfoGql({
      variables: {
        details: getAllReturnsInfoResult,
      },
    });
    return data?.omsGetAllReturn;
  };

  useEffect(() => {
    setIsLoading(true);
    if (infoDataResult) {
      if (infoDataResult?.omsGetAllReturn?.is_successful) {
        setStatus(infoDataResult?.omsGetAllReturn?.is_successful);
      }
      if (infoDataResult?.omsGetAllReturn?.error_msg) {
        setErrorMessage(infoDataResult?.omsGetAllReturn?.error_msg);
      }
      setStatus(infoDataResult?.omsGetAllReturn?.is_successful);
      setIsLoading(false);
    }
  }, [infoDataResult]);

  return { getAllReturnsInfo, getAllReturnsInfoData: infoDataResult?.omsGetAllReturn, isSuccessful, isLoading, errorMessage };
};
