import { MixPanelEvents } from '@fe-monorepo/helper';
import { useAccount, usePages, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { UserModel } from '@fe-monorepo/models';
import { RootState, clearCart, clearDeliveryAddress, clearInvoice, setUser, useAppDispatch } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import Checkbox from '@fe-web/Molecules/InputFields/Checkbox';
import Modal from '@fe-web/Templates/Modal/Modal';
import convivaHelper from '@fe-web/helpers/convivaHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { useBitsProvider } from '@fe-web/hooks/useBits/BitsProvider';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useNotificationProvider } from '@fe-web/hooks/useNotification/useNotificationProvider';
import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { AppRoutes } from '../../app.routes.enum';

const TermsAndConditionsModal = () => {
  const { translate } = useTranslate();
  const { dir } = useHeaderState();
  const isMobile = useMobileDetect();
  const { getStaticPages, pageData } = usePages();
  const user = useSelector((state: RootState) => state.user.userContext);
  const [isAccept, setIsAccept] = useState<boolean>(false);
  const [isMarketingAccept, setIsMarketingAccept] = useState<boolean>(Boolean(user.marketing_consent));
  const lang = useSelector((state: RootState) => state.app.language);
  const tncConfig = useSelector((state: RootState) => state.app.tncConfig);
  const dispatch = useAppDispatch();
  const { acceptUserConsent, logout } = useAccount();
  const { removeDeviceNotification } = useNotificationProvider();
  const { logoutFromQP } = useBitsProvider();

  useEffect(() => {
    getStaticPages('privacy_popup');
    mixpanelHelper.trackEvent(MixPanelEvents.consentPopupWindowViewed, {
      Username: user.username,
    });
  }, []);

  const handleAccept = async () => {
    try {
      const page_code: any = ['privacy'];
      if (isMarketingAccept) page_code.push('marketing');
      const consentResponse = await acceptUserConsent({ page_code: JSON.stringify(page_code) });
      if (consentResponse?.data?.acceptConsent?.is_successful) {
        dispatch(setUser({ privacy_consent: true, marketing_consent: page_code.includes('marketing') } as UserModel));
        mixpanelHelper.trackEvent(MixPanelEvents.acceptAndContinueButton, {
          'Button Name': 'Accept and Continue',
          'Marketing Checbox': isMarketingAccept,
          'PP/T&C Checbox': isAccept,
          'User ID': user.uuid,
        });
      }
    } catch (error: any) {
      // error
    }
  };

  const handleReject = async () => {
    try {
      mixpanelHelper.trackEvent(MixPanelEvents.consentRejected, {
        'Button Name': 'Reject and sign out',
        'User ID': user.uuid,
      });
      removeDeviceNotification();
      dispatch(clearCart());
      dispatch(clearInvoice());
      dispatch(clearDeliveryAddress());
      logout();
      mixpanelHelper.reset();
      convivaHelper.setIdentity('GUEST');
      logoutFromQP();
      localStorage.removeItem('selectedTimezone');
      localStorage.removeItem('selectedCurrency');
      localStorage.removeItem('selectedCountry');
    } catch (error: any) {
      // error
    }
  };

  const content = lang === 'ar' ? pageData?.content_ar : pageData?.content_en;

  const StyledStaticPage = () => {
    const html =
      `<html>
        <head>
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
        </head>
        <style type="text/css">
          h2 {
            margin-top: 2.625rem; 
            margin-bottom: 1rem;    
            font-size: 1rem;
            font-weight: 800;
          }
          ul {
            list-style-type: square;
            list-style-position: inside;
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding-left: 1rem;
          }
          ol {
            list-style-type: number;
            list-style-position: inside;
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding-left: 1rem;
          }
        </style>
        <body className="h-full font-regular text-fs-body text-secondary/70" dir=` +
      dir +
      `>` +
      content +
      `</body>
        </html>`;
    return html;
  };

  const title = lang === 'ar' ? tncConfig?.tnc_title_ar : tncConfig?.tnc_title_en;

  return (
    <Modal open={true} backdropClassName="backdrop-blur w-[100%] h-[100%] p-[1.25rem]">
      <div
        className="relative flex flex-col items-center
          border-[1px] border-solid border-white10
          w-[100%] md:w-[49.5rem] rounded-[0.5rem]
          bg-primary self-center p-[1.25rem] md:p-[3rem]"
      >
        <div className="flex flex-col w-full gap-[1.5rem]">
          <h4 className="font-medium text-secondary text-fs-subtitle">
            {title ?? translate('terms_and_conditions.terms_and_conditions_and_privacy_policy')}
          </h4>
          <div className="max-h-[20rem] md:max-h-[30rem] overflow-scroll">
            <div
              className="
                flex flex-col text-secondary/70 mb-[1rem] gap-[.5rem] 
              [&_a]:text-sunset [&_a]:!underline
                [&_p]:mb-[1rem]
                [&_strong]:font-medium [&_strong]:text-secondary"
            >
              {content ? parse(StyledStaticPage()) : ''}
            </div>
            <div className="flex gap-x-[0.75rem]">
              <div className="flex w-[1.5rem] h-[1.5rem] items-center justify-center">
                <Checkbox
                  isChecked={isMarketingAccept}
                  onClick={() => setIsMarketingAccept(!isMarketingAccept)}
                  classNames="w-[1.125rem] h-[1.125rem] items-center justify-center"
                  width={1.125}
                  height={1.125}
                />
              </div>
              <p className="text-fs-body-small font-regular text-secondary/70">
                {translate('terms_and_conditions.i_would_like_to_receive_updates_about_new_content')}
              </p>
            </div>
            <div className="flex gap-x-[0.75rem] items-center">
              <div className="flex w-[1.5rem] h-[1.5rem] items-center justify-center">
                <Checkbox
                  isChecked={isAccept}
                  onClick={() => setIsAccept(!isAccept)}
                  classNames="w-[1.125rem] h-[1.125rem] items-center justify-center"
                  width={1.125}
                  height={1.125}
                />
              </div>
              <p className="text-fs-body-small font-regular text-secondary/70">
                {translate('terms_and_conditions.i_have_read_and_agreed_to_the') + ' '}
                <a href={AppRoutes.helpPrivacyPolicy} className="text-sunset !underline" target="_blank">
                  {translate('terms_and_conditions.privacy_policy') + ' '}
                </a>
                {translate('terms_and_conditions.and') + ' '}
                <a href={AppRoutes.helpTemrsCondition} className="text-sunset !underline" target="_blank">
                  {translate('terms_and_conditions.terms_and_conditions') + '.'}
                </a>
              </p>
            </div>
          </div>
          <div className="flex flex-col SD:flex-row w-full justify-center items-center gap-y-[1rem] gap-x-[1.25rem]">
            <Button
              text={translate('terms_and_conditions.accept_and_continue')}
              style={`text-base not-italic max-h-[2.5rem]  font-medium
                ${isMobile ? 'px-[1rem]' : 'px-[1.5rem]'} w-full py-[0.5rem] gap-[0.5rem] z-1
                text-white border-sunset border-[0.063rem] mb-[0px]
                items-end
                rounded-[0.188rem] rounded-[0.188rem]`}
              normalStyle={`
                ${dir === 'ltr' ? 'after:bg-sunset before:bg-primary' : 'before:bg-sunset after:bg-primary'}
                hover:border-sunset hover:text-sunset`}
              disabledStyle={'bg-secondary/[10%] border-secondary/[10%]'}
              disabled={!isAccept}
              onClick={() => {
                handleAccept();
              }}
            />
            <Button
              text={translate('terms_and_conditions.reject_and_sign_out')}
              style={`text-base not-italic max-h-[2.5rem] border-[0.063rem] border-sunset font-medium
                ${isMobile ? 'px-[1rem]' : 'px-[1.5rem]'} w-full py-[0.5rem] gap-[0.5rem] z-1
                text-sunset mb-[0px]
                  items-end
                rounded-[0.188rem] 
                rounded-[0.188rem]`}
              normalStyle={`
                ${dir === 'ltr' ? 'after:bg-primary before:bg-sunset' : 'before:bg-primary after:bg-sunset'}
                hover:border-sunset hover:text-primary`}
              onClick={() => {
                handleReject();
              }}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

const TermsAndConditions = () => {
  const { pathname } = useLocation();
  const user = useSelector((state: RootState) => state.user.userContext);
  const tncConfig = useSelector((state: RootState) => state.app.tncConfig);
  const { isLoggedIn } = useUserProfile();
  const isOpen = Boolean(
    tncConfig?.privacy_modal_enabled && user?.token && !user?.privacy_consent && isLoggedIn && !pathname?.startsWith(AppRoutes.help),
  );

  if (isOpen) return <TermsAndConditionsModal />;
  return null;
};

export default TermsAndConditions;
