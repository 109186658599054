import { useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import { useConnectProvider } from '@fe-web/hooks/useConnect';
import useGetCurrentBreakPoint from '@fe-web/hooks/useGetCurrentBreakPoint/useGetCurrentBreakPoint';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SidebarNotificationFilters from './NotificationFilters/SidebarNotificationFilters';
import SidebarNotificationEmpty from './components/SidebarNotification/SidebarNotificationEmpty';
import SidebarNotificationHeader from './components/SidebarNotification/SidebarNotificationHeader';
import SidebarNotificationList from './components/SidebarNotification/SidebarNotificationList';

interface SideNotificationOverviewProps {
  showFullViewIcon: boolean;
  fixedPosition: boolean;
  openFilter?: () => void;
  onClickChat?: (conversationID: string, conversationType: number) => void;
  isNotificationOpen?: boolean;
  closeSidebar?: () => void;
  setIsInviteUser?: (value: boolean) => void;
  isFromChat?: boolean;
}

enum SidebarChatContainerAnimationType {
  show = 'show',
  hide = 'hide',
}

const SidebarNotificationContainer: React.FC<SideNotificationOverviewProps> = props => {
  const containerClasses = `fixed z-30 p-[1rem] pt-0 bottom-0 left-0 w-full h-0 flex flex-row-reverse items-end gap-[0.5rem]`;
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const { notificationSidebarStatus } = useConnectProvider();
  const [currentAnimation2, setCurrentAnimation2] = useState<SidebarChatContainerAnimationType>();
  const { currentBreakPoint } = useGetCurrentBreakPoint();

  const higherScreens = ['2k', '4k', '8k', 'Ll', 'normal'];
  const widthChecker = higherScreens.includes(currentBreakPoint) ? '25%' : '20%';

  const closeFilters = () => {
    setOpenFilter(false);
  };

  // checks what animation should be used when opening and closing the notification sidebar
  useEffect(() => {
    setCurrentAnimation2(notificationSidebarStatus ? SidebarChatContainerAnimationType.show : SidebarChatContainerAnimationType.hide);
  }, [notificationSidebarStatus]);

  return (
    <div className={containerClasses}>
      {notificationSidebarStatus && (
        <motion.div
          initial={false}
          variants={{
            [SidebarChatContainerAnimationType.show]: {
              opacity: [0, 1, 1],
              left: ['100%', '0%'],
              width: ['0%', widthChecker],
            },
            [SidebarChatContainerAnimationType.hide]: {
              opacity: [1, 1, 0],
              left: ['0%', '100%'],
              width: [widthChecker, '0%'],
            },
          }}
          transition={{
            duration: 0.5,
            ease: 'easeInOut',
          }}
          animate={currentAnimation2}
          className="relative w-[20%] h-[90vh] XGA:w-[30%] HD:w-[20%] FHD:w-[17%] 2K:w-[25%] 2K:h-[87vh] z-[11]"
        >
          <div className="absolute top-0 flex justify-start w-full h-full bg-primary border-[.1rem] rounded-[.5rem] border-secondary/25">
            {openFilter ? (
              <div className="p-[1.25rem] w-full h-full">
                <SidebarNotificationFilters closeFilters={closeFilters} />
              </div>
            ) : (
              <SidebarNotificationOverview {...props} openFilter={() => setOpenFilter(true)} />
            )}
          </div>
        </motion.div>
      )}
    </div>
  );
};

const SidebarNotificationOverview: React.FC<SideNotificationOverviewProps> = ({ showFullViewIcon, fixedPosition, openFilter }) => {
  const { isNotificationEmpty } = useNotificationProvider();
  const isRtl = useSelector((state: RootState) => state.app.isRTL);
  const { user } = useUserProfile();
  const isVerifyMotivationNeeded = !user?.email || !user?.is_email_verified || !user?.mobile || !user?.is_mobile_verified;
  const containerClasses = fixedPosition ? 'h-full' : 'h-[80vh]';

  return (
    <div className={`${containerClasses} flex flex-col w-full`}>
      <div className="flex flex-col w-full gap-[1.25rem] p-[1.25rem]">
        <SidebarNotificationHeader isNotificationEmpty={true} showFullViewIcon={showFullViewIcon} isRTL={isRtl} openFilter={openFilter} />
      </div>
      {isNotificationEmpty && !isVerifyMotivationNeeded ? <SidebarNotificationEmpty /> : <SidebarNotificationList isMinimized />}
    </div>
  );
};

export default SidebarNotificationOverview;
export { SidebarNotificationContainer };
