import { IMAGES } from '@fe-monorepo/assets';
import { useTranslate } from '@fe-monorepo/hooks';
import Image from '@fe-web/Atoms/Media/Image';
import { ReactNode, useState } from 'react';

interface EmptyStateProps {
  message?: string | null;
  icon?: JSX.Element;
  isMobile?: boolean;
  img?: string;
  imgClass?: string;
  divClass?: string;
  messageClass?: string;
  hideCaption?: boolean;
  hideMessage?: boolean;
  caption?: string;
  captionClass?: string;
  captionElement?: ReactNode;
}

const EmptyState = (props: EmptyStateProps) => {
  const { translate } = useTranslate();
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  return (
    <div
      className={`flex flex-col pb-[2.5rem] items-center gap-[1rem] ${props.isMobile ? 'w-full' : 'w-[30%] FHD:w-[20%] 2K:w-[30%]'} ${
        props.divClass ?? ''
      }`}
    >
      <div>
        <Image
          img={props.img ? props.img : IMAGES.CartNoItemHD.toString()}
          divStyle={`${props.imgClass ? props.imgClass : 'w-[5rem] 2K:h-[8rem]'} overflow-hidden`}
          imgStyle="object-contain transition transition-all duration-300 w-full h-full"
          onLoad={(isLoaded: boolean) => {
            setIsImageLoaded(isLoaded);
          }}
        />
      </div>
      {isImageLoaded ? (
        <>
          <p className={`text-fs-subtitle font-medium text-secondary ${props.messageClass}`}>
          {!props.hideMessage && (props.message ?? translate('search.no_results'))}
          </p>
          {!props.hideCaption && (
            <p className={`text-fs-body-small font-regular text-secondary/70 text-center ${props.captionClass}`}>
              {props.captionElement ? props.captionElement : props.caption ?? translate('search.no_results_caption')}
            </p>
          )}
        </>
      ) : null}
    </div>
  );
};

export default EmptyState;
