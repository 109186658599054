import { useMutation } from '@apollo/client';
import { CONTACT_MUTATION } from '@fe-monorepo/data-access';
import { useState } from 'react';

import { CommContactSendInput, CommContactSendMutationResponse } from './types';

export const useCommContact = () => {
  const [sendGql, { data: sendData }] = useMutation<CommContactSendMutationResponse>(CONTACT_MUTATION.commContactSend, {
    errorPolicy: 'all',
  });

  const [sendLoading, setSendLoading] = useState<boolean>(false);

  const send = async (details: CommContactSendInput) => {
    const { data } = await sendGql({ variables: { details } });
    return data;
  };

  return {
    sendData: sendData?.commContactSend,
    sendLoading,
    setSendLoading,
    send,
  };
};
