import { useTranslate } from '@fe-monorepo/hooks';
import { useEffect, useState } from 'react';

interface Props {
  content: string;
}

const MiniGamesDetailsDescription = ({ content }: Props) => {
  const { translate } = useTranslate();
  const [description, setDescription] = useState<string>('');

  useEffect(() => {
    setDescription(content);
  }, [content]);

  return (
    <div className="inline-flex flex-col gap-[1.25rem]">
      <p className="font-medium text-fs-subtitle text-secondary">{translate('formAttribute_description')}</p>
      <p className="font-regular text-fs-body text-secondary">{description}</p>
    </div>
  );
};

export default MiniGamesDetailsDescription;
