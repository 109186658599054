import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LMDModel } from '@fe-monorepo/models';

type CountrySliceState = {
    lmds: LMDModel[];
    LmdCode: string;
};

export const countrySlice = createSlice({
    name: 'country',
    initialState: {
        lmds: [],
        LmdCode: '',
    } as unknown as CountrySliceState,
    reducers: {
        setLmds: (state, action: PayloadAction<LMDModel[]>) => {
            state.lmds = action.payload;
        },
        setLmdCode: (state, action: PayloadAction<string>) => {
            state.LmdCode = action.payload;
        },
    },
});

export const { setLmds, setLmdCode } = countrySlice.actions;
