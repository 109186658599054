import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import useTheme from '@fe-web/hooks/useTheme';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';

interface IPhoneNumberEmpty {
  addPhoneNumber?: (value: boolean) => void;
  headerTitle?: string;
  headerTitleStyle?: string;
  iconSource?: IconNames;
  iconStyle?: string;
  headerMessage?: string;
  message?: string;
  hideButton?: boolean;
}

const PhoneNumberEmpty = ({
  addPhoneNumber,
  headerTitle,
  headerTitleStyle,
  iconSource,
  headerMessage,
  message,
  hideButton,
  iconStyle,
}: IPhoneNumberEmpty) => {
  const { isDarkTheme } = useTheme();
  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  const { translate } = useTranslate();
  const navigate = useNavigate();
  return (
    <div className="flex flex-col justify-between h-[80vh] SD:h-full SD:gap-[1.5rem] SD:items-start">
      <div>
        <div className="flex flex-row gap-[1rem]">
          <ResponsiveIcon
            name={IconNames.arrow}
            baseWidth={24}
            baseHeight={24}
            className={`fill-sunset ${!isRTL && 'rotate-180'} self-center SD:hidden`}
            onClick={() => navigate(AppRoutes.userProfile + '/settings')}
          />
          <p className={`${headerTitleStyle} text-fs-subtitle font-medium text-secondary`}>{headerTitle}</p>
        </div>
        {iconSource && (
          <ResponsiveIcon
            name={iconSource}
            baseWidth={160}
            baseHeight={160}
            className={`${iconStyle} w-[10rem] 2K:h-[10rem] 4K:h-[12rem] 8K:h-[15rem] overflow-hidden py-[1.5rem]`}
            iconClasses="object-contain transition transition-all duration-300 w-full h-full"
          />
        )}
        <div className="flex flex-col gap-[1rem] text-fs-body">
          <p className="font-medium text-secondary">{headerMessage}</p>
          <div className={`flex flex-col SD:w-[22rem] font-regular ${isDarkTheme ? 'text-secondary' : 'text-black70'}`}>
            <p>{message}</p>
          </div>
        </div>
      </div>
      {!hideButton && (
        <Button
          text={translate('settings.phone_number_settings.no_phone_number.add_phone_number') ?? ''}
          style={`
            text-fs-body rounded-md font-medium h-[2.5rem] border-[0.063rem] border-sunset mt-[0.5rem] w-full
            gap-[0.5rem] z-1
            text-white mb-[0px]
            items-end
            rounded-sm hover:text-sunset
            xsMax:mb-[0px] SD:w-[15rem]
            ${
              isDarkTheme
                ? isRTL
                  ? 'after:bg-white100 before:bg-sunset'
                  : 'before:bg-white100 after:bg-sunset'
                : `${isRTL ? 'after:bg-white100 before:bg-sunset' : 'before:bg-white100 after:bg-sunset'}`
            }`}
          disabled={false}
          onClick={() => addPhoneNumber?.(true)}
        />
      )}
    </div>
  );
};

export default PhoneNumberEmpty;
