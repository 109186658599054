// @ts-nocheck
import { BrazeCustomEvents, MixPanelEvents, PAGE_DESCRIPTION, PAGE_KEYWORDS, PAGE_TITLE, translate } from '@fe-monorepo/helper';
import { BGType, useThemedBackground, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import SuperPlus from '@fe-web/Molecules/SuperPlus/SuperPlus';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import quickplay from 'quickplay-shorts-js';
import { createRef, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import mixpanelHelper from '../../../app/helpers/mixpanelHelper';
import DownArrowGray from '../../assets/icons/DownArrowGray';
import LeftArrowIcon from '../../assets/icons/LeftArrowIcon';
import RightArrowIcon from '../../assets/icons/RightArrowIcon';
import UpArrowGray from '../../assets/icons/UpArrowGray';
import { EVENTS } from '../../constants';
import { useLoginModalContext } from '../../contexts/LoginModal';
import useAnalytics from '../../hooks/useAnalytics';
import useAutoLogin from '../../hooks/useAutoLogin';
import Bottom from '../Layouts/Bottom';
import LoginModal from '../Login-UI/LoginModal';
import Loader from '../common/Loader';
import './../../App.css';
import CreateIcon from './Icons/CreateIcon';
import VideoItem from './VideoItem';
import EmptyFeed from './components/EmptyFeed/EmptyFeed';
import { getVideosData } from './helpers';
import useVerticalPlayer from './hooks/useVerticalPlayer';

const feedModule = new quickplay.QuickPlayFeedModule();
const discoverModule = new quickplay.QuickPlayDiscover();
const activityTimelineModule = new quickplay.QuickPlayActivityTimeline();

const limit = 6;

const VerticalPlayer = () => {
  const { videos, setVideos, currentVideoIndex, setCurrentVideoIndex } = useVerticalPlayer();
  const [page, setPage] = useState(1);
  const [categoryList, setCategoryList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { token } = useAutoLogin();
  const isMobile = useMobileDetect();
  const [scrollDirection, setScrollDirection] = useState('');
  const lastScrollTop = useRef(0);
  const { showLoginModal, setShowLoginModal } = useLoginModalContext();
  const [followingClicked, setFollowingClicked] = useState(false);
  const [forYou, setForYou] = useState(false);
  const verticalPlayerRef = useRef<HTMLDivElement>(null);
  const themeConfig = useSelector((state: RootState) => state.app.themeConfig);
  const videoRefs = useRef<any>(null);
  const userData = JSON.parse(localStorage.getItem('userData') ?? '{}');
  const { trackEvent } = useAnalytics();
  const navigate = useNavigate();
  const [selectedCategoryId, setSelectedCategoryId] = useState('');
  const { user, isLoggedIn } = useUserProfile();
  const { translate: localisedTranslation } = useTranslate();
  const defaultLanguage = localStorage.getItem('i18nextLng');
  let previousVideo: HTMLVideoElement | null = null;
  let nextVideo: HTMLVideoElement | null = null;
  let previousVideoObject: any = null;
  let nextVideoObject: any = null;

  const { backgroundImage } = useThemedBackground(BGType.landing, isMobile);

  const labelToMethodMap = {
    following: () => feedModule.getFollowingFeedList({ limit, offset: page }),
    default: () => feedModule.getFeedList({ limit, offset: page, c_type: 'video' }),
    category: () => {
      if (selectedCategoryId) {
        const {
          data: { result: categoryFeeds = [] } = {}, // Add a default empty object for data
        } = discoverModule.getDiscoverCategoryWiseVideo(selectedCategoryId) || {};

        if (categoryFeeds.length > 0) {
          return discoverModule.getDiscoverCategoryWiseVideo(selectedCategoryId);
        } else {
          return labelToMethodMap.default();
        }
      }
      // Fallback to default feed if no category is selected
      return labelToMethodMap.default();
    },
    // Add more mappings as needed
  };

  const selectedLabel = followingClicked ? 'following' : selectedCategoryId ? 'category' : 'default';
  const fetchFeeds = labelToMethodMap[selectedLabel] || labelToMethodMap.default;

  const handleAllClick = () => {
    setIsLoading(true);
    setForYou(true);
    setFollowingClicked(false);
    setSelectedCategoryId(''); // Clear the selected category to trigger the default feed
  };

  useEffect(() => {
    if (videos.length <= 6) {
      if (currentVideoIndex === Math.round(videos.length / 2)) {
        setPage(prev => prev + 1);
      }
    } else {
      if (currentVideoIndex === videos.length - 4) {
        setPage(prev => prev + 1);
      }
    }
  }, [videos.length, currentVideoIndex]);

  useEffect(() => {
    setPage(1);
  }, [followingClicked, selectedCategoryId, forYou]);

  useEffect(() => {
    async function fetchCategoryList() {
      try {
        const feedModuleObj = new quickplay.QuickPlayFeedModule();
        // eslint-disable-next-line prefer-const
        let feedModuleResponse = await feedModuleObj.getCategoryList({
          search: '',
          limit: 10,
          offset: 1,
        });
        if (feedModuleResponse.status === 200) {
          // eslint-disable-next-line prefer-const
          let categoriesList = feedModuleResponse.data.result;
          if (categoriesList?.length) {
            setCategoryList(categoriesList);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    fetchCategoryList();
  }, []);

  useEffect(() => {
    const fetchVideos = async () => {
      try {
        const {
          data: { result: feeds = [] },
        } = await fetchFeeds();

        if (feeds.length && feeds.filter((video: any) => video.contentType === 'video').length === 0) {
          setPage(prev => prev + 1);
        }
        let updatedVideos;
        if (page > 1) {
          // Append new data when scrolling
          updatedVideos = [...videos, ...getVideosData(feeds, videos.length)];
        } else {
          // Replace data when a new category is selected
          updatedVideos = getVideosData(feeds, 0);
        }
        setVideos(updatedVideos);
        videoRefs.current = [...updatedVideos.map(() => createRef<HTMLDivElement>())];
        setIsLoading(false);
      } catch (error) {
        console.error(error);
      }
    };
    fetchVideos();
  }, [page, forYou, followingClicked, selectedCategoryId]);

  useEffect(() => {
    if (videoRefs.current && videoRefs.current.length > 0) {
      const currentVideo = videoRefs.current[currentVideoIndex]?.current;
      if (currentVideo) currentVideo.scrollIntoView({ behavior: 'smooth' });
    }

    const handleLoadMore = () => {
      const scrollTop = verticalPlayerRef?.current?.scrollTop ?? 0;
      const scrollHeight = verticalPlayerRef?.current?.scrollHeight ?? 0;
      const clientHeight = verticalPlayerRef?.current?.clientHeight ?? 0;

      // Load more content if scrolled to the bottom
      if (scrollTop + clientHeight >= scrollHeight - 10) {
        setPage(prev => prev + 1);
      }

      // Determine scroll direction
      if (scrollTop > lastScrollTop.current) {
        // Scrolling down
        if (scrollDirection !== 'down') {
          setScrollDirection('down');
        }
      } else if (scrollTop < lastScrollTop.current) {
        // Scrolling up
        if (scrollDirection !== 'up') {
          setScrollDirection('up');
        }
      }

      // Update last scroll position
      lastScrollTop.current = scrollTop;
    };

    // Attach scroll event listener
    verticalPlayerRef?.current?.addEventListener('scroll', handleLoadMore);

    return () => {
      verticalPlayerRef?.current?.removeEventListener('scroll', handleLoadMore);
    };
  }, [currentVideoIndex]);

  useEffect(() => {
    // Ensure videoRefs are populated before accessing them
    if (videoRefs.current && videoRefs.current.length > 0) {
      previousVideo = videoRefs.current[currentVideoIndex - 1]?.current || null;
      nextVideo = videoRefs.current[currentVideoIndex + 1]?.current || null;

      previousVideoObject = videos[currentVideoIndex - 1] || null;
      nextVideoObject = videos[currentVideoIndex + 1] || null;
    }

    const handleTimeUpdate = () => {
      if (previousVideo) {
        const previousVideoPlaybackTime = previousVideo.currentTime;
        console.log('Previous Video Playback Time', previousVideoPlaybackTime);
      }
      if (nextVideo) {
        const nextVideoPlaybackTime = nextVideo.currentTime;
        console.log('Next Video Playback Time', nextVideoPlaybackTime);
      }
    };

    // Attach event listeners to previous and next videos
    if (previousVideo) {
      previousVideo.addEventListener('timeupdate', handleTimeUpdate);
    }
    if (nextVideo) {
      nextVideo.addEventListener('timeupdate', handleTimeUpdate);
    }

    if (scrollDirection === 'down' && previousVideo) {
      let playbackTime = parseFloat(previousVideo.currentTime.toFixed(2));
      trackEvent({
        event: EVENTS.CONTENT_STOP_PLAY,
        content: previousVideoObject,
        timeElapsed: playbackTime || 0,
      });

      if (playbackTime >= 3) {
        activityTimelineModule
          .activityTimelineView({ assetId: previousVideoObject.videoId, duration: playbackTime })
          .then((response: any) => {
            const { status } = response ?? {};
            if (status === 200) {
              trackEvent({
                event: EVENTS.VIEW_IMPRESSION,
                content: previousVideoObject,
              })
                .then(res => {
                  const eventPayload = {
                    'Bit ID': previousVideoObject.videoId,
                    'Bit Title': previousVideoObject.title,
                    'Viewer Username': user?.username ?? 'GUEST',
                    'Bit Publisher Username': previousVideoObject.user.userName,
                  };
                  mixpanelHelper.trackEvent(MixPanelEvents.bitViewed, eventPayload);
                  brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.BitViewed, eventPayload);
                })
                .catch(error => {
                  console.error('Error tracking event:', error);
                });
            }
          });
      }
    } else if (scrollDirection === 'up' && nextVideo) {
      let playbackTime = parseFloat(nextVideo.currentTime.toFixed(2));
      trackEvent({
        event: EVENTS.CONTENT_STOP_PLAY,
        content: nextVideoObject,
        timeElapsed: playbackTime || 0,
      });
      if (playbackTime >= 3) {
        activityTimelineModule.activityTimelineView({ assetId: nextVideoObject.videoId, duration: playbackTime }).then((response: any) => {
          const { status } = response ?? {};
          if (status === 200) {
            trackEvent({
              event: EVENTS.VIEW_IMPRESSION,
              content: nextVideoObject,
            })
              .then(res => {
                const eventPayload = {
                  'Bit ID': nextVideoObject.videoId,
                  'Bit Title': nextVideoObject.title,
                  'Viewer Username': user?.username ?? 'GUEST',
                  'Bit Publisher Username': nextVideoObject.user.userName,
                };
                mixpanelHelper.trackEvent(MixPanelEvents.bitViewed, eventPayload);
                brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.BitViewed, eventPayload);
              })
              .catch(error => {
                console.error('Error tracking event:', error);
              });
          }
        });
      }
    }

    // Cleanup event listeners on unmount or when dependencies change
    return () => {
      if (previousVideo) {
        previousVideo.removeEventListener('timeupdate', handleTimeUpdate);
      }
      if (nextVideo) {
        nextVideo.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [scrollDirection, currentVideoIndex]);

  useEffect(() => {
    if (currentVideoIndex === videos.length - 2) {
      setPage(prev => prev + 1);
    }
  }, [currentVideoIndex, videos.length]);

  const handleNext = () => {
    // trackEvent({
    //   event: EVENTS.CONTENT_SWIPE,
    // });
    setScrollDirection('down');
    setCurrentVideoIndex(prev => prev + 1);
  };

  const handlePrev = () => {
    // trackEvent({
    //   event: EVENTS.CONTENT_SWIPE,
    // });
    setScrollDirection('up');
    setCurrentVideoIndex(prev => prev - 1);
  };

  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'ArrowDown' && currentVideoIndex !== videos.length - 1) {
        handleNext();
      }
    };

    const handleKeyUp = (event: any) => {
      if (event.key === 'ArrowUp' && currentVideoIndex !== 0) {
        handlePrev();
      }
    };

    // Add event listener when component mounts
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    // Cleanup: remove event listener when component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [currentVideoIndex, videos.length]);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty('--default-viewport-value', isLoggedIn ? '180px' : '120px');
  }, [isLoggedIn]);

  const loginModalHandler = () => {
    setShowLoginModal(true);
  };

  const handleCreateContent = (event: any) => {
    event.preventDefault();
    if (userData.creator) {
      navigate(`${AppRoutes.bits}/create-post/step-1`);
    } else {
      setShowLoginModal(true);
    }
  };

  const handleCategoryChange = (newCategoryId: string) => {
    setIsLoading(true);
    setForYou(false);
    setSelectedCategoryId(newCategoryId); // Update the selected category ID
  };

  useEffect(() => {
    mixpanelHelper.trackPageView('BITS PAGE', 'bits_page', window.location.pathname, document.referrer);
    brazeHelper.trackPageView('BITS PAGE', 'bits_page', window.location.pathname, document.referrer);
  }, []);

  const goBack = () => {
    window.history.back();
  };

  return (
    <>
      {isLoading && <Loader />}
      <CustomHelmet
        pageTitle={translate(PAGE_TITLE.bits as TxKeyPath) ?? ''}
        canonicalURL={window.location.href}
        metaDescription={translate(PAGE_DESCRIPTION.bits as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.bits as TxKeyPath) ?? ''}
      />
      <div className="main-wrapper max-h-screen">
        <div className="main-head">
          <button className="back-btn-a" onClick={goBack}>
            {defaultLanguage === 'en' ? <LeftArrowIcon /> : <RightArrowIcon />}
            {translate('quickplay.back-btn')}
          </button>
          <div className={`main-cta ${followingClicked ? 'top-[8rem]' : 'top-[12.5rem]'} lg:top-0 forYou_followingBtns`}>
            {isLoggedIn && (
              <>
                <button
                  className={`forYouFeed_btn ${!followingClicked ? 'active' : ''}`}
                  onClick={() => {
                    setFollowingClicked(false);
                    setIsLoading(true);
                    setForYou(true);
                  }}
                >
                  {localisedTranslation('quickplay.label-for-you')}
                </button>
                <button
                  className={`followingFeed_btn ${!followingClicked ? '' : 'active'}`}
                  onClick={() => {
                    setFollowingClicked(true);
                    setIsLoading(true);
                    setForYou(false);
                  }}
                >
                  {localisedTranslation('quickplay.label-following')}
                </button>
              </>
            )}
          </div>
        </div>

        {/* Create Content Button */}
        {isLoggedIn && !followingClicked && (
          <div className="main-create">
            <div className="main-create-menu">
              <div className="main-create-content">
                <button className="create-btn">
                  <CreateIcon />
                  <SuperPlus />
                  {localisedTranslation('quickplay.label-create')}
                </button>

                <p className="create-text">{localisedTranslation('quickplay.label-share-story')}</p>
              </div>
            </div>
            <div className="main-create-menu !border-0"></div>
          </div>
        )}
        {/* Vertical Feed Player */}
        <div className="row-responsive">
          {!isLoading && videos.length > 0 && (
            <div
              className="mid-content"
              style={
                !isMobile && themeConfig && backgroundImage
                  ? {
                      backgroundImage: `url(${backgroundImage})`,
                      backgroundRepeat: 'no-repeat',
                      backgroundPosition: 'center',
                      backgroundSize: '100% auto',
                    }
                  : {}
              }
            >
              <button onClick={handleNext} className="next-btn" disabled={!(currentVideoIndex !== videos.length - 1)}>
                <DownArrowGray />
                <span>{localisedTranslation('quickplay.label-next-video')}</span>
              </button>
              <button onClick={handlePrev} className="prev-btn" disabled={!(currentVideoIndex > 0)}>
                <UpArrowGray />
                <span>{localisedTranslation('quickplay.label-previous-video')}</span>
              </button>
              <div ref={verticalPlayerRef} id="vertical-player" className={`player_wrapper ${followingClicked ? 'player_viewport' : ''}`}>
                {videos.map((video: any, index: number) => (
                  <VideoItem
                    ref={videoRefs.current[index]}
                    video={video}
                    key={video.id}
                    setVideos={setVideos}
                    setCurrentVideoIndex={setCurrentVideoIndex}
                    allVideoRefs={videoRefs}
                    verticalPlayerRef={verticalPlayerRef}
                    followingClicked={followingClicked}
                  />
                ))}
              </div>
            </div>
          )}
          {!isLoading && videos?.length === 0 && <EmptyFeed followingClicked={followingClicked} />}
          <Bottom />
        </div>
      </div>
      <LoginModal show={showLoginModal} handleClose={() => setShowLoginModal(false)} />
    </>
  );
};

export default VerticalPlayer;
