import { BrazeCustomEvents, IconNames, MixPanelCustomEvents } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState, clearCart, clearInvoice, setIsUserSettingsOpen } from '@fe-monorepo/store';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { useLocation } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import useDrawerToggle from '../../../hooks/useDrawerToggle';
import usePageLayout from '../../../hooks/usePageLayout';
import Button from '../../Atoms/Buttons/Button';
import ResponsiveIcon from '../../Atoms/Icon/ResponsiveIcon';
import PopoverMenu from '../../Molecules/Menu/PopoverMenu';
import './Header.scss';
import ThemeToggle from './ThemeToggle';

const MenuLabel = (props: { text: string }) => (
  <p className="font-regular text-body 4xl:text-subtitle 5xl:text-bigTitle 8xl:text-LPTitle">{props.text}</p>
);

interface GuestProfileProps {
  btnClass?: string;
  handleLanguageToggle?: (language: 'en' | 'ar') => void;
}
const GuestProfile: React.FC<GuestProfileProps> = ({ btnClass, handleLanguageToggle = () => {} }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { isOpen: open } = useDrawerToggle();
  const dispatch = useDispatch();
  const { language } = usePageLayout();
  const { translate } = useTranslate();
  const { pathname } = useLocation();
  const globalConfig = useSelector((state: RootState) => state.app.globalConfig);

  return (
    <div className="cmp-guest-profile flex gap-20 4xl:gap-36 5xl:gap-54 8xl:gap-100 items-center h-full relative">
      {!open && (
        <div className="flex items-center gap-20 4xl:gap-36 5xl:gap-54 8xl:gap-100 ">
          <div className="hidden md:block">
            <PopoverMenu
              text={
                <ResponsiveIcon
                  className="mt-4 4xl:mt-8 5xl:mt-[10.66px] 8xl:mt-22
                    fill-secondary/50
                    hover:fill-secondary/100
                    animate__animated animate__fadeIn"
                  name={IconNames.moreHorizon}
                  baseWidth={20}
                  baseHeight={20}
                />
              }
              cssClassNames="w-200 4xl:w-[355.55px] 5xl:w-[533px] 8xl:w-1050"
              options={[
                {
                  id: 'language',
                  name: <MenuLabel text={translate('menu_changeLanguage') ?? ''} />,
                  icon: (
                    <ResponsiveIcon
                      className="fill-secondary animate__animated animate__fadeIn"
                      name={IconNames.language}
                      baseWidth={24}
                      baseHeight={24}
                    />
                  ),
                  rightIcon: (
                    <ResponsiveIcon
                      className="fill-secondary ltr:rotate-[270deg] rtl:rotate-90 animate__animated animate__fadeIn"
                      name={IconNames.chevron}
                      baseWidth={20}
                      baseHeight={20}
                    />
                  ),
                  children: [
                    {
                      name: (
                        <span
                          className={`
                            text-body 4xl:text-subtitle 5xl:text-bigTitle 8xl:text-LPTitle
                            font-regular text-secondary
                            w-full
                            ${language === 'en' ? 'text-sunset' : ''}`}
                        >
                          {translate('lang_option_en')}
                        </span>
                      ),
                      icon:
                        language === 'en' ? (
                          <ResponsiveIcon
                            className="fill-sunset animate__animated animate__fadeIn"
                            name={IconNames.checkFill}
                            baseWidth={20}
                            baseHeight={20}
                          />
                        ) : (
                          ''
                        ),
                      onClick: () => handleLanguageToggle('en'),
                    },
                    {
                      name: (
                        <span
                          className={`
                            text-body 4xl:text-subtitle 5xl:text-bigTitle 8xl:text-LPTitle
                            font-regular text-secondary
                            w-full
                            ${language === 'ar' ? 'text-sunset' : ''}`}
                        >
                          {translate('lang_option_ar')}
                        </span>
                      ),
                      icon:
                        language === 'ar' ? (
                          <ResponsiveIcon
                            className="fill-sunset animate__animated animate__fadeIn"
                            name={IconNames.checkFill}
                            baseWidth={20}
                            baseHeight={20}
                          />
                        ) : (
                          ''
                        ),
                      onClick: () => handleLanguageToggle('ar'),
                    },
                  ],
                },
                {
                  id: 'theme',
                  name: <ThemeToggle text={t('dark')} />,
                },
                {
                  id: 'settings',
                  name: (
                    <div onClick={() => dispatch(setIsUserSettingsOpen(true))}>
                      <MenuLabel text={translate('menu_settings') ?? ''} />
                    </div>
                  ),
                  icon: (
                    <ResponsiveIcon
                      className="fill-secondary animate__animated animate__fadeIn"
                      name={IconNames.gearIcon}
                      baseWidth={24}
                      baseHeight={24}
                    />
                  ),
                  onClick: () => {
                    navigate(AppRoutes.settings);
                  },
                },
                {
                  id: 'help',
                  name: (
                    <div>
                      <MenuLabel text={translate('profile.help') ?? ''} />
                    </div>
                  ),
                  icon: (
                    <ResponsiveIcon
                      className="fill-secondary animate__animated animate__fadeIn"
                      name={IconNames.support}
                      baseWidth={24}
                      baseHeight={24}
                    />
                  ),
                  onClick: () => {
                    navigate(AppRoutes.help);
                  },
                },
              ].filter(
                menuItem =>
                  !(menuItem.id === 'settings' && globalConfig.settingsHidden) && !(menuItem.id === 'help' && globalConfig.helpHidden),
              )}
              hasDivider={true}
              dividerIndex={[2]}
            />
          </div>
        </div>
      )}
      <div className="hidden md:inline-block">
        <Button
          text={translate('action_signin')}
          style="
            w-full
            px-[1.5rem]
            py-[0.5rem]
            border-[0.063rem]
            border-sunset
            rounded-[0.175rem] 
            text-body 4xl:text-subtitle 5xl:text-bigTitle 8xl:text-LPTitle
            font-medium
            text-white100
            whitespace-nowrap"
          normalStyle={btnClass}
          onClick={() => {
            const eventPayload = {
              'Button Name': 'Sign in',
              Referrer: window.location.href,
            };
            mixpanelHelper.trackEvent(MixPanelCustomEvents.LoginStarted, eventPayload);
            brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.LoginStarted, eventPayload);
            dispatch(clearCart());
            dispatch(clearInvoice());
            navigate(`${AppRoutes.authSignIn}${pathname ? `?redirect_url=${pathname}` : ''}`);
            /**
             * changed this for guest user reminder task - hard refresh causes issues
             * Just keep the below comment for next 2 release
             *  27 Nov 2024
             */
            // window.location.href = `${AppRoutes.authSignIn}${pathname ? `?redirect_url=${pathname}` : ''}`;
          }}
        />
      </div>
    </div>
  );
};

export default GuestProfile;
