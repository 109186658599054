import { useLazyQuery, useMutation } from '@apollo/client';
import { NETWORK_MUTATION, NETWORK_QUERY } from '@fe-monorepo/data-access';
import { CrmNetworkGetAllInput, CrmNetworkGetAllResponse, CrmNetworkInput, CrmNetworkUpdateResponse } from '@fe-monorepo/models';

export const useNetwork = () => {
  const [crmNetworkGetAllGql, { data: getAllData }] = useLazyQuery<CrmNetworkGetAllResponse>(NETWORK_QUERY.crmNetworkGetAll, {
    errorPolicy: 'all',
  });

  const [crmNetworkUpdateGql, { data: updateData }] = useMutation<CrmNetworkUpdateResponse>(NETWORK_MUTATION.crmNetworkUpdate, {
    errorPolicy: 'all',
  });

  const [crmNetworkDeleteGql, { data: deleteData }] = useMutation<CrmNetworkUpdateResponse>(NETWORK_MUTATION.crmNetworkDelete, {
    errorPolicy: 'all',
  });

  const crmNetworkGetAll = async (params: CrmNetworkGetAllInput) => {
    const { data } = await crmNetworkGetAllGql({
      variables: {
        details: params,
      },
    });
    return data;
  };

  const crmNetworkUpdate = async (params: CrmNetworkInput) => {
    const { data } = await crmNetworkUpdateGql({
      variables: {
        details: params,
      },
    });
    return data;
  };

  const crmNetworkDelete = async (params: CrmNetworkInput) => {
    const { data } = await crmNetworkDeleteGql({
      variables: {
        details: params,
      },
    });
    return data;
  };

  return {
    crmNetworkGetAll,
    crmNetworkUpdate,
    crmNetworkDelete,
    updateData: updateData,
    deleteData: deleteData,
    getAllData: getAllData,
  };
};
