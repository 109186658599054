import React, { ReactNode } from 'react';
import { CSSProperties } from 'styled-components';
import { twMerge } from 'tailwind-merge';

interface ContainerProps {
  children?: ReactNode;
  className?: string;
  style?: CSSProperties;
}

const Container: React.FC<ContainerProps> = ({ children, className, style }) => {
  const cssClassName = twMerge('w-full h-full px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655', className);
  return (
    <div className={cssClassName} style={style}>
      {children}
    </div>
  );
};

export default Container;
