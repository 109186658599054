import { useMutation } from '@apollo/client';
import { PARTICIPANT_MUTATION } from '@fe-monorepo/data-access';
import { LeaveTournamentResponse, TournamentIdParams } from '@fe-monorepo/models';

export const useLeaveParticipant = () => {
  const [leaveParticipantGql, { data: leaveData }] = useMutation<LeaveTournamentResponse>(PARTICIPANT_MUTATION.leaveParticipant, {
    errorPolicy: 'all',
  });

  const leaveParticipant = async (leaveInput: TournamentIdParams) => {
    const { data } = await leaveParticipantGql({
      variables: {
        details: leaveInput,
      },
    });
    return data?.tmsParticipantLeave;
  };

  return {
    leave: leaveData?.tmsParticipantLeave,
    leaveParticipant,
  };
};
