import { gql } from '@apollo/client';

export const ORDER_QUERY = {
  getAllOrders: gql`
    query fetAllOrders {
      getAllOrders {
        is_successful
        error_code
        error_msg
        data {
          count
          orders {
            invoice_number
            status_code
            amount_grand_total
            updated_at
            last_cursor
            product
          }
        }
      }
    }
  `,

  getAllFilteredOrderData: gql`
    query fetchAllFilteredOrderData($details: OrderFilteredInput) {
      getFilteredOrders(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          count
          orders {
            invoice_number
            status_code
            amount_grand_total
            updated_at
            last_cursor
            product
          }
        }
      }
    }
  `,
};
