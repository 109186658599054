import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { useFetchQuery } from '../useFetchQuery';
import { USER_QUERY, ACCOUNT_MUTATION } from '@fe-monorepo/data-access';
import { QuestionnaireModel, PersonalizationQuestionnaireModel } from '@fe-monorepo/models';
import { useAppState } from '../index';

type submitQuestionnaireObjects = {
  is_successful: boolean;
  error_code: string;
  error_msg: string;
};

type QuestionnaireResponseData = { submitPersonalizationQuestionnaire: submitQuestionnaireObjects };

type QuestionnaireParams = {
  details: QuestionnaireModel;
};

export const usePersonalizeQuestionnaire = () => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { changeLoadingState } = useAppState();
  const [questions, setQuestions] = useState<PersonalizationQuestionnaireModel[]>([]);

  const getPersonalizationQuestionnaireResponse = useFetchQuery<PersonalizationQuestionnaireModel[]>(USER_QUERY.getPersonalizationQuestionnaire, []);
  const [submitQuestionnaire, { data: questionnaireData, error: questionnaireError }] = useMutation<
    QuestionnaireResponseData,
    QuestionnaireParams
  >(ACCOUNT_MUTATION.submitPersonalizationQuestionnaire, {
    onError: e => {
      changeLoadingState(false);
    }
  });

  const submit = async (model: QuestionnaireModel) => {
    // changeLoadingState(true);
    changeLoadingState(false);
    submitQuestionnaire({
      variables: { details: model }
    });
  };

  const getQuestionnaire = () => {
    // setIsLoading(true);
    getPersonalizationQuestionnaireResponse.fetch();
  };

  useEffect(() => {
    if (questionnaireData) {
      const data = questionnaireData;
      setErrorMessage(data.submitPersonalizationQuestionnaire.error_msg);
      changeLoadingState(false);
    }
  }, [questionnaireData]);

  useEffect(() => {
    if (questionnaireError) 
    {
      changeLoadingState(false);
    }
  }, [questionnaireError]);

  useEffect(() => {
    if (getPersonalizationQuestionnaireResponse.data) {
      const response = getPersonalizationQuestionnaireResponse.data;
      if (response.length > 0) {
        setQuestions(response);
      }
    }
    // setIsLoading(false);
  }, [getPersonalizationQuestionnaireResponse.data]);

  const getPersonalizationQuestionnaire = async () => {
    // setIsLoading(true);
    return getPersonalizationQuestionnaireResponse.fetch();
  };

  return { submit, getPersonalizationQuestionnaire, getQuestionnaire, questions, questionnaireData, errorMessage };
};
