import useGetCurrentBreakPoint from '@fe-web/hooks/useGetCurrentBreakPoint/useGetCurrentBreakPoint';
import { Banner } from '@fe-web/types/bannerTypes';
import { LazyLoadImage } from 'react-lazy-load-image-component';

interface PreviewSliderItemProps {
  item: Banner;
  isMiddle: boolean;
  isFromBraze: boolean;
  absoluteDistanceFromMiddle: number;
  language: 'en' | 'ar';
  handleBannerClick: (item: Banner) => void;
}

const PreviewSliderItem = (props: PreviewSliderItemProps) => {
  const { item, isMiddle, absoluteDistanceFromMiddle, language, isFromBraze, handleBannerClick } = props;

  const { currentBreakPoint } = useGetCurrentBreakPoint();

  const isMobile = currentBreakPoint === 'mobile';

  const isClickable: boolean = isFromBraze
    ? (isMiddle && Boolean(item.url)) || (isMiddle && item.reference_type !== 'none' && item.reference_type != null)
    : item.reference_type !== 'none' && item.reference_type != null;

  const cursorStyle = isClickable ? 'cursor-pointer' : 'cursor-default';

  const handleClick = () => {
    if (isClickable) {
      handleBannerClick(item);
    }
  };

  const imageUrl = isMobile
    ? language === 'ar'
      ? item.mobile_media_url_ar
      : item.mobile_media_url_en
    : language === 'ar'
    ? item.web_media_url_ar
    : item.web_media_url_en;

  const cardWidth = isMobile ? (isMiddle ? '100%' : '0%') : isMiddle ? '76%' : absoluteDistanceFromMiddle === 1 ? '6.66%' : '1%';

  return (
    <li
      className={`relative flex flex-col gap-16 snap-center ${cursorStyle} ${!isMiddle ? 'hidden sm:flex' : 'flex'}`}
      style={{ width: cardWidth }}
      onClick={handleClick}
    >
      <div className="relative w-full h-full">
        <LazyLoadImage
          className={`h-full w-full rounded-lg aspect-square md:aspect-2.75/1 ${
            isMiddle ? 'object-contain' : 'object-cover'
          } object-center ${!isMiddle ? 'grayscale' : ''}`}
          src={imageUrl}
          alt={item?.[`name_${language}`]?.replace(/\s/g, '_') ?? ''}
        />
        <div className={`absolute bottom-0 h-full w-full rounded-lg ${!isMiddle && 'bg-black/80'}`} />
      </div>
    </li>
  );
};

export default PreviewSliderItem;
