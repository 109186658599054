import React, { useEffect, useState } from 'react';

import './CustomModal.css';

interface ModalProps {
  isOpen?: boolean;
  close?: () => void; // Specify the type of 'close' prop as a function that returns void
  children?: React.ReactNode; // Correct type for children
  classes?: any;
}

const CustomModal: React.FC<ModalProps> = ({ isOpen, close, children, classes }) => {
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(() => {
        setModalOpen(true);
      }, 100);
      return () => clearTimeout(timer);
    } else {
      setModalOpen(false);
    }
  }, [isOpen]);

  useEffect(() => {
    const handleEscape = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        if (modalOpen) {
          close && close();
        }
      }
    };
    document.addEventListener('keydown', handleEscape);
    return () => document.removeEventListener('keydown', handleEscape);
  }, [close, modalOpen]);

  if (!isOpen || !close) return null;

  return (
    <div className="modal-overlay higher-z">
      <div className={`modal ${isOpen && 'open'} ${modalOpen && 'slidedown'} ${classes}`}>
        <div className="qp-modal-content">
          <div className="modal-header">
            <button type="button" onClick={close} className="close-button">
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path
                  d="M15.8332 5.3415L14.6582 4.1665L9.99984 8.82484L5.3415 4.1665L4.1665 5.3415L8.82484 9.99984L4.1665 14.6582L5.3415 15.8332L9.99984 11.1748L14.6582 15.8332L15.8332 14.6582L11.1748 9.99984L15.8332 5.3415Z"
                  fill="var(--secondary-black)"
                />
              </svg>
            </button>
          </div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default CustomModal;
