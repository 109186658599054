import { gql } from '@apollo/client';

export const NETWORK_MUTATION = {
  crmNetworkUpdate: gql`
    mutation crmNetworkUpdate($details: CrmNetworkUpdateInput) {
      crmNetworkUpdate(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  crmNetworkDelete: gql`
    mutation crmNetworkDelete($details: CrmNetworkDeleteInput) {
      crmNetworkDelete(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
};
