import { gql } from '@apollo/client';

export const ADDRESS_MUTATION = {
  updateDigitalFulfillmentMethod: gql`
    mutation updateDigitalFulfillmentMethod($details: DigitalFulFillmentInput) {
      updateDigitalFulfillmentMethod(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  updateBillingAddress: gql`
    mutation updateBillingAddress($details: BillingAddressInput) {
      updateBillingAddress(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  updateShippingAddress: gql`
    mutation updateShippingAddress($details: ShippingAddressInput) {
      updateShippingAddress(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
};
