import { IMAGES } from '@fe-monorepo/assets';
import { useSearchHistory } from '@fe-monorepo/hooks';
import EmptyState from '@fe-web/Atoms/Empty/EmptyState';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import React, { useEffect } from 'react';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../../app.routes.enum';
import useSearchInput from '../../../../hooks/useSearchInput';
import Drawer from '../../../Atoms/Drawer';
import RecentSearchResult from './RecentSearchResult';

interface RecentSearchMobileProps {
  open: boolean;
  recentRef: any;
}
const RecentSearchMobile: React.FC<RecentSearchMobileProps> = ({ open, recentRef }) => {
  const { setValue } = useSearchInput();
  const { t } = useTranslation();
  const { searchHistoryData, clearAllHistory, getSearchHistory } = useSearchHistory();
  const navigate = useNavigate();

  useEffect(() => {
    getSearchHistory();
  }, []);

  useEffect(() => {
    if (open) {
      mixpanelHelper.trackPageView('GLOBAL SEARCH PAGE', 'global_search_page', window.location.pathname, document.referrer);
      brazeHelper.trackPageView('GLOBAL SEARCH PAGE', 'global_search_page', window.location.pathname, document.referrer);
    }
  }, [open]);

  return (
    <Drawer className="mt-[30px]" open={open}>
      <section className="h-[calc(100vh-35px)] overflow-y-auto px-[20px] pt-[5px] flex flex-col w-full gap-[12px]">
        {searchHistoryData?.length ? (
          <div ref={recentRef}>
            <div className="flex items-center justify-between w-full mb-10">
              <div className="text-lg font-medium text-secondary">{t('recent')}</div>
              <div className="text-sm font-normal cursor-pointer text-btn-primary mix-blend-normal" onClick={clearAllHistory}>
                {t('common_clearAll')}
              </div>
            </div>
            <RecentSearchResult
              setSearchInput={value => {
                setValue(value);
                navigate(AppRoutes.search + `?q=${value}`);
              }}
              getSearchHistory={getSearchHistory}
              searchHistoryData={searchHistoryData}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <EmptyState
              caption={t('empty_state.searchHistory') ?? ''}
              hideMessage
              isMobile={isMobile}
              img={IMAGES.NoRecentSearch.toString()}
              imgClass="w-[20.534rem] h-[14.462rem]"
              divClass="gap-[0rem]"
            />
          </div>
        )}
      </section>
    </Drawer>
  );
};
export default RecentSearchMobile;
