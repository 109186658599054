import { useTranslate } from '@fe-monorepo/hooks';
import { useEffect, useState } from 'react';

import Input from './Components/Input';
import Label from './Components/Label';
import Underline from './Components/Underline';

export type InputFieldStyle = {
  containerStyle?: string;
  labelStyle?: string;
  inputStyle?: string;
  underlineStyle?: string;
  errorStyle?: string;
};

interface FieldProps {
  style: InputFieldStyle;
  label: string;
  isSecret?: boolean;
  isNumber?: boolean;
  isDateTime?: boolean;
  isDate?: boolean;
  hideFooter?: boolean;
  hasFocused?: boolean;
  errorMessage?: string;
  existingValue?: string;
  maxLength?: number;
  showCounter?: boolean;
  formPlaceHolder?: string;
  minDate?: string;
  maxDate?: string;
  isAlwaysFocused?: boolean;
  min?: string | number;
  max?: string | number;
  labelColor?: string;
  noLabel?: boolean;
  useAltStyle?: boolean;
  disableDefaultInputWidth?: boolean;
  isDisabled?: boolean;
  inputID?: string;
  isUserAllowedToType: (value: string) => boolean;
  retrieveValue?: (value: any) => void;
  retrieveFocus?: (value: boolean) => void;
  retrieveTyped?: (value: boolean) => void;
  onClickEnter?: () => void;
}

const InputField = (props: FieldProps) => {
  const { translate } = useTranslate();
  const [value, setValue] = useState<string>(props.existingValue ?? '');
  const [isFocused, setFocused] = useState<boolean>(false);

  const { containerStyle, inputStyle, underlineStyle, errorStyle } = props.style;

  const changeValue = (value: string) => {
    if (!props.isUserAllowedToType(value)) {
      return;
    }

    if (props?.retrieveTyped) {
      props?.retrieveTyped(true);
    }

    setValue(value);
  };

  useEffect(() => {
    if (props.existingValue && props.retrieveFocus && props?.retrieveTyped) {
      changeValue(props.existingValue);
      props.retrieveFocus(true);
      props.retrieveTyped(true);
    }
  }, []);

  useEffect(() => {
    if (props?.retrieveValue) {
      props?.retrieveValue(value);
    }
  }, [value]);

  useEffect(() => {
    if (!props.hasFocused && props.retrieveFocus && !props?.hasFocused !== undefined) {
      props.retrieveFocus(isFocused);
    }
  }, [isFocused]);

  useEffect(() => {
    if (props?.existingValue) {
      setValue(props?.existingValue);
    } else setValue('');
  }, [props?.existingValue]);

  useEffect(() => {
    if (props?.isAlwaysFocused) {
      setFocused(true);
    }
  }, []);

  return (
    <div className={`relative ${containerStyle}`}>
      {(value || isFocused || props.useAltStyle) && <div className="w-full h-20 mb-4 2K:h-36 4K:h-54 8K:h-100 2K:mb-8 4K:mb-12 8K:mb-22" />}

      <div className="flex w-full">
        {!props.noLabel && <Label text={props.label} valueLength={value.length} isFocused={isFocused} labelColor={props.labelColor} />}
        <Input
          className={inputStyle}
          isNumber={props?.isNumber}
          isSecret={props?.isSecret}
          isDateTime={props?.isDateTime}
          isDate={props?.isDate}
          value={value}
          formPlaceHolder={props?.formPlaceHolder}
          minDate={props?.minDate}
          maxDate={props?.maxDate}
          min={props?.min}
          max={props?.max}
          setValue={changeValue}
          isFocused={isFocused}
          setFocused={setFocused}
          maxLength={props.maxLength}
          onClickEnter={props?.onClickEnter}
          disableDefaultInputWidth={props.disableDefaultInputWidth}
          isEditable={props?.isDisabled ?? false}
          id={props?.inputID}
        />
      </div>
      <Underline
        underlineStyle={underlineStyle}
        errorStyle={errorStyle}
        isFocused={isFocused}
        hideFooter={props.hideFooter}
        errorMessage={props?.errorMessage}
        showCounter={props.showCounter}
      />
      {props.showCounter && (
        <p className="absolute ltr:right-0 rtl:left-0 top-[4rem] flex justify-end text-fs-caption text-secondary/50 py-[0.375rem] font-regular">
          {value.length + '/' + props.maxLength + ' ' + translate('shop.cart.order_main.characters')}
        </p>
      )}
    </div>
  );
};

export default InputField;
