/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from '@apollo/client';
import { GEO_QUERY } from '@fe-monorepo/data-access';
import { CountryModel } from '@fe-monorepo/models';
import { useEffect, useState } from 'react';

import { useAppState } from '../index';
import { useFetchQuery } from '../useFetchQuery';

export const useGeo = () => {
  const [fetchCountries, { data: countryDataResponse, error: countryError }] = useLazyQuery(GEO_QUERY.getAllCountries, {
    errorPolicy: 'all',
  });

  const [countryData, setCountryData] = useState<[CountryModel]>();
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { changeLoadingState } = useAppState();

  const getAllCountries = async () => {
    fetchCountries();
  };

  useEffect(() => {
    if (countryDataResponse) {
      const dataResponse = countryDataResponse?.countries;
      setCountryData(dataResponse.data);
      setErrorMessage(dataResponse.error_msg);
    }
  }, [countryDataResponse]);

  useEffect(() => {
    if (countryError) {
      // TODO ADD CODE HERE
      changeLoadingState(false);
    }
  }, [countryError]);

  return { getAllCountries, countryData, errorMessage };
};

export const useGeoNew = () => {
  const countries = useFetchQuery<CountryModel[]>(GEO_QUERY.getAllCountries, []);

  return {
    countries,
  };
};
