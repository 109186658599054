import { gql } from '@apollo/client';

export const BANNER_QUERY = {
  getAllBanners: gql`
    query fetchAllBanner {
      getAllBanners {
        error_msg
        error_code
        is_successful
        data {
          name_en
          name_ar
          reference_type
          reference_id
          reference_code
          media_url
          media_type
          is_active
          is_deleted
          created_at
          updated_at
          sort_id
          banner_id
        }
      }
    }
  `,
};
