import { IconNames } from '@fe-monorepo/helper';
import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import Modal from '@fe-web/Templates/Modal/Modal';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import useOutsideClick from '@fe-web/hooks/useOutsideClick';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import useVerifyDialogToggle from 'libs/hooks/src/useVerifyDialogToggle';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Icon from '../../Atoms/Icon/Icon';

const VerifyModal = () => {
  const { user } = useUserProfile();
  const { isOpen, toggleVerifyDialog } = useVerifyDialogToggle();
  const { translate } = useTranslate();
  const { dir } = useHeaderState();
  const themes = useSelector((state: RootState) => state.app.themes);
  const navigate = useNavigate();
  const isMobile = useMobileDetect();

  const onClose = () => {
    toggleVerifyDialog(false);
  };
  const elementRef = useOutsideClick(() => {
    onClose();
  });

  const isMobileNotVerified = !user?.mobile || !user?.is_mobile_verified;
  const isEmailNotVerified = !user?.email || !user?.is_email_verified;

  const CTA_STYLE = `
    text-base not-italic max-h-[2.5rem] font-medium
    ${isMobile ? 'w-full px-[1rem] py-[0.5rem]' : 'w-fit px-[1.5rem] py-[0.5rem]'}  gap-[0.5rem] z-1
    text-white border-sunset border-[0.063rem] mb-[0px]
    xsMax:mb-[0px] items-end
    rounded-[0.188rem] rounded-[0.188rem]
  `;

  return (
    <Modal open={isOpen} backdropClassName="backdrop-blur">
      <div
        ref={ref => (elementRef.current = ref)}
        className="
          flex flex-col items-center
          w-[90%] h-[36.25rem] SD:w-[36.75rem] SD:h-[32.4375rem]  
          bg-[#4F008C] self-center px-[2rem] py-[1.5rem]"
      >
        <div>
          <Icon name={IconNames.phoneEmpty} width={115} height={160} />
        </div>
        <div className="flex flex-col w-full pt-[12px] pb-[2rem] gap-[1.5rem] text-center">
          <h1 className="font-medium text-white text-fs-subtitle SD:text-fs-big-title">
            {translate('verify_user_motivation.verify_modal.title')}
          </h1>
          <p className="text-white font-regular text-fs-body-large self-center SD:w-[25.9375rem]">
            {translate('verify_user_motivation.verify_modal.description')}
          </p>
        </div>
        <div className="flex flex-col-reverse w-full md:flex-row justify-center gap-[1.25rem] pt-[1rem] pb-[2.5rem]">
          <Button
            text={translate('verify_user_motivation.verify_modal.not_now')}
            style={`text-base not-italic max-h-[2.5rem] border-[0.063rem] border-white font-medium
              ${isMobile ? 'w-full px-[1rem] py-[0.5rem]' : 'w-fit px-[1.5rem] py-[0.5rem]'}  gap-[0.5rem] z-1
              text-white mb-[0px]
              xsMax:mb-[0px] items-end
              rounded-[0.188rem] 
              rounded-[0.188rem]`}
            normalStyle={`
              ${dir === 'ltr' ? 'after:bg-[#4F008C] before:bg-sunset' : 'before:bg-[#4F008C] after:bg-sunset'}
              hover:border-sunset`}
            onClick={onClose}
          />
          {isMobileNotVerified && (
            <Button
              text={translate('verify_user_motivation.verify_modal.verify_phone_cta')}
              style={CTA_STYLE}
              disabledStyle={`${themes === 'dark' ? 'bg-[#2E2D30] border-[#2E2D30]' : 'bg-black10'} `}
              normalStyle={`
                ${dir === 'ltr' ? 'after:bg-sunset before:bg-[#4F008C]' : 'before:bg-sunset after:bg-[#4F008C]'}
                hover:border-white`}
              onClick={() => {
                navigate(`${AppRoutes.settingsRoot}/phone-number`);
                onClose();
              }}
            />
          )}
          {isEmailNotVerified && !isMobileNotVerified && (
            <Button
              text={translate('verify_user_motivation.verify_modal.verify_email_cta')}
              style={CTA_STYLE}
              disabledStyle={`${themes === 'dark' ? 'bg-[#2E2D30] border-[#2E2D30]' : 'bg-black10'} `}
              normalStyle={`
                ${dir === 'ltr' ? 'after:bg-sunset before:bg-[#4F008C]' : 'before:bg-sunset after:bg-[#4F008C]'}
                hover:border-white`}
              onClick={() => {
                navigate(`${AppRoutes.settingsRoot}/email`);
                onClose();
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default VerifyModal;
