import { useLocation } from 'react-router-dom';

const EmptyPage = () => {
  const location = useLocation();
  const pathname = location.pathname.replace('/', '');

  return (
    <div className="flex items-center self-center justify-center w-full h-full bg-primary">
      <p className="text-secondary text-bigTitle 4xl:text-huge 5xl:text-eightKSubtitle 8xl:text-fiveKDynamic">
        {pathname
          .substring(pathname.lastIndexOf('/') + 1)
          .charAt(0)
          .toUpperCase() + pathname.substring(pathname.lastIndexOf('/') + 2)}
      </p>
    </div>
  );
};

export default EmptyPage;
