import { IconNames } from '@fe-monorepo/helper';
import { DeliveryAddressModel } from '@fe-monorepo/models';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';

import ActionButton from './ActionButton';

type AddressOptionProps = {
  address: DeliveryAddressModel;
  isActive?: boolean;
  label?: string;
  editLabel?: string;
  deleteLabel?: string;
  className?: string;
  onClickEdit?: () => void;
  onClickDelete?: () => void;
  selectAddress?: () => void;
};

const AddressOption = ({
  address,
  isActive,
  label,
  onClickEdit,
  onClickDelete,
  selectAddress,
  editLabel,
  deleteLabel,
  className,
}: AddressOptionProps) => {
  const isMobile = useMobileDetect();
  return (
    <div
      className={`${className ?? ''} p-[1.25rem] rounded-[0.25rem] border-[0.063rem] ${
        isActive ? 'border-sunset' : 'border-secondary/[0.2]'
      }`}
    >
      <div className="flex gap-x-[0.5rem] items-center">
        {isActive && <ResponsiveIcon name={IconNames.verified2} baseWidth={20} baseHeight={20} />}
        <p className="font-medium cursor-pointer text-fs-body-large text-secondary" onClick={selectAddress}>
          {label}
        </p>
        {!isMobile && (
          <div className=" ml-auto gap-x-[1.25rem] flex">
            <ActionButton icon={IconNames.editOutline2} onClick={onClickEdit} />
            <ActionButton icon={IconNames.deleteOutline} onClick={onClickDelete} />
          </div>
        )}
      </div>
      <div className="flex gap-x-[0.75rem] mt-[1rem]">
        <p className="text-fs-caption font-regular text-secondary">{address.name}</p>
        <span className="text-fs-caption font-regular text-secondary">•</span>
        <p className="text-fs-caption font-regular text-secondary">{address.mobile}</p>
        <span className="text-fs-caption font-regular text-secondary">•</span>
        <p className="text-fs-caption font-regular text-secondary">
          {address.location_long} {address.location_lat}
        </p>
      </div>
      <p className="text-fs-caption font-regular text-secondary mt-[0.5rem]">{address.address}</p>
      {isMobile && (
        <div className="flex gap-x-[1.25rem] mt-[1.125rem] ">
          <ActionButton text={editLabel} icon={IconNames.editOutline2} onClick={onClickEdit} />
          <ActionButton text={deleteLabel} icon={IconNames.deleteOutline} onClick={onClickDelete} />
        </div>
      )}
    </div>
  );
};

export default AddressOption;
