import { logCustomEvent } from '@braze/web-sdk';
import { IMAGES } from '@fe-monorepo/assets';
import {
  APPLE_PAY_CONFIG,
  BrazeCustomEvents,
  IconNames,
  Logger,
  MixPanelEvents,
  PAGE_DESCRIPTION,
  PAGE_KEYWORDS,
  PAGE_TITLE,
  PROMO_ELIGIBLE_BRANDS,
  TxKeyPath,
  convertPriceWithSymbol,
  formatNumberWithCommasAndDecimal,
  getOrderSummaryTotalAndDiscount,
  revertPrice,
} from '@fe-monorepo/helper';
import {
  PROMO_CODE_ITEM,
  useAddress,
  useAppState,
  useApplePaySession,
  useCart,
  useCheckProductAvailability,
  useDeliveryAddress,
  useInvoice,
  useOrder,
  usePayment,
  useProduct,
  usePromoCode,
  useToastMessage,
  useTranslate,
  useUserProfile,
  useWallet,
} from '@fe-monorepo/hooks';
import {
  AddressInput,
  BillingAddressInput,
  DeliveryAddressInput,
  DeliveryAddressModel,
  DigitalFulfillmentInput,
  InvoiceModel,
  InvoiceProduct,
  LMDModel,
  PAYMENT_METHODS,
  PaymentInput,
  ShippingAddressInput,
} from '@fe-monorepo/models';
import {
  RootState,
  clearCart,
  clearInvoice as clearInvoiceStore,
  setInvoice,
  setPersona,
  updateCartItemStock,
  useAppDispatch,
} from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import EmptyCart from '@fe-web/Atoms/Empty/EmptyCart';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import ToastMessage from '@fe-web/Atoms/ToastMessage';
import GeneralError from '@fe-web/Molecules/ErrorScreens/GeneralError';
import LoadingIndicator from '@fe-web/Molecules/LoadingIndicator/LoadingIndicator';
import OrderSummary from '@fe-web/Molecules/OrderSummary';
import TrackingBar from '@fe-web/Molecules/TrackingBar';
import { StepModel } from '@fe-web/Molecules/TrackingBar/types';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import DiscountCode from '@fe-web/Organisms/DiscountCode/DiscountCode';
import TailoredSection from '@fe-web/Organisms/Tailored/Tailored';
import Modal from '@fe-web/Templates/Modal/Modal';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import parse from 'html-react-parser';
import { t } from 'i18next';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  trackAddPaymentInfoEvent,
  trackAddShippingInfoEvent,
  trackBeginCheckoutEvent,
  trackCartEvent,
  trackFailedPurchase,
  trackSuccessfulPurchase,
} from '../../../../../helper/webAnalytics';
import { AppRoutes } from '../../../app.routes.enum';
import CountryDropDown from '../ShopCountryDropDown';
import { StyledContainer, StyledHeaderContainer } from '../styles/shop.styled';
import { StyledMainSection, StyledSideSection } from './CartPage.styled';
import CartSummary from './components/CartSummary/CartSummary';
import ContinueAsGuest from './components/ContinueAsGuest';
import Cart from './components/MainSections/Cart';
import Checkout from './components/MainSections/Checkout';
import Confirmation from './components/MainSections/Confirmation';
import CartPageProductAvailabilityModal from './components/Modals/CartPageProductAvailability';
import STCPayVerificationScreen from './components/Payments/STCPayOTP';
import StcWallet from './components/StcWallet';

type TotalValue = {
  value: number;
  formattedValue: string;
};

const DEFAULT_CITY = 'Riyadh';

const CartPage = () => {
  const { translate } = useTranslate();
  const location = useLocation();

  const [currentStep, setCurrentStep] = useState(0);
  const token = useSelector((state: RootState) => state.user.userContext.token);
  const User = useSelector((state: RootState) => state.user.userContext);
  const [selectedAddress, setSelectedAddress] = useState<DeliveryAddressModel | undefined>();
  const isMobile = useMobileDetect();
  const isHeaderDark = useSelector((state: RootState) => state.app.headerColorChange);
  const { dir } = useHeaderState();
  const { language } = usePageLayout();
  const {
    increase,
    decrease,
    clearItems,
    stcWalletToUse,
    setStcWalletToUse,
    removeStcWalletToUse,
    deliveryFee,
    totalDiscountsFormat,
    maxDiscounts,
    selectCartProducts,
    remove,
    totalProductCount,
    itemsInCart,
    unavailableProductCodes,
    productError,
    setProductError,
  } = useCart();
  const { paymentMethod, status, invoice } = useParams<{ paymentMethod: string; status: string; invoice: string }>();
  const { getInvoice, invoiceInfo, isLoading, createInvoice, clearInvoice } = useInvoice(true);
  const { userWallet, getUserWallet, applyWalletBalance } = useWallet();
  const { updateBillingAddress, updateShippingAddress, updateDigitalFulfillment, checkStoreDistance } = useAddress();
  const { updatePaymentMethod, createPayment, verifyOtp } = usePayment();
  const { createDigital, createPhysical, cancelOrder, checkout } = useOrder();
  const { btnClass } = usePageLayout();
  const [isCheckAllSelected, setIsCheckAllSelected] = useState<boolean>(false);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  const [openModal, setOpenModal] = useState(false);
  const [selectedProductsArr, setSelectedProductsArr] = useState<InvoiceProduct[]>([]);
  const navigate = useNavigate();
  const lmd_code = useSelector((state: RootState) => state.app.lmdCode);
  const country = useSelector((state: RootState) => state.country.lmds);
  const walletBalance = useMemo(() => parseFloat(userWallet.total_balance) || 0, [userWallet]);
  const walletToUse = useMemo(
    () =>
      invoiceInfo?.wallet_discount
        ? invoiceInfo.wallet_discount < 0
          ? invoiceInfo?.wallet_discount * -1
          : invoiceInfo.wallet_discount
        : stcWalletToUse
        ? stcWalletToUse
        : 0,
    [invoiceInfo?.wallet_discount, stcWalletToUse],
  );

  const steps: StepModel[] = [
    {
      label: 'shop.cart.order_status_cart',
      labelArgs: ['' + totalProductCount],
      icon: IconNames.shoppingCart1,
      onClick: () => {
        if (!status && status !== 'successful') {
          setCurrentStep(() => 0);
        }
      },
    },
    {
      label: 'shop.cart.order_status_delivery_payment',
      icon: IconNames.deliveryTruck,
      onClick: () => {
        if (!status && status !== 'successful') {
          setCurrentStep(() => 1);
        }
      },
    },
    { label: 'shop.cart.order_status_done', icon: IconNames.taskSuccess },
  ];

  const { user, isLoggedIn } = useUserProfile();
  const currentuser = user?.username;
  const [subtotalPrice, setSubtotalPrice] = useState<TotalValue>({
    value: 0,
    formattedValue: translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(0)) ?? '0.00',
  });
  const [vatPrice, setVatPrice] = useState<TotalValue>({
    value: 0,
    formattedValue: translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(0)) ?? '0.00',
  });
  const [totalPrice, setTotalPrice] = useState<TotalValue>({
    value: 0,
    formattedValue: translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(0)) ?? '0.00',
  });
  const [deliveryCharge, setDeliveryCharge] = useState<TotalValue>({
    value: 0,
    formattedValue: translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(0)) ?? '0.00',
  });
  const [grandTotalPrice, setGrandTotalPrice] = useState<number>(0);

  const [isAddNewAddress, setIsAddNewAddress] = useState(false);
  const { deliveryAddress, addAddress, removeAddress, removeSavedAddress, updateAddress, getDeliveryAddress } = useDeliveryAddress();
  const [addressForUpdate, setAddressForUpdate] = useState<DeliveryAddressModel | undefined>();
  const invoiceNumber = useSelector((state: RootState) => state.cart.invoice?.invoice_number);
  const [cartItems, setItemsInCart] = useState<InvoiceProduct[]>([]);
  const [haserror, setHaserror] = useState(false);
  const [hasNoAvailableItem, setHasNoAvailableItem] = useState(false);
  const [isPageLeave, setIsPageLeave] = useState(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState(false);
  const [creditCardForm, setCreditCardForm] = useState('');
  const [paymentMobileNumber, setPaymentMobileNumber] = useState('');
  const [paymentMobileCodeISO, setPaymentMobileCodeISO] = useState('');
  const [paymentEmail, setPaymentEmail] = useState('');
  const [openVerifySTCPayModal, setOpenVerifySTCPayModal] = useState(false);
  const [stcpayOTP, setSTCPayOTP] = useState('');
  const [verifyOTPError, setVerifyOTPError] = useState('');
  const [fulfilmentCode, setFulfilmentCode] = useState('email');
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isOpenContinueGuest, setIsOpenContinueGuest] = useState(false);
  const [createInvoiceGuestFlag, setCreateInvoiceGuestFlag] = useState(false);
  const [showLoadingIndicator, setShowLoadingIndicator] = useState(false);
  const { addProduct, selectProducts } = useProduct();
  const [listOfAddedProducts, setListOfAddedProducts] = useState<InvoiceProduct[]>([]);
  const dispatch = useAppDispatch();
  const [successfulInvoice, setSuccessfulInvoice] = useState('');
  const [guestCreatedInvoice, setGuestCreatedInvoice] = useState(false);
  const [showUnavailableProducts, setShowUnavailableProducts] = useState<boolean>(true);
  const cartItemsRef = useRef<InvoiceProduct[]>([]);

  const [processWalletAndAppleOnly, setProcessWalletAndAppleOnly] = useState<boolean>(false);
  const [isApplePaySupported, setIsApplePaySupported] = useState<boolean>(false);
  const { startApplePaySession } = useApplePaySession();
  const [isProcessing, setIsProcesing] = useState<boolean>(false);
  const [isCartPageAvailableModalOpen, setCartPageAvailableProductModalOpen] = useState<boolean>(false);
  const [outOfStockAction, setOutOfStockAction] = useState<string>('');
  const [previousLMD, setPreviousLMD] = useState<LMDModel | undefined>();
  const { changeLMDCode } = useAppState();
  const { checkProductAvailability } = useCheckProductAvailability();
  const [selectedAddressError, setSelectedAddressError] = useState<string>('');
  const [pageHaveCheckedAvailability, setPageHaveCheckedAvailability] = useState<number>();
  const { errorToastMessage } = useToastMessage();
  const currency_code = useSelector((state: RootState) => state.app.currency);
  const [activeItems, setActiveItems] = useState<InvoiceProduct[]>([]);
  const { log, error } = Logger();

  const updateProductsWithPromo = (items: InvoiceProduct[], isClearWallet?: boolean) => {
    setItemsInCart(items);
    if (isClearWallet && walletToUse > 0) {
      handleRemoveSTCWallet();
    }
  };
  const { applyPromo, appliedPromo, clearPromo, promoResponse, applyPromoAfterCheckout } = usePromoCode(
    activeItems,
    updateProductsWithPromo,
  );

  const applyPromoAndSetCartItems = (items: InvoiceProduct[]) => {
    const promoItems: InvoiceProduct[] = items?.map(item => {
      if (PROMO_ELIGIBLE_BRANDS.includes(item?.brand?.collection_code?.toLowerCase()) && appliedPromo) {
        const discount = (item?.subtotal * appliedPromo?.percentage) / 100;
        return {
          ...item,
          promo_applied_price: item?.subtotal - discount,
        };
      }
      return item;
    });
    setItemsInCart(promoItems);
  };

  const applyPromoAndSetSelectedProductsArr = (items: InvoiceProduct[]) => {
    const promoItems: InvoiceProduct[] = items?.map(item => {
      if (PROMO_ELIGIBLE_BRANDS.includes(item?.brand?.collection_code?.toLowerCase()) && appliedPromo) {
        const discount = (item?.subtotal * appliedPromo?.percentage) / 100;
        return {
          ...item,
          promo_applied_price: item?.subtotal - discount,
        };
      }
      return item;
    });
    setSelectedProductsArr(promoItems);
  };

  const applyPromoAndReturnCartItem = (cartItem: InvoiceProduct, promoParam: PROMO_CODE_ITEM | null) => {
    const promoItem: InvoiceProduct = { ...cartItem };
    if (PROMO_ELIGIBLE_BRANDS.includes(promoItem?.brand?.collection_code?.toLowerCase()) && promoParam) {
      const discount = (promoItem?.subtotal * promoParam?.percentage) / 100;
      promoItem.promo_applied_price = promoItem?.subtotal - discount;
    }
    return promoItem;
  };

  const products = useMemo(() => {
    if ((isLoggedIn && invoiceInfo?.products) || (!isLoggedIn && token && invoiceInfo?.products)) {
      return appliedPromo
        ? invoiceInfo?.products?.map(product => applyPromoAndReturnCartItem(product, appliedPromo))
        : invoiceInfo?.products;
    } else if (!isLoggedIn && itemsInCart?.length > 0) {
      return appliedPromo ? itemsInCart?.map(prod => applyPromoAndReturnCartItem(prod, appliedPromo)) : itemsInCart;
    }
    return [];
  }, [invoiceInfo?.products, itemsInCart, isLoggedIn, appliedPromo]);

  const activeProducts = useMemo(() => {
    const productsActive = products?.filter(
      product => (product?.is_active && product.in_stock !== 0) || (showUnavailableProducts && product.in_stock === 0),
    );
    return productsActive;
  }, [products, showUnavailableProducts]);

  useEffect(() => {
    setActiveItems(activeProducts);
  }, [activeProducts]);

  const totalPromoAmount = useMemo(() => {
    return selectedProductsArr.reduce((acc, item) => {
      if (item.promo_applied_price) {
        return acc + (item.subtotal - item.promo_applied_price);
      }
      return acc;
    }, 0);
  }, [selectedProductsArr]);

  const unavailableProducts = useMemo(() => activeProducts?.filter(product => product?.in_stock === 0), [activeProducts]);

  const handleShowToastMessage = (message: string, type: 'error' | 'success') => {
    toastWrapper(type, message);
  };

  const selectAddress = (address: DeliveryAddressModel) => {
    updateInvoiceAddress(address);
  };
  const onClickNewAddress = () => {
    setIsAddNewAddress(true);
    setAddressForUpdate(undefined);
    setOpenModal(true);
  };

  const onClickDeleteAddress = (address: AddressInput) => {
    removeAddress(address)
      .then(response => {
        setOpenModal(false);
        setIsAddNewAddress(false);
        if (address.address_id) {
          removeSavedAddress(address.address_id);
        }
      })
      .catch(err => {
        error('ERROR In Saving!');
      });
  };

  const onClickEditAddress = (id: number) => {
    log(`edit ${id} clicked...`);
    setOpenModal(true);
    setIsAddNewAddress(false);

    const addressToUpdate = deliveryAddress?.filter(address => address?.user_address_id === id);
    setAddressForUpdate(addressToUpdate[0]);
    setIsAddNewAddress(false);
  };

  const handleTopButton = () => {
    if (isLoggedIn || (!isLoggedIn && token)) {
      if (currentStep === 0) {
        selectCartProducts(selectedProductsArr?.map(product => product.order_product_id) || []);
        setCurrentStep(1);
      } else if (currentStep === 1) {
        setCurrentStep(2);
      }
    } else {
      setIsOpenContinueGuest(true);
    }
  };

  const handleBottomButton = () => {
    navigate(AppRoutes.shop);
  };

  const handleCheckAll = () => {
    setIsCheckAllSelected(!isCheckAllSelected);
    if (isCheckAllSelected) {
      setSelectedProducts([]);
      setSelectedProductsArr([]);
    } else {
      const allProducts: string[] = cartItems.map(item => {
        return item.product_code + '-' + item.variant_id;
      });
      setSelectedProducts(allProducts);
      const allProductsArr: InvoiceProduct[] = cartItems.filter(item => item.in_stock > 0);
      applyPromoAndSetSelectedProductsArr(allProductsArr);
    }
  };

  const handleSelectProduct = (code: string, variant_id: number) => {
    let new_array: string[] = [];
    let product_arr: InvoiceProduct[] = [];
    const product_id = code + '-' + variant_id;
    if (!selectedProducts.includes(product_id)) {
      new_array = [...selectedProducts, product_id];
    } else {
      new_array = selectedProducts.filter(item => item !== product_id);
    }
    const product = selectedProductsArr.find(item => item.product_code === code && item.variant_id === variant_id);
    if (!product) {
      const prod = cartItems.find(item => item.product_code === code && item.variant_id === variant_id);
      if (prod) {
        product_arr = [...selectedProductsArr, prod];
      }
    } else {
      product_arr = selectedProductsArr.filter(
        item => item.product_code !== code || (item.product_code === code && item.variant_id !== variant_id),
      );
    }
    const available_products = cartItems.filter(item => item.in_stock > 0);
    if (new_array.length === available_products.length) {
      setIsCheckAllSelected(true);
    } else {
      setIsCheckAllSelected(false);
    }
    setSelectedProducts(() => new_array);
    applyPromoAndSetSelectedProductsArr(product_arr);
    const totalCartPrice = product_arr.reduce((acc, item) => {
      return acc + item.grand_total;
    }, 0);
    if (totalCartPrice < walletToUse) {
      removeStcWalletToUse();
    }
    if (!product_arr?.some(item => PROMO_ELIGIBLE_BRANDS.includes(item.brand.collection_code.toLowerCase()))) {
      clearPromo();
    }
  };

  const generatePaymentPayload = (method: string) => {
    const payload: PaymentInput = {
      invoice_number: invoiceInfo?.invoice_number || '',
    };
    switch (method) {
      case 'tabby':
        payload.email = paymentEmail;
        payload.mobile = paymentMobileCodeISO + paymentMobileNumber;
        break;
      case 'stcpay':
        payload.mobile = paymentMobileCodeISO + paymentMobileNumber;
        break;
    }
    return payload;
  };

  const processZeroPayment = async () => {
    const walletData = {
      invoice_number: invoiceInfo?.invoice_number ?? '',
      code: PAYMENT_METHODS.stcplay,
    };
    try {
      setIsProcesing(true);
      const updatePaymentResponse = await updatePaymentMethod(walletData);
      if (updatePaymentResponse?.is_successful) {
        const checkOutResponse = await checkout({ invoice_number: invoiceInfo?.invoice_number ?? '' });
        if (checkOutResponse?.is_successful) {
          if (appliedPromo || walletToUse > 0) {
            const promoPayload: any = {
              invoice_number: invoiceInfo?.invoice_number ?? '',
            };
            if (walletToUse > 0) {
              promoPayload.wallet_number = userWallet.wallet_number;
              promoPayload.amount = walletToUse?.toString() ?? null;
            }
            if (appliedPromo) {
              promoPayload.promo_code = appliedPromo?.code;
            }
            const applyResponse = await applyPromoAfterCheckout(promoPayload);
            if (!applyResponse) {
              setIsProcesing(false);
              return;
            }
            const createPaymentResponse = await createPayment({ invoice_number: invoiceInfo?.invoice_number ?? '' }, true);

            getInvoice({
              details: {
                invoice_number: invoiceNumber ?? '',
              },
            }).then(res => {
              if (res?.data?.info) {
                mixpanelHelper.trackEvent(MixPanelEvents.orderPlaced, {
                  'Product Name': res?.data.info?.products.map(product => product.product_name),
                  'Product Code': res?.data.info?.products.map(product => product.product_code),
                  'Promo Code': res?.data.info?.applied_promo_codes?.[0]?.code,
                  'Total Quantity': res?.data.info?.products.reduce((total, product) => total + product.qty, 0),
                  'Discount Amount': res?.data.info?.amount_discount,
                  'Cart Total': res?.data.info?.total_amount,
                  'Amount Paid': res?.data.info?.total_amount,
                  'Order Status': res?.data.info?.status_code,
                  'Payment Method': [PAYMENT_METHODS.stcplay],
                  'Payment Status': createPaymentResponse?.is_successful ? 'successful' : 'failed',
                });

                logCustomEvent(BrazeCustomEvents.OrderPlaced, {
                  'Product Name': res?.data.info?.products.map(product => product.product_name),
                  'Product Code': res?.data.info?.products.map(product => product.product_code),
                  'Promo Code': res?.data.info?.applied_promo_codes?.[0]?.code,
                  'Total Quantity': res?.data.info?.products.reduce((total, product) => total + product.qty, 0),
                  'Discount Amount': res?.data.info?.amount_discount,
                  'Cart Total': res?.data.info?.total_amount,
                  'Amount Paid': res?.data.info?.total_amount,
                  'Order Status': res?.data.info?.status_code,
                  'Payment Method': [PAYMENT_METHODS.stcplay],
                  'Payment Status': createPaymentResponse?.is_successful ? 'successful' : 'failed',
                });
              }
            });

            if (createPaymentResponse?.is_successful) {
              createShipments();
            } else {
              setIsProcesing(false);
            }
          }
        } else {
          setIsProcesing(false);
        }
      } else {
        setIsProcesing(false);
      }
    } catch (err) {
      setIsProcesing(false);
    }
  };

  const handlePayButton = () => {
    if (unavailableProducts.length > 0) {
      setOutOfStockAction('pay');
      setCartPageAvailableProductModalOpen(true);
      return;
    }

    if (parseFloat(totalPrice?.value?.toFixed(2)) <= 0) {
      processZeroPayment();
      return;
    }
    //This will call checkout API if the invoice is_checkout field is 0
    if (invoiceInfo?.invoice_number && invoiceInfo?.is_checkout !== 1 && invoiceInfo.payment_method_code !== 'apple_pay') {
      setIsProcesing(true);
      processCheckout();
      //This condition handle if there's an stc play amount applied on the invoice
    } else if (walletToUse > 0 && invoiceInfo?.payment_method_code !== 'apple_pay') {
      setIsProcesing(true);
      if (invoiceInfo && !isWalletApplied(invoiceInfo)) {
        log('applied');
        processApplySTCWallet();
      } else if (totalPrice.value > 0 && invoiceInfo?.payment_method_code) {
        processPaymentMethod(invoiceInfo?.payment_method_code);
      } else {
        log('not applied');
        processPaymentForWalletOnly();
      }
    } else if (invoiceInfo?.payment_method_code) {
      if (invoiceInfo.payment_method_code === 'apple_pay') {
        if (totalPrice.value === 0 && invoiceInfo) {
          setIsProcesing(true);
          if (invoiceInfo?.is_checkout !== 1) {
            processCheckout();
          } else if (!isWalletApplied(invoiceInfo)) {
            processApplySTCWallet();
          } else {
            processPaymentForWalletOnly();
          }
        } else {
          processApplePay();
        }
      } else {
        setIsProcesing(true);
        processPaymentMethod(invoiceInfo?.payment_method_code);
      }
    }
  };

  const processCheckout = async () => {
    if (invoiceInfo?.invoice_number) {
      checkout({
        invoice_number: invoiceInfo?.invoice_number,
      })
        .then(async response => {
          if (response?.is_successful) {
            const new_invoice = {
              ...invoiceInfo,
            };
            if (appliedPromo || walletToUse > 0) {
              const promoPayload: any = {
                invoice_number: invoiceInfo.invoice_number,
              };
              if (walletToUse > 0) {
                promoPayload.wallet_number = userWallet.wallet_number;
                promoPayload.amount = walletToUse?.toString() ?? null;
              }
              if (appliedPromo) {
                promoPayload.promo_code = appliedPromo?.code;
              }
              const applyResponse = await applyPromoAfterCheckout(promoPayload);
              handleShowToastMessage(applyResponse?.message ?? '', applyResponse.is_successful ? 'success' : 'error');
              if (!applyResponse.is_successful) {
                setIsProcesing(false);
                return;
              }
            }
            new_invoice.is_checkout = 1;
            dispatch(setInvoice(new_invoice));
            processPaymentMethod(invoiceInfo?.payment_method_code);
          } else {
            error('checkout not successful');
            setIsProcesing(false);
            setHaserror(true);
          }
        })
        .catch(err => {
          setIsProcesing(false);
          error('checkout error');
          setHaserror(true);
        });
    }
  };

  const isWalletApplied = (invoiceInfo: InvoiceModel) => {
    return (
      invoiceInfo?.wallet_discount &&
      invoiceInfo.wallet_discount * -1 > 0 &&
      walletToUse === (invoiceInfo.wallet_discount < 0 ? invoiceInfo.wallet_discount * -1 : invoiceInfo.wallet_discount)
    );
  };

  const processApplySTCWallet = (payment?: string, paymentData?: any, applePaySession?: any, ApplePaySession?: any) => {
    let amount = walletToUse;
    if (totalPrice.value === 0) {
      amount = walletToUse + (invoiceInfo?.amount_tax ?? 0);
    }
    applyWalletBalance({
      invoice_number: invoiceInfo?.invoice_number,
      wallet_number: userWallet.wallet_number,
      amount: amount,
    })
      .then(response => {
        log('apply wallet response >>>', { response: response, payment: payment });
        if (response?.is_successful) {
          if (invoiceInfo?.payment_method_code && totalPrice.value > 0 && payment !== 'apple_pay') {
            processPaymentMethod(invoiceInfo?.payment_method_code);
          } else if (payment === 'apple_pay') {
            log('apple pay after apply wallet API');
            processCreatePaymentForApplePay(paymentData, applePaySession, ApplePaySession);
          } else {
            processPaymentForWalletOnly();
          }
        } else {
          setIsProcesing(false);
          error('apply wallet not successful');
          if (payment === 'apple_pay' && applePaySession && ApplePaySession) {
            applePaySession.completePayment(ApplePaySession.STATUS_FAILURE);
          }
          setHaserror(true);
        }
      })
      .catch(err => {
        setIsProcesing(false);
        error('err>>>', err);
        error('apply wallet error');
        if (payment === 'apple_pay' && applePaySession && ApplePaySession) {
          applePaySession.completePayment(ApplePaySession.STATUS_FAILURE);
        }
        setHaserror(true);
      });
  };

  const processCancelOrder = () => {
    if (invoiceInfo?.invoice_number && invoiceInfo.is_checkout === 1 && invoiceInfo.status_code === 'pending') {
      cancelOrder({ invoice_number: invoiceInfo?.invoice_number })
        .then(response => {
          log('process cancel order >>>>', response);
          if (response?.is_successful) {
            const new_invoice = {
              ...invoiceInfo,
            };
            new_invoice.is_checkout = 0;
            dispatch(setInvoice(new_invoice));
          }
        })
        .catch(err => {
          error('process cancel order err >>>', err);
        });
    }
  };

  const processPaymentForWalletOnly = () => {
    if (invoiceInfo?.invoice_number && totalPrice.value === 0) {
      const method = 'stcplay';
      updatePaymentMethod({
        invoice_number: invoiceInfo?.invoice_number,
        code: method,
      })
        .then(response2 => {
          if (response2?.is_successful) {
            const payload = generatePaymentPayload(method);
            createPayment(payload)
              .then(response => {
                getInvoice({
                  details: {
                    invoice_number: invoiceNumber ?? '',
                  },
                }).then(res => {
                  if (res?.data?.info) {
                    mixpanelHelper.trackEvent(MixPanelEvents.orderPlaced, {
                      'Product Name': res?.data.info?.products.map(product => product.product_name),
                      'Product Code': res?.data.info?.products.map(product => product.product_code),
                      'Promo Code': res?.data.info?.applied_promo_codes?.[0]?.code,
                      'Total Quantity': res?.data.info?.products.reduce((total, product) => total + product.qty, 0),
                      'Discount Amount': res?.data.info?.amount_discount,
                      'Cart Total': res?.data.info?.total_amount,
                      'Amount Paid': res?.data.info?.total_amount,
                      'Order Status': res?.data.info?.status_code,
                      'Payment Method': [method],
                      'Payment Status': response?.is_successful ? 'successful' : 'failed',
                    });

                    logCustomEvent(BrazeCustomEvents.OrderPlaced, {
                      'Product Name': res?.data.info?.products.map(product => product.product_name),
                      'Product Code': res?.data.info?.products.map(product => product.product_code),
                      'Promo Code': res?.data.info?.applied_promo_codes?.[0]?.code,
                      'Total Quantity': res?.data.info?.products.reduce((total, product) => total + product.qty, 0),
                      'Discount Amount': res?.data.info?.amount_discount,
                      'Cart Total': res?.data.info?.total_amount,
                      'Amount Paid': res?.data.info?.total_amount,
                      'Order Status': res?.data.info?.status_code,
                      'Payment Method': [method],
                      'Payment Status': response?.is_successful ? 'successful' : 'failed',
                    });
                  }
                });

                if (response?.is_successful) {
                  trackAddPaymentInfoEvent(invoiceInfo.amount_grand_total, invoiceInfo.products, method);
                  trackSuccessfulPurchase(invoiceInfo, method, '');
                  setProcessWalletAndAppleOnly(true);
                  setIsProcesing(true);
                  createShipments();
                } else {
                  const errorObj = {
                    error_code: response?.error_code ?? '',
                    error_message: response?.error_msg ?? '',
                  };
                  setIsProcesing(false);
                  trackFailedPurchase(invoiceInfo, errorObj, '');
                  processCancelOrder();
                }
              })
              .catch(err => {
                error('wallet create payment error >>>', err);
                const errorObj = {
                  error_code: 'none',
                  error_message: 'wallet create payment error',
                };
                setIsProcesing(false);
                trackFailedPurchase(invoiceInfo, errorObj, '');
                setHaserror(true);
                processCancelOrder();
              });
          } else {
            const errorObj = {
              error_code: '',
              error_message: 'wallet update payment not successful',
            };
            setIsProcesing(false);
            trackFailedPurchase(invoiceInfo, errorObj, '');
            error('wallet update payment not successful');
            setHaserror(true);
            processCancelOrder();
          }
        })
        .catch(err => {
          error('wallet update payment error');
          const errorObj = {
            error_code: '',
            error_message: 'wallet update payment error',
          };
          setIsProcesing(false);
          trackFailedPurchase(invoiceInfo, errorObj, '');
          setHaserror(true);
          processCancelOrder();
        });
    }
  };

  const processApplePay = () => {
    if (window.ApplePaySession) {
      const { ApplePaySession } = window;
      const amount = convertPriceWithSymbol(invoiceInfo?.amount_grand_total ?? 0, 'commaDecimal', true) || 0;
      const applePaySession = new ApplePaySession(6, {
        countryCode: 'SA',
        currencyCode: currency_code,
        supportedNetworks: APPLE_PAY_CONFIG.supportedNetworks,
        merchantCapabilities: APPLE_PAY_CONFIG.merchantCapabilities,
        total: {
          label: APPLE_PAY_CONFIG.displayName,
          amount: amount,
        },
      });
      applePaySession.begin();
      log('applePaySession >>>>', applePaySession);
      applePaySession.onvalidatemerchant = (event: any) => {
        log('onvalidatemerchant >>>>', event);
        const { validationURL } = event;
        startApplePaySession({ url: validationURL })
          .then(response => {
            log('start session response >>>>', response);
            if (response?.is_successful && response.data) {
              log('completeMerchantValidation >>>', response.data);
              applePaySession.completeMerchantValidation(response.data);
            }
          })
          .catch(err => {
            error('start session error >>>', err);
          });
      };

      applePaySession.onpaymentmethodselected = (event: any) => {
        log('onpaymentmethodselected >>>', event);
        const myPaymentMethod = event.paymentMethod;
        log('myPaymentMethod >>>', myPaymentMethod);
        const applePayPaymentMethodUpdate = {
          newLineItems: [],
          newTotal: {
            label: APPLE_PAY_CONFIG.displayName,
            amount: amount,
            type: 'final',
          },
        };
        applePaySession.completePaymentMethodSelection(applePayPaymentMethodUpdate);
      };
      applePaySession.onshippingmethodselected = (event: any) => {
        log('onshippingmethodselected >>>', event);
        const myShippingMethod = event.shippingMethod;
        log('myShippingMethod >>>', myShippingMethod);
        const applePayPaymentMethodUpdate = {
          newLineItems: [],
          newTotal: {
            label: APPLE_PAY_CONFIG.displayName,
            amount: amount,
            type: 'final',
          },
          newShippingMethods: myShippingMethod,
        };
        applePaySession.completeShippingMethodSelection(applePayPaymentMethodUpdate);
      };
      applePaySession.onpaymentauthorized = (event: any) => {
        const paymentData = event.payment.token;
        log('onpaymentauthorized >>>', paymentData);
        log('onpaymentauthorized invoice checkout >>>', invoiceInfo?.is_checkout);
        if (invoiceInfo && invoiceInfo.is_checkout !== 1) {
          checkout({
            invoice_number: invoiceInfo?.invoice_number,
          })
            .then(response => {
              log('apple pay checkout response >>>>', response);
              if (response?.is_successful) {
                log('apple pay checkout response succesful');
                const new_invoice = {
                  ...invoiceInfo,
                };
                new_invoice.is_checkout = 1;
                dispatch(setInvoice(new_invoice));
                processInvoiceForApplePay(paymentData, applePaySession, ApplePaySession);
              } else {
                error('apple pay checkout response not succesful');
                applePaySession.completePayment({
                  status: ApplePaySession.STATUS_FAILURE,
                });
              }
            })
            .catch(err => {
              error('apple pay checkout erro >>>', err);
              applePaySession.completePayment({
                status: ApplePaySession.STATUS_FAILURE,
              });
            });
        } else {
          log('invoice already checkout');
          processInvoiceForApplePay(paymentData, applePaySession, ApplePaySession);
        }
      };
    }
  };

  const processInvoiceForApplePay = (paymentData: any, applePaySession: any, ApplePaySession: any) => {
    log('processInvoiceForApplePay >>>>', { paymentData: paymentData, applePaySession: applePaySession, ApplePaySession: ApplePaySession });
    if (walletToUse > 0 && invoiceInfo && !isWalletApplied(invoiceInfo)) {
      log('payment in apple pay has applied wallet >>>', walletToUse);
      log('wallet is not yet applied to invoice');
      processApplySTCWallet('apple_pay', paymentData, applePaySession, ApplePaySession);
    } else {
      log('no wallet applied on payment');
      processCreatePaymentForApplePay(paymentData, applePaySession, ApplePaySession);
    }
  };

  const processCreatePaymentForApplePay = (paymentData: any, applePaySession: any, ApplePaySession: any) => {
    const payload: PaymentInput = {
      invoice_number: invoiceInfo?.invoice_number ?? '',
      payment_info: JSON.stringify(paymentData),
    };
    createPayment(payload)
      .then(response => {
        log('create payment >>>>', response);
        if (response?.is_successful && invoiceInfo?.invoice_number) {
          log('create completePayment >>>>', ApplePaySession.STATUS_SUCCESS);
          applePaySession.completePayment({
            status: ApplePaySession.STATUS_SUCCESS,
          });
          setProcessWalletAndAppleOnly(true);
          setIsProcesing(true);
          createShipments();
        } else if (!response?.is_successful) {
          setIsProcesing(false);
          error('create completePayment >>>>', ApplePaySession.STATUS_FAILURE);
          applePaySession.completePayment({
            status: ApplePaySession.STATUS_FAILURE,
          });
          processCancelOrder();
        }
      })
      .catch(err => {
        error('create payment err >>>', err);
        setIsProcesing(false);
        applePaySession.completePayment({
          status: ApplePaySession.STATUS_FAILURE,
        });
      });
  };

  const processPaymentMethod = (method: string) => {
    const payload = generatePaymentPayload(method);
    createPayment(payload)
      .then(response => {
        if (response?.is_successful) {
          setPaymentEmail('');
          const PAYMENT_METHOD_CHECKING = ['payfort_redirection', 'tabby'];
          if (PAYMENT_METHOD_CHECKING.includes(method) && response.data.form) {
            setPaymentMobileNumber('');
            setCreditCardForm(response.data.form);
          } else if (method === 'stcpay') {
            setIsProcesing(false);
            setOpenVerifySTCPayModal(true);
          }
        } else {
          setIsProcesing(false);
          error('processPaymentMethod create payment not successful');
          setHaserror(true);
          processCancelOrder();
        }
      })
      .catch(err => {
        setIsProcesing(false);
        error('processPaymentMethod create payment error');
        setHaserror(true);
        processCancelOrder();
      });
  };

  useEffect(() => {
    if (creditCardForm) {
      if (invoiceInfo?.payment_method_code === 'payfort_redirection') {
        document.getElementById('submit')?.click();
      }
    }
  }, [creditCardForm]);

  const closeModal = () => {
    setHaserror(false);
    setIsPageLeave(false);
    setIsPaymentFailed(false);
    processCancelOrder();
  };
  const checkIfHasNoAvailItem = () => {
    setHasNoAvailableItem(true);
  };

  const cartButtons = (
    <div className="gap-24">
      {currentStep === 0 && (
        <>
          <Button
            text={translate('shop.cart.order_status_delivery_payment') ?? ''}
            style={`
              text-fs-body font-medium mt-8 py-8 2K:mt-[0.775rem] 8K:mt-[24px] 8K:mb-[.5rem] 2K:py-[0.675rem]
              w-full gap-[8px] z-1
              text-white100 border-[1px] border-transparent mb-[0px]
              xsMax:mb-[0px] items-end
              rounded-sm ${
                (selectedProductsArr.length !== 0 || unavailableProducts.length !== 0) && unavailableProductCodes.length === 0
                  ? isHeaderDark
                    ? dir === 'ltr'
                      ? 'after:bg-sunset before:bg-white100'
                      : 'before:bg-sunset after:bg-white100'
                    : `${
                        dir === 'ltr' && selectedProductsArr.length !== 0
                          ? 'after:bg-sunset before:bg-white100'
                          : 'before:bg-sunset after:bg-white100'
                      }`
                  : 'disabled:bg-secondary/[0.1]'
              }
              ${selectedProductsArr.length !== 0 && 'hover:border-[1px] hover:border-sunset hover:text-sunsetText'}
              rounded-sm`}
            disabled={(selectedProductsArr.length === 0 && unavailableProducts.length === 0) || unavailableProductCodes.length > 0}
            onClick={handleTopButton}
          />
          <Button
            text={translate('shop.cart.continue_shopping') ?? ''}
            style={`
              text-fs-body font-medium border-[1px] border-sunset mt-8 py-8 2K:mt-[0.775rem] 8K:mt-[24px] 2K:py-[0.675rem]
              w-full gap-[8px] z-1
              text-white100 text-sunsetText mb-[0px]
              xsMax:mb-[0px] items-end w-full
              rounded-sm ${
                isHeaderDark
                  ? dir === 'ltr'
                    ? 'after:bg-white100 before:bg-sunset'
                    : 'before:bg-white100 after:bg-sunset'
                  : `${dir === 'ltr' ? 'after:bg-white100 before:bg-sunset' : 'before:bg-white100 after:bg-sunset'}`
              }
              hover:text-white100 rounded-sm`}
            disabled={false}
            onClick={handleBottomButton}
          />
        </>
      )}
      {currentStep === 1 && (
        <Button
          text={`${translate('action_pay')} ${convertPriceWithSymbol(totalPrice.value, 'commaDecimal')}`}
          style={`
            text-fs-body font-medium border-[1px] border-sunset mt-8 py-8 2K:mt-[0.775rem] 8K:mt-[24px] 2K:py-[0.675rem]
            w-full gap-[8px] z-1
            text-white100 border-[1px] border-transparent mb-[0px]
            xsMax:mb-[0px] items-end w-full
            rounded-sm ${
              !selectedAddress ||
              selectedAddressError !== '' ||
              (invoiceInfo?.payment_method_code &&
                totalPrice.value !== 0 &&
                ((invoiceInfo.payment_method_code === 'tabby' && !paymentMobileNumber && !paymentEmail) ||
                  (invoiceInfo.payment_method_code === 'stcpay' && !paymentMobileNumber)))
                ? 'disabled:bg-secondary/[0.1]'
                : isHeaderDark
                ? dir === 'ltr'
                  ? 'after:bg-sunset before:bg-white100 hover:border-[1px] hover:border-sunset hover:text-sunsetText '
                  : 'before:bg-sunset after:bg-white100 hover:border-[1px] hover:border-sunset hover:text-sunsetText '
                : `${
                    dir === 'ltr'
                      ? 'after:bg-sunset before:bg-white100 hover:border-[1px] hover:border-sunset hover:text-sunsetText '
                      : 'before:bg-sunset after:bg-white100 hover:border-[1px] hover:border-sunset hover:text-sunsetText '
                  }`
            }
            rounded-sm ${invoiceInfo?.payment_method_code === 'apple_pay' && 'apple-pay'}`}
          disabled={
            !selectedAddress ||
            selectedAddressError !== '' ||
            (invoiceInfo?.payment_method_code &&
              totalPrice.value !== 0 &&
              ((invoiceInfo.payment_method_code === 'tabby' && !paymentMobileNumber && !paymentEmail) ||
                (invoiceInfo.payment_method_code === 'stcpay' && !paymentMobileNumber)))
          }
          onClick={handlePayButton}
        />
      )}
    </div>
  );

  const setModalClose = () => {
    setOpenModal(false);
    setIsAddNewAddress(false);
  };

  //When existing Address name button is tapped
  const onClickExistingAddressButton = (id: number) => {
    log(`on tap of existing address ${id} clicked...`);
    const newAddressToUpdate = deliveryAddress?.filter(address => address?.user_address_id === id);
    setAddressForUpdate(newAddressToUpdate[0]);
    setIsAddNewAddress(false);
  };

  const onClickAddAddressNameButton = () => {
    setIsAddNewAddress(true);
  };

  const onClickSaveAddressAction = (addresInput?: DeliveryAddressInput) => {
    if (addresInput) {
      if (addresInput.address_id && addresInput.address_id > 0) {
        updateAddress(addresInput)
          .then(response => {
            if (response?.updateDeliveryAddress.is_successful) {
              mixpanelHelper.trackEvent(MixPanelEvents.addressAdded, {
                'Update Address': addresInput.address,
              });

              logCustomEvent(BrazeCustomEvents.AddressOnDeliveryAndPaymentPage, {
                'Update Address': addresInput.address,
              });
            }
            log('Success In Updating');
            setOpenModal(false);
            setIsAddNewAddress(false);
          })
          .catch(err => {
            error('ERROR In Updating!', err);
          });
      } else {
        addAddress(addresInput)
          .then(response => {
            if (response?.addDeliveryAddress.is_successful) {
              mixpanelHelper.trackEvent(MixPanelEvents.addressAdded, {
                'Add Address': addresInput.address,
              });

              logCustomEvent(BrazeCustomEvents.AddressOnDeliveryAndPaymentPage, {
                'Add Address': addresInput.address,
              });
            }
            log('Success In Saving');
            setOpenModal(false);
            setIsAddNewAddress(false);
          })
          .catch(err => {
            error('ERROR In Saving!', err);
          });
      }
    }
  };

  const loadInvoiceUponLoading = () => {
    if (invoiceNumber) {
      getInvoice({
        details: {
          invoice_number: invoiceNumber,
        },
      }).then(resp => {
        if (resp?.data?.info?.is_checkout === 1 && !paymentMethod && !status && !invoice) {
          if (resp.data.info.status_code === 'pending') {
            cancelOrder({ invoice_number: resp.data.info.invoice_number }).then(cancelresp => {
              log('cancel order upon loading of page >>>>', cancelresp);
              if (cancelresp?.is_successful) {
                const new_invoice = {
                  ...resp.data.info,
                };
                new_invoice.is_checkout = 0;
                dispatch(setInvoice(new_invoice));
              }
            });
          } else if (lmd_code.lmd_code) {
            createInvoice({
              lmd_code: lmd_code.lmd_code,
            });
          }
        }
      });
    }
  };

  useEffect(() => {
    if (token) {
      if (isLoggedIn) {
        getUserWallet();
      }
      loadInvoiceUponLoading();
      getDeliveryAddress();
    }
    if (window.ApplePaySession) {
      const { ApplePaySession } = window;
      const canMakePayments = ApplePaySession.canMakePayments();
      setIsApplePaySupported(canMakePayments);
      if (!canMakePayments && invoiceInfo && invoiceInfo.payment_method_code === 'apple_pay') {
        const method = 'payfort_redirection';
        updatePaymentMethod({
          invoice_number: invoiceInfo?.invoice_number,
          code: method,
        }).then(response => {
          log('response >>>', response);
          if (response?.is_successful) {
            trackAddPaymentInfoEvent(invoiceInfo.amount_grand_total, invoiceInfo.products, method);
          }
        });
      }
    } else {
      setIsApplePaySupported(false);
      if (invoiceInfo && invoiceInfo.payment_method_code === 'apple_pay') {
        const method = 'payfort_redirection';
        updatePaymentMethod({
          invoice_number: invoiceInfo?.invoice_number,
          code: method,
        }).then(response => {
          if (response?.is_successful) {
            trackAddPaymentInfoEvent(invoiceInfo.amount_grand_total, invoiceInfo.products, method);
          }
          log('response >>>', response);
        });
      }
    }
    return () => {
      if (token && invoiceNumber) {
        getInvoice({
          details: {
            invoice_number: invoiceNumber,
          },
        }).then(resp => {
          if (resp?.data?.info?.is_checkout === 1) {
            if (resp.data.info.status_code === 'pending') {
              cancelOrder({ invoice_number: invoiceNumber })
                .then(response => {
                  log(' unmount cancel order >>>>', response);
                  if (response?.is_successful) {
                    const new_invoice = {
                      ...resp.data.info,
                    };
                    new_invoice.is_checkout = 0;
                    dispatch(setInvoice(new_invoice));
                  }
                })
                .catch(err => {
                  error('process cancel order err >>>', err);
                });
            }
          }
        });
      }
    };
  }, []);

  useEffect(() => {
    const { subtotal, vat, total, grandtotal } = getOrderSummaryTotalAndDiscount(
      currentStep === 0 ? selectedProductsArr : activeProducts,
      totalPromoAmount,
      deliveryFee,
      walletToUse,
      appliedPromo?.percentage,
    );
    setSubtotalPrice({
      value: subtotal,
      formattedValue: translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(subtotal)) ?? '0.00',
    });
    setVatPrice({
      value: vat,
      formattedValue: translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(subtotal)) ?? '0.00',
    });
    setTotalPrice({
      value: total,
      formattedValue: translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(total)) ?? '0.00',
    });
    setDeliveryCharge({
      value: 0,
      formattedValue: translate('currency_SR')?.replace('[balance]', '' + formatNumberWithCommasAndDecimal(0)) ?? '0.00',
    });
    setGrandTotalPrice(grandtotal);
  }, [selectedProductsArr, activeProducts, currentStep, invoiceInfo, deliveryFee, totalPromoAmount, paymentMethod, walletToUse]);

  useEffect(() => {
    if (selectedProducts.length > 0) {
      const selected_prods: InvoiceProduct[] = cartItems.filter(item =>
        selectedProducts.includes(item.product_code + '-' + item.variant_id),
      );
      applyPromoAndSetSelectedProductsArr(selected_prods);
      const available_products = cartItems.filter(item => item.in_stock > 0);
      if (selected_prods.length === available_products.length) {
        setIsCheckAllSelected(true);
      } else {
        setIsCheckAllSelected(false);
      }
      const selected_prods_code: string[] = cartItems.map(item => item.product_code + '-' + item.variant_id);
      const selected_array = selectedProducts.filter(item => selected_prods_code.includes(item));
      setSelectedProducts(selected_array);
      const totalCartPrice = cartItems.reduce((acc, item) => {
        return acc + item.grand_total;
      }, 0);
      if (totalCartPrice < walletToUse) {
        removeStcWalletToUse();
      }
      cartItemsRef.current = cartItems;
    }
  }, [cartItems]);

  const showDigitalProductLimitMessage = (products: InvoiceProduct[]) => {
    const digi_products = products.filter(item => item.is_digital === 1);
    if (digi_products.length >= 5) {
      errorToastMessage({
        className: 'SD:!h-[4.5rem] 2K:h-[1rem] 4K:h-[2rem] 8K:h-[3rem]',
        message: (
          <ToastMessage type="error" className="!items-start" errorIconBaseHeight={20} errorIconBaseWidth={20}>
            <div className="flex w-fit gap-[0.25rem]">
              <div className={`${isMobile ? 'w-[15%]' : 'w-[11%]'}`}>
                <span className="font-medium text-fs-body text-error"> {translate('shop.digital_products_validation.oops')} </span>
              </div>
              <div className={`${isMobile ? 'w-[85%]' : 'w-[89%]'}`}>
                <span className="text-fs-body font-regular text-error">
                  {' '}
                  {translate('shop.digital_products_validation.quantity_limit_error')}{' '}
                </span>
              </div>
            </div>
          </ToastMessage>
        ),
      });
    }
  };

  useEffect(() => {
    if (isLoggedIn || (!isLoggedIn && token && invoiceInfo && !showLoadingIndicator && !createInvoiceGuestFlag)) {
      if (invoiceInfo && invoiceInfo?.products?.length > 0) {
        const new_products: InvoiceProduct[] = invoiceInfo.products.map(prod => {
          const new_prod = {
            ...prod,
          };
          return new_prod;
        });
        applyPromoAndSetCartItems(new_products);

        if (!isPageLoaded) {
          const available_products = new_products.filter(item => item.in_stock > 0);
          const selected_prods_code: string[] = available_products.map(item => item.product_code + '-' + item.variant_id);
          showDigitalProductLimitMessage(new_products);
          applyPromoAndSetSelectedProductsArr(available_products);
          setSelectedProducts(selected_prods_code);
          if (available_products.length > 0) {
            setIsCheckAllSelected(true);
          }
          setIsPageLoaded(true);
        }
      } else if (!isLoggedIn && token && invoiceInfo && invoiceInfo.products.length < 1) {
        setItemsInCart([]);
        setSelectedProductsArr([]);
        setSelectedProducts([]);
        setIsCheckAllSelected(false);
      }
    } else if (!(User.token && User.username)) {
      if (invoiceInfo?.products && invoiceInfo.products.length < 1 && guestCreatedInvoice === true) {
        setGuestCreatedInvoice(false);
        processAddProduct(itemsInCart, 0, invoiceInfo.invoice_number);
      } else if (invoiceInfo?.products && invoiceInfo?.products?.length > 0 && guestCreatedInvoice === true) {
        setGuestCreatedInvoice(false);
        clearInvoice({
          invoice_number: invoiceInfo.invoice_number,
          lmd_code: lmd_code.lmd_code,
        }).then(response => {
          if (response?.is_successful) {
            processAddProduct(itemsInCart, 0, invoiceInfo.invoice_number);
          }
        });
      } else if (invoiceInfo?.products && invoiceInfo.products.length > 0 && createInvoiceGuestFlag === true) {
        const in_stock_devices = invoiceInfo.products.filter(
          product =>
            product.in_stock !== 0 &&
            selectedProductsArr.find(item => item.product_code === product.product_code && item.variant_id === product.variant_id) !==
              undefined,
        );
        const selectProds = selectedProductsArr.map(prod => {
          const new_prod = in_stock_devices.find(item => item.product_code === prod.product_code && item.variant_id === prod.variant_id);
          if (new_prod) {
            return new_prod;
          } else {
            return prod;
          }
        });
        applyPromoAndSetSelectedProductsArr(selectProds);
        const order_product_ids = selectProds.map(product => product.order_product_id);
        selectProducts({
          invoice_number: invoiceInfo.invoice_number,
          order_product_ids: order_product_ids,
        }).then(response => {
          if (response?.is_successful) {
            dispatch(setPersona('guest'));
            setShowLoadingIndicator(false);
            setCreateInvoiceGuestFlag(false);
            setCurrentStep(1);
          }
        });
      } else if (!isLoggedIn && !invoiceInfo) {
        applyPromoAndSetCartItems(itemsInCart);
        if (!isPageLoaded) {
          const available_products = itemsInCart.filter(item => item.in_stock > 0);
          const selected_prods_code: string[] = available_products.map(item => item.product_code + '-' + item.variant_id);
          showDigitalProductLimitMessage(itemsInCart);
          applyPromoAndSetSelectedProductsArr(available_products);
          setSelectedProducts(selected_prods_code);
          if (available_products.length > 0) {
            setIsCheckAllSelected(true);
          }
          setIsPageLoaded(true);
        }
      }
    }
  }, [invoiceInfo, itemsInCart, isLoggedIn, showLoadingIndicator, createInvoiceGuestFlag]);

  useEffect(() => {
    //for triggering has no available item popup upon page load
    if (hasNoAvailableItem) {
      setHaserror(true);
    }
  }, [hasNoAvailableItem]);

  useEffect(() => {
    //stops user from clicking back button when current step is 1
    if (currentStep < 2) {
      window.history.pushState(null, '', window.location.href);
      window.onpopstate = () => {
        window.history.go(1);
        handlePageLeave();
      };
    } else {
      window.onpopstate = () => {}; //cleans onpopstate
    }
  }, [currentStep]);

  const handlePageLeave = () => {
    setIsPageLeave(true);
    setHaserror(true);
  };

  const handleApplySTCWallet = (amount: number) => {
    const convertedAmount = revertPrice(amount);
    setStcWalletToUse(convertedAmount);
  };

  const handleRemoveSTCWallet = () => {
    if (
      invoiceInfo?.is_checkout &&
      invoiceInfo?.wallet_discount &&
      (invoiceInfo.wallet_discount < 0 ? invoiceInfo.wallet_discount * -1 : invoiceInfo.wallet_discount) > 0
    ) {
      cancelOrder({
        invoice_number: invoiceInfo.invoice_number,
      }).then(response => {
        log('cancel order  remove wallet >>>>', response);
        if (response?.is_successful) {
          getInvoice({
            details: {
              invoice_number: invoiceInfo?.invoice_number,
            },
          });
        }
      });
    } else if (invoiceInfo && invoiceInfo.is_checkout === 1 && invoiceInfo.status_code === 'pending') {
      processCancelOrder();
      removeStcWalletToUse();
    } else {
      removeStcWalletToUse();
    }
  };

  function getDefaultDeliveryAddress() {
    return deliveryAddress.find(address => address.is_default);
  }

  useEffect(() => {
    if (
      (invoiceInfo?.shipping_address ||
        invoiceInfo?.shipping_city ||
        invoiceInfo?.shipping_country_code ||
        invoiceInfo?.shipping_email ||
        invoiceInfo?.shipping_latitude ||
        invoiceInfo?.shipping_longitude ||
        invoiceInfo?.shipping_name ||
        invoiceInfo?.shipping_mobile ||
        invoiceInfo?.shipping_postal_code) &&
      deliveryAddress.length > 0
    ) {
      const defaultAddress = deliveryAddress.find(
        address =>
          invoiceInfo.shipping_address === address.address &&
          invoiceInfo.shipping_city === address.city &&
          invoiceInfo.shipping_country_code === address.country_code &&
          invoiceInfo.shipping_email === address.email &&
          parseFloat(invoiceInfo.shipping_latitude) === address.location_lat &&
          parseFloat(invoiceInfo.shipping_longitude) === address.location_long &&
          invoiceInfo.shipping_name === address.name &&
          invoiceInfo.shipping_mobile === address.mobile?.toString() &&
          parseInt(invoiceInfo.shipping_postal_code) === address.zip_code &&
          Boolean(address?.is_default),
      );
      if (defaultAddress) {
        setSelectedAddress(defaultAddress);
        checkAddressStoreDistance(defaultAddress);
      } else if (selectedAddress === undefined) {
        const defaultAddress = getDefaultDeliveryAddress();
        if (defaultAddress) {
          updateInvoiceAddress(defaultAddress);
        } else {
          updateInvoiceAddress(deliveryAddress[0]);
        }
      } else {
        updateAddressWhenChangesOnSelected();
      }
    } else if (selectedAddress === undefined && deliveryAddress.length > 0) {
      const defaultAddress = getDefaultDeliveryAddress();
      if (defaultAddress) {
        updateInvoiceAddress(defaultAddress);
      } else {
        updateInvoiceAddress(deliveryAddress[0]);
      }
    } else {
      updateAddressWhenChangesOnSelected();
    }
  }, [invoiceInfo, deliveryAddress]);

  const checkAddressStoreDistance = (address: DeliveryAddressModel) => {
    if (invoiceInfo) {
      const digital_products = invoiceInfo.products.filter(prod => prod.is_digital === 1 && prod.in_stock !== 0 && prod.is_active === 1);
      if (digital_products.length === activeProducts.length) return;
      checkStoreDistance({
        details: {
          lmd_code: invoiceInfo?.lmd_code,
          location_lat: address.location_lat?.toString() ?? '',
          location_long: address.location_long?.toString() ?? '',
        },
      })
        .then(response => {
          if (response?.error_code === '3041') {
            setSelectedAddressError(translate('shop.address_error') ?? '');
          }

          if (!response?.is_successful && response?.error_code && response.error_msg) {
            mixpanelHelper.trackEvent(MixPanelEvents.cartError, {
              'Error Code': response.error_code,
              'Error Message': response.error_msg,
              'Product Code': invoiceInfo.products.map(product => product.product_code),
              'City Name (LMD)': lmd_code.city,
            });

            logCustomEvent(BrazeCustomEvents.CartError, {
              'Error Code': response.error_code,
              'Error Message': response.error_msg,
              'Product Code': invoiceInfo.products.map(product => product.product_code),
              'City Name (LMD)': lmd_code.city,
            });
          }
        })
        .catch(err => {
          error('failed payment err >>>', err);
        });
    }
  };

  const updateAddressWhenChangesOnSelected = () => {
    if (selectedAddress && deliveryAddress.length > 0) {
      const temp_selectedAddress = deliveryAddress.find(
        address =>
          address.user_address_id === selectedAddress.user_address_id &&
          address.city === selectedAddress.city &&
          address.country === selectedAddress.country &&
          address.address === selectedAddress.address &&
          address.country_code === selectedAddress.country_code &&
          address.email === selectedAddress.email &&
          address.location_lat === selectedAddress.location_lat &&
          address.location_long === selectedAddress.location_long &&
          address.mobile === selectedAddress.mobile &&
          address.mobile_code === selectedAddress.mobile_code &&
          address.zip_code === selectedAddress.zip_code,
      );
      if (!temp_selectedAddress) {
        const new_selected_address = deliveryAddress.find(address => address.user_address_id === selectedAddress?.user_address_id);
        if (new_selected_address) {
          setSelectedAddress(new_selected_address);
          updateInvoiceAddress(new_selected_address);
        } else {
          const defaultAddress = getDefaultDeliveryAddress();
          if (defaultAddress) {
            updateInvoiceAddress(defaultAddress);
          } else {
            updateInvoiceAddress(deliveryAddress[0]);
          }
        }
      }
    } else if (deliveryAddress.length === 0) {
      setSelectedAddress(undefined);
    }
  };

  const processUpdateAddress = (address: DeliveryAddressModel) => {
    if (!invoiceInfo) return;
    setSelectedAddressError('');
    const digital_products = invoiceInfo.products.filter(prod => prod.is_digital === 1);
    let shippingAddress: ShippingAddressInput = {
      shipping_name: address?.name ?? '',
      shipping_address: address.address,
      shipping_postal_code: address.zip_code ?? 0,
      shipping_city: address.city,
      shipping_email: address.email,
      shipping_latitude: address.location_lat?.toString(),
      shipping_longitude: address.location_long?.toString(),
      shipping_mobile: address.mobile?.toString(),
      invoice_number: invoiceInfo?.invoice_number,
    };
    let billingAddress: BillingAddressInput = {
      billing_name: address.name ?? '',
      billing_postal_code: address.zip_code ?? 0,
      billing_address: address.address,
      billing_city: address.city,
      billing_email: address.email,
      billing_latitude: address.location_lat?.toString(),
      billing_longitude: address.location_long?.toString(),
      billing_mobile: address.mobile?.toString(),
      invoice_number: invoiceInfo.invoice_number,
    };
    let fulfillmentPayload: DigitalFulfillmentInput = {
      invoice_number: invoiceInfo?.invoice_number,
      identifier: address.email ?? '',
      code: fulfilmentCode,
    };
    if (address.country_code) {
      shippingAddress = {
        ...shippingAddress,
        shipping_country_code: address.country_code,
      };

      billingAddress = {
        ...billingAddress,
        billing_country_code: address.country_code,
      };
    }
    if (address.mobile_code && fulfilmentCode === 'sms') {
      fulfillmentPayload = {
        ...fulfillmentPayload,
        country_code: parseInt(address.mobile_code),
        identifier: address.mobile?.toString() ?? '',
      };
    }
    updateShippingAddress(shippingAddress).then(response => {
      if (response?.is_successful) {
        setSelectedAddress(address);
        if (digital_products.length > 0) {
          updateDigitalFulfillment(fulfillmentPayload);
        }
      }
    });
    updateBillingAddress(billingAddress);
  };

  const updateInvoiceAddress = (address: DeliveryAddressModel) => {
    if (address && invoiceInfo?.invoice_number) {
      const digital_products = invoiceInfo.products.filter(prod => prod.is_digital === 1 && prod.in_stock !== 0 && prod.is_active === 1);
      if (digital_products.length === activeProducts.length) {
        processUpdateAddress(address);
      } else {
        checkStoreDistance({
          details: {
            lmd_code: invoiceInfo.lmd_code,
            location_lat: address.location_lat?.toString() ?? '',
            location_long: address.location_long?.toString() ?? '',
          },
        })
          .then(response => {
            if (response?.is_successful) {
              processUpdateAddress(address);
            } else if (response?.error_code === '3041') {
              setSelectedAddressError(translate('shop.address_error') ?? '');
              setSelectedAddress(address);
            }

            if (!response?.is_successful && response?.error_code && response.error_msg) {
              mixpanelHelper.trackEvent(MixPanelEvents.cartError, {
                'Error Code': response.error_code,
                'Error Message': response.error_msg,
                'Product Code': invoiceInfo.products.map(product => product.product_code),
                'City Name (LMD)': lmd_code.city,
              });

              logCustomEvent(BrazeCustomEvents.CartError, {
                'Error Code': response.error_code,
                'Error Message': response.error_msg,
                'Product Code': invoiceInfo.products.map(product => product.product_code),
                'City Name (LMD)': lmd_code.city,
              });
            }
          })
          .catch(err => {
            error('failed payment err >>>', err);
          });
      }
    }
  };

  const handlePaymentMethod = (payment: string) => {
    if (invoiceInfo?.invoice_number) {
      updatePaymentMethod({
        invoice_number: invoiceInfo?.invoice_number,
        code: payment,
      })
        .then(response => {
          if (response?.is_successful) {
            trackAddPaymentInfoEvent(invoiceInfo.amount_grand_total, invoiceInfo.products, payment);

            getInvoice({
              details: {
                invoice_number: invoiceInfo.invoice_number,
              },
            });
          }
        })
        .catch(err => {
          error('failed payment err >>>', err);
        });
    }
  };

  const resendSTCPayOTP = () => {
    if (invoiceInfo?.invoice_number) {
      setSTCPayOTP('');
      setVerifyOTPError('');
      const payload = generatePaymentPayload(invoiceInfo.payment_method_code);
      createPayment(payload).then(response => {
        if (response?.is_successful) {
        }
      });
    }
  };

  const verifySTCPay = () => {
    if (stcpayOTP && invoiceInfo?.invoice_number) {
      verifyOtp({
        invoice_number: invoiceInfo.invoice_number,
        otp: parseInt(stcpayOTP),
      }).then(response => {
        if (!response?.is_successful && response?.error_msg) {
          setVerifyOTPError(response?.error_msg);
        } else if (response?.is_successful) {
          setPaymentMobileNumber('');
          setOpenVerifySTCPayModal(false);
          setIsProcesing(true);

          getInvoice({
            details: {
              invoice_number: invoiceInfo.invoice_number ?? '',
            },
          }).then(res => {
            if (res?.data?.info) {
              let paymentMethods: string[] = [];

              if (res?.data.info.wallet_discount !== 0) {
                if (res?.data.info.amount_grand_total > 0) {
                  paymentMethods = [PAYMENT_METHODS.stcplay, res?.data.info.payment_method_code];
                } else if (res?.data.info.amount_grand_total === 0) {
                  paymentMethods = [PAYMENT_METHODS.stcplay];
                }
              } else {
                paymentMethods = [res?.data.info.payment_method_code];
              }

              mixpanelHelper.trackEvent(MixPanelEvents.orderPlaced, {
                'Product Name': res?.data.info?.products.map(product => product.product_name),
                'Product Code': res?.data.info?.products.map(product => product.product_code),
                'Promo Code': res?.data.info?.applied_promo_codes?.[0]?.code,
                'Total Quantity': res?.data.info?.products.reduce((total, product) => total + product.qty, 0),
                'Discount Amount': res?.data.info?.amount_discount,
                'Cart Total': res?.data.info?.total_amount,
                'Amount Paid': res?.data.info?.total_amount,
                'Order Status': res?.data.info?.status_code,
                'Payment Method': paymentMethods,
                'Payment Status': response?.is_successful ? 'successful' : 'failed',
              });

              logCustomEvent(BrazeCustomEvents.OrderPlaced, {
                'Product Name': res?.data.info?.products.map(product => product.product_name),
                'Product Code': res?.data.info?.products.map(product => product.product_code),
                'Promo Code': res?.data.info?.applied_promo_codes?.[0]?.code,
                'Total Quantity': res?.data.info?.products.reduce((total, product) => total + product.qty, 0),
                'Discount Amount': res?.data.info?.amount_discount,
                'Cart Total': res?.data.info?.total_amount,
                'Amount Paid': res?.data.info?.total_amount,
                'Order Status': res?.data.info?.status_code,
                'Payment Method': paymentMethods,
                'Payment Status': response?.is_successful ? 'successful' : 'failed',
              });
            }
          });
          createShipments();
        }
      });
    }
  };

  const handleChangeVerifyOTP = (value: string) => {
    setVerifyOTPError('');
    setSTCPayOTP(value);
  };

  useEffect(() => {
    if (paymentMethod && status && invoice) {
      let invoiceNumber: string | null = null;

      if (paymentMethod === PAYMENT_METHODS.tabby) {
        invoiceNumber = invoice;
      } else {
        const matchResult = invoice.match(/_(\d+)_/);
        invoiceNumber = matchResult ? matchResult[1] : null;
      }
      if (invoiceNumber) {
        getInvoice({
          details: {
            invoice_number: invoiceNumber ?? '',
          },
        }).then(res => {
          let paymentMethods: string[] = [];
          if (res?.data?.info) {
            if (res?.data.info.wallet_discount !== 0) {
              if (res?.data.info.amount_grand_total > 0) {
                paymentMethods = [PAYMENT_METHODS.stcplay, res?.data.info.payment_method_code];
              } else if (res?.data.info.amount_grand_total === 0) {
                paymentMethods = [PAYMENT_METHODS.stcplay];
              }
            } else {
              paymentMethods = [res?.data.info.payment_method_code];
            }
          }
          if (res?.data?.info) {
            mixpanelHelper.trackEvent(MixPanelEvents.orderPlaced, {
              'Product Name': res?.data.info?.products.map(product => product.product_name),
              'Product Code': res?.data.info?.products.map(product => product.product_code),
              'Promo Code': res?.data.info?.applied_promo_codes?.[0]?.code,
              'Total Quantity': res?.data.info?.products.reduce((total, product) => total + product.qty, 0),
              'Discount Amount': res?.data.info?.amount_discount,
              'Cart Total': res?.data.info?.total_amount,
              'Amount Paid': res?.data.info?.total_amount,
              'Order Status': res?.data.info?.status_code,
              'Payment Method': paymentMethods,
              'Payment Status': status,
            });

            logCustomEvent(BrazeCustomEvents.OrderPlaced, {
              'Product Name': res?.data.info?.products.map(product => product.product_name),
              'Product Code': res?.data.info?.products.map(product => product.product_code),
              'Promo Code': res?.data.info?.applied_promo_codes?.[0]?.code,
              'Total Quantity': res?.data.info?.products.reduce((total, product) => total + product.qty, 0),
              'Discount Amount': res?.data.info?.amount_discount,
              'Cart Total': res?.data.info?.total_amount,
              'Amount Paid': res?.data.info?.total_amount,
              'Order Status': res?.data.info?.status_code,
              'Payment Method': paymentMethods,
              'Payment Status': status,
            });
          }
        });
      }

      if (status === 'successful') {
        // Successful purchase
        if (invoiceInfo) trackSuccessfulPurchase(invoiceInfo, paymentMethod, '');

        setIsProcesing(true);
        setCurrentStep(1);
        createShipments();
      } else {
        if (invoiceInfo) {
          const errorObj = {
            error_code: 'none',
            error_message: t('error.something_went_wrong'),
          };
          trackFailedPurchase(invoiceInfo, errorObj, '');
          if (invoiceInfo.is_checkout === 1 && invoiceInfo.status_code === 'pending') {
            cancelOrder({ invoice_number: invoiceInfo?.invoice_number })
              .then(response => {
                log('failed payment cancel order >>>>', response);
                if (response?.is_successful) {
                  const new_invoice = {
                    ...invoiceInfo,
                  };
                  new_invoice.is_checkout = 0;
                  dispatch(setInvoice(new_invoice));
                  setHaserror(true);
                  setIsPaymentFailed(true);
                  setCurrentStep(1);
                }
              })
              .catch(err => {
                error('failed payment err >>>', err);
              });
          }
        }
      }
    }
  }, [paymentMethod, status, invoice]);

  const createShipments = () => {
    const digital_products = invoiceInfo?.products.filter(prod => prod.is_digital === 1);
    const physical_products = invoiceInfo?.products.filter(prod => prod.is_digital === 0);
    if (invoiceInfo?.invoice_number) {
      let selected_lmd_code = lmd_code.lmd_code;
      if (!lmd_code.lmd_code) {
        const selectedCity = country.find(item => item.city === DEFAULT_CITY);
        if (selectedCity) {
          selected_lmd_code = selectedCity.lmd_code;
        }
      }
      if (digital_products && digital_products.length > 0) {
        createDigital({
          invoice_number: invoiceInfo.invoice_number,
        })
          .then(response => {
            if (!physical_products || (physical_products && physical_products.length === 0)) {
              setSuccessfulInvoice(invoiceInfo?.invoice_number);
              dispatch(clearCart());
              createInvoice({
                lmd_code: selected_lmd_code,
              }).then(response2 => {
                if (!response2?.is_successful) {
                  dispatch(clearInvoiceStore());
                }
                setIsProcesing(false);
                setCurrentStep(2);
              });
            } else if (physical_products && physical_products.length > 0) {
              createPhysical({
                invoice_number: invoiceInfo.invoice_number,
              }).then(response2 => {
                setSuccessfulInvoice(invoiceInfo?.invoice_number);
                dispatch(clearCart());
                createInvoice({
                  lmd_code: selected_lmd_code,
                }).then(response3 => {
                  if (!response3?.is_successful) {
                    dispatch(clearInvoiceStore());
                  }
                  setIsProcesing(false);
                  setCurrentStep(2);
                });
              });
            }
          })
          .catch(err => {
            error('failed payment err >>>', err);
          });
      }
      if (physical_products && physical_products.length > 0 && (!digital_products || (digital_products && digital_products.length === 0))) {
        createPhysical({
          invoice_number: invoiceInfo.invoice_number,
        }).then(response => {
          setSuccessfulInvoice(invoiceInfo?.invoice_number);
          dispatch(clearCart());
          createInvoice({
            lmd_code: selected_lmd_code,
          }).then(response2 => {
            if (!response2?.is_successful) {
              dispatch(clearInvoiceStore());
            }
            setIsProcesing(false);
            setCurrentStep(2);
          });
        });
      }
    }
  };

  const processCreateInvoiceForGuest = () => {
    setShowLoadingIndicator(true);
    setGuestCreatedInvoice(true);
    handleContinueGuestPopupClose();
    getDeliveryAddress();
    createInvoice({
      lmd_code: lmd_code.lmd_code,
    });
  };

  const processAddProduct = (items: InvoiceProduct[], index: number, invoice_number: string) => {
    if (items[index]) {
      addProduct({
        invoice_number: invoice_number,
        variant_id: items[index].variant_id,
        product_code: items[index].product_code,
        qty: items[index].qty,
      }).then(response => {
        const addedProducts = listOfAddedProducts;
        if (response?.is_successful) {
          addedProducts.push(items[index]);
          setListOfAddedProducts([...listOfAddedProducts, items[index]]);
        }
        if (!response?.is_successful && response?.error_code && response?.error_msg) {
          mixpanelHelper.trackEvent(MixPanelEvents.cartError, {
            'Error Code': response.error_code,
            'Error Message': response.error_msg,
            'Product Code': items[index].product_code,
          });

          logCustomEvent(BrazeCustomEvents.CartError, {
            'Error Code': response.error_code,
            'Error Message': response.error_msg,
            'Product Code': items[index].product_code,
          });
        }
        const new_index = index + 1;
        if (new_index < items.length && items[new_index]) {
          processAddProduct(items, new_index, invoice_number);
        } else if (new_index >= items.length) {
          getInvoice({
            details: {
              invoice_number: invoice_number,
            },
          }).then(response => {
            setCreateInvoiceGuestFlag(true);
          });
        }
      });
    }
  };

  const handleContinueGuestPopupClose = () => {
    setIsOpenContinueGuest(false);
  };

  const handleChangeLMDCode = (code: LMDModel) => {
    setOutOfStockAction('lmd');
    setCartPageAvailableProductModalOpen(true);
    setPreviousLMD(code);
  };

  const checkEachProductAvailability = async (len: number, index: number, products: InvoiceProduct[]) => {
    if (index <= len) {
      if (index === 0) {
        setShowLoadingIndicator(true);
      }
      await checkProductAvailability({
        product_code: products[index]?.product_code,
        variant_id: products[index]?.variant_id.toString(),
        lmd_code: lmd_code.lmd_code,
        qty: products[index]?.qty,
      })
        .then(response => {
          if (!response?.isProductAvailable.is_successful) {
            if (response?.isProductAvailable.error_code && response?.isProductAvailable.error_msg) {
              mixpanelHelper.trackEvent(MixPanelEvents.cartError, {
                'Error Code': response.isProductAvailable.error_code,
                'Error Message': response.isProductAvailable.error_msg,
                'Product Code': products[index]?.product_code,
              });

              logCustomEvent(BrazeCustomEvents.CartError, {
                'Error Code': response.isProductAvailable.error_code,
                'Error Message': response.isProductAvailable.error_msg,
                'Product Code': products[index]?.product_code,
              });
            }

            if (currentStep === 0) {
              if (!isLoggedIn && !invoiceInfo) {
                dispatch(
                  updateCartItemStock({ product_code: products[index].product_code, in_stock: 0, variant_id: products[index].variant_id }),
                );
              } else {
                setItemsInCart((prevState: InvoiceProduct[]) => {
                  const newitems: InvoiceProduct[] = prevState.map((item: InvoiceProduct) => {
                    const new_prod = applyPromoAndReturnCartItem(item, appliedPromo);
                    if (new_prod.product_code === products[index].product_code) {
                      new_prod.in_stock = 0;
                    }
                    return new_prod;
                  });
                  return newitems;
                });
              }
            }
          } else if (response?.isProductAvailable.is_successful) {
            if (!isLoggedIn && !invoiceInfo) {
              dispatch(
                updateCartItemStock({ product_code: products[index].product_code, in_stock: 1, variant_id: products[index].variant_id }),
              );
            }
          }
          if (index <= len) {
            checkEachProductAvailability(len, index + 1, products);
          } else {
            setShowLoadingIndicator(false);
          }
        })
        .catch(err => {
          if (index <= len) {
            checkEachProductAvailability(len, index + 1, products);
          } else {
            setShowLoadingIndicator(false);
          }
        });
    } else {
      setShowLoadingIndicator(false);
    }
  };

  const handleCartProductAvailability = async (action: string) => {
    switch (action) {
      case 'close':
        setCartPageAvailableProductModalOpen(false);
        if (outOfStockAction === 'lmd') {
          previousLMD && changeLMDCode(previousLMD);
          setPreviousLMD(undefined);
        } else {
          setCurrentStep(0);
        }
        break;
      case 'yes':
        setCartPageAvailableProductModalOpen(false);
        setOutOfStockAction('');
        if (outOfStockAction === 'lmd' && lmd_code.lmd_code !== invoiceInfo?.invoice_number) {
          setShowLoadingIndicator(true);
          createInvoice({
            lmd_code: lmd_code.lmd_code,
          }).then(resp => {
            const prodLen = cartItems.length - 1;
            checkEachProductAvailability(prodLen, 0, cartItems);
          });
        } else if (outOfStockAction === 'pay') {
          setShowUnavailableProducts(false);
        }
        break;
    }
  };

  const handleAbandonCartPage = () => {
    handleRemoveSTCWallet();
    navigate(AppRoutes.shop);
  };

  const handleSTCPayCloseModal = () => {
    setOpenVerifySTCPayModal(false);
    processCancelOrder();
  };

  useEffect(() => {
    //Added Product Availability Checking
    if (!lmd_code.lmd_code) return;
    switch (currentStep) {
      case 0: //Cart Page
        if (cartItems.length > 0 && pageHaveCheckedAvailability !== 0) {
          setPageHaveCheckedAvailability(0);
          const prodLen = cartItems.length - 1;
          checkEachProductAvailability(prodLen, 0, cartItems);
        }
        break;
      case 1: //Checkout Page
        if (activeProducts.length > 0 && pageHaveCheckedAvailability !== 1) {
          setPageHaveCheckedAvailability(1);
          const prodLen = activeProducts.length - 1;
          checkEachProductAvailability(prodLen, 0, activeProducts);

          if (invoiceInfo) {
            trackBeginCheckoutEvent(invoiceInfo.amount_grand_total, invoiceInfo.products ?? []);
          }
        }
        break;
    }
  }, [currentStep, cartItems, activeProducts, lmd_code]);

  useEffect(() => {
    // invoiceInfo.
    if (!invoiceInfo) return;

    if (invoiceInfo?.invoice_number) trackCartEvent('view_cart', invoiceInfo.amount_grand_total, invoiceInfo.products ?? []);
  }, [invoiceInfo?.invoice_number]);

  useEffect(() => {
    if (selectedAddress && invoiceInfo) {
      trackAddShippingInfoEvent(invoiceInfo.amount_grand_total, invoiceInfo.products ?? []);
    }
  }, [selectedAddress?.user_address_id]);

  useEffect(() => {
    if (location.pathname === AppRoutes.cart) {
      mixpanelHelper.trackPageView('SHOP - CART PAGE', 'shop_cart_page', window.location.pathname, document.referrer);
      brazeHelper.trackPageView('SHOP - CART PAGE', 'shop_cart_page', window.location.pathname, document.referrer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (currentStep === 2) {
      mixpanelHelper.trackPageView(
        'SHOP - ORDER SUMMARY PAGE “After purchase“',
        'shop_order_summary_page',
        window.location.pathname,
        document.referrer,
      );
      brazeHelper.trackPageView(
        'SHOP - ORDER SUMMARY PAGE “After purchase“',
        'shop_order_summary_page',
        window.location.pathname,
        document.referrer,
      );
    }
  }, [currentStep]);

  useEffect(() => {
    if (productError) {
      mixpanelHelper.trackEvent(MixPanelEvents.cartError, {
        'Error Code': productError.errorCode,
        'Error Message': productError.errorMessage,
        'Product Code': productError.productCode,
      });

      logCustomEvent(BrazeCustomEvents.CartError, {
        'Error Code': productError.errorCode,
        'Error Message': productError.errorMessage,
        'Product Code': productError.productCode,
      });

      setProductError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productError]);

  return (
    <>
      <CustomHelmet
        pageTitle={translate(PAGE_TITLE.cart as TxKeyPath) ?? ''}
        metaDescription={translate(PAGE_DESCRIPTION.cart as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.cart as TxKeyPath) ?? ''}
      />
      {(!products || (products && products.length <= 0)) &&
      status !== 'successful' &&
      !isLoading &&
      !showLoadingIndicator &&
      !processWalletAndAppleOnly &&
      !isProcessing &&
      !successfulInvoice ? (
        <EmptyCart image={IMAGES.CartNoItem.toString()} text={translate('shop.cart.mini_cart_empty_header')} />
      ) : (
        <>
          <CountryDropDown classNames="!mt-20 z-[2]" disabled={currentStep !== 0} onLMDChange={handleChangeLMDCode} />
          <StyledContainer>
            <StyledHeaderContainer>
              <TrackingBar currentStep={currentStep} steps={steps} />
            </StyledHeaderContainer>
            <StyledMainSection>
              {currentStep === 0 && (
                <Cart
                  products={cartItems}
                  language={language}
                  increment={increase}
                  decrement={decrease}
                  checkAllSelected={isCheckAllSelected}
                  selectedProducts={selectedProducts}
                  handleCheckAll={handleCheckAll}
                  handleClickCheckbox={handleSelectProduct}
                  isRTL={isRTL}
                  clearItems={clearItems}
                  isHeaderDark={isHeaderDark}
                  dir={dir}
                  isMobile={isMobile}
                  remove={remove}
                  checkIfHasNoAvailItem={checkIfHasNoAvailItem}
                  unavailableProductCodes={unavailableProductCodes}
                  currentStep={currentStep}
                />
              )}
              {currentStep === 1 && (
                <Checkout
                  addresses={deliveryAddress}
                  selectedAddress={selectedAddress}
                  selectAddress={selectAddress}
                  onClickNewAddress={onClickNewAddress}
                  onClickDeleteAddress={onClickDeleteAddress}
                  onClickEditAddress={onClickEditAddress}
                  products={activeProducts}
                  language={language}
                  isRTL={isRTL}
                  openModal={openModal}
                  setModalClose={setModalClose}
                  isMobile={isMobile}
                  isAddNewAddress={isAddNewAddress}
                  addressForUpdate={addressForUpdate}
                  onClickExistingAddressButton={onClickExistingAddressButton}
                  onClickAddAddressNameButton={onClickAddAddressNameButton}
                  onClickSaveAddressAction={onClickSaveAddressAction}
                  user={currentuser}
                  updatePaymentMethod={handlePaymentMethod}
                  paymentMethod={invoiceInfo?.payment_method_code}
                  setPaymentMobileNumber={setPaymentMobileNumber}
                  setPaymentEmail={setPaymentEmail}
                  paymentEmail={paymentEmail}
                  setPaymentMobileCodeISO={setPaymentMobileCodeISO}
                  paymentMethodDisabled={walletToUse > 0 && totalPrice.value === 0}
                  isApplePaySupported={isApplePaySupported}
                  selectedAddressError={selectedAddressError}
                  subtotalPrice={subtotalPrice.value}
                  totalPrice={totalPrice.value}
                  cartItems={cartItems}
                  currencyCode={currency_code}
                />
              )}
            </StyledMainSection>
            {currentStep === 2 ? (
              <Confirmation
                img={IMAGES.Confirmation.toString()}
                header={translate('shop.cart.confirmation_thank_you')}
                message={translate('shop.cart.confirmation_message')}
                button={isLoggedIn ? translate('shop.cart.confirmation_go_to_orders') : translate('common_create_account')}
                order_id={successfulInvoice}
                showId={true}
                showSign={true}
              />
            ) : (
              <StyledSideSection>
                <div className="">
                  <CartSummary>
                    <OrderSummary
                      items={[
                        [
                          [
                            <span className="text-fs-body-small font-regular text-secondary" key="subtotal">
                              {translate('shop.cart.order_summary.subtotal')}
                            </span>,
                            <CurrencyText
                              className="text-fs-body-small font-regular text-secondary"
                              currency={subtotalPrice.value}
                              key="subtotal_text"
                            />,
                          ],
                          [
                            <span className="text-fs-body-small font-regular text-secondary" key="vat">
                              {translate('shop.cart.order_summary.vat')}
                            </span>,
                            isLoggedIn ? (
                              <CurrencyText className="text-fs-body-small font-regular text-secondary" currency={vatPrice.value} />
                            ) : null,
                          ],
                          [
                            <span className="text-fs-body-small font-regular text-secondary" key="promo">
                              {translate('shop.cart.discount_code.promo') ?? ''}
                            </span>,
                            totalPromoAmount > 0 ? (
                              <div className="flex text-green">
                                <span>-</span>
                                <CurrencyText className="text-fs-body-small font-regular" currency={totalPromoAmount} />
                              </div>
                            ) : null,
                          ],
                          [
                            <span className="text-fs-body-small font-regular text-secondary" key="delivery">
                              {translate('shop.cart.order_summary.delivery')}
                            </span>,
                            deliveryCharge?.value > 0 ? (
                              <CurrencyText className="text-fs-body-small font-regular text-secondary" currency={deliveryCharge.value} />
                            ) : (
                              <span className="text-fs-body-small font-regular text-green">
                                {translate('shop.cart.order_summary.free')}
                              </span>
                            ),
                          ],
                          [
                            <span className="text-sunset" key="promo_code">
                              {translate('shop.cart.order_summary.promo_code')}
                            </span>,
                            totalDiscountsFormat && <span className="text-sunset">-{totalDiscountsFormat}</span>,
                          ],
                          [
                            <span className="flex" key="wallet_title">
                              <ResponsiveIcon
                                name={IconNames.stcPlay}
                                baseWidth={48}
                                baseHeight={20}
                                className="fill-purple dark:fill-white100 mr-[0.5rem]"
                              />
                              {translate('shop.cart.stc_wallet.title')}
                            </span>,

                            walletToUse ? (
                              <div className="flex text-green">
                                <span>-</span>
                                <CurrencyText currency={walletToUse} />
                              </div>
                            ) : null,
                          ],
                        ],
                        [
                          [
                            <span className="font-medium">{translate('shop.cart.order_summary.total')}</span>,
                            <CurrencyText className="font-medium text-fs-body-large" currency={totalPrice.value} />,
                          ],
                        ],
                      ]}
                      discountCodeDisclaimer={
                        isLoggedIn === false ? (
                          <div className="w-full pt-[0.5rem]">
                            <div className="flex items-start self-stretch justify-between font-regular text-fs-body-small text-neutral-400-zinc-500">
                              <span>{translate('shop.cart.order_summary.discount_disclaimer')}</span>
                            </div>
                          </div>
                        ) : null
                      }
                    />
                    {isLoggedIn && (
                      <StcWallet
                        balance={walletBalance}
                        amount={walletToUse}
                        amountToUse={walletToUse}
                        amountRemaining={0 + walletBalance - walletToUse}
                        totalPrice={grandTotalPrice}
                        onApply={(amount: number) => {
                          if (typeof amount !== 'number' || amount === 0) return;
                          if ((amount || 0) > (walletBalance || 0)) return;
                          handleApplySTCWallet(amount);
                        }}
                        title={translate('shop.cart.stc_wallet.title') ?? ''}
                        applyLabel={translate('action_apply') ?? ''}
                        onRemove={handleRemoveSTCWallet}
                        removeLabel={translate('action_remove') ?? ''}
                        amountPlaceholderLabel={translate('shop.cart.stc_wallet.amount_placeholder') ?? ''}
                        remainingBalanceMessage={translate('shop.cart.stc_wallet.remaining_balance_message') ?? ''}
                        notEnoughFundsMessage={translate('error.notEnoughtFunds') ?? ''}
                        greaterAmountThanTotalMessage={translate('shop.cart.stc_wallet.amount_applied_is_greater_than_total') ?? ''}
                      />
                    )}
                    {isLoggedIn || (!isLoggedIn && currentStep === 1) ? (
                      <DiscountCode
                        onApplyDiscount={applyPromo}
                        onRemoveDiscount={clearPromo}
                        appliedPromo={appliedPromo}
                        maxDiscounts={maxDiscounts}
                        label={translate('shop.cart.discount_code.promo') ?? ''}
                        optionalLabel={translate('formAttribute_optional') ?? ''}
                        removeLabel={translate('action_remove') ?? ''}
                        applyLabel={translate('action_apply') ?? ''}
                        discountCodePlaceholder={translate('shop.cart.discount_code.input_placeholder') ?? ''}
                        submitError={promoResponse}
                      />
                    ) : null}
                    {(currentStep === 0 || currentStep === 1) && cartButtons}
                    <div>
                      <p className="text-fs-caption font-regular text-secondary/[0.5]">{translate('shop.cart.order_summary_disclaimer')}</p>
                    </div>
                  </CartSummary>
                </div>
              </StyledSideSection>
            )}

            {currentStep === 0 && (
              <div className="SD:col-span-3">
                <TailoredSection classNames={`${isMobile && 'grid grid-cols-2'}`} isMobile={isMobile} />
              </div>
            )}
          </StyledContainer>
        </>
      )}
      <Modal open={haserror}>
        <GeneralError
          body_text={{
            error_text_header: isPageLeave
              ? translate('error.one_click_away')
              : isPaymentFailed
              ? translate('error.payment_failed')
              : hasNoAvailableItem
              ? translate('error.products_no_longer_avail')
              : translate('error.somethings_lagged'),
            error_text: isPageLeave
              ? translate('error.please_finalize_payment')
              : isPaymentFailed
              ? translate('error.something_went_wrong')
              : hasNoAvailableItem
              ? translate('error.go_back_to_cart')
              : translate('error.please_try_again'),
            haserroradvise: !isPageLeave && !isPaymentFailed && !hasNoAvailableItem,
            error_text_advise: translate('error.issue_keeps_happening'),
            error_text_advise_cta: {
              cta_text: translate('common_contactUs'),
              cta_nav: AppRoutes.contactUS,
            },
          }}
          button_1={{
            button_text: isPageLeave
              ? translate('action_leave')
              : isPaymentFailed
              ? translate('error.choose_method')
              : translate('common.ok'),
            style:
              'px-24 py-8 w-full mt-0  text-fs-body font-medium border-[1px] border-sunset text-sunset 4xl:h-[71px] 5xl:h-[95.55px] 4xl:text-[28px] 5xl:text-[38.22px] 8xl:text-[85.33px] 8xl:h-[213.33px]' +
              (isPageLeave || isPaymentFailed ? '' : ' !hidden'),
            onclick: isPageLeave
              ? () => {
                  handleAbandonCartPage();
                }
              : closeModal,
          }}
          button_2={{
            button_text: isPageLeave
              ? translate('error.finalize_payment')
              : isPaymentFailed
              ? translate('error.try_again')
              : translate('common.ok'),
            style: `px-24 py-8 w-full text-base font-regular text-white100`,
            normalstyle: btnClass,
            classname: 'w-full mt-0 4xl:h-[71px] 5xl:h-[95.55px] 4xl:text-[28px] 5xl:text-[38.22px] 8xl:text-[85.33px] 8xl:h-[213.33px]',
            onclick: closeModal,
          }}
          icon={{
            iconid: IconNames.iconErrorFill,
            bh: isPageLeave ? 0 : 80,
            bw: isPageLeave ? 0 : 80,
            classname: `hidden fill-white ${isPageLeave ? 'invisible' : ''}`,
          }}
          close={closeModal}
        />
      </Modal>
      <div id="card-form-container" className="opacity-5">
        {creditCardForm && parse(creditCardForm)}
      </div>
      <Modal open={openVerifySTCPayModal}>
        <STCPayVerificationScreen
          resend={resendSTCPayOTP}
          mobileNumber={paymentMobileNumber}
          isHeaderDark={isHeaderDark}
          dir={dir}
          verify={verifySTCPay}
          otp={stcpayOTP}
          updateOTP={handleChangeVerifyOTP}
          errorMessage={verifyOTPError}
          close={() => handleSTCPayCloseModal()}
        />
      </Modal>
      <Modal open={isOpenContinueGuest}>
        <ContinueAsGuest
          isHeaderDark={isHeaderDark}
          dir={dir}
          handleClose={handleContinueGuestPopupClose}
          processCreateInvoiceForGuest={processCreateInvoiceForGuest}
        />
      </Modal>
      <Modal open={isCartPageAvailableModalOpen}>
        <CartPageProductAvailabilityModal
          handleAction={handleCartProductAvailability}
          unavailableProducts={unavailableProducts}
          action={outOfStockAction}
        />
      </Modal>
      <LoadingIndicator open={showLoadingIndicator || isProcessing} width={80} height={80} strokeWidth={2} color="#E95F2A" />
    </>
  );
};

export default CartPage;
