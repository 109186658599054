import { PAGE_DESCRIPTION, PAGE_KEYWORDS, PAGE_TITLE, TxKeyPath } from '@fe-monorepo/helper';
import { BGType, useThemedBackground, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import BreadcrumbsLayout from '@fe-web/Organisms/BreadcrumbsLayout';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { AppRoutes } from '../../../app.routes.enum';
import mixpanelHelper from '../../../helpers/mixpanelHelper';
import GuestMatchmaking from './components/GuestMatchmaking';
import LoggedInMatchmaking from './components/LoggedInMatchmaking';

const Matchmaking = () => {
  const { isLoggedIn } = useUserProfile();
  const { translate } = useTranslate();
  const isMobile = useMobileDetect();
  const themeConfig = useSelector((state: RootState) => state.app.themeConfig);
  const { backgroundImage } = useThemedBackground(BGType.landing, isMobile);

  const BREAD_CRUMBS_LIST = [
    {
      pageName: translate('home') ?? '',
      route: AppRoutes.home,
    },
    {
      pageName: translate('play_nav') ?? '',
      route: AppRoutes.games,
    },
    {
      pageName: translate('top_tab_matchmaking') ?? '',
      route: AppRoutes.matchmaking,
    },
  ];

  useEffect(() => {
    mixpanelHelper.trackPageView('PLAY - MATCHMAKING TAB', 'play_page_matchmaking_tab', window.location.pathname, document.referrer);
    brazeHelper.trackPageView('PLAY - MATCHMAKING TAB', 'play_page_matchmaking_tab', window.location.pathname, document.referrer);
  }, []);

  return (
    <>
      <CustomHelmet
        pageTitle={translate(PAGE_TITLE.matchmaking as TxKeyPath) ?? ''}
        metaDescription={translate(PAGE_DESCRIPTION.matchmaking as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.matchmaking as TxKeyPath) ?? ''}
      />
      <BreadcrumbsLayout list={BREAD_CRUMBS_LIST}>
        <div
          className="mb-[6.5rem] bg-contain bg-no-repeat 4K:bg-cover"
          style={themeConfig ? { backgroundImage: `url(${backgroundImage})` } : {}}
        >
          {isLoggedIn ? (
            <div className="mdMaxxSS:flex mdMaxxSS:flex-col ml-[1.25rem] mr-[1.25rem] xl:mx-[7.5rem]">
              <LoggedInMatchmaking />
            </div>
          ) : (
            <div className="flex flex-col w-full items-center px-[1.25rem]">
              <GuestMatchmaking />
            </div>
          )}
        </div>
      </BreadcrumbsLayout>
    </>
  );
};

export default Matchmaking;
