import { logCustomEvent } from '@braze/web-sdk';
import { IMAGES } from '@fe-monorepo/assets';
import { BrazeCustomEvents, IconNames, MixPanelEvents } from '@fe-monorepo/helper';
import { useCart, useInvoice, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { InvoiceProduct } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import EmptyCart from '@fe-web/Atoms/Empty/EmptyCart';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { Popover, Transition } from '@headlessui/react';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { trackCartEvent } from '../../../../../helper/webAnalytics';
import { AppRoutes } from '../../../app.routes.enum';
import usePageLayout from '../../../hooks/usePageLayout';
import Button from '../../Atoms/Buttons/Button';
import CartItemComponent from './CartItem';

type DiffCartObject = { [key: string]: InvoiceProduct };
const getCartChange = (previousProducts: InvoiceProduct[], newProducts: InvoiceProduct[]) => {
  const createSimplifiedProductsObj = (products: InvoiceProduct[]) => {
    const productsObj: DiffCartObject = {};
    products?.forEach(product => {
      productsObj['' + product.variant_id] = product;
    });
    return productsObj;
  };

  const previousProductsObj = createSimplifiedProductsObj(previousProducts);
  const newProductsObj = createSimplifiedProductsObj(newProducts);

  const getDiff = (cartA: DiffCartObject, cartB: DiffCartObject) => {
    const diff: DiffCartObject = {};
    for (const key in cartA) {
      const productA = cartA[key];
      const productB = cartB[key];
      if (!productB) {
        diff[key] = productA;
      } else if (productB.qty !== productA.qty) {
        diff[key] = {
          ...productA,
          grand_total: Math.abs(productB.grand_total - productA.grand_total),
          discount_price: Math.abs(productB.discount_price - productA.discount_price),
          qty: Math.abs(productB.qty - productA.qty),
          subtotal: Math.abs(productB.subtotal - productA.subtotal),
        };
      }
    }
    return diff;
  };
  const diffA = getDiff(newProductsObj, previousProductsObj);
  const diffB = getDiff(previousProductsObj, newProductsObj);
  const diff: DiffCartObject = { ...diffA, ...diffB };
  return Object.values(diff);
};

const useCartDiffDetect = (totalProductCount: number, products: InvoiceProduct[]) => {
  const previousCart = useRef<{ cartSize: number; products: InvoiceProduct[] }>({
    cartSize: totalProductCount ?? 0,
    products: products ?? [],
  });

  useEffect(() => {
    if (previousCart.current.cartSize === totalProductCount) return;
    const diffArr = getCartChange(previousCart.current.products, products ?? []);
    if (diffArr.length > 0) {
      const grandTotal = diffArr.reduce((accumulator, product) => accumulator + product.grand_total, 0);
      const eventName = totalProductCount > previousCart.current.cartSize ? 'add_to_cart' : 'remove_from_cart';
      trackCartEvent(eventName, grandTotal, diffArr);
    }
    previousCart.current = {
      cartSize: totalProductCount ?? 0,
      products: products ?? [],
    };
  }, [totalProductCount]);
};

const MiniCart = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { translate } = useTranslate();
  const { btnClass, language } = usePageLayout();
  const { increase, decrease, subtotal, total, remove, totalProductCount, itemsInCart, productError, setProductError } = useCart();

  const navigate = useNavigate();
  const ref = useRef<HTMLDivElement>(null);
  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  const { invoiceInfo } = useInvoice(true);
  const { isLoggedIn } = useUserProfile();
  const products_length = useMemo(
    () => (isLoggedIn || (!isLoggedIn && invoiceInfo) ? invoiceInfo?.products?.length : itemsInCart.length),
    [isLoggedIn, itemsInCart, invoiceInfo?.products],
  );
  const products = useMemo(
    () => (isLoggedIn || (!isLoggedIn && invoiceInfo) ? invoiceInfo?.products : itemsInCart),
    [isLoggedIn, invoiceInfo?.products, itemsInCart],
  );

  const itemsCartComponent =
    products &&
    products.map(item => (
      <CartItemComponent
        key={item.product_code}
        product={item}
        language={language}
        incrementItem={increase}
        decrementItem={decrease}
        removeItem={remove}
        isRTL={isRTL}
      />
    ));

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (ref.current && !ref.current?.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  useCartDiffDetect(totalProductCount, products ?? []);

  useEffect(() => {
    if (isOpen) {
      trackCartEvent('view_cart', total, products ?? []);
    }
  }, [isOpen]);

  const handleNavigateToCart = () => {
    mixpanelHelper.trackEvent(MixPanelEvents.cartViewed, {
      Referrer: window.location.href,
    });
    logCustomEvent(BrazeCustomEvents.CartViewed, {
      Referrer: window.location.href,
    });
    navigate(AppRoutes.cart);
  };

  useEffect(() => {
    if (productError) {
      mixpanelHelper.trackEvent(MixPanelEvents.cartError, {
        'Error Code': productError.errorCode,
        'Error Message': productError.errorMessage,
        'Product Code': productError.productCode,
      });
      logCustomEvent(BrazeCustomEvents.CartError, {
        'Error Code': productError.errorCode,
        'Error Message': productError.errorMessage,
        'Product Code': productError.productCode,
      });
      setProductError(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productError]);

  return (
    <div className="flex flex-nowrap gap-20 4xl:gap-36 5xl:gap-54 8xl:gap-100 items-center mt-[5px]" ref={ref}>
      <div className="md:block">
        <div className="w-full max-w-sm px-4 4xl:px-8 5xl:px-[10.66px] 8xl:px-[21.33px]">
          <Popover className="relative">
            <Popover.Button
              className="inline-flex items-center rounded-md group focus:outline-none"
              onClick={() => {
                setIsOpen(!isOpen);
              }}
            >
              <div>
                <ResponsiveIcon
                  className={`
                    ${isOpen ? 'fill-secondary' : 'fill-secondary/50'}
                    cursor-pointer
                    hover:fill-secondary/100
                    xsMax:fill-secondary/70
                    animate__animated animate__fadeIn
                  `}
                  name={IconNames.shoppingCart}
                  baseWidth={20}
                  baseHeight={20}
                />
                {products_length && products_length > 0 ? (
                  <span
                    className="
                      absolute bottom-[0.75rem] left-[0.75rem]
                      rounded-[1.875rem] 
                      w-[1.5rem] text-fs-body-small
                      bg-red text-white100 text-secondary font-regular"
                  >
                    {totalProductCount}
                  </span>
                ) : null}
              </div>
            </Popover.Button>
            <Transition
              as={Fragment}
              show={isOpen}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel
                static
                className="
                  absolute
                  origin-top-left z-20
                  mt-[3px] 4xl:mt-[5.33px] 5xl:mt-8 8xl:mt-16
                  w-[282px]
                  min-w-full
                  max-w-sm
                  transform
                  2K:w-[520px] 4K:w-[720px] 8K:w-[1620px]
                  ltr:right-[-100%] rtl:left-[-100%]
                  px-[1.5rem] py-[1rem] sm:px-0"
              >
                <div
                  className="
                    overflow-y-auto transition-all duration-300
                    rounded-[2px] 4xl:rounded-[3.55px] 5xl:rounded-[5.33px] 8xl:rounded-[10.66px]
                    border border-solid
                    border-secondary/20
                    focus:outline-none bg-primary relative
                    border-[1px]
                    rounded-[4px]
                    border-black20
                    2K:border-[2px]
                    2K:rounded-[8px]
                    4K:border-[3px]
                    4K:rounded-[16px]
                    8K:border-[5px]
                    8K:rounded-[32px]"
                >
                  <div
                    className="
                      gap-8 4xl:gap-12 5xl:gap-20 8xl:gap-44
                      p-16 4xl:p-[28px] 5xl:p-[42px] 8xl:p-81
                      flex flex-col relative bg-primary"
                  >
                    {!products || !products.length ? (
                      <EmptyCart text={translate('shop.cart.mini_cart_empty_header')} isMiniCart image={IMAGES.CartNoItem.toString()} />
                    ) : (
                      <div>
                        <div className="grid gap-16 2K:gap-[36px] 4K:gap-[48px] 8K:gap-[88px]">
                          <div className="text-lg font-medium flex-nowrap text-secondary">
                            {`${translate('shop.cart.cart_title')}`}
                            <span className="text-lg font-normal text-secondary/50">{` (${totalProductCount})`}</span>
                          </div>
                          <div className="grid gap-16 max-h-[28rem] 8K:max-h-[32rem] overflow-y-auto">{itemsCartComponent}</div>
                          <div className="flex grid flex-col grid-cols-2">
                            <div className="text-fs-body font-regular text-secondary/[0.5]"> {`${translate('shop_subTotal')}:`} </div>

                            <CurrencyText
                              className={`${isRTL ? 'text-left' : 'text-right'} text-secondary font-medium`}
                              currency={subtotal}
                            />
                          </div>
                          <div className="w-full">
                            <Popover.Button className="w-full">
                              <Button
                                text={translate('shop.cart.mini_cart_view_btn') ?? ''}
                                className="w-full py-8 px-24 2K:py-[0.475rem] 8K:py-[0.675rem] text-fs-body font-medium text-white100 hover:border-[1px] hover:border-sunset"
                                normalStyle={btnClass}
                                onClick={handleNavigateToCart}
                              />
                            </Popover.Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </Popover>
        </div>
      </div>
    </div>
  );
};

export default MiniCart;
