import { gql } from '@apollo/client';

export const JOIN_MUTATION = {
  joinTournament: gql`
    mutation joinTournament($details: JoinInput) {
      joinTournament(details: $details) {
        is_successful
        error_msg
        error_code
        app_code
      }
    }
  `,
};
