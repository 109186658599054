import { gql } from '@apollo/client';

export const COLLECTION_PRODUCTS_QUERY = {
  getAllProducts: gql`
    query fetchAllProducts($details: ProductCollectionInput) {
      getAllProducts(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          count
          list {
            product_code
            name_en
            name_ar
            featured_img
            metadata
            lowest_actual_cost
            highest_actual_cost
            highest_selling_price
            lowest_selling_price
            lastCursor
            last_cursor
            description_en
            description_ar
            brand {
              name_en
              name_ar
              collection_code
            }
          }
          banner_info {
            reference_type
            reference_value
            web_media_url_ar
            web_media_url_en
            mobile_media_url_ar
            mobile_media_url_en
            name_en
            name_ar
            banner_type
            carousel_type
            expire_on
          }
        }
      }
    }
  `,
  getAllBrands: gql`
    query fetchAllBrands($details: BrandCollectionInput) {
      getAllBrands(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          name_en
          name_ar
          brand_code
        }
      }
    }
  `,
};
