import { gql } from '@apollo/client';

export const NOTIFICATION_MESSAGE_QUERY = {
  notificationMessageGetAll: gql`
    query notificationMessageGetAll($details: NotificationMessageGetAllInput) {
      notificationMessageGetAll(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          total
          cursor
          data {
            id
            msg_id
            title
            body_en
            body_ar
            bodyHTML_en
            bodyHTML_ar
            image
            event_type
            is_read
            created
            notification_type
            conversation_id
            conversation_type
            type
            deeplink_scheme
            avatar_url
            conversation_message
            conversation_name
            invoice_number
            shipment_number
            return_number
            collection_code
            product_code
            mini_game_code
            tournament_id
            username
            currentTime
            order_id
            cursor
            media_url
            sender_username
            image_url
            is_following
            follower_username
            follower_display_name
            follower_notification_type
            game_code
            title_en
            title_ar
            opponent_display_name
            asset_id
            asset_type
            comment_parent_id
            comment_reply_id
            video_thumbnail
            message
            description
          }
        }
      }
    }
  `,
};
