import { gql } from '@apollo/client';

export const REDEEMGIFTCARD_MUTATION = {
  redeemGiftCard: gql`
    mutation redeemGiftCard($details: RedeemGiftCardParams) {
      redeemGiftCard(details: $details) {
        is_successful
        error_msg
        error_code
      }
    }
  `,
};
