import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import { Formik } from 'formik';
import { useState } from 'react';
import styled from 'styled-components';

interface StcWalletProps {
  amount: number | undefined;
  amountToUse: number | undefined;
  onApply: (amount: number) => void;
  onRemove: () => void;
  balance: number;
  applyLabel?: string;
  removeLabel?: string;
  title?: string;
  balanceText?: string;
  amountRemaining: number;
  amountToUseFormat?: string;
  amountRemainingFormat?: string;
  amountPlaceholderLabel?: string;
  notEnoughFundsMessage?: string;
  remainingBalanceMessage?: string;
  totalPrice?: number;
  greaterAmountThanTotalMessage?: string;
}

const StyledInput = styled.input`
  -moz-appearance: textfield;
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StcWallet = ({
  balance,
  amountToUse,
  onApply,
  onRemove,
  title,
  applyLabel,
  amountRemaining,
  removeLabel,
  amountPlaceholderLabel,
  notEnoughFundsMessage,
  remainingBalanceMessage,
  totalPrice,
  greaterAmountThanTotalMessage,
}: StcWalletProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { translate } = useTranslate();
  return (
    <div className="flex flex-col gap-y-[1rem] border border-secondary/[0.2] rounded-[4px] py-[1.25rem] px-[1rem] 8K:py-[1rem] 8K:px-[1rem]">
      <div className="flex items-center justify-between">
        <div className="inline-flex items-center gap-x-[0.5rem] text-secondary font-regular text-fs-body-small 8K:gap-x-[.5rem]">
          <ResponsiveIcon name={IconNames.stcPlay} baseWidth={48} baseHeight={20} className="fill-purple" /> {title ?? 'Wallet'}{' '}
          {balance && <CurrencyText className="text-secondary/[0.5]" currency={balance} />}
        </div>
        <button type="button" onClick={() => setIsOpen(() => !isOpen)}>
          <ResponsiveIcon name={isOpen ? IconNames.minus2 : IconNames.add} baseWidth={16} baseHeight={16} className="fill-sunset" />
        </button>
      </div>
      {isOpen && !amountToUse && (
        <Formik
          initialValues={{ amount: undefined }}
          onSubmit={(values, { resetForm }) => {
            onApply(values.amount ?? 0);
            resetForm();
          }}
          validate={values => {
            const errors: Partial<{ amount: string }> = {};
            if (values.amount && values.amount > balance) {
              errors.amount = notEnoughFundsMessage || (translate('error.notEnoughtFunds') as string);
            } else if (values.amount && totalPrice && values.amount > totalPrice) {
              errors.amount = greaterAmountThanTotalMessage || 'Wallet amount applied is greater than total';
            }
            return errors;
          }}
        >
          {({ handleSubmit, values, handleChange, handleBlur, errors }) => (
            <form className="flex flex-col gap-y-[11px]" onSubmit={handleSubmit}>
              <div className="flex gap-16">
                <StyledInput
                  type="number"
                  name="amount"
                  step=".01"
                  min={0}
                  max={balance}
                  value={values.amount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder={amountPlaceholderLabel ?? 'Enter amount'}
                  className="pb-8 flex-1 bg-transparent min-w-0 outline-0 font-regular text-fs-body-large text-static-secondary border-b border-secondary/[0.2] placeholder:text-secondary/[0.5]"
                />
                <button type="submit" className="font-regular text-fs-body-small text-sunset">
                  {applyLabel ?? 'Apply'}
                </button>
              </div>
              {errors?.amount ? <span className="font-regular text-fs-body-small text-red">{errors?.amount}</span> : null}
            </form>
          )}
        </Formik>
      )}
      {isOpen && amountToUse !== 0 && (
        <div className="flex gap-x-16 font-regular justify-between">
          <div className="flex flex-col">
            <CurrencyText className="text-fs-body-small text-sunset" currency={amountToUse ?? 0} />
            <p className="text-fs-caption text-secondary/[0.5]">
              <CurrencyText currency={amountRemaining} />
              <span> {remainingBalanceMessage ?? 'left after purchase'}</span>
            </p>
          </div>
          <button type="button" className="text-fs-body-small text-sunset" onClick={onRemove}>
            {removeLabel ?? 'Remove'}
          </button>
        </div>
      )}
    </div>
  );
};

export default StcWallet;
