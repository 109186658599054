import { useShopInfo } from '@fe-monorepo/hooks';
import { MenuItem } from '@fe-monorepo/models';
import { motion } from 'framer-motion';
import { Fragment, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import HoverText from '../../components/Atoms/Text/HoverText';
import Container from '../../components/Templates/Container';
import useClickOutSide from '../../hooks/useClickOutSide';
import usePageLayout from '../../hooks/usePageLayout';
import useTheme from '../../hooks/useTheme';
import TopBanner from '../Home/Sections/GenGEntryPoints/TopBanner';
import ShopMegaMenu from './ShopMegaMenu';

export type MainCategory = {
  name_en: string;
  name_ar: string;
  category_id: number;
  category_seo_code: string;
  sort_id: number;
};

interface ShopHeaderProps {
  isMegaMenuOpen: boolean;
  setIsMegaMenuOpen: (key: boolean) => void;
}

const ShopHeader = (props: ShopHeaderProps) => {
  const [choosedTab, setChoosedTab] = useState<string>('');
  const [choosedCategory, setCategory] = useState<string>('');
  const [isDuration, setIsDuration] = useState<boolean>(false);
  const [headerData, setHeaderData] = useState<MenuItem>();
  const [subCategoryData, setSubCategoryData] = useState<MenuItem>();

  const listOfLoading = Array.from({ length: 8 }, (_, i) => {
    return (
      <div className="animate-pulse space-x-[120px]">
        <div className="bg-secondary/20 rounded space-y-[120px] w-[90px] h-[10px]" />
      </div>
    );
  });

  const megeMenuRef = useRef<HTMLDivElement | null>(null);
  const tabRef = useRef<HTMLDivElement | null>(null);
  const ShopInfo = useShopInfo();
  const isClickInside = useClickOutSide(megeMenuRef, tabRef);
  const pageLayout = usePageLayout();
  const { collectionCode } = useParams();

  const language = pageLayout?.language;

  const onTabClick = (category: string) => {
    setIsDuration(true);
    setTimeout(() => props?.setIsMegaMenuOpen(true), 30);
    setChoosedTab(category);
    setCategory(category);
    isClickInside?.setIsOutSide(false);
  };

  const isClickedOutSideMegaMenu = (isOutSide: boolean) => {
    if (isOutSide) {
      setIsDuration(false);
      setTimeout(() => props?.setIsMegaMenuOpen(false), 30);
      setChoosedTab('');
    }
  };

  const actionTabHover = (category: string) => {
    setIsDuration(true);
    setTimeout(() => props?.setIsMegaMenuOpen(true), 30);

    setChoosedTab(category);
    setCategory(category);

    setSubCategoryData(
      headerData?.menu_items?.filter(item => {
        return item?.menu_code === category && item?.menu_items?.length > 0;
      })[0],
    );

    isClickInside?.setIsOutSide(false);
  };

  const actionLeaveHover = () => {
    setIsDuration(false);
    setTimeout(() => props?.setIsMegaMenuOpen(false), 30);
    isClickInside?.setIsOutSide(true);
    setChoosedTab('');
  };

  const ColorAnimation = useTheme()?.isDarkTheme ? `#FFFFFF` : `#171619`;

  useEffect(() => {
    isClickedOutSideMegaMenu(isClickInside?.isOutSide);
  }, [isClickInside?.isOutSide]);

  useEffect(() => {
    ShopInfo?.setIsLoading(true);
    ShopInfo?.getAll({ menu_code: ShopInfo?.SHOP_MENU_CODE.SHOP_PAGE });
  }, []);

  useEffect(() => {
    if (ShopInfo?.info) {
      let tempSubCategoryList: MenuItem[] = [];
      ShopInfo?.info?.data?.menu_items.forEach(menu => {
        const newList = menu.menu_items.filter(item => item.menu_items.length > 0);
        tempSubCategoryList = [...tempSubCategoryList, ...newList];
      });

      let tempCategory: MenuItem = ShopInfo?.info?.data?.menu_items?.slice()?.filter(item => {
        return item?.menu_code === 'navigation_menu';
      })[0];

      tempCategory = { ...tempCategory, menu_items: tempSubCategoryList };

      setHeaderData(tempCategory);
    }
  }, [ShopInfo?.info]);

  return (
    <header
      onMouseLeave={() => actionLeaveHover()}
      className={`text-secondary ${
        props?.isMegaMenuOpen ? `fixed` : ``
      } border-b-[0.063rem] z-[41] border-secondary/20 sticky top-[60.67px] SD:top-[72.67px] 2K:top-[112px] 4K:top-[178px] 8K:top-[350px] bg-primary font-regular w-full min-h-[48px] z-[100]`}
    >
      {!collectionCode && <TopBanner CTASource="Shop Page" />}
      {/* DO THE ANIMATION FOR THE HEADER HERE */}
      <Container className="pt-[0.188rem]">
        <div ref={tabRef} className="flex items-center gap-[4rem] overflow-x-scroll py-[0.75rem]">
          {ShopInfo?.isLoading
            ? listOfLoading?.map((item, index) => {
                return <Fragment key={index}>{item}</Fragment>;
              })
            : headerData?.menu_items?.map((tab, index) => {
                return (
                  <div
                    className="relative"
                    key={index}
                    onMouseEnter={() => actionTabHover(tab?.menu_code)}
                    onClick={() => onTabClick(tab?.menu_code)}
                  >
                    <HoverText
                      className={`font-regular text-fs-body-small whitespace-nowrap ${
                        choosedTab === tab?.name_en ? 'text-secondary' : 'text-secondary/70'
                      }`}
                      hover={{ color: 'text-secondary' }}
                      alwaysShowUnderline={false}
                      underlineStyle=""
                      text={language === `en` ? tab?.name_en : tab?.name_ar}
                    />
                    <motion.div
                      initial={{ width: `0px` }}
                      animate={{
                        backgroundColor: choosedTab === tab?.name_en ? [`#E95F2A`, `#E95F2A`, ColorAnimation] : ColorAnimation,
                        width: choosedTab === tab?.name_en ? `100%` : `0%`,
                      }}
                      transition={{ duration: 0.3 }}
                      className="relative bg-secondary h-[2px] w-full bottom-[-12px] 4xl:h-[5px] 4xl:bottom-[-44px] 8K:h-[10px] 8K:bottom-[-85px] duration-[0.3]"
                    />
                  </div>
                );
              })}
        </div>
      </Container>
      <ShopMegaMenu
        megeMenuRef={megeMenuRef}
        isDuration={isDuration}
        category={choosedCategory}
        megaMenuList={subCategoryData}
        mainCategoryId={choosedCategory}
        isMegaMenuOpen={props?.isMegaMenuOpen}
        actionLeaveHover={actionLeaveHover}
        isClickedOutSideMegaMenu={isClickedOutSideMegaMenu}
      />
    </header>
  );
};

export default ShopHeader;
