import { gql } from '@apollo/client';

export const CHAT_SEARCH_QUERIES = {
  getSearch: gql`
    query getSearch($details: GetSearchInput) {
      getSearch(details: $details) {
        is_successful
        error_msg
        error_code
        app_code
        data {
          username
          display_name
          avatar_url
          last_cursor
        }
      }
    }
  `,
};
