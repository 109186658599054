import { convertPriceWithSymbol } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import React, { useEffect, useState } from 'react';

interface PriceRangePickerProps {
  min: number;
  max: number;
  onRangeChange: (min: number, max: number) => void;
  filterByPrice?: (min: number, max: number) => void;
}

const PriceRangePicker: React.FC<PriceRangePickerProps> = ({ min, max, onRangeChange, filterByPrice }) => {
  const { translate } = useTranslate();
  const [selectedMin, setSelectedMin] = useState(min);
  const [selectedMax, setSelectedMax] = useState(max);

  const handleMinChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    setSelectedMin(newValue);
  };

  const handleMaxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseFloat(event.target.value);
    setSelectedMax(newValue);
  };

  useEffect(() => {
    if ((selectedMin === 0 && selectedMax === 0) || selectedMin > selectedMax) {
      return;
    }
    const changeNumber = setTimeout(() => {
      if (selectedMin >= 0 && selectedMax >= 0) {
        onRangeChange(selectedMin, selectedMax);
      }
    }, 1000);
    return () => clearTimeout(changeNumber);
  }, [selectedMin, selectedMax, filterByPrice]);

  return (
    <div className={`flex flex-row items-center self-stretch rounded-[4px] gap-[1.5rem] mix-blend-normal`}>
      <div>
        <label htmlFor="price" className="price-range__title text-secondary text-fs-body-small font-medium">
          {translate('price')}
        </label>
      </div>
      <div className="flex flex-row flex-end  SD:justify-start justify-between w-full gap-[.5rem]">
        <div className="flex flex-row items-center justify-center rounded-[2px] px-3 py-[.5rem] border 2K:border-2 4k:border-4 8K:border-8 border-neutral-300-zinc-700">
          <label htmlFor="min" className="text-fs-body-small w-[57px] text-center text-secondary flex items-center font-regular">
            {translate('min')}
          </label>
          <input
            type="number"
            className="text-end bg-transparent 2K:w-[94px] 4K:w-[4.375rem] w-[85px] mdMaxLl:w-[54px] text-fs-body-small outline-none text-secondary"
            placeholder={'' + convertPriceWithSymbol(0, 'none')}
            id="min"
            onChange={handleMinChange}
          />
        </div>
        <div className="flex flex-row items-center justify-center rounded-[2px] px-3 py-[.5rem] border 2K:border-2 4k:border-4 8K:border-8 border-neutral-300-zinc-700">
          <label htmlFor="max" className="text-fs-body-small w-[57px] text-center text-secondary flex items-center font-regular">
            {translate('max')}
          </label>
          <input
            type="number"
            className="text-end bg-transparent mdMaxLl:w-[54px] text-fs-body-small 2K:w-[94px] 4K:w-[4.375rem] w-[85px] outline-none text-secondary "
            id="max"
            placeholder={'' + convertPriceWithSymbol(0, 'none')}
            onChange={handleMaxChange}
          />
        </div>
      </div>
    </div>
  );
};

export default PriceRangePicker;
