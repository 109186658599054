import { useLazyQuery, useMutation } from '@apollo/client';
import { GENG_CHECKUSER_MUTATION, GENG_SUBMIT_FORM_MUATTION, GET_COURSES_QUERY, GET_QUESTIONS_QUERY } from '@fe-monorepo/data-access';
import { CheckUserGenGInput, GenGUserFormInput, QuestionGenGInput } from '@fe-monorepo/models';

export const useGenG = () => {
  const [getCoursesGql] = useLazyQuery(GET_COURSES_QUERY.GetCourses, {
    errorPolicy: 'all',
  });

  const [getQuestionsGql] = useLazyQuery(GET_QUESTIONS_QUERY.GetQuestions, {
    errorPolicy: 'all',
  });

  const [checkUserGql] = useMutation(GENG_CHECKUSER_MUTATION.CheckUser, {
    errorPolicy: 'all',
  });

  const [submitFormGengGql] = useMutation(GENG_SUBMIT_FORM_MUATTION.SubmitGenGForm, {
    errorPolicy: 'all',
  });

  const getAllGenGCourses = async () => {
    const { data } = await getCoursesGql();
    return data?.GetCourses;
  };

  const getAllGenGQuestions = async (body: QuestionGenGInput) => {
    const { data } = await getQuestionsGql({
      variables: { details: body },
    });
    return data?.GetQuestions;
  };
  const checkUserGenG = async (body: CheckUserGenGInput) => {
    const { data } = await checkUserGql({
      variables: { details: body },
    });
    return data?.CheckUser;
  };

  const submitFormGeng = async (body: GenGUserFormInput) => {
    const { data } = await submitFormGengGql({
      variables: { details: body },
    });
    return data?.SubmitGenGForm;
  };

  return {
    getAllGenGCourses,
    getAllGenGQuestions,
    checkUserGenG,
    submitFormGeng,
  };
};
