import { gql } from '@apollo/client';

export const TOURNAMENT_INFO_QUERY = {
  getTournamentInfo: gql`
    query getTournamentInfo($details: TmsDataGetInfoParams) {
      getTournamentInfo(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          id
          discipline
          name
          full_name
          description
          rules
          status
          participant_type
          organization
          contact
          discord
          website
          online
          location
          country
          size
          prize
          scheduled_date_start
          scheduled_date_end
          timezone
          public
          logo {
            logo_small
            logo_medium
            logo_large
            original
            id
          }
          background
          platforms
          featured
          archived
          match_report_enabled
          registration_enabled
          registration_auto_accept_enabled
          registration_opening_datetime
          registration_closing_datetime
          check_in_enabled
          check_in_participant_enabled
          check_in_participant_start_datetime
          check_in_participant_end_datetime
          circuit
          circuit_season
          circuit_region
          circuit_tier
          registration_notification_enabled
          registration_participant_email_enabled
          registration_request_message
          registration_acceptance_message
          registration_refusal_message
          registration_terms_enabled
          registration_terms_url
          registration_permanent_team_mandatory
          team_min_size
          team_max_size
        }
      }
    }
  `,
};

export const TOURNAMENT_QUERY = {
  getTournamentInfo: gql`
    query getTournamentInfo($details: TmsDataGetInfoInput) {
      getTournamentInfo(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          tournament_id
          name
          status_code
          mode_code
          reference_bracket_code
          reference_game_code
          current_round
          total_rounds
          cash_prize_total_amount
          registration_start_date
          registration_end_date
          tournament_start_date
          check_in_date
          total_number_of_participants
          max_number_of_participants
          description
          rules
          swiss_total_rounds
          royale_total_rounds
          banner_url
          stream_url_twitch
          stream_url_youtube
          stream_url_stcplay
          stream_url_stc_play
          is_seeded
          is_self_reporting_allowed
          is_join_approval_required
          is_team_based
          is_cash_prize_required
          is_check_in_required
          is_auto_advance_enabled
          created_at
          minimum_team_size
          maximum_team_size
          last_reminder
          match_update
          is_featured
          tournament_end_date
          registration_fee
          hosted_by
          is_live
          team_size
          prize_details
          email
          mobile
          mobile_code
          is_participant
          is_approved
          is_checked_in
          is_waitlist
          prizes
          platforms
          organizer_info {
            username
            display_name
            avatar_url
            is_official_account
          }
          referees_list {
            username
            display_name
            avatar_url
            is_official_account
          }
          reference_bracket_name_en
          reference_bracket_name_ar
          status_name_en
          status_name_ar
        }
      }
    }
  `,
  getUserTournaments: gql`
    query getUserTournaments($details: UserTournamentsInput) {
      getUserTournaments(details: $details) {
        data {
          acitve_tournamanets {
            id
            discipline
            name
            full_name
            status
            scheduled_date_start
            scheduled_date_end
            timezone

            online
            public

            location
            country
            size
            platforms

            logo {
              logo_small
              logo_medium
              logo_large
              original
              id
            }

            registration_enabled

            registration_opening_datetime
            registration_closing_datetime
          }

          ended_tournaments {
            id
            discipline
            name
            full_name
            status
            scheduled_date_start
            scheduled_date_end
            timezone

            online
            public

            location
            country
            size
            platforms

            logo {
              logo_small
              logo_medium
              logo_large
              original
              id
            }

            registration_enabled

            registration_opening_datetime
            registration_closing_datetime
          }
        }
        error_msg
        error_code
        is_successful
      }
    }
  `,
};
