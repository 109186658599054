import usePageLayout from '@fe-web/hooks/usePageLayout';
import { Fragment } from 'react';

import ProductDetail from '../../../../../../../lib/types/productDetailedPage';

type ProductVariantsProps = {
  product: ProductDetail;
  variants: number[];
  setVaraints: (index: number, number: number) => void;
};

export default function ProductVariants(props: ProductVariantsProps) {
  const lang = usePageLayout()?.language;

  return (
    <div>
      {props?.product?.variants?.map((variant, index) => {
        return (
          <Fragment key={index}>
            {variant && (
              <div className="flex flex-nowrap text-secondary gap-12 2K:gap-24 8K:gap-48 py-12 2K:py-20 4K:py-32 8K:py-64 text-bodySmall 2K:text-[24.88px]/[35.55px] 4K:text-[37.33px]/[53.33px] 8K:text-[74.66px]/[106.66px]">
                <div className="flex gap-12 mt-6 2K:gap-24 8K:gap-48 4K:mt-12 8K:mt-16">
                  <p className="font-medium whitespace-nowrap">{lang === 'en' ? variant?.name_en : variant?.name_ar}</p>
                  <p>•</p>
                </div>
                <div className="flex flex-wrap gap-12 4K:gap-24 8K:gap-48">
                  {variant?.values?.map((value, variantIndex) => {
                    return (
                      <button
                        className={`cursor-pointer text-secondary/70 ${
                          value?.product_option_value_id === props?.variants[index] ? `border-sunset bg-sunset/10` : `border-secondary/20`
                        } p-6 4K:p-12 8K:p-24 border-[1px] 2K:border-[2px] 4K:border-[3px] 8K:border-[5px] rounded 8K:rounded-lg whitespace-nowrap`}
                        key={variantIndex}
                        onClick={() => props?.setVaraints(index, value?.product_option_value_id)}
                      >
                        {lang === 'en' ? value?.name_en : value?.name_ar}
                      </button>
                    );
                  })}
                </div>
              </div>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}
