import { gql } from '@apollo/client';

const TMS_STAGE_QUERY = {
  tmsStageGetAll: gql`
    query tmsStageGetAll($details: TmsTournamentStageGetAllInput) {
      tmsStageGetAll(details: $details) {
        is_successful
        error_code
        error_msg
        app_code
        data {
          id
          tournament_id
          number
          name
          type
          closed
          auto_placement_enabled
          settings {
            size
            third_decider
            threshold
          }
          match_settings {
            format {
              type
              options {
                calculation
              }
            }
          }
        }
      }
    }
  `,
};

export { TMS_STAGE_QUERY };
