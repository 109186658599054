import { InputFieldStyle } from '../../components/Molecules/InputFields/CombinedInputField';

export const authInputFieldStyleObj: InputFieldStyle = {
  containerStyle: 'mb-20 2K:mb-36 4K:mb-54 8K:mb-100',
  inputStyle: `
      my-8 2K:my-12 4K:my-20 8K:my-44
      h-24 2K:h-40 4K:h-64 8K:h-120
      text-secondary
      font-regular
      responsive-text-bodyLarge
      placeholder:responsive-text-bodyLarge
      placeholder-secondary/50
  `,
  underlineStyle: 'h-[1px] 4xl:h-[1.77px] 5xl:h-[2.66px] 8xl:h-[5.33px]',
};
