import { useState, useEffect } from 'react';
import { PARTICIPANTS_QUERY } from '@fe-monorepo/data-access';
import { useLazyQuery } from '@apollo/client';
import { GetRegistrationInfoParams, GetRegistrationInfoResponse, GetRegInfo } from './types';

export const useRegistrationInfo = () => {
    const [isSuccessful, setStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>('');

    const [getRegistrationInfoGql, { data: infoDataResult, loading: loadingGetRegisteration }] = useLazyQuery<GetRegistrationInfoResponse>(
        PARTICIPANTS_QUERY.registrationInfo,
        {
            errorPolicy: 'all',
        },
    );

    const getRegistrationInfo = async (infoTournamentResult: GetRegistrationInfoParams) => {
        const { data } = await getRegistrationInfoGql({
            variables: {
                details: infoTournamentResult,
            },
        });
        return data?.registrationInfo;
    };

    useEffect(() => {
        if (infoDataResult) {
            if (infoDataResult?.registrationInfo?.is_successful) {
                setStatus(infoDataResult?.registrationInfo?.is_successful);
            }
            if (infoDataResult?.registrationInfo?.error_msg) {
                setErrorMessage(infoDataResult?.registrationInfo?.error_msg);
            }
            setStatus(infoDataResult?.registrationInfo?.is_successful);
        }
        setIsLoading(false);
    }, [infoDataResult]);

    return { getRegistrationInfo, regInfoData: infoDataResult?.registrationInfo, isSuccessful, isLoading, errorMessage, loadingGetRegisteration };
};
