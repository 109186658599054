import LoaderDark from '../../../common/LoaderDark/LoaderDark';
import LikeIcon from '../../Icons/Like';
import UnLikeIcon from '../../Icons/UnLike';
import { formatLargeNumber } from '../../helpers';

export default function LikeButton({ video, onClick, isLikingVideo, videoLikeCount, isVideoLiked }: any) {
  const { videoId } = video ?? {};

  //   const { isVideoLiked, isLoading: isCheckingForLikedVideo } = useLikedVideo(videoId);

  return (
    (video?.likeEnabled || video?.likeEnabled === true) && (
      <button className="like_icon" title="I like this" disabled={isLikingVideo} onClick={e => onClick(e, video.videoId)}>
        {isLikingVideo ? <LoaderDark /> : <div className="icon">{isVideoLiked ? <LikeIcon /> : <UnLikeIcon />}</div>}
        <div className={`icon-count like-count-${video.videoId}`} id={`like-count-${video?.videoId}`}>
          {formatLargeNumber(videoLikeCount || 0)}
        </div>
      </button>
    )
  );
}
