import React from "react";

function ZoomIn() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 21V13.5H4.5V18.45L18.45 4.5H13.5V3H21V10.5H19.5V5.55L5.55 19.5H10.5V21H3Z"
        fill="white"
      />
    </svg>
  );
}

export default ZoomIn;
