import { IconNames, ModerationStatuses } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import LazyImage from '@fe-web/Atoms/Media/LazyImage';
import ModerationStatus from '@fe-web/Atoms/ModerationStatus/ModerationStatus';
import { useSelector } from 'react-redux';

interface BitsModerationDetailsProps {
  goBack: () => void;
}

const BitsModerationDetails = (props: BitsModerationDetailsProps & any) => {
  const { time, image, body, title, asset_title, status, goBack } = props;
  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  const { translate } = useTranslate();

  return (
    <div className="flex flex-col px-[1.25rem]">
      <div className="text-otherGray">{time}</div>
      {[ModerationStatuses.reject, ModerationStatuses.rejected].includes(status) ? (
        <div className="relative mt-[3.5rem] mx-auto w-[10rem] h-[17.75rem] aspect-[9/16] rounded-[0.5rem] bg-black">
          <ModerationStatus status={status} className="text-white100" />
        </div>
      ) : (
        <LazyImage
          img={image}
          alt={asset_title}
          imgStyle={`w-[10rem] h-[17.75rem] aspect-[9/16] object-cover object-center rounded-[0.5rem]${
            [ModerationStatuses.pending, ModerationStatuses.pending1].includes(status) ? ' grayscale' : ''
          }`}
          divStyle="relative mt-[3.5rem] mx-auto"
        >
          <ModerationStatus status={status} />
        </LazyImage>
      )}
      <div className="text-secondary w-[10rem] mx-auto text-body">{asset_title}</div>
      <div className="mt-[1.25rem] w-[10rem] mx-auto text-center text-[1.2rem]">{title}</div>
      <div className="mt-[1.25rem] w-[10rem] mx-auto text-center">{body}</div>
      <div
        className="mt-[1.25rem] mb-[4rem] flex justify-center items-center text-sunset gap-[0.5rem] cursor-pointer"
        onClick={goBack}
        dir={isRTL ? 'rtl' : 'ltr'}
      >
        <ResponsiveIcon name={IconNames.leftArrow} baseHeight={20} baseWidth={20} className="fill-sunset z-2" />
        <div>{translate('profile.bits.go_back')}</div>
      </div>
    </div>
  );
};

export default BitsModerationDetails;
