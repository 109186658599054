import { BrandData } from './ProductDetailedModel';

export enum PAYMENT_METHODS {
  payfort_redirection = 'payfort_redirection',
  apple_pay = 'apple_pay',
  stcpay = 'stcpay',
  stcplay = 'stcplay',
  tabby = 'tabby',
}

export enum PAYMENT_METHODS_LABEL {
  payfort_redirection = 'settingPage_creditCard',
  apple_pay = 'shop.cart.payment_method.payment_header_applepay',
  stcpay = 'shop.cart.payment_method.payment_header_stcpay',
  stcplay = 'shop.cart.order_summary.stc_play_wallet',
  tabby = 'shop.cart.payment_method.payment_tabby',
}

interface ResponseModel {
  is_successful: boolean;
  error_code: string;
  error_msg: string;
}
interface InitailInvoiceProduct {
  order_product_id: number;
  product_code: string;
  variant_id: number;
  product_name: string;
  description: string;
  media: string[];
  weight: number;
  SKU: string;
  sku: string;
  taxable: number;
  is_digital: number;
  is_active: number;
  in_stock: number;
  brand: BrandData;
  seller: string;
  qty: number;
  unit_price: number;
  discount_price: number;
  subtotal: number;
  total_tax: number;
  grand_total: number;
  is_delivered: number;
  unit_price_total_tax: number;
  unit_price_grand_total: number;
}

interface InvoiceProduct extends InitailInvoiceProduct {
  lowest_actual_cost?: number;
  lowest_selling_price?: number;
  promo_applied_price?: number;
}

export interface ShipmentProduct {
  serial_number?: string;
  tracking_number?: string;
  sku?: string;
  reference_shipment_physical_id?: number;
  reference_serial_value?: string;
}

interface ShipmentsModel {
  shipment_status_en: string;
  shipment_status_ar: string;
  shipment_status: string;
  reference_order_number?: string;
  created_at?: string;
  shipment_physical_id?: number;
  updated_at?: string;
  delivered_at?: string;
  product_name?: string;
  order_product_id?: string;
  reference_receipt_number?: string;
  sent_to?: string;
  is_digital?: number;
  sku?: string;
  products: ShipmentProduct[];
}

interface AppliedPromoCode {
  name: string;
  code: string;
  percentage: number;
  start_date: string;
  end_date: string;
}

interface InvoiceModel {
  order_id?: number;
  reference_user_id?: number;
  lmd_code: string;
  invoice_number: string;
  lang: string;
  status_code: string;
  payment_method_code: PAYMENT_METHODS;
  cancel_reason_code: string;
  is_paid: number;
  is_sent: number;
  is_checkout: number;
  updated_at: string;
  created_at: string;
  amount_total: number;
  promo_discount: number;
  total_amount: number;
  amount_sub_total: number;
  amount_discount: number;
  amount_tax: number;
  amount_grand_total: number;
  wallet_discount?: number;
  final_amount?: number;
  order_status_id: number;
  status_en?: string;
  status_ar?: string;
  name_en: string;
  name_ar: string;
  address_id: number;
  billing_name: string;
  billing_email: string;
  billing_mobile: string;
  billing_address: string;
  billing_postal_code: string;
  billing_longitude: string;
  billing_latitude: string;
  billing_city: string;
  billing_country_code: string;
  shipping_name: string;
  shipping_email: string;
  shipping_mobile: string;
  shipping_address: string;
  shipping_postal_code: string;
  shipping_longitude: string;
  shipping_latitude: string;
  shipping_city: string;
  shipping_country_code: string;
  fulfilment_method_code: string;
  fulfilment_digital_email: string;
  fulfilment_digital_mobile: string;
  fulfilment_digital_country_code: string;
  products: InitailInvoiceProduct[];
  shipments: [ShipmentsModel];
  applied_promo_codes?: [AppliedPromoCode];
}

interface InvoiceInfoModel {
  info: InvoiceModel;
}

interface CreateInvoiceModel {
  invoice_number: string;
}

interface OrderInvoiceInput {
  lmd_code: string;
}

interface ClearInvoiceInput {
  invoice_number: string;
  lmd_code?: string;
}

interface CreateShipmentModel {
  shipment_number: string;
}

interface OrderShipmentInput {
  invoice_number: string;
}

interface CreateInvoiceResponse extends ResponseModel {
  data: CreateInvoiceModel;
}

interface CreateShipmentResponse extends ResponseModel {
  data: CreateShipmentModel;
}

type ClearInvoiceResponse = ResponseModel;

interface InvoiceMutationResponse {
  createInvoice?: CreateInvoiceResponse;
  clearInvoice?: ClearInvoiceResponse;
  createShipment?: CreateShipmentResponse;
}

interface OrderShipmentResponse {
  createPhysical: ResponseModel;
  createDigital: ResponseModel;
}

interface CancelOrderResponse {
  cancelOrder: ResponseModel;
}

const ProductUnavailableCode = '2016';

export {
  CancelOrderResponse,
  ClearInvoiceInput,
  InitailInvoiceProduct,
  InvoiceInfoModel,
  InvoiceModel,
  InvoiceMutationResponse,
  InvoiceProduct,
  OrderInvoiceInput,
  OrderShipmentInput,
  OrderShipmentResponse,
  ProductUnavailableCode,
  ShipmentsModel,
};
