import { convertGMTToIana, getCurrentUserTimeZone } from '@fe-monorepo/helper';
import { RootState } from '@fe-monorepo/store';
import { parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { useSelector } from 'react-redux';

import { useTranslate } from './useTranslate';

export const useEWCEventStatus = (status: string, dateTime: string) => {
  const { translate } = useTranslate();
  const preferred_timezone = useSelector((state: RootState) => state.user.userContext.preferred_timezone);

  const finalTimezone = preferred_timezone ? convertGMTToIana(preferred_timezone) : getCurrentUserTimeZone();

  const parsedDate = parseISO(dateTime);
  const zonedDate = toZonedTime(parsedDate, finalTimezone);
  const currentTime = toZonedTime(new Date(), finalTimezone).getTime();
  const startTimestamp = zonedDate.getTime();

  let eventStatus = '';
  let eventStatusText = '';
  const seventyTwoHoursInMillis = 72 * 60 * 60 * 1000;

  if (currentTime < startTimestamp - seventyTwoHoursInMillis) {
    eventStatusText = translate('ewc.soon') ?? '';
    eventStatus = 'soon';
  } else if (currentTime < startTimestamp) {
    eventStatusText = translate('ewc.upcoming') ?? '';
    eventStatus = 'upcoming';
  } else if (currentTime >= startTimestamp && status !== 'completed') {
    eventStatusText = translate('ewc.now') ?? '';
    eventStatus = 'now';
  } else {
    eventStatusText = translate('ewc.ended') ?? '';
    eventStatus = 'ended';
  }
  return {
    eventStatusText,
    eventStatus,
  };
};
