import { LottieIconAnimations } from '@fe-monorepo/assets';
import { useForm } from '@fe-monorepo/forms';
import { IconNames, Logger } from '@fe-monorepo/helper';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import InputField from '@fe-web/Molecules/InputFields/InputField';
import PhoneNumberField from '@fe-web/Molecules/InputFields/PhoneNumberField';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import Lottie from 'lottie-react';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { authInputFieldStyleObj } from '../../../ShopAddAddress/commonStyles';
import { validateEmailSaudiArabia, validateNameSaudiArabia } from '../../../ShopAddAddress/validation';

interface SendGiftProps {
  closeModal: () => void;
  modalHeader: string;
  fullName: string;
  email: string;
  mobileNumber: string;
  comments: string;
  voucher: string;
  maxCharLimit: number;
  buttonLeft: string;
  buttonRight: string;
  buttonLast: string;
}

const SendGift = (props: SendGiftProps) => {
  const { form, editForm } = useForm();
  const [fullName, setFullName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [additionalInformation, setAdditionalInformation] = useState<string>('');
  const [isValidForm, setIsValidForm] = useState<boolean>(false);
  const [savedInfo, setSaveInfo] = useState({
    fullName: '',
    email: '',
    additionalInformation: '',
    phoneNumber: '',
  });
  const [isConfirmation, setIsConfirmation] = useState<boolean>(false);
  const isMobile = useMobileDetect();
  const isHeaderDark = useSelector((state: RootState) => state.app.headerColorChange);
  const { dir } = useHeaderState();
  const { log } = Logger();

  const saveInfo = () => {
    setSaveInfo({ fullName: fullName, email: email, additionalInformation: additionalInformation, phoneNumber: form.phoneNumber.value });
    showConfirmation();
  };

  const validateForm = () => {
    const isFullNameValid = validateNameSaudiArabia(fullName);
    const isEmailValid = validateEmailSaudiArabia(email);
    // Check if the entire form is valid
    const isValid = isFullNameValid && isEmailValid;
    setIsValidForm(isValid);
    log('isValidForm', isValid);
    return isValid;
  };

  useEffect(() => {
    validateForm();
  }, [fullName, email]);

  const showConfirmation = () => {
    setIsConfirmation(true);
  };

  return (
    <div
      id="send_gift"
      className={`
        flex flex-col mt-[5%] ${isConfirmation ? 'w-[26.875rem]' : 'w-[39.688rem]'} justify-start items-center bg-white
        rounded-lg p-[1.5rem] gap-[1.5rem] max-h-[100vh] overflow-y-auto`}
    >
      <div className={`flex flex-col w-[100%] ${isConfirmation ? 'SD:h-[13.375rem]' : 'SD:h-[27.813rem]'} gap-[8px]`}>
        <div className="flex flex-row justify-between items-center">
          <p className="font-medium text-fs-subtitle">{props.modalHeader}</p>
          <ResponsiveIcon
            name={IconNames.close_xbutton}
            baseHeight={10}
            baseWidth={10}
            onClick={props.closeModal}
            className="hover:cursor-pointer"
          />
        </div>
        {!isConfirmation ? (
          <>
            <div className="mt-0">
              <InputField
                label={props.fullName}
                isSecret={false}
                hasFocused={false}
                errorMessage=""
                isUserAllowedToType={(value: string) => true}
                retrieveValue={(value: any) => setFullName(value)}
                style={authInputFieldStyleObj}
              />
            </div>
            <div className="mt-0">
              <InputField
                label={props.email}
                isSecret={false}
                hasFocused={false}
                errorMessage=""
                isUserAllowedToType={(value: string) => true}
                retrieveValue={(value: any) => setEmail(value)}
                style={authInputFieldStyleObj}
              />
            </div>
            <div className="w-full SD:w-[23.938rem]">
              <PhoneNumberField
                label={props.mobileNumber}
                apiErrorMessage=""
                onCountryCode={e => {
                  log('Country Code object', e);
                }}
                onPhoneNumberChange={editForm}
              />
            </div>
            <div className="mt-0">
              <InputField
                label={props.comments}
                isSecret={false}
                hasFocused={false}
                errorMessage={''}
                isUserAllowedToType={(value: string) => true}
                retrieveValue={(value: any) => setAdditionalInformation(value)}
                style={authInputFieldStyleObj}
                showCounter
                maxLenght={props.maxCharLimit}
              />
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center">
            <Lottie
              animationData={LottieIconAnimations.Icon_Envelope}
              loop={true}
              style={{ height: '8.125rem', width: '7.688rem', flexShrink: 0, marginLeft: '1rem' }}
            />
            <p className="text-fs-body font-medium">{props.voucher}</p>
            <p className="text-fs-body">{savedInfo.email}</p>
          </div>
        )}
      </div>
      <div className="w-full">
        <div className={`flex ${isMobile ? 'flex-col' : ''} gap-[16px] h-full w-[100%]`}>
          {isConfirmation ? (
            <Button
              text={props.buttonLast}
              style={`ml-5 text-base not-italic text-fs-body w-full
                h=full ${isMobile ? 'w-full mt-5 px-[16px] py-[8px]' : 'px-[100px] py-[8px]'}
                gap-10px] z-1
                text-white100 border-[1px] border-transparent mb-[0px]
                xsMax:mb-[0px] items-end
                rounded-sm font-medium text-white100 ${
                  isHeaderDark
                    ? dir === 'ltr'
                      ? `after:bg-sunset before:bg-white100`
                      : `before:bg-sunset after:bg-white100`
                    : `${dir === 'ltr' ? 'after:bg-sunset before:bg-white100' : 'before:bg-sunset after:bg-white100'}
                    hover:border-[1px] hover:border-sunset`
                }
                hover:text-sunsetText rounded-sm`}
              onClick={props.closeModal}
              disabled={false}
            />
          ) : (
            <>
              <Button
                text={props.buttonLeft}
                style={`ml-5 text-base not-italic text-fs-body w-full
                  h-full border-[1px] border-sunset
                  ${isMobile ? 'w-full px-[16px] py-[8px]' : 'px-[100px] py-[8px]'}
                  gap-[10px] z-1
                  text-white100 text-sunsetText mb-[0px]
                  xsMax:mb-[0px] items-end
                  rounded-sm font-medium text-sunset ${
                    isHeaderDark
                      ? dir === 'ltr'
                        ? 'after:bg-white100 before:bg-sunset'
                        : 'before:bg-white100 after:bg-sunset'
                      : `${dir === 'ltr' ? 'after:bg-white100 before:bg-sunset' : 'before:bg-white100 after:bg-sunset'}
                      `
                  }
                  hover:text-white100 rounded-sm`}
                onClick={props.closeModal}
                disabled={false}
              />
              <Button
                text={props.buttonRight}
                style={`ml-5 text-base not-italic text-fs-body w-full
                  h=full ${isMobile ? 'w-full mt-5 px-[16px] py-[8px]' : 'px-[100px] py-[8px]'}
                  gap-10px] z-1
                  text-white100 border-[1px] border-transparent mb-[0px]
                  xsMax:mb-[0px] items-end
                  rounded-sm font-medium text-white100 ${
                    isHeaderDark
                      ? dir === 'ltr'
                        ? 'after:bg-sunset before:bg-white100'
                        : 'before:bg-sunset after:bg-white100'
                      : `${dir === 'ltr' ? 'after:bg-sunset before:bg-white100' : 'before:bg-sunset after:bg-white100'}
                      hover:border-[1px] hover:border-sunset`
                  }
                  hover:text-sunsetText rounded-sm`}
                onClick={saveInfo}
                disabled={!isValidForm}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SendGift;
