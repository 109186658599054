import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import Footer from '@fe-web/Organisms/ShopFooter/Footer';

import ErrorPageLayout from './ErrorPageLayout';

const NoInternetPage = () => {
  const { translate } = useTranslate();
  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <ErrorPageLayout
      list={[{ label: translate('home') as string }, { label: translate('common_internet_connection') as string }]}
      footer={<Footer />}
    >
      <div className="flex-1 flex flex-col gap-[2rem] items-center text-center justify-center">
        <ResponsiveIcon
          name={IconNames.noInternetDark}
          baseWidth={129.04}
          baseHeight={154}
          className=""
          iconClasses="max-w-screen max-h-screen w-[11rem] h-[9rem] md:w-[14.5rem] md:h-[12rem]"
        />
        <div className="flex flex-col gap-[2.5rem]">
          <div className="flex flex-col gap-[0.25rem]">
            <p className="font-medium text-fs-title text-black-white">{translate('common_game_over')}</p>
            <p className="text-zinc-600-400 max-w-[15rem]">{translate('error_no_internet')}</p>
          </div>
          <HoverText
            className={`font-regular text-sunset text-fs-body whitespace-nowrap flex m-auto`}
            hover={{ color: 'text-sunset' }}
            alwaysShowUnderline={false}
            underlineStyle="bg-sunset h-[0.0625rem] bottom-2"
            text={translate('action_refresh') ?? 'Refresh'}
            onClick={refreshPage}
          />
        </div>
      </div>
    </ErrorPageLayout>
  );
};

export default NoInternetPage;
