import { gql } from '@apollo/client';

export const CIRCUIT_QUERY = {
  getAllCircuitTournament: gql`
    query getAllCircuitTournament($details: TmsGetAllCircuitTournamentInput) {
      getAllCircuitTournament(details: $details) {
        is_successful
        error_msg
        error_code
        app_code
        data {
          id
          discipline
          name
          full_name
          status
          scheduled_date_start
          scheduled_date_end
          timezone
          online
          public
          location
          country
          size
          platforms
          logo {
            logo_small
            logo_medium
            logo_large
            original
            id
          }
          registration_enabled
          registration_opening_datetime
          registration_closing_datetime
        }
      }
    }
  `,

  getAllCircuitMatch: gql`
    query getAllCircuitMatch($details: TmsGetAllCircuitMatchInput) {
      getAllCircuitMatch(details: $details) {
        is_successful
        error_msg
        error_code
        app_code
        data {
          id
          tournament_id
          stage_id
          group_id
          round_id
          number
          type
          status
          scheduled_datetime
          played_at
          public_note
          private_note
          report_closed
          opponents {
            number
            position
            rank
            result
            forfeit
            score
            participant {
              id
              name
              custom_user_identifier
              custom_fields {
                logo {
                  icon_small
                  icon_medium
                  logo_small
                  logo_medium
                  logo_large
                }
              }
            }
          }
          discipline
          tournament_name
          tournament_logo {
            logo_small
            logo_medium
            logo_large
            original
            id
          }
          stream_url
          stage_name
          user_prediction
        }
      }
    }
  `,
  submitPrediction: gql`
    mutation submitPrediction($details: TmsSubmitPredictionInput) {
      submitPrediction(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  getAllCircuitPredictions: gql`
    query getAllCircuitPredictions($details: TMSGetAllCircuitPredictionsInput) {
      getAllCircuitPredictions(details: $details) {
        is_successful
        error_code
        error_msg
        app_code
        data {
          match_id
          result {
            team_id
            prediction_result
          }
        }
      }
    }
  `,

  getCircuitPredictionRankings: gql`
    query getCircuitPredictionRankings($details: TMSgetCircuitPredictionRankingsInput) {
      getCircuitPredictionRankings(details: $details) {
        is_successful
        error_code
        error_msg
        app_code
        data {
          scheduled_datetime
          rankings {
            week_id
            score
            win
            loss
            last_cursor
            name
            username
            avatar
          }
        }
      }
    }
  `,

  getCircuitStandingRankings: gql`
    query getCircuitStandingRankings($details: TMSGetCircuitStandingRankingsInput) {
      getCircuitStandingRankings(details: $details) {
        is_successful
        error_msg
        error_code
        data {
          img_url
          ewc_team_name
          ewc_team_code
          ewc_rank
          ewc_match_played
          ewc_match_win
          ewc_match_lose
          ewc_points
          ewc_sort_id
          last_cursor
        }
      }
    }
  `,

  getCircuitPredictionWeeks: gql`
    query getCircuitPredictionWeeks {
      getCircuitPredictionWeeks {
        is_successful
        error_code
        error_msg
        app_code
        data {
          week_id
          name_ar
          name_en
        }
      }
    }
  `,
};
