import { useTranslate } from '@fe-monorepo/hooks';
import { NotificationMessageData } from '@fe-monorepo/models';
import PopoverAbstract from '@fe-web/Molecules/Popover/PopoverAbstract';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';

interface NotificationPopoverProps extends NotificationMessageData {
  showMore: boolean;
}

const NotificationPopover = ({ is_read, isMinimized, id, showMore }: NotificationPopoverProps) => {
  const isMobile = useMobileDetect();
  const { translate } = useTranslate();
  const { removeNotification, updateNotification } = useNotificationProvider();

  // Marks the notification as read or unread based on the is_read property
  const readUnread = () => {
    const isRead = is_read === 'true';
    updateNotification({ message_id: id as string, is_read: String(!isRead) });
  };

  // removes the notification from the list. Removed notifications will not be returned on subsequent calls of notificationGetAll
  const remove = () => {
    removeNotification(id as string);
  };

  return (
    <PopoverAbstract
      iconBaseHeight={20}
      iconBaseWidth={20}
      position={['right', 'bottom']}
      align={isMinimized || isMobile ? 'end' : 'start'}
      padding={isMinimized ? 'mt-[0]' : 'mt-[0.75rem] SD:mt-0 SD:ltr:ml-[2rem] SD:rtl:mr-[2rem]'}
      iconClassName={`${!showMore && 'hidden'}`}
      containerClassName="flex flex-row gap-[.25rem]"
    >
      <div
        onClick={readUnread}
        className="text-secondary py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all"
      >
        <p className="font-regular text-fs-body">{translate('notifications.mark_as_unread')}</p>
      </div>
      <div
        onClick={remove}
        className="text-secondary py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all"
      >
        <p className="font-regular text-fs-body">{translate('notifications.remove_notification')}</p>
      </div>
    </PopoverAbstract>
  );
};

export default NotificationPopover;
