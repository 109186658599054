import { gql } from '@apollo/client';

export const DISCOUNT_MUTATION = {
  applyPromoCode: gql`
    mutation applyPromoCode($details: DiscountPromoInput) {
      applyPromoCode(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  removePromoCode: gql`
    mutation removePromoCode($details: DiscountPromoInput) {
      removePromoCode(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
  applyWallet: gql`
    mutation applyWallet($details: WalletBalanceInput) {
      applyWallet(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  removeWallet: gql`
    mutation removeWallet($details: WalletBalanceInput) {
      removeWallet(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
};
