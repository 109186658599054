import React from "react";

function LeftArrowIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="15.556"
      viewBox="0 0 16 15.556"
    >
      <path
        id="Path_18496"
        data-name="Path 18496"
        d="M7.828,11H20v2H7.828l5.364,5.364-1.414,1.414L4,12l7.778-7.778,1.414,1.414Z"
        transform="translate(-4 -4.222)"
      />
    </svg>
  );
}

export default LeftArrowIcon;
