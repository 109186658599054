import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { useLayoutEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import NavBar from './MyOrdersNavBar';
import OrderPage from './Orders/OrderPage';
import { StyledH2, StyledHeaderContainer } from './Orders/OrderPage.styled';
import ReturnPage from './Returns/ReturnPage';

const MyOrders = () => {
  const { translate } = useTranslate();
  const location = useLocation();
  const navigate = useNavigate();

  const initialTab = location.pathname === AppRoutes.myorders ? 'Orders' : location.pathname === AppRoutes.myreturns ? 'Returns' : 'Orders';

  const [selectedTab] = useState(initialTab);
  const [filteredOrdersLength, setFilteredOrdersLength] = useState(0);
  const [filteredReturnsLength, setFilteredReturnsLength] = useState(0);

  const handleTabChange = (tabName: string) => {
    if (tabName === selectedTab) return;
    if (tabName === 'Orders') {
      navigate(AppRoutes.myorders);
    } else if (tabName === 'Returns') {
      navigate(AppRoutes.myreturns);
    }
  };

  const myReturnsTotal = useSelector((state: RootState) => state.shopInfo?.returns_total ?? 0);
  const myOrdersTotal = useSelector((state: RootState) => state.shopInfo?.orders_total ?? 0);

  useLayoutEffect(() => {
    mixpanelHelper.trackPageView('MY ORDERS PAGE', 'my_orders_page', window.location.pathname, document.referrer);
    brazeHelper.trackPageView('MY ORDERS PAGE', 'my_orders_page', window.location.pathname, document.referrer);
  }, []);

  return (
    <div>
      <StyledHeaderContainer className="flex flex-start">
        <StyledH2>{translate('pageTitle_myOrders')}</StyledH2>
      </StyledHeaderContainer>
      <div>
        <NavBar onTabChange={handleTabChange} OrdersValue={myOrdersTotal} ReturnsValue={myReturnsTotal} activeTab={selectedTab} />
      </div>
      {selectedTab === 'Orders' && <OrderPage setFilteredOrdersLength={setFilteredOrdersLength} />}
      {selectedTab === 'Returns' && <ReturnPage setFilteredReturnsLength={setFilteredReturnsLength} />}
    </div>
  );
};

export default MyOrders;
