import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import Icon from '@fe-web/Atoms/Icon/Icon';

interface Props {
  closeFilters: () => void;
  filterCount: number;
}

const FilterHeader = ({ closeFilters, filterCount }: Props) => {
  const { translate } = useTranslate();

  return (
    <div className="flex flex-row gap-[0.5rem]">
      <Icon
        name={IconNames?.arrow}
        iconClasses="h-[1rem] w-[1rem] fill-btn-primary"
        className="flex items-center duration-[0.3s] rtl:rotate-[0deg] rotate-[180deg]"
        onClick={closeFilters}
      />
      <h2 className="font-medium text-fs-body-large text-secondary">
        {translate('common_filter_count', { value: `${filterCount > 0 ? `(${filterCount})` : ``}` })}
      </h2>
    </div>
  );
};

export default FilterHeader;
