export default function CloseIcon() {
  return (
    <svg width="35" height="35" viewBox="0 0 52 52">
      <g id="Group_24410" data-name="Group 24410" transform="translate(-316 -127)">
        <path
          id="Path_16222"
          data-name="Path 16222"
          d="M11.637,10.3,16.3,5.637,17.637,6.97,12.97,11.637,17.637,16.3,16.3,17.637,11.637,12.97,6.97,17.637,5.637,16.3,10.3,11.637,5.637,6.97,6.97,5.637Z"
          transform="translate(330.363 141.363)"
          fill="var(--secondary-black)"
        />
      </g>
    </svg>
  );
}
