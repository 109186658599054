import { gql } from '@apollo/client';

export const CONTENT_MUTATION = {
  uploadMedia: gql`
    mutation uploadMedia($details: String, $file: Upload) {
      uploadMedia(details: $details, file: $file) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  getGenerateLinks: gql`
    mutation getGenerateLinks($details: GenerateLinksInput) {
      getGenerateLinks(details: $details) {
        error_msg
        is_successful
        error_code
        data
      }
    }
  `,
};
