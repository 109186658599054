import styled from 'styled-components';
import tw from 'twin.macro';

const StyledContainer = styled.div`
  ${tw`
    flex
    flex-col
    SD:grid
    SD:grid-cols-cartPageLayout
    gap-x-[1.5rem]
    gap-y-32
    SD:gap-y-[2.5rem]
    px-20
    py-20
    pb-[3.75rem]
    md:px-64
    lg:px-120
    2K:px-160
    4K:px-320
    8K:px-655
    `}
`;

const StyledMainSection = styled.div`
  ${tw`SD:col-span-3 flex flex-col gap-y-32 SD:gap-y-40 divide-y`}
`;

const StyledHeaderContainer = styled.div`
  ${tw`SD:col-span-4`}
`;

export { StyledContainer, StyledHeaderContainer, StyledMainSection };
