const Loader = () => (
  <div className="full-loader holder allpopup_loader screen-loader">
    <div className="preloader" id="loader">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
);

export default Loader;
