import Skeleton from '@fe-web/Atoms/Skeleton/Skeleton';
import useGetCurrentBreakPoint from '@fe-web/hooks/useGetCurrentBreakPoint/useGetCurrentBreakPoint';

export const BannerSkeleton = () => {
  const { currentBreakPoint } = useGetCurrentBreakPoint();
  const isMobile = currentBreakPoint === 'mobile';

  return (
    <div className="flex flex-col gap-y-[0.5rem] h-full">
      <div className="w-full h-full px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655 flex">
        <div className="relative flex items-center w-full h-full">
          <ul className="list-none h-full w-full flex sm:justify-center gap-[2%] overflow-x-scroll sm:overflow-x-hidden snap-x snap-mandatory">
            {Array.from({ length: 3 }, (e, i) => (
              <li
                key={i}
                className={`relative flex flex-col gap-16 snap-center  ${
                  i === 1 ? `${isMobile ? 'w-[100%]' : 'w-[76%]'}` : `${isMobile ? 'w-[0%]' : 'w-[6.66%] sm:flex'}`
                }`}
              >
                <Skeleton height="h-full" width="w-full" className=" rounded-lg aspect-square md:aspect-2.75/1" />
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className="w-full h-full md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655 px-0">
        <div
          className={`items-center ${isMobile ? 'justify-between' : 'justify-center'} md:gap-[1.875rem] flex mt-0 SD:mt-[0.5rem] h-full`}
        >
          <Skeleton height="h-[1.5rem]" width="w-[2.5rem]" className="mdMaxxSD:w-[5.25rem] p-0 my-0 SD:mx-[2rem] SD:px-[2rem]" />
          <Skeleton height="h-[1.5rem]" width="w-[2.5rem]" className="mdMaxxSD:w-[5.25rem] p-0 my-0 SD:mx-[2rem] SD:px-[2rem]" />
          <Skeleton height="h-[1.5rem]" width="w-[2.5rem]" className="mdMaxxSD:w-[5.25rem] p-0 my-0 SD:mx-[2rem] SD:px-[2rem]" />
        </div>
      </div>
    </div>
  );
};
