import { gql } from '@apollo/client';

export const USER_QUERY = {
  getUserContext: gql`
    query fetchUserContext {
      user {
        userId
        firstName
        lastName
        email
      }
    }
  `,

  isUserFoundByEmail: gql`
    query fetchIsUserFoundByEmail($details: SignUpInput) {
      isUserFoundByEmail(details: $details) {
        error_msg
        error_code
        is_successful
      }
    }
  `,

  lockDuration: gql`
    query lockDuration($details: LockDurationInput) {
      lockDuration(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          time_remaining
        }
      }
    }
  `,

  isUserFoundByUsername: gql`
    query fetchIsUserFoundByUsername($details: SignUpInput) {
      isUserFoundByUsername(details: $details) {
        error_msg
        error_code
        is_successful
      }
    }
  `,

  isValidReferral: gql`
    query fetchIsValidReferral($details: SignUpInput) {
      isValidReferral(details: $details) {
        error_msg
        error_code
        is_successful
      }
    }
  `,

  isMobileAvailable: gql`
    query fetchIsMobileAvailable($details: SignUpInput) {
      isMobileAvailable(details: $details) {
        error_msg
        error_code
        is_successful
      }
    }
  `,

  isUsernameAvailable: gql`
    query fetchIsUsernameAvailable($details: SignUpInput) {
      isUsernameAvailable(details: $details) {
        error_msg
        error_code
        is_successful
      }
    }
  `,

  isUserFoundByMobile: gql`
    query fetchIsUserFoundByMobile($details: SignUpInput) {
      isUserFoundByMobile(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          token
          identifier
          is_2FA_required
          preferred_theme
          uuid
        }
      }
    }
  `,

  getPersonalizationQuestionnaire: gql`
    query fetchGetPersonalizationQuestionnaire {
      getPersonalizationQuestionnaire {
        error_msg
        error_code
        is_successful
        data {
          questionnaire_id
          name_en
          name_ar
          gamer_type
        }
      }
    }
  `,

  getUserProfileInfo: gql`
    query fetchUserData {
      getUserProfileInfo {
        error_msg
        error_code
        is_successful
        data {
          uuid
          gender_code
          display_name
          username
          email
          mobile_code
          mobile
          gamer_type
          avatar_url
          cover_banner_url
          bio
          provider
          points
          is_official_account
          is_email_verified
          is_mobile_verified
          is_2FA_required
          referral_code
          preferred_language
          preferred_theme
          preferred_timezone
          preferred_2FA_channel
          country_code
          reference_wallet_id
          total_followers
          total_following
          password_updated_at
          mobile_updated_at
          email_updated_at
          birthdate
          created_at
          preferred_private_chat_code
          preferred_private_chat_name_ar
          preferred_private_chat_name_en
          preferred_group_chat_code
          preferred_group_chat_name_ar
          preferred_group_chat_name_en
          is_read_status_enabled
          privacy_consent
          marketing_consent
        }
      }
    }
  `,

  getUserProfileInfoSSoLogin: gql`
    query fetchUserData($details: ProfileParams) {
      getUserProfileInfoSSoLogin(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          uri
        }
      }
    }
  `,

  getInfoByUsername: gql`
    query fetchUserData($details: AccountInput) {
      getInfoByUsername(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          gender_code
          display_name
          username
          email
          mobile_code
          mobile
          gamer_type
          avatar_url
          cover_banner_url
          bio
          provider
          points
          is_official_account
          is_email_verified
          is_2FA_required
          referral_code
          preferred_language
          preferred_theme
          preferred_timezone
          preferred_2FA_channel
          country_code
          reference_wallet_id
          total_followers
          total_following
          viewer_is_following
          viewer_is_follower
          message_recipient
          is_followed
          is_follower
          is_blocked
          is_blocking
          password_updated_at
          mobile_updated_at
          email_updated_at
          birthdate
          created_at
        }
      }
    }
  `,

  getWallet: gql`
    query fetchWallet {
      getWallet {
        error_msg
        error_code
        is_successful
        data {
          entity_type
          wallet_number
          total_balance
        }
      }
    }
  `,

  getDeliveryAddress: gql`
    query fetchDeliveryAddress {
      getDeliveryAddress {
        error_msg
        error_code
        is_successful
        data {
          district
          zip_code
          name
          user_address_id
          country_code
          mobile
          mobile_code
          updated_at
          address
          label
          city
          country
          zip_code
          state
          location_lat
          location_long
          district
          email
          is_default
          street
        }
      }
    }
  `,

  getDeliveryInfo: gql`
    query fetchDeliveryInfo($details: AddressInput) {
      getDeliveryInfo(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          district
          zip_code
          name
          user_address_id
          country_code
          mobile
          mobile_code
          updated_at
          address
          label
          city
          country
          zip_code
          state
          location_lat
          location_long
          district
          default
          street
        }
      }
    }
  `,

  getAllNetworkUser: gql`
    query fetchAllNetworkUser($details: GetAllNetworkInput) {
      getAllNetworkUser(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          gaming {
            social_network_code
            social_network_account
          }

          social {
            social_network_code
            social_network_account
          }
        }
      }
    }
  `,
};
