import { useBitsConfig } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { useShopConfig } from 'libs/hooks/src/useShopConfig';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import HoverText from '../../Atoms/Text/HoverText';

export const navlinks = [
  {
    label: 'Bits',
    link: AppRoutes.bits,
  },
  {
    label: 'play_nav',
    link: AppRoutes.games,
  },
  {
    label: 'shop.value',
    link: AppRoutes.shop,
  },
];

const NavLinks = () => {
  const { t } = useTranslation();
  const location = useLocation().pathname;
  const playConfig = useSelector((state: RootState) => state.app.playConfig);
  const prefs = useSelector((state: RootState) => state.app);
  const navigate = useNavigate();

  const { isShopDisabled } = useShopConfig();
  const { showBitsScreen } = useBitsConfig();

  const handleNavLinkClick = (label: string) => {
    if (label === 'play_nav') {
      mixpanelHelper.trackPageView('PLAY PAGE', 'play_page', AppRoutes.play, document.referrer);
      brazeHelper.trackPageView('PLAY PAGE', 'play_page', window.location.pathname, document.referrer);
    }
  };

  const navigation = (link: string) => {
    if (link === AppRoutes.games) {
      if (playConfig?.enabled && playConfig?.gameVisible) {
        return navigate(AppRoutes.games);
      } else if (playConfig?.enabled && playConfig?.matchmakingVisible) {
        return navigate(AppRoutes.matchmaking);
      } else if (playConfig?.enabled && playConfig?.tournamentVisible) {
        return window.location.replace(`https://tournament.stcplay.gg/${prefs?.language === 'ar' ? 'ar' : 'en_US'}/`);
      } else {
        return navigate(AppRoutes.games);
      }
    } else {
      return navigate(link);
    }
  };

  return (
    <nav className="gap-[30px] 4xl:gap-54 5xl:gap-80 8xl:gap-160 hidden md:flex">
      {navlinks.map(({ label, link }) => {
        const isPlayNav = label === 'play_nav';
        const shouldRenderPlayNav = !isPlayNav || (playConfig?.enabled && playConfig?.screenVisible);

        if (!shouldRenderPlayNav) {
          return null;
        }

        if (label === 'shop.value' && isShopDisabled) {
          return null;
        }
        if (label === 'Bits' && !showBitsScreen) {
          return null;
        }

        return (
          <div
            key={label}
            className="flex flex-col items-start justify-between h-full p-0 m-0 not-italic font-normal group text-secondary/50 hover:cursor-pointer"
            onClick={() => {
              navigation(link);
              handleNavLinkClick(label);
            }}
          >
            <HoverText
              className={`${location.includes(link) ? 'text-secondary' : 'text-secondary/50'} font-regular
              text-bodySmall 4xl:text-subtitle 5xl:text-bigTitle 8xl:text-LPTitle`}
              hover={{ color: 'text-secondary' }}
              alwaysShowUnderline={false}
              underlineStyle="
              z-1 static
              bg-secondary
              top-[28px] 4xl:top-[47px] 5xl:top-[70px] 8xl:top-[142px]
              h-2 4xl:h-[3.55px] 5xl:h-[5.33px] 8xl:h-[10.66px]"
              text={t(label) ?? ''}
            />
            <div
              className={`${
                location.includes(link)
                  ? 'top-[26.375px] 4xl:top-[43px] 5xl:top-[65px] 8xl:top-[130px]  relative bg-secondary h-[2px] 2K:h-[-3.56px] 4K:h-[-5.3px] 8K:h-[-10.67px] w-full 4xl:h-[5px] 8K:h-[10px]'
                  : 'hidden'
              }`}
            ></div>
          </div>
        );
      })}
    </nav>
  );
};

export default NavLinks;
