import { IconNames } from '@fe-monorepo/helper';
import { ReactNode, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import usePageLayout from '../../../hooks/usePageLayout';
import ResponsiveIcon from '../../Atoms/Icon/ResponsiveIcon';
import './ScrollBar.scss';

interface ScrollBarProps {
  children?: ReactNode;
  containerClassName?: string;
  leftArrowClassName?: string;
  rightArrowClassName?: string;
  leftArrowClassNameOnReachEnd?: string;
  rightArrowClassNameOnReachEnd?: string;
  iconSize?: number;
  iconClassName?: string;
  showLeftArrow?: boolean;
  showRightArrow?: boolean;
}

const ScrollBar = forwardRef<HTMLDivElement, ScrollBarProps>((props, ref) => {
  const {
    children,
    containerClassName = '',
    leftArrowClassName = '',
    rightArrowClassName = '',
    leftArrowClassNameOnReachEnd = '',
    rightArrowClassNameOnReachEnd = '',
    iconSize = 20,
    iconClassName = 'fill-white',
    showLeftArrow = true,
    showRightArrow = true,
  } = props;

  const listRef = useRef<HTMLDivElement | null>(null);

  const { language } = usePageLayout();

  const [isReachedToRight, setIsReachedToRight] = useState<boolean>(language === 'en' ? false : true);
  const [isReachedToLeft, setIsReachedToLeft] = useState<boolean>(language === 'en' ? true : false);

  useImperativeHandle(ref, () => listRef.current as HTMLDivElement);

  const scrollLeft = () => {
    if (listRef.current) {
      listRef.current.scrollLeft -= 1000;
    }
  };

  const scrollRight = () => {
    if (listRef.current) {
      listRef.current.scrollLeft += 1000;
    }
  };

  const handleScroll = () => {
    if (!listRef.current) return;

    const { scrollLeft, scrollWidth, clientWidth } = listRef.current;

    const isAtRightEdge = language === 'en' ? scrollWidth - scrollLeft <= clientWidth : scrollLeft === 0;

    const isAtLeftEdge = language === 'en' ? scrollLeft === 0 : scrollLeft + scrollWidth <= clientWidth;

    setIsReachedToRight(isAtRightEdge);
    setIsReachedToLeft(isAtLeftEdge);
  };

  useEffect(() => {
    const timeout = setTimeout(handleScroll, 500);
    return () => clearTimeout(timeout);
  }, [language]);

  return (
    <div className="relative flex items-center content-center w-full">
      {showLeftArrow && (
        <span
          className={`absolute left-0 z-[10] flex items-center ${
            isReachedToLeft ? `cursor-not-allowed ${leftArrowClassNameOnReachEnd}` : 'cursor-pointer'
          } ${leftArrowClassName}`}
          onClick={scrollLeft}
        >
          <ResponsiveIcon
            className={`rotate-180 ${isReachedToLeft ? `${iconClassName} fill-secondary/40` : iconClassName}`}
            name={IconNames.chevronRight}
            baseHeight={iconSize}
            baseWidth={iconSize}
          />
        </span>
      )}

      <div
        ref={listRef}
        onScroll={handleScroll}
        className={`${containerClassName} w-fit flex gap-22 m-0 overflow-x-scroll whitespace-nowrap scroll-smooth`}
      >
        {children}
      </div>

      {showRightArrow && (
        <span
          className={`absolute right-0 z-[10] flex items-center ${
            isReachedToRight ? `cursor-not-allowed ${rightArrowClassNameOnReachEnd}` : 'cursor-pointer'
          } ${rightArrowClassName}`}
          onClick={scrollRight}
        >
          <ResponsiveIcon
            className={`${isReachedToRight ? `${iconClassName} fill-secondary/40` : iconClassName}`}
            name={IconNames.chevronRight}
            baseHeight={iconSize}
            baseWidth={iconSize}
          />
        </span>
      )}
    </div>
  );
});

export default ScrollBar;
