import { useBitsConfig, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Spinner from '@fe-web/Atoms/Load/Spinner';
import VerifyPhoneModal from '@fe-web/Molecules/SuperPlus/VerifyPhoneModal';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import quickplay from 'quickplay-shorts-js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as VideoLikeIcon } from '../../assets/icons/VideoLikeIcon.svg';
import { ReactComponent as VideoViewsIcon } from '../../assets/icons/VideoViewsIcon.svg';
import { CONTENT_TYPE, EVENTS } from '../../constants';
import { useLoginModalContext } from '../../contexts/LoginModal';
import useAnalytics from '../../hooks/useAnalytics';
import { formatLargeNumber } from '../VerticalPlayer/helpers';

function Hashtag() {
  const [hashtagDetail, setHashtagDetail]: any = useState([]);
  const [hashtagTotal, setHashtagTotal] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { hashtagName } = useParams();
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const { user, isLoggedIn } = useUserProfile();
  const { setShowLoginModal } = useLoginModalContext();
  const { translate } = useTranslate();
  const [isVerifyOpen, setIsVerifyOpen] = useState<boolean>(false);
  const isPhoneNotAdded = !user?.mobile;
  const isPhoneNotVerified = user?.mobile && !user?.is_mobile_verified;
  const { showBitsCreator } = useBitsConfig();
  const globalConfig = useSelector((state: RootState) => state.app.globalConfig);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const hashTagModuleObj = new quickplay.QuickPlayHashTag();
        let limit = 12;
        let offset = page;
        const hashTagResponse = await hashTagModuleObj.getHashTagVideoList({
          limit: limit,
          offset: offset,
          hashtagName: hashtagName,
        });
        if (hashTagResponse?.status === 200) {
          setHashtagDetail((prevDetails: any) => [...prevDetails, ...hashTagResponse.data.result]);
          setHashtagTotal(hashTagResponse.data.total);
          if (hashTagResponse.data.result.length < limit) {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
    fetchData();
  }, [hashtagName, page]);

  const handleContentDetail = (contentInfo: any, index: number) => {
    trackEvent({
      event: EVENTS.THUMBNAIL_CLICK,
      content: { ...contentInfo, horizontalIndex: index, verticalIndex: 0 },
    });
    navigate(`${AppRoutes.bitsPlay}/content/${hashtagName}?contentType=${CONTENT_TYPE.HASHTAG}&clickedVideoId=${contentInfo._id}`);
  };

  const handleUseHashtag = () => {
    if (isLoggedIn) {
      if (isPhoneNotAdded || isPhoneNotVerified) {
        setIsVerifyOpen(true);
        return;
      }
      localStorage.setItem('hashtag', `${hashtagName}`);
      navigate(`${AppRoutes.bits}/create-post/step-1?hashtag=true`);
    } else {
      setShowLoginModal(true);
    }
  };

  const handleLoadMore = () => {
    setPage(prev => prev + 1);
  };

  return (
    <>
      <section className="main-section">
        {/* Section Title Start */}
        <div className="section-head">
          <div className="section-head-title">
            <h2>#{hashtagName}</h2>
            <p>
              <span>{formatLargeNumber(hashtagTotal || 0)} Bits &nbsp;</span> {translate('quickplay.label-use-hashtag-description')}
            </p>
          </div>
          {showBitsCreator && (
            <button
              className="text-base not-italic max-h-[2.5rem] font-medium gap-[0.5rem] z-1 text-white border-sunset border-[0.063rem] mb-[0px] px-[1rem] py-[0.5rem] items-end rounded-[0.188rem] bg-sunset disabled:bg-secondary/[0.1] after:bg-sunset before:bg-white100 cursor-pointer hover:border-[0.0625rem] hover:border-sunset hover:text-sunsetText button-animation-ltr"
              onClick={handleUseHashtag}
            >
              {translate('quickplay.label-use-this-hashtag')}
            </button>
          )}
        </div>
        {/* Section Title End */}

        {/* Video List - Grid View Start */}
        <div className="flex flex-col gap-y-[2rem] justify-center items-center">
          <div className="grid grid-cols-12 gap-y-[2rem] gap-x-[1rem] w-[100%]" id="tab1">
            {hashtagDetail?.map((video: any, index: number) => {
              let thumbnailUrls = video?.thumbnailUrls ? video?.thumbnailUrls[0] : video?.thumbnailUrl;
              return (
                <div className="col-span-6 lg:col-span-2" key={video?.videoId}>
                  <div className="relative cursor-pointer" id={video?.videoId}>
                    {/* User Profile */}
                    {globalConfig?.profileHidden ? (
                      <>
                        <img src={video?.user?.profileImageUrl} alt={video?.user?.userName} className="video_profile--image" />
                        <p className="video_profile--name">{video?.user?.userName}</p>
                      </>
                    ) : (
                      <Link to={`/users/${video?.user?.userId}`} className="video_profile">
                        <img src={video?.user?.profileImageUrl} alt={video?.user?.userName} className="video_profile--image" />
                        <p className="video_profile--name">{video?.user?.userName}</p>
                      </Link>
                    )}

                    {/* Video Image */}
                    <img
                      src={thumbnailUrls}
                      alt=""
                      className="w-full h-full aspect-[9/16] object-cover object-center rounded-md"
                      onClick={() => handleContentDetail(video, index)}
                    />

                    {/* Video Views & Like Count */}
                    <div className="video_detail">
                      <div className="video_info videoViews">
                        <VideoViewsIcon />
                        <span className="video_info--count">{formatLargeNumber(video.viewsCount || 0)}</span>
                      </div>

                      <div className="video_info videoLike">
                        <VideoLikeIcon />
                        <span className="video_info--count">{formatLargeNumber(video.likeCount || 0)}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="flex items-center justify-center mt-32">
          {isLoading && <Spinner className="h-[50px] w-[50px] mx-auto my-0" />}
          {!isLoading && hasMore && hashtagDetail.length > 0 && (
            <button
              onClick={handleLoadMore}
              className="py-[0.5rem] px-[1.5rem] w-fit mx-auto my-0 text-sunset responsive-text-body font-medium border-[1px] 2K:border-[1.77px] 4K:border-[2.66px] 8K:border-[5.33px] hover:border-[1px] hover:2K:border-[1.77px] hover:4K:border-[2.66px] hover:8K:border-[5.33px] rounded-sm button-animation-ltr"
            >
              Show more
            </button>
          )}
        </div>

        {/* Video List - Grid View End */}
      </section>

      <VerifyPhoneModal
        type={isPhoneNotAdded ? 'add' : isPhoneNotVerified ? 'verify' : null}
        open={isVerifyOpen}
        onClose={() => setIsVerifyOpen(false)}
      />
    </>
  );
}

export default Hashtag;
