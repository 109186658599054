import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import React from 'react';

interface ToastMessageProps {
  icon?: React.ReactNode;
  className?: string;
  children?: React.ReactNode;
  noIcon?: boolean;
  defaultIconClassName?: string;
  type: 'success' | 'error';
  errorIconBaseWidth?: number;
  errorIconBaseHeight?: number;
}

const ToastMessage = ({
  icon,
  className,
  children,
  noIcon,
  type,
  defaultIconClassName,
  errorIconBaseWidth,
  errorIconBaseHeight,
}: ToastMessageProps) => {
  return (
    <>
      {type === 'success' && (
        <span
          className={`${
            className ?? ''
          } flex gap-[0.5rem] mdxs:justify-center items-center text-green text-fs-body not-italic font-medium leading-6 w-full`}
        >
          {icon && icon}
          {/* Below is a default icon when icon prop is not passed, icon can be disabled as well */}
          {!icon && !noIcon && (
            <ResponsiveIcon
              className={`${defaultIconClassName && defaultIconClassName} fill-green`}
              name={IconNames.icon_toast_checkmark}
              baseWidth={18}
              baseHeight={13}
            />
          )}
          {children}
        </span>
      )}
      {type === 'error' && (
        <span
          className={`${
            className ?? ''
          } flex gap-[0.25rem] mdxs:justify-center items-center text-error text-fs-body not-italic font-medium leading-6 w-full`}
        >
          {icon && icon}
          {/* Below is a default icon when icon prop is not passed, icon can be disabled as well */}
          {!icon && !noIcon && (
            <ResponsiveIcon
              className={`${defaultIconClassName && defaultIconClassName} fill-red`}
              name={IconNames.icon_toast_exclamation}
              baseWidth={errorIconBaseWidth ?? 30}
              baseHeight={errorIconBaseHeight ?? 30}
            />
          )}
          {children}
        </span>
      )}
    </>
  );
};

export default ToastMessage;
