import { useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import { HARD_POPUP_EXCLUDE_PATHS } from '@fe-web/constant/constants';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

interface UseHardPopupReturn {
  isOpen: boolean;
}

const useHardPopup = (): UseHardPopupReturn => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const hardPopup = useSelector((state: RootState) => state.app.hardPopup);
  const hardPopupEnabled = hardPopup?.enabled ?? false;

  const { isLoggedIn } = useUserProfile();

  useEffect(() => {
    setIsOpen(hardPopupEnabled && !isLoggedIn && !HARD_POPUP_EXCLUDE_PATHS.some(page => currentPath.includes(page)));
  }, [hardPopupEnabled, isLoggedIn, currentPath]);

  return { isOpen };
};

export default useHardPopup;
