import { useTranslate } from '@fe-monorepo/hooks';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import { useLocation } from 'react-router-dom';

import NumberOne from './Icons/One';
import NumberThree from './Icons/Three';
import NumberTwo from './Icons/Two';
import Step from './Step';
import './Step.css';

const activeColor = '#E95F2A';

function Stepper() {
  const location = useLocation();
  const { translate } = useTranslate();

  const activeStep = (() => {
    if (location.pathname === `${AppRoutes.bits}/create-post/step-1`) {
      return 1;
    } else if (location.pathname === `${AppRoutes.bits}/create-post/step-2`) {
      return 2;
    } else if (location.pathname === `${AppRoutes.bits}/create-post/step-3`) {
      return 3;
    }
    return 0;
  })();

  const steps = [
    {
      number: 1,
      icon: <NumberOne {...((activeStep === 1 && { pathFill: activeColor }) || {})} />,
      label: `${translate('quickplay.edit-step')}`,
    },
    {
      number: 2,
      icon: <NumberTwo {...((activeStep === 2 && { pathFill: activeColor }) || {})} />,
      label: `${translate('quickplay.finalDetails-step')}`,
    },
    {
      number: 3,
      icon: <NumberThree {...((activeStep === 3 && { pathFill: activeColor }) || {})} />,
      label: `${translate('quickplay.done-step')}`,
    },
  ];
  return (
    <div className="flex items-center justify-between gap-10 mt-[1.5rem] mb-[1.5rem] step_wrapper">
      {steps.map((step: any) => (
        <Step
          key={step.number}
          step={{
            ...step,
            active: step.number === activeStep,
          }}
        />
      ))}
    </div>
  );
}

export default Stepper;
