import { useTranslate } from '@fe-monorepo/hooks';

interface ReplyCTAProps {
  markAsReadAndRedirect: () => void;
  label?: string;
  containerClass?: string;
}

const ReplyCTA = ({ markAsReadAndRedirect, label, containerClass }: ReplyCTAProps) => {
  const { translate } = useTranslate();
  return (
    <div
      onClick={() => markAsReadAndRedirect && markAsReadAndRedirect()}
      className={`text-sunset text-fs-caption not-italic font-regular leading-4 cursor-pointer ${containerClass}`}
    >
      {label || translate('notification_post_replay')}
    </div>
  );
};

export default ReplyCTA;
