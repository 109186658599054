export function isNotEmptyOrWhitespaced(string: string) {
  return /(?!^$)([^\s])/.test(string);
}

export function isNotWhitespaced(string: string) {
  return !string.includes(' ');
}

export function isValidEmail(email: string) {
  return /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i.test(
    email,
  );
}

export function isPartialValidEmail(email: string) {
  return /^[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~](\.?[-!#$%&'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9]{1,}[.\/0-9=?A-Z^_a-z`{|}~]+$/.test(email);
}

export function isValidMobile(mobile: string) {
  return /^\d+$/.test(mobile);
}

export function isValidMobileLength(mobile: string) {
  return mobile.length <= 15 && mobile.length >= 8;
}

export function isValidMobileMinLength(mobile: string) {
  return mobile.length >= 9;
}

export function isValidMobileMaxLength(mobile: string) {
  return mobile.length <= 15;
}

export function isValidPassword(value: string) {
  return /^[a-zA-Z0-9_\d#?!@$%^&*-.]{0,}$/.test(value);
}

export function isValidUsername(value: string) {
  return /^[a-zA-Z][a-zA-Z0-9_-]{0,}$/.test(value);
}

export function isValidPasswordMinLength(value: string) {
  return value?.length >= 8;
}

export function isValidPasswordLength(value: string) {
  return value?.length >= 8 && value?.length <= 30;
}

export function isPasswordStrengthOne(password: string) {
  return /^(?=.*?[a-z]).{0,}$/.test(password) || /^(?=.*?[0-9]).{0,}$/.test(password);
}

export function isPasswordStrengthTwo(password: string) {
  return password?.length >= 8 && /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{0,}$/.test(password);
}

export function isPasswordStrengthThree(password: string) {
  return password?.length >= 10 && /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{0,}$/.test(password);
}

export function isPasswordStrengthFour(password: string) {
  return password?.length >= 12 && /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-.]).{0,}$/.test(password);
}

export function isValidUsernameMaxLength(value: string) {
  return value?.length <= 60;
}

export function isValidUsernameLength(value: string) {
  return value?.length >= 3 && value?.length <= 60;
}

export function isValidMaxDisplayLength(value: string) {
  return value?.length <= 64;
}

export function isValidMaxBioLength(value: string) {
  return value?.length <= 124;
}

export function isValidEmailLength(value: any) {
  return value?.length <= 320;
}

export function isNotEmpty(string: string) {
  return string !== '';
}

export function isDigitsOnly(number: string) {
  return /^[0-9]*$/.test(number);
}

export function isValidDate(date: string) {
  const dateArray = date.split('-');
  if (dateArray.length !== 3) {
    return false;
  }
  if (
    !isNotEmpty(dateArray[0]) ||
    !isDigitsOnly(dateArray[0]) ||
    dateArray[0].length !== 4 ||
    !isNotEmpty(dateArray[1]) ||
    !isDigitsOnly(dateArray[1]) ||
    dateArray[1].length !== 2 ||
    !isNotEmpty(dateArray[2]) ||
    !isDigitsOnly(dateArray[2]) ||
    dateArray[2].length !== 2
  ) {
    return false;
  }
  return true;
}

export function validateText(input: string) {
  return /^[A-Za-z]+$/.test(input);
}
