import { InvoiceModel, InvoiceProduct } from '@fe-monorepo/models';

import { PROMO_ELIGIBLE_BRANDS } from './constants';

const getVatBasedOnPromo = (items: InvoiceProduct[], promoPercent?: number) => {
  return items.reduce((vat, item) => {
    if (PROMO_ELIGIBLE_BRANDS.includes(item?.brand?.collection_code)) {
      return vat + item?.total_tax - ((promoPercent ?? 0) * item?.total_tax) / 100;
    }
    return vat + item.total_tax;
  }, 0);
};

const getNetTotal = (items: InvoiceProduct[], promoPercent?: number) => {
  return items.reduce((net, item) => {
    if (PROMO_ELIGIBLE_BRANDS.includes(item?.brand?.collection_code)) {
      const net_subtotal = item?.subtotal - ((promoPercent ?? 0) * item?.subtotal) / 100;
      const net_tax = item?.total_tax - ((promoPercent ?? 0) * item?.total_tax) / 100;
      return net + net_subtotal + net_tax;
    }
    return net + item.grand_total;
  }, 0);
};

export const getOrderSummaryTotalAndDiscount = (
  items: InvoiceProduct[],
  discount: number | undefined,
  deliveryFee: number | undefined,
  stcWallet: number | undefined,
  promoPercent?: number,
) => {
  const subtotal = items.reduce((total, item) => total + item.subtotal, 0);
  const vat = promoPercent ? getVatBasedOnPromo(items, promoPercent) : items.reduce((vat, item) => vat + item.total_tax, 0);
  const total = subtotal + (vat || 0) + (deliveryFee ?? 0) - (discount ?? 0) - (stcWallet ?? 0);
  const grandtotal = promoPercent ? getNetTotal(items, promoPercent) : items.reduce((total, item) => total + item.grand_total, 0);
  return {
    subtotal,
    vat,
    total: total < 0 ? 0 : total,
    grandtotal,
  };
};

export const getOrderSummaryTotal = (invoiceInfo: InvoiceModel | undefined) => {
  const SUBTOTAL = invoiceInfo ? invoiceInfo.amount_sub_total : 0;
  const VAT = invoiceInfo ? invoiceInfo.amount_tax : 0;
  const walletAmount = invoiceInfo?.wallet_discount ? Math.abs(invoiceInfo.wallet_discount) : 0;
  const PROMO = invoiceInfo?.promo_discount ?? 0;
  const DELIVERY = 0;
  const totalPrice = SUBTOTAL - walletAmount + DELIVERY + VAT;
  const TOTAL = invoiceInfo?.amount_total ?? 0;
  return {
    totalPrice,
    VAT,
    DELIVERY,
    SUBTOTAL,
    TOTAL,
    walletAmount,
    PROMO,
  };
};
