import { MixPanelEvents, formatDecimalAsPercentage } from '@fe-monorepo/helper';
import { useProduct, useUserProfile } from '@fe-monorepo/hooks';
import { Product } from '@fe-monorepo/models';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { ReactElement, useEffect } from 'react';
import { Link } from 'react-router-dom';

import ScrollBar from '../../Molecules/ScrollBar/ScrollBar';
import ProductCard2 from '../ProductCard2/ProductCard2';
import { StyledHeader, StyledProductsCarouselContainer } from './ProductsCarousel.styled';

interface ProductCard extends Product {
  link?: string;
  brandLink?: string;
}

export type ProductCarouselProps = {
  seeAllLink: string;
  title?: string;
  sectionName: string;
  firstCard?: ReactElement | null;
  products: ProductCard[];
  ctaLabel: string | null;
  isRTL?: boolean;
  subCategory?: string;
  navigate: (path: string) => void;
  language: 'en' | 'ar';
  checkIsSuccess?: (errorMessage: string) => void;
};

function ProductsCarousel({
  title,
  sectionName,
  ctaLabel,
  firstCard,
  products,
  isRTL,
  navigate,
  seeAllLink,
  language,
  checkIsSuccess,
}: ProductCarouselProps) {
  const renderTitle = DOMPurify.sanitize(title || '');
  const { wishlistData, getAllWishlist, addToWishList, removeToWishList } = useProduct();
  const { isLoggedIn, user } = useUserProfile();

  useEffect(() => {
    isLoggedIn && getAllWishlist({ direction: '', last_cursor: '' });
  }, [isLoggedIn]);

  return (
    <StyledProductsCarouselContainer>
      <StyledHeader>
        {title && (
          <h2 className="inline-flex items-center font-medium text-fs-big-title-sm SD:text-fs-big-title text-secondary gap-x-24">
            {parse(renderTitle)}
          </h2>
        )}
        <HoverText
          className="font-regular text-sunset text-fs-body whitespace-nowrap hidden sm:flex tracking-[-0.0125rem]"
          hover={{ color: 'text-sunset' }}
          alwaysShowUnderline={true}
          underlineStyle="bg-sunset h-[0.0625rem] bottom-[0.125rem]"
          text={ctaLabel ? ctaLabel : ''}
          onClick={() => {
            mixpanelHelper.trackEvent(MixPanelEvents.bannersButtonCTA, {
              'CTA Name': `Carousel See All - ${sectionName}`,
              Referrer: window.location.href,
              'CTA Source': 'Shop Page',
              Username: isLoggedIn ? user?.username : 'Guest',
            });
            navigate(seeAllLink);
          }}
        />
      </StyledHeader>
      <ScrollBar
        containerClassName="w-full px-20 pr-73 gap-[1.5rem] md:px-64 lg:px-[7.5rem]"
        leftArrowClassName="hidden SD:flex mx-[76px] pb-[5rem]"
        rightArrowClassName="hidden SD:flex mx-[76px] pb-[5rem]"
        leftArrowClassNameOnReachEnd="hidden"
        rightArrowClassNameOnReachEnd="hidden"
      >
        {!!firstCard && <div className="min-w-[17.625rem]">{firstCard}</div>}
        {products?.map(
          (
            {
              lowest_selling_price,
              lowest_selling_price_formatted,
              lowest_actual_cost,
              lowest_actual_cost_formatted,
              name_en,
              name_ar,
              description_en,
              link,
              brandLink,
              brand,
              featured_img,
              product_code,
            }: ProductCard,
            key: number,
          ) => {
            const sellingPrice = lowest_selling_price;
            const sellingPriceFormatted = lowest_selling_price_formatted;
            const price = lowest_actual_cost;
            const priceFormatted = lowest_actual_cost_formatted;
            const image = featured_img || '';
            const isWishList = wishlistData ? (wishlistData.find(item => item.product_code === product_code) ? true : false) : false;
            return (
              <div className="min-w-[17.625rem] SD:w-[17.625rem]" key={key}>
                <ProductCard2
                  name={isRTL ? name_ar : name_en}
                  description={description_en || ''}
                  price={price}
                  priceFormatted={priceFormatted}
                  sellingPrice={sellingPrice}
                  sellingPriceFormatted={sellingPriceFormatted}
                  image={image}
                  isRTL={isRTL}
                  link={link}
                  brandLink={brandLink}
                  brand={brand[`name_${language}`] ?? ''}
                  tag={
                    lowest_selling_price < lowest_actual_cost
                      ? formatDecimalAsPercentage(lowest_selling_price, lowest_actual_cost)
                      : undefined
                  }
                  product_code={product_code}
                  isWishlist={isWishList}
                  checkIsSuccess={checkIsSuccess}
                  addToWishList={addToWishList}
                  removeToWishList={removeToWishList}
                  getAllWishlist={getAllWishlist}
                  productBrand={brand.name_en}
                />
              </div>
            );
          },
        )}
      </ScrollBar>
      <Link
        className="inline-block px-20 mt-32 underline text-sunset font-regular text-body md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655 SD:hidden"
        to={seeAllLink}
      >
        {ctaLabel}
      </Link>
    </StyledProductsCarouselContainer>
  );
}

export default ProductsCarousel;
