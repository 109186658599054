/* eslint-disable react-hooks/exhaustive-deps */
import { useLazyQuery } from '@apollo/client';
import { FIREBASE_QUERY } from '@fe-monorepo/data-access';
import { useEffect } from 'react';

import { useAppState } from '../index';

type FirebaseResponse = {
  generateFirebaseToken: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: {
      token: string;
    };
  };
};

export const useFirebase = () => {
  const [fetchFirebaseToken, { data: firebaseResponse, error: firebaseError }] = useLazyQuery<FirebaseResponse>(
    FIREBASE_QUERY.generateFirebaseToken,
    { errorPolicy: 'all' },
  );

  const { changeLoadingState, changeFirebaseToken } = useAppState();

  const getFirebaseToken = async () => {
    changeLoadingState(true);
    const { data } = await fetchFirebaseToken();
    return data?.generateFirebaseToken;
  };

  useEffect(() => {
    if (firebaseResponse) {
      const response = firebaseResponse?.generateFirebaseToken;
      if (response) {
        changeFirebaseToken(response?.data?.token);
      }
      changeLoadingState(false);
    }
  }, [firebaseResponse]);

  const initializeFirebase = async () => {
    changeLoadingState(true);
    fetchFirebaseToken();
  };

  useEffect(() => {
    if (firebaseError) {
      // TODO ADD CODE HERE
      changeLoadingState(false);
    }
  }, [firebaseError]);

  return { getFirebaseToken, initializeFirebase, firebaseResponse };
};
