import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import { useNavigate } from 'react-router-dom';

import SuccessImage from '../../../assets/img/success.png';
import Stepper from '../Stepper';

function PostCreateStepThree() {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const { user } = useUserProfile();

  const handleNavigationToFeed = () => {
    navigate(AppRoutes.users + `/${user?.username}`);
  };

  return (
    <>
      <Stepper />
      <div className="creat-full uploadInputWrapper stepThreeContent">
        <div className="creat-box w-full flex flex-col justify-center items-center rounded-md py-16">
          <div className="flex justify-center">
            <img src={SuccessImage} alt="post-created" width="200" />
          </div>
          <div className="content-sec dropzone-wrapper flex flex-col items-center justify-center">
            <div className="c-text">
              {translate('quickplay.label-congrats')} <br /> {translate('quickplay.label-bits-created')}
            </div>
            <div className="text-sm text-g">{translate('quickplay.label-bits-review')}</div>

            <div className="file-upload-btn-parent">
              <button className="btn_primary mt-t-30 flex items-center gap-16 text-white" onClick={handleNavigationToFeed}>
                {translate('quickplay.label-check-status')}
                {/* <RightArrowIcon /> */}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PostCreateStepThree;
