import { useMutation } from '@apollo/client';
import { ACCOUNT_MUTATION, USER_QUERY } from '@fe-monorepo/data-access';
import { AddressInput, DeliveryAddressInput, DeliveryAddressModel } from '@fe-monorepo/models';
import { RootState, deleteDeliveryAddress, setDeliveryAddress, useAppDispatch } from '@fe-monorepo/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFetchQuery } from '../useFetchQuery';

type DeliveryAddressParams = {
  details: DeliveryAddressInput;
};

type DeliveryAddressResponse = {
  addDeliveryAddress: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: DeliveryAddressModel;
  };
};

type UpdateAddressResponse = {
  updateDeliveryAddress: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: DeliveryAddressModel;
  };
};

type RemoveAddressResponse = {
  removeDeliveryAddress: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
  };
};

const addressInput: AddressInput = {
  address_id: 0,
};

export const useDeliveryAddress = () => {
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [deliveryAddressesData, setDeliveryAddressesData] = useState<DeliveryAddressModel[]>();

  const getDeliveryAddressResponse = useFetchQuery<DeliveryAddressModel[]>(USER_QUERY.getDeliveryAddress, []);
  const getDeliveryInfoResponse = useFetchQuery<DeliveryAddressModel, AddressInput>(USER_QUERY.getDeliveryInfo, addressInput);
  const deliveryAddress = useSelector((state: RootState) => state.deliveryAddress.deliveryAddress);

  const [addDeliveryAddress, { data: addDeliveryAddressData, error: addDeliveryAddressError }] = useMutation<
    DeliveryAddressResponse,
    DeliveryAddressParams
  >(ACCOUNT_MUTATION.addDeliveryAddress, {
    onError: e => {
      setIsLoading(false);
    },
  });

  const [updateDeliveryAddress, { data: updateDeliveryAddressData, error: updateDeliveryAddressError }] = useMutation<
    UpdateAddressResponse,
    DeliveryAddressParams
  >(ACCOUNT_MUTATION.updateDeliveryAddress, {
    onError: e => {
      setIsLoading(false);
    },
  });

  const [removeDeliveryAddress, { data: removeDeliveryAddressData, error: removeDeliveryAddressError }] = useMutation<
    RemoveAddressResponse,
    DeliveryAddressParams
  >(ACCOUNT_MUTATION.removeDeliveryAddress, {
    onError: e => {
      setIsLoading(false);
    },
  });

  useEffect(() => {
    if (getDeliveryAddressResponse.data) {
      const response = getDeliveryAddressResponse.data;
      if (response.length > 0) {
        setDeliveryAddressesData(response);
        dispatch(setDeliveryAddress(response));
      }
    }
    setIsLoading(false);
  }, [getDeliveryAddressResponse.data]);

  useEffect(() => {
    if (getDeliveryInfoResponse.data) {
      const response = getDeliveryInfoResponse.data;
    }
    setIsLoading(false);
  }, [getDeliveryInfoResponse.data]);

  useEffect(() => {
    if (updateDeliveryAddressError || addDeliveryAddressError || removeDeliveryAddressError || getDeliveryInfoResponse.error) {
      setIsLoading(false);
    }
  }, [updateDeliveryAddressError, addDeliveryAddressError, removeDeliveryAddressError, getDeliveryInfoResponse.error]);

  useEffect(() => {
    if (updateDeliveryAddressData?.updateDeliveryAddress.is_successful || addDeliveryAddressData?.addDeliveryAddress.is_successful) {
      getDeliveryAddressResponse.fetch();
    }
  }, [updateDeliveryAddressData, addDeliveryAddressData]);

  // useEffect(() => {
  //     if (removeDeliveryAddressData && removeDeliveryAddressData.removeDeliveryAddress.is_successful) {
  //         getDeliveryAddressResponse.fetch();
  //     }
  // }, [removeDeliveryAddressData])

  // Adding of Address
  // const addAddress = async (addressInput: DeliveryAddressInput) => {
  //   setIsLoading(true);
  //   addDeliveryAddress({
  //     variables: { details: addressInput },
  //   });
  // };
  const addAddress = async (addressInput: DeliveryAddressInput) => {
    setIsLoading(true);
    const response = await addDeliveryAddress({
      variables: { details: addressInput },
    });
    return response.data;
  };

  // Update Address
  // const updateAddress = async (addressInput: DeliveryAddressInput) => {
  //   setIsLoading(true);
  //   updateDeliveryAddress({
  //     variables: { details: addressInput },
  //   });
  // };
  const updateAddress = async (addressInput: DeliveryAddressInput) => {
    setIsLoading(true);
    const result = await updateDeliveryAddress({
      variables: { details: addressInput },
    });
    return result.data;
  };

  // Remove Address
  // const removeAddress = async (addressInput: AddressInput) => {
  //   setIsLoading(true);
  //   removeDeliveryAddress({
  //     variables: { details: addressInput },
  //   });
  // };
  const removeAddress = async (addressInput: AddressInput) => {
    setIsLoading(true);
    const result = await removeDeliveryAddress({
      variables: { details: addressInput },
    });
    return result.data;
  };

  // Get Address
  const getDeliveryAddress = async () => {
    setIsLoading(true);
    return getDeliveryAddressResponse.fetch();
  };

  // Get Address information
  const getDeliveryInfo = async (address_id: number) => {
    setIsLoading(true);
    return getDeliveryInfoResponse.fetch({ address_id: address_id });
  };

  const removeSavedAddress = (address_id: number) => {
    dispatch(deleteDeliveryAddress(address_id));
  };

  return {
    addAddress,
    updateAddress,
    removeAddress,
    getDeliveryAddress,
    getDeliveryInfo,
    isLoading,
    setIsLoading,
    addDeliveryAddressData,
    updateDeliveryAddressData,
    removeDeliveryAddressData,
    deliveryAddress,
    deliveryAddressesData,
    removeSavedAddress,
  };
};
