function VideoErrorIcon() {
  return (
    <svg width="120" height="120" viewBox="0 0 120 120" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="60" cy="60" r="60" fill="#232225" />
      <path
        d="M45.0668 79.6663L41.3335 75.933L56.2668 60.9997L41.3335 46.0663L45.0668 42.333L60.0002 57.2663L74.9335 42.333L78.6668 46.0663L63.7335 60.9997L78.6668 75.933L74.9335 79.6663L60.0002 64.733L45.0668 79.6663Z"
        fill="#E03030"
      />
    </svg>
  );
}

export default VideoErrorIcon;
