import { MenuItem, ShopContentModel, ShopInfoDataModel } from '@fe-monorepo/models';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export const shopInfoSlice = createSlice({
  name: 'shopInfo',
  initialState: {
    shopInfo: {
      menu_code: '',
      name_en: '',
      name_ar: '',
      menu_items: [] as MenuItem[],
    },
    shopMenu: {
      menu_code: '',
      name_en: '',
      name_ar: '',
      menu_items: [] as MenuItem[],
    },
    shopContent: [] as ShopContentModel[],
    orders_total: 0,
    returns_total: 0,
  },
  reducers: {
    addShopInfo: (state, action: PayloadAction<ShopInfoDataModel>) => {
      state.shopInfo = action.payload;
    },
    addShopMenu: (state, action: PayloadAction<ShopInfoDataModel>) => {
      state.shopMenu = action.payload;
    },
    addMyOrdersTotal: (state, action: PayloadAction<number>) => {
      state.orders_total = action.payload;
    },
    addReturnsTotal: (state, action: PayloadAction<number>) => {
      state.returns_total = action.payload;
    },
    addShopContent: (state, action: PayloadAction<ShopContentModel[]>) => {
      state.shopContent = action.payload;
    },
  },
});

export const { addShopInfo, addShopMenu, addMyOrdersTotal, addReturnsTotal, addShopContent } = shopInfoSlice.actions;
