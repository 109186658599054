import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Modal from '@fe-web/Templates/Modal/Modal';
import { useEffect, useState } from 'react';

import GroupPreference from './GroupPreference';

interface Props {
  isGroupPreferenceOpen: boolean;
  isGroupPreferenceClose: () => void;
}

const GroupPreferenceFullView = ({ isGroupPreferenceOpen = false, isGroupPreferenceClose }: Props) => {
  const { translate } = useTranslate();
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(isGroupPreferenceOpen);
  }, [isGroupPreferenceOpen]);

  return (
    <Modal backdropClassName="bg-black/[0.25] items-center" open={isOpen}>
      <div className="w-[25.875rem] bg-primary rounded-lg border border-neutral-300-zinc-700 inline-flex flex-col items-start justify-start">
        <div className="w-full inline-flex flex-row justify-between px-[1rem] pt-[1rem] pb-[0.75rem] border-b border-neutral-300-zinc-700">
          <div className="font-regular text-fs-body-small text-secondary">{translate('connect.group_info_label')}</div>
          <ResponsiveIcon
            onClick={isGroupPreferenceClose}
            className="cursor-pointer dark:hover:fill-zinc-300 hover:fill-neutral-600 fill-neutral-400-zinc-500"
            name={IconNames.close}
            baseHeight={16}
            baseWidth={16}
          />
        </div>
        <GroupPreference />
      </div>
    </Modal>
  );
};

export default GroupPreferenceFullView;
