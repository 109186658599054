import {
  CartEvents,
  CommonUserProperties,
  EventObject,
  ProductItem,
  convertPriceToChosenCurrency,
  getAllUserProperties,
  getChosenCurrency,
  getCommonEventProperties,
} from '@fe-monorepo/helper';
import { PREF_2FA_CHANNEL } from '@fe-monorepo/hooks';
import { GetInfoDataResult, InvoiceModel, InvoiceProduct, Product, ShopProductsModel } from '@fe-monorepo/models';
import ProductDetail from '@fe-web/types/productDetailedPage';
import moment from 'moment';

import { getPageType } from '../src/app/app.routes.enum';

declare global {
  interface Window {
    dataLayer: any;
  }
}

type PageProperties = {
  page_url: string;
  page_title: string;
  page_language: string;
  page_referrer: string;
  page_type: string;
  timestamp: string;
};

type DataLayerDatum = {
  event: string;
  event_properties: object;
  user_properties: object;
  page_properties: object;
  ecommerce?: object;
};

export const gtm = (event: EventObject, user_properties: object, page_properties: PageProperties, isShopEvent?: boolean) => {
  const commonEventProperties = getCommonEventProperties();
  if (window['dataLayer']) {
    window['dataLayer'] = window['dataLayer'] || [];
    const eventObj: DataLayerDatum = {
      event: event.name,
      event_properties: commonEventProperties,
      user_properties: user_properties,
      page_properties: page_properties,
    };
    if (isShopEvent) {
      eventObj['ecommerce'] = event.properties;
    } else {
      eventObj['event_properties'] = { ...commonEventProperties, ...event.properties };
    }
    window['dataLayer'].push(eventObj);
  }
};

export const webAnalytics = (event: EventObject, guestInfo: CommonUserProperties = {}, isShopEvent?: boolean) => {
  const pageType = getPageType(new URL(document.URL).pathname);
  const pageProperties: PageProperties = {
    page_url: document.URL,
    page_title: document.title,
    page_language: navigator.language,
    page_referrer: document.referrer,
    page_type: pageType,
    timestamp: moment().toString(),
  };
  const isSignup = ['signup', 'failed_signup'].includes(event.name);
  const allUserProperties = { ...getAllUserProperties(isSignup), ...guestInfo };
  gtm(event, allUserProperties, pageProperties, isShopEvent);
};

export const otpGTMConfirmed = (method: PREF_2FA_CHANNEL, guestInfo: CommonUserProperties = {}) => {
  const otpSuccess: EventObject = {
    name: 'confirmed_otp',
    properties: { otp_method: method },
  };
  webAnalytics(otpSuccess);
};

export const otpGTMFailed = (method: PREF_2FA_CHANNEL, errorCode: string, errorMessage: string, guestInfo: CommonUserProperties = {}) => {
  const otpFailed: EventObject = {
    name: 'failed_otp',
    properties: {
      method,
      error_code: errorCode,
      error_message: errorMessage,
    },
  };
  webAnalytics(otpFailed, guestInfo);
};

export const trackTournamentEvent = (eventName: 'viewed_tournament' | 'joined_tournament', data: GetInfoDataResult) => {
  const viewedTournamentEvent: EventObject = {
    name: eventName,
    properties: {
      tournament_name: data.name,
      tournament_registration_start_time: data.registration_opening_datetime,
      tournament_registration_end_time: data.registration_closing_datetime,
      tournament_start_time: data.scheduled_date_start,
      tournament_game: data.discipline,
      tournament_platform: data.platforms,
      tournament_total_players: data.size,
      tournament_category: '',
      tournament_type: data.participant_type,
      tournament_format: '',
      tournament_creator: data.organization,
      tournament_registration: 'Free',
      tournament_region: data.country,
    },
  };
  webAnalytics(viewedTournamentEvent);
};

export interface CategoryData {
  categoryID: string;
  categoryName: string;

  subCategoryID: string;
  subCategoryName: string;
}

const convertShopProductsToItems = (categoryInfo: CategoryData, products: ShopProductsModel[] | Product[], currentIndex: number = 0) => {
  const items: ProductItem[] = products.map((product, index) => {
    const discountExists = product?.lowest_actual_cost && product?.lowest_actual_cost > product?.lowest_selling_price;
    const productItem: ProductItem = {
      index: currentIndex + index + 1,
      item_id: product.product_code.trim(),
      item_name: product.name_en.trim(),
      affiliation: 'stc',
      item_category: categoryInfo.categoryName.trim(),
      item_category2: categoryInfo.subCategoryName.trim(),
      price: convertPriceToChosenCurrency(product.lowest_actual_cost),
      discount: discountExists ? convertPriceToChosenCurrency(product.lowest_actual_cost - product?.lowest_selling_price) : 0,
    };
    if (product.brand.name_en) productItem.item_brand = product.brand.name_en.trim();
    return productItem;
  });
  return items;
};

const convertCartProductsToItems = (products: InvoiceProduct[]) => {
  const items: ProductItem[] = products.map((product, index) => {
    const productItem: ProductItem = {
      item_id: (product.product_code ?? '').trim(),
      item_name: (product.product_name ?? '').trim(),
      affiliation: 'stc',
      item_category: '',
      item_category2: '',
      price: convertPriceToChosenCurrency(product?.unit_price),
      discount: product.discount_price ? convertPriceToChosenCurrency(product.discount_price / product.qty) : 0,
      quantity: product.qty ?? 1,
    };
    if (product.brand.name_en) productItem.item_brand = product.brand.name_en.trim();
    if (product.variant_id) productItem.item_variant = '' + product.variant_id;
    return productItem;
  });
  return items;
};

export type ProductListTracker = {
  track: (products: ShopProductsModel[] | Product[]) => void;
};

export const viewProductListEvent = (categoryInfo: CategoryData, products: ShopProductsModel[] | Product[]) => {
  const items: ProductItem[] = convertShopProductsToItems(categoryInfo, products);
  const event: EventObject = {
    name: 'view_item_list',
    properties: {
      currency: getChosenCurrency(),
      item_list_id: categoryInfo.subCategoryID.trim(),
      item_list_name: categoryInfo.subCategoryName.trim(),
      items: items,
    },
  };
  webAnalytics(event, {}, true);
};

export const createProductListTracker = (categoryInfo: CategoryData): ProductListTracker => {
  let currentIndex = 0;
  return {
    track: (products: ShopProductsModel[] | Product[]) => {
      const items: ProductItem[] = convertShopProductsToItems(categoryInfo, products, currentIndex);

      const event: EventObject = {
        name: 'view_item_list',
        properties: {
          currency: getChosenCurrency(),
          item_list_id: categoryInfo.subCategoryID.trim(),
          item_list_name: categoryInfo.subCategoryName.trim(),
          items: items,
        },
      };
      webAnalytics(event, {}, true);
      currentIndex += products.length;
    },
  };
};

export const viewProductEvent = (categoryInfo: CategoryData, product: ProductDetail, locationID: string, isAvailable: boolean) => {
  const discountExists = product.sellingPrice < product.price;
  const item: ProductItem = {
    item_id: (product.product_code ?? '').trim(),
    item_name: (product.name.en ?? '').trim(),
    affiliation: 'stc',
    item_category: categoryInfo.categoryName.trim(),
    item_category2: categoryInfo.subCategoryName.trim(),
    stock: isAvailable,
    price: convertPriceToChosenCurrency(product.price),
    discount: discountExists ? convertPriceToChosenCurrency(product.price - product?.sellingPrice) : 0,
    location_id: locationID,
  };
  if (product.brand.en) item.item_brand = product.brand.en.trim();
  if (product.variant_id) item.item_variant = '' + product.variant_id;
  const event: EventObject = {
    name: 'view_item',
    properties: {
      currency: getChosenCurrency(),
      items: [item],
    },
  };
  webAnalytics(event, {}, true);
};

export const trackCartEvent = (name: 'view_cart' | 'add_to_cart' | 'remove_from_cart', value: number, products: InvoiceProduct[]) => {
  const items = convertCartProductsToItems(products);
  const event: CartEvents = {
    name: name,
    properties: {
      currency: getChosenCurrency(),
      value: convertPriceToChosenCurrency(value),
      items: items,
    },
  };
  webAnalytics(event, {}, true);
};

export const trackBeginCheckoutEvent = (value: number, products: InvoiceProduct[], coupon?: string) => {
  const items = convertCartProductsToItems(products);
  const beginCheckoutEvent: EventObject = {
    name: 'begin_checkout',
    properties: {
      currency: getChosenCurrency(),
      value: convertPriceToChosenCurrency(value),
      items,
    },
  };
  if (coupon) beginCheckoutEvent.properties.coupon = coupon;
  webAnalytics(beginCheckoutEvent, {}, true);
};

export const trackAddShippingInfoEvent = (value: number, products: InvoiceProduct[], coupon?: string) => {
  const items = convertCartProductsToItems(products);
  const event: EventObject = {
    name: 'add_shipping_info',
    properties: {
      currency: getChosenCurrency(),
      value: convertPriceToChosenCurrency(value),
      items,
      shipping_tier: 'delivery',
    },
  };
  if (coupon) event.properties.coupon = coupon;
  webAnalytics(event, {}, true);
};

export const trackAddPaymentInfoEvent = (value: number, products: InvoiceProduct[], paymentInfo: string, coupon?: string) => {
  const items = convertCartProductsToItems(products);
  const event: EventObject = {
    name: 'add_payment_info',
    properties: {
      currency: getChosenCurrency(),
      value: convertPriceToChosenCurrency(value),
      items,
      payment_type: paymentInfo,
    },
  };
  if (coupon) event.properties.coupon = coupon;
  webAnalytics(event, {}, true);
};

export const trackSuccessfulPurchase = (invoice: InvoiceModel, paymentInfo: string, coupon?: string) => {
  const items = convertCartProductsToItems(invoice.products);
  const purchaseSuccessEvent: EventObject = {
    name: 'purchase',
    properties: {
      transaction_id: invoice.invoice_number,
      currency: getChosenCurrency(),
      value: convertPriceToChosenCurrency(invoice.amount_grand_total),
      tax: convertPriceToChosenCurrency(invoice.amount_tax),
      shipping: 0,
      items,
      payment_type: paymentInfo,
    },
  };
  if (coupon) purchaseSuccessEvent.properties.coupon = coupon;
  webAnalytics(purchaseSuccessEvent, {}, true);
};

type ErrorObject = {
  error_code: string;
  error_message: string;
};

export const trackFailedPurchase = (invoice: InvoiceModel, errorObject: ErrorObject, coupon?: string) => {
  const items = convertCartProductsToItems(invoice.products);
  const purchaseFailedEvent: EventObject = {
    name: 'failed_purchase',
    properties: {
      ...errorObject,
      transaction_id: invoice.invoice_number,
      currency: getChosenCurrency(),
      value: convertPriceToChosenCurrency(invoice.amount_grand_total),
      tax: convertPriceToChosenCurrency(invoice.amount_tax),
      shipping: 0,
      items,
    },
  };
  if (coupon) purchaseFailedEvent.properties.coupon = coupon;
  webAnalytics(purchaseFailedEvent, {}, true);
};

type ErrorInput = {
  APIName: string;
  errorCode: string;
  errorMessage: string;
};

export const trackEncounteredError = (errorObject: ErrorInput) => {
  const errorEvent: EventObject = {
    name: 'encountered_error',
    properties: {
      error_code: errorObject.errorCode,
      error_message: errorObject.errorMessage,
      error_API: errorObject.APIName,
      error_page: document.URL,
    },
  };
  webAnalytics(errorEvent);
};

export type MiniGameTracker = {
  select: () => void;
  start: () => void;
  end: () => void;
};

export const createMinigameEventsTracker = (gameCode: string, gameName: string): MiniGameTracker => {
  let isGameInProgress = false;

  const select = () => {
    const event: EventObject = {
      name: 'selected_minigame',
      properties: {
        minigame_id: gameCode,
        minigame_name: gameName,
      },
    };
    webAnalytics(event);
  };

  const start = () => {
    const event: EventObject = {
      name: 'started_minigame',
      properties: {
        minigame_id: gameCode,
        minigame_name: gameName,
        start_time: moment().toString(),
      },
    };
    webAnalytics(event);
    isGameInProgress = true;
  };

  const end = () => {
    if (!isGameInProgress) return;
    const event: EventObject = {
      name: 'ended_minigame',
      properties: {
        minigame_id: gameCode,
        minigame_name: gameName,
        end_time: moment().toString(),
      },
    };
    webAnalytics(event);
  };

  return { select, start, end };
};
