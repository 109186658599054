import Skeleton from '@fe-web/Atoms/Skeleton/Skeleton';

import { BannerSkeleton } from './BannerSkeleton';
import ProductsSkeleton from './ProductSkeleton';
import { StaticBannerSkeleton } from './StaticBannerSkeleton';

export const ShopLandingSkeleton = () => {
  return (
    <>
      <div className="px-20 md:px-64 mt-[1.125rem] lg:px-120 2K:px-160 4K:px-320 8K:px-655">
        <Skeleton height="h-[1.75rem]" width="w-[20%]" className="aspect-[141/154] m-t-2 mb-20" />
      </div>
      <div className="flex flex-col gap-y-[5rem] SD:gap-y-[7.5rem] mt-[1.125rem] mb-40 SD:mb-[7.5rem]">
        <BannerSkeleton />
        <ProductsSkeleton count={6} />
        <StaticBannerSkeleton />
        <ProductsSkeleton count={6} />
      </div>
    </>
  );
};
