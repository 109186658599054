import { getCurrentDate } from '@fe-monorepo/helper';
import { Product } from '@fe-monorepo/models';
import {
  RootState,
  insertRecentlyViewed,
  removeAllRecentlyViewed,
  removeExpiredRecentlyViewed,
  updateRecentlyViewed,
  useAppDispatch,
} from '@fe-monorepo/store';
import moment from 'moment';
import { useSelector } from 'react-redux';

const DAYS_EXPIRED = 2;

export const useRecentlyViewed = () => {
  const dispatch = useAppDispatch();
  const recentlyViewed = useSelector((state: RootState) => state.recentlyViewed.recentlyViewed);

  const getAllRecentlyViewedProducts = () => {
    return recentlyViewed;
  };

  const addRecentlyViewed = (product: Product) => {
    const recentlyViewedItem = { ...product, dateInserted: getCurrentDate() };
    const inList = recentlyViewed?.some(recentProduct => {
      return recentProduct.product_code === product.product_code;
    });
    if (!inList) {
      dispatch(insertRecentlyViewed(recentlyViewedItem));
    } else {
      dispatch(updateRecentlyViewed(recentlyViewedItem));
    }
  };

  const clearAllRecentlyViewed = () => {
    dispatch(removeAllRecentlyViewed());
  };

  const getFilteredRecentlyViewed = () => {
    const today = moment();
    const renderedRecentlyViewedList = recentlyViewed.filter(item => {
      return today.isBefore(moment(item.dateInserted).add(DAYS_EXPIRED, 'day'));
    });
    return renderedRecentlyViewedList;
  };

  const clearExpiredRecentlyViewed = () => {
    const today = moment();
    const renderedRecentlyViewedList = recentlyViewed.filter(item => {
      return today.isBefore(moment(item.dateInserted).add(DAYS_EXPIRED, 'day'));
    });
    dispatch(removeExpiredRecentlyViewed(renderedRecentlyViewedList));
  };

  return {
    getAllRecentlyViewedProducts,
    clearAllRecentlyViewed,
    clearExpiredRecentlyViewed,
    getFilteredRecentlyViewed,
    addRecentlyViewed,
  };
};
