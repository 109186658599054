import { useMiniGames, useRanking, useTranslate } from '@fe-monorepo/hooks';
import { RankingModel } from '@fe-monorepo/models';
import Spinner from '@fe-web/Atoms/Load/Spinner';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useParams } from 'react-router-dom';

import SectionTitle from '../../../../../MiniGames/MiniGamesPages/components/SectionTitle';
import EmptySection from '../EmptySection';
import LeaderboardList from './LeaderboardList';
import Podium from './Podium';

interface Props {
  isMiniGame?: boolean;
  isGameOpen?: boolean;
}

const Leaderboard = ({ isMiniGame = false, isGameOpen }: Props) => {
  const { getTournmentRanking } = useRanking();
  const { translate } = useTranslate();
  const [ranking, setRanking] = useState<RankingModel[]>([]);
  const [userRank, setUserRank] = useState<RankingModel>();
  const tournamentId = useLocation().pathname.split('/')[3];
  const { getMiniGameLeaderboard, miniGameLeaderboardData, isLoading } = useMiniGames();
  const { miniGameID } = useParams();

  //MINI GAME FETCH DATA
  useEffect(() => {
    if (miniGameID && isMiniGame && !isGameOpen) {
      getMiniGameLeaderboard({ mini_game_code: miniGameID, page: 1 });
    }
  }, [miniGameID, isGameOpen]);

  //TOURNAMENT FETCH DATA
  useEffect(() => {
    if (!isMiniGame) {
      getTournmentRanking.fetch({
        tournament_id: tournamentId,
      });
    }
  }, [tournamentId]);

  //SET RANKING DATA HERE
  useEffect(() => {
    if (isMiniGame && miniGameLeaderboardData) {
      const miniGameData = miniGameLeaderboardData.data.leaderboard;
      const userRankData = miniGameLeaderboardData.data.user_rank;
      setRanking(
        miniGameData.map(item => ({
          rank: item.rank,
          score: item.total_wins,
          userName: item.username,
          displayName: item.display_name,
          userAvatar: item.avatar_url,
        })),
      );
      if (userRankData?.rank) {
        setUserRank({
          rank: userRankData?.rank,
          score: userRankData?.totalWins,
          userName: userRankData?.userName,
          displayName: '',
          userAvatar: userRankData?.profileImage,
        });
      }
    } else if (!isMiniGame && getTournmentRanking.data) {
      const tournamentData = getTournmentRanking.data.final_standing;
      setRanking(
        tournamentData.map(item => ({
          rank: item.rank,
          score: undefined,
          userName: item.participant.custom_user_identifier,
          displayName: item.participant.name,
          userAvatar: item.participant.custom_fields.avatar_url,
        })),
      );
    }
  }, [getTournmentRanking.data, miniGameLeaderboardData]);

  return !isLoading ? (
    <>
      {ranking && ranking.length > 0 ? (
        isMiniGame ? (
          <div className="inline-flex flex-col gap-[2.5rem]">
            <SectionTitle>{translate('mini_game.leaderboards')}</SectionTitle>
            <div className="inline-flex SD:flex-row flex-col SD:gap-[5.375rem] gap-[1rem] SD:justify-center SD:items-center justify-start">
              <Podium isMiniGame ranking={ranking} />
              <LeaderboardList isMiniGame ranking={ranking} userRank={userRank} />
            </div>
          </div>
        ) : (
          <div className="inline-flex SD:flex-row flex-col SD:gap-[5.375rem] gap-[1rem] SD:justify-center SD:items-center justify-start">
            <Podium isMiniGame ranking={ranking} />
            <LeaderboardList isMiniGame ranking={ranking} userRank={userRank} />
          </div>
        )
      ) : (
        <EmptySection description={translate('no_results_found') as string} />
      )}
    </>
  ) : (
    <span>
      <Spinner className="text-secondary" />
    </span>
  );
};

export default Leaderboard;
