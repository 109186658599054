import { Translation } from '@fe-monorepo/helper';
import { FeatureFlag, FirebaseSettingsModel, LMDModel } from '@fe-monorepo/models';
import {
  AppStateSliceState,
  setCountry,
  setCurrency,
  setFeatureFlags,
  setFirebaseSettings,
  setFirebaseToken,
  setHeaderColorChange,
  setInstallStatus,
  setLMDCode,
  setLanguage,
  setLoadingState,
  setPreferences,
  setRTLStatus,
  setSelectedLanguage,
  setServerErrorState,
  setThemes,
  setTimezone,
  setWishlistLoadingState,
  useAppDispatch,
} from '@fe-monorepo/store';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Platform } from 'react-native';

import { useTranslate } from '../useTranslate';

export const useAppState = () => {
  const dispatch = useAppDispatch();
  const { t, i18n } = useTranslation();
  const { translate } = useTranslate();

  const changeLanguage = async (language: 'en' | 'ar', selectedLanguage?: 'en' | 'ar') => {
    Platform.OS !== 'web' ? i18n.changeLanguage(language) : Translation(language);
    dispatch(setLanguage(language));
    dispatch(setRTLStatus(language === 'ar'));
    dispatch(
      setSelectedLanguage(
        selectedLanguage === 'ar' ? translate('matchmaking.languages.arabic') ?? '' : translate('matchmaking.languages.english') ?? '',
      ),
    );
    //PLACED THIS CONDITION TO HANDLE ERROR ON SOME USAGE THAT DON'T PASS SELECTEDLANGUAGE VALUE
    if (selectedLanguage !== undefined) {
      dispatch(setSelectedLanguage(selectedLanguage));
    }
  };

  const changePrefernces = async (prefs: AppStateSliceState) => {
    dispatch(setPreferences(prefs));
  };

  const changeThemes = async (theme: string) => {
    dispatch(setThemes(theme));
  };

  const changeCountry = async (country: string) => {
    dispatch(setCountry(country));
  };

  const changeCurrency = async (currency: AppStateSliceState) => {
    dispatch(setCurrency(currency));
  };

  const changeTimezone = async (timezone: string) => {
    dispatch(setTimezone(timezone));
  };

  const changeInstallStatus = useCallback(() => {
    dispatch(setInstallStatus(false));
  }, [dispatch]);

  const changeLoadingState = async (isLoading: boolean) => {
    dispatch(setLoadingState(isLoading));
  };

  const changeWishlistLoadingState = async (isWishlistLoading: boolean) => {
    dispatch(setWishlistLoadingState(isWishlistLoading));
  };

  const changeHeaderColorState = async (headerColorChange: boolean) => {
    dispatch(setHeaderColorChange(headerColorChange));
  };

  const changeServerErrorState = async (isServerError: boolean) => {
    dispatch(setServerErrorState(isServerError));
  };

  const changeFeatureFlags = async (featureFlags: FeatureFlag[]) => {
    dispatch(setFeatureFlags(featureFlags));
  };

  const changeLMDCode = async (lmd_code: LMDModel) => {
    dispatch(setLMDCode(lmd_code));
  };

  const changeFirebaseToken = async (token: string) => {
    dispatch(setFirebaseToken(token));
  };

  const changeFirebaseSettings = async (settings: FirebaseSettingsModel) => {
    dispatch(setFirebaseSettings(settings));
  };

  return {
    t,
    dispatch,
    changePrefernces,
    changeLanguage,
    changeCountry,
    changeCurrency,
    changeTimezone,
    changeThemes,
    changeLoadingState,
    changeWishlistLoadingState,
    changeInstallStatus,
    changeHeaderColorState,
    changeServerErrorState,
    changeFeatureFlags,
    changeLMDCode,
    changeFirebaseToken,
    changeFirebaseSettings,
  };
};
