import { Fragment, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation } from 'react-router-dom';

interface Props {
  pageTitle?: string;
  canonicalURL?: string;
  metaDescription?: string;
  metaKeyWords?: string;
  children?: ReactNode;
  image?: string;
  robots?: string;
}

const CustomHelmet = ({ children, pageTitle, canonicalURL, metaDescription, metaKeyWords, image, robots }: Props) => {
  const { pathname } = useLocation();
  const appUrl = process.env.NX_WEBSITE_URL;

  const summaryImage = 'https://lounges.tv/summary.jpeg';
  const metaHost = canonicalURL || appUrl;
  const metaTitle = pageTitle || 'STC Play -  Discover, shop & Play';
  const metaDesc =
    metaDescription ||
    'Dive into a platform that helps gamers discover everything about gaming.Moreover, connect with all gamers, find matches to compete against in epically organized tournaments, and level up your gaming gear and setup with over 4,000 products from the Shop.';
  const metaImageUrl = image || summaryImage;
  const metaImageType = image || 'image/jpeg';
  const metaImageRatios = [
    { width: 500, height: 267 },
    { width: 300, height: 160 },
    { width: 100, height: 53 },
    { width: 750, height: 400 },
    { width: 1000, height: 533 },
  ];
  const robotsTag = `${robots ? robots : 'noindex,nofollow'},max-image-preview:large, max-snippet:-1, max-video-preview:-1'}`;
  const schema = {
    '@context': 'https://schema.org',
    '@type': 'Organization',
    name: 'STC Play Ltd',
    description:
      'A hub for esports enthusiasts, featuring tournaments, meet-and-greets with professional players, and interactive gaming activities',
    logo: `https://play-lh.googleusercontent.com/skrRGY_S4C6mrROJ4ItpfTOxoS9GHif3PbVVKoWnjPbLmra9RSwu2vVsU5Idoo1OkUIl`,
    image: summaryImage,
    url: appUrl,
    telephone: '+123456',
    sameAs: [
      'https://www.facebook.com/@stcplay.hub',
      'https://x.com/stcplay',
      'https://www.instagram.com/stcplay/',
      'https://www.linkedin.com/company/stc-play',
    ],
    address: {
      '@type': 'PostalAddress',
      streetAddress: 'BLVD Riyadh',
      addressLocality: 'Riyadh ',
      postalCode: 'NW7 3TD',
      addressCountry: 'Saudi Arabia',
    },
  };
  const urlPath = pathname.replace('/', '');
  return (
    <Helmet>
      {/* Google recommends using Unicode/UTF‑8 where possible */}
      <meta charSet="UTF-8" />
      {/* mobile-friendly */}
      <meta content="initial-scale=1.0, width=device-width, maximum-scale=1.0, user-scalable=no" name="viewport" />
      {/* Title tag */}
      <title>{metaTitle}</title>
      {/* Description shown on search pages */}
      <meta content={metaDesc} name="description" />
      {/* Asking robots to index pages and crawls to subpages */}
      <meta content={robotsTag} name="robots" />
      <link href="/manifest.json" rel="manifest" />
      <meta content="STC Play" name="application-name" />
      {metaKeyWords && <meta name="keywords" content={metaKeyWords} />}
      {/* <link href={`${appUrl}/favicon.ico`} rel="shortcut icon" type="image/x-icon" />
        <link href={`${appUrl}/apple-touch-icon-57x57.png`} rel="apple-touch-icon-precomposed" sizes="57x57" />
        <link href={`${appUrl}/apple-touch-icon-60x60.png`} rel="apple-touch-icon-precomposed" sizes="60x60" />
        <link href={`${appUrl}/apple-touch-icon-72x72.png`} rel="apple-touch-icon-precomposed" sizes="72x72" />
        <link href={`${appUrl}/apple-touch-icon-76x76.png`} rel="apple-touch-icon-precomposed" sizes="76x76" />
        <link href={`${appUrl}/apple-touch-icon-114x114.png`} rel="apple-touch-icon-precomposed" sizes="114x114" />
        <link href={`${appUrl}/apple-touch-icon-120x120.png`} rel="apple-touch-icon-precomposed" sizes="120x120" />
        <link href={`${appUrl}/apple-touch-icon-144x144.png`} rel="apple-touch-icon-precomposed" sizes="144x144" />
        <link href={`${appUrl}/apple-touch-icon-152x152.png`} rel="apple-touch-icon-precomposed" sizes="152x152" />
        <link href={`${appUrl}/favicon-16x16.png`} rel="icon" sizes="16x16" type="image/png" />
        <link href={`${appUrl}/favicon-32x32.png`} rel="icon" sizes="32x32" type="image/png" />
        <link href={`${appUrl}/favicon-96x96.png`} rel="icon" sizes="96x96" type="image/png" />
        <link href={`${appUrl}/favicon-128.png`} rel="icon" sizes="128x128" type="image/png" />
        <link href={`${appUrl}/favicon-196x196.png`} rel="icon" sizes="196x196" type="image/png" /> */}
      <meta content="#000000" name="msapplication-TileColor" />
      {/* <meta content={`${appUrl}/mstile-70x70.png`} name="msapplication-square70x70logo" />
        <meta content={`${appUrl}/mstile-144x144.png`} name="msapplication-TileImage" />
        <meta content={`${appUrl}/mstile-150x150.png`} name="msapplication-square150x150logo" />
        <meta content={`${appUrl}/mstile-310x150.png`} name="msapplication-wide310x150logo" />
        <meta content={`${appUrl}/mstile-310x310.png`} name="msapplication-square310x310logo" /> */}

      {/* Open Graph start */}
      <link href={`${metaHost}${urlPath}`} rel="canonical" />
      <meta content={metaTitle} property="og:title" />
      <meta content="website" property="og:type" />
      <meta content={`${metaHost}${urlPath}`} property="og:url" />
      <meta content={metaDesc} property="og:description" />
      <meta content="the" property="og:determiner" />
      <meta content="en_GB" property="og:locale" />
      <meta content="STC Play" property="og:site_name" />
      {metaImageRatios.map(({ width, height }, index) => {
        return (
          <Fragment key={`${index}_${width}_${height}_${index}`}>
            <meta content={`${metaImageUrl}?format=${width}w`} property="og:image" />
            <meta content={`${width}`} property="og:image:width" />
            <meta content={`${height}`} property="og:image:height" />
          </Fragment>
        );
      })}
      <meta content={metaImageType} property="og:image:type" />
      <meta content="STC Play banner image" property="og:image:alt" />
      {/* Open Graph end */}

      {/* Twitter card start */}
      <meta content="summary_large_image" property="twitter:card" />
      <meta content={metaDesc} property="twitter:description" />
      <meta content={metaTitle} property="twitter:title" />
      <meta content={`${metaImageUrl}?format=300w`} property="twitter:image" />
      <meta content="STC Play banner image" property="twitter:image:alt" />

      {/* Twitter card end */}
      <script dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} key="structured-data-company" type="application/ld+json" />
      {/* Rest of the child elements according to page */}
      {children}
    </Helmet>
  );
};

export default CustomHelmet;
