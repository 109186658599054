import { useLazyQuery, useMutation } from '@apollo/client';
import { COMMUNITY_BLOCK_MUTATION, COMMUNITY_BLOCK_QUERIES } from '@fe-monorepo/data-access';
import { BlockInput, BlockResponse, GetListOfBlockedUsersInput, GetListOfBlockedUsersResponse, UnblockResponse } from '@fe-monorepo/models';

export const useBlock = () => {
  const [getListOfBlockedUsersGql, { data: getListOfBlockedUsersData }] = useLazyQuery<GetListOfBlockedUsersResponse>(
    COMMUNITY_BLOCK_QUERIES.getListOfBlockedUsers,
    {
      errorPolicy: 'all',
    },
  );

  const [crmCommunityBlockGql, { data: blockResponse }] = useMutation<BlockResponse>(COMMUNITY_BLOCK_MUTATION.crmCommunityBlock, {
    errorPolicy: 'all',
  });

  const [crmCommunityUnblockGql, { data: unblockResponse }] = useMutation<UnblockResponse>(COMMUNITY_BLOCK_MUTATION.crmCommunityUnblock, {
    errorPolicy: 'all',
  });

  const getListOfBlockedUsers = async (details: GetListOfBlockedUsersInput) => {
    const { data } = await getListOfBlockedUsersGql({ variables: { details } });
    return data;
  };

  const crmCommunityBlock = async (details: BlockInput) => {
    const { data } = await crmCommunityBlockGql({ variables: { details } });
    return data;
  };

  const crmCommunityUnblock = async (details: BlockInput) => {
    const { data } = await crmCommunityUnblockGql({ variables: { details } });
    return data;
  };

  return {
    getListOfBlockedUsers,
    crmCommunityBlock,
    crmCommunityUnblock,
    getListOfBlockedUsersData,
    blockResponse,
    unblockResponse,
  };
};
