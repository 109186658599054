// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { TxKeyPath, convertGMTToIana, getCurrentUserTimeZone } from '@fe-monorepo/helper';
import {
  CircuitMatchModel,
  PredictionsModalData,
  ProductCollectionInput,
  ShopBrandModel,
  ShopProductsModel,
  StandingRanking,
  TMSGetCircuitPredictionRankingResponseData,
  TMSGetCircuitPredictionWeeksResponseData,
  TmsMyTournaments,
  TmsSubmitPredictionInput,
} from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import { format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { ReactNode, createContext, useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { useShopListingProducts } from '../useShop/useShopListingProducts';
import { useTranslate } from '../useTranslate';
import { useUserProfile } from '../useUserProfile';
import { EWCBannerModel, SkinnyBannerModel, StreamsModel } from './models';
import { useCircuit } from './useCircuit';

type TabID = number | string;

export type TabType<T extends TabID> = {
  id: T;
  label: TxKeyPath;
};

const eWCKeys = ['schedules', 'standings', 'predictions', 'shop', 'watch'] as const;
const TabValuesObj = eWCKeys.reduce((acc, next) => ({ ...acc, [next]: null }), {} as Record<string, null>);

export type EWCTab = (typeof eWCKeys)[number];

export function isTabType(value: string): value is EWCTab {
  return value in TabValuesObj;
}

const eWCTabs: TabType<EWCTab>[] = [
  {
    id: 'schedules',
    label: 'ewc.tabs.schedule',
  },
  {
    id: 'standings',
    label: 'ewc.tabs.standings',
  },
  {
    id: 'predictions',
    label: 'ewc.tabs.predictions_leaderboard',
  },
  {
    id: 'shop',
    label: 'ewc.tabs.ewc_shop',
  },
  {
    id: 'watch',
    label: 'ewc.tabs.watch',
  },
];

export interface MatchItem {
  match: CircuitMatchModel;
  predictions?: PredictionsModalData;
}

export interface MatchesGroup {
  date: Date;
  checkDate: string;
  data: MatchItem[];
}

export enum PredictCardStatusTypes {
  SOON = 'soon',
  UPCOMING = 'upcoming',
  NOW = 'now',
  ENDED = 'ended',
}

interface UseEwcProvider {
  TABS: TabType<EWCTab>[];
  activeTab: EWCTab;
  setActiveTab: (value: EWCTab) => void;
  WEEKS: { id: string | number; label: string }[];
  activeWeek: number;
  onWeekClick: (value: number) => void;
  tournaments: TmsMyTournaments[];
  activeTournament: string;
  ewcBanners: EWCBannerModel[];
  ewcBannersLoading: boolean;
  skinnyBanners: SkinnyBannerModel;
  getSkinnyBanner: () => void;
  getBanners: () => void;
  handleGetTournaments: () => void;
  onTournamentClick: (value?: string) => void;
  standings: {
    loading: boolean;
    loadMoreLoading: boolean;
    data: StandingRanking[];
    loadMoreStandings: () => void;
    handleGetStandings: (params?: { last_cursor?: number; direction?: string; limit?: number }) => Promise<void>;
    resetStandingsData: () => void;
    hasMoreStandings: boolean;
  };
  predictions: {
    loading: boolean;
    loadMoreLoading: boolean;
    data: TMSGetCircuitPredictionRankingResponseData | null;
    loadMorePredictions: () => void;
    handleGetPredictions: (params: { week_id: number; last_cursor?: number; direction?: string }) => Promise<void>;
    resetPredictionsData: () => void;
    hasMorePredictions: boolean;
    weeks: TMSGetCircuitPredictionWeeksResponseData[];
    weeksLoading: boolean;
    handleGetPredictionWeeks: () => Promise<void>;
    getFallbackWeekScheduleByActiveWeek: (activeWeek: number) => WeekSchedule | undefined;
  };
  predict: {
    loading: boolean;
    matchToPredict?: MatchItem;
    setMatchToPredict: (value?: MatchItem) => void;
    onPredictMatch: (value: TmsSubmitPredictionInput) => void;
    onPredictButtonClick: (value?: MatchItem) => void;
    showGuestModal: boolean;
    setShowGuestModal: (value: boolean) => void;
  };
  matches: {
    loading: boolean;
    data: MatchesGroup[];
    handleGetMatches: ({ page_number, tournament_id, reset }: { page_number: number; tournament_id: string; reset?: boolean }) => void;
    onMatchesLoadmore: () => void;
    loadNext: boolean;
    previousLoading: boolean;
    previousLoadNext: boolean;
    onPreviousMatchesLoadmore: () => void;
  };
  shop: {
    loading: boolean;
    count: number;
    params: ProductCollectionInput;
    data: ShopProductsModel[];
    brands: ShopBrandModel[];
    setProductsParams: (value: ProductCollectionInput | ((prevVar: ProductCollectionInput) => ProductCollectionInput)) => void;
    handleGetProducts: (loadMore?: boolean) => Promise<void>;
    handleGetBrands: () => Promise<void>;
    resetProductData: () => void;
    onProductLoadmore: () => void;
    productsLoadable: boolean;
  };
  streams: {
    data: [StreamsModel];
    handleGetStreams: () => void;
  };
  ewcMatches: CircuitMatchModel[];
  getEWCCardData: () => void;
  bannerLoading: boolean;
  activePushNotificationTab: null | EWCTab;
  setActivePushNotificationTab: (tab: null | EWCTab) => void;
}

const EwcContext = createContext<any>(undefined);

// TODO: fix the type here.
const WEEKS: { id: number; label: string }[] = [
  { id: 1, label: 'Week 1' },
  { id: 2, label: 'Week 2' },
  { id: 3, label: 'Week 3' },
  { id: 4, label: 'Week 4' },
  { id: 5, label: 'Week 5' },
  { id: 6, label: 'Week 6' },
  { id: 7, label: 'Week 7' },
  { id: 8, label: 'Week 8' },
  { id: 9, label: 'Final' },
];

export interface WeekSchedule {
  week: number;
  startDate: string;
  endDate: string;
}

export const fallbackWeekSchedule: WeekSchedule[] = [
  {
    week: 1,
    startDate: '2024-07-03T08:00:00+00:00', // Converted from 03/07/2024 11:00 AM KSA
    endDate: '2024-07-07T20:59:00+00:00', // Converted from 07/07/2024 11:59 PM KSA
  },
  {
    week: 2,
    startDate: '2024-07-10T08:00:00+00:00', // Converted from 10/07/2024 11:00 AM KSA
    endDate: '2024-07-14T20:59:00+00:00', // Converted from 14/07/2024 11:59 PM KSA
  },
  {
    week: 3,
    startDate: '2024-07-16T09:00:00+00:00', // Converted from 16/07/2024 12:00 PM KSA
    endDate: '2024-07-21T20:59:00+00:00', // Converted from 21/07/2024 11:59 PM KSA
  },
  {
    week: 4,
    startDate: '2024-07-23T11:00:00+00:00', // Converted from 23/07/2024 2:00 PM KSA
    endDate: '2024-07-28T20:59:00+00:00', // Converted from 28/07/2024 11:59 PM KSA
  },
  {
    week: 5,
    startDate: '2024-07-31T10:00:00+00:00', // Converted from 31/07/2024 1:00 PM KSA
    endDate: '2024-08-04T20:59:00+00:00', // Converted from 04/08/2024 11:59 PM KSA
  },
  {
    week: 6,
    startDate: '2024-08-08T12:00:00+00:00', // Converted from 08/08/2024 3:00 PM KSA
    endDate: '2024-08-11T20:59:00+00:00', // Converted from 11/08/2024 11:59 PM KSA
  },
  {
    week: 7,
    startDate: '2024-08-14T10:00:00+00:00', // Converted from 14/08/2024 1:00 PM KSA
    endDate: '2024-08-18T20:59:00+00:00', // Converted from 18/08/2024 11:59 PM KSA
  },
  {
    week: 8,
    startDate: '2024-08-21T15:00:00+00:00', // Converted from 21/08/2024 6:00 PM KSA
    endDate: '2024-08-25T20:59:00+00:00', // Converted from 25/08/2024 11:59 PM KSA
  },
  {
    week: 0,
    startDate: '2024-08-29T13:00:00+00:00', // Converted from 26/08/2024 4:00 PM KSA due to 72-hour logic, making it 29/08/2024 so that 72 hours back brings it to 26/08/2024
    endDate: '2024-09-18T20:59:00+00:00', // Converted from 25/09/2024 11:59 PM KSA
  },
];

export type ProductSort = { id: string; label: string };

export const SHOP_SORT_OPTIONS: ProductSort[] = [
  { id: 'asc', label: 'shop.filters.price.low-to-high' },
  { id: 'desc', label: 'shop.filters.price.high-to-low' },
];

export const EwcProvider = ({
  children,
  showToastMessage,
}: {
  children?: ReactNode;
  showToastMessage: (message: string, type: 'success' | 'error') => void;
}) => {
  const { translate } = useTranslate();
  const { isLoggedIn } = useUserProfile();
  const {
    getAllCircuitTournament,
    getAllCircuitMatch,
    submitPrediction,
    getAllPredictions,
    getPredictionRankings,
    getStandingRankings,
    getEWCBanners,
    getAllStreams,
    getPredictionWeeks,
  } = useCircuit();
  const [predictLoading, setPredictLoading] = useState<boolean>(false);
  const [matchLoading, setMatchLoading] = useState<boolean>(false);
  const [matchLoadable, setMatchLoadable] = useState<boolean>(false);
  const [matchPageNumber, setMatchPageNumber] = useState<number>(1);
  const [matchPreviousLoadable, setMatchPreviousLoadable] = useState<boolean>(true);
  const [matchPreviousPageNumber, setMatchPreviousPageNumber] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<EWCTab>(eWCTabs[0].id);
  const [activeWeek, setActiveWeek] = useState<number>(1);
  const [activeTournament, setActiveTournament] = useState<string>('');
  const [tournaments, setTournaments] = useState<TmsMyTournaments[]>([]);
  const [matches, setMatches] = useState<MatchesGroup[]>([]);
  const [standingsLoading, setStandingsLoading] = useState<boolean>(false);
  const [standings, setStandings] = useState<StandingRanking[]>([]);
  const [predictionsLoading, setPredictionsLoading] = useState<boolean>(true);
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false);
  const [hasMoreData, setHasMoreData] = useState<boolean>(true);
  const [hasMorePredictions, setHashMorePredictions] = useState<boolean>(true);
  const [hasMoreStandings, setHasMoreStandings] = useState<boolean>(true);
  const [predictions, setPredictions] = useState<TMSGetCircuitPredictionRankingResponseData | null>(null);
  const [showGuestModal, setShowGuestModal] = useState<boolean>(false);
  const [matchToPredict, setMatchToPredict] = useState<MatchItem>();
  const [ewcBanners, setEWCBanners] = useState<EWCBannerModel[]>([]);
  const [ewcBannersLoading, setEwcBannersLoading] = useState<boolean>(true);
  const [skinnyBanners, setSkinnyBanners] = useState<SkinnyBannerModel | null>(null);
  const [streams, setStreams] = useState<StreamsModel[]>([]);
  const [ewcMatches, setEwcMatches] = useState<CircuitMatchModel[]>([]);
  const [bannerLoading, setBannerLoading] = useState(false);
  const [weeks, setWeeks] = useState<TMSGetCircuitPredictionWeeksResponseData[]>([]);
  const [weeksLoading, setWeeksLoading] = useState<boolean>(true);
  const [activePushNotificationTab, setActivePushNotificationTab] = useState<null | EWCTab>(null);
  const preferred_timezone = useSelector((state: RootState) => state.user.userContext.preferred_timezone);
  const timezone = useSelector((state: RootState) => state.app.timezone);
  const onTournamentClick = (value: string) => {
    if (value === activeTournament) return;

    setActiveTournament(value);
    setMatches([]);
    handleGetMatches({ page_number: 1, tournament_id: value });
  };

  const onPredictButtonClick = (match?: MatchItem) => {
    if (isLoggedIn) {
      setMatchToPredict(match);
    } else {
      setShowGuestModal(true);
    }
  };

  const onPredictMatch = async (body: TmsSubmitPredictionInput) => {
    try {
      setPredictLoading(true);
      const data = await submitPrediction(body);
      if (data?.is_successful) {
        const prediction_response = await getAllPredictions({ match_ids: [body?.reference_match_id] });
        setMatches(value =>
          value.map((group: MatchesGroup) => ({
            ...group,
            data: group.data.map(match => {
              const tempMatch = { ...match };
              if (tempMatch.match.id === body.reference_match_id) {
                tempMatch.match.user_prediction = body.reference_team_id;
                tempMatch['predictions'] = prediction_response?.data[0];
              }
              return tempMatch;
            }),
          })),
        );
        setMatchToPredict(undefined);
        setPredictLoading(false);
        showToastMessage(translate('ewc.predictModal.message') ?? '', 'success');
      } else {
        setPredictLoading(false);
        showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
      }
    } catch (error: any) {
      setPredictLoading(false);
      showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
    }
  };

  const handleGetTournaments = async () => {
    try {
      const data = await getAllCircuitTournament({});
      if (data?.is_successful) {
        setTournaments(data?.data ?? []);
      } else {
        showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
      }
    } catch (error: any) {
      showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
    }
  };

  const getEWCCardData = async () => {
    setBannerLoading(true);
    const data = await getAllCircuitMatch({ page_number: 1, type: 'card' });

    if (data?.is_successful) {
      setEwcMatches(data.data);
    }
    setBannerLoading(false);
  };

  const handleGetEWCBanners = async () => {
    try {
      const data = await getEWCBanners({});
      if (data?.is_successful) {
        setEWCBanners(data?.response?.banner_items ?? []);
      } else {
        showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
      }
    } catch (error: any) {
      showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
    } finally {
      setEwcBannersLoading(false);
    }
  };

  const handleGetSkinnyBanners = async () => {
    try {
      const data = await getEWCBanners({ type: 'skinny_banner' });
      if (data?.is_successful && data?.response?.banner_type) {
        setSkinnyBanners(data?.response ?? []);
      }
    } catch (error: any) {
      showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
    }
  };

  const handleGetStreams = async () => {
    try {
      const data = await getAllStreams();
      if (data?.is_successful) {
        setStreams(data?.response ?? []);
      } else {
        showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
      }
    } catch (error: any) {
      showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
    }
  };

  const handleGetMatches = async (params: {
    page_number?: number;
    tournament_id?: string;
    direction?: 'previous' | 'next';
    reset?: boolean;
  }) => {
    try {
      delete params.reset;
      params.direction = params.direction ?? 'next';
      params.page_number = params.page_number ?? 1;
      const reset = params.page_number === 1 && params.direction === 'next';
      setMatchLoading(true);
      if (reset) {
        setMatchPreviousPageNumber(0);
        setMatchPreviousLoadable(true);
      }
      const data = await getAllCircuitMatch({ ...params, type: 'schedule' });
      if (data?.is_successful) {
        let matchesData = data.data;
        let predictionsResponse = (await getAllPredictions({ match_ids: matchesData.map(match => match.id) }))?.data ?? [];
        if (reset) {
          const preData = await getAllCircuitMatch({ ...params, type: 'schedule', direction: 'previous' });
          if (preData?.is_successful) {
            setMatchPreviousLoadable(((preData?.data ?? [])?.length ?? 0) > 0);
            const prePredictionsResponse = (await getAllPredictions({ match_ids: preData.data.map(match => match.id) }))?.data ?? [];
            matchesData = matchesData.concat(preData.data.slice(0, 2).map(item => ({ ...item })));
            predictionsResponse = predictionsResponse.concat(prePredictionsResponse);
          }
        }

        const tempMatches: MatchesGroup[] = reset ? [] : [...matches];
        matchesData.forEach(match => {
          if (match.scheduled_datetime) {
            const scheduledDate = parseISO(match.scheduled_datetime);
            const finalTimezone =
              preferred_timezone || timezone ? convertGMTToIana(preferred_timezone || timezone) : getCurrentUserTimeZone();
            const date = toZonedTime(scheduledDate, finalTimezone);
            const checkDate = format(date, 'EEE MMM dd');
            let targetIndex = tempMatches.findIndex(item => item.checkDate === checkDate);
            if (targetIndex === -1) {
              tempMatches.push({ date, checkDate, data: [] });
              targetIndex = tempMatches.length - 1;
            }
            if (tempMatches?.[targetIndex]?.data.findIndex(item => item.match.id === match.id) === -1) {
              tempMatches?.[targetIndex]?.data.push({
                match,
                predictions: predictionsResponse.find(item => match.id === item.match_id),
              });
              if (tempMatches?.[targetIndex]?.data) {
                tempMatches[targetIndex].data = tempMatches?.[targetIndex]?.data.sort((a, b) =>
                  (a.match.scheduled_datetime ?? '') > (b.match.scheduled_datetime ?? '') ? 1 : -1,
                );
              }
            }
          }
        });

        setMatches(tempMatches.sort((a, b) => (a.date > b.date ? 1 : -1)));

        if (params.direction === 'previous') {
          setMatchPreviousPageNumber(params?.page_number ?? 1);
          setMatchPreviousLoadable(((data?.data ?? [])?.length ?? 0) >= 10);
        } else {
          setMatchPageNumber(params?.page_number ?? 1);
          setMatchLoadable(((data?.data ?? [])?.length ?? 0) >= 10);
        }
        setMatchLoading(false);
      } else {
        setMatchLoading(false);
        showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
      }
    } catch (error: any) {
      setMatchLoading(false);
      showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
    }
  };

  const onMatchesLoadmore = () => {
    if (!matchLoading && matchLoadable) handleGetMatches({ page_number: matchPageNumber + 1, tournament_id: activeTournament });
  };

  const onPreviousMatchesLoadmore = () => {
    if (!matchLoading && matchPreviousLoadable)
      handleGetMatches({ page_number: matchPreviousPageNumber + 1, tournament_id: activeTournament, direction: 'previous' });
  };

  const handleGetStandings = async (params?: { last_cursor?: number; direction?: string; limit?: number }) => {
    const isLoadMore = params?.direction === 'next';

    if (isLoadMore) {
      setLoadMoreLoading(true);
    } else {
      setStandingsLoading(true);
    }

    try {
      const res = await getStandingRankings({ ...params });
      if (res?.is_successful) {
        if (isLoadMore) {
          setStandings(prev => [...prev, ...res.data]);
          if (res.data.length === 0) {
            setHasMoreData(false);
            setHasMoreStandings(false);
          }
        } else {
          setStandings(res.data);
          setHasMoreData(true); // Reset hasMoreData when fetching new data
          setHasMoreStandings(true);
        }
      } else {
        if (!isLoadMore) setStandings([]);
        setHasMoreData(false);
        setHasMoreStandings(false);
      }
    } catch (error: any) {
      showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
    } finally {
      setStandingsLoading(false);
      setLoadMoreLoading(false);
    }
  };

  const loadMoreStandings = () => {
    if (hasMoreData) {
      handleGetStandings({
        last_cursor: standings[standings.length - 1]?.last_cursor,
        direction: 'next',
        limit: 9,
      });
    }
  };

  const handleGetPredictions = async (params: { week_id: number; last_cursor?: number; direction?: string }) => {
    const isLoadMore = params.direction === 'next';
    if (isLoadMore) {
      setLoadMoreLoading(true);
    } else {
      setPredictionsLoading(true);
    }

    try {
      const res = await getPredictionRankings({ ...params });
      if (res?.is_successful) {
        setPredictions(prev => ({
          ...res.data,
          rankings: isLoadMore ? [...(prev?.rankings || []), ...res.data.rankings] : res.data.rankings,
        }));

        if (isLoadMore && res.data.rankings.length === 0) {
          setHasMoreData(false);
          setHashMorePredictions(false);
        }
      } else {
        setPredictions(null);
        setHasMoreData(false);
        setHashMorePredictions(false);
      }
    } catch (error: any) {
      showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
    } finally {
      setPredictionsLoading(false);
      setLoadMoreLoading(false);
    }
  };

  const loadMorePredictions = () => {
    if (hasMoreData) {
      handleGetPredictions({
        week_id: activeWeek,
        last_cursor: predictions?.rankings[predictions.rankings.length - 1]?.last_cursor,
        direction: 'next',
      });
    }
  };

  const handleGetPredictionWeeks = async () => {
    setWeeksLoading(true);

    try {
      const res = await getPredictionWeeks();
      if (res?.is_successful) {
        setWeeks(res.data);
      } else {
        setWeeks([]);
        setWeeksLoading(false);
      }
    } catch (error) {
      showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
    } finally {
      setWeeksLoading(false);
    }
  };

  const onWeekClick = (value: number) => {
    if (value !== activeWeek) {
      setPredictions(null);
      setActiveWeek(value);
      setHasMoreData(true);
      setPredictionsLoading(true);
      setLoadMoreLoading(false);

      handleGetPredictions({ week_id: value });
    }
  };

  const getFallbackWeekScheduleByActiveWeek = (activeWeek: number): WeekSchedule | undefined => {
    return fallbackWeekSchedule.find(week => week.week === activeWeek);
  };

  const resetStandingsData = () => {
    setStandings([]);
    setStandingsLoading(false);
    setHasMoreData(true);
    setHasMoreStandings(true);
    setLoadMoreLoading(false);
  };

  const resetPredictionsData = () => {
    setPredictions(null);
    setActiveWeek(1);
    setWeeks([]);
    setWeeksLoading(true);
    setHasMoreData(true);
    setHashMorePredictions(true);
    setPredictionsLoading(true);
    setLoadMoreLoading(false);
  };

  // Shop
  const { getAll: getAllProducts, getAllBrand } = useShopListingProducts();
  const [brands, setBrands] = useState<ShopBrandModel[]>([]);
  const [productsLoading, setProductsLoading] = useState<boolean>(false);
  const [productsParams, setProductsParams] = useState<ProductCollectionInput>({ sort_type: 'asc' });
  const [products, setProducts] = useState<ShopProductsModel[]>([]);
  const [productsCount, setProductsCount] = useState<number>(0);
  const [productsLoadable, setProductsLoadable] = useState<boolean>(true);
  const handleGetProducts = async (loadMore?: boolean) => {
    const isPaginating = loadMore && products.length > 0;
    const lastCursor = isPaginating ? products[products.length - 1]?.last_cursor : undefined;

    try {
      setProductsLoading(true);

      const res = await getAllProducts({
        ...productsParams,
        collection_code: 'ewc_shop',
        ...(isPaginating && { last_cursor: lastCursor, direction: 'next' }),
      });

      if (res?.is_successful && res.data) {
        const fetchedProducts = res.data.list;
        const totalFetched = fetchedProducts.length;

        setProducts(prevProducts => (isPaginating ? [...prevProducts, ...fetchedProducts] : fetchedProducts));

        setProductsCount(res.data.count);
        setProductsLoadable(totalFetched !== 0);
      } else {
        showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
      }
    } catch (error: any) {
      showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
    } finally {
      setProductsLoading(false);
    }
  };

  const onProductLoadmore = () => {
    if (!productsLoading && productsLoadable) handleGetProducts(true);
  };

  const handleGetBrands = async () => {
    try {
      const data = await getAllBrand({ collection_code: 'ewc_shop' });
      if (data?.is_successful) {
        setBrands(data?.data ?? []);
      } else {
        showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
      }
      setBrands(data?.data ?? []);
    } catch (error: any) {
      showToastMessage(translate('error.something_went_wrong_title') ?? '', 'error');
    }
  };

  const resetProductData = () => {
    setProducts([]);
    setBrands([]);
    setProductsCount(0);
    setProductsLoading(false);
    setProductsLoadable(true);
    setProductsParams({ sort_type: 'asc' });
  };

  return (
    <EwcContext.Provider
      value={{
        TABS: eWCTabs,
        activeTab,
        setActiveTab,
        WEEKS,
        activeWeek,
        onWeekClick,
        tournaments,
        activeTournament,
        ewcBanners,
        ewcBannersLoading,
        skinnyBanners,
        getSkinnyBanner: handleGetSkinnyBanners,
        getBanners: handleGetEWCBanners,
        handleGetTournaments,
        onTournamentClick,
        standings: {
          loading: standingsLoading,
          data: standings,
          handleGetStandings,
          loadMoreStandings,
          loadMoreLoading,
          resetStandingsData,
          hasMoreStandings,
        },
        predictions: {
          loading: predictionsLoading,
          data: predictions,
          loadMorePredictions,
          loadMoreLoading,
          handleGetPredictions,
          resetPredictionsData,
          hasMorePredictions,
          weeks,
          weeksLoading,
          handleGetPredictionWeeks,
          getFallbackWeekScheduleByActiveWeek,
        },
        predict: {
          loading: predictLoading,
          matchToPredict,
          setMatchToPredict,
          onPredictMatch,
          onPredictButtonClick,
          showGuestModal,
          setShowGuestModal,
        },
        matches: {
          loading: matchLoading,
          data: matches,
          onMatchesLoadmore,
          handleGetMatches,
          loadNext: matchLoadable,
          previousLoading: matchLoading,
          previousLoadNext: matchPreviousLoadable,
          onPreviousMatchesLoadmore,
        },
        shop: {
          loading: productsLoading,
          count: productsCount,
          params: productsParams,
          data: products,
          brands,
          productsLoadable,
          setProductsParams,
          handleGetProducts,
          handleGetBrands,
          resetProductData,
          onProductLoadmore,
        },
        streams: {
          data: streams,
          handleGetStreams,
        },
        ewcMatches,
        getEWCCardData,
        bannerLoading,
        activePushNotificationTab,
        setActivePushNotificationTab,
      }}
    >
      {children}
    </EwcContext.Provider>
  );
};

export const useEwcProvider = (): UseEwcProvider => {
  const context = useContext(EwcContext);

  if (!context) throw 'Please use a provider to use this hook';

  return context;
};
