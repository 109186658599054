import { useCurrencyWeb, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import CookiesPopup from '@fe-web/Organisms/CookieConsent';
import VerifyModal from '@fe-web/Organisms/VerifyModal/VerifyModal';
import useRecentSearch from '@fe-web/hooks/useRecentSearch';
import useVerifyDialogToggle from 'libs/hooks/src/useVerifyDialogToggle';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { AppRoutes } from '../../app.routes.enum';
import Header from '../../components/Organisms/Header/Header';
import ShopSettingsMenu from './ShopSettingsMenu';
import TermsAndConditionsModal from './TermsAndConditionsModal';

//create an interface for the DashboardScreenLayout which will receive a Footer as a component
interface DashboardScreenLayoutProps {
  footer?: JSX.Element | null;
  className?: string;
  isFullScreen?: boolean;
}

const DashboardScreenLayout = ({ footer, className, isFullScreen }: DashboardScreenLayoutProps) => {
  //get the state of the isUserSettingsOpen from the rootState
  const isUserSettingsOpen = useSelector((state: RootState) => state.app.isUserSettingsOpen);
  // useDispatch to dispatch the action to close the user settings menu
  const currencyWeb = useCurrencyWeb();
  const { isOpen } = useVerifyDialogToggle();
  const { isOpen: isRecentSearchOpen } = useRecentSearch();
  const location = useLocation();
  const isOnFAQs = location.pathname.includes(AppRoutes.help);
  const { isLoggedIn } = useUserProfile();
  const showCookieConsent = useSelector((state: RootState) => state.app.showCookieConsent);

  return (
    <div className="min-h-screen h-full fixed top-0 z-[500] w-full">
      <main
        className={`mx-auto h-full w-full 
            ${isRecentSearchOpen ? 'fixed overscroll-none' : 'overflow-scroll'}
            ${isOnFAQs ? 'overscroll-none' : ''}
            `}
      >
        {isUserSettingsOpen && <ShopSettingsMenu currencyWeb={currencyWeb} />}
        {isOpen && <VerifyModal />}
        <TermsAndConditionsModal />
        {!isLoggedIn && showCookieConsent && <CookiesPopup />}
        <Header />
        <div className={`${className} flex flex-col ${isFullScreen ? '' : 'min-h-screen'} justify-between bg-primary w-full `}>
          <section className="flex-1 w-full h-full mb-auto overflow-x-clip">
            <Outlet />
          </section>
          {footer}
        </div>
      </main>
    </div>
  );
};

export default DashboardScreenLayout;
