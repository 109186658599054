interface ResponseModel {
  is_successful: boolean;
  error_code: string;
  error_msg: string;
}

interface NotificationMessageData {
  isMinimized?: boolean;
  id?: string;
  msg_id?: string;
  title?: string;
  body_en?: string;
  body_ar?: string;
  bodyHTML_en?: string;
  bodyHTML_ar?: string;
  image?: string;
  event_type?: string;
  is_read?: string;
  created?: string;
  notification_type?: string;
  conversation_id?: string;
  conversation_type?: string;
  type?: string;
  deeplink_scheme?: string;
  avatar_url?: string;
  conversation_message?: string;
  conversation_name?: string;
  invoice_number?: string;
  shipment_number?: string;
  return_number?: string;
  collection_code?: string;
  product_code?: string;
  mini_game_code?: string;
  tournament_id?: string;
  username?: string;
  currentTime?: string;
  order_id?: string;
  cursor?: string;
  sender_username?: string;
  media_url?: string;
  image_url?: string;
  is_following?: boolean;
  follower_username?: string;
  follower_display_name?: string;
  follower_notification_type?: string;
  game_code?: string;
  title_en?: string;
  title_ar?: string;
  opponent_display_name?: string;
  return_product_id?: string;
  message?: string;
  asset_id?: string;
  asset_type?: string;
  comment_parent_id?: String;
  comment_reply_id?: String;
  video_thumbnail?: String;
  description?: String;
  mob_deeplink?: string;
  asset_title?: string;
  status?: string;
  status_en?: string;
  status_ar?: string;
}

enum EventType {
  // CHAT
  chat = 'chat_message_send',
  chatPrivateTextSent = 'chat_privatemessagetext_sent',
  chatPrivateImageSent = 'chat_privatemessageimage_sent',
  chatPrivateVideoSent = 'chat_privatemessagevideo_sent',
  chatPrivateVideoShared = 'chat_privatemessagevideo_shared',
  chatGroupTextSent = 'chat_groupmessagetext_sent',
  chatGroupTextReceived = 'chat_groupmessagetext_received',
  chatGroupImageSent = 'chat_groupmessageimage_sent',
  chatGroupVideoSent = 'chat_groupmessagevideo_sent',
  chatGroupLeft = 'chat_groupmember_left',
  chatGroupUpdate = 'chat_groupname_update',
  // SHOP
  orderSingle = 'shop_orders_dispatched_single_item',
  orderMultiple = 'shop_orders_dispatched_multiple_item',
  returnSingle = 'shop_returns_dispatched_single_item',
  returnMultiple = 'shop_returns_dispatched_multiple_item',
  productWishList =  'product_wishlist',

  plp = 'shop_product_check',
  pdp = 'shop_product_view',

  productWishlist = 'product_wishlist',
  orderDispatched = 'shop_order_dispatched',
  orderDelivered = 'shop_order_delivered',
  orderDeliveredShop = 'shop_orderitem_delivered', // Added 03/13/2024
  orderPlaced = 'shop_order_placed',
  orderRejected = 'shop_order_rejected',

  //Changes for order product 03/19/2024
  orderItemPlaced = 'shop_ordershipment_placed',
  orderItemDispatched = 'shop_ordershipment_dispatched',
  orderItemDelivered = 'shop_ordershipment_delivered',

  returnPlaced = 'shop_return_placed',
  returnSingleRejected = 'shop_return_rejected',
  returnActionRequired = 'shop_return_actionrequired',
  returnDelivered = 'shop_return_delivered',
  returnRejected = 'shop_return_rejected', // Added 03/13/2024
  returnCompleted = 'shop_return_completed', // Added 03/13/2024

  returnShipmentPlaced = 'shop_returnshipment_placed',
  returnShipmentActionRequired = 'shop_return_actionrequired', // Updated 03/13/2024

  //Changes for return product 03/19/2024
  returnItemPlaced = 'shop_returnproduct_accepted',
  returnItemReject = 'shop_returnproduct_rejected',
  returnItemActionRequired = 'shop_returnproduct_actionrequired',
  returnItemClosed = 'shop_returnproduct_closed',
  returnItemDispatched = 'shop_returnproduct_dispatched',
  returnItemCompleted = 'shop_returnproduct_completed',

  returnDispatched = 'shop_return_dispatched',

  orderShipmentDispatched = 'shop_ordershipment_dispatched',
  returnShipmentDispatched = 'shop_returnshipment_dispatched',
  returnShipmentCompleted = 'shop_returnshipment_completed',

  // MATCHMAKING
  matchmakingUserFollowed = 'matchmaking_user_followed',
  matchmakingPreferences = 'matchmaking_preferences_completerequired',
  matchmakingSearch = 'matchmaking_match_search',
  matchmakingFound = 'matchmaking_match_found',
  // MINI GAMES
  minigamesNewUser = 'minigames_newuser_receive',
  minigamesReturningUser = 'minigames_returninguser_receive',
  minigamesHighscoreSet = 'minigames_highscore_set',
  minigamesHighscoreBeaten = 'minigames_highscore_beaten',
  minigamesDone = 'minigames_player_done',
  minigamesOtherGames = 'minigames_othergames_play',
  minigamesUpdate = 'minigames_games_update',
  minigamesNewGames = 'minigames_newgames_play',
  // Tournament
  tournamentList = 'tournament_list',
  tournamentDetails = 'tournament_details',
  tournamentCheckinOpen = 'tournament_checkin_open',
  tournamentCheckinMissed = 'tournament_checkin_Missed',
  tournamentCheckinClosed = 'tournament_checkin_closed',
  tournamentScoreReported = 'tournament_opponentscore_reported',
  tournamentEnded = 'tournament_winners_ended',
  tournamentCancelled = 'tournament_details_cancelled',
  // Bits
  bits = 'bits',
  // Profile
  profileUserFollowed = 'profile_user_followed',

  //Quickplay bits
  qpLike = 'qp_like',
  qpMention = 'qp_mention',
  qpReply = 'qp_reply',
  qpComment = 'qp_comment',
  qpModeration = 'qp_moderation',
  qpFollow = 'qp_follow',
  qpCommentRemoved = 'qp_comment_removed',

  // EWC
  ewcSchedule = 'ewc_schedules',
  ewcStandings = 'ewc_standings',
  ewcPredictions = 'ewc_predictions',
  ewcShop = 'ewc_shop',
  ewcWatch = 'ewc_watch',
}

interface NotificationMessageGetAllInput {
  cursor?: string;
  limit?: number;
  is_read?: string;
  event_type?: string;
  event_group_type?: string;
  period_start?: number;
  period_end?: number;
}

interface NotificationMessageUpdateInput {
  message_id: string;
  is_read: string;
}

interface NotificationMessageSendInput {
  title: string;
  body?: string;
  body_en?: string;
  body_ar?: string;
  bodyHTML?: string;
  bodyHTML_en?: string;
  bodyHTML_ar?: string;
  image?: string;
  recipients: string;
  event_type: string;
  notification_type?: string;
  conversation_id?: string;
  conversation_type?: string;
  type?: string;
  avatar_url?: string;
  conversation_message?: string;
  conversation_name?: string;
  invoice_number?: string;
  shipment_number?: string;
  return_number?: string;
  collection_code?: string;
  product_code?: string;
  mini_game_code?: string;
  tournament_id?: string;
  order_id?: string;
  username?: string;
  media_url?: string;
  image_url?: string;
  sender_username?: string;
  is_following?: boolean;
  follower_username?: string;
  follower_display_name?: string;
  follower_notification_type?: string;
  game_code?: string;
  title_en?: string;
  title_ar?: string;
  opponent_display_name?: string;
  message?: string;
  asset_id?: string;
  asset_type?: string;
  comment_parent_id?: String;
  comment_reply_id?: String;
  video_thumbnail?: String;
  description?: String;
}

interface RemoveInput {
  message_id: string;
}

type NotificationMessageDataGetAll = {
  total: number;
  cursor: string;
  data: NotificationMessageData[];
};

type ResponseModelGetAll = {
  is_successful: boolean;
  error_code: string;
  error_msg: string;
  data: NotificationMessageDataGetAll;
};

type NotificationMessageGetAllResponse = {
  notificationMessageGetAll: ResponseModelGetAll;
};

type GenericResponse = {
  is_successful: boolean;
  error_code: string;
  error_msg: string;
};

type NotificationMessageMarkReadAllResponse = {
  notificationMessageMarkReadAll: GenericResponse;
};

type NotificationMessageRemoveAllResponse = {
  notificationMessageRemoveAll: GenericResponse;
};

interface NotificationMessageUpdateData extends ResponseModel {
  data: NotificationMessageData;
}

interface NotificationMessageUpdateResponse {
  notificationMessageUpdate: NotificationMessageUpdateData;
}

interface NotificationMessageSendResponse {
  notificationMessageSend: ResponseModel;
}

interface RegisterDeviceParams {
  device_token: string;
  os_name: string;
  os_version: string;
  device_name: string;
  device_brand: string;
  device_model: string;
}

interface RemoveDeviceParams {
  device_token: string;
}

interface NotificationRemoveResponse {
  notificationMessageRemove: ResponseModel;
}

export {
  EventType,
  NotificationMessageData,
  NotificationMessageGetAllInput,
  NotificationMessageGetAllResponse,
  NotificationMessageMarkReadAllResponse,
  NotificationMessageRemoveAllResponse,
  NotificationMessageSendInput,
  NotificationMessageSendResponse,
  NotificationMessageUpdateInput,
  NotificationMessageUpdateResponse,
  NotificationRemoveResponse,
  RegisterDeviceParams,
  RemoveDeviceParams,
  RemoveInput
};

