export * from './graphql/mutations/accout.mutation';
export * from './graphql/mutations/cms/genG/genG.mutation';
export * from './graphql/mutations/comm/contact.mutation';
export * from './graphql/mutations/comm/conversation.mutation';
export * from './graphql/mutations/comm/device/register.mutation';
export * from './graphql/mutations/comm/device/remove.mutation';
export * from './graphql/mutations/comm/notification/message/message.mutation';
export * from './graphql/mutations/community.mutation';
export * from './graphql/mutations/content.mutation';
export * from './graphql/mutations/crm/user/community/block.mutation';
export * from './graphql/mutations/crm/user/network/network.mutation';
export * from './graphql/mutations/discount/discount.mutation';
export * from './graphql/mutations/gitftcard.mutation';
export * from './graphql/mutations/invoice/invoice.mutation';
export * from './graphql/mutations/newsletter.mutation';
export * from './graphql/mutations/oms/discount/discount.data.mutation';
export * from './graphql/mutations/oms/return/return.data.mutation';
export * from './graphql/mutations/oms/return/return.product.mutation';
export * from './graphql/mutations/order/address.mutation';
export * from './graphql/mutations/order/order.mutation';
export * from './graphql/mutations/otp.mutation';
export * from './graphql/mutations/password.mutation';
export * from './graphql/mutations/payment/payment.mutation';
export * from './graphql/mutations/play/cancel.mutation';
export * from './graphql/mutations/play/closereg.mutation';
export * from './graphql/mutations/play/openreg.mutation';
export * from './graphql/mutations/play/start.mutation';
export * from './graphql/mutations/player/checkin.mutation';
export * from './graphql/mutations/player/join.mutation';
export * from './graphql/mutations/report/createreport.mutation';
export * from './graphql/mutations/search.mutation';
export * from './graphql/mutations/shipment/shipment.mutation';
export * from './graphql/mutations/shop/liked/wishlistliked.mutation';
export * from './graphql/mutations/tms/data/tournament.mutation';
export * from './graphql/mutations/tms/manage.mutation';
export * from './graphql/mutations/tms/matches.mutation';
export * from './graphql/mutations/tms/matchmaking/community.mutation';
export * from './graphql/mutations/tms/matchmaking/data.mutation';
export * from './graphql/mutations/tms/participant/participant.mutation';
export * from './graphql/mutations/user.mutation';
export * from './graphql/mutations/user/preference/update.mutation';
export * from './graphql/queries/category.query';
export * from './graphql/queries/cms/content/streams.query';
export * from './graphql/queries/cms/ewc/ewcbanner.query';
export * from './graphql/queries/cms/game/game.query';
export * from './graphql/queries/cms/genG/genG.query';
export * from './graphql/queries/cms/giftcard/giftcard.query';
export * from './graphql/queries/cms/stage/stage.query';
export * from './graphql/queries/cms/timeline/bit.query';
export * from './graphql/queries/comm/notification/message/message.query';
export * from './graphql/queries/community.query';
export * from './graphql/queries/content.query';
export * from './graphql/queries/crm/user/chat/getSearch.query';
export * from './graphql/queries/crm/user/community/block.query';
export * from './graphql/queries/crm/user/community/getfollowinfo.query';
export * from './graphql/queries/crm/user/network/network.query';
export * from './graphql/queries/currency.query';
export * from './graphql/queries/fms/wallet.query';
export * from './graphql/queries/geo.query';
export * from './graphql/queries/oms/invoice/invoice.query';
export * from './graphql/queries/oms/order/lmd.query';
export * from './graphql/queries/oms/order/order.query';
export * from './graphql/queries/oms/product/stock/stock.query';
export * from './graphql/queries/oms/return/return.data.query';
export * from './graphql/queries/oms/return/return.product.query';
export * from './graphql/queries/product.query';
export * from './graphql/queries/search.query';
export * from './graphql/queries/settings/featureFlag.query';
export * from './graphql/queries/settings/firebase.query';
export * from './graphql/queries/shop.query';
export * from './graphql/queries/shop/collections/products.query';
export * from './graphql/queries/shop/liked/wishlist.query';
export * from './graphql/queries/shop/menu/banner.query';
export * from './graphql/queries/shop/product/productDetailed.query';
export * from './graphql/queries/shop/promo/promo.query';
export * from './graphql/queries/statistics.query';
export * from './graphql/queries/tms/bracket/bracket.query';
export * from './graphql/queries/tms/circuit/circuit.query';
export * from './graphql/queries/tms/data/tournament.query';
export * from './graphql/queries/tms/manage.query';
export * from './graphql/queries/tms/match/getinfo/getinfo.query';
export * from './graphql/queries/tms/matches.query';
export * from './graphql/queries/tms/matchmaking/data.query';
export * from './graphql/queries/tms/minigame/data/minigames.query';
export * from './graphql/queries/tms/participant/participant.query';
export * from './graphql/queries/tms/rank/rank.query';
export * from './graphql/queries/tms/stage/stage.query';
export * from './graphql/queries/tms/tournament/data/tournaments.query';
export * from './graphql/queries/tournamentSections.query';
export * from './graphql/queries/user.query';
