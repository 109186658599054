import React from "react";

function NumberOne({ width = "8", height = "12", pathFill = "#454547" }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="number-1"
        d="M0.807617 0.267578H2.68262C2.85189 0.267578 2.93652 0.355469 2.93652 0.53125V2.15234C2.93652 2.34766 2.764 2.44531 2.41895 2.44531H0.905273C0.644857 2.44531 0.514648 2.34766 0.514648 2.15234V0.560547C0.54069 0.365234 0.638346 0.267578 0.807617 0.267578ZM3.35645 0.267578H5.23145C5.40072 0.267578 5.48535 0.355469 5.48535 0.53125V2.15234C5.48535 2.26953 5.43327 2.35742 5.3291 2.41602H3.32715C3.15137 2.41602 3.06348 2.32812 3.06348 2.15234V0.560547C3.09603 0.365234 3.19368 0.267578 3.35645 0.267578ZM3.28809 2.57227H5.13379C5.3291 2.57227 5.42676 2.66341 5.42676 2.8457V4.4668C5.42676 4.63607 5.33887 4.7207 5.16309 4.7207H3.25879C3.08952 4.7207 3.00488 4.63607 3.00488 4.4668V2.875C3.03092 2.67318 3.12533 2.57227 3.28809 2.57227ZM3.32715 4.95508H5.20215C5.37142 4.95508 5.45605 5.04297 5.45605 5.21875V6.94727C5.40397 7.05143 5.31934 7.10352 5.20215 7.10352H3.22949C3.09928 7.02539 3.03418 6.92773 3.03418 6.81055V5.25781C3.06022 5.05599 3.15788 4.95508 3.32715 4.95508ZM3.28809 7.2793H5.13379C5.3291 7.30534 5.42676 7.40299 5.42676 7.57227V9.1543C5.42676 9.35612 5.29655 9.45703 5.03613 9.45703H3.4541C3.20671 9.45703 3.05697 9.38867 3.00488 9.25195V7.5332C3.00488 7.36393 3.09928 7.2793 3.28809 7.2793ZM3.28809 9.58398H5.10449C5.2998 9.61003 5.39746 9.71094 5.39746 9.88672V11.4688C5.39746 11.6445 5.30957 11.7324 5.13379 11.7324H3.13184L2.96582 11.5664V9.84766C2.96582 9.67188 3.07324 9.58398 3.28809 9.58398Z"
        fill={pathFill}
      />
    </svg>
  );
}

export default NumberOne;
