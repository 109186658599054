import { MixPanelEvents, PAGE_DESCRIPTION, PAGE_KEYWORDS, PAGE_TITLE, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { ConnectConversationProvider, useConnectProvider } from '@fe-web/hooks/useConnect';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import SideBarChatOverView from '../SideBarChatOverView';
import NewChat from '../components/NewChat';
import PrivateMessage from './components/PrivateMessage';
import { useSelector } from 'react-redux';
import { RootState } from '@fe-monorepo/store';

const FullViewConvo = () => {
  const { closeAllChat } = useConnectProvider();
  const user = useSelector((state: RootState) => state.user.userContext);

  const navigate = useNavigate();
  const { translate } = useTranslate();
  const [isInviteUser, setIsInviteUser] = useState<boolean>(false);
  const { conversationId, conversationType } = useParams();
  const convoType = conversationType && parseInt(conversationType);

  const onClickNewChat = () => {
    mixpanelHelper.trackEvent(MixPanelEvents.chatCreationStarted, { Username: user.username ?? 'GUEST' });
    navigate(AppRoutes.newMessage);
  };

  const onCloseInvite = () => {
    setIsInviteUser(!isInviteUser);
  };

  useEffect(() => {
    closeAllChat && closeAllChat();
  }, []);

  useEffect(() => {
    if (conversationId === 'new') {
      mixpanelHelper.trackPageView(
        'CONNECT - CREATE NEW CHAT PAGE',
        'connect_create_new_chat_page',
        window.location.pathname,
        document.referrer,
      );
      brazeHelper.trackPageView(
        'CONNECT - CREATE NEW CHAT PAGE',
        'connect_create_new_chat_page',
        window.location.pathname,
        document.referrer,
      );
    } else {
      mixpanelHelper.trackPageView('CONNECT - CHAT PAGE', 'connect_chat_page', window.location.pathname, document.referrer);
      brazeHelper.trackPageView('CONNECT - CHAT PAGE', 'connect_chat_page', window.location.pathname, document.referrer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <CustomHelmet
        pageTitle={translate(PAGE_TITLE.connect as TxKeyPath) ?? ''}
        metaDescription={translate(PAGE_DESCRIPTION.connect as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.connect as TxKeyPath) ?? ''}
      />
      <div className="w-full px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655">
        <ConnectConversationProvider
          conversationID={conversationId !== 'new' ? conversationId : undefined}
          conversationType={conversationId !== 'new' ? convoType || 0 : undefined}
        >
          <div className="grid grid-cols-5 HD:grid-cols-4 gap-[1rem]">
            <div className="col-span-2 HD:col-span-1">
              <SideBarChatOverView
                isFullView
                showFullViewIcon={false}
                fixedPosition={false}
                onClickNewChat={onClickNewChat}
                setIsInviteUser={setIsInviteUser}
              />
            </div>
            <div className="col-span-3 HD:col-span-3 pt-[1.25rem] h-full pb-[2.5rem]">
              {conversationId === 'new' ? (
                <div className="border rounded-[.5rem] border-secondary/25 h-full">
                  <NewChat isFullView />
                </div>
              ) : isInviteUser ? (
                <div className="border rounded-[.5rem] border-secondary/25 h-full">
                  <NewChat isFullView isInviteUsers={isInviteUser} onCloseInvite={onCloseInvite} />
                </div>
              ) : (
                <PrivateMessage setIsInviteUser={setIsInviteUser} />
              )}
            </div>
          </div>
        </ConnectConversationProvider>
      </div>
    </>
  );
};

export default FullViewConvo;
