import { combineReducers } from '@reduxjs/toolkit';
import { appStateSlice } from './appStateSlice/appStateSlice';
import { connectSlice } from './connectSlice/connect';
import { countrySlice } from './countrySlice/country';
import { locationSlice } from './locationSlice/location';
import { participantSlice } from './participantSlice/participant';
import { pushNotificationSlice } from './pushNotificationSlice/pushnotification';
import { quickPlaySlice } from './quickPlaySlice/quickPlaySlice';
import { searchSlice } from './searchSlice/search';
import { bannerSlice } from './shopSlice/banner';
import { cartSlice } from './shopSlice/cart';
import { recentlyViewedSlice } from './shopSlice/recentlyViewed';
import { shopInfoSlice } from './shopSlice/shopInfo';
import { wishlistSlice } from './shopSlice/wishlist';
import { faqCategoriesSlice } from './supportSlice/faqCategories';
import { deliveryAddressSlice } from './userSlice/deliveryAddress';
import { userSlice } from './userSlice/user';
import { walletSlice } from './walletSlice/wallet';

const rootReducer = combineReducers({
    user: userSlice.reducer,
    deliveryAddress: deliveryAddressSlice.reducer,
    app: appStateSlice.reducer,
    search: searchSlice.reducer,
    wallet: walletSlice.reducer,
    pushnotification: pushNotificationSlice.reducer,
    banner: bannerSlice.reducer,
    faqCategories: faqCategoriesSlice.reducer,
    recentlyViewed: recentlyViewedSlice.reducer,
    shopInfo: shopInfoSlice.reducer,
    wishlist: wishlistSlice.reducer,
    cart: cartSlice.reducer,
    country: countrySlice.reducer,
    participants: participantSlice.reducer,
    location: locationSlice.reducer,
    connect: connectSlice.reducer,
    quickPlay: quickPlaySlice.reducer
});

export default rootReducer;
