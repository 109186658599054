import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ResponsiveIcon from '../../../../../../components/Atoms/Icon/ResponsiveIcon';
import DropDown from '../../../../../../components/Molecules/InputFields/DropDown/DropDown';
import usePageLayout from '../../../../../../hooks/usePageLayout';

type ProductSort = { id: string; label: any; value: string };

interface DropdownProps {
  label: string;
  onDateFilterChange: (filterId: string) => void;
  dateShopSort: ProductSort[];
  defaultSelectedId: string;
}

const DateDropDown: React.FC<DropdownProps> = ({ label, onDateFilterChange, dateShopSort, defaultSelectedId }) => {
  const { t, i18n } = useTranslation();
  const { translate } = useTranslate();
  const { language } = usePageLayout();
  const [selectedSort, setSelectedSort] = useState<ProductSort | null>(() => {
    return dateShopSort.find(item => item.id === defaultSelectedId) || null;
  });

  const handleDateFilterChange = (filterId: string, filterValue: string) => {
    const selectedSort = dateShopSort.find(item => item.id === filterId);
    setSelectedSort(selectedSort || null);
    onDateFilterChange(filterValue);
  };

  useEffect(() => {
    setSelectedSort(dateShopSort.find(item => item.id === defaultSelectedId) || null);
  }, [i18n.language, dateShopSort]);

  return (
    <div className="flex flex-row items-center mdMaxMm:self-stretch rounded-[.5rem] gap-[1rem] mix-blend-normal">
      <label className="text-secondary">
        <span className="text-secondary font-medium text-fs-body-small">{translate('common_period')}</span>
      </label>
      <div className="rounded-[2px] border 2K:border-2 4k:border-4 8K:border-8 mdMaxxSD:w-full border-neutral-300-zinc-700 SD:w-[12rem] FHD:w-[14rem]">
        <DropDown<ProductSort>
          style={{
            container: 'justify-between',
            text: 'SD:flex relative text-secondary text-fs-body-small flex flex-col justify-between gap-[0.5rem] items-end px-3 py-[.5rem] font-medium',
            wrapper: 'relative',
            tooltip: 'w-full',
            tooltipContainer: 'w-full z-100',
            listContainer: `w-full absolute mdMaxLl:text-fs-caption ${language === 'en' ? 'SD:right-0 left-0' : 'SD:left-0 right-0'}`,
            option: 'font-regular',
          }}
          selectedOption={selectedSort ?? dateShopSort[0]}
          selectedIcon={
            <ResponsiveIcon
              className={`z-[1] absolute ${language === 'en' ? 'right-[0.625rem]' : 'left-[0.625rem]'} fill-sunset`}
              name={IconNames.checkFill}
              baseHeight={18.67}
              baseWidth={16.67}
            />
          }
          selectedIconPosition="end"
          options={dateShopSort}
          getStringValue={(option: ProductSort) => t(option.label)}
          retreiveSelection={(option: ProductSort) => {
            setSelectedSort(option);
            handleDateFilterChange(option.id, option.value);
          }}
        />
      </div>
    </div>
  );
};

export default DateDropDown;
