import { useBitsConfig, useTranslate } from '@fe-monorepo/hooks';
import { TagType } from '@fe-web/Molecules/Tags/Tags';
import { getEnvironment } from '@fe-web/constant/environment';
import quickplay from 'quickplay-shorts-js';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface Pagination {
  limit: number;
  offset: number;
}

const HOME_BITS_PAGN: Pagination = {
  limit: 13,
  offset: 1,
};

const HASHTAGS_PAGINATION: Pagination = {
  limit: 13,
  offset: 1,
};

const ALL_HASHTAG = {
  name: '',
  label: 'all',
  id: '',
};

export interface QPPayload {
  apiKey: string;
  secretKey: string;
  baseUrl?: string;
}

interface UseBitsProvider {
  logoutFromQP: () => void;
  handleQuickPlayLogin: (accessToken: string) => Promise<void>;
  homeBits: {
    fetchBitsbyHashtag: (value: string) => void;
    fetchRecentHashtags: () => void;
    fetchFeedsData: () => void;
    bits: { data: any[]; loading: boolean };
    recentHashtags: { data: TagType[]; loading: boolean };
  };
}

const BitsContext = createContext<any>(undefined);

export const BitsProvider = ({
  children,
  showToastMessage,
}: {
  children?: ReactNode;
  showToastMessage: (message: string, type: 'success' | 'error') => void;
}) => {
  const [homeBitsLoading, setHomeBitsLoading] = useState<boolean>(false);
  const [hashtagsLoading, setHashtagsLoading] = useState<boolean>(false);
  const [homeBits, setHomeBits] = useState<any[]>([]);
  const [recentHashtags, setRecentHashtags] = useState<TagType[]>([]);
  const { translate } = useTranslate();
  const { quickplayAPIKey, quickplaySecretKey, quickplayBaseURL } = getEnvironment();
  const { showBitsScreen } = useBitsConfig();

  const initializeQuickplaySDK = () => {
    if (!showBitsScreen) {
      return;
    }
    let quickPlaySDKInitilize = new quickplay.QuickPlaySDKInitilize();
    const qpPayload: QPPayload = {
      apiKey: quickplayAPIKey,
      secretKey: quickplaySecretKey,
    };
    if (quickplayBaseURL) {
      qpPayload.baseUrl = quickplayBaseURL;
    }
    quickPlaySDKInitilize.initialize(qpPayload);
  };

  const fetchBitsbyHashtag = async (tagName: string) => {
    setHomeBitsLoading(true);
    try {
      const hashTagModuleObj = new quickplay.QuickPlayHashTag();
      const {
        data: { result: hashVideos = [] },
      } = await hashTagModuleObj.getHashTagVideoList({
        ...HOME_BITS_PAGN,
        hashtagName: tagName,
      });
      setHomeBits(hashVideos);
      setHomeBitsLoading(false);
    } catch (error: any) {
      showToastMessage('Hashtags fetch', 'success');
      setHomeBitsLoading(false);
    }
  };

  const fetchFeedsData = async () => {
    setHomeBitsLoading(true);
    const videoModule = new quickplay.QuickPlayVideosModule();
    const {
      data: { result: feeds = [] },
    } = await videoModule.getRecentVideoList(HOME_BITS_PAGN);
    setHomeBitsLoading(false);
    setHomeBits(feeds);
  };

  const fetchRecentHashtags = async () => {
    setHashtagsLoading(true);
    let hashtagModule = new quickplay.QuickPlayHashTag();
    const translatedAll = {
      ...ALL_HASHTAG,
      label: translate('action.all'),
    };
    const {
      data: { result: hashtags = [] },
    } = await hashtagModule.getRecentHashtagList(HASHTAGS_PAGINATION);
    setRecentHashtags([
      translatedAll,
      ...hashtags.map((ht: any) => ({
        id: ht._id,
        name: ht?.title,
        label: ht?.title,
      })),
    ]);
    setHashtagsLoading(false);
  };

  useEffect(() => {
    if (showBitsScreen) {
      initializeQuickplaySDK();
    }
  }, [showBitsScreen]);

  const logoutFromQP = () => {
    if (!showBitsScreen) {
      return;
    }
    const authLoginInput = new quickplay.QuickPlayAuthModule();
    authLoginInput.SignOut();
    localStorage.removeItem('userData');
    localStorage.removeItem('userDetails');
    localStorage.removeItem('following');
  };

  const fetchUserMetadata = async () => {
    try {
      const userModule = new quickplay.QuickPlayUserModule();
      const userMetadataResponse = await userModule.getUserMetaIds('');
      // Check for successful response
      if ([200, 201].includes(userMetadataResponse.status)) {
        const followingList = userMetadataResponse.data.result?.following || [];
        localStorage.setItem('following', JSON.stringify(followingList));
      } else {
        console.error(userMetadataResponse.data.statusMessage, 'Fetching user metadata failed');
      }
    } catch (error) {
      console.error(error, 'Error fetching user metadata');
    }
  };

  const handleQuickPlayLogin = async (accessToken: string) => {
    if (!showBitsScreen) {
      return;
    }
    try {
      const loginData = {
        accessToken,
        env: 'dev',
      };
      const authModule = new quickplay.QuickPlayAuthModule();
      // Attempt to sign in
      const loginResponse = await authModule.clientSignIn(loginData);
      // Handle non-successful login status
      if (loginResponse.status !== 200) {
        console.error(loginResponse.data.statusMessage, 'Login failed');
        return;
      }
      // Handle specific status code within a successful response
      if (loginResponse.data.statusCode === 2001) {
        await fetchUserMetadata();
      } else {
        console.error(loginResponse.data.statusMessage, 'Unexpected status code');
      }
    } catch (error) {
      // General error handling
      console.error(error, 'Quickplay login error');
    }
  };

  return (
    <BitsContext.Provider
      value={{
        logoutFromQP,
        handleQuickPlayLogin,
        homeBits: {
          fetchRecentHashtags,
          fetchFeedsData,
          fetchBitsbyHashtag,
          bits: {
            data: homeBits,
            loading: homeBitsLoading,
          },
          recentHashtags: {
            data: recentHashtags,
            loading: hashtagsLoading,
          },
        },
      }}
    >
      {children}
    </BitsContext.Provider>
  );
};

export const useBitsProvider = (): UseBitsProvider => {
  const context = useContext(BitsContext);
  if (!context) throw 'Please use a provider to use this hook';
  return context;
};
