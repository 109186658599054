import { gql } from '@apollo/client';

const TMS_RANK_QUERY = {
  tmsRankFinalStanding: gql`
    query tmsRankFinalStanding($details: RankInput) {
      tmsRankFinalStanding(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          final_standing {
            id
            position
            rank
            participant {
              id
              name
              custom_user_identifier
              custom_fields {
                avatar_url
              }
            }
            tournament_id
          }
        }
      }
    }
  `,
};

export { TMS_RANK_QUERY };
