import { IMAGES } from '@fe-monorepo/assets';
import { OrderQueryResponse, ShopModel, ShopWishlistInput, WishlistMutationResponse } from '@fe-monorepo/models';
import WishList from '@fe-web/Atoms/Favorite/WishListFavorite';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';

interface ProductCardSearchProps {
  product: ShopModel;
  isMobile?: boolean;
  isRTL?: boolean;
  isWishList?: boolean;
  addToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  removeToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  getAllWishlist: (getAllWishlistInput: { last_cursor: string; direction: string }) => Promise<OrderQueryResponse | undefined>;
}

const ProductCardSearch = ({
  product,
  isMobile,
  isRTL,
  isWishList,
  getAllWishlist,
  addToWishList,
  removeToWishList,
}: ProductCardSearchProps) => {
  const navigate = useNavigate();

  return (
    <div
      className={`flex flex-col aspect-[282/308] items-center gap-[.75rem] mb-[1rem] hover:cursor-pointer`}
      onClick={() => navigate(`${AppRoutes.shop}/c-${product.collection_code}/${product.id}`)}
    >
      <div className="img-bg flex w-full aspect-[141/154] flex-col rounded-t py-5 4xl:py-[8.88px] 5xl:py-[13.33px] 8xl:py-[26.67px] items-center min-h-[308px] relative">
        {/*WISHLIST ICON */}
        <WishList
          isWishlist={isWishList || false}
          product_code={product.id ? product.id : ''}
          addToWishList={addToWishList}
          removeToWishList={removeToWishList}
          getAllWishlist={getAllWishlist}
          productName={product.title_en ?? ''}
          brand={product.brand_name_en ?? ''}
        />
        <div className="relative bg-secondary/[0.04] w-full h-full flex items-center justify-center flex-1 px-[10px] 4xl:px-[17.77px] 5xl:px-[26.66px] 8xl:px-[53.33px] rounded-t">
          <LazyLoadImage
            className="absolute w-full h-full rounded-t"
            src={product?.img ?? IMAGES?.DefaultPlaceholder?.toString()}
            alt={!isRTL ? product?.title_en?.replace(/\s/g, '_') : product?.title_ar?.replace(/\s/g, '_')}
          />
        </div>
      </div>

      <div className="w-full flex flex-col gap-[.75rem]">
        <p
          className="text-fs-body text-sunset font-regular hover:cursor-pointer"
          onClick={(event: any) => navigate(`${AppRoutes.shop}/c-${product?.collection_code}`, event.stopPropagation())}
        >
          {isRTL ? product.brand_name_ar : product.brand_name_en}
        </p>
        <p className="font-medium text-fs-body text-secondary truncate min-h-[24px]">{isRTL ? product.title_ar : product.title_en}</p>
        <p>
          <CurrencyText className="font-medium text-fs-body-large text-secondary" currency={product?.lowest_selling_price ?? 0} />
        </p>
      </div>
    </div>
  );
};

export default ProductCardSearch;
