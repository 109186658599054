import { useTranslate } from '@fe-monorepo/hooks';
import quickplay from 'quickplay-shorts-js';
import { useEffect, useState } from 'react';

import EmptyFeedImage from '../../../../assets/img/empty-feed.png';
import EmptyFeedFollowList from './EmptyFeedFollowList';

function EmptyFeed({ followingClicked }: any) {
  const [followingData, setFollowingData]: any = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { translate } = useTranslate();

  useEffect(() => {
    async function fetchData() {
      try {
        const userModuleObj = new quickplay.QuickPlayUserModule();
        const userModuleResponse = await userModuleObj.getTopProfiles({});
        setFollowingData(userModuleResponse.data.result);
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
    fetchData();
  }, []);

  return (
    <div className="emptyFeed">
      <div className="emptyFeed-wrap">
        <img src={EmptyFeedImage} alt="Empty Feed" className="emptyFeed-image" />
        <h2 className="emptyFeed-title">{translate('quickplay.label-empty-feed')}</h2>
        <p className="emptyFeed-description">
          {translate('quickplay.label-empty-feed-text1')} <br />
          {translate('quickplay.label-empty-feed-text2')}
        </p>
      </div>

      <div className="emptyFeed-content">
        <h2 className="emptyFeed-lists-heading">{translate('quickplay.label-worth-following')}</h2>
        <ul className="emptyFeed-lists">
          {followingData?.map((followingItem: any, index: number) => (
            <EmptyFeedFollowList followingItem={followingItem} followingClicked={followingClicked} />
          ))}
        </ul>
      </div>
    </div>
  );
}

export default EmptyFeed;
