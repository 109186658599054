import { TOURNAMENT_STATUS } from '@fe-monorepo/helper';
import { GetInfoDataResult, GetInfoResponse, ParticipantsDataModel, TournamentQueryResult } from '@fe-monorepo/models';
import { GraphQLError } from 'graphql';
import { Dispatch, ReactNode, SetStateAction, createContext, useContext, useEffect, useState } from 'react';

import { useAppState } from '../useAppState/useAppState';
import { RoundModel, TmsTournamentBracketMatch } from '../useBracket/type';
import { useBracket } from '../useBracket/useBracket';
import { JoinTournamentParamsModelV2 } from '../useJoinTournament/type';
import { useJoinTournament } from '../useJoinTournament/useJoinTournament';
import { useGetParticipants } from '../useParticipants/useGetParticipants';
import { TmsTournamentStage } from '../useStage/type';
import { useStage } from '../useStage/useStage';
import { useUserProfile } from '../useUserProfile';
import {
  ApproveInput,
  ApproveResponse,
  GetParticipantsForApprovalInput,
  GetParticipantsForApprovalModel,
  ManageMutationResponse,
  ManageQueryResponse,
  RejectInput,
  RejectResponse,
  SeedManuallyInput,
  SortParticipantsInput,
  useTmsManage,
} from './useManage';
import {
  GetMatchesInput,
  MatchesListModel,
  MatchesMutationResponse,
  MatchesQueryResponse,
  ReportScoreInput,
  ResetMatchInput,
  ResolveConflictInput,
  SubmitScoreInput,
  useMatches,
} from './useMatches';
import { useRegistrationInfo } from './useRegistrationInfo';
import { useTournament } from './useTournament';

interface TournamentProviderProps {
  tournamentId?: string;
  children?: ReactNode;
}

interface UseTournamentProvider {
  tournamentId: string;
  tournament: GetInfoDataResult;
  participants: ParticipantsDataModel[];
  approveData: ApproveResponse | undefined;
  rejectData: RejectResponse | undefined;
  participantsForApproval: GetParticipantsForApprovalModel[] | undefined;
  rounds: (Partial<MatchesListModel> | undefined)[];
  tempRounds: (Partial<MatchesListModel> | undefined)[];
  isManualSeeding: boolean;
  stages: TmsTournamentStage[] | undefined;
  selectedStage: TmsTournamentStage | undefined;
  brackets: TmsTournamentBracketMatch[] | undefined;
  tournamentInfoData: TournamentQueryResult | undefined;
  wBrackets: RoundModel[] | undefined;
  lBrackets: RoundModel[] | undefined;
  setSelectedStage: Dispatch<SetStateAction<TmsTournamentStage | undefined>>;
  registerTournamentSuccess: boolean;
  registerTournamentError: string;
  userRegistrationStatus: string | undefined;
  loadingGetTournament: boolean;
  loadingGetParticipants: boolean;
  loadingGetAllStages: boolean;
  loadingGetRegisteration: boolean;
  approve: (details: ApproveInput) => Promise<ManageMutationResponse | null | undefined>;
  reject: (details: RejectInput) => Promise<ManageMutationResponse | null | undefined>;
  sortParticipants: (details: SortParticipantsInput) => Promise<ManageMutationResponse | null | undefined>;
  submitScore: (details: SubmitScoreInput, file?: any) => Promise<MatchesMutationResponse | null | undefined>;
  reportScore: (details: ReportScoreInput) => Promise<
    | {
        data: MatchesMutationResponse;
        errors: readonly GraphQLError[] | undefined;
      }
    | undefined
  >;
  resolveConflict: (details: ResolveConflictInput) => Promise<MatchesMutationResponse | null | undefined>;
  resetMatch: (details: ResetMatchInput) => Promise<MatchesMutationResponse | null | undefined>;
  getParticipantsForApproval: (details: GetParticipantsForApprovalInput) => Promise<ManageQueryResponse | undefined>;
  moveParticipant: (sourceIndex: number, sourceIsA: boolean, destinationIndex: number, destinationIsA: boolean) => void;
  seedManually: (details: SeedManuallyInput) => Promise<ManageMutationResponse | null | undefined>;
  refreshDetails: (details: string) => Promise<GetInfoResponse | null | undefined>;
  getMatches: (details: GetMatchesInput) => Promise<MatchesQueryResponse | undefined>;
  getTournamentInformation: (details: { tournament_id: string }) => Promise<GetInfoResponse | null | undefined>;
  registerTournament: (params: JoinTournamentParamsModelV2) => Promise<{
    data: any;
    errors: GraphQLError[] | undefined;
  }>;
}

const TournamentContext = createContext<any>(undefined);

export const TournamentProvider = ({ children, tournamentId }: TournamentProviderProps) => {
  const [tournament, setTournament] = useState<GetInfoDataResult | undefined>();
  const [tournamentInformation, setTournamentInformation] = useState<GetInfoDataResult | undefined>();
  const [participants, setParticipants] = useState<ParticipantsDataModel[] | undefined>();
  const [participantsForApproval, setParticipantsForApproval] = useState<GetParticipantsForApprovalModel[] | undefined>();
  const [rounds, setRounds] = useState<(Partial<MatchesListModel> | undefined)[] | undefined>();
  const [tempRounds, setTempRounds] = useState<(Partial<MatchesListModel> | undefined)[] | undefined>();
  const [isManualSeeding, setIsManualSeeding] = useState<boolean>();
  const [selectedStage, setSelectedStage] = useState<TmsTournamentStage>();
  const [wBrackets, setWBrackets] = useState<RoundModel[]>();
  const [lBrackets, setLBrackets] = useState<RoundModel[]>();
  const { getTournamentInformation, tournamentInfoData, loadingGetTournament } = useTournament();
  const { getParticipants, getParticipantsData, isLoading: loadingGetParticipants } = useGetParticipants();
  const {
    getMatches,
    submitScore,
    submitScoreData,
    reportScore,
    reportScoreData,
    resolveConflict,
    resolveConflictData,
    resetMatch,
    resetMatchData,
    advanceData,
  } = useMatches();
  const { approveData, rejectData, sortParticipantsData, approve, reject, sortParticipants, seedManually } = useTmsManage();
  const { user } = useUserProfile();
  const { changeLoadingState } = useAppState();
  const { getAllData: stages, getAll: getAllStages, isGetAllLoading: loadingGetAllStages } = useStage();
  const { getAll: getAllBrackets, getAllData: brackets } = useBracket();
  const { registerTournament, isSuccessful: registerTournamentSuccess, errorMsg: registerTournamentError } = useJoinTournament();
  const { getRegistrationInfo, loadingGetRegisteration } = useRegistrationInfo();
  const [userRegistrationStatus, setUserRegistrationStatus] = useState<string>();

  useEffect(() => {
    const initGetRegistrationInfo = async () => {
      if (!tournamentId || !user?.username) return;
      const registrationInfoResponse = await getRegistrationInfo({ tournament_ids: tournamentId, username: user.username });
      const registrations = registrationInfoResponse?.data;
      if (registrations && registrations?.length > 0) {
        const registration = registrations.find(registration => registration.tournament_id === tournamentId);
        setUserRegistrationStatus(registration?.status);
      }
    };
    initGetRegistrationInfo();
  }, [tournamentId, user?.username, tournamentInfoData]);

  useEffect(() => {
    if (tournamentId) {
      changeLoadingState(true);
      getTournamentInformation({ tournament_id: tournamentId });
      getParticipants({ tournament_id: tournamentId, is_sorted: 1 });
    }
  }, [tournamentId]);

  useEffect(() => {
    if (tournamentId) {
      getTournamentInformation({ tournament_id: tournamentId });
    }
  }, [tournamentId]);

  useEffect(() => {
    setTournament(tournamentInfoData?.getTournamentInfo?.data);
    changeLoadingState(false);
  }, [tournamentInfoData?.getTournamentInfo]);

  useEffect(() => {
    if (tournamentId) {
      changeLoadingState(true);
      getParticipants({ tournament_id: tournamentId, is_sorted: 1 });
    }
  }, [approveData, rejectData]);

  /**
   * update participants on refreshDetails
   */
  useEffect(() => {
    if (tournamentId) {
      getParticipants({ tournament_id: tournamentId, is_sorted: 1 }).then(participants =>
        setParticipants(participants?.data?.tmsParticipantGetParticipants?.data),
      );
    }
  }, [tournamentInfoData]);

  useEffect(() => {
    if (tournamentId) {
      changeLoadingState(true);
      getParticipants({ tournament_id: tournamentId, is_sorted: 1 });
    }
  }, [sortParticipantsData]);

  useEffect(() => {
    setParticipants(getParticipantsData?.data);
    // TODO: Deprecated. Remove this in the future once confirmed no longer needed
    // if (tournamentId) {
    // changeLoadingState(true);
    // getParticipantsForSeeding({ tournament_id: tournamentId });
    // }
    changeLoadingState(false);
  }, [getParticipantsData?.data]);

  // TODO: Deprecated. Remove this in the future once confirmed no longer needed
  // useEffect(() => {
  //     setParticipantsForApproval(getParticipantsForApprovalData?.data?.list);
  //     changeLoadingState(false);
  // }, [getParticipantsForApprovalData?.data]);

  useEffect(() => {
    changeLoadingState(true);
    // TODO: Deprecated. Remove this in the future once confirmed no longer needed
    // const getRounds = async () => {
    //     const totalRounds = tournament?.total_rounds || 0;
    //     const rounds = [];
    //     if (totalRounds > 0 && tournamentId) {
    //         for (let i = 0; totalRounds > i; i++) {
    //             const round = await getMatches({ tournament_id: tournamentId, round: i + 1 });
    //             rounds.push({ list: round?.tmsMatchesGetMatches?.data?.list || [], roundNumber: i + 1 });
    //         }
    //     }
    //     setRounds(rounds);
    //     changeLoadingState(false);
    // };
    if (tournamentId) {
      getAllStages({ tournament_id: '' + tournamentId });
    }
    if (tournamentInfoData) {
      setTournamentInformation(tournamentInfoData?.getTournamentInfo?.data);
    }

    // getRounds();
  }, [tournament, tournamentId, submitScoreData, resolveConflictData, resetMatchData, tournamentInfoData]);

  // TODO: This is deprecated. If this won't be needed in the future please remove.
  // useEffect(() => {
  //     setIsOrganizer(user?.username === tournament?.organizer_info?.username);
  // }, [user?.username, tournament?.organizer_info?.username]);

  // useEffect(() => {
  //     const participantsForSeeding = getParticipantsForSeedingData?.data?.list || [];
  //     if ((tournament?.total_rounds || 0) <= 0) {
  //         const numberOfMatches = Math.ceil(participantsForSeeding.length / 2);
  //         const matches = [];
  //         for (let i = 0; numberOfMatches > i; i++) {
  //             const participantA = participantsForSeeding?.shift();
  //             const participantB = participantsForSeeding?.pop();
  //             const match: Partial<MatchModel> = {
  //                 opponent_a_participant_id: participantA?.participant_id,
  //                 opponent_a_avatar_url: participantA?.reference_participant_avatar_url,
  //                 opponent_a_display_name: participantA?.reference_participant_display_name,
  //                 opponent_a_username: participantA?.reference_participant_username,
  //                 opponent_b_participant_id: participantB?.participant_id,
  //                 opponent_b_avatar_url: participantB?.reference_participant_avatar_url,
  //                 opponent_b_display_name: participantB?.reference_participant_display_name,
  //                 opponent_b_username: participantB?.reference_participant_username,
  //                 match_id: i + 1,
  //             };
  //             matches.push(match);
  //         }
  //         const round: Partial<MatchesListModel>[] = [
  //             {
  //                 list: matches,
  //                 roundNumber: 1,
  //             },
  //         ];
  //         setTempRounds(round);
  //     }
  // }, [getParticipantsForSeedingData?.data]);

  useEffect(() => {
    let participantIds: number[] = [];
    if (tempRounds && tempRounds.length > 0) {
      const tempRound = [...(tempRounds[0]?.list || [])];
      tempRound?.reverse()?.forEach(item => {
        typeof item.opponent_a_participant_id === 'number' && participantIds.unshift(item.opponent_a_participant_id);
        typeof item.opponent_b_participant_id === 'number' && participantIds.push(item.opponent_b_participant_id);
      });
    }
  }, []);
  // TODO: Deprecated. Remove this in the future once confirmed no longer needed
  // useEffect(() => {
  //     const currentRoundNumber = tournament?.current_round;
  //     if (typeof currentRoundNumber === 'number' && tournamentId) {
  //         const currentRound = rounds?.find(round => round?.roundNumber === currentRoundNumber);
  //         if (!currentRound || !currentRound?.list || currentRound?.list?.length <= 0 || tournament?.status_code === 'ended') return;
  //         for (let i = 0; currentRound?.list?.length > i; i++) {
  //             if (currentRound?.list[i].status_code != 'complete') return;
  //         }
  //         advance({ tournament_id: tournamentId });
  //     }
  // }, [getMatchesData, tournament?.current_round, tournament?.status_code]);

  useEffect(() => {
    if (tournamentId) {
      changeLoadingState(true);
      getTournamentInformation({ tournament_id: tournamentId });
    }
  }, [advanceData]);

  const moveParticipant = (sourceId: number, sourceIsA: boolean, destinationId: number, destinationIsA: boolean) => {
    if (tempRounds && tempRounds?.length > 0) {
      const round = tempRounds[0];
      let list = round?.list;
      setIsManualSeeding(true);

      if (list && list.length > 0) {
        const sourceIndex = list.findIndex(item => item?.match_id === sourceId);
        const destinationIndex = list.findIndex(item => item?.match_id === destinationId);
        let sourceMatch = { ...list[sourceIndex] };
        let destinationMatch = { ...list[destinationIndex] };
        const sourceValue = {
          gameId: sourceIsA ? sourceMatch?.opponent_a_game_id : sourceMatch?.opponent_b_game_id,
          avatarUrl: sourceIsA ? sourceMatch?.opponent_a_avatar_url : sourceMatch?.opponent_b_avatar_url,
          displayName: sourceIsA ? sourceMatch?.opponent_a_display_name : sourceMatch?.opponent_b_display_name,
          username: sourceIsA ? sourceMatch?.opponent_a_username : sourceMatch?.opponent_b_username,
        };

        const destinationValue = {
          gameId: destinationIsA ? destinationMatch?.opponent_a_game_id : destinationMatch?.opponent_b_game_id,
          avatarUrl: destinationIsA ? destinationMatch?.opponent_a_avatar_url : destinationMatch?.opponent_b_avatar_url,
          displayName: destinationIsA ? destinationMatch?.opponent_a_display_name : destinationMatch?.opponent_b_display_name,
          username: destinationIsA ? destinationMatch?.opponent_a_username : destinationMatch?.opponent_b_username,
        };

        if (sourceIndex === destinationIndex && sourceIsA != destinationIsA) {
          list[sourceIndex] = {
            ...sourceMatch,
            opponent_a_game_id: sourceMatch?.opponent_b_game_id,
            opponent_a_avatar_url: sourceMatch?.opponent_b_avatar_url,
            opponent_a_display_name: sourceMatch?.opponent_b_display_name,
            opponent_a_username: sourceMatch?.opponent_b_username,
            opponent_b_game_id: sourceMatch?.opponent_a_game_id,
            opponent_b_avatar_url: sourceMatch?.opponent_a_avatar_url,
            opponent_b_display_name: sourceMatch?.opponent_a_display_name,
            opponent_b_username: sourceMatch?.opponent_a_username,
          };
          setTempRounds([round]);
          return;
        }
        if (sourceIsA) {
          list[sourceIndex] = {
            ...sourceMatch,
            opponent_a_game_id: destinationValue.gameId,
            opponent_a_avatar_url: destinationValue.avatarUrl,
            opponent_a_display_name: destinationValue.displayName,
            opponent_a_username: destinationValue.username,
          };
        } else {
          list[sourceIndex] = {
            ...sourceMatch,
            opponent_b_game_id: destinationValue.gameId,
            opponent_b_avatar_url: destinationValue.avatarUrl,
            opponent_b_display_name: destinationValue.displayName,
            opponent_b_username: destinationValue.username,
          };
        }

        if (destinationIsA) {
          list[destinationIndex] = {
            ...destinationMatch,
            opponent_a_game_id: sourceValue.gameId,
            opponent_a_avatar_url: sourceValue.avatarUrl,
            opponent_a_display_name: sourceValue.displayName,
            opponent_a_username: sourceValue.username,
          };
        } else {
          list[destinationIndex] = {
            ...destinationMatch,
            opponent_b_game_id: sourceValue.gameId,
            opponent_b_avatar_url: sourceValue.avatarUrl,
            opponent_b_display_name: sourceValue.displayName,
            opponent_b_username: sourceValue.username,
          };
        }
        setTempRounds([round]);
      }
    }
  };

  useEffect(() => {
    if (stages && stages?.length > 0) {
      setSelectedStage(stages[0]);
    }
  }, [stages]);

  useEffect(() => {
    if (selectedStage?.id && selectedStage?.tournament_id) {
      getAllBrackets({ tournament_id: selectedStage?.tournament_id, stage_id: selectedStage.id });
    }
  }, [selectedStage]);

  useEffect(() => {
    if (brackets && brackets?.length > 0) {
      const wb = brackets.filter(data => data.branch === 'wb' || data.branch === 'gf');
      const lb = brackets.filter(data => data.branch === 'lb');
      const wSize = wb?.length ? wb[0].depth : 0;
      const lSize = lb?.length ? lb[0].depth : 0;
      let counter = 1;
      let tempWb: RoundModel[] = [];
      let tempLb: RoundModel[] = [];
      for (let i = 0; wSize > i; i++) {
        tempWb.unshift({ matches: wb.filter(data => data.depth === i + 1) });
      }
      for (let i = 0; lSize > i; i++) {
        tempLb.unshift({ matches: lb.filter(data => data.depth === i + 1) });
      }

      tempWb = tempWb.filter(bracket => bracket?.matches?.length > 0);
      tempWb = tempWb.map((bracket, index) => {
        let status = 'completed';

        for (let i = 0; bracket.matches.length > i; i++) {
          if (bracket.matches[i].status === TOURNAMENT_STATUS.PENDING) {
            status = TOURNAMENT_STATUS.PENDING;
            break;
          } else if (bracket.matches[i].status === TOURNAMENT_STATUS.RUNNING && status === TOURNAMENT_STATUS.COMPLETED) {
            status = TOURNAMENT_STATUS.RUNNING;
          }
        }
        return { ...bracket, roundNumber: index + 1, status };
      });
      tempLb = tempLb.filter(bracket => bracket?.matches?.length > 0);
      tempLb = tempLb.map((bracket, index) => {
        let status = 'completed';

        for (let i = 0; bracket.matches.length > i; i++) {
          if (bracket.matches[i].status === TOURNAMENT_STATUS.PENDING) {
            status = TOURNAMENT_STATUS.PENDING;
            break;
          } else if (bracket.matches[i].status === TOURNAMENT_STATUS.RUNNING && status === TOURNAMENT_STATUS.COMPLETED) {
            status = TOURNAMENT_STATUS.RUNNING;
          }
        }
        return { ...bracket, roundNumber: index + 1, status };
      });
      setWBrackets(
        tempWb.map(branch => {
          return {
            ...branch,
            matches: branch.matches.map(match => {
              return { ...match, number: counter++ };
            }),
          };
        }),
      );
      setLBrackets(
        tempLb.map(branch => {
          return {
            ...branch,
            matches: branch.matches.map(match => {
              return { ...match, number: counter++ };
            }),
          };
        }),
      );
    }
  }, [brackets]);

  useEffect(() => {
    if (tournamentId) {
      getTournamentInformation({ tournament_id: tournamentId });
    }
  }, [reportScoreData]);

  useEffect(() => {
    if (registerTournamentSuccess && tournamentId) {
      getParticipants({ tournament_id: tournamentId });
    }
  }, [registerTournamentSuccess]);

  const refreshDetails = (tournamentId: string) => {
    changeLoadingState(true);
    getTournamentInformation({ tournament_id: tournamentId })
      .then(e => changeLoadingState(false))
      .catch(e => changeLoadingState(false));
    //  changeLoadingState(false);
  };

  return (
    <TournamentContext.Provider
      value={{
        tournamentId,
        tournament,
        participants,
        approveData,
        rejectData,
        participantsForApproval,
        rounds,
        tempRounds,
        isManualSeeding,
        stages,
        selectedStage,
        brackets,
        tournamentInfoData,
        wBrackets,
        lBrackets,
        approve,
        reject,
        sortParticipants,
        submitScore,
        reportScore,
        resolveConflict,
        resetMatch,
        moveParticipant,
        seedManually,
        refreshDetails,
        getMatches,
        setSelectedStage,
        getTournamentInformation,
        registerTournament,
        registerTournamentSuccess,
        registerTournamentError,
        userRegistrationStatus,
        loadingGetTournament,
        loadingGetParticipants,
        loadingGetAllStages,
        loadingGetRegisteration,
      }}
    >
      {children}
    </TournamentContext.Provider>
  );
};

export const useTournamentProvider = (): UseTournamentProvider => {
  const context = useContext(TournamentContext);

  if (!context) throw 'Please use a provider to use this hook';

  return context;
};
