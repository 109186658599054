import { useEwcProvider } from '@fe-monorepo/hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';

type ShopMegaMenuAnimationType = {
  children: ReactNode;
  isMegaMenuOpen: boolean;
  megeMenuRef: any;
  isDuration: boolean;

  actionLeaveHover: () => void;
};

const ShopMegaMenuAnimation = (props: ShopMegaMenuAnimationType) => {
  const { skinnyBanners } = useEwcProvider();
  return (
    <motion.div
      animate={{ height: props?.isMegaMenuOpen ? `100%` : `0px`, backgroundColor: props?.isMegaMenuOpen ? `` : `transparent` }}
      className={`${
        props?.isMegaMenuOpen ? `bg-secondary/50 min-h-screen` : `h-0 bg-transparent`
      } z-[1000] absolute w-full duration-[0.6s] ${skinnyBanners ? 'mt-[4.125rem] 2K:mt-[4.5rem]' : ''}`}
    >
      <AnimatePresence>
        {props?.isMegaMenuOpen && (
          <motion.div
            initial={{ height: props?.isMegaMenuOpen ? `0px` : `0px` }}
            animate={{
              boxSizing: `border-box`,
              borderBottom: props?.isMegaMenuOpen ? [`0px solid #480986`, `40px solid #480986`, `0px solid #480986`] : ``,
              height: props?.isMegaMenuOpen ? `auto` : `0px`,
            }}
            exit={{
              height: props?.isMegaMenuOpen ? `0px` : `0px`,
              boxSizing: `border-box`,
              borderBottom: props?.isMegaMenuOpen ? [`0px solid #480986`, `40px solid #480986`, `0px solid #480986`] : ``,
            }}
            transition={{ delay: props?.isDuration ? 0 : 0.3, duration: 0.3 }}
            className={`w-full relative overflow-hidden`}
          >
            <div
              ref={props?.megeMenuRef}
              className={`bg-primary py-44 4K:py-100 8K:py-200 relative border-t-[0.063rem] border-secondary/20`}
              onMouseLeave={props?.actionLeaveHover}
            >
              {props?.children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ShopMegaMenuAnimation;
