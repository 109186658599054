//Social
import EmptyAvatar, { ReactComponent as EmptyAvatarComponent } from 'libs/assets/src/icons/avatar/icon_avatar_empty.svg';
//Bold
import AddBold, { ReactComponent as AddBoldComponent } from 'libs/assets/src/icons/bold/icon_add_bold.svg';
import AnalyticsBold, { ReactComponent as AnalyticsBoldComponent } from 'libs/assets/src/icons/bold/icon_analytics_bold.svg';
import AttachmentBold, { ReactComponent as AttachmentBoldComponent } from 'libs/assets/src/icons/bold/icon_attachment_bold.svg';
import BlockBold, { ReactComponent as BlockBoldComponent } from 'libs/assets/src/icons/bold/icon_block_bold.svg';
import CheckInBold, { ReactComponent as CheckInBoldComponent } from 'libs/assets/src/icons/bold/icon_check_in_bold.svg';
import CloseBold, { ReactComponent as CloseBoldComponent } from 'libs/assets/src/icons/bold/icon_close_bold.svg';
import LogoutBold, { ReactComponent as LogoutBoldComponent } from 'libs/assets/src/icons/bold/icon_logout_bold.svg';
import MenuBold, { ReactComponent as MenuBoldComponent } from 'libs/assets/src/icons/bold/icon_menu_bold.svg';
import MinusBold, { ReactComponent as MinusBoldComponent } from 'libs/assets/src/icons/bold/icon_minus_bold.svg';
import OpenBold, { ReactComponent as OpenBoldComponent } from 'libs/assets/src/icons/bold/icon_open_bold.svg';
import OptionsBold, { ReactComponent as OptionsBoldComponent } from 'libs/assets/src/icons/bold/icon_options_bold.svg';
import RepostBold, { ReactComponent as RepostBoldComponent } from 'libs/assets/src/icons/bold/icon_repost_bold.svg';
import SearchBold, { ReactComponent as SearchBoldComponent } from 'libs/assets/src/icons/bold/icon_search_bold.svg';
import ShareBold, { ReactComponent as ShareBoldComponent } from 'libs/assets/src/icons/bold/icon_share_bold.svg';
//Fill
import AddCircleFill, { ReactComponent as AddCircleFillComponent } from 'libs/assets/src/icons/fill/icon_add_circle_fill.svg';
import AddUserFill, { ReactComponent as AddUserFillComponent } from 'libs/assets/src/icons/fill/icon_add_user_fill.svg';
import AppleFill, { ReactComponent as AppleFillComponent } from 'libs/assets/src/icons/fill/icon_apple_fill.svg';
import AutomatedSeedFill, { ReactComponent as AutomatedSeedFillComponent } from 'libs/assets/src/icons/fill/icon_automated_seed_fill.svg';
import BellFill, { ReactComponent as BellFillComponent } from 'libs/assets/src/icons/fill/icon_bell_fill.svg';
//Two Colors
import BitsApproved, { ReactComponent as BitsApprovedComponent } from 'libs/assets/src/icons/fill/icon_bits_approved.svg';
import BoxFill, { ReactComponent as BoxFillComponent } from 'libs/assets/src/icons/fill/icon_box_fill.svg';
import CalculatorFill, { ReactComponent as CalculatorFillComponent } from 'libs/assets/src/icons/fill/icon_calculator_fill.svg';
import CalendarFill, { ReactComponent as CalendarFillComponent } from 'libs/assets/src/icons/fill/icon_calendar_fill.svg';
import CallFill, { ReactComponent as CallFillComponent } from 'libs/assets/src/icons/fill/icon_call_fill.svg';
import CameraFill, { ReactComponent as CameraFillComponent } from 'libs/assets/src/icons/fill/icon_camera_fill.svg';
import CartFill, { ReactComponent as CartFillComponent } from 'libs/assets/src/icons/fill/icon_cart_fill.svg';
import ChampionFill, { ReactComponent as ChampionFillComponent } from 'libs/assets/src/icons/fill/icon_champion_fill.svg';
import ChatFill, { ReactComponent as ChatFillComponent } from 'libs/assets/src/icons/fill/icon_chat_fill.svg';
import CheckFill, { ReactComponent as CheckFillComponent } from 'libs/assets/src/icons/fill/icon_check_circle.svg';
import CheckInCircleFill, { ReactComponent as CheckInCircleFillComponent } from 'libs/assets/src/icons/fill/icon_check_in_circle_fill.svg';
import CheckMarkFill, { ReactComponent as CheckMarkFillComponent } from 'libs/assets/src/icons/fill/icon_check_mark_fill.svg';
//Chat Icon
import icon_chat_circle, { ReactComponent as IconChatCircle } from 'libs/assets/src/icons/fill/icon_circle.svg';
//MyOders
import icon_circle_filled_v2, {
  ReactComponent as icon_circle_filled_v2Component,
} from 'libs/assets/src/icons/fill/icon_circle_filled_v2.svg';
import CloseCircleFill, { ReactComponent as CloseCircleFillComponent } from 'libs/assets/src/icons/fill/icon_close_circle_fill.svg';
import CommentFill, { ReactComponent as CommentFillComponent } from 'libs/assets/src/icons/fill/icon_comment_fill.svg';
import CommunityCircleFill, {
  ReactComponent as CommunityCircleFillComponent,
} from 'libs/assets/src/icons/fill/icon_community_circle_fill.svg';
import CopyFill, { ReactComponent as CopyFillComponent } from 'libs/assets/src/icons/fill/icon_copy_fill.svg';
import CouponFill, { ReactComponent as CouponFillComponent } from 'libs/assets/src/icons/fill/icon_coupon_fill.svg';
import DeclineFill, { ReactComponent as DeclineFillComponent } from 'libs/assets/src/icons/fill/icon_decline_fill.svg';
import EditFill, { ReactComponent as EditFillComponent } from 'libs/assets/src/icons/fill/icon_edit_fill.svg';
import icon_error_fill, { ReactComponent as icon_error_fillComponent } from 'libs/assets/src/icons/fill/icon_error_fill.svg';
import ExclamationFill, { ReactComponent as ExclamationFillComponent } from 'libs/assets/src/icons/fill/icon_exclamation_fill.svg';
import EyeFill, { ReactComponent as EyeFillComponent } from 'libs/assets/src/icons/fill/icon_eye_fill.svg';
import FacebookFill, { ReactComponent as FacebookFillComponent } from 'libs/assets/src/icons/fill/icon_facebook_fill.svg';
import FlagFill, { ReactComponent as FlagFillComponent } from 'libs/assets/src/icons/fill/icon_flag_fill.svg';
import FoodFill, { ReactComponent as FoodFillComponent } from 'libs/assets/src/icons/fill/icon_food_fill.svg';
import GoogleFill, { ReactComponent as GoogleFillComponent } from 'libs/assets/src/icons/fill/icon_google_fill.svg';
import HomeFill, { ReactComponent as HomeFillComponent } from 'libs/assets/src/icons/fill/icon_home_fill.svg';
import LikeFill, { ReactComponent as LikeFillComponent } from 'libs/assets/src/icons/fill/icon_like_fill.svg';
import LocationSignFill, { ReactComponent as LocationSignFillComponent } from 'libs/assets/src/icons/fill/icon_location_sign_fill.svg';
import LockFill, { ReactComponent as LockFillComponent } from 'libs/assets/src/icons/fill/icon_lock_fill.svg';
import MaintenanceFill, { ReactComponent as MaintenanceFillComponent } from 'libs/assets/src/icons/fill/icon_maintenance_fill.svg';
import ManageReferee2Fill, {
  ReactComponent as ManageReferee2FillComponent,
} from 'libs/assets/src/icons/fill/icon_manage_referees2_fill.svg';
import ManageRequestsFill, {
  ReactComponent as ManageRequestsFillComponent,
} from 'libs/assets/src/icons/fill/icon_manage_requests_fill.svg';
import MicrophoneFill, { ReactComponent as MicrophoneFillComponent } from 'libs/assets/src/icons/fill/icon_microphone_fill.svg';
import MinusReturns, { ReactComponent as MinusReturnsComponent } from 'libs/assets/src/icons/fill/icon_minus.svg';
import MinusFill, { ReactComponent as MinusFillComponent } from 'libs/assets/src/icons/fill/icon_minus_fill.svg';
import MoneyFill, { ReactComponent as MoneyFillComponent } from 'libs/assets/src/icons/fill/icon_money_fill.svg';
import MyOrderFill, { ReactComponent as MyOrderFillComponent } from 'libs/assets/src/icons/fill/icon_my_order_fill.svg';
import ParticipantsFill, { ReactComponent as ParticipantsFillComponent } from 'libs/assets/src/icons/fill/icon_participants_fill.svg';
import PinFill, { ReactComponent as PinFillComponent } from 'libs/assets/src/icons/fill/icon_pin_fill.svg';
import PlayFill, { ReactComponent as PlayFillComponent } from 'libs/assets/src/icons/fill/icon_play_fill.svg';
import AddPlus, { ReactComponent as AddPlusComponent } from 'libs/assets/src/icons/fill/icon_plus_add.svg';
import QuestionFill, { ReactComponent as QuestionFillComponent } from 'libs/assets/src/icons/fill/icon_question_fill.svg';
import RadioButtonFill, { ReactComponent as RadioButtonFillComponent } from 'libs/assets/src/icons/fill/icon_radio_button_fill.svg';
import ScheduleFill, { ReactComponent as ScheduleFillComponent } from 'libs/assets/src/icons/fill/icon_schedule_fill.svg';
import SendFill, { ReactComponent as SendFillComponent } from 'libs/assets/src/icons/fill/icon_send_fill.svg';
import SettingsFill, { ReactComponent as SettingsFillComponent } from 'libs/assets/src/icons/fill/icon_settings_fill.svg';
import ShopFill, { ReactComponent as ShopFillComponent } from 'libs/assets/src/icons/fill/icon_shop_fill.svg';
import icon_skull_base_fill, { ReactComponent as IconSkullBaseFillComponent } from 'libs/assets/src/icons/fill/icon_skull_base_fill.svg';
import StarFill, { ReactComponent as StarFillComponent } from 'libs/assets/src/icons/fill/icon_star_fill.svg';
import TeamFill, { ReactComponent as TeamFillComponent } from 'libs/assets/src/icons/fill/icon_team_fill.svg';
import TimeFill, { ReactComponent as TimeFillComponent } from 'libs/assets/src/icons/fill/icon_time_fill.svg';
import TrashFill, { ReactComponent as TrashFillComponent } from 'libs/assets/src/icons/fill/icon_trash_fill.svg';
import TVFill, { ReactComponent as TVFillComponent } from 'libs/assets/src/icons/fill/icon_tv_fill.svg';
import TwitterFill, { ReactComponent as TwitterFillComponent } from 'libs/assets/src/icons/fill/icon_twitter_fill.svg';
import UplockFill, { ReactComponent as UplockFillComponent } from 'libs/assets/src/icons/fill/icon_unlock_fill.svg';
import UploadFill, { ReactComponent as UploadFillComponent } from 'libs/assets/src/icons/fill/icon_upload_fill.svg';
import UserFill, { ReactComponent as UserFillComponent } from 'libs/assets/src/icons/fill/icon_user_fill.svg';
import VideoFill, { ReactComponent as VideoFillComponent } from 'libs/assets/src/icons/fill/icon_video_fill.svg';
import VRFill, { ReactComponent as VRFillComponent } from 'libs/assets/src/icons/fill/icon_vr_fill.svg';
import WaitlistFill, { ReactComponent as WaitlistFillComponent } from 'libs/assets/src/icons/fill/icon_waitlist_fill.svg';
import WalletFill, { ReactComponent as WalletFillComponent } from 'libs/assets/src/icons/fill/icon_wallet_fill.svg';
import WhistleFill, { ReactComponent as WhistleFillComponent } from 'libs/assets/src/icons/fill/icon_whistle_fill.svg';
import AlertGreen, { ReactComponent as AlertGreenComponent } from 'libs/assets/src/icons/filled/AlertGreen.svg';
import AlertRed, { ReactComponent as AlertRedComponent } from 'libs/assets/src/icons/filled/AlertRed.svg';
import AlertYellow, { ReactComponent as AlertYellowComponent } from 'libs/assets/src/icons/filled/AlertYellow.svg';
// Shop Empty
import { default as ShopEmpty, ReactComponent as ShopEmptyComponent } from 'libs/assets/src/icons/filled/ShopEmpty.svg';
import Dangerous, { ReactComponent as DangerousIconComponent } from 'libs/assets/src/icons/filled/icon_dangerous.svg';
import HeartFill, { ReactComponent as HeartFillComponent } from 'libs/assets/src/icons/filled/icon_heart_fill.svg';
import { default as Processing, ReactComponent as ProcessingComponent } from 'libs/assets/src/icons/filled/processing.svg';
import tournament, { ReactComponent as tournamentComponent } from 'libs/assets/src/icons/filled/tournament-svg.svg';
//Letters
import ALetter, { ReactComponent as ALetterComponent } from 'libs/assets/src/icons/letters/icon_a_ltr.svg';
import LLetter, { ReactComponent as LLetterComponent } from 'libs/assets/src/icons/letters/icon_l_ltr.svg';
import PLetter, { ReactComponent as PLetterComponent } from 'libs/assets/src/icons/letters/icon_p_ltr.svg';
import YLetter, { ReactComponent as YLetterComponent } from 'libs/assets/src/icons/letters/icon_y_ltr.svg';
import Instagram2, { ReactComponent as Instagram2Component } from 'libs/assets/src/icons/logos/icon_Instagram2.svg';
import Activity, { ReactComponent as ActivityComponent } from 'libs/assets/src/icons/logos/icon_activity.svg';
import AppleStore, { ReactComponent as AppleStoreComponent } from 'libs/assets/src/icons/logos/icon_appleStore.svg';
import BattleNet, { ReactComponent as BattleNetComponent } from 'libs/assets/src/icons/logos/icon_battlenet.svg';
import BattlenetGray, { ReactComponent as BattlenetGrayComponent } from 'libs/assets/src/icons/logos/icon_battlenet_gray.svg';
//Other
import Check, { ReactComponent as CheckComponent } from 'libs/assets/src/icons/logos/icon_check.svg';
import Discord, { ReactComponent as DiscordComponent } from 'libs/assets/src/icons/logos/icon_discord.svg';
import DiscordGray, { ReactComponent as DiscordGrayComponent } from 'libs/assets/src/icons/logos/icon_discord_gray.svg';
import EpicGray, { ReactComponent as EpicGrayComponent } from 'libs/assets/src/icons/logos/icon_epic_gray.svg';
import Facebook, { ReactComponent as FacebookComponent } from 'libs/assets/src/icons/logos/icon_facebook.svg';
import FullScreen, { ReactComponent as FullScreenComponent } from 'libs/assets/src/icons/logos/icon_full_screen.svg';
import GooglePlay, { ReactComponent as GooglePlayComponent } from 'libs/assets/src/icons/logos/icon_googlePlay.svg';
import Instagram3, { ReactComponent as Instagram3Component } from 'libs/assets/src/icons/logos/icon_instagram3.svg';
import Instagram, { ReactComponent as InstagramComponent } from 'libs/assets/src/icons/logos/icon_instagram.svg';
import InstagramGray, { ReactComponent as InstagramGrayComponent } from 'libs/assets/src/icons/logos/icon_instagram_gray.svg';
import LinkedIn, { ReactComponent as LinkedInComponent } from 'libs/assets/src/icons/logos/icon_linkedin.svg';
import Minimize, { ReactComponent as MinimizeComponent } from 'libs/assets/src/icons/logos/icon_minimize.svg';
import Nintendo, { ReactComponent as NintendoComponent } from 'libs/assets/src/icons/logos/icon_nintendo.svg';
import NintendoGray, { ReactComponent as NintendoGrayComponent } from 'libs/assets/src/icons/logos/icon_nintendo_gray.svg';
import Origin, { ReactComponent as OriginComponent } from 'libs/assets/src/icons/logos/icon_origin.svg';
import OriginGray, { ReactComponent as OriginGrayComponent } from 'libs/assets/src/icons/logos/icon_origin_gray.svg';
import PlayStation, { ReactComponent as PlayStationComponent } from 'libs/assets/src/icons/logos/icon_playstation.svg';
import PlaystationGray, { ReactComponent as PlaystationGrayComponent } from 'libs/assets/src/icons/logos/icon_playstation_gray.svg';
//ShareMedia
import ShareFacebook, { ReactComponent as ShareFacebookComponent } from 'libs/assets/src/icons/logos/icon_share_facebook.svg';
import ShareLinkedin, { ReactComponent as ShareLinkedinComponent } from 'libs/assets/src/icons/logos/icon_share_linkedin.svg';
import ShareTwitter, { ReactComponent as ShareTwitterComponent } from 'libs/assets/src/icons/logos/icon_share_twitter.svg';
import Snapchat, { ReactComponent as SnapchatComponent } from 'libs/assets/src/icons/logos/icon_snapchat.svg';
import SnapchatGray, { ReactComponent as SnapchatGrayComponent } from 'libs/assets/src/icons/logos/icon_snapchat_gray.svg';
import StcPlay2, { ReactComponent as StcPlayComponent2 } from 'libs/assets/src/icons/logos/icon_stcPlay2.svg';
import StcPlay, { ReactComponent as StcPlayComponent } from 'libs/assets/src/icons/logos/icon_stcPlay.svg';
import Steam, { ReactComponent as SteamComponent } from 'libs/assets/src/icons/logos/icon_steam.svg';
import SteamGray, { ReactComponent as SteamGrayComponent } from 'libs/assets/src/icons/logos/icon_steam_gray.svg';
import Tabby, { ReactComponent as TabbyComponent } from 'libs/assets/src/icons/logos/icon_tabby.svg';
import Twitch, { ReactComponent as TwitchComponent } from 'libs/assets/src/icons/logos/icon_twitch.svg';
import TwitchGray, { ReactComponent as TwitchGrayComponent } from 'libs/assets/src/icons/logos/icon_twitch_gray.svg';
import Twitter2, { ReactComponent as Twitter2Component } from 'libs/assets/src/icons/logos/icon_twitter2.svg';
import Twitter, { ReactComponent as TwitterComponent } from 'libs/assets/src/icons/logos/icon_twitter.svg';
import TwitterGray, { ReactComponent as TwitterGrayComponent } from 'libs/assets/src/icons/logos/icon_twitter_gray.svg';
import TwitterX, { ReactComponent as TwitterXComponent } from 'libs/assets/src/icons/logos/icon_twitterx.svg';
import Ubisoft, { ReactComponent as UbisoftComponent } from 'libs/assets/src/icons/logos/icon_ubisoft.svg';
import UbisoftGray, { ReactComponent as UbisoftGrayComponent } from 'libs/assets/src/icons/logos/icon_ubisoft_gray.svg';
import Xbox, { ReactComponent as XboxComponent } from 'libs/assets/src/icons/logos/icon_xbox.svg';
import XboxGray, { ReactComponent as XboxGrayComponent } from 'libs/assets/src/icons/logos/icon_xbox_gray.svg';
import YouTube2, { ReactComponent as YouTube2Component } from 'libs/assets/src/icons/logos/icon_youtube2.svg';
import YouTube, { ReactComponent as YouTubeComponent } from 'libs/assets/src/icons/logos/icon_youtube.svg';
import Rejected, { ReactComponent as RejectedComponent } from 'libs/assets/src/icons/other/icon_bit_rejected.svg';
import Uncheck, { ReactComponent as UncheckComponent } from 'libs/assets/src/icons/other/icon_check_outline.svg';
//Tournament Section Stepper
import icon_circle_donut, { ReactComponent as IconCircleDonutComponent } from 'libs/assets/src/icons/other/icon_circle_donut.svg';
import icon_line_dashed, { ReactComponent as IconLineDashedComponent } from 'libs/assets/src/icons/other/icon_line_dashed.svg';
import icon_line_filled, { ReactComponent as IconLineFilledComponent } from 'libs/assets/src/icons/other/icon_line_filled.svg';
import Media, { ReactComponent as MediaComponent } from 'libs/assets/src/icons/other/icon_media.svg';
import MicrophoneMute, { ReactComponent as MicrophoneMuteComponent } from 'libs/assets/src/icons/other/icon_microphone_mute.svg';
import Polls, { ReactComponent as PollsComponent } from 'libs/assets/src/icons/other/icon_poll.svg';
import QRCodeScanner, { ReactComponent as QRCodeScannerComponent } from 'libs/assets/src/icons/other/icon_qrcode_scanner.svg';
import SpeakerMute1, { ReactComponent as SpeakerMute1Component } from 'libs/assets/src/icons/other/icon_speaker_mute-1.svg';
import SpeakerMuteWhite, { ReactComponent as SpeakerMuteWhiteComponent } from 'libs/assets/src/icons/other/icon_speaker_mute-white.svg';
import SpeakerMute, { ReactComponent as SpeakerMuteComponent } from 'libs/assets/src/icons/other/icon_speaker_mute.svg';
import Unchecked, { ReactComponent as UncheckedComponent } from 'libs/assets/src/icons/other/icon_unchecked_outline.svg';
import Verified1, { ReactComponent as Verified1Component } from 'libs/assets/src/icons/other/icon_verified_1.svg';
import Verified2, { ReactComponent as Verified2Component } from 'libs/assets/src/icons/other/icon_verified_2.svg';
import VideoDisabled, { ReactComponent as VideoDisabledComponent } from 'libs/assets/src/icons/other/icon_video_disable.svg';
import WifiOff, { ReactComponent as WifiOffComponent } from 'libs/assets/src/icons/other/icon_wifi_off.svg';
import WifiOn, { ReactComponent as WifiOnComponent } from 'libs/assets/src/icons/other/icon_wifi_on.svg';
import MenuButton, { ReactComponent as MenuBurgerComponent } from 'libs/assets/src/icons/other/icons_burger_menu_button.svg';
import CloseXbutton, { ReactComponent as CloseXButtonComponent } from 'libs/assets/src/icons/other/icons_close_button.svg';
//Mini Games
import { ReactComponent as UserViewComponent, default as UserViewCount } from 'libs/assets/src/icons/other/userView.svg';
//Outline
import AddCartOutline, { ReactComponent as AddCartOutlineComponent } from 'libs/assets/src/icons/outline/icon_add_cart_outline.svg';
import AddCircleOutline, { ReactComponent as AddCircleOutlineComponent } from 'libs/assets/src/icons/outline/icon_add_circle_outline.svg';
import AddImageOutline, { ReactComponent as AddImageOutlineComponent } from 'libs/assets/src/icons/outline/icon_add_image_outline.svg';
import AddOutline, { ReactComponent as AddOutlineComponent } from 'libs/assets/src/icons/outline/icon_add_outline.svg';
import AddPostOutline, { ReactComponent as AddPostOutlineComponent } from 'libs/assets/src/icons/outline/icon_add_post_outline.svg';
import AddUserOutline, { ReactComponent as AddUserOutlineComponent } from 'libs/assets/src/icons/outline/icon_add_user_outline.svg';
import AddUser2Outline, { ReactComponent as AddUser2OutlineComponent } from 'libs/assets/src/icons/outline/icon_add_user_outline_2.svg';
import AnalyticsOutline, { ReactComponent as AnalyticsOutlineComponent } from 'libs/assets/src/icons/outline/icon_analytics_outline.svg';
import AttachmentOutline, { ReactComponent as AttachmentOutlineComponent } from 'libs/assets/src/icons/outline/icon_attachment_outline.svg';
import AutomatedSeedOutline, {
  ReactComponent as AutomatedSeedOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_automated_seed_outline.svg';
import BellOutline, { ReactComponent as BellOutlineComponent } from 'libs/assets/src/icons/outline/icon_bell_outline.svg';
import BellWhiteOutline, { ReactComponent as BellWhiteOutlineComponent } from 'libs/assets/src/icons/outline/icon_bell_white_outline.svg';
import ViewsOutline, { ReactComponent as ViewsOutlineComponent } from 'libs/assets/src/icons/outline/icon_bits_views.svg';
import BoxOutline, { ReactComponent as BoxOutlineComponent } from 'libs/assets/src/icons/outline/icon_box_outline.svg';
//Header Icon
import ScreenBrightness, { ReactComponent as ScreenBrightnessComponent } from 'libs/assets/src/icons/outline/icon_brigtness.svg';
import CalCulatorOutline, { ReactComponent as CalCulatorOutlineComponent } from 'libs/assets/src/icons/outline/icon_calculator_outline.svg';
import CalendarOutline, { ReactComponent as CalendarOutlineComponent } from 'libs/assets/src/icons/outline/icon_calendar_outline.svg';
import CallOutline, { ReactComponent as CallOutlineComponent } from 'libs/assets/src/icons/outline/icon_call_outline.svg';
import CameraOutline, { ReactComponent as CameraOutlineComponent } from 'libs/assets/src/icons/outline/icon_camera_outline.svg';
import CartOutline, { ReactComponent as CartOutlineComponent } from 'libs/assets/src/icons/outline/icon_cart_outline.svg';
import ChampionOutline, { ReactComponent as ChampionOutlineComponent } from 'libs/assets/src/icons/outline/icon_champion_outline.svg';
import ChatIcon, { ReactComponent as ChatIconComponent } from 'libs/assets/src/icons/outline/icon_chat.svg';
import icon_chat_maximize, { ReactComponent as IconChatMaximize } from 'libs/assets/src/icons/outline/icon_chat_maximize.svg';
import icon_chat_new, { ReactComponent as IconChatNew } from 'libs/assets/src/icons/outline/icon_chat_new.svg';
import ChatOutline, { ReactComponent as ChatOutlineComponent } from 'libs/assets/src/icons/outline/icon_chat_outline.svg';
import CheckCircleOutline, {
  ReactComponent as CheckCircleOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_check_circle_outline.svg';
import CheckInCircleOutline, {
  ReactComponent as CheckInCircleOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_check_in_circle_outline.svg';
import CloseCircleOutline, {
  ReactComponent as CloseCircleOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_close_circle_outline.svg';
import CloseOutline, { ReactComponent as CloseOutlineComponent } from 'libs/assets/src/icons/outline/icon_close_outline.svg';
import CommentOutline, { ReactComponent as CommentOutlineComponent } from 'libs/assets/src/icons/outline/icon_comment_outline.svg';
import CommunityCircleOutline, {
  ReactComponent as CommunityCircleOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_community_circle_outline.svg';
import CopyOutline, { ReactComponent as CopyOutlineComponent } from 'libs/assets/src/icons/outline/icon_copy_outline.svg';
import CouponOutline, { ReactComponent as CouponOutlineComponent } from 'libs/assets/src/icons/outline/icon_coupon_outline.svg';
import DeclineOutline, { ReactComponent as DeclineOutlineComponent } from 'libs/assets/src/icons/outline/icon_decline_outline.svg';
import DeleteOutline, { ReactComponent as DeleteOutlineComponent } from 'libs/assets/src/icons/outline/icon_delete_outline.svg';
import DownloadOutline, { ReactComponent as DownloadOutlineComponent } from 'libs/assets/src/icons/outline/icon_download_outline.svg';
import EditOutline2, { ReactComponent as EditOutlineComponent2 } from 'libs/assets/src/icons/outline/icon_edit.svg';
import EditOutline, { ReactComponent as EditOutlineComponent } from 'libs/assets/src/icons/outline/icon_edit_outline.svg';
import EndCallOutline, { ReactComponent as EndCallOutlineComponent } from 'libs/assets/src/icons/outline/icon_endCall_outline.svg';
import ExclamationOutline, {
  ReactComponent as ExclamationOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_exclamation_outline.svg';
import SDKExitScreen, { ReactComponent as SDKExitScreenComponent } from 'libs/assets/src/icons/outline/icon_exitscreen.svg';
import EyeClosedOutline, { ReactComponent as EyeClosedOutlineComponent } from 'libs/assets/src/icons/outline/icon_eye_closed_outline.svg';
import EyeOutline, { ReactComponent as EyeOutlineComponent } from 'libs/assets/src/icons/outline/icon_eye_outline.svg';
import FilterOutline, { ReactComponent as FilterOutlineComponent } from 'libs/assets/src/icons/outline/icon_filter_outline.svg';
import FlagOutline, { ReactComponent as FlagOutlineComponent } from 'libs/assets/src/icons/outline/icon_flag_outline.svg';
import Country, { ReactComponent as CountryComponent } from 'libs/assets/src/icons/outline/icon_flags_outline.svg';
import FoodOutline, { ReactComponent as FoodOutlineComponent } from 'libs/assets/src/icons/outline/icon_food_outline.svg';
import SDKFullScreen, { ReactComponent as SDKFullScreenComponent } from 'libs/assets/src/icons/outline/icon_fullscreen.svg';
import icon_header_chat, { ReactComponent as IconHeaderChat } from 'libs/assets/src/icons/outline/icon_header_chat.svg';
import icon_header_notification, {
  ReactComponent as IconHeaderNotification,
} from 'libs/assets/src/icons/outline/icon_header_notification.svg';
import HeartOutline1, { ReactComponent as HeartOutline1Component } from 'libs/assets/src/icons/outline/icon_heart_outline1.svg';
import HeartOutline, { ReactComponent as HeartOutlineComponent } from 'libs/assets/src/icons/outline/icon_heart_outline.svg';
import HomeOutline, { ReactComponent as HomeOutlineComponent } from 'libs/assets/src/icons/outline/icon_home_outline.svg';
// Wallet no transaction
import InfoIcon, { ReactComponent as InfoIconComponent } from 'libs/assets/src/icons/outline/icon_info.svg';
import LeftArrow, { ReactComponent as LeftArrowComponent } from 'libs/assets/src/icons/outline/icon_leftarrow.svg';
import LikeOutline, { ReactComponent as LikeOutlineComponent } from 'libs/assets/src/icons/outline/icon_like_outline.svg';
import LinkOutline, { ReactComponent as LinkOutlineComponent } from 'libs/assets/src/icons/outline/icon_link_outline.svg';
import LocalATM, { ReactComponent as LocalATMComponent } from 'libs/assets/src/icons/outline/icon_local_atm_outline.svg';
import LocationSignOutline, {
  ReactComponent as LocationSignOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_location_sign_outline.svg';
import LockOutline, { ReactComponent as LockOutlineComponent } from 'libs/assets/src/icons/outline/icon_lock_outline.svg';
import LockScreen, { ReactComponent as LockScreenComponent } from 'libs/assets/src/icons/outline/icon_lock_screen.svg';
import LogoutOutline, { ReactComponent as LogoutOutlineComponent } from 'libs/assets/src/icons/outline/icon_logout_outline.svg';
import MaintenanceOutline, {
  ReactComponent as MaintenanceOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_maintenance_outline.svg';
import ManageReferee2Outline, {
  ReactComponent as ManageReferee2OutlineComponent,
} from 'libs/assets/src/icons/outline/icon_manage_referees2_outline.svg';
import ManageRefereesOutline, {
  ReactComponent as ManageRefereesOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_manage_referees_outline.svg';
import ManageRequestsOutline, {
  ReactComponent as ManageRequestsOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_manage_requests_outline.svg';
import MicrophoneOutline, { ReactComponent as MicrophoneOutlineComponent } from 'libs/assets/src/icons/outline/icon_microphone_outline.svg';
import MinusOutline, { ReactComponent as MinusOutlineComponent } from 'libs/assets/src/icons/outline/icon_minus_outline.svg';
import MoneyOutline, { ReactComponent as MoneyOutlineComponent } from 'libs/assets/src/icons/outline/icon_money_outline.svg';
import MyOrderOutline, { ReactComponent as MyOrderOutlineComponent } from 'libs/assets/src/icons/outline/icon_my_order_outline.svg';
import NewChatOutline, { ReactComponent as NewChatOutlineComponent } from 'libs/assets/src/icons/outline/icon_new_chat_outline.svg';
import NewGameOutline, { ReactComponent as NewGameOutlineComponent } from 'libs/assets/src/icons/outline/icon_new_game_outline.svg';
import NewTeamOutline, { ReactComponent as NewTeamOutlineComponent } from 'libs/assets/src/icons/outline/icon_new_team_outline.svg';
import NewBellOutline, { ReactComponent as NewBellOutlineComponent } from 'libs/assets/src/icons/outline/icon_newbell_outline.svg';
import ParticipantsOutline, {
  ReactComponent as ParticipantsOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_participants_outline.svg';
import VideoPauseSymbolOutline, {
  ReactComponent as VideoPauseSymbolOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_pause_video.svg';
import Currency, { ReactComponent as CurrencyComponent } from 'libs/assets/src/icons/outline/icon_payments_outline.svg';
import PictureOutline, { ReactComponent as PictureOutlineComponent } from 'libs/assets/src/icons/outline/icon_picture_outline.svg';
import PinOutline, { ReactComponent as PinOutlineComponent } from 'libs/assets/src/icons/outline/icon_pin_outline.svg';
import ImageOutline, { ReactComponent as ImageOutlineComponent } from 'libs/assets/src/icons/outline/icon_placeholder_image_outline.svg';
import PlayOutline, { ReactComponent as PlayOutlineComponent } from 'libs/assets/src/icons/outline/icon_play_outline.svg';
import PlaySymbolOutline, {
  ReactComponent as PlaySymbolOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_play_symbol_outline.svg';
import VideoPlaySymbolOutline, {
  ReactComponent as VideoPlaySymbolOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_play_video.svg';
import PreferencesOutline, {
  ReactComponent as PreferencesOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_preferences_outline.svg';
import QualityIcon, { ReactComponent as QualityIconComponent } from 'libs/assets/src/icons/outline/icon_quality.svg';
import QuestionOutline, { ReactComponent as QuestionOutlineComponent } from 'libs/assets/src/icons/outline/icon_question_outline.svg';
import RadioButtonOutline, {
  ReactComponent as RadioButtonOutlineComponent,
} from 'libs/assets/src/icons/outline/icon_radio_button_outline.svg';
import RepostOutline, { ReactComponent as RepostOutlineComponent } from 'libs/assets/src/icons/outline/icon_repost_outline.svg';
import ScheduleOutline, { ReactComponent as ScheduleOutlineComponent } from 'libs/assets/src/icons/outline/icon_schedule_outline.svg';
import SearchOutline, { ReactComponent as SearchOutlineComponent } from 'libs/assets/src/icons/outline/icon_search_outline.svg';
import SendOutline, { ReactComponent as SendOutlineComponent } from 'libs/assets/src/icons/outline/icon_send_outline.svg';
import SettingsOutline, { ReactComponent as SettingsOutlineComponent } from 'libs/assets/src/icons/outline/icon_settings_outline.svg';
import ShareOutline, { ReactComponent as ShareOutlineComponent } from 'libs/assets/src/icons/outline/icon_share_outline.svg';
import ShopOutline, { ReactComponent as ShopOutlineComponent } from 'libs/assets/src/icons/outline/icon_shop_outline.svg';
import SpeakerOutline, { ReactComponent as SpeakerOutlineComponent } from 'libs/assets/src/icons/outline/icon_speaker_outline.svg';
import StarOutline, { ReactComponent as StarOutlineComponent } from 'libs/assets/src/icons/outline/icon_star_outline.svg';
import TeamOutline, { ReactComponent as TeamOutlineComponent } from 'libs/assets/src/icons/outline/icon_team_outline.svg';
import ThumbOutline, { ReactComponent as ThumbOutlineComponent } from 'libs/assets/src/icons/outline/icon_thumb_outline.svg';
import TimeOutline, { ReactComponent as TimeOutlineComponent } from 'libs/assets/src/icons/outline/icon_time_outline.svg';
import TrashOutline, { ReactComponent as TrashOutlineComponent } from 'libs/assets/src/icons/outline/icon_trash_outline.svg';
import TVOutline, { ReactComponent as TVOutlineComponent } from 'libs/assets/src/icons/outline/icon_tv_outline.svg';
import UnLockScreen, { ReactComponent as UnLockScreenComponent } from 'libs/assets/src/icons/outline/icon_unLock_screen.svg';
import Undo, { ReactComponent as UndoComponent } from 'libs/assets/src/icons/outline/icon_undo_outline.svg';
import UnfollowOutline, { ReactComponent as UnfollowOutlineComponent } from 'libs/assets/src/icons/outline/icon_unfollow_outline.svg';
import UplockOutline, { ReactComponent as UplockOutlineComponent } from 'libs/assets/src/icons/outline/icon_unlock_outline.svg';
import UploadOutline, { ReactComponent as UploadOutlineComponent } from 'libs/assets/src/icons/outline/icon_upload_outline.svg';
import UserOutline, { ReactComponent as UserOutlineComponent } from 'libs/assets/src/icons/outline/icon_user_outline.svg';
import VectorOutline, { ReactComponent as VectorOutlineComponent } from 'libs/assets/src/icons/outline/icon_vector_outline.svg';
import Verified, { ReactComponent as VerifiedComponent } from 'libs/assets/src/icons/outline/icon_verified_user_outline.svg';
import VROutline, { ReactComponent as VROutlineComponent } from 'libs/assets/src/icons/outline/icon_vr_outline.svg';
import WaitlistOutline, { ReactComponent as WaitlistOutlineComponent } from 'libs/assets/src/icons/outline/icon_waitlist_outline.svg';
import WalletOutline, { ReactComponent as WalletOutlineComponent } from 'libs/assets/src/icons/outline/icon_wallet_outline.svg';
import WarningOutlined, { ReactComponent as WarningOutlinedComponent } from 'libs/assets/src/icons/outline/icon_warning.svg';
import WhistleOutline, { ReactComponent as WhistleOutlineComponent } from 'libs/assets/src/icons/outline/icon_whistle_outline.svg';
import PhoneEmpty, { ReactComponent as PhoneEmptyComponent } from 'libs/assets/src/icons/placeholder/phone_empty.svg';
import AlertAddCheck, { ReactComponent as AlertAddCheckComponent } from 'libs/assets/src/icons/regular/AlertAddCheck.svg';
import AlertFlame, { ReactComponent as AlertFlameComponent } from 'libs/assets/src/icons/regular/AlertFlame.svg';
import Ended, { ReactComponent as EndedComponent } from 'libs/assets/src/icons/regular/Ended.svg';
import Started, { ReactComponent as StartedComponent } from 'libs/assets/src/icons/regular/Started.svg';
import Starts, { ReactComponent as StartsComponent } from 'libs/assets/src/icons/regular/Starts.svg';
//Regular
import EmailVerification, { ReactComponent as EmailVerificationComponent } from 'libs/assets/src/icons/regular/emailVerification.svg';
import EmailVerified, { ReactComponent as EmailVerifiedComponent } from 'libs/assets/src/icons/regular/emailVerified.svg';
import Gallery, { ReactComponent as GalleryComponent } from 'libs/assets/src/icons/regular/gallery.svg';
import HomeBannerBackground, {
  ReactComponent as HomeBannerBackgroundComponent,
} from 'libs/assets/src/icons/regular/home_banner_background.svg';
import HomeBannerBackgroundMobile, {
  ReactComponent as HomeBannerBackgroundMobileComponent,
} from 'libs/assets/src/icons/regular/home_banner_background_mobile.svg';
import NotFoundDark, { ReactComponent as NotFoundDarkComponent } from 'libs/assets/src/icons/regular/icon_404_dark.svg';
import NotFoundLight, { ReactComponent as NotFoundLightComponent } from 'libs/assets/src/icons/regular/icon_404_light.svg';
import Add, { ReactComponent as AddComponent } from 'libs/assets/src/icons/regular/icon_add2.svg';
import Alert, { ReactComponent as AlertComponent } from 'libs/assets/src/icons/regular/icon_alert.svg';
import Arrow, { ReactComponent as ArrowComponent } from 'libs/assets/src/icons/regular/icon_arrow.svg';
import Attachment1, { ReactComponent as Attachment1Component } from 'libs/assets/src/icons/regular/icon_attachment_1.svg';
import Avatar, { ReactComponent as AvatarComponent } from 'libs/assets/src/icons/regular/icon_avatar.svg';
import BlockRegular, { ReactComponent as BlockRegularComponent } from 'libs/assets/src/icons/regular/icon_block_regular.svg';
import BlockedAccount, { ReactComponent as BlockedAccountComponent } from 'libs/assets/src/icons/regular/icon_blocked_account.svg';
import Calendar1, { ReactComponent as Calendar1Component } from 'libs/assets/src/icons/regular/icon_calendar_1.svg';
import icon_chat_group, { ReactComponent as IconChatGroup } from 'libs/assets/src/icons/regular/icon_chat_group.svg';
import icon_chat_minimize, { ReactComponent as IconChatMinimize } from 'libs/assets/src/icons/regular/icon_chat_minimize.svg';
import icon_chat_minimize_underline, {
  ReactComponent as IconChatMinimizeUnderline,
} from 'libs/assets/src/icons/regular/icon_chat_minimize_underline.svg';
import icon_chat_chat_normal_check, {
  ReactComponent as IconChatNormalCheck,
} from 'libs/assets/src/icons/regular/icon_chat_normal_check.svg';
import IconCircleGrayChecked, {
  ReactComponent as IconCircleGrayCheckedComponent,
} from 'libs/assets/src/icons/regular/icon_check_circle.svg';
import CheckInRegular, { ReactComponent as CheckInRegularComponent } from 'libs/assets/src/icons/regular/icon_check_in_regular.svg';
import ChevronRight, { ReactComponent as ChevronRightComponent } from 'libs/assets/src/icons/regular/icon_chevron-right.svg';
import Chevron, { ReactComponent as ChevronComponent } from 'libs/assets/src/icons/regular/icon_chevron.svg';
import IconCircleFilled, { ReactComponent as IconCircleFilledComponent } from 'libs/assets/src/icons/regular/icon_circle_filled.svg';
import IconCircleGray, { ReactComponent as IconCircleGreyComponent } from 'libs/assets/src/icons/regular/icon_circle_gray.svg';
import CircleOutlined, { ReactComponent as CircleOutlinedComponent } from 'libs/assets/src/icons/regular/icon_circle_outline.svg';
import TargetIcon, { ReactComponent as TargetIconComponent } from 'libs/assets/src/icons/regular/icon_circle_target.svg';
import Close, { ReactComponent as CloseComponent } from 'libs/assets/src/icons/regular/icon_close.svg';
import Close1, { ReactComponent as Close1Component } from 'libs/assets/src/icons/regular/icon_close_1.svg';
import icon_copy, { ReactComponent as icon_copyComponent } from 'libs/assets/src/icons/regular/icon_content_copy.svg';
import DarkMode, { ReactComponent as DarkModeComponent } from 'libs/assets/src/icons/regular/icon_dark_mode.svg';
import DeliveryTruck, { ReactComponent as DeliveryTruckComponent } from 'libs/assets/src/icons/regular/icon_delivery_truck.svg';
import Download, { ReactComponent as DownloadComponent } from 'libs/assets/src/icons/regular/icon_download.svg';
import EmailChecked, { ReactComponent as EmailCheckedComponent } from 'libs/assets/src/icons/regular/icon_email_checked.svg';
import Error, { ReactComponent as ErrorComponent } from 'libs/assets/src/icons/regular/icon_error.svg';
import icon_expand_circle_right, {
  ReactComponent as icon_expand_circle_rightComponent,
} from 'libs/assets/src/icons/regular/icon_expand_circle_right.svg';
import FilterList, { ReactComponent as FilterListComponent } from 'libs/assets/src/icons/regular/icon_filter_list.svg';
import CountryFlag, { ReactComponent as CountryFlagComponent } from 'libs/assets/src/icons/regular/icon_flags.svg';
import Grid, { ReactComponent as GridComponent } from 'libs/assets/src/icons/regular/icon_grid.svg';
import HamburderMenu, { ReactComponent as HamburgerMenuComponent } from 'libs/assets/src/icons/regular/icon_hamburger.svg';
import InternalServerErrorDark, {
  ReactComponent as InternalServerErrorDarkComponent,
} from 'libs/assets/src/icons/regular/icon_internal_server_error_dark.svg';
import InternalServerErrorLight, {
  ReactComponent as InternalServerErrorLightComponent,
} from 'libs/assets/src/icons/regular/icon_internal_server_error_light.svg';
import Capslock, { ReactComponent as CapslockComponent } from 'libs/assets/src/icons/regular/icon_keyboard_capslock.svg';
import Language, { ReactComponent as LanguageComponent } from 'libs/assets/src/icons/regular/icon_language.svg';
import List, { ReactComponent as ListComponent } from 'libs/assets/src/icons/regular/icon_list.svg';
import Menu, { ReactComponent as MenuComponent } from 'libs/assets/src/icons/regular/icon_menu.svg';
import MenuRegular, { ReactComponent as MenuRegularComponent } from 'libs/assets/src/icons/regular/icon_menu_regular.svg';
import MinusRegular2, { ReactComponent as MinusRegular2Component } from 'libs/assets/src/icons/regular/icon_minus2.svg';
import MinusRegular, { ReactComponent as MinusRegularComponent } from 'libs/assets/src/icons/regular/icon_minus_outline.svg';
import Moon, { ReactComponent as MoonComponent } from 'libs/assets/src/icons/regular/icon_moon.svg';
import MoreHorizon, { ReactComponent as MoreHorizonComponent } from 'libs/assets/src/icons/regular/icon_more_horiz.svg';
import NoInternetLight, { ReactComponent as NoInternetLightComponent } from 'libs/assets/src/icons/regular/icon_no_internet_light.svg';
import Notification, { ReactComponent as NotificationComponent } from 'libs/assets/src/icons/regular/icon_notification.svg';
import Openregular, { ReactComponent as OpenregularComponent } from 'libs/assets/src/icons/regular/icon_open_regular.svg';
import OptionsRegular, { ReactComponent as OptionsRegularComponent } from 'libs/assets/src/icons/regular/icon_options_regular.svg';
import Payments, { ReactComponent as PaymentsComponent } from 'libs/assets/src/icons/regular/icon_payments.svg';
import PlayerSelectEmpty, {
  ReactComponent as PlayerSelectEmptyComponent,
} from 'libs/assets/src/icons/regular/icon_player_select_empty.svg';
import Promoted, { ReactComponent as PromotedComponent } from 'libs/assets/src/icons/regular/icon_promoted.svg';
import RadioCheck, { ReactComponent as RadioCheckComponent } from 'libs/assets/src/icons/regular/icon_radio_check.svg';
import RadioUncheck, { ReactComponent as RadioUncheckComponent } from 'libs/assets/src/icons/regular/icon_radio_uncheck.svg';
import icon_redeem, { ReactComponent as icon_redeemComponent } from 'libs/assets/src/icons/regular/icon_redeem.svg';
import Reorder, { ReactComponent as ReorderComponent } from 'libs/assets/src/icons/regular/icon_reorder.svg';
import Search, { ReactComponent as SearchComponent } from 'libs/assets/src/icons/regular/icon_search.svg';
import Search1, { ReactComponent as Search1Component } from 'libs/assets/src/icons/regular/icon_search_1.svg';
import SentCheck, { ReactComponent as SentCheckComponent } from 'libs/assets/src/icons/regular/icon_sent_check.svg';
import ServerDownDark, { ReactComponent as ServerDownDarkComponent } from 'libs/assets/src/icons/regular/icon_server_down_dark.svg';
import ServerDownLight, { ReactComponent as ServerDownLightComponent } from 'libs/assets/src/icons/regular/icon_server_down_light.svg';
import ServerDownMobile, { ReactComponent as ServerDownMobileComponent } from 'libs/assets/src/icons/regular/icon_server_down_mobile.svg';
import Shipping, { ReactComponent as ShippingComponent } from 'libs/assets/src/icons/regular/icon_shipping.svg';
import ShoppingCart, { ReactComponent as ShoppingCartComponent } from 'libs/assets/src/icons/regular/icon_shopping_cart.svg';
import ShoppingCart1, { ReactComponent as ShoppingCart1Component } from 'libs/assets/src/icons/regular/icon_shopping_cart_1.svg';
import STC, { ReactComponent as STCComponent } from 'libs/assets/src/icons/regular/icon_stc.svg';
import Swords, { ReactComponent as SwordsComponent } from 'libs/assets/src/icons/regular/icon_swords.svg';
import Talk, { ReactComponent as TalkComponent } from 'libs/assets/src/icons/regular/icon_talk.svg';
import TaskSuccess, { ReactComponent as TaskSuccessComponent } from 'libs/assets/src/icons/regular/icon_task_success.svg';
//Toast
import icon_toast_checkmark, { ReactComponent as IconToastCheckmark } from 'libs/assets/src/icons/regular/icon_toast_checkmark.svg';
import icon_toast_cross_error, { ReactComponent as IconToastCrossError } from 'libs/assets/src/icons/regular/icon_toast_cross_error.svg';
import icon_toast_exclamation, { ReactComponent as IconToastExclamation } from 'libs/assets/src/icons/regular/icon_toast_exclamation.svg';
import CartItemDelete, { ReactComponent as CartItemDeleteComponent } from 'libs/assets/src/icons/regular/icon_trash.svg';
import Trophy, { ReactComponent as TrophyComponent } from 'libs/assets/src/icons/regular/icon_trophy.svg';
import Trophy1, { ReactComponent as Trophy1Component } from 'libs/assets/src/icons/regular/icon_trophy_1.svg';
import TwoFactorIconv2, { ReactComponent as TwoFactorIconComponentv2 } from 'libs/assets/src/icons/regular/icon_two_factor2.svg';
import TwoFactorIcon, { ReactComponent as TwoFactorIconComponent } from 'libs/assets/src/icons/regular/icon_two_factor.svg';
import Users, { ReactComponent as UsersComponent } from 'libs/assets/src/icons/regular/icon_users.svg';
//Shop > Cart > Add Address
import LocalFireDepartment, {
  ReactComponent as LocalFireDepartmentComponent,
} from 'libs/assets/src/icons/regular/local_fire_department.svg';
import Predict, { ReactComponent as PredictComponent } from 'libs/assets/src/icons/regular/predict.svg';
import radio_button_dark, { ReactComponent as RadioButtonDark } from 'libs/assets/src/icons/regular/radio_button_dark.svg';
import radio_button_light, { ReactComponent as RadioButtonLight } from 'libs/assets/src/icons/regular/radio_button_light.svg';
import SendMessage, { ReactComponent as SendMessageComponent } from 'libs/assets/src/icons/regular/sendMessage.svg';
import Today, { ReactComponent as TodayComponent } from 'libs/assets/src/icons/regular/today.svg';
import WarningRegular, { ReactComponent as WarningRegularComponent } from 'libs/assets/src/icons/regular/warning_regular.svg';
import AddPostTwoColor, { ReactComponent as AddPostTwoColorComponent } from 'libs/assets/src/icons/twoColor/icon_add_post_2color.svg';
import LinkTwoColor, { ReactComponent as LinkTwoColorComponent } from 'libs/assets/src/icons/twoColor/icon_link_2color.svg';
import NewChatTwoColor, { ReactComponent as NewChatTwoColorComponent } from 'libs/assets/src/icons/twoColor/icon_new_chat_2color.svg';
import NewGameTwoColor, { ReactComponent as NewGameTwoColorComponent } from 'libs/assets/src/icons/twoColor/icon_new_game_2color.svg';
import NewTeamTwoColor, { ReactComponent as NewTeamTwoColorComponent } from 'libs/assets/src/icons/twoColor/icon_new_team_2color.svg';
import PictureTwoColor, { ReactComponent as PictureTwoColorComponent } from 'libs/assets/src/icons/twoColor/icon_picture_2color.svg';
import PreferencesTwoColor, {
  ReactComponent as PreferencesTwoColorComponent,
} from 'libs/assets/src/icons/twoColor/icon_preferences_2color.svg';
import AddAccount, { ReactComponent as AddAccountComponent } from 'libs/assets/src/icons/v3/add_account.svg';
import Approved, { ReactComponent as ApprovedComponent } from 'libs/assets/src/icons/v3/approved.svg';
import arrowDown, { ReactComponent as ArrowDownComponent } from 'libs/assets/src/icons/v3/arrow_down.svg';
import ArrowRight, { ReactComponent as ArrowRightComponent } from 'libs/assets/src/icons/v3/arrow_right.svg';
import arrowUp, { ReactComponent as ArrowUpComponent } from 'libs/assets/src/icons/v3/arrow_up.svg';
import attach_file, { ReactComponent as AttachComponent } from 'libs/assets/src/icons/v3/attach_file.svg';
import BellSettings, { ReactComponent as BellSettingsComponent } from 'libs/assets/src/icons/v3/bell_settings.svg';
import Cash, { ReactComponent as CashComponent } from 'libs/assets/src/icons/v3/cash.svg';
import ChatProcessOutline, { ReactComponent as ChatProcessOutlineComponent } from 'libs/assets/src/icons/v3/chat-processing-outline.svg';
import CheckCircle, { ReactComponent as CheckCircleComponent } from 'libs/assets/src/icons/v3/check_circle.svg';
import ContactUsSuccess, { ReactComponent as ContactUsSuccessComponent } from 'libs/assets/src/icons/v3/contact-us-success.svg';
import CreditCard, { ReactComponent as CreditCardComponent } from 'libs/assets/src/icons/v3/credit_card.svg';
import Crown, { ReactComponent as CrownComponent } from 'libs/assets/src/icons/v3/crown.svg';
import EmailContactUs, { ReactComponent as EmailContactUsComponent } from 'libs/assets/src/icons/v3/email-contact-us.svg';
import Email, { ReactComponent as EmailComponent } from 'libs/assets/src/icons/v3/email.svg';
import Encrypted, { ReactComponent as EncryptedComponent } from 'libs/assets/src/icons/v3/encrypted.svg';
import FileLock, { ReactComponent as FileLockComponent } from 'libs/assets/src/icons/v3/file_lock.svg';
import GenderMaleFemale, { ReactComponent as GenderMaleFemaleComponent } from 'libs/assets/src/icons/v3/gender_male_female.svg';
import GroupChatIcon, { ReactComponent as GroupChatIconComponent } from 'libs/assets/src/icons/v3/group-icon.svg';
import help, { ReactComponent as HelpComponent } from 'libs/assets/src/icons/v3/help.svg';
import HomeEdit, { ReactComponent as HomeEditComponent } from 'libs/assets/src/icons/v3/home_edit.svg';
import icon_1, { ReactComponent as Icon1Component } from 'libs/assets/src/icons/v3/icon_1.svg';
import icon_2, { ReactComponent as Icon2Component } from 'libs/assets/src/icons/v3/icon_2.svg';
import icon_3, { ReactComponent as Icon3Component } from 'libs/assets/src/icons/v3/icon_3.svg';
import icon_4, { ReactComponent as Icon4Component } from 'libs/assets/src/icons/v3/icon_4.svg';
import CalendarV3, { ReactComponent as CalendarV3Component } from 'libs/assets/src/icons/v3/icon_calendar_range.svg';
import CheckboxChecked, { ReactComponent as CheckboxCheckedComponent } from 'libs/assets/src/icons/v3/icon_checkbox_filled.svg';
import CheckboxUnchecked, { ReactComponent as CheckboxUncheckedComponent } from 'libs/assets/src/icons/v3/icon_checkbox_unchecked.svg';
import Epic, { ReactComponent as EpicComponent } from 'libs/assets/src/icons/v3/icon_epic.svg';
import GearIcon, { ReactComponent as GearIconComponent } from 'libs/assets/src/icons/v3/icon_gear.svg';
import Insta, { ReactComponent as InstaComponent } from 'libs/assets/src/icons/v3/icon_instagram.svg';
import MoreVertical, { ReactComponent as MoreVerticalComponent } from 'libs/assets/src/icons/v3/icon_options_vertical.svg';
import IconPinLocationLight, {
  ReactComponent as IconPinLocationLightComponent,
} from 'libs/assets/src/icons/v3/icon_pin_location_light.svg';
import icon_sports_esports, { ReactComponent as icon_sports_esportsComponent } from 'libs/assets/src/icons/v3/icon_sports_esports.svg';
import Trophy2, { ReactComponent as Trophy2Component } from 'libs/assets/src/icons/v3/icon_trophy_v2.svg';
import VerifiedIco, { ReactComponent as VerifiedIcoComponent } from 'libs/assets/src/icons/v3/icon_verified.svg';
import WalletNew, { ReactComponent as WalletNewComponent } from 'libs/assets/src/icons/v3/icon_wallet_new.svg';
import Close20, { ReactComponent as Close20Component } from 'libs/assets/src/icons/v3/icons_close_20.svg';
import PinIconSunset, { ReactComponent as PinIconSunsetComponent } from 'libs/assets/src/icons/v3/location_pin_sunset.svg';
import LockSettings, { ReactComponent as LockSettingsComponent } from 'libs/assets/src/icons/v3/lock_settings.svg';
import LogoutNew, { ReactComponent as LogoutNewComponent } from 'libs/assets/src/icons/v3/logout-new.svg';
import MapMarker, { ReactComponent as MapMarkerComponent } from 'libs/assets/src/icons/v3/map_marker.svg';
import moreOptions, { ReactComponent as MoreOptionsComponent } from 'libs/assets/src/icons/v3/more_options.svg';
import MutedIcon, { ReactComponent as MutedIconComponent } from 'libs/assets/src/icons/v3/muted-icon.svg';
import Orders, { ReactComponent as OrdersComponent } from 'libs/assets/src/icons/v3/orders.svg';
import Pace, { ReactComponent as PaceComponent } from 'libs/assets/src/icons/v3/pace.svg';
import PhoneDial, { ReactComponent as PhoneDialComponent } from 'libs/assets/src/icons/v3/phone_dial.svg';
import QRCode, { ReactComponent as QRCodeComponent } from 'libs/assets/src/icons/v3/qrcode.svg';
import QuestionBox, { ReactComponent as QuestionBoxComponent } from 'libs/assets/src/icons/v3/questionBox.svg';
import Robot, { ReactComponent as RobotComponent } from 'libs/assets/src/icons/v3/robot_2.svg';
import ShareNew, { ReactComponent as ShareNewComponent } from 'libs/assets/src/icons/v3/share_new.svg';
import SmartToy, { ReactComponent as SmartToyComponent } from 'libs/assets/src/icons/v3/smart_toy.svg';
import SpotLight, { ReactComponent as SpotLightComponent } from 'libs/assets/src/icons/v3/spot_light.svg';
import Support, { ReactComponent as SupportComponent } from 'libs/assets/src/icons/v3/support.svg';
import TournamentReport, { ReactComponent as TournamentReportComponent } from 'libs/assets/src/icons/v3/tournament_report.svg';
import TournamentShare, { ReactComponent as TournamentShareComponent } from 'libs/assets/src/icons/v3/tournament_share.svg';
import TwoFactorShield, { ReactComponent as TwoFactorShieldComponent } from 'libs/assets/src/icons/v3/two-factor-shield.svg';
import UploadImageSunset, { ReactComponent as UploadImageSunsetComponent } from 'libs/assets/src/icons/v3/upload_image_sunset.svg';
import Verified3, { ReactComponent as Verified3Component } from 'libs/assets/src/icons/v3/verified3.svg';
import VerticalLine, { ReactComponent as VerticalLineComponent } from 'libs/assets/src/icons/v3/vertical_line.svg';
import EmptyState, { ReactComponent as EmptyStateComponent } from 'libs/assets/src/images/common/empty_states.svg';
import NoInternetDark, { ReactComponent as NoInternetDarkComponent } from 'libs/assets/src/images/error_pages/game_over.svg';
import HomeBannerDark, { ReactComponent as HomeBannerDarkComponent } from 'libs/assets/src/images/home/banner_dark.svg';
import HomeBannerLight, { ReactComponent as HomeBannerLightComponent } from 'libs/assets/src/images/home/banner_light.svg';
import Loading, { ReactComponent as LoadingComponent } from 'libs/assets/src/images/login/loading.svg';
import BlockedEmpty, { ReactComponent as BlockedEmptyComponent } from 'libs/assets/src/images/settings/blocked_empty_state.svg';
import WalletNoTransaction, {
  ReactComponent as WalletNoTransactionComponent,
} from 'libs/assets/src/images/settings/wallet_no_transactions.svg';
import MegaMenuPlacholder, { ReactComponent as MegaMenuPlacholderComponent } from 'libs/assets/src/images/shop/mega_menu_placeholder.svg';
import SkullIcon, { ReactComponent as SkullIconComponent } from 'libs/assets/src/images/tournaments/icon_skull.svg';
import TournamentBannerCrossPlatform, {
  ReactComponent as TournamentBannerCrossPlatformComponent,
} from 'libs/assets/src/images/tournaments/platform_cross_platform.svg';
import TournamentBannerNintendo, {
  ReactComponent as TournamentBannerNintendoComponent,
} from 'libs/assets/src/images/tournaments/platform_nintendo.svg';
import TournamentBannerPc, { ReactComponent as TournamentBannerPcComponent } from 'libs/assets/src/images/tournaments/platform_pc.svg';
import TournamentBannerMobile, {
  ReactComponent as TournamentBannerMobileComponent,
} from 'libs/assets/src/images/tournaments/platform_phone.svg';
import TournamentBannerPs5, { ReactComponent as TournamentBannerPs5Component } from 'libs/assets/src/images/tournaments/platform_ps5.svg';
import TournamentBannerXbox, {
  ReactComponent as TournamentBannerXboxComponent,
} from 'libs/assets/src/images/tournaments/platform_xbox.svg';
import { SvgProps } from 'react-native-svg';

//Player Icons
import ProductWishlist, { ReactComponent as ProductWishlistComponent } from '/libs/assets/src/icons/outline/icon_product_wishlist.svg';
import VideoPlay, { ReactComponent as VideoPlayComponent } from '/libs/assets/src/icons/player/icon_live_play.svg';

export enum IconNames {
  activity = 'activity',
  alert = 'alert',
  appleStore = 'appleStore',
  arrow = 'arrow',
  arrowRight = 'arrowRight',
  addPlus = 'addPlus',
  minusReturns = 'minusReturns',
  battlenet = 'battlenet',
  discord = 'discord',
  facebook = 'facebook',
  instagram = 'instagram',
  instagram2 = 'instagram2',
  instagram3 = 'instagram2',
  nintendo = 'nintendo',
  origin = 'origin',
  playstation = 'playstation',
  snapchat = 'snapchat',
  info = 'info',
  steam = 'steam',
  twitch = 'twitch',
  twitter = 'twitter',
  ubisoft = 'ubisoft',
  xbox = 'xbox',
  youtube = 'youtube',
  youtube2 = 'youtube2',
  shareFacebook = 'shareFacebook',
  shareLinkedin = 'shareLinkedin',
  linkedin = 'linkedin',
  shareTwitter = 'shareTwitter',
  addCircle = 'addCircle',
  addUser = 'addUser',
  automatedSeed = 'automatedSeed',
  bell = 'bell',
  box = 'box',
  calculator = 'calculator',
  calendar = 'calendar',
  calendar1 = 'calendar1',
  today = 'today',
  starts = 'starts',
  started = 'started',
  ended = 'ended',
  capslock = 'capslock',
  call = 'call',
  camera = 'camera',
  cart = 'cart',
  champion = 'champion',
  chat = 'chat',
  checkFill = 'checkFill',
  checkInCircle = 'checkInCircle',
  closeCircle = 'closeCircle',
  close1 = 'close1',
  comment = 'comment',
  communityCircle = 'communityCircle',
  copy = 'copy',
  coupon = 'coupon',
  decline = 'decline',
  edit = 'edit',
  exclamation = 'exclamation',
  eye = 'eye',
  flag = 'flag',
  food = 'food',
  home = 'home',
  heartOutline = 'heartOutline',
  heartFill = 'heartFill',
  tournament = 'tournament',
  like = 'like',
  locationSign = 'locationSign',
  lock = 'lock',
  maintenance = 'maintenance',
  manageReferee2 = 'manageReferee2',
  manageRequests = 'manageRequests',
  minus = 'minus',
  minus2 = 'minus2',
  moneyFill = 'moneyFill',
  moon = 'moon',
  menu = 'menu',
  myOrder = 'myOrder',
  participants = 'participants',
  pin = 'pin',
  play = 'play',
  question = 'question',
  radioButton = 'radioButton',
  schedule = 'schedule',
  send = 'send',
  settings = 'settings',
  shop = 'shop',
  star = 'star',
  stc = 'stc',
  talk = 'talk',
  team = 'team',
  time = 'time',
  trash = 'trash',
  trophy = 'trophy',
  tv = 'tv',
  uplock = 'uplock',
  upload = 'upload',
  user = 'user',
  vr = 'vr',
  waitlist = 'waitlist',
  wallet = 'wallet',
  whistle = 'whistle',
  facebookFill = 'facebookFill',
  twitter2 = 'twitter2',
  twitterX = 'twitterX',
  twitterFill = 'twitterFill',
  googleFill = 'googleFill',
  googlePlay = 'googlePlay',
  appleFill = 'appleFill',
  addCart = 'addCart',
  undo = 'undo',
  heartOutline1 = 'heartOutline1',
  addCircleOutline = 'addCircleOutline',
  addImage = 'addImage',
  addUserOutline = 'addUserOutline',
  addUser2Outline = 'addUser2Outline',
  automatedSeedOutline = 'automatedSeedOutline',
  bellOutline = 'bellOutline',
  boxOutline = 'boxOutline',
  calCulatorOutline = 'calCulatorOutline',
  calendarOutline = 'calendarOutline',
  callOutline = 'callOutline',
  cameraOutline = 'cameraOutline',
  cartOutline = 'cartOutline',
  championOutline = 'championOutline',
  chatOutline = 'chatOutline',
  checkInCircleOutline = 'checkInCircleOutline',
  calendarV3 = 'calendarV3',
  checkMarkFill = 'checkMarkFill',
  checkCircle = 'checkCircle',
  closeCircleOutline = 'closeCircleOutline',
  commentOutline = 'commentOutline',
  communityCircleOutline = 'communityCircleOutline',
  copyOutline = 'copyOutline',
  couponOutline = 'couponOutline',
  declineOutline = 'declineOutline',
  downloadOutline = 'downloadOutline',
  editOutline = 'editOutline',
  endCallOutline = 'endCallOutline',
  exclamationOutline = 'exclamationOutline',
  eyeOutline = 'eyeOutline',
  eyeClosedOutline = 'eyeClosedOutline',
  filterOutline = 'filterOutline',
  flagOutline = 'flagOutline',
  foodOutline = 'foodOutline',
  homeOutline = 'homeOutline',
  imageOutline = 'imageOutline',
  likeOutline = 'likeOutline',
  locationSignOutline = 'locationSignOutline',
  lockOutline = 'lockOutline',
  insta = 'insta',
  maintenanceOutline = 'maintenanceOutline',
  manageReferee2Outline = 'manageReferee2Outline',
  manageRefereesOutline = 'manageRefereesOutline',
  manageRequestsOutline = 'manageRequestsOutline',
  minusOutline = 'minusOutline',
  microphoneOutline = 'microphoneOutline',
  moneyOutline = 'moneyOutline',
  notification = 'notification',
  myOrderOutline = 'myOrderOutline',
  participantsOutline = 'participantsOutline',
  playOutline = 'playOutline',
  playSymbolOutline = 'playSymbolOutline',
  videoPlaySymbolOutline = 'videoPlaySymbolOutline',
  videoPauseSymbolOutline = 'videoPauseSymbolOutline',
  screenBrightness = 'screenBrightness',
  fullscreenSDK = 'fullscreenSDK',
  exitscreenSDK = 'exitscreenSDK',
  lockScreen = 'lockScreen',
  unLockScreen = 'unLockScreen',
  leftArrow = 'leftArrow',
  qualityIcon = 'qualityIcon',
  pinOutline = 'pinOutline',
  questionOutline = 'questionOutline',
  radioButtonOutline = 'radioButtonOutline',
  scheduleOutline = 'scheduleOutline',
  sendOutline = 'sendOutline',
  settingsOutline = 'settingsOutline',
  shopOutline = 'shopOutline',
  speakerOutline = `speakerOutline`,
  starOutline = 'starOutline',
  stcPlay = 'stcPlay',
  stcPlay2 = 'stcPlay2',
  teamOutline = 'teamOutline',
  timeOutline = 'timeOutline',
  trashOutline = 'trashOutline',
  tVOutline = 'tVOutline',
  filterList = 'filterList',
  unfollowOutline = 'unfollowOutline',
  uplockOutline = 'uplockOutline',
  uploadOutline = 'uploadOutline',
  userOutline = 'userOutline',
  vectorOutline = 'vectorOutline',
  vROutline = 'vROutline',
  waitlistOutline = 'waitlistOutline',
  walletOutline = 'walletOutline',
  whistleOutline = 'whistleOutline',
  qRCodeScanner = 'qRCodeScanner',
  tabby = 'tabby',
  media = 'media',
  polls = 'polls',
  bellWhiteOutline = 'bellWhiteOutline',
  addBold = 'addBold',
  analyticsBold = 'analyticsBold',
  attachmentBold = 'attachmentBold',
  blockBold = 'blockBold',
  checkInBold = 'checkInBold',
  closeBold = 'closeBold',
  logoutBold = 'logoutBold',
  minusBold = 'minusBold',
  menuBold = 'menuBold',
  openBold = 'openBold',
  optionsBold = 'optionsBold',
  repostBold = 'repostBold',
  searchBold = 'searchBold',
  shareBold = 'shareBold',
  addOutline = 'addOutline',
  analyticsOutline = 'analyticsOutline',
  attachmentOutline = 'attachmentOutline',
  blockRegular = 'blockRegular',
  checkInRegular = 'checkInRegular',
  bitsApproved = 'bitsApproved',
  closeOutline = 'closeOutline',
  logoutOutline = 'logoutOutline',
  list = 'list',
  grid = 'grid',
  minusRegular = 'minusRegular',
  menuRegular = 'menuRegular',
  openregular = 'openregular',
  optionsRegular = 'optionsRegular',
  repostOutline = 'repostOutline',
  searchOutline = 'searchOutline',
  shareOutline = 'shareOutline',
  warningRegular = 'warningRegular',
  addPostOutline = 'addPostOutline',
  linkOutline = 'linkOutline',
  localATM = 'localATM',
  newBellOutline = 'newBellOutline',
  newChatOutline = 'newChatOutline',
  newGameOutline = 'newGameOutline',
  newTeamOutline = 'newTeamOutline',
  pictureOutline = 'pictureOutline',
  preferencesOutline = 'preferencesOutline',
  addPostTwoColor = 'addPostTwoColor',
  linkTwoColor = 'linkTwoColor',
  newChatTwoColor = 'newChatTwoColor',
  newGameTwoColor = 'newGameTwoColor',
  newTeamTwoColor = 'newTeamTwoColor',
  pictureTwoColor = 'pictureTwoColor',
  preferencesTwoColor = 'preferencesTwoColor',
  microphoneMute = 'microphoneMute',
  speakerMute1 = 'speakerMute1',
  speakerMuteWhite = 'speakerMuteWhite',
  videoDisabled = 'videoDisabled',
  checkOutline = 'checkOutline',
  unchecked = 'unchecked',
  wifiOff = 'wifiOff',
  microphoneFill = 'microphoneFill',
  videoFill = 'videoFill',
  speakerMute = 'speakerMute',
  check = 'check',
  chevron = 'chevron',
  gallery = 'gallery',
  emailVerification = 'emailVerification',
  emailVerified = 'emailVerified',
  sendMessage = 'sendMessage',
  search = 'search',
  search1 = 'search1',
  shoppingCart = 'shoppingCart',
  shoppingCart1 = 'shoppingCart1',
  shipping = 'shipping',
  moreHorizon = 'moreHorizon',
  moreVertical = 'moreVertical',
  language = 'language',
  darkMode = 'darkMode',
  hamburgerMenu = 'hamburgerMenu',
  promoted = 'promoted',
  close = 'close',
  chevronRight = 'chevronRight',
  wifiOn = 'wifiOn',
  verified1 = 'verified1',
  verified2 = 'verified2',
  fullScreen = 'fullScreen',
  minimize = 'minimize',
  verified = 'verified',
  verifiedIco = 'verifiedIco',
  menu_button = 'menu_button',
  close_xbutton = 'close_xbutton',
  users = 'users',
  pletter = 'pletter',
  lletter = 'lletter',
  aletter = 'aletter',
  yletter = 'yletter',
  currency = 'currency',
  country = 'country',
  countryFlag = 'countryFlag',
  payments = 'payments',
  deliveryTruck = 'deliveryTruck',
  taskSuccess = 'taskSuccess',
  deleteOutline = 'deleteOutline',
  editOutline2 = 'editOutline2',
  checkboxChecked = 'checkboxChecked',
  checkboxUnchecked = 'checkboxUnchecked',
  cartDelete = 'cartDelete',
  emptyAvatar = 'emptyAvatar',
  iconCircleFilled = 'iconCircleFilled',
  iconCircleGray = 'iconCircleGray',
  add = 'add',
  iconCircleGrayChecked = 'iconCircleGrayChecked',
  iconPinLocationLight = 'iconPinLocationLight',
  iconExpandCircleRight = 'iconExpandCircleRight',
  iconRedeem = 'iconRedeem',
  iconcopy = 'iconcopy',
  iconcirclefilledv2 = 'iconcirclefilledv2',
  trophy1 = 'trophy',
  iconErrorFill = 'iconErrorFill',
  verified3 = 'verified3',
  qrcode = 'qrcode',
  orders = 'orders',
  support = 'support',
  logoutNew = 'logoutNew',
  gearIcon = 'gearIcon',
  walletNew = 'walletNew',
  iconsportsesports = 'iconsportsesports',
  skullIcon = 'skullIcon',
  swords = 'swords',
  attachment1 = 'attachment1',
  help = 'help',
  attach_file = 'attach_file',
  icon_1 = 'icon_1',
  icon_2 = 'icon_2',
  icon_3 = 'icon_3',
  icon_4 = 'icon_4',
  arrowUp = 'arrowUp',
  arrowDown = 'arrowDown',
  moreOptions = 'moreOptions',
  tournamentShare = 'tournamentShare',
  tournamentReport = 'tournamentReport',
  iconcircledonut = 'iconcircledonut',
  iconlinefilled = 'iconlinefilled',
  iconlinedashed = 'iconlinedashed',
  iconSkullBaseFill = 'iconSkullBaseFill',
  radioButtonDark = 'radioButtonDark',
  radioButtonLight = 'radioButtonLight',
  thumbOutline = 'thumbOutline',
  reorder = 'reorder',
  twitchGray = 'twitchGray',
  twitterGray = 'twitterGray',
  instagramGray = 'instagramGray',
  snapchatGray = 'snapchatGray',
  playstationGray = 'playstationGray',
  xboxGray = 'xboxGray',
  steamGray = 'steamGray',
  battlenetGray = 'battlenetGray',
  originGray = 'originGray',
  epicGray = 'epicGray',
  epic = 'epic',
  ubisoftGray = 'ubisoftGray',
  nintendoGray = 'nintendoGray',
  discordGray = 'discordGray',
  smartToy = 'smartToy',
  genderMaleFemale = 'genderMaleFemale',
  phoneDial = 'phoneDial',
  email = 'email',
  approved = 'approved',
  encrypted = 'encrypted',
  creditCard = 'creditCard',
  homeEdit = 'homeEdit',
  mapMarker = 'mapMarker',
  cash = 'cash',
  bellSettings = 'bellSettings',
  lockSettings = 'lockSettings',
  fileLock = 'fileLock',
  addAccount = 'addAccount',
  pace = 'pace',
  verticalLine = 'verticalLine',
  questionBox = 'questionBox',
  twoFactorIcon = 'twoFactorIcon',
  twoFactorIconv2 = 'twoFactorIconv2',
  playerSelectEmpty = 'playerSelectEmpty',
  twoFactorShield = 'twoFactorShield',
  avatar = 'avatar',
  robot = 'robot',
  trophy2 = 'trophy2',
  spotLight = 'spotLight',
  crown = 'crown',
  emailChecked = 'emailChecked',
  pinIconSunset = 'pinIconSunset',
  emailContactUs = 'emailContactUs',
  uploadImageSunset = 'uploadImageSunset',
  contactUsSuccess = 'contactUsSuccess',
  icon_header_chat = 'icon_header_chat',
  icon_chat_new = 'icon_chat_new',
  icon_chat_maximize = 'icon_chat_maximize',
  icon_chat_circle = 'icon_chat_circle',
  icon_toast_cross_error = 'icon_toast_cross_error',
  error = 'error',
  chat_process_outline = 'chat_process_outline',
  icon_toast_exclamation = 'icon_toast_exclamation',
  icon_toast_checkmark = 'icon_toast_checkmark',
  group_icon = 'group_icon',
  target_icon = 'target_icon',
  circleoutine_icon = 'circleoutine_icon',
  icon_chat_minimize = 'icon_chat_minimize',
  icon_chat_group = 'icon_chat_group',
  muted_icon = 'muted_icon',
  blocked_account = 'blocked_account',
  icon_chat_minimize_underline = 'icon_chat_minimize_underline',
  download = 'download',
  sentCheck = 'sentCheck',
  radioCheck = 'radioCheck',
  radioUncheck = 'radioUncheck',
  icon_chat_chat_normal_check = 'icon_chat_chat_normal_check',
  warningOutlined = 'warningOutlined',
  icon_close_20 = 'icon_close_20',
  share_new = 'share_new',
  noInternetLight = 'noInternetLight',
  noInternetDark = 'noInternetDark',
  notFoundLight = 'notFoundLight',
  notFoundDark = 'notFoundDark',
  internalServerErrorLight = 'internalServerErrorLight',
  internalServerErrorDark = 'internalServerErrorDark',
  serverDownLight = 'serverDownLight',
  serverDownDark = 'serverDownDark',
  serverDownMobile = 'serverDownMobile',
  bellNotification = 'bellNotification',
  dangerousIcon = 'dangerousIcon',
  alertRed = 'alertRed',
  alertGreen = 'alertGreen',
  alertYellow = 'alertYellow',
  alertAddCheck = 'alertAddCheck',
  alertFlame = 'alertFlame',
  checkCircleOutline = 'checkCircleOutline',
  homeBannerBackground = 'homeBannerBackground',
  homeBannerBackgroundMobile = 'homeBannerBackgroundMobile',
  tournamentBannerPs5 = 'tournamentBannerPs5',
  tournamentBannerXbox = 'tournamentBannerXbox',
  tournamentBannerNintendo = 'tournamentBannerNintendo',
  tournamentBannerPc = 'tournamentBannerPc',
  tournamentBannerMobile = 'tournamentBannerMobile',
  tournamentBannerCrossPlatform = 'tournamentBannerCrossPlatform',
  loading = 'loading',
  emptyState = 'emptyState',
  blockedEmpty = 'blockedEmpty',
  walletNoTransaction = 'walletNoTransaction',
  homeBannerLight = 'homeBannerLight',
  homeBannerDark = 'homeBannerDark',
  chatIcon = 'chatIcon',
  megaMenuPlaceholder = 'megaMenuPlaceholder',
  shopEmpty = 'shopEmpty',
  bitViews = 'bitViews',
  processing = 'processing',
  userViewCount = 'userViewCount',
  videoPlay = 'videoPlay',
  predict = 'predict',
  phoneEmpty = 'phoneEmpty',
  rejected = 'rejected',
  fire = 'fire',
  productWishlist = 'productWishlist',
}
export interface IconProps extends SvgProps {
  name: IconNames;
  iconClasses?: string;
  width?: number;
  height?: number;
  fill?: string;
  theme?: string;
  color?: string;
  platform: 'web' | 'mobile';
}

let iconsObj: {
  readonly [key in keyof typeof IconNames]: {
    mobile: string;
    web: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
  };
};

iconsObj = {
  checkMarkFill: {
    mobile: CheckMarkFill,
    web: CheckMarkFillComponent,
  },
  addPlus: {
    mobile: AddPlus,
    web: AddPlusComponent,
  },
  minusReturns: {
    mobile: MinusReturns,
    web: MinusReturnsComponent,
  },
  activity: {
    mobile: Activity,
    web: ActivityComponent,
  },
  alert: {
    mobile: Alert,
    web: AlertComponent,
  },
  appleStore: {
    mobile: AppleStore,
    web: AppleStoreComponent,
  },
  checkCircle: {
    mobile: CheckCircle,
    web: CheckCircleComponent,
  },
  arrow: {
    mobile: Arrow,
    web: ArrowComponent,
  },
  arrowRight: {
    mobile: ArrowRight,
    web: ArrowRightComponent,
  },
  battlenet: {
    mobile: BattleNet,
    web: BattleNetComponent,
  },

  capslock: {
    mobile: Capslock,
    web: CapslockComponent,
  },
  discord: {
    mobile: Discord,
    web: DiscordComponent,
  },

  facebook: {
    mobile: Facebook,
    web: FacebookComponent,
  },
  instagram: {
    mobile: Instagram,
    web: InstagramComponent,
  },
  instagram2: {
    mobile: Instagram2,
    web: Instagram2Component,
  },
  instagram3: {
    mobile: Instagram3,
    web: Instagram3Component,
  },
  linkedin: {
    mobile: LinkedIn,
    web: LinkedInComponent,
  },
  nintendo: {
    mobile: Nintendo,
    web: NintendoComponent,
  },
  notification: {
    mobile: Notification,
    web: NotificationComponent,
  },
  origin: {
    mobile: Origin,
    web: OriginComponent,
  },
  heartOutline1: {
    mobile: HeartOutline1,
    web: HeartOutline1Component,
  },
  playstation: {
    mobile: PlayStation,
    web: PlayStationComponent,
  },
  snapchat: {
    mobile: Snapchat,
    web: SnapchatComponent,
  },
  steam: {
    mobile: Steam,
    web: SteamComponent,
  },

  twitch: {
    mobile: Twitch,
    web: TwitchComponent,
  },

  twitter: {
    mobile: Twitter,
    web: TwitterComponent,
  },
  calendarV3: {
    mobile: CalendarV3,
    web: CalendarV3Component,
  },
  trophy: {
    mobile: Trophy,
    web: TrophyComponent,
  },
  ubisoft: {
    mobile: Ubisoft,
    web: UbisoftComponent,
  },
  xbox: {
    mobile: Xbox,
    web: XboxComponent,
  },
  youtube: {
    mobile: YouTube,
    web: YouTubeComponent,
  },
  youtube2: {
    mobile: YouTube2,
    web: YouTube2Component,
  },
  shareFacebook: {
    mobile: ShareFacebook,
    web: ShareFacebookComponent,
  },
  shareLinkedin: {
    mobile: ShareLinkedin,
    web: ShareLinkedinComponent,
  },
  shareTwitter: {
    mobile: ShareTwitter,
    web: ShareTwitterComponent,
  },
  stc: {
    mobile: STC,
    web: STCComponent,
  },
  addCircle: {
    mobile: AddCircleFill,
    web: AddCircleFillComponent,
  },
  addUser: {
    mobile: AddUserFill,
    web: AddUserFillComponent,
  },
  automatedSeed: {
    mobile: AutomatedSeedFill,
    web: AutomatedSeedFillComponent,
  },
  bell: {
    mobile: BellFill,
    web: BellFillComponent,
  },
  box: {
    mobile: BoxFill,
    web: BoxFillComponent,
  },
  calculator: {
    mobile: CalculatorFill,
    web: CalculatorFillComponent,
  },
  calendar: {
    mobile: CalendarFill,
    web: CalendarFillComponent,
  },
  calendar1: {
    mobile: Calendar1,
    web: Calendar1Component,
  },
  insta: {
    mobile: Insta,
    web: InstaComponent,
  },
  today: {
    mobile: Today,
    web: TodayComponent,
  },
  starts: {
    mobile: Starts,
    web: StartsComponent,
  },
  started: {
    mobile: Started,
    web: StartedComponent,
  },
  ended: {
    mobile: Ended,
    web: EndedComponent,
  },
  call: {
    mobile: CallFill,
    web: CallFillComponent,
  },
  camera: {
    mobile: CameraFill,
    web: CameraFillComponent,
  },

  cart: {
    mobile: CartFill,
    web: CartFillComponent,
  },

  champion: {
    mobile: ChampionFill,
    web: ChampionFillComponent,
  },

  chat: {
    mobile: ChatFill,
    web: ChatFillComponent,
  },
  checkInCircle: {
    mobile: CheckInCircleFill,
    web: CheckInCircleFillComponent,
  },
  closeCircle: {
    mobile: CloseCircleFill,
    web: CloseCircleFillComponent,
  },
  close1: {
    mobile: Close1,
    web: Close1Component,
  },
  comment: {
    mobile: CommentFill,
    web: CommentFillComponent,
  },
  communityCircle: {
    mobile: CommunityCircleFill,
    web: CommunityCircleFillComponent,
  },
  copy: {
    mobile: CopyFill,
    web: CopyFillComponent,
  },
  coupon: {
    mobile: CouponFill,
    web: CouponFillComponent,
  },
  decline: {
    mobile: DeclineFill,
    web: DeclineFillComponent,
  },
  edit: {
    mobile: EditFill,
    web: EditFillComponent,
  },
  exclamation: {
    mobile: ExclamationFill,
    web: ExclamationFillComponent,
  },
  eye: {
    mobile: EyeFill,
    web: EyeFillComponent,
  },
  localATM: {
    mobile: LocalATM,
    web: LocalATMComponent,
  },
  flag: {
    mobile: FlagFill,
    web: FlagFillComponent,
  },
  food: {
    mobile: FoodFill,
    web: FoodFillComponent,
  },
  home: {
    mobile: HomeFill,
    web: HomeFillComponent,
  },
  heartOutline: {
    mobile: HeartOutline,
    web: HeartOutlineComponent,
  },
  like: {
    mobile: LikeFill,
    web: LikeFillComponent,
  },
  locationSign: {
    mobile: LocationSignFill,
    web: LocationSignFillComponent,
  },
  lock: {
    mobile: LockFill,
    web: LockFillComponent,
  },
  maintenance: {
    mobile: MaintenanceFill,
    web: MaintenanceFillComponent,
  },
  manageReferee2: {
    mobile: ManageReferee2Fill,
    web: ManageReferee2FillComponent,
  },
  manageRequests: {
    mobile: ManageRequestsFill,
    web: ManageRequestsFillComponent,
  },
  minus: {
    mobile: MinusFill,
    web: MinusFillComponent,
  },
  moneyFill: {
    mobile: MoneyFill,
    web: MoneyFillComponent,
  },
  moon: {
    mobile: Moon,
    web: MoonComponent,
  },
  menu: {
    mobile: Menu,
    web: MenuComponent,
  },
  myOrder: {
    mobile: MyOrderFill,
    web: MyOrderFillComponent,
  },
  participants: {
    mobile: ParticipantsFill,
    web: ParticipantsFillComponent,
  },
  pin: {
    mobile: PinFill,
    web: PinFillComponent,
  },
  play: {
    mobile: PlayFill,
    web: PlayFillComponent,
  },
  promoted: {
    mobile: Promoted,
    web: PromotedComponent,
  },
  question: {
    mobile: QuestionFill,
    web: QuestionFillComponent,
  },
  radioButton: {
    mobile: RadioButtonFill,
    web: RadioButtonFillComponent,
  },
  schedule: {
    mobile: ScheduleFill,
    web: ScheduleFillComponent,
  },
  send: {
    mobile: SendFill,
    web: SendFillComponent,
  },
  settings: {
    mobile: SettingsFill,
    web: SettingsFillComponent,
  },
  shop: {
    mobile: ShopFill,
    web: ShopFillComponent,
  },
  star: {
    mobile: StarFill,
    web: StarFillComponent,
  },
  heartFill: {
    mobile: HeartFill,
    web: HeartFillComponent,
  },

  tournament: {
    mobile: tournament,
    web: tournamentComponent,
  },
  talk: {
    mobile: Talk,
    web: TalkComponent,
  },
  team: {
    mobile: TeamFill,
    web: TeamFillComponent,
  },
  time: {
    mobile: TimeFill,
    web: TimeFillComponent,
  },
  trash: {
    mobile: TrashFill,
    web: TrashFillComponent,
  },
  tv: {
    mobile: TVFill,
    web: TVFillComponent,
  },
  uplock: {
    mobile: UplockFill,
    web: UplockFillComponent,
  },
  upload: {
    mobile: UploadFill,
    web: UploadFillComponent,
  },
  user: {
    mobile: UserFill,
    web: UserFillComponent,
  },
  vr: {
    mobile: VRFill,
    web: VRFillComponent,
  },
  waitlist: {
    mobile: WaitlistFill,
    web: WaitlistFillComponent,
  },
  wallet: {
    mobile: WalletFill,
    web: WalletFillComponent,
  },
  whistle: {
    mobile: WhistleFill,
    web: WhistleFillComponent,
  },
  facebookFill: {
    mobile: FacebookFill,
    web: FacebookFillComponent,
  },
  twitterFill: {
    mobile: TwitterFill,
    web: TwitterFillComponent,
  },
  twitter2: {
    mobile: Twitter2,
    web: Twitter2Component,
  },
  twitterX: {
    mobile: TwitterX,
    web: TwitterXComponent,
  },
  googleFill: {
    mobile: GoogleFill,
    web: GoogleFillComponent,
  },

  googlePlay: {
    mobile: GooglePlay,
    web: GooglePlayComponent,
  },

  appleFill: {
    mobile: AppleFill,
    web: AppleFillComponent,
  },
  addCart: {
    mobile: AddCartOutline,
    web: AddCartOutlineComponent,
  },
  bitViews: {
    mobile: ViewsOutline,
    web: ViewsOutlineComponent,
  },
  addCircleOutline: {
    mobile: AddCircleOutline,
    web: AddCircleOutlineComponent,
  },
  addImage: {
    mobile: AddImageOutline,
    web: AddImageOutlineComponent,
  },
  addUserOutline: {
    mobile: AddUserOutline,
    web: AddUserOutlineComponent,
  },
  addUser2Outline: {
    mobile: AddUser2Outline,
    web: AddUser2OutlineComponent,
  },
  tabby: {
    mobile: Tabby,
    web: TabbyComponent,
  },
  automatedSeedOutline: {
    mobile: AutomatedSeedOutline,
    web: AutomatedSeedOutlineComponent,
  },
  bellOutline: {
    mobile: BellOutline,
    web: BellOutlineComponent,
  },
  boxOutline: {
    mobile: BoxOutline,
    web: BoxOutlineComponent,
  },
  calCulatorOutline: {
    mobile: CalCulatorOutline,
    web: CalCulatorOutlineComponent,
  },
  calendarOutline: {
    mobile: CalendarOutline,
    web: CalendarOutlineComponent,
  },
  callOutline: {
    mobile: CallOutline,
    web: CallOutlineComponent,
  },
  cameraOutline: {
    mobile: CameraOutline,
    web: CameraOutlineComponent,
  },
  cartOutline: {
    mobile: CartOutline,
    web: CartOutlineComponent,
  },
  championOutline: {
    mobile: ChampionOutline,
    web: ChampionOutlineComponent,
  },
  chatOutline: {
    mobile: ChatOutline,
    web: ChatOutlineComponent,
  },
  checkFill: {
    mobile: CheckFill,
    web: CheckFillComponent,
  },
  checkInCircleOutline: {
    mobile: CheckInCircleOutline,
    web: CheckInCircleOutlineComponent,
  },
  closeCircleOutline: {
    mobile: CloseCircleOutline,
    web: CloseCircleOutlineComponent,
  },
  commentOutline: {
    mobile: CommentOutline,
    web: CommentOutlineComponent,
  },
  communityCircleOutline: {
    mobile: CommunityCircleOutline,
    web: CommunityCircleOutlineComponent,
  },
  copyOutline: {
    mobile: CopyOutline,
    web: CopyOutlineComponent,
  },
  couponOutline: {
    mobile: CouponOutline,
    web: CouponOutlineComponent,
  },
  declineOutline: {
    mobile: DeclineOutline,
    web: DeclineOutlineComponent,
  },
  downloadOutline: {
    mobile: DownloadOutline,
    web: DownloadOutlineComponent,
  },
  editOutline: {
    mobile: EditOutline,
    web: EditOutlineComponent,
  },
  endCallOutline: {
    mobile: EndCallOutline,
    web: EndCallOutlineComponent,
  },
  exclamationOutline: {
    mobile: ExclamationOutline,
    web: ExclamationOutlineComponent,
  },
  eyeOutline: {
    mobile: EyeOutline,
    web: EyeOutlineComponent,
  },
  eyeClosedOutline: {
    mobile: EyeClosedOutline,
    web: EyeClosedOutlineComponent,
  },
  filterOutline: {
    mobile: FilterOutline,
    web: FilterOutlineComponent,
  },
  robot: {
    mobile: Robot,
    web: RobotComponent,
  },
  flagOutline: {
    mobile: FlagOutline,
    web: FlagOutlineComponent,
  },
  foodOutline: {
    mobile: FoodOutline,
    web: FoodOutlineComponent,
  },
  homeOutline: {
    mobile: HomeOutline,
    web: HomeOutlineComponent,
  },
  imageOutline: {
    mobile: ImageOutline,
    web: ImageOutlineComponent,
  },
  likeOutline: {
    mobile: LikeOutline,
    web: LikeOutlineComponent,
  },
  locationSignOutline: {
    mobile: LocationSignOutline,
    web: LocationSignOutlineComponent,
  },
  lockOutline: {
    mobile: LockOutline,
    web: LockOutlineComponent,
  },
  maintenanceOutline: {
    mobile: MaintenanceOutline,
    web: MaintenanceOutlineComponent,
  },
  manageReferee2Outline: {
    mobile: ManageReferee2Outline,
    web: ManageReferee2OutlineComponent,
  },
  manageRefereesOutline: {
    mobile: ManageRefereesOutline,
    web: ManageRefereesOutlineComponent,
  },
  manageRequestsOutline: {
    mobile: ManageRequestsOutline,
    web: ManageRequestsOutlineComponent,
  },
  minusOutline: {
    mobile: MinusOutline,
    web: MinusOutlineComponent,
  },
  microphoneOutline: {
    mobile: MicrophoneOutline,
    web: MicrophoneOutlineComponent,
  },
  moneyOutline: {
    mobile: MoneyOutline,
    web: MoneyOutlineComponent,
  },
  myOrderOutline: {
    mobile: MyOrderOutline,
    web: MyOrderOutlineComponent,
  },
  participantsOutline: {
    mobile: ParticipantsOutline,
    web: ParticipantsOutlineComponent,
  },
  playOutline: {
    mobile: PlayOutline,
    web: PlayOutlineComponent,
  },
  playSymbolOutline: {
    mobile: PlaySymbolOutline,
    web: PlaySymbolOutlineComponent,
  },
  videoPlaySymbolOutline: {
    mobile: VideoPlaySymbolOutline,
    web: VideoPlaySymbolOutlineComponent,
  },
  videoPauseSymbolOutline: {
    mobile: VideoPauseSymbolOutline,
    web: VideoPauseSymbolOutlineComponent,
  },
  screenBrightness: {
    mobile: ScreenBrightness,
    web: ScreenBrightnessComponent,
  },
  fullscreenSDK: {
    mobile: SDKFullScreen,
    web: SDKFullScreenComponent,
  },
  exitscreenSDK: {
    mobile: SDKExitScreen,
    web: SDKExitScreenComponent,
  },
  lockScreen: {
    mobile: LockScreen,
    web: LockScreenComponent,
  },
  unLockScreen: {
    mobile: UnLockScreen,
    web: UnLockScreenComponent,
  },
  leftArrow: {
    mobile: LeftArrow,
    web: LeftArrowComponent,
  },
  qualityIcon: {
    mobile: QualityIcon,
    web: QualityIconComponent,
  },
  pinOutline: {
    mobile: PinOutline,
    web: PinOutlineComponent,
  },
  questionOutline: {
    mobile: QuestionOutline,
    web: QuestionOutlineComponent,
  },
  radioButtonOutline: {
    mobile: RadioButtonOutline,
    web: RadioButtonOutlineComponent,
  },
  stcPlay: {
    mobile: StcPlay,
    web: StcPlayComponent,
  },
  stcPlay2: {
    mobile: StcPlay2,
    web: StcPlayComponent2,
  },
  scheduleOutline: {
    mobile: ScheduleOutline,
    web: ScheduleOutlineComponent,
  },
  sendOutline: {
    mobile: SendOutline,
    web: SendOutlineComponent,
  },
  settingsOutline: {
    mobile: SettingsOutline,
    web: SettingsOutlineComponent,
  },
  shopOutline: {
    mobile: ShopOutline,
    web: ShopOutlineComponent,
  },
  shipping: {
    mobile: Shipping,
    web: ShippingComponent,
  },
  speakerOutline: {
    mobile: SpeakerOutline,
    web: SpeakerOutlineComponent,
  },
  starOutline: {
    mobile: StarOutline,
    web: StarOutlineComponent,
  },
  teamOutline: {
    mobile: TeamOutline,
    web: TeamOutlineComponent,
  },
  timeOutline: {
    mobile: TimeOutline,
    web: TimeOutlineComponent,
  },
  trashOutline: {
    mobile: TrashOutline,
    web: TrashOutlineComponent,
  },
  tVOutline: {
    mobile: TVOutline,
    web: TVOutlineComponent,
  },
  unfollowOutline: {
    mobile: UnfollowOutline,
    web: UnfollowOutlineComponent,
  },
  uplockOutline: {
    mobile: UplockOutline,
    web: UplockOutlineComponent,
  },
  uploadOutline: {
    mobile: UploadOutline,
    web: UploadOutlineComponent,
  },
  userOutline: {
    mobile: UserOutline,
    web: UserOutlineComponent,
  },
  vectorOutline: {
    mobile: VectorOutline,
    web: VectorOutlineComponent,
  },
  vROutline: {
    mobile: VROutline,
    web: VROutlineComponent,
  },
  waitlistOutline: {
    mobile: WaitlistOutline,
    web: WaitlistOutlineComponent,
  },
  walletOutline: {
    mobile: WalletOutline,
    web: WalletOutlineComponent,
  },
  whistleOutline: {
    mobile: WhistleOutline,
    web: WhistleOutlineComponent,
  },
  qRCodeScanner: {
    mobile: QRCodeScanner,
    web: QRCodeScannerComponent,
  },
  media: {
    mobile: Media,
    web: MediaComponent,
  },
  polls: {
    mobile: Polls,
    web: PollsComponent,
  },
  bellWhiteOutline: {
    mobile: BellWhiteOutline,
    web: BellWhiteOutlineComponent,
  },
  addBold: {
    mobile: AddBold,
    web: AddBoldComponent,
  },
  analyticsBold: {
    mobile: AnalyticsBold,
    web: AnalyticsBoldComponent,
  },
  attachmentBold: {
    mobile: AttachmentBold,
    web: AttachmentBoldComponent,
  },
  blockBold: {
    mobile: BlockBold,
    web: BlockBoldComponent,
  },
  checkInBold: {
    mobile: CheckInBold,
    web: CheckInBoldComponent,
  },
  closeBold: {
    mobile: CloseBold,
    web: CloseBoldComponent,
  },
  logoutBold: {
    mobile: LogoutBold,
    web: LogoutBoldComponent,
  },
  minusBold: {
    mobile: MinusBold,
    web: MinusBoldComponent,
  },
  menuBold: {
    mobile: MenuBold,
    web: MenuBoldComponent,
  },
  openBold: {
    mobile: OpenBold,
    web: OpenBoldComponent,
  },
  optionsBold: {
    mobile: OptionsBold,
    web: OptionsBoldComponent,
  },
  repostBold: {
    mobile: RepostBold,
    web: RepostBoldComponent,
  },
  searchBold: {
    mobile: SearchBold,
    web: SearchBoldComponent,
  },
  search1: {
    mobile: Search1,
    web: Search1Component,
  },
  shareBold: {
    mobile: ShareBold,
    web: ShareBoldComponent,
  },
  addOutline: {
    mobile: AddOutline,
    web: AddOutlineComponent,
  },
  analyticsOutline: {
    mobile: AnalyticsOutline,
    web: AnalyticsOutlineComponent,
  },
  attachmentOutline: {
    mobile: AttachmentOutline,
    web: AttachmentOutlineComponent,
  },
  blockRegular: {
    mobile: BlockRegular,
    web: BlockRegularComponent,
  },
  checkInRegular: {
    mobile: CheckInRegular,
    web: CheckInRegularComponent,
  },
  bitsApproved: {
    mobile: BitsApproved,
    web: BitsApprovedComponent,
  },
  closeOutline: {
    mobile: CloseOutline,
    web: CloseOutlineComponent,
  },
  logoutOutline: {
    mobile: LogoutOutline,
    web: LogoutOutlineComponent,
  },
  minusRegular: {
    mobile: MinusRegular,
    web: MinusRegularComponent,
  },
  minus2: {
    mobile: MinusRegular2,
    web: MinusRegular2Component,
  },
  menuRegular: {
    mobile: MenuRegular,
    web: MenuRegularComponent,
  },
  openregular: {
    mobile: Openregular,
    web: OpenregularComponent,
  },
  optionsRegular: {
    mobile: OptionsRegular,
    web: OptionsRegularComponent,
  },
  repostOutline: {
    mobile: RepostOutline,
    web: RepostOutlineComponent,
  },
  searchOutline: {
    mobile: SearchOutline,
    web: SearchOutlineComponent,
  },
  shareOutline: {
    mobile: ShareOutline,
    web: ShareOutlineComponent,
  },
  warningRegular: {
    mobile: WarningRegular,
    web: WarningRegularComponent,
  },
  addPostOutline: {
    mobile: AddPostOutline,
    web: AddPostOutlineComponent,
  },
  linkOutline: {
    mobile: LinkOutline,
    web: LinkOutlineComponent,
  },
  newBellOutline: {
    mobile: NewBellOutline,
    web: NewBellOutlineComponent,
  },
  newChatOutline: {
    mobile: NewChatOutline,
    web: NewChatOutlineComponent,
  },
  newGameOutline: {
    mobile: NewGameOutline,
    web: NewGameOutlineComponent,
  },
  newTeamOutline: {
    mobile: NewTeamOutline,
    web: NewTeamOutlineComponent,
  },
  grid: {
    mobile: Grid,
    web: GridComponent,
  },
  list: {
    mobile: List,
    web: ListComponent,
  },
  filterList: {
    mobile: FilterList,
    web: FilterListComponent,
  },
  pictureOutline: {
    mobile: PictureOutline,
    web: PictureOutlineComponent,
  },
  preferencesOutline: {
    mobile: PreferencesOutline,
    web: PreferencesOutlineComponent,
  },
  addPostTwoColor: {
    mobile: AddPostTwoColor,
    web: AddPostTwoColorComponent,
  },
  linkTwoColor: {
    mobile: LinkTwoColor,
    web: LinkTwoColorComponent,
  },
  newChatTwoColor: {
    mobile: NewChatTwoColor,
    web: NewChatTwoColorComponent,
  },
  newGameTwoColor: {
    mobile: NewGameTwoColor,
    web: NewGameTwoColorComponent,
  },
  newTeamTwoColor: {
    mobile: NewTeamTwoColor,
    web: NewTeamTwoColorComponent,
  },
  pictureTwoColor: {
    mobile: PictureTwoColor,
    web: PictureTwoColorComponent,
  },
  preferencesTwoColor: {
    mobile: PreferencesTwoColor,
    web: PreferencesTwoColorComponent,
  },
  microphoneMute: {
    mobile: MicrophoneMute,
    web: MicrophoneMuteComponent,
  },
  speakerMute1: {
    mobile: SpeakerMute1,
    web: SpeakerMute1Component,
  },
  speakerMuteWhite: {
    mobile: SpeakerMuteWhite,
    web: SpeakerMuteWhiteComponent,
  },
  videoDisabled: {
    mobile: VideoDisabled,
    web: VideoDisabledComponent,
  },
  checkOutline: {
    mobile: Uncheck,
    web: UncheckComponent,
  },
  unchecked: {
    mobile: Unchecked,
    web: UncheckedComponent,
  },
  wifiOff: {
    mobile: WifiOff,
    web: WifiOffComponent,
  },
  microphoneFill: {
    mobile: MicrophoneFill,
    web: MicrophoneFillComponent,
  },
  videoFill: {
    mobile: VideoFill,
    web: VideoFillComponent,
  },
  speakerMute: {
    mobile: SpeakerMute,
    web: SpeakerMuteComponent,
  },
  check: {
    mobile: Check,
    web: CheckComponent,
  },
  chevron: {
    mobile: Chevron,
    web: ChevronComponent,
  },
  gallery: {
    mobile: Gallery,
    web: GalleryComponent,
  },
  emailVerification: {
    mobile: EmailVerification,
    web: EmailVerificationComponent,
  },
  emailVerified: {
    mobile: EmailVerified,
    web: EmailVerifiedComponent,
  },
  sendMessage: {
    mobile: SendMessage,
    web: SendMessageComponent,
  },
  search: {
    mobile: Search,
    web: SearchComponent,
  },
  shoppingCart: {
    mobile: ShoppingCart,
    web: ShoppingCartComponent,
  },
  shoppingCart1: {
    mobile: ShoppingCart1,
    web: ShoppingCart1Component,
  },
  moreHorizon: {
    mobile: MoreHorizon,
    web: MoreHorizonComponent,
  },
  moreVertical: {
    mobile: MoreVertical,
    web: MoreVerticalComponent,
  },
  language: {
    mobile: Language,
    web: LanguageComponent,
  },
  darkMode: {
    mobile: DarkMode,
    web: DarkModeComponent,
  },
  hamburgerMenu: {
    mobile: HamburderMenu,
    web: HamburgerMenuComponent,
  },
  close: {
    mobile: Close,
    web: CloseComponent,
  },
  chevronRight: {
    mobile: ChevronRight,
    web: ChevronRightComponent,
  },
  wifiOn: {
    mobile: WifiOn,
    web: WifiOnComponent,
  },
  verified1: {
    mobile: Verified1,
    web: Verified1Component,
  },
  verified2: {
    mobile: Verified2,
    web: Verified2Component,
  },
  fullScreen: {
    mobile: FullScreen,
    web: FullScreenComponent,
  },
  minimize: {
    mobile: Minimize,
    web: MinimizeComponent,
  },
  menu_button: {
    mobile: MenuButton,
    web: MenuBurgerComponent,
  },
  close_xbutton: {
    mobile: CloseXbutton,
    web: CloseXButtonComponent,
  },
  users: {
    mobile: Users,
    web: UsersComponent,
  },
  pletter: {
    mobile: PLetter,
    web: PLetterComponent,
  },
  lletter: {
    mobile: LLetter,
    web: LLetterComponent,
  },
  aletter: {
    mobile: ALetter,
    web: ALetterComponent,
  },
  undo: {
    mobile: Undo,
    web: UndoComponent,
  },
  yletter: {
    mobile: YLetter,
    web: YLetterComponent,
  },

  verified: {
    mobile: Verified,
    web: VerifiedComponent,
  },
  currency: {
    mobile: Currency,
    web: CurrencyComponent,
  },
  country: {
    mobile: Country,
    web: CountryComponent,
  },
  countryFlag: {
    mobile: CountryFlag,
    web: CountryFlagComponent,
  },
  payments: {
    mobile: Payments,
    web: PaymentsComponent,
  },
  deliveryTruck: {
    mobile: DeliveryTruck,
    web: DeliveryTruckComponent,
  },
  taskSuccess: {
    mobile: TaskSuccess,
    web: TaskSuccessComponent,
  },
  deleteOutline: {
    mobile: DeleteOutline,
    web: DeleteOutlineComponent,
  },
  editOutline2: {
    mobile: EditOutline2,
    web: EditOutlineComponent2,
  },
  checkboxChecked: {
    mobile: CheckboxChecked,
    web: CheckboxCheckedComponent,
  },
  checkboxUnchecked: {
    mobile: CheckboxUnchecked,
    web: CheckboxUncheckedComponent,
  },
  cartDelete: {
    mobile: CartItemDelete,
    web: CartItemDeleteComponent,
  },
  emptyAvatar: {
    mobile: EmptyAvatar,
    web: EmptyAvatarComponent,
  },
  iconCircleFilled: {
    mobile: IconCircleFilled,
    web: IconCircleFilledComponent,
  },
  iconCircleGray: {
    mobile: IconCircleGray,
    web: IconCircleGreyComponent,
  },
  add: {
    mobile: Add,
    web: AddComponent,
  },
  iconCircleGrayChecked: {
    mobile: IconCircleGrayChecked,
    web: IconCircleGrayCheckedComponent,
  },
  iconPinLocationLight: {
    mobile: IconPinLocationLight,
    web: IconPinLocationLightComponent,
  },
  iconExpandCircleRight: {
    mobile: icon_expand_circle_right,
    web: icon_expand_circle_rightComponent,
  },
  iconRedeem: {
    mobile: icon_redeem,
    web: icon_redeemComponent,
  },
  iconcopy: {
    mobile: icon_copy,
    web: icon_copyComponent,
  },
  iconcirclefilledv2: {
    mobile: icon_circle_filled_v2,
    web: icon_circle_filled_v2Component,
  },
  trophy1: {
    mobile: Trophy1,
    web: Trophy1Component,
  },
  iconErrorFill: {
    mobile: icon_error_fill,
    web: icon_error_fillComponent,
  },
  verified3: {
    mobile: Verified3,
    web: Verified3Component,
  },
  qrcode: {
    mobile: QRCode,
    web: QRCodeComponent,
  },
  orders: {
    mobile: Orders,
    web: OrdersComponent,
  },
  support: {
    mobile: Support,
    web: SupportComponent,
  },
  logoutNew: {
    mobile: LogoutNew,
    web: LogoutNewComponent,
  },
  gearIcon: {
    mobile: GearIcon,
    web: GearIconComponent,
  },
  walletNew: {
    mobile: WalletNew,
    web: WalletNewComponent,
  },
  iconsportsesports: {
    mobile: icon_sports_esports,
    web: icon_sports_esportsComponent,
  },
  skullIcon: {
    mobile: SkullIcon,
    web: SkullIconComponent,
  },
  swords: {
    mobile: Swords,
    web: SwordsComponent,
  },
  attachment1: {
    mobile: Attachment1,
    web: Attachment1Component,
  },
  help: {
    mobile: help,
    web: HelpComponent,
  },
  attach_file: {
    mobile: attach_file,
    web: AttachComponent,
  },
  icon_1: {
    mobile: icon_1,
    web: Icon1Component,
  },
  icon_2: {
    mobile: icon_2,
    web: Icon2Component,
  },
  icon_3: {
    mobile: icon_3,
    web: Icon3Component,
  },
  icon_4: {
    mobile: icon_4,
    web: Icon4Component,
  },
  arrowUp: {
    mobile: arrowUp,
    web: ArrowUpComponent,
  },
  arrowDown: {
    mobile: arrowDown,
    web: ArrowDownComponent,
  },
  moreOptions: {
    mobile: moreOptions,
    web: MoreOptionsComponent,
  },
  tournamentShare: {
    mobile: TournamentShare,
    web: TournamentShareComponent,
  },
  tournamentReport: {
    mobile: TournamentReport,
    web: TournamentReportComponent,
  },
  iconcircledonut: {
    mobile: icon_circle_donut,
    web: IconCircleDonutComponent,
  },
  iconlinefilled: {
    mobile: icon_line_filled,
    web: IconLineFilledComponent,
  },
  iconlinedashed: {
    mobile: icon_line_dashed,
    web: IconLineDashedComponent,
  },
  iconSkullBaseFill: {
    mobile: icon_skull_base_fill,
    web: IconSkullBaseFillComponent,
  },
  radioButtonDark: {
    mobile: radio_button_dark,
    web: RadioButtonDark,
  },
  verifiedIco: {
    mobile: VerifiedIco,
    web: VerifiedIcoComponent,
  },
  radioButtonLight: {
    mobile: radio_button_light,
    web: RadioButtonLight,
  },
  thumbOutline: {
    mobile: ThumbOutline,
    web: ThumbOutlineComponent,
  },
  reorder: {
    mobile: Reorder,
    web: ReorderComponent,
  },
  twitchGray: {
    mobile: TwitchGray,
    web: TwitchGrayComponent,
  },
  twitterGray: {
    mobile: TwitterGray,
    web: TwitterGrayComponent,
  },
  instagramGray: {
    mobile: InstagramGray,
    web: InstagramGrayComponent,
  },
  snapchatGray: {
    mobile: SnapchatGray,
    web: SnapchatGrayComponent,
  },
  playstationGray: {
    mobile: PlaystationGray,
    web: PlaystationGrayComponent,
  },
  xboxGray: {
    mobile: XboxGray,
    web: XboxGrayComponent,
  },
  steamGray: {
    mobile: SteamGray,
    web: SteamGrayComponent,
  },
  battlenetGray: {
    mobile: BattlenetGray,
    web: BattlenetGrayComponent,
  },
  originGray: {
    mobile: OriginGray,
    web: OriginGrayComponent,
  },
  epic: {
    mobile: Epic,
    web: EpicComponent,
  },
  epicGray: {
    mobile: EpicGray,
    web: EpicGrayComponent,
  },
  ubisoftGray: {
    mobile: UbisoftGray,
    web: UbisoftGrayComponent,
  },
  nintendoGray: {
    mobile: NintendoGray,
    web: NintendoGrayComponent,
  },
  discordGray: {
    mobile: DiscordGray,
    web: DiscordGrayComponent,
  },
  smartToy: {
    mobile: SmartToy,
    web: SmartToyComponent,
  },
  genderMaleFemale: {
    mobile: GenderMaleFemale,
    web: GenderMaleFemaleComponent,
  },
  phoneDial: {
    mobile: PhoneDial,
    web: PhoneDialComponent,
  },
  email: {
    mobile: Email,
    web: EmailComponent,
  },
  approved: {
    mobile: Approved,
    web: ApprovedComponent,
  },
  encrypted: {
    mobile: Encrypted,
    web: EncryptedComponent,
  },
  creditCard: {
    mobile: CreditCard,
    web: CreditCardComponent,
  },
  homeEdit: {
    mobile: HomeEdit,
    web: HomeEditComponent,
  },
  mapMarker: {
    mobile: MapMarker,
    web: MapMarkerComponent,
  },
  cash: {
    mobile: Cash,
    web: CashComponent,
  },
  bellSettings: {
    mobile: BellSettings,
    web: BellSettingsComponent,
  },
  lockSettings: {
    mobile: LockSettings,
    web: LockSettingsComponent,
  },
  fileLock: {
    mobile: FileLock,
    web: FileLockComponent,
  },
  addAccount: {
    mobile: AddAccount,
    web: AddAccountComponent,
  },
  pace: {
    mobile: Pace,
    web: PaceComponent,
  },
  verticalLine: {
    mobile: VerticalLine,
    web: VerticalLineComponent,
  },
  questionBox: {
    mobile: QuestionBox,
    web: QuestionBoxComponent,
  },
  twoFactorIcon: {
    mobile: TwoFactorIcon,
    web: TwoFactorIconComponent,
  },
  twoFactorIconv2: {
    mobile: TwoFactorIconv2,
    web: TwoFactorIconComponentv2,
  },
  playerSelectEmpty: {
    mobile: PlayerSelectEmpty,
    web: PlayerSelectEmptyComponent,
  },
  twoFactorShield: {
    mobile: TwoFactorShield,
    web: TwoFactorShieldComponent,
  },
  avatar: {
    mobile: Avatar,
    web: AvatarComponent,
  },
  trophy2: {
    mobile: Trophy2,
    web: Trophy2Component,
  },
  spotLight: {
    mobile: SpotLight,
    web: SpotLightComponent,
  },
  crown: {
    mobile: Crown,
    web: CrownComponent,
  },
  emailChecked: {
    mobile: EmailChecked,
    web: EmailCheckedComponent,
  },
  pinIconSunset: {
    mobile: PinIconSunset,
    web: PinIconSunsetComponent,
  },
  emailContactUs: {
    mobile: EmailContactUs,
    web: EmailContactUsComponent,
  },
  uploadImageSunset: {
    mobile: UploadImageSunset,
    web: UploadImageSunsetComponent,
  },
  contactUsSuccess: {
    mobile: ContactUsSuccess,
    web: ContactUsSuccessComponent,
  },
  icon_header_chat: {
    mobile: icon_header_chat,
    web: IconHeaderChat,
  },
  icon_chat_new: {
    mobile: icon_chat_new,
    web: IconChatNew,
  },
  icon_chat_maximize: {
    mobile: icon_chat_maximize,
    web: IconChatMaximize,
  },
  icon_chat_circle: {
    mobile: icon_chat_circle,
    web: IconChatCircle,
  },
  icon_toast_cross_error: {
    mobile: icon_toast_cross_error,
    web: IconToastCrossError,
  },
  error: {
    mobile: Error,
    web: ErrorComponent,
  },
  chat_process_outline: {
    mobile: ChatProcessOutline,
    web: ChatProcessOutlineComponent,
  },
  icon_toast_exclamation: {
    mobile: icon_toast_exclamation,
    web: IconToastExclamation,
  },
  icon_toast_checkmark: {
    mobile: icon_toast_checkmark,
    web: IconToastCheckmark,
  },
  info: {
    mobile: InfoIcon,
    web: InfoIconComponent,
  },
  group_icon: {
    mobile: GroupChatIcon,
    web: GroupChatIconComponent,
  },
  target_icon: {
    mobile: TargetIcon,
    web: TargetIconComponent,
  },
  circleoutine_icon: {
    mobile: CircleOutlined,
    web: CircleOutlinedComponent,
  },
  icon_chat_minimize: {
    mobile: icon_chat_minimize,
    web: IconChatMinimize,
  },
  icon_chat_group: {
    mobile: icon_chat_group,
    web: IconChatGroup,
  },
  muted_icon: {
    mobile: MutedIcon,
    web: MutedIconComponent,
  },
  blocked_account: {
    mobile: BlockedAccount,
    web: BlockedAccountComponent,
  },
  icon_chat_minimize_underline: {
    mobile: icon_chat_minimize_underline,
    web: IconChatMinimizeUnderline,
  },
  download: {
    mobile: Download,
    web: DownloadComponent,
  },
  sentCheck: {
    mobile: SentCheck,
    web: SentCheckComponent,
  },
  radioCheck: {
    mobile: RadioCheck,
    web: RadioCheckComponent,
  },
  radioUncheck: {
    mobile: RadioUncheck,
    web: RadioUncheckComponent,
  },
  icon_chat_chat_normal_check: {
    mobile: icon_chat_chat_normal_check,
    web: IconChatNormalCheck,
  },
  warningOutlined: {
    mobile: WarningOutlined,
    web: WarningOutlinedComponent,
  },
  icon_close_20: {
    mobile: Close20,
    web: Close20Component,
  },
  share_new: {
    mobile: ShareNew,
    web: ShareNewComponent,
  },
  noInternetLight: {
    mobile: NoInternetLight,
    web: NoInternetLightComponent,
  },
  noInternetDark: {
    mobile: NoInternetDark,
    web: NoInternetDarkComponent,
  },
  notFoundLight: {
    mobile: NotFoundLight,
    web: NotFoundLightComponent,
  },
  notFoundDark: {
    mobile: NotFoundDark,
    web: NotFoundDarkComponent,
  },
  internalServerErrorLight: {
    mobile: InternalServerErrorLight,
    web: InternalServerErrorLightComponent,
  },
  internalServerErrorDark: {
    mobile: InternalServerErrorDark,
    web: InternalServerErrorDarkComponent,
  },
  serverDownLight: {
    mobile: ServerDownLight,
    web: ServerDownLightComponent,
  },
  serverDownDark: {
    mobile: ServerDownDark,
    web: ServerDownDarkComponent,
  },
  serverDownMobile: {
    mobile: ServerDownMobile,
    web: ServerDownMobileComponent,
  },
  bellNotification: {
    mobile: icon_header_notification,
    web: IconHeaderNotification,
  },
  dangerousIcon: {
    mobile: Dangerous,
    web: DangerousIconComponent,
  },
  alertRed: {
    mobile: AlertRed,
    web: AlertRedComponent,
  },
  alertGreen: {
    mobile: AlertGreen,
    web: AlertGreenComponent,
  },
  alertYellow: {
    mobile: AlertYellow,
    web: AlertYellowComponent,
  },
  alertAddCheck: {
    mobile: AlertAddCheck,
    web: AlertAddCheckComponent,
  },
  alertFlame: {
    mobile: AlertFlame,
    web: AlertFlameComponent,
  },
  checkCircleOutline: {
    mobile: CheckCircleOutline,
    web: CheckCircleOutlineComponent,
  },
  homeBannerBackground: {
    mobile: HomeBannerBackground,
    web: HomeBannerBackgroundComponent,
  },
  homeBannerBackgroundMobile: {
    mobile: HomeBannerBackgroundMobile,
    web: HomeBannerBackgroundMobileComponent,
  },
  tournamentBannerPs5: {
    mobile: TournamentBannerPs5,
    web: TournamentBannerPs5Component,
  },
  tournamentBannerXbox: {
    mobile: TournamentBannerXbox,
    web: TournamentBannerXboxComponent,
  },
  tournamentBannerNintendo: {
    mobile: TournamentBannerNintendo,
    web: TournamentBannerNintendoComponent,
  },
  tournamentBannerPc: {
    mobile: TournamentBannerPc,
    web: TournamentBannerPcComponent,
  },
  tournamentBannerMobile: {
    mobile: TournamentBannerMobile,
    web: TournamentBannerMobileComponent,
  },
  tournamentBannerCrossPlatform: {
    mobile: TournamentBannerCrossPlatform,
    web: TournamentBannerCrossPlatformComponent,
  },
  loading: {
    mobile: Loading,
    web: LoadingComponent,
  },
  emptyState: {
    mobile: EmptyState,
    web: EmptyStateComponent,
  },
  blockedEmpty: {
    mobile: BlockedEmpty,
    web: BlockedEmptyComponent,
  },
  walletNoTransaction: {
    mobile: WalletNoTransaction,
    web: WalletNoTransactionComponent,
  },
  homeBannerDark: {
    mobile: HomeBannerDark,
    web: HomeBannerDarkComponent,
  },
  homeBannerLight: {
    mobile: HomeBannerLight,
    web: HomeBannerLightComponent,
  },
  chatIcon: {
    mobile: ChatIcon,
    web: ChatIconComponent,
  },
  megaMenuPlaceholder: {
    mobile: MegaMenuPlacholder,
    web: MegaMenuPlacholderComponent,
  },
  shopEmpty: {
    mobile: ShopEmpty,
    web: ShopEmptyComponent,
  },
  processing: {
    mobile: Processing,
    web: ProcessingComponent,
  },
  userViewCount: {
    mobile: UserViewCount,
    web: UserViewComponent,
  },
  videoPlay: {
    mobile: VideoPlay,
    web: VideoPlayComponent,
  },
  predict: {
    mobile: Predict,
    web: PredictComponent,
  },
  phoneEmpty: {
    mobile: PhoneEmpty,
    web: PhoneEmptyComponent,
  },
  rejected: {
    mobile: Rejected,
    web: RejectedComponent,
  },
  fire: {
    mobile: LocalFireDepartment,
    web: LocalFireDepartmentComponent,
  },
  productWishlist: {
    mobile: ProductWishlist,
    web: ProductWishlistComponent,
  },
};

export const getSvgXml = (props: IconProps) => {
  const { name, width = 24, height = 24, platform = 'mobile', fill, iconClasses, stroke } = props;
  if (platform === 'mobile') {
    const Icon = iconsObj[name]['mobile'];
    return <Icon width={width} height={height} {...props} />;
  } else {
    const Icon = iconsObj[name]['web'];
    return <Icon className={iconClasses} width={width} height={height} fill={fill} />;
  }
};
