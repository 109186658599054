import { IconNames, getSvgXml } from '@fe-monorepo/helper';
import { forwardRef } from 'react';

export interface IAppProps {
  name: IconNames;
  className?: string;
  iconClasses?: string;
  width?: number;
  height?: number;
  fill?: string;
  srAltText?: string;
  onClick?: () => void;
  onTouchStart?: (e: React.TouchEvent<HTMLDivElement>) => void;
}

const Icon = forwardRef<HTMLDivElement, IAppProps>((props, ref) => {
  return (
    <div ref={ref} className={props?.className} onClick={props?.onClick}>
      {getSvgXml({
        name: props.name,
        platform: 'web',
        height: props?.height,
        width: props?.width,
        fill: props?.fill,
        iconClasses: props?.iconClasses,
      })}
      {props?.srAltText && props.srAltText.length > 0 && <span className="sr-only">{props.srAltText}</span>}
    </div>
  );
});

export default Icon;
