import { useMutation } from '@apollo/client';
import { REGISTER_DEVICE_MUTATION } from '@fe-monorepo/data-access';
import { useEffect, useState } from 'react';

import { RegisterDeviceParams, RegisterDeviceResponse } from './type';

export const useRegisterDevice = () => {
  const [isSuccessful, setStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [registerDeviceParams, { data: registerDeviceParamsResponse, error: errorDevice }] = useMutation<RegisterDeviceResponse>(
    REGISTER_DEVICE_MUTATION.registerDevice,
    {
      errorPolicy: 'all',
    },
  );

  useEffect(() => {
    if (registerDeviceParamsResponse) {
      if (registerDeviceParamsResponse.registerDevice.is_successful) {
        setStatus(registerDeviceParamsResponse.registerDevice.is_successful);
      }
      if (registerDeviceParamsResponse.registerDevice.error_msg) {
        setErrorMessage(registerDeviceParamsResponse.registerDevice.error_msg);
      }
      setStatus(registerDeviceParamsResponse.registerDevice.is_successful);
    }
    setIsLoading(false);
  }, [registerDeviceParamsResponse]);

  useEffect(() => {
    if (errorDevice) {
      setError(errorDevice.message);
    }
    setIsLoading(false);
  }, [errorDevice]);

  const registerDevice = async (params: RegisterDeviceParams) => {
    setIsLoading(true);
    const { data } = await registerDeviceParams({ variables: { ...params } });
    setIsLoading(false);
    return data?.registerDevice;
  };

  return {
    registerDevice,
    isSuccessful,
    isLoading,
    error,
    errorMessage,
  };
};
