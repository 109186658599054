import { IconNames, PAGE_DESCRIPTION, PAGE_KEYWORDS, PAGE_TITLE, TxKeyPath } from '@fe-monorepo/helper';
import { useInvoice, useOrder, useTranslate } from '@fe-monorepo/hooks';
import { InvoiceModel, OrderFilteredModel } from '@fe-monorepo/models';
import { RootState, addMyOrdersTotal } from '@fe-monorepo/store';
import LoadingIndicator from '@fe-web/Molecules/LoadingIndicator/LoadingIndicator';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ResponsiveIcon from '../../../../components/Atoms/Icon/ResponsiveIcon';
import ProductDetailsHeader from '../component/ProductLabel';
import DateDropDown from '../component/SeachDetails/Component/DateDropDown';
import PriceRangePicker from '../component/SeachDetails/Component/PriceRange';
import SearchBar from '../component/SeachDetails/Component/SearchBar';
import StatusDropDown from '../component/SeachDetails/Component/StatusDropDown';
import OrderData from './OrderData';
import { StyledHeaderContainer } from './OrderPage.styled';

interface OrderPageProps {
  setFilteredOrdersLength: (value: number) => void;
}

const OrderPage: React.FC<OrderPageProps> = () => {
  const { language } = usePageLayout();
  const isMobile = useMobileDetect();
  const { translate } = useTranslate();
  const [shouldReload, setShouldReload] = useState(true);
  const isHeaderDark = useSelector((state: RootState) => state.app.headerColorChange);
  const { resend, getAllOrdersFiltered, ordersfiltered } = useOrder();
  const { getInvoice, invoiceInfoData, downloadInvoice } = useInvoice();
  const [orderInvoice, setOrderInvoice] = useState<InvoiceModel[]>([]);
  const [showMoreFilters, setShowOtherFilters] = useState(false);
  const sentinelRef = useRef(null);
  const [showLoadingText, setShowLoadingText] = useState<boolean>(true);
  const [isMomentumReachEnd, setMomentumReachEndStatus] = useState<boolean>(false);
  const [isInitLoading, setIsInitLoading] = useState<boolean>();

  useEffect(() => {
    if (isMobile && shouldReload) {
      setShouldReload(false);
    }
  }, [isMobile, shouldReload]);

  const showDetails = (invoice_number: string) => {
    getInvoice({ details: { invoice_number: invoice_number } });
  };

  useEffect(() => {
    if (invoiceInfoData?.data?.info) {
      const temp_invoice = [...orderInvoice];
      const invoiceIdx = temp_invoice.findIndex(item => item.invoice_number === invoiceInfoData.data.info.invoice_number);
      if (invoiceIdx !== -1) {
        temp_invoice[invoiceIdx] = invoiceInfoData.data.info;
      } else {
        temp_invoice.push(invoiceInfoData.data.info);
      }
      setOrderInvoice(temp_invoice);
      setFilteredInvoice(temp_invoice);
    }
  }, [invoiceInfoData]);

  /* ================================ ALL FILTERS LOGIC HERE ================================ */

  const [minPrice, setMinPrice] = useState<number>(0);
  const [maxPrice, setMaxPrice] = useState<number>(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredOrders, setFilteredOrders] = useState<OrderFilteredModel[]>([]);
  const [filteredInvoice, setFilteredInvoice] = useState<InvoiceModel[]>([]);
  const [selectedStartDate, setSelectedStartDate] = useState<Date | null>(null);
  const [selectedEndDate, setSelectedEndDate] = useState<Date | null>(null);
  const [selectedStatus, setSelectedStatus] = useState('');
  const [isIDAscending, setIsIDAscending] = useState(false);
  const [isDateAscending, setIsDateAscending] = useState(false);
  const [sortType, setSortType] = useState('date');
  const [sortValue, setSortValue] = useState('DESC');
  const [lastCursor, setLastCursor] = useState<number | undefined>();

  const toggleSorting = (isAscending: boolean, isID: boolean) => {
    if (isID) {
      setIsDateAscending(true); //reset
      setIsIDAscending(isAscending);
    } else {
      setIsIDAscending(true); //reset
      setIsDateAscending(isAscending);
    }
    setSortValue(isAscending ? 'ASC' : 'DESC');
    setSortType(isID ? 'order_id' : 'date');
  };

  useEffect(() => {
    const reset = async () => {
      setIsInitLoading(true);
      setShowLoadingText(true);
      try {
        //load when filter change; resets last cursor
        setMomentumReachEndStatus(false);
        setFilteredOrders([]);
        setLastCursor(undefined);

        const response = await getAllOrdersFiltered({
          details: {
            invoice_number: searchQuery ? searchQuery.toLocaleLowerCase() : undefined,
            status_code: selectedStatus ? selectedStatus : undefined,
            date_from: selectedStartDate ? selectedStartDate.toISOString() : undefined,
            date_to: selectedEndDate ? selectedEndDate.toISOString() : undefined,
            price_from: minPrice ? minPrice : undefined,
            price_to: maxPrice ? maxPrice : undefined,
            sort_type: sortType ? sortType : undefined,
            sort_value: sortValue ? sortValue : undefined,
          },
        });

        const data = response?.getFilteredOrders?.data?.orders;

        setFilteredOrders(data || []);
        setLastCursor(_.last(data)?.last_cursor);
      } catch {}
      setIsInitLoading(false);
      setShowLoadingText(false);
    };
    reset();
  }, [minPrice, maxPrice, selectedStatus, searchQuery, selectedStartDate, selectedEndDate, sortType, sortValue]);

  const handleLoadMore = useCallback(async () => {
    if (showLoadingText) return;

    setShowLoadingText(true);
    try {
      //load for last cursor pagination
      const response = await getAllOrdersFiltered({
        details: {
          invoice_number: searchQuery ? searchQuery.toLocaleLowerCase() : undefined,
          status_code: selectedStatus ? selectedStatus : undefined,
          date_from: selectedStartDate ? selectedStartDate.toISOString() : undefined,
          date_to: selectedEndDate ? selectedEndDate.toISOString() : undefined,
          price_from: minPrice ? minPrice : undefined,
          price_to: maxPrice ? maxPrice : undefined,
          sort_type: sortType ? sortType : undefined,
          sort_value: sortValue ? sortValue : undefined,
          direction: sortValue === 'ASC' ? 'before' : 'next',
          last_cursor: lastCursor ? lastCursor : undefined,
        },
      });

      const data = response?.getFilteredOrders?.data?.orders;
      setFilteredOrders(prevOrders => _.uniqBy([...prevOrders, ...(data || [])], 'last_cursor'));

      if (!data || data?.length <= 0) {
        setMomentumReachEndStatus(true);
      }
      const newLastCursor = _.last(data)?.last_cursor;
      setLastCursor(() => newLastCursor);
    } catch {}
    setShowLoadingText(false);
  }, [filteredOrders, showLoadingText, lastCursor, setLastCursor]);

  useEffect(() => {
    // loads new data if reach ref element
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isMomentumReachEnd) {
            handleLoadMore();
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [handleLoadMore]);

  const handleDateFilterChange = (filterId: string) => {
    const now = new Date();
    setSelectedEndDate(now);

    switch (filterId) {
      case 'anytime':
        setSelectedStartDate(null);
        break;
      case 'last_seven_days':
        setSelectedStartDate(new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000));
        break;
      case 'last_thirty_days':
        setSelectedStartDate(new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000));
        break;
      case 'last_six_months':
        setSelectedStartDate(new Date(now.getTime() - 6 * 30.44 * 24 * 60 * 60 * 1000));
        break;
      case 'older_one_year':
        setSelectedStartDate(new Date(now.getTime() - 365.25 * 24 * 60 * 60 * 1000));
        break;
      default:
        setSelectedStartDate(null);
    }
  };

  const filteredOrdersLength = ordersfiltered?.data?.count ?? 0;

  const dispatch = useDispatch();

  useEffect(() => {
    if (filteredOrdersLength > 0) {
      dispatch(addMyOrdersTotal(filteredOrdersLength));
    }
  }, [filteredOrdersLength, dispatch]);

  const shopSort = useMemo(
    () => [
      { id: '0', label: translate('wallet.all'), value: '' },
      { id: '1', label: translate('shop.cart.stepper.delivery_payment'), value: 'partially_delivered' },
      { id: '2', label: translate('shop.cart.stepper.cart'), value: 'order_placed' },
      { id: '3', label: translate('shop.cart.stepper.delivered'), value: 'complete' },
    ],
    [language],
  );

  const dateShopSort = useMemo(
    () => [
      { id: '0', label: translate('anytime'), value: 'anytime' },
      { id: '1', label: translate('last_seven_days'), value: 'last_seven_days' },
      { id: '2', label: translate('last_thirty_days'), value: 'last_thirty_days' },
      { id: '3', label: translate('last_six_months'), value: 'last_six_months' },
      { id: '4', label: translate('older_one_year'), value: 'older_one_year' },
    ],
    [language],
  );

  useEffect(() => {
    mixpanelHelper.trackPageView('MY ORDERS PAGE - ORDERS TAB', 'my_orders_page_orders_tab', window.location.pathname, document.referrer);
    brazeHelper.trackPageView('MY ORDERS PAGE - ORDERS TAB', 'my_orders_page_orders_tab', window.location.pathname, document.referrer);
  }, []);

  return (
    <>
      <CustomHelmet
        pageTitle={`${translate(PAGE_TITLE.settings as TxKeyPath) ?? ''} - ${translate(PAGE_TITLE.settings_account as TxKeyPath) ?? ''} - ${
          translate(PAGE_TITLE.settings_my_orders as TxKeyPath) ?? ''
        }`}
        metaDescription={translate(PAGE_DESCRIPTION.settings as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.settings as TxKeyPath) ?? ''}
      />
      <div className={`SD:grid SD:grid-cols-4 gap-x-[1.5rem] gap-y-[2rem] SD:gap-y-[2.5rem]`}>
        <StyledHeaderContainer>
          {/* MOBILE VIEW */}
          <div className="flex flex-row justify-between SD:justify-start gap-[1rem] self-stretch mt-[1rem]">
            <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
            <div
              className="flex flex-row gap-[10px] items-center self-stretch cursor-pointer"
              onClick={() => setShowOtherFilters(!showMoreFilters)}
            >
              <div>
                {showMoreFilters ? (
                  <ResponsiveIcon name={IconNames.close1} className="fill-sunset" baseHeight={20} baseWidth={20} />
                ) : (
                  <ResponsiveIcon name={IconNames.filterList} className="fill-sunset" baseHeight={20} baseWidth={20} />
                )}
              </div>
              <span className="font-medium text-fs-body-small text-sunset">{translate('filters')}</span>
            </div>
          </div>
          {showMoreFilters && (
            <div className={`flex mdMaxMm:flex-col flex-row justify-between items-center gap-[1rem] SD:gap-[1.5rem] my-[1rem]`}>
              <DateDropDown
                label="Dropdown Label"
                onDateFilterChange={handleDateFilterChange}
                dateShopSort={dateShopSort}
                defaultSelectedId="3"
              />
              <PriceRangePicker
                min={minPrice}
                max={maxPrice}
                onRangeChange={(min, max) => {
                  setMinPrice(min);
                  setMaxPrice(max);
                }}
              />
              <StatusDropDown label="Dropdown Label" onStatusChange={setSelectedStatus} shopSort={shopSort} />
            </div>
          )}
        </StyledHeaderContainer>

        <StyledHeaderContainer className={`productLabel__head block ${isMobile ? 'hidden' : ''}`}>
          <ProductDetailsHeader
            dateTitleKey={translate('shop.cart.order_main.order_date')}
            totalTitleKey={translate('common_total')}
            statusTitleKey={translate('common_status')}
            productTitleKey={translate('order_products')}
            productCountKey={' '}
            createdAtTitleKey={' '}
            updatedAtTitleKey={' '}
            returnsSpan={false}
            toggleSorting={toggleSorting}
            IsDateAscending={isDateAscending}
            IsIDAscending={isIDAscending}
            toggleBorder={true}
          />
        </StyledHeaderContainer>
        {isInitLoading ? (
          <p>
            <LoadingIndicator width={80} height={80} strokeWidth={2} color="#E95F2A" open={true} />
          </p>
        ) : !isInitLoading && filteredOrders && filteredOrders.length > 0 ? (
          filteredOrders.map((order, key) => {
            const invoice = filteredInvoice.find(item => item.invoice_number === order.invoice_number);
            return (
              <OrderData
                order={order}
                ordersInvoice={invoice}
                isHeaderDark={isHeaderDark}
                showDetails={showDetails}
                downloadInvoice={downloadInvoice}
                resend={resend}
                language={language}
                key={key}
              />
            );
          })
        ) : (
          <p className="font-medium text-secondary text-fs-body-large">{translate('no_results_found')}</p>
        )}
        <div className="flex flex-col items-center justify-center w-full col-span-4 text-secondary" ref={sentinelRef}>
          {showLoadingText && <span className="text-fs-body-large font-regular">{translate('common_loading') ?? 'Loading'}...</span>}
        </div>
      </div>
    </>
  );
};

export default OrderPage;
