import { ReactNode } from 'react';

interface CartSummaryProps {
    children?: ReactNode;
}

const CartSummary = (props: CartSummaryProps) => {
    return (
        <div className={`flex flex-col gap-[1.25rem] 8K:gap-[1rem]`}>
            {props?.children}
        </div>
    );
};

export default CartSummary;
