import { Card, ImageOnly, logContentCardClick, logContentCardImpressions } from '@braze/web-sdk';
import { IconNames, MixPanelEvents } from '@fe-monorepo/helper';
import { useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { Banner } from '@fe-web/types/bannerTypes';
import { getURLForRedirection } from 'apps/fe-web/src/lib/helper';
import { useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import ResponsiveIcon from '../../../components/Atoms/Icon/ResponsiveIcon';
import Container from '../../../components/Templates/Container';
import useSlidingWindow from '../../../hooks/useSlidingWindow';
import PreviewSliderItem from './PreviewSliderItem';
import SliderNavigation from './SliderNavigation';

interface PreviewSliderProps {
  banners: Banner[];
  isTransparent?: boolean;
  isFromBraze?: boolean;
  brazeCards: Card[] | ImageOnly[];
}

const PreviewSlider = (props: PreviewSliderProps) => {
  const { banners, isTransparent = false, isFromBraze = false, brazeCards } = props;

  const navigate = useNavigate();

  const { user, isLoggedIn } = useUserProfile();
  const {
    processedList: visibleItems,
    shiftLeft,
    shiftRight,
  } = useSlidingWindow(banners, banners.length > 3 ? 3 : banners.length, 0, false);

  const hasLoggedImpressionRef = useRef<Set<string>>(new Set());

  const language = useSelector((state: RootState) => state?.app.language);
  const direction = language === 'en' ? 'ltr' : 'rtl';

  const isArrowDisabled = banners.length < 2;

  const chevronClassNames = `absolute hidden md:flex z-[1] ${
    banners.length > 1 ? 'fill-secondary/70 cursor-pointer' : 'fill-secondary/40 cursor-not-allowed'
  } w-fit`;

  const goPrevious = () => {
    if (isArrowDisabled) return;
    direction === 'ltr' ? shiftLeft() : shiftRight();
  };

  const goNext = () => {
    if (isArrowDisabled) return;
    direction === 'ltr' ? shiftRight() : shiftLeft();
  };

  const handleNavigationClick = (identifier: string) => {
    const targetIndex = visibleItems.findIndex(item => item.name_en === identifier);
    if (targetIndex !== -1) {
      const middleIndex = Math.floor(visibleItems.length / 2);
      const distanceFromMiddle = targetIndex - middleIndex;

      if (distanceFromMiddle > 0) {
        for (let i = 0; i < distanceFromMiddle; i++) {
          goNext();
        }
      } else if (distanceFromMiddle < 0) {
        for (let i = 0; i < Math.abs(distanceFromMiddle); i++) {
          goPrevious();
        }
      }
    }
  };

  const handleBannerClick = (item: Banner) => {
    const { id, name_en, reference_type, reference_value, url, is_external } = item;

    mixpanelHelper.trackEvent(MixPanelEvents.bannersButtonCTA, {
      'CTA Name': `Hero Banner - ${name_en}`,
      Referrer: window.location.href,
      'CTA Source': 'Shop Page',
      Username: isLoggedIn ? user?.username : 'Guest',
    });

    if (isFromBraze) {
      const isExternal = is_external === 'true';

      const card = brazeCards.find(card => card.id === id);

      if (card) {
        logContentCardClick(card);
      }

      if (url) {
        if (isExternal) {
          window.open(url, '_blank');
        } else {
          navigate(url);
        }

        return;
      }
    }

    const referenceType = reference_type ?? '';
    const referenceValue = reference_value ?? '';

    const { url: redirectUrl, options } = getURLForRedirection(referenceType, referenceValue, isLoggedIn, user);

    if (redirectUrl) {
      navigate(redirectUrl, options);
    }
  };

  const logImpressionForBanner = useCallback(
    (item: Banner) => {
      if (!hasLoggedImpressionRef.current.has(item.id!)) {
        const card = brazeCards.find(card => card.id === item.id);

        if (card) {
          logContentCardImpressions([card]);
          hasLoggedImpressionRef.current.add(item.id!);
        }
      }
    },
    [banners, brazeCards],
  );

  return (
    <div className="flex flex-col gap-y-[0.5rem] h-full">
      <Container className={`flex ${isTransparent ? 'bg-transparent' : 'bg-primary'}`}>
        <div className="relative flex items-center w-full h-full">
          <button onClick={goPrevious} disabled={isArrowDisabled} aria-label="Previous Slide">
            <ResponsiveIcon
              className={`-left-[2.5rem] rotate-180 ${chevronClassNames}`}
              name={IconNames.chevronRight}
              baseWidth={20}
              baseHeight={20}
            />
          </button>

          <ul className="list-none h-full w-full flex sm:justify-center gap-[2%] overflow-x-scroll sm:overflow-x-hidden snap-x snap-mandatory">
            {visibleItems.map((item, index) => {
              const middleIndex = Math.floor(visibleItems.length / 2);
              const distanceFromMiddle = index - middleIndex;
              const isMiddle = distanceFromMiddle === 0;

              if (isMiddle && isFromBraze) {
                logImpressionForBanner(item);
              }

              return (
                <PreviewSliderItem
                  key={index}
                  item={item}
                  isMiddle={isMiddle}
                  absoluteDistanceFromMiddle={Math.abs(distanceFromMiddle)}
                  language={language}
                  isFromBraze={isFromBraze}
                  handleBannerClick={handleBannerClick}
                />
              );
            })}
          </ul>

          <button onClick={goNext} disabled={isArrowDisabled} aria-label="Next Slide">
            <ResponsiveIcon
              className={`-right-[2.5rem] ${chevronClassNames}`}
              name={IconNames.chevronRight}
              baseWidth={20}
              baseHeight={20}
            />
          </button>
        </div>
      </Container>

      <Container className="flex items-center justify-center px-0 bg-primary">
        <div className="flex items-center justify-center h-full">
          <SliderNavigation list={visibleItems} language={language} onNavClick={handleNavigationClick} />
        </div>
      </Container>
    </div>
  );
};

export default PreviewSlider;
