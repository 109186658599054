import {
  MixPanelEvents,
  PAGE_DESCRIPTION,
  PAGE_KEYWORDS,
  PAGE_TITLE,
  TxKeyPath,
  formatNumberWithCommasAndDecimal,
} from '@fe-monorepo/helper';
import { BGType, useShopContent, useThemedBackground, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { BannerInfoDataModel } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import BrandsSection from '@fe-web/Organisms/Brands/Brands';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import TailoredSection from '@fe-web/Organisms/Tailored/Tailored';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import { CategoryData, viewProductListEvent } from 'apps/fe-web/helper/webAnalytics';
import { getURLForRedirection } from 'apps/fe-web/src/lib/helper';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { AppRoutes } from '../../app.routes.enum';
import CCardImgOnly from '../../components/Molecules/CarouselHeaderCard/CCardImgOnly';
import ProductsCarousel from '../../components/Organisms/ProductsCarousel/ProductsCarousel';
import RecentlyViewed from '../../components/Organisms/RecentlyViewed';
import mixpanelHelper from '../../helpers/mixpanelHelper';
import useMobileDetect from '../../hooks/useMobileDetect';
import usePageLayout from '../../hooks/usePageLayout';
import { useBrazeContent } from '../../providers/BrazeContentProvider';
import CountryDropDown from './ShopCountryDropDown';
import HeroSection from './components/HeroSection';
import StaticBannerSection from './components/StaticBannerSection';

interface ShopBannerItems extends BannerInfoDataModel {
  title_en?: string;
  title_ar?: string;
  subtitle_en?: string;
  subtitle_ar?: string;
  img?: string;
}

interface IRecentlyViewed {
  image: string;
  productId: string;
  productName?: string;
  brand?: string;
}

const ShopHome = () => {
  const { user, isLoggedIn } = useUserProfile();
  const username = useSelector((state: RootState) => state.user.userContext.username);
  const themeConfig = useSelector((state: RootState) => state.app.themeConfig);
  const isMobile = useMobileDetect();
  const { backgroundImage } = useThemedBackground(BGType.shop, isMobile);
  const [recentlyViewed, setRecentlyViewed] = useState<IRecentlyViewed[]>([]);
  const { getShopLanding, getShopLandingResponse } = useShopContent();
  const { translate } = useTranslate();
  const isRTL = useSelector((state: RootState) => state.app.isRTL);

  const { shopPageBrazeSliders, shopPageBrazeStaticBanner } = useBrazeContent();

  const showCookieConsent = useSelector((state: RootState) => state.app.showCookieConsent);
  const { language } = usePageLayout();
  const navigate = useNavigate();
  const content = useMemo(() => (getShopLandingResponse.data.length > 0 ? getShopLandingResponse.data : []), [getShopLandingResponse.data]);

  useEffect(() => {
    getShopLanding();
    mixpanelHelper.trackPageView('SHOP PAGE', 'shop_page', window.location.pathname, document.referrer);
    brazeHelper.trackPageView('SHOP PAGE', 'shop_page', window.location.pathname, document.referrer);
  }, []);

  useEffect(() => {
    if (content.length > 0) {
      content.forEach(con => {
        const itemInfo = con.item_info;
        if (itemInfo.menu_code && itemInfo.products_list) {
          const categoryInfo: CategoryData = {
            categoryID: '',
            categoryName: '',

            subCategoryID: itemInfo.menu_code,
            subCategoryName: itemInfo.name_en,
          };
          viewProductListEvent(categoryInfo, itemInfo.products_list);
        }
      });
    }
  }, [content]);

  //create a typescript function that will get the cardImages from the localstorage and return it
  function getCardImages() {
    const cardImages = localStorage.getItem(`cardImages_${username}`);
    return cardImages;
  }

  //create a typescript function that will delete the cardImages from the localstorage
  function deleteCardImages() {
    localStorage.removeItem(`cardImages_${username}`);
    setRecentlyViewed([]);
  }

  //create a typescript function that will check if the 1st index of the cardImages dateAndTimeCreated is more than 48 hours old, if it is then it will delete the cardImages from the localstorage
  function checkCardImages() {
    const cardImages = JSON.parse(getCardImages() || '{}');
    const dateAndTimeCreated = new Date(cardImages[0].dateAndTimeCreated);
    const dateAndTimeNow = new Date();
    const differenceInTime = dateAndTimeNow.getTime() - dateAndTimeCreated.getTime();
    const differenceInHours = Math.abs(differenceInTime / (1000 * 3600));
    if (differenceInHours > 48) {
      deleteCardImages();
    }
  }
  //This useEffect will run when the component is mounted, it will check if the cardImages exists in the localstorage, if it does then it will run the checkCardImages function
  useEffect(() => {
    if (getCardImages()) {
      checkCardImages();
    }
  });
  //create a useEffect that will check if the cardImages exists in the localstorage, if it does then it will set the recentlyViewed state to the cardImages from the localstorage
  useEffect(() => {
    if (getCardImages()) {
      const cardImages = JSON.parse(getCardImages() || '{}');
      setRecentlyViewed(showCookieConsent ? [] : cardImages.slice(-10).reverse());
    }
  }, [showCookieConsent]);

  const [isSuccess, setIsSuccess] = useState(true);
  const [Message, setMessage] = useState('');

  const checkIsSuccess = (errorMessage: string) => {
    setIsSuccess(false);
    setMessage(errorMessage);
  };

  useEffect(() => {
    //toast modal
    if (!Message) return;
    if (!isSuccess) {
      toast.error(<span className="not-italic font-medium leading-6 text-green text-fs-body">{Message}</span>, {
        position: 'top-center',
        hideProgressBar: true,
        closeButton: false,
        className: 'bg-white100 font-regular w-full SD:mt-0',
      });
    }
    setMessage('');
  }, [isSuccess, Message]);

  const renderCarousel = (menu_item: any, banner_info: ShopBannerItems, key: number): JSX.Element => {
    const { url, options } = banner_info
      ? getURLForRedirection(banner_info.reference_type, banner_info.reference_value, isLoggedIn, user, `m-${menu_item?.menu_code}`)
      : { url: '', options: {} };

    const isClickable = banner_info?.reference_type !== 'none' && banner_info?.reference_type != null;
    const cursorStyle = isClickable ? 'cursor-pointer' : 'cursor-default';

    const content = (
      <CCardImgOnly
        bannerType={banner_info?.reference_type}
        title={banner_info ? banner_info[`name_${language}`] ?? '' : ''}
        subTitle={banner_info ? banner_info[`subtitle_${language}`] ?? '' : ''}
        img={banner_info?.img}
        bannerImg={
          banner_info && isMobile ? banner_info[`mobile_media_url_${language}`] : !isMobile ? banner_info[`web_media_url_${language}`] : ''
        }
        card_type={banner_info?.carousel_type}
        timer={banner_info?.expire_on}
        cursorStyle={cursorStyle}
      />
    );

    return (
      <ProductsCarousel
        key={key}
        title={isRTL ? menu_item?.name_ar : menu_item?.name_en}
        sectionName={menu_item?.name_en}
        ctaLabel={translate('common_see_all')}
        products={menu_item?.products_list?.map((product: any) => {
          const lowestPrice = formatNumberWithCommasAndDecimal(product?.lowest_selling_price);
          const actualPrice = formatNumberWithCommasAndDecimal(product?.lowest_actual_cost);
          const lowest_selling_price_formatted = translate('currency_SR')?.replace('[balance]', '' + lowestPrice);
          const lowest_actual_cost_formatted = translate('currency_SR')?.replace('[balance]', '' + actualPrice);
          const link = `${AppRoutes.shop}/m-${menu_item?.menu_code}/${product?.product_code}`;
          const brandLink = `${AppRoutes.shop}/c-${product?.brand?.collection_code}`;
          return {
            ...product,
            lowest_selling_price_formatted,
            lowest_actual_cost_formatted,
            link,
            brand: product?.brand,
            brandLink,
          };
        })}
        seeAllLink={`${AppRoutes.shop}/m-${menu_item?.menu_code}`}
        subCategory={menu_item[`name_${language}`]}
        navigate={navigate}
        isRTL={isRTL}
        isMobile={isMobile}
        firstCard={
          banner_info &&
          (banner_info.mobile_media_url_ar ||
            banner_info.mobile_media_url_en ||
            banner_info.web_media_url_ar ||
            banner_info.web_media_url_en) ? (
            isClickable ? (
              <Link
                to={url !== '' ? url : ''}
                {...options}
                onClick={() => {
                  mixpanelHelper.trackEvent(MixPanelEvents.bannersButtonCTA, {
                    'CTA Name': `Mini Carousel Banner - ${banner_info.name_en}`,
                    Referrer: window.location.href,
                    'CTA Source': 'Shop Page',
                    Username: isLoggedIn ? user?.username : 'Guest',
                  });
                }}
              >
                {content}
              </Link>
            ) : (
              content
            )
          ) : null
        }
        language={language}
        checkIsSuccess={checkIsSuccess}
      />
    );
  };

  return (
    <>
      <CustomHelmet
        pageTitle={translate(PAGE_TITLE.shop as TxKeyPath) ?? ''}
        metaDescription={translate(PAGE_DESCRIPTION.shop as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.shop as TxKeyPath) ?? ''}
      />

      <CountryDropDown classNames="z-[1]" />

      <div
        className={`flex flex-col bg-top bg-no-repeat bg-contain ${themeConfig ? '4K:bg-cover' : ''}`}
        style={themeConfig ? { backgroundImage: `url(${backgroundImage})` } : {}}
      >
        <div className="mt-[1.125rem] mb-40 SD:mb-[7.5rem]">
          <div className="flex flex-col gap-y-[5rem] SD:gap-y-[7.5rem]">
            {content?.map((data, key) => {
              const { section_type, banner_info, item_info } = data;

              return (
                <Fragment key={item_info?.banner_id ?? key}>
                  {((section_type === 'slider' && banner_info?.banner_items) || section_type === 'braze_slider') && (
                    <HeroSection
                      shopPageBrazeSliders={shopPageBrazeSliders}
                      spectatorBanners={banner_info?.banner_items}
                      isFromBraze={section_type === 'braze_slider'}
                    />
                  )}

                  {key === 0 && recentlyViewed.length > 0 && (
                    <RecentlyViewed recentlyViewed={recentlyViewed} checkIsSuccess={checkIsSuccess} />
                  )}

                  {section_type === 'carousel' && item_info?.products_list?.length > 0 && renderCarousel(item_info, banner_info, key)}

                  {(section_type === 'static_banner' || section_type === 'braze_static_banner') && (
                    <StaticBannerSection
                      shopPageBrazeStaticBanner={shopPageBrazeStaticBanner}
                      banner={banner_info}
                      isFromBraze={section_type === 'braze_static_banner'}
                    />
                  )}

                  {section_type === 'section' && item_info && (
                    <BrandsSection
                      name_en={item_info?.name_en}
                      name_ar={item_info?.name_ar}
                      menu_items={item_info?.menu_items}
                      menu_code={item_info?.menu_code}
                    />
                  )}
                </Fragment>
              );
            })}

            <TailoredSection classNames="px-20 md:px-64 lg:px-120 4xl:px-160 5xl:px-320 8xl:px-655" hasTitle={true} isMobile={isMobile} />
          </div>
        </div>
      </div>
    </>
  );
};

export default ShopHome;
