import { FormValue, OptionsInputFieldDefinition, RadioOption } from '@fe-monorepo/forms';
import { useComponentIsAppear } from '@fe-web/hooks/useComponentIsAppear/useComponentIsAppear';
import { useEffect, useRef, useState } from 'react';

const Divider = () => <div className={'w-full bg-secondary/10 h-[0.0625rem]'} />;

const RadioCircle = (props: { isSelected: boolean; onClick: () => void }) => {
  const { isSelected, onClick } = props;
  return (
    <div
      className={`flex items-center justify-center grow-0 shrink-0 aspect-square w-[1.25rem] border-[0.125rem] ${
        isSelected ? 'border-sunset' : 'border-secondary/50'
      } rounded-full cursor-pointer`}
      onClick={onClick}
    >
      {isSelected && <div className="w-8/12 rounded-full grow-0 shrink-0 aspect-square bg-sunset" />}
    </div>
  );
};

interface RadioButtonProps<T> {
  value: T;
  OptionValue: React.FC<{ className?: string; value: T }>;
  isSelected: boolean;
  isLast: boolean;
  showDivider: boolean;
  onClick: () => void;
}

const RadioButton = <T,>(props: RadioButtonProps<T>) => {
  const ref = useRef<HTMLDivElement>(null);
  const { value, OptionValue, isLast, showDivider, isSelected, onClick } = props;
  const { isIntersecting } = useComponentIsAppear(ref);

  useEffect(() => {
    if (isSelected && !isIntersecting) {
      ref.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }
  }, []);

  return (
    <>
      <div ref={ref} className="flex items-center gap-[0.75rem] py-[0.5rem]">
        <RadioCircle isSelected={isSelected} onClick={onClick} />
        <OptionValue className={`${isSelected ? 'font-medium' : 'font-regular'}`} value={value} />
      </div>
      {showDivider && !isLast && <Divider />}
    </>
  );
};

const useOptionsFilter = <T,>(fullOptions: RadioOption<T>[], searchFilter?: (option: T) => boolean) => {
  const [filteredList, setFilteredList] = useState<RadioOption<T>[]>(fullOptions);

  useEffect(() => {
    if (!fullOptions) return;
    if (!searchFilter) {
      setFilteredList(fullOptions);
      return;
    }
    if (fullOptions.length > 0) {
      const newFilteredOptions = fullOptions
        .filter(item => searchFilter(item.value))
        .map(item => ({ id: '' + item.id, value: item.value }));
      setFilteredList(newFilteredOptions);
    }
  }, [fullOptions, searchFilter]);

  return filteredList;
};

interface RadioProps<T> {
  className?: string;
  showDivider?: boolean;
  fieldDefinition: OptionsInputFieldDefinition<T>;
  initialSelectionID?: string;
  OptionComponent: React.FC<{ className?: string; value: T }>;
  searchFilter?: (option: T) => boolean;
  initializeForm: (key: string, value: string) => void;
  onChange: (key: string, value: FormValue) => void;
}

const RadioButtons = <T,>(props: RadioProps<T>) => {
  const selectedButton = useRef<HTMLDivElement>(null);
  const { className, fieldDefinition, initialSelectionID, onChange } = props;
  const [selectionID, setSelection] = useState<string>(fieldDefinition.defaultOptionID);
  const filteredOptions = useOptionsFilter(fieldDefinition.options, props.searchFilter);

  useEffect(() => {
    const optionIDs = fieldDefinition.options.map(option => option.id);
    props.initializeForm(fieldDefinition.key, fieldDefinition.defaultOptionID);
    if (initialSelectionID && optionIDs.includes(initialSelectionID)) {
      props.initializeForm(fieldDefinition.key, initialSelectionID);
      setSelection(initialSelectionID);
      if (selectedButton.current) {
        selectedButton.current.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
    }
  }, [initialSelectionID, fieldDefinition.options]);

  useEffect(() => {
    onChange(fieldDefinition.key, { value: selectionID, isValid: true });
  }, [selectionID]);

  return (
    <div className={className}>
      <div className="w-full h-fit flex flex-col gap-[0.5rem]">
        {filteredOptions.map((option, index) => {
          return (
            <RadioButton
              key={option.id}
              isSelected={option.id === selectionID}
              isLast={index === filteredOptions.length - 1}
              showDivider={props.showDivider ?? false}
              value={option.value}
              OptionValue={props.OptionComponent}
              onClick={() => setSelection(option.id)}
            />
          );
        })}
      </div>
    </div>
  );
};

export default RadioButtons;
