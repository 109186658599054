import { gql } from '@apollo/client';

const TMS_BRACKET_QUERY = {
  tmsBracketGetAll: gql`
    query tmsBracketGetAll($details: TmsTournamentBracketBracketInput) {
      tmsBracketGetAll(details: $details) {
        is_successful
        error_code
        error_msg
        app_code
        data {
          id
          tournament_id
          stage_id
          group_id
          round_id
          number
          type
          status
          scheduled_datetime
          played_at
          depth
          branch
          opponents {
            number
            participant {
              id
              name
              custom_user_identifier
              # custom_fields
            }
            position
            rank
            result
            forfeit
            score
            source_type
            source_node_id
          }
        }
      }
    }
  `,
};

export { TMS_BRACKET_QUERY };
