import { DocumentNode, useMutation } from "@apollo/client";
import { setErrorObject, useAppDispatch } from "@fe-monorepo/store";
import { useEffect, useState } from "react";



type APIError =
{
  code: string,
  message: string
}

type ResponseType<ItemType> =
{
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: ItemType;
}

type QueryVariables<ItemType> =
{
  [queryName: string]: ResponseType<ItemType>
}

type QueryParams<InputModel>=
{
  details?: InputModel
}


export const useMutateQuery = <ItemType, InputModel = void>(query: DocumentNode, defaultValue: ItemType) =>
{
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ItemType>(defaultValue)
  const [isValid, setValidity] = useState<boolean>(false)
  const [error, setError] = useState<APIError>();

  const [mutateGql, { data: mutateResponse, error: errorResponse, loading }] = useMutation<
    QueryVariables<ItemType>,
    QueryParams<InputModel>>
    (query,
    {
      onError: error =>
      {
        // changeLoadingState(false);
        // This is required to prevent unhandled promise errors due to network and invalid token errors
      },

      onCompleted(data: QueryVariables<ItemType>) 
      {
        for (const key in data) 
        {
          if (Object.prototype.hasOwnProperty.call(data, key)) 
          {
            const element = data[key];

            // console.log({ key, element });
            
            if (element.is_successful === false) 
            {
              const errorObject =
              {
                  APIName: key,
                  errorCode: element.error_code,
                  errorMessage: element.error_msg
              }
                        
              dispatch(setErrorObject(errorObject))
            }
          }
        }
      },
    });


  const mutate = (model: InputModel) =>
  {
    setIsLoading(true);

    return mutateGql({ variables: { details: model }})
  }

  const reset = () =>
  {
    setIsLoading(false)
    setData(defaultValue)
    setValidity(false)
    setError(undefined)
  }


  useEffect(() =>
  {
    if (mutateResponse)
    {
      const responseKey = Object.keys(mutateResponse)[0];

      const response = mutateResponse[responseKey];

      if (response)
      {
        if (response.is_successful)
        {
          setData(response.data)
        }

        setIsLoading(false);

        setValidity(response.is_successful)

        setError(
        {
          message: response.error_msg,
          code: response.error_code
        })
      }
    }
  }, [mutateResponse])


  useEffect(() =>
  {
    if (errorResponse)
    {
      setIsLoading(false)
    }
  }, [errorResponse])

  return {
    isLoading,
    isValid,
    data,
    error,

    mutate,
    reset
  }

}
