import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import Icon from '@fe-web/Atoms/Icon/Icon';

const VerifyUserNotification = (props: { onClick: () => void }) => {
  const { translate } = useTranslate();
  const { onClick } = props;
  return (
    <div className="w-full flex items-center gap-[0.5rem] px-[1.25rem] py-[0.5rem] cursor-pointer" onClick={onClick}>
      <div className="text-warningOrange h-[2.5rem] w-[2.5rem] flex items-center">
        <Icon className="cursor-pointer fill-secondary" name={IconNames.alert} height={24} width={24} />
      </div>
      <div className="text-bodySmall">
        {translate('verify_user_motivation.verified_users.secure_text')}{' '}
        <span className="text-sunset">{translate('verify_user_motivation.verified_users.secure_cta')}</span>
      </div>
    </div>
  );
};

export default VerifyUserNotification;
