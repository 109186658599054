import { Genders, changeUser, getUser, openSession } from '@braze/web-sdk';
import {
  AuthMethod,
  BrazeCustomEvents,
  EventObject,
  Logger,
  MixPanelEvents,
  genderMapping,
  isValidSocialAuthMethod,
} from '@fe-monorepo/helper';
import { useAccount, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import convivaHelper from '@fe-web/helpers/convivaHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { useBitsProvider } from '@fe-web/hooks/useBits/BitsProvider';
import { isEmpty } from 'lodash';
import queryString from 'query-string';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';

import { webAnalytics } from '../../../helper/webAnalytics';
import { AppRoutes } from '../app.routes.enum';

const socialSignupGTMSuccess = (provider: string) => {
  const event: EventObject = {
    name: 'signup',
    properties: {
      method: provider as AuthMethod,
      referral_code: 'No',
    },
  };
  webAnalytics(event);
};

const socialLoginGTMSuccess = (provider: string) => {
  const loginSocialSuccess: EventObject = {
    name: 'login',
    properties: { method: provider as AuthMethod },
  };
  webAnalytics(loginSocialSuccess);
};

type TempType = {
  provider: string;
  type: 'signup' | 'login';
  parsedQueryString: queryString.ParsedQuery<string | number | boolean>;
};

const CallbackPage = () => {
  const { setUserToken, user } = useUserProfile();
  const User = useSelector((state: RootState) => state.user.userContext);
  const [state, setState] = useState<TempType | null>(null);
  const { getUserProfile, getUserProfileSSoLogin } = useAccount();
  const navigate = useNavigate();
  const params = useParams();
  const { log } = Logger();
  const { handleQuickPlayLogin } = useBitsProvider();

  log('window.location.search', {
    windowLocationSearch: window.location.search,
    queryString: queryString,
    isEmailVerified: user?.is_email_verified,
  });

  const trackSocialAuthGTMSuccess = (
    provider: string,
    type: 'signup' | 'login',
    parsedQueryString: queryString.ParsedQuery<string | number | boolean>,
  ) => {
    if (!isValidSocialAuthMethod(provider)) return;
    provider = provider.charAt(0).toUpperCase() + provider.slice(1);
    if (type === 'login') {
      socialLoginGTMSuccess(provider);
      const eventPayload = {
        'Login Method': provider,
        'Authentication Status': 'Success',
        'API Response Code': '',
        'API Response Message': '',
      };
      mixpanelHelper.trackEvent(MixPanelEvents.loginSubmitted, eventPayload);
      brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.LoginSubmitted, eventPayload);
      navigate(`${AppRoutes.home}?${queryString.stringify(parsedQueryString)}`);
    } else {
      socialSignupGTMSuccess(provider);
      const eventPayload = {
        'Sign Up Method': provider,
        'Authentication Status': 'Success',
        'API Response Code': '',
        'API Response Message': '',
      };
      mixpanelHelper.trackEvent(MixPanelEvents.signupMethodSubmitted, eventPayload);
      brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.SignupMethodSubmitted, eventPayload);
      navigate(`${AppRoutes.auth?.replace(':type', 'presignup')}?${queryString.stringify(parsedQueryString)}`);
    }
  };

  const fetchUserDetails = async () => {
    const sso_login_client_id = localStorage.getItem('sso_login_client_id');
    const sso_login_redirect_uri = localStorage.getItem('sso_login_redirect_uri');
    if (sso_login_client_id && sso_login_redirect_uri) {
      const response = await getUserProfileSSoLogin({ redirect_uri: sso_login_redirect_uri || '', client_id: sso_login_client_id || '' });
      const redirect_url = response.data?.getUserProfileInfoSSoLogin?.data?.uri || '';
      window.location.replace(redirect_url);
    } else {
      const res = await getUserProfile();

      if (res?.data?.getUserProfileInfo?.data && res?.data?.getUserProfileInfo?.data?.uuid) {
        const { data } = res.data.getUserProfileInfo;

        mixpanelHelper.setIdentity(data.uuid);
        convivaHelper.setIdentity(data.uuid);
        changeUser(data.uuid);

        mixpanelHelper.setUserProperties({
          $name: data.display_name,
          $avatar: data.avatar_url,
          Username: data.username,
          'Is Email Verified': Boolean(data.is_email_verified),
          'Is Mobile Verified': Boolean(data.is_mobile_verified),
        });
        if (data.email) {
          getUser()?.setEmail(data.email);
        }
        if (data.mobile) {
          getUser()?.setPhoneNumber(`+${data.mobile}`);
        }
        getUser()?.setCustomUserAttribute('Is Email Verified', Boolean(data.is_email_verified));
        getUser()?.setCustomUserAttribute('Is Mobile Verified', Boolean(data.is_mobile_verified));
        if (data.birthdate) {
          const date = new Date(data.birthdate);
          const year = date.getUTCFullYear();
          const month = date.getUTCMonth() + 1;
          const day = date.getUTCDate();

          getUser()?.setDateOfBirth(year, month, day);
        }
        if (data.preferred_language) {
          getUser()?.setLanguage(data.preferred_language);
        }
        if (data.gender_code) {
          getUser()?.setGender(genderMapping[data.gender_code] as Genders);
        }
        if (data.country_code) {
          getUser()?.setCountry(data.country_code);
        }

        openSession();
      }
    }
  };
  // This should eliminate the duplicate login tracking
  useEffect(() => {
    if (params.auth === 'payment') {
      navigate(`/payment/${params.version}/${params.type}/${params.provider}`);
      return;
    }

    const parsedQueryString = queryString.parse(window.location.search, { parseBooleans: true, parseNumbers: true });

    log('parsedQueryString', parsedQueryString);
    log('params: ', params);

    if (!isEmpty(parsedQueryString) && parsedQueryString?.token) {
      setUserToken(parsedQueryString?.token as string);
      handleQuickPlayLogin(parsedQueryString.token as string);
      if (params?.type === 'presignup') {
        if (params.provider) {
          setState({
            provider: params.provider,
            type: 'signup',
            parsedQueryString,
          });
          fetchUserDetails();
        }
      } else {
        if (params.provider) {
          setState({
            provider: params.provider,
            type: 'login',
            parsedQueryString,
          });
          fetchUserDetails();
        }
      }
    } else {
      navigate(`${AppRoutes.authSignUp}?${queryString.stringify(parsedQueryString)}`);
    }
  }, [params.auth, params?.type, params.provider]);

  useEffect(() => {
    if (state) {
      if (User.username) {
        trackSocialAuthGTMSuccess(state.provider, state.type, state.parsedQueryString);
      }
    }
  }, [state, User]);

  return null;
};

export default CallbackPage;
