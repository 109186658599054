import { gql } from '@apollo/client';

export const WISHLIST_QUERY = {
  getAllWishlist: gql`
    query fetchAllWishlist($details: GetAllWishlistInput) {
      getAllWishlist(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          product_code
          description_ar
          description_en
          featured_img
          name_ar
          name_en
          metadata
          lowest_actual_cost
          highest_actual_cost
          highest_selling_price
          lowest_selling_price
          brand {
            name_en
            name_ar
            collection_code
          }
          last_cursor
        }
      }
    }
  `,
};
