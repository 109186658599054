import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import React from 'react';

interface Sorting {
  field: string;
  sort: string;
}
interface ProductDetailsHeaderProps {
  toggleSorting: (isAscending: boolean, isISD: boolean) => void;
  IsIDAscending: boolean;
  IsDateAscending: boolean;
  returnsSpan: boolean;
  productCountKey: string | null;
  dateTitleKey: string | null;
  totalTitleKey: string | null;
  statusTitleKey: string | null;
  productTitleKey: string | null;
  createdAtTitleKey: string | null;
  updatedAtTitleKey: string | null;
  toggleBorder?: boolean;
}

const ProductDetailsHeader: React.FC<ProductDetailsHeaderProps> = ({
  toggleSorting,
  IsIDAscending,
  IsDateAscending,
  dateTitleKey,
  totalTitleKey,
  statusTitleKey,
  productTitleKey,
  createdAtTitleKey,
  updatedAtTitleKey,
  returnsSpan,
  productCountKey,
  toggleBorder,
}) => {
  const { translate } = useTranslate();
  const isMobile = useMobileDetect();
  return (
    <div className={`${returnsSpan ? 'flex w-full' : ''}`}>
      <div
        className={`flex flex-row items-center justify-between grid ${toggleBorder && 'border-b-[.25px] border-secondary/25'} ${
          isMobile ? 'grid-cols-2' : `${returnsSpan ? 'grid-cols-12 w-[98%]' : 'grid-cols-8'}`
        } pb-[0.81rem] pt-[2.5rem]`}
      >
        <div
          className={`flex justify-start items-center gap-[1rem] hover:cursor-pointer ${returnsSpan ? 'col-span-2' : ''}`}
          onClick={() => {
            toggleSorting(!IsIDAscending, true);
          }}
        >
          <span className="text-secondary/50">{translate('ID')}</span>
          <ResponsiveIcon
            className={`fill-secondary ${IsIDAscending ? 'rotate-180' : 'rotate-0'}`}
            name={IconNames.chevron}
            baseHeight={24}
            baseWidth={24}
          />
        </div>
        <div
          className={`orderdate flex 
            justify-start items-center gap-[1rem] 
            hover:cursor-pointer ${returnsSpan ? 'hidden' : 'block'} `}
          onClick={() => {
            toggleSorting(!IsDateAscending, false);
          }}
        >
          <span className="w-[0.063rem] h-[1.5rem]"></span>
          <span className="text-secondary/50 2K:whitespace-nowrap">{dateTitleKey}</span>
          <ResponsiveIcon
            className={`fill-secondary ${IsDateAscending ? 'rotate-180' : 'rotate-0'}`}
            name={IconNames.chevron}
            baseHeight={24}
            baseWidth={24}
          />
        </div>
        <div
          className={`${isMobile && 'hidden'} ${
            returnsSpan ? 'col-span-1' : ''
          } total mdMaxS:hidden flex justify-start items-center gap-[1rem] block`}
        >
          <span className="w-[0.063rem] h-[1.5rem]"></span>
          <span className="text-secondary/50">{totalTitleKey}</span>
        </div>
        <div
          className={`${isMobile && 'hidden'} ${
            returnsSpan ? 'col-span-1' : ''
          } image mdMaxS:hidden flex justify-start items-center pl-[.5rem] block col-span-1 ${returnsSpan ? 'block' : 'hidden'}`}
        >
          <span className="text-secondary/50">{productCountKey}</span>
        </div>
        <div
          className={`${isMobile && 'hidden'} status mdMaxS:hidden flex justify-start items-center gap-[1rem] ${
            returnsSpan ? 'col-span-2' : 'col-span-2'
          }`}
        >
          <span className="w-[0.063rem] h-[1.5rem]"></span>
          <span className="text-secondary/50">{statusTitleKey}</span>
        </div>
        <div
          className={`${isMobile && 'hidden'} image mdMaxS:hidden flex justify-start items-center pl-[1.5rem] block ${
            returnsSpan ? 'col-span-2' : 'col-span-2'
          }`}
        >
          <span className="text-secondary/50">{productTitleKey}</span>
        </div>
        <div
          className={`orderdate flex 
            justify-start items-center gap-[1rem] 
            hover:cursor-pointer ${returnsSpan ? 'col-span-2' : ''}`}
          onClick={() => {
            toggleSorting(!IsDateAscending, false);
          }}
        >
          <span className="w-[0.063rem] h-[1.5rem]"></span>
          <span className="text-secondary/50 2K:whitespace-nowrap truncate">{updatedAtTitleKey}</span>
          <ResponsiveIcon
            className={`fill-secondary ${IsDateAscending ? 'rotate-180' : 'rotate-0'} ${returnsSpan ? 'block' : 'hidden'}`}
            name={IconNames.chevron}
            baseHeight={24}
            baseWidth={24}
          />
        </div>
        <div
          className={`
            orderdate flex 
            justify-start items-center gap-[1rem] 
            hover:cursor-pointer ${returnsSpan ? 'col-span-2' : ''}`}
          onClick={() => {
            toggleSorting(!IsDateAscending, false);
          }}
        >
          <span className="w-[0.063rem] h-[1.5rem]"></span>
          <span className="text-secondary/50 2K:whitespace-nowrap truncate">{createdAtTitleKey}</span>
          <ResponsiveIcon
            className={`fill-secondary ${IsDateAscending ? 'rotate-180' : 'rotate-0'} ${returnsSpan ? 'block' : 'hidden'}`}
            name={IconNames.chevron}
            baseHeight={24}
            baseWidth={24}
          />
        </div>
      </div>
      <div className="w-[2%]"></div>
    </div>
  );
};

export default ProductDetailsHeader;
