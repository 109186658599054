import { ReactNode } from 'react';

interface MiniCardContainerProps {
  children: ReactNode;
}

const MiniCardContainer = ({ children }: MiniCardContainerProps) => {
  return (
    <div className="w-[11.25rem] h-[12.3125rem] border-[0.063rem] border-secondary/20 rounded-[0.25rem] relative flex justify-center items-center shrink-0">
      {children}
    </div>
  );
};

export default MiniCardContainer;
