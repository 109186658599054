import { IconNames, ModerationStatuses, formatLargeNumbers } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import BitThumbnail from '@fe-web/Atoms/BitThumbnail/BitThumbnail';
import ModerationStatus from '@fe-web/Atoms/ModerationStatus/ModerationStatus';

import ResponsiveIcon from '../../Atoms/Icon/ResponsiveIcon';
import PlayerAvatar from '../../Molecules/PlayerAvatar';
import BitActions from './BitActions';

interface IconCountBadgeProps {
  count: number | string;
  iconName: IconNames;
  className?: string;
}
const IconCountBadge = (props: IconCountBadgeProps) => {
  const { count, iconName, className } = props;
  return (
    <div className={`flex bg-black70 gap-4 px-4 py-2 rounded-sm items-center ${className}`}>
      <ResponsiveIcon name={iconName} className="fill-white100" baseHeight={12} baseWidth={12} />
      <p className="text-xs font-normal text-white100">{formatLargeNumbers(Number(count))}</p>
    </div>
  );
};

interface BitProps {
  id?: string;
  img: string;
  count: number;
  title: string;
  showMenu?: boolean;
  status?: string;
  showUserBadge?: boolean;
  showHeartBadge?: boolean;
  showPlayBadge?: boolean;
  userAvatarUrl?: string;
  showBitTitle?: boolean;
  username?: string;
  description?: string;
  showUserInfo?: boolean;
  onClick?: () => void;
  handleBitDelete?: (videoId: string, type: string, title: string) => void;
  handleBitShare?: (videoId: string, description: string) => void;
  containerStyle?: string;
  likeCount: number;
  isDraft?: boolean;
  showOverlay?: boolean;
  onDraftClick?: (event: React.MouseEvent<HTMLDivElement>) => void; // Add event typing here
  repost?: boolean;
  repostUser?: any;
}
const Bit = (props: BitProps) => {
  const {
    id,
    img,
    count,
    title,
    showMenu = false,
    status,
    onClick,
    handleBitShare,
    handleBitDelete,
    showUserBadge,
    showHeartBadge,
    showPlayBadge,
    showBitTitle = false,
    userAvatarUrl,
    username,
    description,
    showUserInfo = false,
    containerStyle,
    likeCount,
    isDraft = false,
    showOverlay = false,
    onDraftClick,
    repost,
    repostUser,
  } = props;

  const { translate } = useTranslate();
  const isActive = status === ModerationStatuses.active;

  const handleBitClick = () => {
    if (isActive && onClick && onClick instanceof Function) {
      onClick();
    }
  };

  return (
    <div className="flex flex-col gap-[0.75rem] cursor-pointer" onClick={handleBitClick}>
      <BitThumbnail url={img} status={status}>
        <>
          <div
            className="absolute top-0 left-0 right-0 z-10 flex items-center justify-start gap-8 p-8 rounded-t-md repostUser"
            style={{ background: repost ? 'linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%)' : 'none' }}
          >
            {repost && (
              <img
                src={repostUser?.profileImageUrl}
                className="w-[32px] h-[32px] object-cover border-[1px] border-solid border-[#454547] rounded-full"
              />
            )}
            {repost ? (
              <div className="flex flex-col flex-1 repostUserInfo">
                <h4 className="text-xs font-normal text-white">{translate('quickplay.create-repost')}</h4>
                <span className="text-xs font-normal text-white">{repostUser?.userName}</span>
              </div>
            ) : (
              <div className="flex-1" />
            )}
            {showMenu && (
              <BitActions
                isActive={isActive}
                onDelete={() => {
                  if (handleBitDelete instanceof Function) {
                    handleBitDelete(id ?? '', repost ? 'repost' : 'upload', title);
                  }
                }}
                onShare={() => {
                  if (handleBitShare instanceof Function && id) {
                    handleBitShare(id, description ?? '');
                  }
                }}
                repost={repost ?? false}
              />
            )}
          </div>
          {showOverlay && <div className="absolute inset-0 rounded-md bg-black42" />}
          {showUserBadge && <IconCountBadge iconName={IconNames.users} count={count} className="absolute bottom-[0.5rem] left-[0.5rem]" />}
          {showPlayBadge && (
            <IconCountBadge iconName={IconNames.bitViews} count={count} className="absolute bottom-[0.5rem] left-[0.5rem]" />
          )}
          {showHeartBadge && (
            <IconCountBadge iconName={IconNames.heartOutline} count={likeCount} className="absolute bottom-[0.5rem] right-[0.5rem]" />
          )}
          {!isActive && <ModerationStatus status={status} />}
          {isDraft && (
            <div className="absolute flex flex-col items-center justify-center w-full h-full gap-[0.625rem]" onClick={onDraftClick}>
              <ResponsiveIcon
                name={IconNames.editOutline2}
                baseHeight={24}
                baseWidth={24}
                iconClasses="fill-white"
                className="flex items-center justify-center w-[3rem] h-[3rem] rounded-full bg-black70"
              />
              <p className="font-normal text-white font-regular text-fs-body-small">{translate('profile.bits.drafts')}</p>
            </div>
          )}
        </>
      </BitThumbnail>
      {showUserInfo && (
        <div className={`flex flex-col gap-[0.5rem] ${containerStyle ?? ''}`}>
          <p
            className="font-medium text-fs-body-small text-secondary line-clamp-2"
            style={{
              minHeight: 'calc(2 * 1.429em)',
            }}
          >
            {description}
          </p>
          <PlayerAvatar
            avatarUrl={userAvatarUrl ?? ''}
            avatarSize={2}
            dir="row"
            hasDisplayName={false}
            userId={`@${username ?? ''}`}
            containerStyle="gap-16"
            userIdStyle="font-sm text-fs-caption text-neutral-400-zinc-500"
            labelContainerStyle="hidden"
          />
        </div>
      )}
      {showBitTitle && <p className="font-normal text-fs-body text-secondary line-clamp-1">{title}</p>}
    </div>
  );
};

export default Bit;
