export const colors = {
  //GRAY-SCALE
  black100: '#171619',
  black70: 'rgba(23, 22, 25, 0.7)',
  black90: 'rgba(23, 22, 25, 0.9)',
  black50: 'rgba(23, 22, 25, 0.5)',
  black42: 'rgba(23, 22, 25, 0.42)',
  black40: 'rgba(0, 0, 0, 0.40)',
  black30: 'rgba(23, 22, 25, 0.3)',
  black20: 'rgba(23, 22, 25, 0.2)',
  black10: 'rgba(23, 22, 25, 0.1)',
  black05: 'rgba(23, 22, 25, 0.05)',
  black06: 'rgba(23, 22, 25, 0.06)',
  black00: 'rgba(23, 22, 25, 0.0)',

  //WHITE-SCALE
  white100: '#FFFFFF',
  white90: 'rgba(255, 255, 255, 0.9)',
  white70: 'rgba(255, 255, 255, 0.7)',
  white50: 'rgba(255, 255, 255, 0.5)',
  white42: 'rgba(255, 255, 255, 0.42)',
  white30: 'rgba(255, 255, 255, 0.3)',
  white20: 'rgba(255, 255, 255, 0.2)',
  white10: 'rgba(255, 255, 255, 0.1)',
  white06: 'rgba(255, 255, 255, 0.06)',
  white05: 'rgba(255, 255, 255, 0.05)',
  white00: 'rgba(255, 255, 255, 0.0)',

  //SUNSET-Scale
  sunset100: 'rgba(233, 95, 42, 1)',
  sunset90: 'rgba(233, 95, 42, 0.9)',
  sunset70: 'rgba(233, 95, 42, 0.7)',
  sunset50: 'rgba(233, 95, 42, 0.5)',
  sunset30: 'rgba(233, 95, 42, 0.3)',
  sunset10: 'rgba(233, 95, 42, 0.1)',
  sunset12: 'rgba(233, 95, 42, 0.12)',
  sunset05: 'rgba(233, 95, 42, 0.05)',
  sunset00: 'rgba(233, 95, 42, 0.0)',

  //THEMING-COLORS
  //BRANDS
  purple: '#480986',
  moonlight: '#9A53DA',
  darkPurple: '#351362',
  sunset: '#E95F2A',
  sunsetText: '#E84E13',
  silver: '#9199A0',
  silverLight: 'rgba(145, 153, 160, 0.3)',
  coral: '#EB4B62',
  coralLight: '#F1A0AF',
  coralSuperLight: '#F9D9DF',
  sun: '#FADF60',
  sunLight: '#FCEFAA',
  sunSuperLight: '#FEF7DC',
  sea: '#61CBD6',
  seaLight: '#A1E4E9',
  seaSuperLight: '#D8F5F6',
  lightBlue: '#2388FF',
  lightGray: '#DEE2E7',
  whiteSmoke: '#F1F1F1',

  //UI FEEDBACK
  red: '#E03030',
  redB: '#F26262',
  redC: '#FBE6E6',
  red20: '#FED2D2',
  red10: 'rgba(248, 30, 30, 0.1)',
  yellow: '#FFC52F',
  yellowA: '#FFF1CD',
  yellow20: 'rgba(255, 197, 47, 0.2)',
  yellow10: 'rgba(255, 197, 47, 0.1)',
  green: '#108715',
  greenA: '#9BC425',
  greenB: '#119E30',
  greenC: '#E2F1E3',
  greenD: '#46AE4B',
  green20: 'rgba(42, 201, 77, 0.2)',
  green10: 'rgba(42, 201, 77, 0.1)',
  blue: '#2388FF',
  blue20: 'rgba(35, 136, 255, 0.2)',
  blue10: 'rgba(35, 136, 255, 0.1)',
  gray: '#B9B9BA',
  gray05: '#F3F3F3',
  gray20: '#17161933',
  gray2005: 'rgba(23, 22, 25, 0.10)',
  gray30: '#1716194D',
  gray42: 'rgba(23, 22, 25, 0.42)',
  gray50: '#17161980',
  gray70: '#171619B2',
  gray90: '#5D5C5E',
  grayD: '#BEB48C',
  darkGray: '#222224',
  otherGray: '#8B8A8C',
  surfaceGray: '#E8E8E8',
  surfaceGrey05: '#232225',
  tertiary: '#A2A2A3',
  pink: '#FF375E',
  desertBrown: '#a18853',
  grayM: '#8A8A8B',
  gray40: 'rgba(23, 22, 25, 0.4)',
  muesli: '#A18853',
  warningOrange: '#EFAC00',

  // ORDER STATUS CUSTOM COLOR
  lightPurple: '#F7F1FC',
  purple40: '#9A53DA66',

  lightGreen: '#ecf5ec',
  green40: '#10871566',

  //OTHERS
  lightSunset: '#FBE5DD',
  darkSunset: '#49281D',

  sparklingRed: '#f43030',
  royalIndigo: '#4F3E60',
  'neutral-800': '#232225',

  //MEDALS
  'amber-300': '#F5C556',
  'gray-300': '#D4C6E3',
  'amber-800': '#9B541F',

  //MATCH EWC
  firstPlace: '#D0C3A9',
  secondPlace: '#CCCCCC',
  thirdPlace: '#EEEEEE',

  //RANK GRAPH
  dark_gold: '#AF4000',
  dark_silver: '#293346',
  dark_bronze: '#4C2302',

  //STRANGER SCREEN
  purpleBG: '#4F008C',

  // GRADEINT RANKS
  Rank1Gradient: ['#AF4000', '#FFE68C00'],
  Rank2Gradient: ['#293346', '#D2E0F400'],
  Rank3Gradient: ['#4C2302', '#D7A96800'],
  //gradient
  garyGradient1: 'rgba(53, 52, 53, 1)',
  grayGradient2: 'rgba(35, 34, 37, 1)',
  black1: 'rgba(0,0,0,1)',
  black0: 'rgba(0,0,0,0)',
  pink1: 'rgba(72, 9, 134, 1)',
  pink2: 'rgba(31, 4, 57, 0.43)',

  // rank border colors - schedule
  first: '#A18853',
  second: '#999999',
  third: '#DDDDDD',

  playerMinTrack: '#E95F2A',
  playerMaxTrack: '#ffc584',

  light: {
    colors: {
      primary: '#FFFFFF',
      'primary-100': '#FFFFFF',
      secondary: '#171619',
      'brand-primary': '#480986',
      'brand-secondary': '#FFFFFF',
      brand: '#F4F4F4',
      play: '#351362',
      'btn-primary': '#E95F2A',
      'static-primary': '#171619',
      'static-secondary': '#0F0E10',
      'secondary-100': '#0F0E10',
      error: '#E03030',
      outline: '#9199A0',
      'neutral-400-zinc-500': '#A2A2A3', //neutral-400 eg. tournament User ID - @userID
      'zinc-600-400': '#5D5C5E', //zinc-600 eg. tournament Game ID & Full name
      'zinc-100-neutral-800': '#F3F3F3', //zinc-100 eg. tournament empty participants gray bg
      'neutral-300-zinc-700': '#D1D0D1', //neutral-300 eg. tournament avatar border
      'zinc-800-gray-200': '#E8E8E8', //gray-200 eg. tournament participants list border
      'gray-200-zinc-700': '#E8E8E8',
      'neutral-900-white': '#171619',
      'gray-200-neutral-800': '#E8E8E8',
      'gray-200-zinc-800': '#E8E8E8', //gray-200 eg. tournament participants list border
      'gray-200-zinc-600': '#E8E8E8',
      'neutral-400-zinc-700': '#A2A2A3',
      tail: 'rgba(23, 22, 25)/0.05',
      button: 'rgba(23, 22, 25, 0.1)',
      'button-text': 'rgba(255, 255, 255, 0.3)',
      'black-white': '#000',
      onyx: '#1F252C',
    },
  },
  dark: {
    colors: {
      primary: '#171619',
      'primary-100': '#0F0E10',
      secondary: '#FFFFFF',
      'secondary-100': '#FFFFFF',
      'brand-primary': '#FFFFFF',
      'brand-secondary': '#351362',
      brand: '#480986',
      play: '#171619',
      'btn-primary': '#E95F2A',
      'static-primary': '#171619',
      'static-secondary': '#0F0E10',
      error: '#E03030',
      outline: '#9199A0',
      'neutral-400-zinc-500': '#8B8A8C', //zinc-500
      'zinc-600-400': '#B9B9BA', //zinc-400
      'zinc-100-neutral-800': '#232225', //neutral-800
      'neutral-300-zinc-700': '#454547', //zinc-700
      'zinc-800-gray-200': '#2E2D30', //zinc-800
      'gray-200-zinc-700': '#454547',
      'neutral-900-white': '#FFFFFF',
      'gray-200-neutral-800': '#232225',
      'gray-200-zinc-800': '#2E2D30', //zinc-800
      'gray-200-zinc-600': '#4F3E60',
      'neutral-400-zinc-700': '#454547',
      tail: 'rgba(255, 255, 255)',
      button: '#2E2D30',
      'button-text': 'rgba(255, 255, 255, 0.3)',
      'black-white': '#fff',
      onyx: '#FFF',
    },
  },
  gradientDarkGray: '#6f6c75',
  gradientLightGray: '#b5b3ba',
};

export default colors;
