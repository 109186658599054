import CommentIcon from '../../Icons/Comment';
import { formatLargeNumber } from '../../helpers';

export default function CommentButton({ setCommentExpand, setShowComments, video, videoCommentCount, videoControls, videoRef }: any) {
  return (
    (video?.commentEnabled || video?.commentEnabled === true) && (
      <button
        className="comment_icon"
        title="Comments"
        onClick={() => {
          setCommentExpand && setCommentExpand((prev: boolean) => !prev);
          setShowComments && setShowComments((prev: boolean) => !prev);
        }}
      >
        <div className="icon">
          <CommentIcon />
        </div>
        <div className="icon-count comment_count">{formatLargeNumber(videoCommentCount || 0)}</div>
      </button>
    )
  );
}
