// NavBar.tsx
import { useTranslate } from '@fe-monorepo/hooks';
import React, { useLayoutEffect, useRef, useState } from 'react';

interface NavBarProps {
  onTabChange: (tabName: string) => void;
  OrdersValue: number;
  ReturnsValue: number | undefined;
  activeTab: string;
}

const NavBar: React.FC<NavBarProps> = ({ onTabChange, OrdersValue, ReturnsValue, activeTab }) => {
  const ordersRef = useRef<HTMLDivElement>(null);
  const returnsRef = useRef<HTMLDivElement>(null);
  const [underlineStyle, setUnderlineStyle] = useState({});
  const { translate } = useTranslate();

  const handleTabClick = (tabName: string) => {
    onTabChange(tabName);
  };

  useLayoutEffect(() => {
    if (activeTab === 'Orders' && ordersRef.current) {
      setUnderlineStyle({ width: ordersRef.current.offsetWidth, left: ordersRef.current.offsetLeft });
    } else if (activeTab === 'Returns' && returnsRef.current) {
      setUnderlineStyle({ width: returnsRef.current.offsetWidth, left: returnsRef.current.offsetLeft });
    }
  }, [activeTab]);

  return (
    <div className="flex flex-row py-[20px] 2K:py-[30px] 4K:py-[50px] 8K:py-[100px] items-center gap-[20px] 2K:gap-[30px] 4K:gap-[50px] 8K:gap-[100px] border-b-[0.0625rem] border-secondary/25 bg-primary relative">
      <div
        ref={ordersRef}
        onClick={() => handleTabClick('Orders')}
        className={`cursor-pointer text-fs-body-small ${
          activeTab === 'Orders' ? 'font-medium text-secondary' : 'font-regular text-secondary/40'
        }`}
      >
        {OrdersValue && OrdersValue > 0 ? translate('orders', { value: OrdersValue }) : translate('ordersWithoutValue')}
      </div>
      <div
        ref={returnsRef}
        onClick={() => handleTabClick('Returns')}
        className={`cursor-pointer text-fs-body-small ${
          activeTab === 'Returns' ? 'font-medium text-secondary' : 'font-regular text-secondary/40'
        }`}
      >
        {ReturnsValue && ReturnsValue > 0 ? translate('returns', { value: ReturnsValue }) : translate('returnsWithoutValue')}
      </div>
      <div style={underlineStyle} className="absolute bottom-0 h-[0.125rem] bg-secondary transition-all duration-500 ease-in-out" />
    </div>
  );
};

export default NavBar;
