import PlaceHolderImage from '@fe-web/Atoms/Skeleton/Placeholders/Image';
import Skeleton from '@fe-web/Atoms/Skeleton/Skeleton';
import useGetCurrentBreakPoint from '@fe-web/hooks/useGetCurrentBreakPoint/useGetCurrentBreakPoint';

export const StaticBannerSkeleton = () => {
  const { currentBreakPoint } = useGetCurrentBreakPoint();
  const isMobile = currentBreakPoint === 'mobile';

  return (
    <div>
      <Skeleton
        height="h-full"
        width="w-[100% - 2.5rem]"
        className={` overflow-hidden justify-center items-center
             mx-[1.25rem] 
             md:w-auto w-[100% - 2.5rem] ${isMobile ? 'aspect-[1/1]' : 'aspect-[4/1]'}`}
      >
        <PlaceHolderImage svgStyle="w-[64px] h-[64px] fill-primary" />
      </Skeleton>
    </div>
  );
};
