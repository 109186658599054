import { IconNames } from '@fe-monorepo/helper';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { useNavigate } from 'react-router-dom';

interface GeneralErrorProps {
  icon: {
    iconid: IconNames;
    bw: number;
    bh: number;
    classname: string;
    container_classname?: string;
  };
  body_text: {
    error_text_header: string | null;
    error_text: string | null;
    error_text_advise: string | null;
    haserroradvise: boolean;
    error_text_advise_cta: {
      cta_text: string | null;
      cta_nav: string;
    };
  };
  container_button_class?: string;
  button_1?: {
    button_text: string | null;
    style: string;
    normalstyle?: string;
    classname?: string;
    onclick: () => void;
  };
  button_2?: {
    button_text: string | null;
    style: string;
    normalstyle?: string;
    classname?: string;
    onclick: () => void;
  };
  close?: () => void;
  classname?: string;
}

const GeneralError = ({ icon, body_text, button_1, button_2, close, classname, container_button_class }: GeneralErrorProps) => {
  const navigate = useNavigate();
  return (
    <div
      className={`absolute top-1/4 flex flex-col justify-between text-secondary bg-primary border border-neutral-300-zinc-700 rounded-[.5rem] gap-[1.25rem] p-[1.5rem] flex:[1_0_0] SD:p-[3rem] SD:w-[30rem] mdxs:w-[7rem] mdMaxxS:w-[25rem]  ${classname}`}
    >
      <div className="flex flex-col gap-[2rem]">
        <div className={`flex flex-row justify-end ${icon.container_classname}`}>
          <ResponsiveIcon name={icon.iconid} baseWidth={icon.bw} baseHeight={icon.bh} className={icon.classname} />
          <ResponsiveIcon
            name={IconNames.close}
            baseWidth={20}
            baseHeight={20}
            className="fill-secondary hover:cursor-pointer"
            onClick={close}
          />
        </div>
        <div className="flex flex-col gap-[.75rem]">
          <div>
            <p className="font-medium text-fs-title">{body_text?.error_text_header}</p>
          </div>
          <div className="flex flex-col gap-[.75rem] text-fs-body font-regular">
            <p className="text-secondary text-opacity-[0.70]">{body_text?.error_text}</p>
            <p className={`${body_text.haserroradvise ? 'text-secondary text-opacity-[0.70]' : 'hidden'}`}>
              {body_text?.error_text_advise}{' '}
              <span className="text-sunset hover:cursor-pointer" onClick={() => navigate(body_text.error_text_advise_cta.cta_nav)}>
                {body_text?.error_text_advise_cta.cta_text}
              </span>{' '}
            </p>
          </div>
        </div>
      </div>
      <div className={`flex flex-col gap-[1rem] self-stretch SD:flex-row ${container_button_class}`}>
        <Button
          text={button_1?.button_text}
          style={button_1?.style}
          normalStyle={button_1?.normalstyle}
          className={button_1?.normalstyle}
          onClick={button_1?.onclick}
        />
        <Button
          text={button_2?.button_text}
          style={button_2?.style}
          normalStyle={button_2?.normalstyle}
          className={button_2?.classname}
          onClick={button_2?.onclick}
        />
      </div>
    </div>
  );
};

export default GeneralError;
