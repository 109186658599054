import { PayloadAction, createSlice } from '@reduxjs/toolkit';


export type AppStateSliceState = {
  videoInfo: any;
};

export const quickPlaySlice = createSlice({
  name: 'quickPlayState',
  initialState: {
    videoInfo: null,
  } as AppStateSliceState,
  reducers: {
    setQPVideoInfo: (state, action: PayloadAction<boolean>) => {
      state.videoInfo = action.payload;
    },
  },
});

export const {
    setQPVideoInfo,
} = quickPlaySlice.actions;
