import { Helmet } from 'react-helmet-async';

interface Props {
  pageTitle?: string;
  canonicalURL?: string;
  metaDescription?: string;
  metaKeyWords?: string;
}

const CustomHelmet = ({ pageTitle, canonicalURL, metaDescription, metaKeyWords }: Props) => {
  return (
    <Helmet>
      {canonicalURL && <link rel="canonical" href={canonicalURL} />}
      {pageTitle && <title>{pageTitle}</title>}
      {metaDescription && <meta name="description" content={metaDescription} />}
      {metaKeyWords && <meta name="keywords" content={metaKeyWords} />}
    </Helmet>
  );
};

export default CustomHelmet;
