import { gql } from '@apollo/client';

export const LMD_QUERY = {
  getAllLmd: gql`
    query fetchLMD {
      getAllLmd {
        error_msg
        error_code
        is_successful
        data {
          region
          city
          lmd_code
        }
      }
    }
  `,

  checkStoreDistance: gql`
    query checkStoreDistance($details: CheckStoreDistanceInput) {
      checkStoreDistance(details: $details) {
        error_msg
        error_code
        is_successful
      }
    }
  `,
};
