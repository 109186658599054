import { RootState, setRecentSearchOpen, setRecentSearchRevalidateData } from '@fe-monorepo/store';
import { useDispatch, useSelector } from 'react-redux';

const useRecentSearch = () => {
  const dispatch = useDispatch();
  const recentSearchIsOpen = useSelector((state: RootState) => state.app.recentSearchIsOpen);
  const recentSearchRevalidateData = useSelector((state: RootState) => state.app.recentSearchRevalidateData);

  return {
    isOpen: recentSearchIsOpen,
    setIsOpen: (value: boolean) => dispatch(setRecentSearchOpen(value)),
    revalidateData: recentSearchRevalidateData,
    setRevalidateData: (value: boolean) => dispatch(setRecentSearchRevalidateData(value)),
  };
};

export default useRecentSearch;
