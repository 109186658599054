import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { WishlistData } from '@fe-monorepo/models';

export const wishlistSlice = createSlice({
    name: 'wishlist',
    initialState: {
        wishlist: [] as WishlistData[],
        wishlistItem: '',
    },
    reducers: {
        insertAllWishlist: (state, action: PayloadAction<WishlistData[]>) => {
            state.wishlist = action.payload;
        },
        insertSelectedWishlist: (state, action: PayloadAction<string>) => {
            state.wishlistItem = action.payload;
        },

        updateWishlist: (state, action: PayloadAction<WishlistData>) => {
            state.wishlist = [...state.wishlist.filter(recentWishlist => recentWishlist.product_code != action.payload.product_code), action.payload];
        },
        removeAllWishlist: (state) => {
            state.wishlist = [];
        },
        removeSelectedWishlist: (state) => {
            state.wishlistItem = '';
        },
    }
});

export const { insertAllWishlist, insertSelectedWishlist, updateWishlist, removeAllWishlist, removeSelectedWishlist } = wishlistSlice.actions;
