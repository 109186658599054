import { RootState } from '@fe-monorepo/store';
import { forwardRef } from 'react';
import { useSelector } from 'react-redux';

import Button from './Button';

interface Props {
  className?: string;
  disabledStyle?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  text: string;
  action: () => void;
}

const PrimaryButton = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const isRTL = useSelector((state: RootState) => state.app.isRTL);

  const dir = isRTL ? 'rtl' : 'ltr';
  const { className, isDisabled, isLoading, text, action } = props;

  return (
    <Button
      ref={ref}
      text={text}
      action={action}
      style={`${className} responsive-text-body font-medium text-white100 border-transparent border-[0.0625rem] disabled:bg-secondary/[0.1]`}
      normalStyle={`${
        dir === 'ltr' ? 'after:bg-sunset before:bg-white100' : 'before:bg-sunset after:bg-white100'
      } cursor-pointer hover:border-[0.0625rem] hover:border-sunset hover:text-sunsetText hover:text-sunsetText`}
      disabledStyle={props?.disabledStyle}
      disabled={isDisabled}
      isLoading={isLoading}
    />
  );
});

export default PrimaryButton;
