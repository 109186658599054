import { GOOGLE_CONFIG } from '@fe-monorepo/helper';
import axios from 'axios';
import { useState } from 'react';

import { useTranslate } from '../useTranslate';

interface LongLat {
  location_lat: number;
  location_long: number;
}

export function useGoogleMaps() {
  const [address, setAddress] = useState<string>('');
  const [fullAddress, setFullAddress] = useState<any>();
  const [addressError, setAddressError] = useState<string>();
  const [longLat, setLongLat] = useState<LongLat>();
  const { translate } = useTranslate();

  const getReverseGeocode = async (latitude: number, longitude: number) => {
    const errorAddress = translate('Address.address_not_found');
    try {
      const response = await axios.get(
        `${GOOGLE_CONFIG.googleAPIGeoEncoding}${latitude},${longitude}&key=${GOOGLE_CONFIG.googlePlaceAPIKey}`,
      );
      if (response.data.results.length > 0) {
        const address = response.data.results[0].formatted_address;
        setFullAddress(response.data.results[0].address_components);
        setAddress(address);
        return address;
      } else {
        if (errorAddress) {
          setAddressError(errorAddress);
        }
      }
    } catch (error) {
      if (errorAddress) {
        setAddressError(errorAddress);
      }
      return translate('Address.address_not_found');
    }
  };

  const getPlusCode = async (code: string) => {
    const errorAddress = translate('Address.address_not_found');
    try {
      const response = await axios.get(
        `${GOOGLE_CONFIG.googleAPIPlusCode}${encodeURIComponent(code)}&ekey=${GOOGLE_CONFIG.googlePlaceAPIKey}`,
      );

      if (response.data.status === 'ZERO_RESULTS') {
        if (errorAddress) {
          setAddressError(errorAddress);
        }
        return translate('Address.address_not_found');
      }
      if (response.data.plus_code) {
        const geometry = response.data.plus_code.geometry;
        setLongLat({
          location_lat: geometry.location.lat,
          location_long: geometry.location.lng,
        });
        getReverseGeocode(geometry.location.lat, geometry.location.lng);
      } else if (errorAddress) {
        setAddressError(errorAddress);
      }
    } catch (error) {
      if (errorAddress) {
        setAddressError(errorAddress);
      }
      return translate('Address.address_not_found');
    }
  };

  return {
    getReverseGeocode,
    getPlusCode,
    fullAddress,
    address,
    addressError,
    longLat,
  };
}
