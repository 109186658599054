import React from "react";

function VideoWaitIcon() {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="60" cy="60" r="60" fill="#232225" />
      <path
        d="M49.3332 82.333H70.6665V74.333C70.6665 71.3997 69.6221 68.8886 67.5332 66.7997C65.4443 64.7108 62.9332 63.6663 59.9998 63.6663C57.0665 63.6663 54.5554 64.7108 52.4665 66.7997C50.3776 68.8886 49.3332 71.3997 49.3332 74.333V82.333ZM59.9998 58.333C62.9332 58.333 65.4443 57.2886 67.5332 55.1997C69.6221 53.1108 70.6665 50.5997 70.6665 47.6663V39.6663H49.3332V47.6663C49.3332 50.5997 50.3776 53.1108 52.4665 55.1997C54.5554 57.2886 57.0665 58.333 59.9998 58.333ZM38.6665 87.6663V82.333H43.9998V74.333C43.9998 71.6219 44.6332 69.0775 45.8998 66.6997C47.1665 64.3219 48.9332 62.4219 51.1998 60.9997C48.9332 59.5775 47.1665 57.6775 45.8998 55.2997C44.6332 52.9219 43.9998 50.3775 43.9998 47.6663V39.6663H38.6665V34.333H81.3332V39.6663H75.9998V47.6663C75.9998 50.3775 75.3665 52.9219 74.0998 55.2997C72.8332 57.6775 71.0665 59.5775 68.7998 60.9997C71.0665 62.4219 72.8332 64.3219 74.0998 66.6997C75.3665 69.0775 75.9998 71.6219 75.9998 74.333V82.333H81.3332V87.6663H38.6665Z"
        fill="#5D5C5E"
      />
    </svg>
  );
}

export default VideoWaitIcon;
