import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';

export function MaintenancePage() {
  const { translate } = useTranslate();
  return (
    <div className="w-screen h-screen flex flex-col bg-primary">
      <div className="flex-1 flex flex-col gap-[4rem] items-center text-center justify-center">
        <ResponsiveIcon
          name={IconNames.stcPlay}
          baseWidth={267.2}
          baseHeight={106}
          iconClasses="max-w-screen max-h-screen w-[16.7rem] h-[6.625rem] fill-purple dark:fill-white100"
        />
        <div className="flex flex-col gap-[2rem] md:gap-[2.5rem]">
          <div className="flex flex-col gap-[0.5rem] md:gap-[1rem] items-center">
            <p className="font-medium text-fs-title text-black-white max-w-[26rem]">{translate('error_503')}</p>
            <p className="text-zinc-600-400 max-w-[18rem] font-regular text-fs-body-small">{translate('error_503_description')}</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MaintenancePage;
