import { useTranslate } from '@fe-monorepo/hooks';
import { GetAllMiniGamesData, TmsGetAllMiniGamesDetails } from '@fe-monorepo/models';
import Spinner from '@fe-web/Atoms/Load/Spinner';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';

import MiniGameCard from './MiniGameCard';
import SectionTitle from './SectionTitle';

interface Props {
  miniGameList: GetAllMiniGamesData | undefined;
  title: string;
  showCount?: boolean;
  isLoading?: boolean;
  isSimilarGames?: boolean;
  handleLoadMore?: (last_cursor: number) => void;
}

const AllMiniGames = ({ miniGameList, title, showCount = true, isLoading, handleLoadMore, isSimilarGames = false }: Props) => {
  const [miniGames, setMiniGames] = useState<TmsGetAllMiniGamesDetails[]>([]);
  const [sortedMiniGames, setSortedMiniGames] = useState<TmsGetAllMiniGamesDetails[]>([]);
  const [isMomentumReachEnd, setMomentumReachEndStatus] = useState<boolean>(false);
  const [showLoadingText, setShowLoadingText] = useState<boolean>(true);
  const [lastCursor, setLastCursor] = useState<number>(0);
  const sentinelRef = useRef(null);
  const { translate } = useTranslate();
  const { miniGameID } = useParams();

  useEffect(() => {
    if (!miniGameList) return;
    if (miniGameList.data.data.length > 0) {
      const gamesList = miniGameList.data.data;
      setMiniGames(game => [...game, ...(gamesList || [])]);
    } else if (miniGames && miniGames.length > 0 && miniGameList.data.data.length === 0) {
      setLastCursor(miniGames[miniGames.length - 1]?.last_cursor);
      setMomentumReachEndStatus(true);
    }
  }, [miniGameList]);

  useEffect(() => {
    if (miniGames.length > 0) {
      const sortedMiniGames = miniGames?.sort((a, b) => (a.sort_id > b.sort_id ? 1 : -1));
      setSortedMiniGames(sortedMiniGames);
    }
  }, [miniGames]);

  useEffect(() => {
    // loads new data if reach ref element
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isMomentumReachEnd) {
            setShowLoadingText(true);
            if (lastCursor !== 0 && lastCursor === miniGames[miniGames.length - 1]?.last_cursor) {
              //stop load when end
              setMomentumReachEndStatus(true);
              setShowLoadingText(false);
            }
            if (miniGames && miniGames.length > 0 && lastCursor !== miniGames[miniGames.length - 1]?.last_cursor) {
              //sets last cursor for pagination then load more
              setLastCursor(miniGames[miniGames.length - 1]?.last_cursor);
              handleLoadMore?.(miniGames[miniGames.length - 1]?.last_cursor);
            }
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [isMomentumReachEnd, miniGames]);

  useEffect(() => {
    //removes loading text
    setTimeout(() => {
      setShowLoadingText(false);
    }, 3000);
  }, [showLoadingText]);

  return (
    <div className="inline-flex flex-col gap-[2rem]">
      <SectionTitle>{title}</SectionTitle>
      {isLoading ? (
        <span>
          <Spinner className="text-secondary" />
        </span>
      ) : (
        <div className="inline-flex flex-row flex-wrap gap-[1rem] SD:gap-[1.4rem]">
          {isSimilarGames
            ? sortedMiniGames
                ?.filter(game => game.mini_game_code !== miniGameID)
                ?.map(game => <MiniGameCard key={game.mini_game_code} gameData={game} />)
            : sortedMiniGames?.map(game => <MiniGameCard key={game.mini_game_code} gameData={game} />)}
        </div>
      )}
      {sortedMiniGames && sortedMiniGames?.length > 0 && (
        <div className="text-secondary w-full flex flex-col col-span-4 justify-center items-center" ref={sentinelRef}>
          {showLoadingText && <span className="text-fs-body-large font-regular">{translate('common_loading') ?? 'Loading'}...</span>}
        </div>
      )}
    </div>
  );
};

export default AllMiniGames;
