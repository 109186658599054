import { IconNames } from '@fe-monorepo/helper';
import { motion } from 'framer-motion';
import { t } from 'i18next';

import ResponsiveIcon from '../../../Atoms/Icon/ResponsiveIcon';

interface ShippingInfoProps {
  classNames?: string;
}

const ShippingInfo = (props: ShippingInfoProps) => {
  const { classNames } = props;
  return (
    <motion.div
      className="whitespace-nowrap flex items-center gap-8 2K:gap-[14.22px] 4K:gap-[21.33px] 8K:gap-[42.66px]"
      initial={{ opacity: 0 }}
      animate={{
        display: 'flex',
        position: 'relative',
        opacity: [0, 1],
        transition: { duration: 1 },
      }}
      exit={{
        opacity: 0,
        transition: { duration: 0 },
      }}
    >
      <ResponsiveIcon className="fill-secondary" name={IconNames.shipping} baseHeight={20} baseWidth={20} />

      <p className={`font-regular text-secondary ${classNames} 2K:text-subtitle 4K:text-bigTitle 8K:text-huge`}>{t('shop.freeDelivery')}</p>
    </motion.div>
  );
};

export default ShippingInfo;
