import { RootState } from '@fe-monorepo/store';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';

import LocalizedText from '../../../../../../../../lib/types/localizedText';
import { StyledProductDetailsContent } from './ProductDetails.styled';

export interface ProductDetailsProps {
  title: string;
  content?: LocalizedText;
  disclaimer?: LocalizedText;
  image?: string;
}

const ProductDetails = ({ title, content, disclaimer, image }: ProductDetailsProps) => {
  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  const renderContent = DOMPurify.sanitize((isRTL ? content?.ar : content?.en) || '');
  return (
    <div className="SD:grid SD:grid-cols-4 mt-[40px] 2K:mt-[60px] 4K:mt-[80px] 8K:mt-[160px]">
      <h2 className="text-fs-big-title font-medium text-secondary mb-24 col-span-4">{title}</h2>
      {content && <StyledProductDetailsContent>{parse(renderContent)}</StyledProductDetailsContent>}
      {image && <LazyLoadImage src={image} className="w-full SD:col-span-4" />}
    </div>
  );
};

export default ProductDetails;
