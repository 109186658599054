import React, { useEffect, useState } from 'react';

import ZoomIn from '../VerticalPlayer/Icons/ZoomIn';
import ZoomOut from '../VerticalPlayer/Icons/ZoomOut';

interface FullScreenComponentProps {
  playerRef: React.RefObject<any>;
}

const documentWithFullscreen = document as Document & {
  exitFullscreen?: () => Promise<void>;
  mozCancelFullScreen?: () => Promise<void>;
  webkitExitFullscreen?: () => Promise<void>;
  msExitFullscreen?: () => Promise<void>;
};

const FullScreenMode: React.FC<FullScreenComponentProps> = ({ playerRef }) => {
  const [fullScreen, setFullScreen] = useState(false);

  useEffect(() => {
    const handleFullScreenChange = () => {
      setFullScreen(!!document.fullscreenElement);
    };

    document.addEventListener('fullscreenchange', handleFullScreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullScreenChange);
    document.addEventListener('mozfullscreenchange', handleFullScreenChange);
    document.addEventListener('MSFullscreenChange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
      document.removeEventListener('webkitfullscreenchange', handleFullScreenChange);
      document.removeEventListener('mozfullscreenchange', handleFullScreenChange);
      document.removeEventListener('MSFullscreenChange', handleFullScreenChange);
    };
  }, []);

  const toggleFullScreen = () => {
    if (document.fullscreenElement) {
      // Exit fullscreen
      if (documentWithFullscreen.exitFullscreen) {
        documentWithFullscreen.exitFullscreen();
      } else if (documentWithFullscreen.mozCancelFullScreen) {
        documentWithFullscreen.mozCancelFullScreen();
      } else if (documentWithFullscreen.webkitExitFullscreen) {
        documentWithFullscreen.webkitExitFullscreen();
      } else if (documentWithFullscreen.msExitFullscreen) {
        documentWithFullscreen.msExitFullscreen();
      }
      setFullScreen(false);
    } else {
      // Enter fullscreen
      if (playerRef.current) {
        if (playerRef.current.requestFullscreen) {
          playerRef.current.requestFullscreen();
        } else if (playerRef.current.mozRequestFullScreen) {
          playerRef.current.mozRequestFullScreen();
        } else if (playerRef.current.webkitRequestFullscreen) {
          playerRef.current.webkitRequestFullscreen();
        } else if (playerRef.current.msRequestFullscreen) {
          playerRef.current.msRequestFullscreen();
        }
        setFullScreen(true);
      }
    }
  };

  return (
    <button className="zoom_button" onClick={toggleFullScreen}>
      {fullScreen ? <ZoomOut /> : <ZoomIn />}
    </button>
  );
};

export default FullScreenMode;
