import { EventType } from '@fe-monorepo/models';

type NotificationFilterType = {
  id: string;
  title: string;
};

const statusFilter: NotificationFilterType[] = [
  {
    id: 'read',
    title: 'connect.read',
  },
  {
    id: 'unread',
    title: 'connect.unread',
  },
];

const featureFilter: NotificationFilterType[] = [
  // {
  //   id: 'bits',
  //   title: 'top_tab_bits',
  // },
  // {
  //     id: 'streams',
  //     title: 'top_tab_streams'
  // },
  {
    id: 'tournament',
    title: 'top_tab_tournaments',
  },
  {
    id: 'minigames',
    title: 'search_type_games',
  },
  {
    id: 'matchmaking',
    title: 'super_plus_matchmaking',
  },
  {
    id: 'chat',
    title: 'action_chat',
  },
  {
    id: 'shop',
    title: 'pageTitle_shop',
  },
  {
    id: 'profile',
    title: 'profile.account',
  },
  {
    id: 'qp',
    title: 'quickplay_bits',
  },
  // {
  //   id: 'others',
  //   title: 'notifications.others',
  // },
];

const periodFilter: NotificationFilterType[] = [
  {
    id: 'new',
    title: 'tabTitle_new',
  },
  {
    id: 'last7days',
    title: 'last_seven_days',
  },
  {
    id: 'last30days',
    title: 'last_thirty_days',
  },
  {
    id: 'older',
    title: 'older',
  },
];

const shopEventArray = [
  EventType.orderSingle,
  EventType.orderMultiple,
  EventType.returnSingle,
  EventType.returnMultiple,
  EventType.plp,
  EventType.pdp,
  EventType.orderDispatched,
  EventType.orderShipmentDispatched,
  EventType.returnPlaced,
  EventType.returnDispatched,
  EventType.returnDelivered,
  EventType.returnShipmentPlaced,
  EventType.returnShipmentActionRequired,
  EventType.returnShipmentDispatched,
  EventType.returnShipmentCompleted,
];

const accountEventArray = [
  EventType.chat,
  EventType.chatPrivateTextSent,
  EventType.chatPrivateImageSent,
  EventType.chatPrivateVideoSent,
  EventType.chatPrivateVideoShared,
  EventType.chatGroupTextSent,
  EventType.chatGroupTextReceived,
  EventType.chatGroupImageSent,
  EventType.chatGroupVideoSent,
  EventType.chatGroupLeft,
  EventType.chatGroupUpdate,
];

const tournamentEventArray: EventType[] = [
  EventType.minigamesUpdate,
  EventType.minigamesNewUser,
  EventType.minigamesReturningUser,
  EventType.minigamesHighscoreSet,
  EventType.minigamesHighscoreBeaten,
  EventType.minigamesDone,
  EventType.minigamesOtherGames,
  EventType.matchmakingPreferences,
  EventType.matchmakingFound,
  EventType.matchmakingSearch,
  EventType.tournamentDetails,
  EventType.tournamentCheckinOpen,
  EventType.tournamentCheckinMissed,
  EventType.tournamentCheckinClosed,
  EventType.tournamentScoreReported,
  EventType.tournamentEnded,
  EventType.tournamentCancelled,
  EventType.tournamentList,
];

const bitsEventArray: EventType[] = [EventType.bits];

const chatEventCheck = [
  EventType.chat,
  EventType.chatPrivateTextSent,
  EventType.chatPrivateImageSent,
  EventType.chatPrivateVideoSent,
  EventType.chatPrivateVideoShared,
  EventType.chatGroupTextSent,
  EventType.chatGroupTextReceived,
  EventType.chatGroupImageSent,
  EventType.chatGroupVideoSent,
  EventType.chatGroupUpdate,
  EventType.chatGroupLeft,
];

const eventCheck1 = [
  EventType.orderSingle,
  EventType.orderMultiple,
  EventType.returnSingle,
  EventType.returnMultiple,
  EventType.plp,
  EventType.pdp,
  EventType.orderDispatched,
  EventType.orderShipmentDispatched,
  EventType.returnPlaced,
  EventType.returnDispatched,
  EventType.returnDelivered,
  EventType.returnRejected,
  EventType.returnCompleted,
  EventType.returnItemPlaced,
  EventType.returnItemActionRequired,
  EventType.returnItemDispatched,
  EventType.returnItemCompleted,
  EventType.returnItemReject,
  EventType.returnItemClosed,
  EventType.orderDelivered,
  EventType.orderPlaced,
  EventType.orderDeliveredShop,
  EventType.orderItemPlaced,
  EventType.orderItemDispatched,
  EventType.orderItemDelivered,
];

const eventCheck2 = [
  EventType.minigamesUpdate,
  EventType.minigamesNewUser,
  EventType.minigamesReturningUser,
  EventType.minigamesHighscoreSet,
  EventType.minigamesHighscoreBeaten,
  EventType.minigamesDone,
  EventType.minigamesOtherGames,
  EventType.minigamesNewGames,
  EventType.matchmakingPreferences,
  EventType.matchmakingFound,
  EventType.matchmakingSearch,
  EventType.tournamentDetails,
  EventType.tournamentCheckinOpen,
  EventType.tournamentCheckinMissed,
  EventType.tournamentCheckinClosed,
  EventType.tournamentScoreReported,
  EventType.tournamentEnded,
  EventType.tournamentCancelled,
  EventType.tournamentList,
];

const eventCheck3 = [EventType.profileUserFollowed, EventType.matchmakingUserFollowed];

const qpNotifications = [EventType.qpLike, EventType.qpMention, EventType.qpReply, EventType.qpComment, EventType.qpModeration];

export {
  NotificationFilterType,
  accountEventArray,
  bitsEventArray,
  chatEventCheck,
  eventCheck1,
  eventCheck2,
  eventCheck3,
  featureFilter,
  periodFilter,
  qpNotifications,
  shopEventArray,
  statusFilter,
  tournamentEventArray,
};
