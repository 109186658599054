import { gql } from '@apollo/client';

export const CONTENT_QUERY = {
  getStaticPage: gql`
    query fetchStaticPage($details: ContentInput) {
      getStaticPage(details: $details) {
        error_msg
        error_code
        app_code
        is_successful
        data {
          page_code
          content_en
          content_ar
          created_at
          updated_at
        }
      }
    }
  `,

  getFaqCategories: gql`
    query fetchFaqCategories {
      getFaqCategories {
        error_msg
        error_code
        is_successful
        data {
          category_id
          name_en
          name_ar
          icon
          category_code
          metadata
        }
      }
    }
  `,

  getFAQs: gql`
    query fetchFAQsPage($details: ContentInput) {
      getFaqs(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          question_ar
          question_en
          answer_en
          answer_ar
          faq_id
        }
      }
    }
  `,

  getShopLanding: gql`
    query fetchShopLanding {
      getShopLanding {
        error_msg
        error_code
        is_successful
        data {
          section_type
          reference_type
          item_info {
            menu_code
            name_en
            name_ar
            products_list {
              product_code
              featured_img
              brand_id
              name_ar
              name_en
              metadata
              lowest_actual_cost
              highest_actual_cost
              lowest_selling_price
              highest_selling_price
              brand {
                name_en
                name_ar
                collection_code
                collection_id
              }
            }
            menu_items {
              menu_code
              name_ar
              name_en
              media
            }
          }
          banner_info {
            name_en
            name_ar
            banner_type
            reference_type
            carousel_type
            reference_value
            web_media_url_ar
            web_media_url_en
            mobile_media_url_ar
            mobile_media_url_en
            banner_items {
              name_en
              name_ar
              reference_type
              reference_value
              web_media_url_ar
              web_media_url_en
              mobile_media_url_ar
              mobile_media_url_en
            }
            expire_on
          }
        }
      }
    }
  `,
};
