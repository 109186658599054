import { IMAGES } from '@fe-monorepo/assets';
import { useTranslate } from '@fe-monorepo/hooks';
import SignInStranger from '@fe-web/Molecules/SignInStranger';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const GuestMatchmaking = () => {
  const { translate } = useTranslate();
  return (
    <div className="flex flex-col justify-center gap-[2.5rem] mt-[2.5rem] SD:w-[37rem]">
      <div className="flex flex-col SD:items-center gap-[0.5rem]">
        <h1 className="font-medium text-fs-big-title text-secondary">{translate(`play.matchmaking.guest_header`)}</h1>
        <p className=" font-regular text-fs-body-small text-zinc-600-400">{translate(`play.matchmaking.guest_caption`)}</p>
      </div>
      <div>
        <SignInStranger
          header={translate('play.matchmaking.stranger_header') ?? ''}
          caption={translate('play.matchmaking.stranger_caption') ?? ''}
          button={translate('action_signin') ?? ''}
        />
      </div>
      <div className="flex items-center justify-center">
        <LazyLoadImage src={IMAGES.MatchmakingGuestImage.toString()} className="object-contain w-[28.15rem] h-[12.56rem]" />
      </div>
    </div>
  );
};

export default GuestMatchmaking;
