import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import useOutsideClick from '@fe-web/hooks/useOutsideClick';
import { MutableRefObject, ReactNode, useEffect, useRef, useState } from 'react';
import { isFirefox, isSafari } from 'react-device-detect';
import { PopoverAlign, PopoverPosition, Popover as TinyPopover } from 'react-tiny-popover';

interface PopoverAbstractProps {
  children: ReactNode;
  disabled?: boolean;
  isIconDot?: boolean;
  buttonClassName?: string;
  buttonRef?: MutableRefObject<HTMLButtonElement> | undefined;
  containerClassName?: string;
  onClickMoreOption?: () => void;
  isOpenCall?: (value: boolean) => void;
  cta?: ReactNode;
  iconBaseWidth?: number;
  iconBaseHeight?: number;
  position?: PopoverPosition[];
  align?: PopoverAlign;
  padding?: string;
  iconClassName?: string;
}

const PopoverAbstract = ({
  children,
  disabled,
  isIconDot = true,
  buttonClassName,
  buttonRef,
  containerClassName,
  onClickMoreOption,
  isOpenCall,
  cta,
  iconBaseHeight,
  iconBaseWidth,
  position,
  align,
  padding,
  iconClassName,
}: PopoverAbstractProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const popOverRef = useOutsideClick(() => {
    setIsOpen(false);
  });

  const handleOnClick = () => {
    if (!isOpen) {
      onClickMoreOption && onClickMoreOption();
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const currentRef = contentRef?.current?.parentElement;
    if (currentRef && isOpen) {
      const boundingClientRect = currentRef?.getBoundingClientRect();
      const yAdjustments = boundingClientRect?.y + boundingClientRect.height - window.innerHeight;
      const xAdjustments = boundingClientRect?.x + boundingClientRect.width - window.innerWidth;
      if (yAdjustments > 0) {
        currentRef.style.top = `${-yAdjustments}px`;
      }
      if (xAdjustments > 0) {
        currentRef.style.left = `${-xAdjustments}px`;
      }
    }
    return () => {
      if (!currentRef) return;
      currentRef.style.top = '0px';
      currentRef.style.left = '0px';
    };
  }, [contentRef?.current]);

  useEffect(() => {
    isOpenCall && isOpenCall(isOpen);
  }, [isOpen]);

  return (
    <TinyPopover
      ref={newRef => (popOverRef.current = newRef)}
      isOpen={isOpen}
      positions={position || ['bottom', 'top']}
      reposition={true}
      containerClassName={`${padding ?? ''} bg-primary border-[0.0625rem] border-secondary/[0.2] rounded-[0.25rem]`}
      content={<div ref={contentRef}>{children}</div>}
      align={align ?? 'start'}
      containerStyle={{
        top: isFirefox || isSafari ? '151px' : '',
        left: isFirefox || isSafari ? '-190px' : '',
      }}
    >
      <div
        className={containerClassName ? containerClassName : 'flex flex-row gap-[.25rem] w-full'}
        onClick={e => {
          e.stopPropagation();
          handleOnClick();
        }}
      >
        {cta && cta}
        <button type="button" className={buttonClassName ?? 'relative'} disabled={disabled} ref={buttonRef}>
          {isIconDot ? (
            <ResponsiveIcon
              name={IconNames.optionsRegular}
              baseHeight={iconBaseHeight ?? 24}
              baseWidth={iconBaseWidth ?? 24}
              iconClasses={`${iconClassName} hover:fill-zinc-400 dark:hover:fill-zinc-200 fill-zinc-600-400 rotate-90`}
            />
          ) : (
            <ResponsiveIcon
              name={IconNames.chevron}
              baseWidth={16}
              baseHeight={16}
              iconClasses={`${isOpen ? 'rotate-180' : ''} ${'transition-all fill-neutral-400-zinc-500 hover:fill-secondary'}`}
              className="cursor-pointer"
            />
          )}
        </button>
      </div>
    </TinyPopover>
  );
};

export default PopoverAbstract;
