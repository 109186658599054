import { CaptionedImage, Card, ImageOnly, requestContentCardsRefresh, subscribeToContentCardsUpdates } from '@braze/web-sdk';
import { BrazeFeedType } from 'libs/models/src/lib/braze/types';
import { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface BrazeContentContextValue {
  homePageBrazeCarouselCards: Card[] | CaptionedImage[];
  homePageBrazeStaticBanner: Card | ImageOnly | null;
  shopPageBrazeStaticBanner: Card | ImageOnly | null;
  miniGamesPageBrazeStaticBanner: Card | ImageOnly | null;
  homePageBrazeSliders: Card[] | ImageOnly[];
  shopPageBrazeSliders: Card[] | ImageOnly[];
}

interface BrazeContentProps {
  children: ReactNode;
}

const filterAndSortBrazeCardsByFeedType = (cards: Card[], feedType: BrazeFeedType): Card[] => {
  return cards
    .filter(card => card.extras?.feed_type === feedType)
    .sort((a, b) => {
      const sortA = a.extras?.sort_id ? Number(a.extras.sort_id) : 0;
      const sortB = b.extras?.sort_id ? Number(b.extras.sort_id) : 0;
      return sortA - sortB;
    });
};

const BrazeContentContext = createContext<BrazeContentContextValue | undefined>(undefined);

export const BrazeContentProvider = ({ children }: BrazeContentProps) => {
  const [homePageBrazeCarouselCards, setHomePageBrazeCarouselCards] = useState<Card[] | CaptionedImage[]>([]);
  const [homePageBrazeStaticBanner, setHomePageBrazeStaticBanner] = useState<Card | ImageOnly | null>(null);
  const [shopPageBrazeStaticBanner, setShopPageBrazeStaticBanner] = useState<Card | ImageOnly | null>(null);
  const [miniGamesPageBrazeStaticBanner, setMiniGamesPageBrazeStaticBanner] = useState<Card | ImageOnly | null>(null);
  const [homePageBrazeSliders, setHomePageBrazeSliders] = useState<Card[] | ImageOnly[]>([]);
  const [shopPageBrazeSliders, setShopPageBrazeSliders] = useState<Card[] | ImageOnly[]>([]);

  useEffect(() => {
    const handleContentCardsUpdate = (event: { cards: Card[] }) => {
      if (event?.cards?.length) {
        const homePageCarouselCards = filterAndSortBrazeCardsByFeedType(event.cards, 'home_page_carousel');
        const homePageStaticBannerCards = filterAndSortBrazeCardsByFeedType(event.cards, 'home_page_static_banner');
        const shopPageStaticBannerCards = filterAndSortBrazeCardsByFeedType(event.cards, 'shop_page_static_banner');
        const miniGamesPageStaticBannerCards = filterAndSortBrazeCardsByFeedType(event.cards, 'mini_games_page_static_banner');
        const homePageSliderCards = filterAndSortBrazeCardsByFeedType(event.cards, 'home_page_slider');
        const shopPageSliderCards = filterAndSortBrazeCardsByFeedType(event.cards, 'shop_page_slider');

        setHomePageBrazeCarouselCards(homePageCarouselCards);
        setHomePageBrazeStaticBanner(homePageStaticBannerCards?.[0] || null);
        setShopPageBrazeStaticBanner(shopPageStaticBannerCards?.[0] || null);
        setMiniGamesPageBrazeStaticBanner(miniGamesPageStaticBannerCards?.[0] || null);
        setHomePageBrazeSliders(homePageSliderCards);
        setShopPageBrazeSliders(shopPageSliderCards);
      }
    };

    subscribeToContentCardsUpdates(handleContentCardsUpdate);
    requestContentCardsRefresh();
  }, []);

  return (
    <BrazeContentContext.Provider
      value={{
        homePageBrazeCarouselCards,
        homePageBrazeStaticBanner,
        shopPageBrazeStaticBanner,
        miniGamesPageBrazeStaticBanner,
        homePageBrazeSliders,
        shopPageBrazeSliders,
      }}
    >
      {children}
    </BrazeContentContext.Provider>
  );
};

export const useBrazeContent = () => {
  const context = useContext(BrazeContentContext);
  if (!context) {
    throw new Error('useBrazeContent must be used within a BrazeContentProvider');
  }
  return context;
};
