import {
  ApolloClient,
  ApolloLink,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  NextLink,
  NormalizedCacheObject,
  Operation,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { checkForSessionExpiry } from '@fe-monorepo/helper';
import { PageErrorTypes, store } from '@fe-monorepo/store';
import fetch from 'cross-fetch';
import dayjs from 'dayjs';
import { Platform } from 'react-native';

import { getEnvironment } from './apiEnvironments';

export let error: string | undefined;

interface ApolloClientInstance {
  instance?: ApolloClient<NormalizedCacheObject>;
  upload?: ApolloClient<NormalizedCacheObject>;
}

const clientSingleton: ApolloClientInstance = {
  instance: undefined,
  upload: undefined,
};

const { baseUrl, apiKey } = getEnvironment();
const httpLink = new HttpLink({ uri: baseUrl, fetch });

const authMiddleware = new ApolloLink((operation: Operation, forward: NextLink) => {
  const { token } = store.getState().user.userContext;
  const { authToken } = operation.getContext();
  operation.setContext({
    headers: {
      authorization: authToken,
      'x-api-token': token,
      'x-api-lang': store.getState().app.language,
      'x-api-version': '427', // is this the build version //reply: yes
      'x-api-key': apiKey,
      'x-api-endpoint': Platform.OS,
      'x-request-time': dayjs().toISOString(),
      device_token: store.getState().app.fingerPrint,
      'apollo-require-preflight': true,
    },
  });
  return forward(operation);
});

const defaultOptions: DefaultOptions = {
  // note that useLazyQuery ignores these "global" defaults so the errorPolicy must be added to each useLazyQuery
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
};

export const apolloClientUpload = (onPageError?: (error: any, isNetworkError?: boolean) => void): ApolloClient<NormalizedCacheObject> => {
  const errorLink = onError(({ graphQLErrors, networkError, response, operation }) => {
    const operationName = operation?.operationName;
    if (graphQLErrors) {
      graphQLErrors.forEach(({ extensions }) => {
        if (!error && extensions?.error_code) {
          error = '' + extensions?.error_code;
          onPageError?.(error);
        }
      });
    }
    if (networkError) {
      onPageError?.(null, true);
    }
    if (operationName === 'fetchUserData' && response && response.data && checkForSessionExpiry(response.data)) {
      if (typeof onPageError === 'function') {
        onPageError?.(PageErrorTypes.SESSION_EXPIRED);
      }
    }
  });

  const responseCheckLink = new ApolloLink((operation: Operation, forward: NextLink) => {
    return forward(operation).map(response => {
      const operationName = operation?.operationName;
      if (operationName === 'fetchUserData' && response.data && checkForSessionExpiry(response.data)) {
        if (typeof onPageError === 'function') {
          onPageError?.(PageErrorTypes.SESSION_EXPIRED);
        }
      }
      return response;
    });
  });

  if (!clientSingleton.upload) {
    clientSingleton.upload = new ApolloClient({
      link: ApolloLink.from([errorLink, responseCheckLink, authMiddleware.concat(httpLink)]),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      cache: new InMemoryCache({ addTypename: false }),
      credentials: 'include',
      defaultOptions: defaultOptions,
    });
  }
  return clientSingleton.upload;
};
