import { useMutation } from '@apollo/client';
import { PREFERENCE_UPDATE_MUTATION } from '@fe-monorepo/data-access';
import { UserModel } from '@fe-monorepo/models';
import { setUser } from '@fe-monorepo/store';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { PreferenceUpdateParamsModel } from './type';

export const usePreferenceUpdate = () => {
  const [isSuccessful, setStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [apiParams, setAPIprams] = useState<PreferenceUpdateParamsModel>();
  const dispatch = useDispatch();

  const [preferenceUpdate, { data: preferenceUpdateResponse, error: errorPreferenceUpdate }] = useMutation(
    PREFERENCE_UPDATE_MUTATION.preferenceUpdate,
    { errorPolicy: 'all' },
  );

  useEffect(() => {
    if (preferenceUpdateResponse) {
      if (!preferenceUpdateResponse.preferenceUpdate) {
        return;
      }

      if (preferenceUpdateResponse.preferenceUpdate.is_successful) {
        setStatus(preferenceUpdateResponse.preferenceUpdate.is_successful);
      }
      if (preferenceUpdateResponse.preferenceUpdate.error_msg) {
        setErrorMessage(preferenceUpdateResponse.preferenceUpdate.error_msg);
      }
      if (preferenceUpdateResponse.preferenceUpdate.is_successful && apiParams) {
        if (apiParams.preferred_timezone) {
          dispatch(
            setUser({
              preferred_timezone: apiParams.preferred_timezone,
            } as UserModel),
          );
        }
        if (apiParams.preferred_currency) {
          dispatch(
            setUser({
              preferred_currency: apiParams.preferred_currency,
            } as UserModel),
          );
        }
        if (apiParams.preferred_language) {
          dispatch(
            setUser({
              preferred_language: apiParams.preferred_language,
            } as UserModel),
          );
        }
        if (apiParams.preferred_theme) {
          dispatch(
            setUser({
              preferred_theme: apiParams.preferred_theme,
            } as UserModel),
          );
        }
        if (apiParams.preferred_country) {
          dispatch(
            setUser({
              preferred_country: apiParams.preferred_country,
            } as UserModel),
          );
        }
        if (apiParams.is_matchmaking_enabled) {
          dispatch(
            setUser({
              is_matchmaking_enabled: apiParams.is_matchmaking_enabled,
            } as UserModel),
          );
        }
        if (apiParams.is_2FA_required === 1 || apiParams.is_2FA_required === 0) {
          dispatch(
            setUser({
              is_2FA_required: apiParams.is_2FA_required ?? 0,
            } as UserModel),
          );
        }
        setStatus(preferenceUpdateResponse.preferenceUpdate.is_successful);
      }
    }
    setIsLoading(false);
  }, [preferenceUpdateResponse]);

  useEffect(() => {
    if (errorPreferenceUpdate) {
      setError(errorPreferenceUpdate.message);
    }
    setIsLoading(false);
  }, [errorPreferenceUpdate]);

  const updateUserPreference = (apiParams: PreferenceUpdateParamsModel) => {
    setError('');
    preferenceUpdate({ variables: { details: apiParams } });
    setIsLoading(true);
    setAPIprams(apiParams);
  };

  const updateUserPreferences = async (apiParams: PreferenceUpdateParamsModel) => {
    let result;
    try {
      setError('');
      setIsLoading(true);
      setAPIprams(apiParams);
      result = await preferenceUpdate({ variables: { details: apiParams } });
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
    return result;
  };

  const resetStatus = () => {
    setStatus(false);
    setErrorMessage('');
  };

  return {
    updateUserPreference,
    updateUserPreferences,
    isSuccessful,
    isLoading,
    error,
    errorMessage,
    resetStatus,
  };
};
