import { useEffect, useState } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import { TMS_STAGE_QUERY } from '@fe-monorepo/data-access';
import { TmsTournamentStageGetAllInput, TmsTournamentStageResponse } from './type';

export const useStage = () => {
    const [isGetAllLoading, setIsGetAllLoading] = useState<boolean>();
    const [getAllGql, { data: getAllData }] = useLazyQuery<TmsTournamentStageResponse>(TMS_STAGE_QUERY.tmsStageGetAll, {
        errorPolicy: 'all',
    });

    const getAll = async (details: TmsTournamentStageGetAllInput) => {
        setIsGetAllLoading(true);
        const { data } = await getAllGql({ variables: { details } });
        setIsGetAllLoading(false);
        return data;
    };

    return { getAll, getAllData: getAllData?.tmsStageGetAll?.data, isGetAllLoading };
};
