import { safelyParseJSON } from '@fe-monorepo/helper';
import { Platform } from 'react-native';

const getAPIKey = () => {
  if (Platform.OS === 'android') {
    return process.env.NX_API_ANDROID_KEY;
  } else if (Platform.OS === 'ios') {
    return process.env.NX_API_IOS_KEY;
  }
  return process.env.NX_API_WEB_KEY;
};

const getFirebaseConfig = () => {
  if (Platform.OS === 'android') {
    return safelyParseJSON(process.env.NX_FIREBASE_ANDROID_CONFIG ?? '');
  } else if (Platform.OS === 'ios') {
    return safelyParseJSON(process.env.NX_FIREBASE_IOS_CONFIG ?? '');
  }
  return safelyParseJSON(process.env.NX_FIREBASE_WEB_CONFIG ?? '');
};

export const getEnvironment = () => {
  const baseUrl = process.env.NX_BFF_BASE_URL ?? '';
  const apiKey = getAPIKey();
  const websiteBaseURL = process.env.NX_WEBSITE_BASE_URL ?? '';
  const envCode = process.env.NX_ENV ?? '';
  const firebaseConfig = getFirebaseConfig();
  const mixPanelToken = process.env.NX_MIXPANEL_TOKEN ?? '';
  const convivaAppTracker = {
    appname: Platform.OS === 'ios' ? 'STCPLAY_IOS' : 'STCPLAY_ANDROID',
    customerKey: process.env.NX_CONVIVA_CUSTOMER_KEY ?? '',
  };
  const tournamentsURL = process.env.NX_TOURNAMENTS_URL ?? '';
  const quickplayAPIKey = process.env.NX_QUICKPLAY_API_KEY ?? '';
  const quickplaySecretKey = process.env.NX_QUICKPLAY_SECRET_KEY ?? '';
  const quickplayBaseURL = process.env.NX_QUICKPLAY_BASE_URL ?? '';

  return {
    baseUrl,
    apiKey,
    envCode,
    firebaseConfig,
    websiteBaseURL,
    mixPanelToken,
    convivaAppTracker,
    tournamentsURL,
    quickplayAPIKey,
    quickplaySecretKey,
    quickplayBaseURL,
  };
};
