import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { AddressInput, DeliveryAddressModel } from '@fe-monorepo/models';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import AddressOption from '@fe-web/Molecules/AddressOption';

const RecipientsDetails = ({
  addresses,
  selectedAddress,
  editLabel,
  deleteLabel,
  selectAddress,
  onClickNewAddress,
  onClickEditAddress,
  onClickDeleteAddress,
}: {
  addresses: DeliveryAddressModel[];
  selectedAddress?: DeliveryAddressModel;
  editLabel?: string;
  deleteLabel?: string;
  selectAddress: (address: DeliveryAddressModel) => void;
  onClickNewAddress?: () => void;
  onClickEditAddress?: (id: number) => void;
  onClickDeleteAddress?: (address: AddressInput) => void;
}) => {
  const { translate } = useTranslate();
  const handleDeleteAddress = (address: DeliveryAddressModel) => {
    if (onClickDeleteAddress) {
      const payload = {
        // name: address.name,
        address_id: address.user_address_id,
        // mobile: address.mobile,
        // mobile_code: address.mobile_code
      };
      onClickDeleteAddress(payload);
    }
  };
  return (
    <div className="flex flex-col gap-y-[1rem] mt-[1.5rem]">
      {addresses?.map((currentAddress, key) => {
        const { user_address_id, label } = currentAddress;
        return (
          <AddressOption
            key={key}
            address={currentAddress}
            isActive={user_address_id === selectedAddress?.user_address_id}
            label={label}
            editLabel={editLabel}
            deleteLabel={deleteLabel}
            onClickEdit={() => onClickEditAddress?.(user_address_id || -1)}
            onClickDelete={() => handleDeleteAddress?.(currentAddress)}
            selectAddress={() => selectAddress(currentAddress)}
          />
        );
      })}

      <button
        type="button"
        className={`flex items-center gap-x-[0.625rem] text-sunset text-body font-medium ${
          addresses?.length ? 'text-left' : 'text-center p-[1.688rem] border border-sunset  rounded-[0.125rem] max-w-[24rem]'
        }`}
        onClick={onClickNewAddress}
      >
        <ResponsiveIcon name={IconNames.add} baseWidth={20} baseHeight={20} iconClasses="stroke-sunset" />
        <span className="text-fs-body-small">{translate('shop.cart.checkout_add_new_address')}</span>
      </button>
    </div>
  );
};

export default RecipientsDetails;
