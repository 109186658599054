import { gql } from '@apollo/client';

export const REGISTER_DEVICE_MUTATION = {
  registerDevice: gql`
    mutation registerDevice($details: RegisterInput) {
      registerDevice(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          device_token
          os_name
          os_version
          device_name
          device_brand
          device_model
        }
      }
    }
  `,
};
