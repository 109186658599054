import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import QualityPopUp from './Qualitypopup';
import SeekBar from './SeekBar';

interface CustomDataType {
  [key: string]: any;
}
const times: CustomDataType = {
  year: 31557600,
  month: 2629746,
  day: 86400,
  hour: 3600,
};
const transformDuration = (seconds: number): string => {
  if (!seconds) {
    return '0:00';
  }
  let timeString = '';
  for (const key in times) {
    if (Math.floor(seconds / times[key]) > 0) {
      timeString += `${Math.floor(seconds / times[key]).toString()}:`;
      seconds -= times[key] * Math.floor(seconds / times[key]);
    }
  }

  if (Math.floor(seconds / 60) < 10) {
    timeString += '0';
  }

  timeString += `${Math.floor(seconds / 60).toString()}:`;
  seconds -= 60 * Math.floor(seconds / 60);
  if (Math.floor(seconds) < 10) {
    timeString += '0';
  }
  timeString += Math.floor(seconds).toString();
  return timeString;
};

const VideoPlayerControllers = ({
  playerObject,
  onLockControler,
  controllerLock,
  contentTitle,
  handleQuality,
  volume,
  setVolume,
  screen,
  toggleFullScreen,
  openQuality,
  showController,
  selectedQuality,
  setSelectedQuality,
  convivaService,
  isFullScreen,
  backHandler,
}: any) => {
  const [currentTime, setCurrentTime] = useState(playerObject?.currentEpochTime);
  const [isSeeking, setIsSeeking] = useState(false);
  const [progress, setProgress] = useState(0);
  const [minutesValue, setMinutesValue] = useState<string | null>(null);
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const [isPlaying, setIsPlaying] = useState<boolean>(true);
  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  async function handleSeek() {
    const current = playerObject?.currentTime;
    const duration = playerObject?.duration;
    const progressPercentage = (current / duration) * 100;
    setProgress(progressPercentage);
    setCurrentTime(playerObject?.currentTime);
  }

  useEffect(() => {
    playerObject?.subscribe('progressupdate', handleSeek);
    return () => {
      playerObject?.unsubscribe('progressupdate', handleSeek);
    };
  }, [isSeeking]);

  const onPlayHandler = () => {
    if (!isPlaying) {
      playerObject.play();
    } else {
      playerObject.pause();
    }
  };

  playerObject.subscribe('playbackstatechanged', (state: string) => {
    switch (state) {
      case 'idle':
        //handle idle state.
        break;
      case 'loading':
        //handle loading state.
        break;
      case 'loaded':
        setIsPlaying(false);
        break;
      case 'started':
        setIsPlaying(true);
        break;
      case 'paused':
        setIsPlaying(false);
        break;
    }
  });

  const onBackHandler = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch(err => {
        console.error(err);
      });
    }
    playerObject.stop();
    convivaService.destroy();
    navigate(-1);
  };

  function formatTime(seconds: number) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = Math.floor(seconds % 60);

    let remainingTime =
      !hours && !minutes && remainingSeconds < 4 ? translate('ewc.Player.Live') : `-${hours}:${minutes}:${remainingSeconds}`;

    setMinutesValue(remainingTime);
  }

  useEffect(() => {
    let seekableRange = playerObject?.seekableRange()?.end - playerObject?.seekableRange()?.start;
    const getCurrentTime = playerObject.currentEpochTime - playerObject.seekableRange().start;
    let differenceTime = seekableRange - getCurrentTime;

    formatTime(differenceTime);
  }, [playerObject?.currentEpochTime]);

  const handleChange = (event: any) => {
    playerObject?.pause();

    let seekableRange = playerObject?.seekableRange()?.end - playerObject?.seekableRange()?.start;
    const computedSeekpoint = (seekableRange * event.target.value) / 100;
    let seekPoint = playerObject.seekableRange().start + computedSeekpoint;

    playerObject?.seek(seekPoint);
    playerObject?.play();
  };

  useEffect(() => {
    playerObject.volume = volume ? 1 : 0;
  }, [volume]);

  return (
    <div className="absolute z-10 w-full h-full flex justify-between items-center flex-col bg-black-40 p-10 md:p-20">
      {openQuality ? (
        <QualityPopUp
          onClose={handleQuality}
          playerObject={playerObject}
          selectedQuality={selectedQuality}
          setSelectedQuality={setSelectedQuality}
        />
      ) : (
        <>
          <div className="flex justify-between w-full items-center">
            <div className={`flex items-center gap-10 ${!showController && 'invisible'}   rounded-full`}>
              <span
                onClick={backHandler ? () => backHandler({ playerObject, convivaService }) : onBackHandler}
                className={`cursor-pointer ${isRTL ? 'rotate-180deg' : 'rotate-0deg'} ${
                  !document.fullscreenElement && !isFullScreen && 'hidden'
                }`}
              >
                <ResponsiveIcon
                  className="fill-primary"
                  name={IconNames.leftArrow}
                  baseHeight={28}
                  baseWidth={28}
                  mobileSize={{ height: 18, width: 18 }}
                />
              </span>
              <span className="text-white">
                {contentTitle}
                {playerObject?.isLive && <span className="text-white bg-red px-4 py-2">{translate('ewc.Player.Live')}</span>}
              </span>
            </div>

            <div className={`flex items-center justify-between gap-18 rounded-full`}>
              <span onClick={onLockControler} className={`cursor-pointer ${!showController && !controllerLock && 'invisible'}`}>
                <ResponsiveIcon
                  className="fill-primary"
                  name={controllerLock ? IconNames.unLockScreen : IconNames.lockScreen}
                  baseHeight={28}
                  baseWidth={28}
                  mobileSize={{ height: 18, width: 18 }}
                />
              </span>
            </div>
          </div>
          {showController && (
            <div className="w-full items-center flex relative gap-10 justify-between">
              <div className="flex items-center gap-16">
                <span onClick={onPlayHandler} className={`cursor-pointer`}>
                  <ResponsiveIcon
                    className="fill-primary"
                    name={isPlaying ? IconNames.videoPauseSymbolOutline : IconNames.videoPlaySymbolOutline}
                    baseHeight={28}
                    baseWidth={28}
                    mobileSize={{ height: 18, width: 18 }}
                  />
                </span>

                <div className="flex flex-col items-center">
                  <div style={{ position: 'relative' }}>
                    <span
                      className="rounded-[1.875rem] flex justify-center items-center cursor-pointer"
                      onClick={() => {
                        setVolume(!volume);
                      }}
                    >
                      <ResponsiveIcon
                        className="fill-primary"
                        name={!volume ? IconNames.speakerMuteWhite : IconNames.speakerOutline}
                        baseHeight={28}
                        baseWidth={28}
                        mobileSize={{ height: 18, width: 18 }}
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="w-[calc(100%-80px)]">
                <SeekBar
                  value={
                    Math.ceil(
                      ((Math.floor(playerObject?.currentEpochTime - playerObject?.seekableRange().start) /
                        Math.floor(playerObject?.seekableRange().end - playerObject?.seekableRange().start)) *
                        100) /
                        5,
                    ) * 5
                  }
                  handleChange={handleChange}
                  minutesValue={minutesValue}
                />
              </div>
              <div className="flex items-center gap-16">
                <div
                  className={`flex justify-center items-center cursor-pointer ${!showController && 'invisible'}`}
                  onClick={handleQuality}
                >
                  <span>
                    <ResponsiveIcon
                      className="fill-primary"
                      name={IconNames.qualityIcon}
                      baseHeight={28}
                      baseWidth={28}
                      mobileSize={{ height: 18, width: 18 }}
                    />
                  </span>
                </div>
                <span onClick={toggleFullScreen} className={`cursor-pointer ${!showController && 'hidden'}`}>
                  <ResponsiveIcon
                    className="fill-primary"
                    name={screen ? IconNames.exitscreenSDK : IconNames.fullscreenSDK}
                    baseHeight={28}
                    baseWidth={28}
                    mobileSize={{ height: 18, width: 18 }}
                  />
                </span>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default VideoPlayerControllers;
