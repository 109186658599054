import { IMAGES } from '@fe-monorepo/assets';
import {
  EventObject,
  IconNames,
  PAGE_DESCRIPTION,
  PAGE_KEYWORDS,
  PAGE_TITLE,
  TxKeyPath,
  searchResultsTabEventMapping,
} from '@fe-monorepo/helper';
import { SearchResults, useGlobalSearchNew, useSearchHistory, useTranslate } from '@fe-monorepo/hooks';
import { ContentModel, SearchData, SearchModel, ShopModel, TabListProps, UserSearchModel } from '@fe-monorepo/models';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { DiscoverVideoListResponse, RootHashtag, UserVideo } from '@fe-web/types/bitTypes';
import { AxiosResponse } from 'axios';
import quickplay from 'quickplay-shorts-js';
import { useCallback, useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { useLocation } from 'react-router-dom';

import { webAnalytics } from '../../../helper/webAnalytics';
import EmptyState from '../components/Atoms/Empty/EmptyState';
import ResponsiveIcon from '../components/Atoms/Icon/ResponsiveIcon';
import Container from '../components/Templates/Container';
import useRecentSearch from '../hooks/useRecentSearch';
import HashtagResult from './Search/Bit/HashtagResult/HashtagResult';
import SearchBitResult from './Search/Bit/SearchBitResult';
import SearchHeader from './Search/SearchHeader';
import SearchShopResult from './Search/Shop/SearchShopResult';
import SearchUserResult from './Search/User/SearchUserResult';

const searchEvent = (searchTerm: string, results: number) => {
  const searchEvent: EventObject = {
    name: 'site_search',
    properties: {
      search_term: searchTerm,
      results,
    },
  };
  webAnalytics(searchEvent);
};

const tabs: TabListProps[] = [
  {
    key: 'all',
    translate: 'tob_tab_all',
    count: 0,
    resultCount: 0,
  },
  {
    key: 'bit',
    translate: 'top_tab_bits',
    count: 0,
    resultCount: 0,
  },
  {
    key: 'shop',
    translate: 'shop.value',
    count: 0,
    resultCount: 0,
  },
  {
    key: 'user',
    translate: 'top_tab_users',
    count: 0,
    resultCount: 0,
  },
];

const useSearchResults = <T,>() => {
  const [results, setResults] = useState<SearchData<T>>({
    total: 0,
    result: [],
    paginate: true,
  });

  const receiveResults = (searchResults: SearchResults<T> | undefined): number => {
    if (searchResults) {
      const { result: data, total } = searchResults;
      setResults({
        result: data,
        total: total,
        paginate: true,
      });
      return total;
    }
    return 0;
  };
  return {
    results,
    receiveResults,
  };
};

const useTabData = () => {
  const [selected, setSelected] = useState<SearchTab>('all');
  const [data, setData] = useState<ContentModel[]>([]);
  const [paginate, setPaginate] = useState<boolean>(true);

  const appendData = (newData: ContentModel[]) => {
    if (!newData || newData.length === 0) {
      setPaginate(false);
      return;
    }
    if (selected === 'all') {
      return;
    } else {
      setData(oldData => [...oldData, ...newData]);
    }
  };

  const reset = () => {
    setData([]);
    setPaginate(true);
  };

  useEffect(() => {
    reset();
  }, [selected]);

  return {
    selected,
    setSelected,
    data,
    appendData,
    paginate,
    reset,
  };
};

export type SearchTab = 'all' | 'tournament' | 'user' | 'stream' | 'bit' | 'shop';

const SearchPage = () => {
  const bitsLimit = 18;

  const { globalSearch, allSearchInfo } = useGlobalSearchNew();

  const location = useLocation();
  const [totalResult, setTotalResult] = useState<number>();
  const shop = useSearchResults<ShopModel>();
  const users = useSearchResults<UserSearchModel>();
  const [bitsVideos, setBitsVideos] = useState<UserVideo[]>([]);
  const [hashtags, setHashtags] = useState<RootHashtag[]>([]);
  const [isLoadingBitsVideos, setIsLoadingBitsVideos] = useState<boolean>(false);
  const [totalBitsVideo, setTotalBitsVideo] = useState<number>(0);
  const [bitsPage, setBitsPage] = useState<number>(1);
  const [hasMoreBits, setHasMoreBits] = useState<boolean>(true);
  const tab = useTabData();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [tabsList, setTabsList] = useState<TabListProps[]>(tabs);
  const [isLoadingMore, setIsLoadingMore] = useState<boolean>(false);
  const { setIsOpen } = useRecentSearch();
  const { setRevalidateData } = useRecentSearch();
  const { translate } = useTranslate();
  const isMobile = useMobileDetect();
  const saveSearch = useSearchHistory();
  const searchParams = new URLSearchParams(location.search);
  const searchValue = searchParams.get('q') ?? '';
  const isFetching = globalSearch.isLoading;

  const fetchBitSearchResults = useCallback(
    async (searchParam: string, newPage?: number) => {
      setIsLoadingBitsVideos(true);
      try {
        const quickPlayDiscover = new quickplay.QuickPlayDiscover();
        const response: AxiosResponse<DiscoverVideoListResponse> = await quickPlayDiscover.getDiscoverAllVideos({
          search: searchParam,
          limit: bitsLimit,
          offset: newPage ?? bitsPage,
        });
        if (response.status === 200) {
          const videoList = response.data.result.videos;
          const hashtagList = response.data.result.hashtags;
          const totalBitsVideo = response.data.total;
          setBitsVideos(prevVideos => [...prevVideos, ...videoList]);
          setHashtags(hashtagList);
          setTotalBitsVideo(totalBitsVideo);
          setHasMoreBits(videoList.length === bitsLimit);
        } else {
          console.error(`Error: Received status code ${response.status}`);
        }
      } catch (error: any) {
        console.error(`Error: ${error.message || error.statusMessage}`);
      } finally {
        setIsLoadingBitsVideos(false);
      }
    },
    [bitsPage, searchValue],
  );

  const loadMore = () => {
    if (tab.paginate && allSearchInfo.data?.length > 0 && !isLoading && !isLoadingMore) {
      loadTabData(true);
    }
  };

  const loadMoreBits = useCallback(() => {
    if (!isLoadingBitsVideos && hasMoreBits && searchValue) {
      setBitsPage(prevPage => {
        const newPage = prevPage + 1;
        fetchBitSearchResults(searchValue, newPage);
        return newPage;
      });
    }
  }, [fetchBitSearchResults, hasMoreBits, isLoadingBitsVideos, searchValue]);

  const loadTabData = async (isPagination?: boolean) => {
    setIsLoadingMore(true);
    allSearchInfo.fetch({
      type: tab.selected,
      direction: 'next',
      identifier: searchValue,
      pagination: isPagination ? allSearchInfo.data[allSearchInfo.data.length - 1]?.pagination : null,
    } as SearchModel);
    if (allSearchInfo.error && ['stream', 'tournament'].includes(tab.selected)) {
      tab.reset();
      setIsLoadingMore(false);
    }
  };

  useEffect(() => {
    setBitsPage(1);
    setHasMoreBits(true);
    setBitsVideos([]);
    fetchBitSearchResults(searchValue, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  useEffect(() => {
    tab.reset();
    if (searchValue) {
      if (tab.selected !== 'all') {
        loadTabData();
        tab.setSelected('all');
      }
      setIsLoading(true);
      globalSearch.fetch({ identifier: searchValue, name: '', search_id: 0 });
      saveSearch.saveHistory(searchValue);
    }
  }, [searchValue]);

  useEffect(() => {
    if (isFetching || isLoadingBitsVideos) {
      return;
    }
    let totalNumber = 0;
    totalNumber += totalBitsVideo;
    totalNumber += shop.receiveResults(globalSearch.data?.shop);
    totalNumber += users.receiveResults({ ...globalSearch.data?.users, total: globalSearch.data?.users?.result?.length });
    const updatedTabCountList: TabListProps[] = tabs.map(item => {
      switch (item.key) {
        case 'all':
          return {
            ...item,
            count: shop.receiveResults(globalSearch.data?.shop) + users.receiveResults(globalSearch.data?.users) + totalBitsVideo,
            resultCount: globalSearch.data?.shop?.result?.length + globalSearch.data?.users?.result?.length + bitsVideos.length,
          };
        case 'bit':
          return { ...item, count: totalBitsVideo, resultCount: totalBitsVideo };
        case 'shop':
          return {
            ...item,
            count: shop.receiveResults(globalSearch.data?.shop),
            resultCount: globalSearch.data?.shop?.result?.length,
          };
        case 'user':
          return {
            ...item,
            count: users.receiveResults(globalSearch.data?.users),
            resultCount: globalSearch.data?.users?.result?.length,
          };
        default:
          return item;
      }
    });
    setTabsList(updatedTabCountList);
    setTotalResult(totalNumber);
    setRevalidateData(true);
    setIsOpen(false);
    setIsLoading(false);
    searchEvent(searchValue, totalNumber);
  }, [globalSearch.data, bitsVideos, isFetching, isLoadingBitsVideos]);

  useEffect(() => {
    if (tab.selected === 'bit') {
      setBitsPage(1);
      setHasMoreBits(true);
      setBitsVideos([]);
      fetchBitSearchResults(searchValue, 1);
    }
    if (tab.selected !== 'all') {
      loadTabData();
    }
  }, [tab.selected]);

  useEffect(() => {
    const data = allSearchInfo?.data;
    if (data === undefined) {
      return;
    }
    setIsLoadingMore(false);
    tab.appendData(data);
  }, [allSearchInfo.data]);

  useEffect(() => {
    mixpanelHelper.trackPageView('GLOBAL SEARCH - SEARCH RESULT PAGE', 'search_results_page', window.location.pathname, document.referrer);
    brazeHelper.trackPageView('GLOBAL SEARCH - SEARCH RESULT PAGE', 'search_results_page', window.location.pathname, document.referrer);
  }, []);

  useEffect(() => {
    const tabInfo = searchResultsTabEventMapping[tab.selected];
    if (tabInfo) {
      mixpanelHelper.trackPageView(tabInfo.pageName, tabInfo.pageID, window.location.pathname, document.referrer);
      brazeHelper.trackPageView(tabInfo.pageName, tabInfo.pageID, window.location.pathname, document.referrer);
    }
  }, [tab.selected]);

  return (
    <>
      <CustomHelmet
        pageTitle={translate(PAGE_TITLE.search as TxKeyPath) ?? ''}
        metaDescription={translate(PAGE_DESCRIPTION.search as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.search as TxKeyPath) ?? ''}
      />
      <div className="w-full h-full font-regular pb-[1rem]">
        <SearchHeader tabs={tabsList} title={searchValue} selectedTab={tab.selected} setSelectedTab={tab.setSelected} />
        <Container className={`flex flex-col mt-[1.5rem] min-h-[50vh] ${isMobile ? 'gap-[2rem]' : 'gap-[2.5rem]'}`}>
          {(['all'].includes(tab.selected) ? (totalResult as number) === 0 : (tab.data?.length as number) === 0) &&
          !(isLoading || isLoadingMore || isLoadingBitsVideos) ? (
            !(isFetching || isLoadingBitsVideos) ? (
              <>
                {hashtags.length > 0 && <HashtagResult hashtags={hashtags} />}

                <div className="flex items-center justify-center h-full">
                  <EmptyState
                    message={translate('error_not_found_generic') ?? ''}
                    icon={
                      <ResponsiveIcon
                        className="stroke-secondary fill-primary"
                        name={IconNames?.searchOutline}
                        baseHeight={90}
                        baseWidth={90}
                      />
                    }
                    isMobile={isMobile}
                    img={IMAGES.GlobalSearchEmptyStage.toString()}
                    imgClass="w-[10.534rem] h-[7.462rem]"
                    divClass="py-[2.5rem]"
                  />
                </div>
              </>
            ) : (
              <Oval
                height={500}
                width={500}
                color="#E95F2A"
                secondaryColor="#E95F2A"
                visible={true}
                ariaLabel="oval-loading"
                strokeWidth={2}
                strokeWidthSecondary={2}
                wrapperClass="w-[5rem] h-[5rem] ml-auto mr-auto flex items-center justify-center"
              />
            )
          ) : (
            <>
              {hashtags.length > 0 && tab.selected === 'all' && <HashtagResult hashtags={hashtags} />}
              {(shop.results.result?.length > 0 || (tab.data?.length > 0 && tab.selected !== 'all') || isLoading || isLoadingMore) &&
                ['all', 'shop'].includes(tab.selected) && (
                  <SearchShopResult
                    shopData={shop.results}
                    isLoading={tab.selected === 'shop' ? isLoadingMore : isLoading}
                    tabData={tab.data}
                    selectedTab={tab.selected}
                    loadMore={loadMore}
                    setSelectedTab={tab.setSelected}
                    isMobile={isMobile}
                    searchValue={searchValue}
                  />
                )}
              {((users.results.result?.length as number) > 0 ||
                ((tab.data?.length as number) > 0 && tab.selected !== 'all') ||
                isLoading ||
                isLoadingMore) &&
                ['all', 'user'].includes(tab.selected) && (
                  <SearchUserResult
                    userData={users.results}
                    isLoading={tab.selected === 'user' ? isLoadingMore : isLoading}
                    tabData={tab.data}
                    selectedTab={tab.selected}
                    loadMore={loadMore}
                    setSelectedTab={tab.setSelected}
                    isMobile={isMobile}
                  />
                )}
              {hashtags.length > 0 && tab.selected === 'bit' && <HashtagResult hashtags={hashtags} />}
              {(totalBitsVideo > 0 || tab.selected !== 'all') && ['all', 'bit'].includes(tab.selected) && (
                <SearchBitResult
                  bitData={bitsVideos}
                  isLoading={tab.selected === 'bit' && isLoadingBitsVideos}
                  selectedTab={tab.selected}
                  loadMore={loadMoreBits}
                  setSelectedTab={tab.setSelected}
                  totalBitsVideo={totalBitsVideo}
                />
              )}
            </>
          )}
        </Container>
      </div>
    </>
  );
};

export default SearchPage;
