import { Card, ImageOnly } from '@braze/web-sdk';
import { BannerInfoDataModel } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import BrazeStaticBanner from '@fe-web/Organisms/BrazeStaticBanner';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useSelector } from 'react-redux';

import Banner from './Banner';

interface StaticBannerSectionProps {
  shopPageBrazeStaticBanner: Card | ImageOnly | null;
  banner: BannerInfoDataModel;
  isFromBraze: boolean;
}

const StaticBannerSection = (props: StaticBannerSectionProps) => {
  const { shopPageBrazeStaticBanner, banner, isFromBraze } = props;

  const language = useSelector((state: RootState) => state.app.language);
  const isMobile = useMobileDetect();

  return isFromBraze
    ? shopPageBrazeStaticBanner && (
        <div className="px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655">
          <BrazeStaticBanner card={shopPageBrazeStaticBanner} ctaSource="Shop Page" />
        </div>
      )
    : banner && (
        <Banner
          name={banner[`name_${language}`]}
          language={language}
          img={isMobile ? banner[`mobile_media_url_${language}`] : banner[`web_media_url_${language}`]}
          banner_info={banner}
        />
      );
};

export default StaticBannerSection;
