import { createPlayerBuilder } from 'fl-player';
import { MediaType, PlaybackOptions, Player } from 'fl-player-interface';
import { useCallback, useEffect, useRef, useState } from 'react';

import useConvivaService from '../../ConvivaContext';
import VideoPlayerControllers from './Controllers';

interface PlayerProps {
  mediaUrl: string;
  contentTitle: string;
  isFullScreen: boolean;
  backHandler?: ({ playerObject, convivaService }: any) => void;
}

const VideoPlayer = ({ mediaUrl, contentTitle, backHandler, isFullScreen }: PlayerProps) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const componentRef = useRef<HTMLDivElement | null>(null);
  const playerBuilder = createPlayerBuilder();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [playerObject, setPlayerObject] = useState<Player | any>(null);
  const [showController, setShowController] = useState(true);
  const [controllerLock, setControllerLock] = useState(false);
  const [openQuality, setOpenQuality] = useState(false);
  const [brightness, setBrightness] = useState(1);
  const [volume, setVolume] = useState(true);
  const [screen, setFullscreen] = useState(false);
  const [selectedQuality, setSelectedQuality] = useState<string>('Auto');

  const playback_options: PlaybackOptions = {
    autoPlayOnLoad: true,
    shouldRetryOnErrors: true,
    maxRetries: 2,
    retiresAttempted: 1,
    repeatWhenFinished: false,
    enforceHDCPCompliance: false,
    initialPlaybackTime: undefined,
    safariBehaviourForLiveHls: false,
  };
  const convivaService = useConvivaService(playerObject, mediaUrl, contentTitle);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const playerInit = useCallback(() => {
    if (videoRef.current) {
      // const mediaUrl = 'https://live-streaming-stc.vctrl-stag.stcplay.gg/stcpoc/stcpoc_hls5_ts.isml/.m3u8';
      const player: Player = playerBuilder
        .mediaElement(videoRef.current)
        .mediaUrl(mediaUrl)
        .mediaType(MediaType.HLS)
        .playbackOptions(playback_options)
        .build();
      setPlayerObject(player);
      player.load();
    }
  }, [videoRef]);

  useEffect(() => {
    const time = setTimeout(() => {
      setShowController(false);
    }, 10000);
    return () => clearTimeout(time);
  }, [showController]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    playerInit();
  }, [videoRef]);

  useEffect(() => {
    if (playerObject) {
      convivaService.initialize();
    }
  }, [playerObject]);

  const onLockControler = () => {
    setControllerLock(!controllerLock);
  };
  useEffect(() => {
    if (controllerLock) {
      setShowController(false);
    } else {
      setShowController(true);
    }
  }, [controllerLock]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (!entry.isIntersecting && playerObject) {
          playerObject.stop();
          convivaService.destroy();
        }
      },
      { threshold: 0.1 },
    );

    if (componentRef.current) {
      observer.observe(componentRef.current);
    }

    return () => {
      if (componentRef.current) {
        observer.unobserve(componentRef.current);
      }
    };
  }, [componentRef, playerObject]);

  const handleQuality = () => {
    setOpenQuality(!openQuality);
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement && componentRef?.current) {
      componentRef?.current
        .requestFullscreen()
        .then(() => {
          setFullscreen(true);
        })
        .catch((err: any) => {
          console.error(`Error attempting to enable full-screen mode: ${err.message} (${err.name})`);
        });
    } else {
      document
        .exitFullscreen()
        .then(() => {
          setFullscreen(false);
        })
        .catch(err => {
          console.error(`Error attempting to exit full-screen mode: ${err.message} (${err.name})`);
        });
    }
  };

  return (
    <div
      ref={componentRef}
      className={isFullScreen ? 'relative w-screen h-screen' : 'relative w-full h-full'}
      style={{ filter: `brightness(${Math.round(brightness * 100)}%) contrast(100%)` }}
      onClick={() => {
        if (!controllerLock) {
          setShowController(true);
        }
      }}
    >
      <video ref={videoRef} className={`absolute block z-0 bg-black w-full h-full`}></video>
      {playerObject && (
        <VideoPlayerControllers
          playerObject={playerObject}
          onLockControler={onLockControler}
          controllerLock={controllerLock}
          contentTitle={contentTitle}
          handleQuality={handleQuality}
          brightness={brightness}
          volume={volume}
          screen={screen}
          setFullscreen={setFullscreen}
          openQuality={openQuality}
          showController={showController}
          selectedQuality={selectedQuality}
          setSelectedQuality={setSelectedQuality}
          convivaService={convivaService}
          toggleFullScreen={toggleFullScreen}
          backHandler={backHandler}
          setVolume={setVolume}
          isFullScreen={isFullScreen}
        />
      )}
    </div>
  );
};

export default VideoPlayer;
