import styled from 'styled-components';
import tw from 'twin.macro';

{/* STCACN-4981 onyx color in line 9 */}
const StyledProductDetailsContent = styled.div`
    ${tw`
        text-body
        font-regular
        text-secondary/[0.5]
        
        SD:col-span-3
        2K:text-[28.44px]/[42.66px]
        4K:text-[42.66px]/[64px]
        8K:text-[85.33px]/[128px]
    `}

    p, ul {
        ${tw`
            my-16
        `}
    }

    ul {
        ${tw`
            list-disc
            pl-24
            2K:pl-[42.66px]
            4K:pl-64
            8K:pl-[128px]
        `}
    }

    a {
        ${tw`
            text-sunset
            underline
        `}
    }
`;

const StyledProductDetailsDisclaimer = styled.div`
    ${tw`
        font-regular
        text-secondary/[0.5]
        text-bodySmall
        
        SD:col-span-3
        2K:text-[28.44px]/[42.66px]
        4K:text-[42.66px]/[64px]
        8K:text-[85.33px]/[128px]
    `}

    p, ul {
        ${tw`
            my-16
        `}
    }

    ul {
        ${tw`
            list-disc
            pl-24
            2K:pl-[42.66px]
            4K:pl-64
            8K:pl-[128px]
        `}
    }

    a {
        ${tw`
            text-sunset
        `}
    }
`;

export { StyledProductDetailsContent, StyledProductDetailsDisclaimer };
