export const DEFAULT_PRIVATE_NOTE = { url: '', game_name: '', stage_name: '', match_name: '' };

export const PREDICTION_LEADERBOARD_TNC = [
  {
    id: 1,
    en: 'Prediction open 72 hours before the match',
    ar: 'يبداء التنبؤ للفريق الفائز بـ 72 ساعة قبل بدء المباراة.',
  },
  {
    id: 2,
    en: 'Predictions ends once the games started.',
    ar: 'ينتهي التنبؤ للفريق الفائز بمجرد بدء المباراة.',
  },
  {
    id: 3,
    en: 'Prediction leaderboard screen gets updated every 6 hours.',
    ar: 'يتم تحديث جدول ترتيب المتنبئين الفائزين كل 6 ساعات.',
  },
  {
    id: 4,
    en: 'Gathering points logic is:',
    ar: 'النقاط المكتسبة للمتنبئين:',
    children: [
      {
        id: 41,
        en: 'Correct Prediction = 30 points.',
        ar: 'التنبؤ الصحيح = 30 نقطة.',
      },
      {
        id: 42,
        en: 'Wrong Prediction = 5 points.',
        ar: 'التنبؤ الخاطئ = 5 نقاط.',
      },
      {
        id: 43,
        en: 'Incase the two teams tied = 0 points.',
        ar: 'في حال تعادل الفريقين = 0 نقاط.',
      },
    ],
  },
  {
    id: 5,
    en: 'Users ranks are based on the more collected points.',
    ar: 'يتم ترتيب المستخدمين بناءً على عدد النقاط التي جمعوها.',
  },
  {
    id: 6,
    en: 'If 2 users or more tied:',
    ar: 'في حال تساوى مستخدمان  أو أكثر:',
    children: [
      {
        id: 61,
        en: 'The user with more correct prediction will be the winner.',
        ar: 'الفائز هو المستخدم الذي لديه أكثر تنبؤات صحيحة.',
      },
      {
        id: 62,
        en: 'If both has the same correct prediction the user who predicted first will win.',
        ar: 'يإذا كان لديهما نفس عدد التنبؤات الصحيحة، فالفائز هو من قام بالتنبؤ أولاً.',
      },
    ],
  },
  {
    id: 7,
    en: 'If a user cheated in anyway the user will be removed from that week’s prediction',
    ar: 'إذا غش مستخدم بأي طريقة، سيتم إزالته من التنبؤ لتلك الأسبوع.',
  },
  {
    id: 8,
    en: 'Top 3 users on the weekly leaderboard will each receive a voucher, and Final prizes will be awarded in cash to the users who participated in the final giveaway. This applies to users who are Saudi Arabia citizens or residents.',
    ar: 'سيتم توزيع الجوائز الأسبوعية وفقاً لأعلى ٣ مستخدمين في قائمة المتصدرين، كما سيتم توزيع الجوائز النقدية في المسابقة النهائية للمستخدمين الأعلى مشاركة، ومواطنين أو مقيمين في المملكة العربية السعودية',
  },
  {
    id: 9,
    en: 'Final Giveaway winners must be 16 years old or above',
    ar: 'يجب أن يكون عمر المشارك 16 عام أو أعلى',
  },

  {
    id: 10,
    en: 'Prize will be distributed to the first three winners on a weekly basis as below',
    ar: 'سيتم توزيع الجوائز على أول ثلاثة فائزين على أساس أسبوعي كما هو موضح أدناه',
    children: [
      {
        id: 101,
        en: '1st will receive a voucher worth SAR2,500',
        ar: 'سيحصل الفائز الأول على قسيمة بقيمة: 2500 ريال سعودي',
      },
      {
        id: 102,
        en: '2nd will receive a voucher worth SAR1,500',
        ar: 'سيحصل الثاني على قسيمة بقيمة: 1500 ريال سعودي',
      },
      {
        id: 103,
        en: '3rd  will receive a voucher worth SAR1,000',
        ar: 'سيحصل الثالث على قسيمة بقيمة: 500 ريال سعودي',
      },
    ],
  },

  {
    id: 11,
    en: 'At the end of EWC we will have the ultimate winners who got the most correct predictions from all the 8 weeks. Top 3 winners as below. For Saudi citizens and residents only',
    ar: 'في نهاية كأس العالم للرياضات الإلكترونية، سنعلن عن الفائزين في التحدي النهائي والذين حصلوا على أكبر عدد من التوقعات الصحيحة خلال الأسابيع الثمانية. الفائزون الثلاثة الأوائل كما هو موضح أدناه. للمواطنين السعوديين والمقيمين في المملكة العربية السعودية',
    children: [
      {
        id: 111,
        en: '1st will receive cash worth SAR50,000',
        ar: 'سيحصل الأول على مبلغ نقدي: 50000 ريال سعودي',
      },
      {
        id: 112,
        en: '2nd will receive cash worth SAR30,000',
        ar: 'سيحصل الثاني على مبلغ نقدي: 30000 ريال سعودي',
      },
      {
        id: 113,
        en: '3rd  will receive cash worth SAR20,000',
        ar: 'سيحصل الثالث على مبلغ نقدي: 20000 ريال سعودي',
      },
    ],
  },
];

export const PREDICTION_LEADERBOARD_TNC_BOTTOM_TEXT = {
  en: 'The total prize pool is 140K, 40K for the weekly winners as vouchers and 100K for the ultimate winners as cash prizes ',
  ar: 'جموع الجوائز هو 140 ألف ريال سعودي، 40 ألف ريال سعودي للفائزين الأسبوعيين على شكل قسائم و100 ألف ريال سعودي للفائزين النهائيين على شكل جوائز نقدية',
};

export const PHONE_NUMBER_LIMITS = {
  max: 11,
  min: 7,
};

export const PROMO_ELIGIBLE_BRANDS = ['lg'];

export enum ModerationStatuses {
  active = 'ACTIVE',
  inactive = 'INACTIVE',
  reject = 'REJECT',
  pending = 'PENDING',

  // Notification preview
  accepted = 'accepted',
  pending1 = 'pending',
  rejected = 'rejected',
}
