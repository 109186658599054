import { RootState, setVerifyDialogOpen } from '@fe-monorepo/store';
import { useDispatch, useSelector } from 'react-redux';

const useVerifyDialogToggle = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.app.verifyDialogOpen);

  return {
    isOpen,
    toggleVerifyDialog: (value: boolean) => dispatch(setVerifyDialogOpen(value)),
  };
};

export default useVerifyDialogToggle;
