import PlaceHolderImage from '@fe-web/Atoms/Skeleton/Placeholders/Image';
import Skeleton from '@fe-web/Atoms/Skeleton/Skeleton';

const ProductsSkeleton = ({ count }: { count: number }) => {
  return (
    <div className="px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655">
      <Skeleton height="h-[2.375rem]" width="w-[25%]" className="aspect-[141/154] m-t-2 mb-20" />
      <div className="flex gap-[1rem] md:gap-[1.5rem]  w-fit">
        <Skeleton height="h-[21.25rem] md:h-[25rem]" width="" className="aspect-[141/154]">
          <PlaceHolderImage svgStyle="w-[48px] h-[48px] fill-white/50" />
        </Skeleton>
        {[...Array(count)].map((e, i) => (
          <div className="w-[13.4375rem] md:w-[17.625rem] flex flex-col" key={i.toString()}>
            <Skeleton height="h-[14.875rem] md:h-[19.25rem]" width="" className="aspect-[141/154]">
              <PlaceHolderImage svgStyle="w-[48px] h-[48px] fill-white/50" />
            </Skeleton>
            <div className="py-[1rem] flex flex-col gap-[0.5rem]">
              <Skeleton height="h-[1.375rem]" width="w-[50%]" className="aspect-[141/154] m-t-2" />
              <Skeleton height="h-[1.375rem]" width="w-full" className="aspect-[141/154] m-t-2" />
              <Skeleton height="h-[1.375rem]" width="w-[50%]" className="aspect-[141/154] m-t-2" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default ProductsSkeleton;
