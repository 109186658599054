import { gql } from '@apollo/client';

export const GET_QUESTIONS_QUERY = {
  GetQuestions: gql`
    query GetQuestions($details: QuestionInput!) {
      GetQuestions(details: $details) {
        is_successful
        error_code
        error_msg
        app_code
        response {
          course_id
          question_id
          question_en
          question_ar
        }
      }
    }
  `,
};

export const GET_COURSES_QUERY = {
  GetCourses: gql`
    query GetCourses {
      GetCourses {
        is_successful
        error_code
        error_msg
        app_code
        response {
          course_id
          course_code
          course_name_en
        }
      }
    }
  `,
};
