import BreadCrumbs from '@fe-web/Atoms/BreadCrumb';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';

interface BreadcrumbPath {
  pageName: string;
  route: AppRoutes;
}

interface BreadcrumbsLayoutProps {
  children: ReactNode;
  list: BreadcrumbPath[];
}

const BreadcrumbsLayout = ({ children, list }: BreadcrumbsLayoutProps) => {
  const navigate = useNavigate();
  return (
    <>
      <BreadCrumbs
        list={list}
        getString={(link: BreadcrumbPath) => link.pageName}
        onClick={(link: BreadcrumbPath) => navigate(link.route)}
      ></BreadCrumbs>
      {children}
    </>
  );
};

export default BreadcrumbsLayout;
