import { IconNames } from '@fe-monorepo/helper';
import { useUserProfile } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';

const NavWishList = () => {
  const { isLoggedIn } = useUserProfile();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleNavWishListClick = () => {
    if (isLoggedIn) {
      navigate(AppRoutes.wishList);
    } else {
      navigate(`${AppRoutes.authSignIn}${pathname ? `?redirect_url=${pathname}` : ''}`);
    }
  };

  return (
    <div className="z-10 hover:cursor-pointer" onClick={handleNavWishListClick}>
      <ResponsiveIcon name={IconNames.heartOutline} baseHeight={25} baseWidth={25} iconClasses="fill-secondary/50 hover:fill-secondary" />
    </div>
  );
};

export default NavWishList;
