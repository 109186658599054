function UnLikeIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M9.99999 17.4999L8.79166 16.4166C7.38888 15.1527 6.22916 14.0624 5.31249 13.1458C4.39582 12.2291 3.66666 11.4062 3.12499 10.677C2.58332 9.94784 2.20485 9.2777 1.98957 8.66658C1.7743 8.05547 1.66666 7.43047 1.66666 6.79158C1.66666 5.48603 2.10416 4.39575 2.97916 3.52075C3.85416 2.64575 4.94443 2.20825 6.24999 2.20825C6.97221 2.20825 7.65971 2.36103 8.31249 2.66659C8.96527 2.97214 9.52777 3.4027 9.99999 3.95825C10.4722 3.4027 11.0347 2.97214 11.6875 2.66659C12.3403 2.36103 13.0278 2.20825 13.75 2.20825C15.0555 2.20825 16.1458 2.64575 17.0208 3.52075C17.8958 4.39575 18.3333 5.48603 18.3333 6.79158C18.3333 7.43047 18.2257 8.05547 18.0104 8.66658C17.7951 9.2777 17.4167 9.94784 16.875 10.677C16.3333 11.4062 15.6042 12.2291 14.6875 13.1458C13.7708 14.0624 12.6111 15.1527 11.2083 16.4166L9.99999 17.4999ZM9.99999 15.2499C11.3333 14.0555 12.4305 13.0312 13.2917 12.177C14.1528 11.3228 14.8333 10.5798 15.3333 9.94784C15.8333 9.31589 16.1805 8.75339 16.375 8.26034C16.5694 7.76728 16.6667 7.2777 16.6667 6.79158C16.6667 5.95825 16.3889 5.26381 15.8333 4.70825C15.2778 4.1527 14.5833 3.87492 13.75 3.87492C13.0972 3.87492 12.493 4.05895 11.9375 4.427C11.3819 4.79506 11 5.26381 10.7917 5.83325H9.20832C8.99999 5.26381 8.61805 4.79506 8.06249 4.427C7.50693 4.05895 6.90277 3.87492 6.24999 3.87492C5.41666 3.87492 4.72221 4.1527 4.16666 4.70825C3.6111 5.26381 3.33332 5.95825 3.33332 6.79158C3.33332 7.2777 3.43055 7.76728 3.62499 8.26034C3.81943 8.75339 4.16666 9.31589 4.66666 9.94784C5.16666 10.5798 5.84721 11.3228 6.70832 12.177C7.56943 13.0312 8.66666 14.0555 9.99999 15.2499Z"
        fill="var(--secondary-black)"
        fillOpacity="0.7"
      />
    </svg>
  );
}

export default UnLikeIcon;
