import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { Formik } from 'formik';
import React, { useEffect, useState } from 'react';

interface ResponseType {
  type: 'success' | 'error' | null;
  text: string | null;
}
interface DiscountCodeProps {
  appliedPromo: any;
  label?: string;
  discountCodePlaceholder?: string;
  optionalLabel?: string;
  applyLabel?: string;
  removeLabel?: string;
  maxDiscounts: number;
  onRemoveDiscount?: () => void;
  onApplyDiscount: (code: string) => void;
  submitError?: ResponseType;
}

const DiscountCode: React.FC<DiscountCodeProps> = ({
  onApplyDiscount,
  appliedPromo,
  onRemoveDiscount,
  label,
  optionalLabel,
  applyLabel,
  removeLabel,
  discountCodePlaceholder,
  submitError,
}) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    setErrorMessage(submitError?.text ?? '');
  }, [submitError]);

  return (
    <div className="flex flex-col gap-[1rem] self-stretch rounded-md border border-secondary/[0.2] w-full py-[1.25rem] px-[1rem] 8K:py-[1rem] 8K:px-[1rem] 8K:gap-[1rem]">
      <div className="flex items-center gap-[0.25rem] self-stretch font-regular text-fs-body-small 8K:gap-[.5rem]">
        <label className="text-secondary">{label ?? 'Discount Code'}</label>
        <span className="text-secondary/[0.5]"> ({optionalLabel ?? 'optional'})</span>
      </div>
      {appliedPromo ? (
        <div className="flex justify-between gap-16" key={appliedPromo?.code}>
          <div className="flex items-center gap-[0.5rem]">
            <ResponsiveIcon name={IconNames.checkFill} baseWidth={20} baseHeight={20} iconClasses="fill-sunset" />
            <span className="col-span-2 text-static-secondary font-regular">{appliedPromo?.code}</span>
          </div>
          <button className="font-regular text-fs-body-small text-sunset" onClick={onRemoveDiscount}>
            {removeLabel ?? 'Remove'}
          </button>
        </div>
      ) : (
        <Formik
          initialValues={{ discountCode: '' }}
          validate={values => {
            const errors: Partial<{ discountCode: string }> = {};
            setIsInvalid(false);
            return errors;
          }}
          onSubmit={values => {
            if (!values.discountCode) {
              return;
            }
            setErrorMessage('');
            onApplyDiscount(values.discountCode);
          }}
        >
          {({ handleSubmit, handleChange, handleBlur, values, submitCount }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-[0.688rem]">
                <div className="flex gap-[1rem] text-secondary/50 font-regular">
                  <input
                    type="text"
                    name="discountCode"
                    className="min-w-0 bg-transparent outline-0 flex-1 pb-8 placeholder:text-secondary/[0.5] font-regular text-fs-body-large text-static-secondary border-b border-secondary/[0.2]"
                    value={values.discountCode}
                    onChange={e => {
                      handleChange(e);
                      setErrorMessage('');
                    }}
                    onBlur={handleBlur}
                    placeholder={discountCodePlaceholder ?? 'Enter promo code'}
                  />
                  <button type="submit" className="font-regular text-fs-body-small text-sunset">
                    {applyLabel ?? 'Apply'}
                  </button>
                </div>
              </div>
            </form>
          )}
        </Formik>
      )}
      {errorMessage && (
        <span className={`font-regular text-fs-body-small ${submitError?.type === 'error' ? 'text-red' : 'text-greenB'}`}>
          {errorMessage}
        </span>
      )}
    </div>
  );
};

export default DiscountCode;
