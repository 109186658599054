import { useTranslate } from '@fe-monorepo/hooks';

interface ProgressBarProps {
  steps: number;
}

const ProgressBar = ({ steps }: ProgressBarProps) => {
  const { translate } = useTranslate();

  //create a function that will received the steps and if steps is equal to 0 it will return translate('shop.cart.returns_or_exchange.items'), if steps is equal to 1 it will return translate('shop.cart.returns_or_exchange.photos'), if steps is equal to 2 it will return translate('shop.cart.returns_or_exchange.refund')
  function getSteps(steps: number) {
    if (steps === 0) {
      return translate('shop_firstStep_title');
    } else if (steps === 1) {
      return translate('shop.cart.returns_or_exchange.photos');
    } else if (steps === 2) {
      return translate('shop.cart.returns_or_exchange.refund');
    }
  }
  return (
    <div className="flex items-center mdxs:gap-[0.75rem] mdMaxxS:gap-[0.75rem] SD:gap-[1.5rem] mb-[2.5rem]">
      <div
        className={`${
          steps === 0 || steps === 1 || steps === 2 ? 'text-sunset' : 'text-secondary/50'
        } text-fs-body-small font-regular w-full`}
      >
        <span className="mdxs:hidden mdMaxxS:hidden">{translate('shop_firstStep_title')}</span>
        <span className="SD:hidden">{getSteps(steps)}</span>
        <hr
          className={`${
            steps === 0 || steps === 1 || steps === 2
              ? 'text-sunset bg-sunset h-[0.125rem]'
              : 'text-secondary/10 bg-secondary/10 h-[0.0625rem]'
          } w-full border-[none] mt-[0.5625rem] `}
        />
      </div>
      <div className={`${steps === 1 || steps === 2 ? 'text-sunset' : 'text-secondary/50'} text-fs-body-small font-regular w-full`}>
        <span className="mdxs:opacity-0 mdMaxxS:opacity-0 SD:inline">{translate('shop.cart.returns_or_exchange.photos')}</span>
        <hr
          className={`${
            steps === 1 || steps === 2 ? 'text-sunset bg-sunset h-[0.125rem]' : 'text-secondary/10 bg-secondary/10 h-[0.0625rem]'
          } w-full border-[none] mt-[0.5625rem] `}
        />
      </div>
      <div className={`${steps === 2 ? 'text-sunset' : 'text-secondary/50'} text-fs-body-small font-regular w-full`}>
        <span className="mdxs:hidden mdMaxxS:hidden SD:inline">{translate('shop.cart.returns_or_exchange.refund')}</span>
        <span className="text-secondary text-opacity-[42%] mdxs:ml-[3rem] SD:hidden">step {steps + 1}/3</span>
        <hr
          className={`${
            steps === 2 ? 'text-sunset bg-sunset h-[0.125rem]' : 'text-secondary/10 bg-secondary/10 h-[0.0625rem]'
          } w-full border-[none] mt-[0.5625rem] `}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
