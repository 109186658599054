import { PageErrorTypes, store } from '@fe-monorepo/store';
import { t } from 'i18next';
import { sha256 } from 'js-sha256';
import moment from 'moment/min/moment-with-locales';
import { Image as ImageType } from 'react-native-image-crop-picker';
import { Asset } from 'react-native-image-picker';

import { placeToIanaMapping, timezoneData } from './mockData';
import {
  isPasswordStrengthFour,
  isPasswordStrengthOne,
  isPasswordStrengthThree,
  isPasswordStrengthTwo,
  isValidEmail,
  isValidMobile,
} from './validators';

export const swapElementsFromArray = (array: any[], from: number, to: number) => {
  const newArray = [...array];
  const temp = newArray[from];
  newArray[from] = newArray[to];
  newArray[to] = temp;
  return newArray;
};

export const formatLargeNumbers = (number: number) => {
  return number < 1000
    ? formatNumberWithCommas(number)
    : number >= 1000000
    ? new Intl.NumberFormat().format(Math.round((number / 1000000) * 10) / 10) + t('common_number_millions')
    : number >= 1000
    ? new Intl.NumberFormat().format(Math.round((number / 1000) * 10) / 10) + t('common_number_thousand')
    : new Intl.NumberFormat().format(Math.round((number / 1000) * 10) / 10) + t('common_number_thousands');
};

export const formatNumberWithCommas = (number: number) => {
  const isRTL = store.getState().app.isRTL;
  return number?.toLocaleString(!isRTL ? 'en' : undefined, { minimumFractionDigits: 0, maximumFractionDigits: 0 });
};

export const formatNumberWithCommasAndDecimal = (number: number) => {
  return number?.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const currencyFormat = (amount: number) => {
  return Number(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const formatDecimalAsPercentage = (actualAmount: number, originalAmount: number) => {
  if (originalAmount === 0) {
    return '0%';
  }

  const decimalAmount = (originalAmount - actualAmount) / originalAmount;
  return new Intl.NumberFormat('default', {
    style: 'percent',
  }).format(decimalAmount);
};

export function replaceStringsFromJson(jsonString: string, toBeReplaced: any) {
  let str: string = jsonString;
  for (const item of toBeReplaced) {
    Object.keys(item).forEach(key => {
      str = str.replace(key, item[key]);
    });
  }
  return str;
}

export function replaceString(stringToReplace: string, toBeReplaced: any) {
  let s = stringToReplace;
  for (const prop in toBeReplaced) {
    s = s.replace(new RegExp('{' + prop + '}', 'g'), toBeReplaced[prop]);
  }
  return s;
}

export const stringToHash256 = (value: string) => sha256(value);

export const getCurrentDate = (isSlashFormat = false, replaceSpace = false) => {
  const today = new Date();
  const yyyy = String(today.getFullYear()).padStart(2, '0');
  const mm = String(today.getMonth() + 1).padStart(2, '0');
  const dd = String(today.getDate()).padStart(2, '0');
  const hh = String(today.getHours()).padStart(2, '0');
  const ii = String(today.getMinutes()).padStart(2, '0');
  const ss = String(today.getSeconds()).padStart(2, '0');
  let timeFormatted = '';
  if (isSlashFormat === true) {
    timeFormatted = mm + '/' + dd + '/' + yyyy + ' ' + hh + ':' + ii + ':' + ss;
  } else if (replaceSpace === true) {
    timeFormatted = yyyy + '-' + mm + '-' + dd + 'T' + hh + ':' + ii + ':' + ss;
  } else {
    timeFormatted = yyyy + '-' + mm + '-' + dd + ' ' + hh + ':' + ii + ':' + ss;
  }
  return timeFormatted?.toString();
};

export const getPasswordStrength = (password: string) => {
  if (isPasswordStrengthFour(password)) {
    return 3;
  } else if (isPasswordStrengthThree(password)) {
    return 2;
  } else if (isPasswordStrengthTwo(password)) {
    return 1;
  } else if (isPasswordStrengthOne(password)) {
    return 0;
  } else if (password === '') {
    return -1;
  }
  return 0;
};

export const reorder = (key: any, list: [any] | any[], keyName?: string) => {
  let firstItem;
  const filteredItems =
    list?.length > 0
      ? list.filter(item => {
          if ((keyName && item[keyName] === key) || item === key) {
            firstItem = [item];
            return false;
          }
          return true;
        })
      : [];
  return firstItem ? [...firstItem, ...filteredItems] : [...filteredItems];
};
export const generateGroupId = (len: number, startChar: string) => {
  let result = startChar;
  const maxPos = startChar.length;
  len = len || 5;
  for (let i = 0; i < len; i++) {
    result += Math.floor(Math.random() * maxPos);
  }
  return result;
};

const CurrencyEn = new Map([
  ['SAR', 'SR'],
  ['SR', 'SR'],
  ['AED', 'AED'],
  ['BHD', 'BHD'],
  ['KWD', 'KWD'],
  ['OMR', 'OMR'],
  ['USD', '$'],
  ['EUR', '€'],
]);

const CurrencyAr = new Map([
  ['SAR', 'ر.س'],
  ['SR', 'ريال'],
  ['AED', 'د.إ'],
  ['BHD', '.د.ب'],
  ['KWD', 'د.ك'],
  ['OMR', 'ر.ع.'],
  ['USD', 'دولار'],
  ['EUR', 'يورو'],
]);

export function getCurrencySymbol(currency: string, lang: string) {
  if (lang === 'en') {
    return CurrencyEn.get(currency);
  } else if (lang === 'ar') {
    return CurrencyAr.get(currency);
  }
}

export const isDateToday = (date: moment.Moment): boolean => {
  return date.isSame(new Date(), 'day');
};

export const convertToTimezone = (chosenDate: string, isUnix?: boolean): moment.Moment => {
  const timezone = store.getState().app.timezone;
  const preferred_timezone = store.getState().user.userContext.preferred_timezone || timezone;
  if (isUnix) {
    return moment.unix(Number(chosenDate)).utcOffset(`${preferred_timezone}`);
  } else return moment(chosenDate).utcOffset(`${preferred_timezone}`);
};

export const revertPrice = (price: number) => {
  const currencyOBJ = store.getState().app.currencyOBJ;
  return price / currencyOBJ.rate;
};

export const convertPrice = (price: number, formatter?: 'commaDecimal' | 'decimal' | 'none') => {
  const currencyOBJ = store.getState().app.currencyOBJ;
  const convertedPrice = Math.abs(price) * currencyOBJ?.rate;
  const formattedPrice =
    formatter === 'commaDecimal'
      ? formatNumberWithCommasAndDecimal(convertedPrice)
      : formatter === 'decimal'
      ? Number(convertedPrice.toFixed(2))
      : convertedPrice;
  return formattedPrice;
};

export const getChosenCurrency = () => {
  const { currency, currencyRate } = store.getState().app;
  const currencyObjAlt = { rate: currencyRate, code: currency };
  const currencyOBJ = store.getState().app.currencyOBJ;
  let symbol;
  if (currencyOBJ) {
    symbol = t(`config.currency.${currencyOBJ?.code}.symbol`);
  } else {
    symbol = t(`config.currency.${currencyObjAlt.code}`);
  }
  return symbol;
};

export const convertPriceToChosenCurrency = (price: number) => {
  const { currencyRate } = store.getState().app;
  const currencyOBJ = store.getState().app.currencyOBJ;
  const rateForConvertion = currencyOBJ ? currencyOBJ.rate : currencyRate;
  return Number((Math.abs(price) * rateForConvertion).toFixed(2));
};

export const convertPriceWithSymbol = (price: number, formatter: 'commaDecimal' | 'none', hideSymbol = false, platform = 'web') => {
  const { currency, currencyRate } = store.getState().app;
  const currencyObjAlt = { rate: currencyRate, code: currency };
  const currencyOBJ = store.getState().app.currencyOBJ;
  const isRTL = store.getState().app.isRTL;
  let symbol;
  const deducted = Math.sign(price) < 0 ? '-' : '';
  if (currencyOBJ) {
    symbol = t(`config.currency.${currencyOBJ?.code}.symbol`);
  } else {
    symbol = t(`config.currency.${currencyObjAlt.code}`);
  }

  const rateForConvertion = currencyOBJ ? currencyOBJ.rate : currencyRate;
  const convertedPrice = Math.abs(price) * rateForConvertion;
  const formattedPrice = formatter === 'commaDecimal' ? formatNumberWithCommasAndDecimal(convertedPrice) : convertedPrice;
  let combinedPrice = isRTL ? `‏${deducted}${formattedPrice} ${symbol}` : `‏${symbol} ${formattedPrice}${deducted}`;
  if (platform === 'web') {
    combinedPrice = isArabic(symbol) ? `${deducted}${formattedPrice} ${symbol}` : `${symbol} ${deducted}${formattedPrice}`;
  }
  return hideSymbol ? formattedPrice : combinedPrice;
};

export const isArabic = (text: string) => {
  const pattern = /[\u0600-\u06FF\u0750-\u077F]/;
  return pattern.test(text);
};

export const flipPrice = (price: string) => {
  const isRTL = store.getState().app.isRTL;
  return isRTL ? price : price.toString().split(' ').reverse().join(' ');
};

export const trimCountryCode = (code: string, mobile: string) => {
  if (!mobile && !code) return;
  if (mobile?.startsWith?.(code)) {
    const regex = new RegExp(code, 'g');
    return mobile?.replace(regex, '');
  }
};

type MonthNames = {
  Jan: string;
  Feb: string;
  Mar: string;
  Apr: string;
  May: string;
  Jun: string;
  Jul: string;
  Aug: string;
  Sep: string;
  Oct: string;
  Nov: string;
  Dec: string;
};

// Function to translate month to Arabic
const translateMonthToArabic = (month: string): string => {
  const months: MonthNames = {
    Jan: 'يناير',
    Feb: 'فبراير',
    Mar: 'مارس',
    Apr: 'أبريل',
    May: 'مايو',
    Jun: 'يونيو',
    Jul: 'يوليو',
    Aug: 'أغسطس',
    Sep: 'سبتمبر',
    Oct: 'أكتوبر',
    Nov: 'نوفمبر',
    Dec: 'ديسمبر',
  };
  return months[month as keyof MonthNames] || month;
};

export const getTranslatedTime = (timeStamp: string, isArabic: boolean) => {
  const timeString = convertToTimezone(timeStamp)?.locale('en').format('hh:mm a'); //moment(timeStamp).format('hh:mm a');
  if (!isArabic) {
    return timeString;
  }

  const [time, ampm] = timeString.split(' ');
  return `${time} ${translateAmPmToArabic(ampm)}`;
};

export const getTranslatedDate = (timeStamp: string) => {
  if (!timeStamp) return '';
  const [week, month, year, time, ampm] = timeStamp.split(' ');
  return `${week} ${translateMonthToArabic(month)} ${year} ${t('common_timestampAt')} ${time} ${translateAmPmToArabic(ampm)}`;
};

// Function to translate AM/PM to Arabic
export const translateAmPmToArabic = (amPm: string): string => {
  return amPm === 'am' ? 'ص' : 'م';
};

// Helper function to convert to English ordinal
export const convertToOrdinal = (d: number) => {
  if (d > 3 && d < 21) return 'th';
  switch (d % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export const mapNumberToOrdinal = (rank: number, language: 'en' | 'ar'): string => {
  if (!Number.isInteger(rank) || rank < 1) {
    return `${rank}`;
  }
  if (language === 'ar') {
    return `${rank}`;
  }
  const remainder10 = rank % 10;
  const remainder100 = rank % 100;
  let suffix = 'th';
  if (remainder10 === 1 && remainder100 !== 11) {
    suffix = 'st';
  } else if (remainder10 === 2 && remainder100 !== 12) {
    suffix = 'nd';
  } else if (remainder10 === 3 && remainder100 !== 13) {
    suffix = 'rd';
  }
  return `${rank}${suffix}`;
};

export const formatLargeNumber = (number: number): string => {
  if (!Number.isFinite(number) || number < 0) {
    return `${number}`;
  }
  const suffixes = [
    { value: 1_000_000, suffix: 'M' },
    { value: 1_000, suffix: 'K' },
  ];
  for (const { value, suffix } of suffixes) {
    if (number >= value) {
      return `${(number / value).toFixed(1).replace(/\.0$/, '')}${suffix}`;
    }
  }
  return `${number}`;
};

export const dateToFromNowDaily = (date: any, isArabic?: boolean) => {
  const fromNow = moment(date)
    .locale(isArabic ? 'ar' : 'en')
    .fromNow();
  const callback = function () {
    return '[' + fromNow + ']';
  };
  return moment(date).calendar(null, {
    lastWeek: callback,
    lastDay: '[' + t('common.yesterday') + ']',
    sameDay: '[' + t('common.today') + ']',
    nextDay: '[' + t('common.tomorrow') + ']',
    nextWeek: callback,
    sameElse: function (m, now) {
      return '[' + fromNow + ']';
    },
  });
};

export const convertGMTToIana = (gmtOffset: string): string => {
  const timezoneEntry = timezoneData.find(entry => entry.key === gmtOffset);
  if (!timezoneEntry) {
    return getCurrentUserTimeZone();
  }
  const ianaTimezones = placeToIanaMapping[timezoneEntry.place];
  if (!ianaTimezones || ianaTimezones.length === 0) {
    return getCurrentUserTimeZone();
  }
  return ianaTimezones[0];
};

const timeZoneMap: any = {
  'Asia/Kolkata': 'Asia/Calcutta',
};

export const getCurrentUserTimeZone = (): string => {
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
  return timeZoneMap[timezone] || timezone;
};

export const checkForSessionExpiry = (data: any, maxDepth = 3, currentDepth = 0): boolean => {
  if (currentDepth > maxDepth) return false;
  if (!data) return false;
  if (data.error_code === PageErrorTypes.SESSION_EXPIRED) return true;
  if (typeof data === 'object' && data !== null) {
    for (const value of Object.values(data)) {
      if (checkForSessionExpiry(value, maxDepth, currentDepth + 1)) return true;
    }
  }
  return false;
};

export const maskEmail = (email: string) => {
  if (!email) {
    return '';
  } else if (!isValidEmail(email)) {
    return email;
  }
  const [localPart, domain] = email.split('@');
  const [router, domainName] = domain?.split('.') ?? ['', ''];
  const toMaskLength = localPart.length + router.length;
  const sliceLength = toMaskLength >= 6 ? 4 : localPart.length / 2;
  const maskedLocalPart = localPart.slice(0, sliceLength).padEnd(localPart.length, '*');
  const maskedRouter = '*'.repeat(router.length);
  const maskedEmail = `${maskedLocalPart}*${maskedRouter}.${domainName}`;
  return maskedEmail;
};

export const maskPhoneNumber = (phoneNumber: string, visibleLength = 4) => {
  if (!phoneNumber) {
    return '';
  } else if (!isValidMobile(phoneNumber) || phoneNumber?.length < visibleLength) {
    return phoneNumber;
  }
  const visiblePart = phoneNumber.slice(-visibleLength);
  const maskedPart = '*'.repeat(phoneNumber.length - visibleLength);
  return maskedPart + visiblePart;
};

export const safelyParseJSON = (strInput: string, fallback?: any) => {
  let output = fallback ?? {};
  try {
    output = JSON.parse(strInput);
  } catch (err) {
    return output;
  }
  return output;
};

export const uniqBy = (array: any[], key: any) => {
  return array.filter((value, index, self) => index === self.findIndex(t => t[key] === value[key]));
};

export const supportedMediaFormats = ['jpg', 'jpeg', 'png', 'gif'];
export enum MediaSecurityCheck {
  UnsupportedType = 'unsupported_type',
  SizeExceeded = 'size_exceeded',
}
interface MediaSecurityResult {
  isSecure: boolean;
  failedChecks: MediaSecurityCheck[];
}
type MediaInput = Asset | ImageType;

export const checkBasicMediaSecurity = (imageObject: MediaInput, platform?: string): MediaSecurityResult => {
  // Check if image object is from crop picker library
  const isImageType = (obj: Asset | ImageType): obj is ImageType => 'size' in obj && 'mime' in obj;
  const isAsset = (obj: Asset | ImageType): obj is Asset => 'fileSize' in obj && 'type' in obj;

  const fileSize = isImageType(imageObject) ? imageObject.size ?? 0 : isAsset(imageObject) ? imageObject.fileSize ?? 0 : 0;

  let fileType = '';
  if (platform === 'ios' && isImageType(imageObject) && imageObject.sourceURL) {
    fileType = imageObject.sourceURL.split('.').pop() ?? '';
  } else if (platform === 'android' && isImageType(imageObject) && imageObject.path) {
    // Android: Extract file type from path
    fileType = imageObject.path.split('.').pop() ?? '';
  } else {
    // Fallback to MIME type
    const typeOrMime = isImageType(imageObject) ? imageObject?.mime : isAsset(imageObject) ? imageObject?.type : '';
    fileType = typeOrMime?.split('/')[1]?.split('+')[0] ?? '';
  }

  const readableSizeMB = fileSize / (1024 * 1024); // convert byte to MB in binary (this is more precise compared to disk size)
  const maxAllowedFileSize = 5;

  // NOTE: fileName character limit check isnt done - instead name is hardcoded as Date.now() - due to issue with react-nativeimage-picker library filename (known open github issue #1997 #1569)
  const failedChecks = [];

  // Check 1: File type is supported
  if (!supportedMediaFormats.includes(fileType?.toLowerCase())) {
    failedChecks.push(MediaSecurityCheck.UnsupportedType);
  }

  // Check 2: File size limit (accurate comparison for > maxAllowedFileSize)
  if (readableSizeMB > maxAllowedFileSize) {
    failedChecks.push(MediaSecurityCheck.SizeExceeded);
  }

  // Determine if all checks passed
  const isSecure = failedChecks.length === 0;

  return {
    isSecure,
    failedChecks,
  };
};
