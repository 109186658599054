import { IMAGE_ALT_LABELS, Logger, TxKeyPath, ZEGO_CLOUD_CONFIG } from '@fe-monorepo/helper';
import { useBlock, useReport, useTranslate } from '@fe-monorepo/hooks';
import PrimaryButton from '@fe-web/Atoms/Buttons/PrimaryButton';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import InputField from '@fe-web/Molecules/InputFields/InputField/InputField';
import PlayerAvatar from '@fe-web/Molecules/PlayerAvatar';
import ReportModal from '@fe-web/Organisms/ReportModal/ReportModal';
import { useConnectConversationProvider, useConnectProvider } from '@fe-web/hooks/useConnect';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import MoreOption from '../MoreOption/MoreOption';
import OptionsActionModal from '../OptionsActionModal/OptionsActionModal';
import GroupAvatarModal from './GroupAvatarModal';
import './styles/GroupPreference.css';

const useModal = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return { isOpen, open, close };
};

interface Props {
  isMinimized?: boolean;
}

const GroupPreference = ({ isMinimized = false }: Props) => {
  const [isActionOptionOpen, setIsActionOptionOpen] = useState(false); //open clear chat modal
  const [optionActionType, setOptionActionType] = useState<'clear' | 'block' | 'delete' | 'leave' | 'dismiss' | 'report'>('clear');
  const avatarModal = useModal();
  const [avatarImage, setAvatarImage] = useState<string>('');
  const { translate } = useTranslate();
  const { createTheReport } = useReport();
  const navigate = useNavigate();
  const { kickGroupMembers, updateGroupAvatarUrl, updateGroupName } = useConnectProvider();
  const { conversationID, userAdmin, groupMembersList, groupInfo, groupMemberCount, avatarUrl, setGroupMembersList, setGroupOwner } =
    useConnectConversationProvider();
  const { setIsModalOpen } = useNotificationProvider();
  const { GROUP_MEMBER_COUNT } = ZEGO_CLOUD_CONFIG;
  const { crmCommunityUnblock } = useBlock();
  const [groupName, setGroupName] = useState<string>('Group chat name');
  const [controlGroupName, setControlGroupName] = useState<string>(groupInfo?.groupName ?? '');
  const [groupNameTouched, setGroupNameTouched] = useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [userName, setUserName] = useState<string>('');
  const [isBlocking, setIsBlocking] = useState<boolean>(false);
  const [blockId, setBlockId] = useState<string>();
  const [isGroupNameValid, setIsGroupNameValid] = useState<boolean>(true);
  const { log } = Logger();

  const handleAction = () => {
    setIsActionOptionOpen(false);
  };

  useEffect(() => {
    setAvatarImage(groupInfo?.groupAvatarUrl ? groupInfo?.groupAvatarUrl : '');
  }, [groupInfo?.groupAvatarUrl]);

  const changeAvatar = (newAvatarURL: string) => {
    const convoId = conversationID;
    convoId &&
      updateGroupAvatarUrl?.(newAvatarURL, convoId)
        .then(res => {
          if (res?.groupAvatarUrl && res.groupID) {
            setAvatarImage(newAvatarURL);
          } else {
            //TODO for the failed update group avatart
            toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
          }
        })
        .catch(err => {
          //TODO for the failed update group avatart
          toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
        });
  };

  const validateGroupName = (name: string) => {
    const check = /^[a-zA-Z0-9 ,]*$/;
    return name.length > 0 && name.length <= 25 && check.test(name) && name.trim().length > 0;
  };

  useEffect(() => {
    setIsGroupNameValid(validateGroupName(groupName));
  }, [groupName]);

  const handleSubmitReport = (code: string, content: string) => {
    createTheReport({
      flag_code: code,
      reference_content_id: userName ?? '', //Change conversation ID here on API Integration.
      reference_content_type: 'chat',
      flag_content: content,
      reported_username: blockId,
    }).then(res => {
      res?.createReport.is_successful
        ? toastWrapper('success', translate('msg_success_user_report', { username: userName }) ?? '')
        : toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
    });
    setShowReportModal(false);
  };

  const showHideReportModal = (isOpen: boolean) => {
    setShowReportModal(isOpen);
  };

  const setAdmin = async (memberRole: number, userID: string, displayName: string) => {
    const adminCount = groupMembersList?.filter(item => item.memberRole === 1).length;
    if (memberRole === 1 && adminCount && adminCount <= 1) {
      log('Group cannot have 0 admin');
    } else if (conversationID) {
      await setGroupOwner?.(userID, displayName);
    }
  };

  const onRemoveUser = (userId: string) => {
    const convoId = conversationID;
    convoId &&
      kickGroupMembers?.([userId], convoId)
        .then(response => {
          if (response?.kickedUserIDs && response.groupID) {
            setGroupMembersList?.(prevUsers => {
              const newMemberList = prevUsers?.filter(user => !response.kickedUserIDs.includes(user.userID));
              return newMemberList;
            });
          }
        })
        .catch(err => {
          toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
        });
  };

  const onSaveGroupName = () => {
    if (isGroupNameValid) {
      groupName &&
        groupInfo?.groupID &&
        updateGroupName?.(groupName, groupInfo?.groupID)
          .then(res => {
            if (res?.groupID && res?.groupName) {
              setGroupNameTouched(false);
              setControlGroupName(groupName);
              toastWrapper('success', translate('chat.update_group_name.successful_message') ?? '');
            } else {
              toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
            }
          })
          .catch(err => {
            toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
          });
    }
  };

  const goToProfile = (userID: string) => {
    navigate(AppRoutes.users + `/${userID}`);
  };

  const handleBlockCheck = (value: boolean) => {
    setIsBlocking(value);
  };

  const unblock = (username: string) => {
    crmCommunityUnblock({ username: username }).then(res => {
      if (res?.crmCommunityUnblock.is_successful) {
        toastWrapper('success', translate('msg_success_user_unblocked', { username: userName }) ?? '');
        setIsBlocking?.(false);
      } else {
        toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
      }
    });
  };

  useEffect(() => {
    groupInfo?.groupName && setControlGroupName(groupInfo?.groupName);
  }, [groupInfo?.groupName]);

  useEffect(() => {
    const modalCheck = isActionOptionOpen || showReportModal || avatarModal.isOpen;
    setIsModalOpen?.(modalCheck);
  }, [isActionOptionOpen, showReportModal, avatarModal.isOpen]);

  return (
    <>
      {avatarModal.isOpen && <GroupAvatarModal close={avatarModal.close} changeImageFE={changeAvatar} />}
      <OptionsActionModal
        isOpen={isActionOptionOpen}
        isClose={() => setIsActionOptionOpen(false)}
        type={optionActionType}
        handleAction={handleAction}
        isGroupChat={true}
        conversationID={conversationID}
        isMinimized={isMinimized}
        userName={userName}
        userID={blockId}
      />
      <div className="p-[1rem] w-full">
        <PlayerAvatar
          altLabel={translate(IMAGE_ALT_LABELS.preference_group_avatar as TxKeyPath) ?? ''}
          openUploadGroupAvatar={avatarModal.open}
          isGroupAvatar
          isAdmin={userAdmin === 1}
          avatarUrl={avatarUrl ?? ''}
          avatarSize={4.25}
          dir="row"
          avatarStyle="!bg-[#2388FF33] max-w-[4.25rem] !border-gray-200-zinc-700 object-cover"
        />
      </div>
      <div className={`px-[1rem] ${userAdmin === 1 ? 'pb-[1rem]' : ''} w-full`}>
        {userAdmin === 1 ? (
          <div className="relative">
            <div className="mb-[2rem]">
              <InputField
                label={translate('connect.group_chat_name') ?? ''}
                formPlaceHolder={translate('formPlaceholder_start_typing') ?? ''}
                isSecret={false}
                hasFocused={false}
                errorMessage={
                  groupNameTouched ? (isGroupNameValid ? '' : translate('chat_preference_page.invalid_groupchat_name') ?? '') : ''
                }
                isUserAllowedToType={(value: string) => true}
                retrieveValue={(value: any) => setGroupName(value)}
                retrieveFocus={(value: boolean) => {
                  if (value && !groupNameTouched) {
                    setGroupNameTouched(true);
                  }
                }}
                style={{
                  containerStyle: 'w-full',
                  inputStyle:
                    'my-[0.5rem] text-secondary/50 font-regular text-fs-body-large placeholder:text-fs-body-large placeholder-text-secondary/50',
                  underlineStyle: 'h-[0.0625rem] bg-secondary/20',
                  errorStyle: `${isMinimized && 'text-fs-caption pt-[0.125rem]'}`,
                }}
                existingValue={groupInfo?.groupName}
                labelColor="text-secondary/50"
                maxLength={25}
                showCounter
              />
            </div>
            {controlGroupName !== groupName && (
              <div className="w-full mb-[1rem]">
                <PrimaryButton
                  className="
                    w-full
                    py-[0.5rem]
                    px-[1.5rem]
                    rounded-[2px] 2K:rounded-[3.55px] 4K:rounded-[5.33px] 8K:rounded-[10.66px]
                    !text-fs-body"
                  disabledStyle={'bg-zinc-100-neutral-800'}
                  text={translate('action.save_changes') ?? ''}
                  action={onSaveGroupName}
                  isDisabled={groupName.length === 0}
                />
              </div>
            )}
          </div>
        ) : (
          <>
            <div className="mt-[1.75rem] mb-[0.5rem] font-regular text-fs-body-large text-secondary/70">{groupInfo?.groupName}</div>
            <div className="h-[0.0625rem] bg-secondary/20 mb-[1.5rem]" />
          </>
        )}
      </div>
      <div className="w-full ltr:pl-[1rem] ltr:pr-[0.5rem] rtl:pl-[0.5rem] rtl:pr-[1rem] pb-[1rem] inline-flex flex-col gap-[0.5rem]">
        <div className="font-regular text-fs-caption text-neutral-400-zinc-500">
          {translate('connect.participants', {
            participants: groupMemberCount,
            max_participants: GROUP_MEMBER_COUNT,
          })}
        </div>
        {/* SCROLLABLE PART */}
        <div className="max-h-[11rem] custom-scroll overflow-y-auto">
          {groupMembersList?.map(user => (
            <PlayerAvatar
              altLabel={translate(IMAGE_ALT_LABELS.preference_group_member_avatar as TxKeyPath) ?? ''}
              containerStyle="w-full py-[0.5rem] gap-[0.5rem]"
              displayNameStyle="font-regular text-fs-body-small text-secondary"
              userIdStyle="font-regular text-fs-body-small text-secondary/50"
              displayName={user.userName}
              userId={user.userID}
              avatarUrl={user.memberAvatarUrl}
              avatarSize={2}
              dir="row"
              isAdmin={user.memberRole === 1}
              customCTA={
                <MoreOption
                  setOptionActionType={type => {
                    setOptionActionType(type);
                    setUserName(user.userName);
                    setBlockId(user.userID);
                    if (type === 'report') {
                      setShowReportModal(true);
                    } else {
                      setIsActionOptionOpen(true);
                    }
                  }}
                  goToProfile={() => goToProfile(user.userID)}
                  setAdmin={() => setAdmin(user.memberRole, user.userID, user.userName)}
                  chatType="private"
                  origin="preference"
                  userType={userAdmin === 1 ? 'admin' : 'regular'}
                  isUserAdmin={user.memberRole === 1}
                  isUserMember={true}
                  removeUser={() => onRemoveUser(user.userID)}
                  conversationID={user.userID}
                  checkBlocking
                  returnCheckBlocking={handleBlockCheck}
                  unblock={unblock}
                />
              }
            />
          ))}
        </div>
      </div>
      <ReportModal
        setIsReportModalOpen={showHideReportModal}
        handleSubmitReportButton={handleSubmitReport}
        title={translate('chat.report_user.header_title', { name: userName }) ?? ''}
        isOpen={showReportModal}
        subtitle={translate('chat.report_user.subtitle') ?? ''}
      />
    </>
  );
};

export default GroupPreference;
