import { DocumentNode, useLazyQuery } from '@apollo/client';
import { clearUser, setErrorObject, useAppDispatch } from '@fe-monorepo/store';
import { useEffect, useState } from 'react';

type APIError = {
  code: string;
  message: string;
};

type ResponseType<ItemType> = {
  is_successful: boolean;
  error_code: string;
  error_msg: string;
  data: ItemType;
};

type QueryVariables<ItemType> = {
  [queryName: string]: ResponseType<ItemType>;
};

type QueryParams<InputModel> = {
  details?: InputModel;
};

// TODO: ItemType that is object should have a default value of null
// TODO: ItemType that is an array should have a default value of []
export const useFetchQuery = <ItemType, InputModel = void>(query: DocumentNode, defaultValue: ItemType) => {
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [data, setData] = useState<ItemType>(defaultValue);
  const [isValid, setValidity] = useState<boolean>(false);
  const [error, setError] = useState<APIError>();

  const [fetchGQL, { data: fetchResponse, error: errorResponse }] = useLazyQuery<QueryVariables<ItemType>, QueryParams<InputModel>>(query, {
    errorPolicy: 'all',
    onCompleted(data: QueryVariables<ItemType>) {
      for (const key in data) {
        if (Object.prototype.hasOwnProperty.call(data, key)) {
          const element = data[key];

          // console.log({ key, element });

          if (element.is_successful === false) {
            const errorObject = {
              APIName: key,
              errorCode: element.error_code,
              errorMessage: element.error_msg,
            };

            dispatch(setErrorObject(errorObject));
          }
        }
      }
    },
  });

  const trackError = () => {};

  const fetch = async (model: InputModel) => {
    setIsLoading(true);

    await fetchGQL({ variables: { details: model } });
  };

  useEffect(() => {
    if (fetchResponse) {
      const responseKey = Object.keys(fetchResponse)[0];

      const response = fetchResponse[responseKey];

      if (response) {
        if (response.is_successful) {
          setData(response.data);
        } else {
        }

        setIsLoading(false);

        setValidity(response.is_successful);

        setError({
          message: response.error_msg,
          code: response.error_code,
        });

        if (response.error_code === '1010') {
          dispatch(clearUser());
        }
      }
    }
  }, [fetchResponse]);

  useEffect(() => {
    if (errorResponse) {
      setIsLoading(false);
    }
  }, [errorResponse]);

  return {
    isLoading,
    isValid,
    data,
    error,

    fetch,
  };
};
