function Step({ step }: any) {
  const { active, icon, label, number } = step ?? {};
  return (
    <div data-active={active} className="w-full border-b-2 pb-2 data-[active=true]:border-active border-disabled">
      <h4 className="flex items-center gap-8">
        <span data-active={active} className="text-sm data-[active=true]:text-active text-disabled font-bold">
          {number}
        </span>
        <span data-active={active} className="text-sm data-[active=true]:text-active text-disabled font-normal">
          {label}
        </span>
      </h4>
    </div>
  );
}

export default Step;
