import { logCustomEvent } from '@braze/web-sdk';
import { IMAGES } from '@fe-monorepo/assets';
import {
  BrazeCustomEvents,
  Logger,
  MixPanelEvents,
  PAGE_DESCRIPTION,
  PAGE_KEYWORDS,
  PAGE_TITLE,
  TxKeyPath,
  convertPriceWithSymbol,
} from '@fe-monorepo/helper';
import {
  useCart,
  useCheckProductAvailability,
  useInvoice,
  useOrder,
  useProduct,
  useProductDetails,
  useRelatedProducts,
  useToastMessage,
  useTranslate,
  useUserProfile,
} from '@fe-monorepo/hooks';
import { CartItem, InvoiceProduct, LMDModel, OptionsVariant, ProductUnavailableCode } from '@fe-monorepo/models';
import { RootState, store } from '@fe-monorepo/store';
import ToastMessage from '@fe-web/Atoms/ToastMessage';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import Loading from '@fe-web/Templates/Loading';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import useQuantity from '@fe-web/hooks/useQuantity';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { viewProductEvent } from '../../../../../helper/webAnalytics';
import { getCountOfDigitalProducts, mapProductDetailToCart, validateSameDigitalProducts } from '../../../../lib/helper';
import ProductDetail from '../../../../lib/types/productDetailedPage';
import { AppRoutes } from '../../../app.routes.enum';
import mixpanelHelper from '../../../helpers/mixpanelHelper';
import CountryDropDown from '../ShopCountryDropDown';
import { useShopContext } from '../ShopLayout';
import BarSection from './Components/BarSection';
import DescriptionSection from './Components/DescriptionSection';
import MainSection from './Components/MainSection/MainSection';
import RecommendationSection from './Components/RecommendationSection';

export type DescriptionValues = 'details' | 'delivery' | 'specification';
const DEFAULT_CITY = 'Riyadh';

//This is for the recently viewed products feature

const { DefaultPlaceholder } = IMAGES;

function createCardImages(image: string, productId?: string, sectionId?: string, username?: string, productName?: string, brand?: string) {
  if (sectionId === 'undefined') {
    sectionId = 'recently';
  }
  const dateAndTimeCreated = new Date();
  if (getCardImages(username as string)) {
    const cardImages = JSON.parse(getCardImages(username as string) || '{}');
    //if duplicate productId and sectionId then do not push to array
    for (let i = 0; i < cardImages.length; i++) {
      if (cardImages[i].productId === productId && cardImages[i].sectionId === sectionId) {
        return;
      }
    }
    cardImages.push({ image, productId, sectionId, dateAndTimeCreated, productName, brand });
    // localStorage.setItem('cardImages', JSON.stringify(cardImages));
    localStorage.setItem(`cardImages_${username}`, JSON.stringify(cardImages));
  } else {
    const cardImages = [{ image, productId, sectionId, dateAndTimeCreated, productName, brand }];
    //  localStorage.setItem('cardImages', JSON.stringify(cardImages));
    localStorage.setItem(`cardImages_${username}`, JSON.stringify(cardImages));
  }
}

function getCardImages(username: string) {
  const cardImages = localStorage.getItem(`cardImages_${username}`);
  return cardImages;
}

const useSimilarProducts = () => {
  const ref = useRef<HTMLDivElement>(null);

  const navigateToSimilarProducts = () => {
    ref.current?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
  };

  return {
    ref,
    navigate: navigateToSimilarProducts,
  };
};

const useTrackViewProduct = (product: ProductDetail | undefined, LMD: LMDModel, variantID: string, isAvailable: boolean | null) => {
  const { shopCategory } = useShopContext();

  const track = () => {
    if (!product?.product_code || !LMD.lmd_code || !shopCategory.main?.menu_code || isAvailable === null) return;

    const item: ProductDetail = {
      ...product,
      variant_id: variantID,
    };

    viewProductEvent(
      {
        categoryID: shopCategory.main?.menu_code ?? '',
        categoryName: shopCategory.main?.name_en ?? '',

        subCategoryID: shopCategory?.sub?.collection_code ?? '',
        subCategoryName: shopCategory?.sub?.name_en ?? '',
      },
      item,
      `${LMD.lmd_code} ${LMD.city}`,
      isAvailable,
    );
  };

  // track product when the user lands on the page
  useEffect(() => {
    if (variantID || isAvailable === null) return;

    track();
  }, [product?.product_code, shopCategory.main?.menu_code, LMD.lmd_code, isAvailable]);

  // track product as the variant ID changes
  useEffect(() => {
    if (!variantID || isAvailable === null) return;

    track();
  }, [product?.product_code, variantID, shopCategory.main?.menu_code, LMD.lmd_code, isAvailable]);
};

const ProductDetailedPage = () => {
  const username = useSelector((state: RootState) => state.user.userContext.username);
  // createCardImages is used for the Recently Viewed Feature, the useEffect is used to create the images for the cards
  const { collectionCode, productID } = useParams<{ collectionCode: string; productID: string }>();

  const [searchParams] = useSearchParams();

  const shopType = searchParams.get('shopType') ?? 'STC Play Shop';
  const decodedShopType = decodeURIComponent(shopType);

  const { setProductInfo } = useShopContext();

  const { productDetails, productVariants, productSpecifications } = useProductDetails();
  const { checkProductAvailability } = useCheckProductAvailability();
  const { getAll, related_product, more_product } = useRelatedProducts();

  const barSectionMiniImage = productDetails?.data?.media[0];
  const barSectionPrice = productDetails?.data?.lowest_selling_price;

  // Add a graphql section to fetch data
  const [isAvailable, setAvailability] = useState<boolean | null>(null);

  const [product, setProduct] = useState<ProductDetail>();
  const [variants, setVariants] = useState<number[]>([]);
  const [indexVariant, setIndexVariant] = useState<number>();
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [isPassedContainer, setIsPassedContainer] = useState<boolean>(false);
  const [isAvailabilityLoading, setIsAvailabilityLoading] = useState<boolean>(true);

  const [descriptionSection, setDescriptionSection] = useState<DescriptionValues>('details');
  const [productSelectVariants, setProductSelectedVariants] = useState<string>('');
  const lmd_code = useSelector((state: RootState) => state.app.lmdCode);
  const country = useSelector((state: RootState) => state.country.lmds);

  const quantity = useQuantity();
  const { addToCart, itemsInCart } = useCart();
  const { wishlistData, addProduct, getAllWishlist, addToWishList, removeToWishList } = useProduct();
  const similarProducts = useSimilarProducts();
  const { invoiceInfo, createInvoice, getInvoice } = useInvoice(true);
  const { isLoggedIn } = useUserProfile();
  const { language } = usePageLayout();
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const [quantityError, setQuantityError] = useState<string>('');
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const isMobile = useMobileDetect();

  const isTrackedRef = useRef<boolean>(false);
  const { currency } = store.getState().app;

  // Some functions for the buttons
  const switchToDelivery = () => setDescriptionSection('delivery');

  const switchToDetails = () => setDescriptionSection('details');
  const switchToSpecification = () => setDescriptionSection('specification');
  const { errorToastMessage } = useToastMessage();
  const { error } = Logger();
  const { cancelOrder } = useOrder();

  const handleScroll = (val: number, bool: boolean) => {
    if (0 > val && bool === false) {
      setIsPassedContainer(true);
    } else setIsPassedContainer(false);
  };

  useEffect(() => {
    const elem = document.getElementById('sectionContainer')!;
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0];
      handleScroll(entry.boundingClientRect.top, entry.isIntersecting);
    });
    observer.observe(elem);
  }, []);

  const buyNow = () => {
    addProductToCart('buy_now');

    if (product) {
      const hasDiscount = product.sellingPrice < product.price;

      const finalPrice = hasDiscount ? product.sellingPrice : product.price;

      const formattedPrice = convertPriceWithSymbol(finalPrice, 'commaDecimal', true);

      mixpanelHelper.trackEvent(MixPanelEvents.productBuyNow, {
        'Product Name': product.name.en,
        'Product Code': product.product_code,
        Quantity: quantity.value,
        'Shop Type': decodedShopType,
        'Product Price': formattedPrice,
        Currency: currency,
      });

      logCustomEvent(BrazeCustomEvents.ProductBuyNow, {
        'Product Name': product.name.en,
        'Product Code': product.product_code,
        Quantity: quantity.value,
        'Shop Type': decodedShopType,
        'Product Price': formattedPrice,
        Currency: currency,
      });
    }
  };

  const handleAddToCart = () => {
    addProductToCart('add_to_cart');

    if (product) {
      const hasDiscount = product.sellingPrice < product.price;

      const finalPrice = hasDiscount ? product.sellingPrice : product.price;

      const formattedPrice = convertPriceWithSymbol(finalPrice, 'commaDecimal', true);

      mixpanelHelper.trackEvent(MixPanelEvents.productAddedToCart, {
        'Product Name': product.name.en,
        'Product Code': product.product_code,
        Quantity: quantity.value,
        'Shop Type': decodedShopType,
        'Product Price': formattedPrice,
        Currency: currency,
      });

      logCustomEvent(BrazeCustomEvents.ProductAddedToCart, {
        'Product Name': product.name.en,
        'Product Code': product.product_code,
        Quantity: quantity.value,
        'Shop Type': decodedShopType,
        'Product Price': formattedPrice,
        Currency: currency,
      });
    }
  };

  const digitalProductsValidation = (products: InvoiceProduct[], product_code: string) => {
    let isProductLimited = false;
    if (getCountOfDigitalProducts(products) === 5) {
      errorToastMessage({
        className: 'SD:!h-[4.5rem] 2K:h-[1rem] 4K:h-[2rem] 8K:h-[3rem]',
        message: (
          <ToastMessage type="error" className="!items-start" errorIconBaseHeight={20} errorIconBaseWidth={20}>
            <div className="flex w-fit gap-[0.25rem]">
              <div className={`${isMobile ? 'w-[15%]' : 'w-[11%]'}`}>
                <span className="font-medium text-fs-body text-error"> {translate('shop.digital_products_validation.oops')} </span>
              </div>
              <div className={`${isMobile ? 'w-[85%]' : 'w-[89%]'}`}>
                <span className="text-fs-body font-regular text-error">
                  {' '}
                  {translate('shop.digital_products_validation.quantity_limit_error')}{' '}
                </span>
              </div>
            </div>
          </ToastMessage>
        ),
      });
      isProductLimited = true;
    } else if (validateSameDigitalProducts(products, product_code || '', productSelectVariants)) {
      errorToastMessage({
        className: 'SD:!h-[4.5rem] 2K:h-[1rem] 4K:h-[2rem] 8K:h-[3rem]',
        message: (
          <ToastMessage type="error" className="!items-start" errorIconBaseHeight={20} errorIconBaseWidth={20}>
            <div className="flex w-fit gap-[0.25rem]">
              <div className={`${isMobile ? 'w-[15%]' : 'w-[11%]'}`}>
                <span className="font-medium text-fs-body text-error"> {translate('shop.digital_products_validation.oops')} </span>
              </div>
              <div className={`${isMobile ? 'w-[85%]' : 'w-[89%]'}`}>
                <span className="text-fs-body font-regular text-error"> {translate('shop.digital_products_validation.same_product')} </span>
              </div>
            </div>
          </ToastMessage>
        ),
      });
      isProductLimited = true;
    }
    return isProductLimited;
  };

  const showProductQuantityLimitationError = () => {
    errorToastMessage({
      className: `SD:!h-[3.5rem] 2K:h-[1rem] 4K:h-[2rem] 8K:h-[3rem] ${isMobile ? 'mx-[1rem]' : ''}`,
      message: (
        <ToastMessage type="error" className="!items-start" errorIconBaseHeight={20} errorIconBaseWidth={20}>
          <div className="flex w-fit gap-[0.25rem]">
            <div className={`w-full`}>
              <span className="text-fs-body font-regular text-error">{translate('validation_invalid_product_quantity')} </span>
            </div>
          </div>
        </ToastMessage>
      ),
    });
  };

  const addProductToCart = async (action: string) => {
    if (isLoggedIn || (!isLoggedIn && invoiceInfo)) {
      if (invoiceInfo?.invoice_number) {
        if (!product?.isPhysical && product) {
          const isLimited = digitalProductsValidation(invoiceInfo.products, product?.product_code);
          if (isLimited) {
            return;
          }
        }
        if (invoiceInfo.is_checkout === 1 && invoiceInfo.status_code === 'pending') {
          await cancelOrder({ invoice_number: invoiceInfo.invoice_number });
        }
        addProduct({
          invoice_number: invoiceInfo?.invoice_number || '',
          variant_id: parseInt(productSelectVariants),
          product_code: product?.product_code ? product?.product_code : '',
          qty: quantity.value,
        }).then(response => {
          if (response?.is_successful) {
            setAvailability(true);
            getInvoice({ details: { invoice_number: invoiceInfo.invoice_number } });
            if (action === 'buy_now') {
              navigate(AppRoutes.cart);
            }
          } else if (response?.error_code === ProductUnavailableCode) {
            setAvailability(false);
          } else if (response?.error_code === '2017') {
            showProductQuantityLimitationError();
          }

          if (!response?.is_successful && response?.error_code && response?.error_msg) {
            mixpanelHelper.trackEvent(MixPanelEvents.cartError, {
              'Error Code': response.error_code,
              'Error Message': response.error_msg,
              'Product Code': product?.product_code,
            });

            logCustomEvent(BrazeCustomEvents.CartError, {
              'Error Code': response.error_code,
              'Error Message': response.error_msg,
              'Product Code': product?.product_code,
            });
          }
        });
      } else {
        let selected_lmd_code = lmd_code.lmd_code;
        if (!lmd_code.lmd_code) {
          const selectedCity = country.find(item => item.city === DEFAULT_CITY);
          if (selectedCity) {
            selected_lmd_code = selectedCity.lmd_code;
          }
        }

        createInvoice({ lmd_code: selected_lmd_code })
          .then(response => {
            if (response?.data.invoice_number) {
              addProduct({
                invoice_number: response?.data.invoice_number || '',
                variant_id: parseInt(productSelectVariants),
                product_code: product?.product_code ? product?.product_code : '',
                qty: quantity.value,
              }).then(resp => {
                if (resp?.is_successful) {
                  getInvoice({ details: { invoice_number: response?.data?.invoice_number } });
                  if (action === 'buy_now') {
                    navigate(AppRoutes.cart);
                  }
                }

                if (!resp?.is_successful && resp?.error_code && resp?.error_msg) {
                  mixpanelHelper.trackEvent(MixPanelEvents.cartError, {
                    'Error Code': resp.error_code,
                    'Error Message': resp.error_msg,
                    'Product Code': product?.product_code,
                  });

                  logCustomEvent(BrazeCustomEvents.CartError, {
                    'Error Code': resp.error_code,
                    'Error Message': resp.error_msg,
                    'Product Code': product?.product_code,
                  });
                }
              });
            }
          })
          .catch(err => {
            error('ERROR In Saving!', err);
          });
      }
    } else if (productDetails.data) {
      const temp_product: CartItem = {
        ...productDetails.data,
        qty: quantity.value,
        variant_id: productSelectVariants,
      };
      if (product?.price && temp_product.lowest_actual_cost !== product?.price) {
        temp_product.lowest_actual_cost = product.price;
      }
      if (product?.sellingPrice && temp_product.lowest_selling_price !== product?.sellingPrice) {
        temp_product.lowest_selling_price = product.sellingPrice;
      }
      const product_to_be_added = mapProductDetailToCart(temp_product, language);
      if (!product?.isPhysical) {
        const isLimited = digitalProductsValidation(itemsInCart, product_to_be_added?.product_code);
        if (isLimited) {
          return;
        }
      } else {
        const same_product = itemsInCart.find(
          item => item.product_code === product_to_be_added.product_code && item.variant_id === product_to_be_added.variant_id,
        );
        if (same_product) {
          const product_qty = same_product.qty + product_to_be_added.qty;
          if (product_qty > 5) {
            showProductQuantityLimitationError();
            return;
          }
        }
      }
      addToCart(product_to_be_added);
      if (action === 'buy_now') {
        navigate(AppRoutes.cart);
      }
    }
  };

  const addVariant = (index: number, variant_id: number) => {
    if (variants?.length >= index && !productVariants?.isLoading) {
      const tempArray: number[] = [];

      for (let temp = 0; temp < index + 1; temp++) {
        if (temp === index) {
          tempArray[index] = variant_id;
        } else {
          tempArray[temp] = variants[temp];
        }
      }
      setVariants(tempArray);
      setIndexVariant(index);
    }
  };

  // useEffect for data fetching
  useEffect(() => {
    // Do some initial data fetching here and change the product state...
    if (productID) {
      productDetails.fetch({ product_code: productID });
    }
  }, [productID]);

  useEffect(() => {
    if (productDetails.data && productDetails.isValid) {
      const {
        name_ar,
        name_en,
        brand,
        lowest_selling_price,
        lowest_actual_cost,
        media,
        description_en,
        description_ar,
        is_digital,
        options,
        product_code,
        variant_id,
        quantity,
        taxable,
        sku,
      } = productDetails.data;

      const isPhysical = is_digital !== 1;

      if (productID && name_en && name_ar) {
        setProductInfo({ code: productID, name_en, name_ar });
      }

      setProduct(() => {
        return {
          price: lowest_actual_cost,
          sellingPrice: lowest_selling_price || lowest_actual_cost,
          isPhysical: isPhysical,
          name: { en: name_en, ar: name_ar },
          brand: { en: brand?.name_en, ar: brand?.name_ar, collection_code: brand.collection_code },
          images: media,
          details: { en: description_en, ar: description_ar },
          variants: options,
          product_code: product_code,
          variant_id: variant_id,
          quantity: quantity,
          isTaxable: taxable,
          sku,
        };
      });
      setIsButtonDisabled(productDetails?.data?.options.length > 0 ? true : false);
      if (product_code) {
        getAll({ product_code: product_code });
      }
      if (productDetails?.data?.options.length === 0) {
        setProductSelectedVariants(variant_id);
        if (variant_id === '' && productDetails.isValid === true) {
          setIsPageLoaded(true);
        }
      } else {
        setIsPageLoaded(true);
      }
    } else {
      setIsPageLoaded(true);
    }
  }, [productDetails.data]);

  //create a useRef counter to be used for the Recently Viewed Feature
  const counter = useRef<number>(0);

  useEffect(() => {
    //set counter useRef to increment
    counter.current++;
    if (counter.current > 1) {
      if (productDetails.data.media[0]) {
        createCardImages(
          productDetails.data.media[0],
          productID,
          collectionCode,
          username,
          productDetails.data.name_en,
          productDetails.data.brand.name_en,
        );
      } else {
        createCardImages(
          DefaultPlaceholder.toString(),
          productID,
          collectionCode,
          username,
          productDetails.data.name_en,
          productDetails.data.brand.name_en,
        );
      }
    }
  }, [productDetails.data]);

  useEffect(() => {
    if (productID && variants?.length) {
      productVariants.fetch({ product_code: productID, option_ids: variants });
    }
  }, [variants]);

  useEffect(() => {
    if (productVariants?.data) {
      if (indexVariant || indexVariant === 0) {
        if (product?.variants) {
          let tempVariants: OptionsVariant[] = [];
          for (let temp = 0; temp < product?.variants?.length; temp++) {
            if (temp <= indexVariant) {
              tempVariants.push(product?.variants[temp]);
            }
          }
          if (!(indexVariant + 1 === product?.variants?.length)) {
            tempVariants = tempVariants?.concat(productVariants?.data?.options);
          }

          setProduct({
            ...product,
            variants: tempVariants,
            price:
              variants?.length === tempVariants?.length && productVariants?.data?.lowest_actual_cost
                ? productVariants?.data?.lowest_actual_cost
                : variants?.length === tempVariants?.length && productVariants?.data?.original_cost
                ? productVariants?.data?.original_cost
                : productDetails?.data?.lowest_selling_price || productDetails?.data?.lowest_actual_cost,
            sellingPrice:
              variants?.length === tempVariants?.length && productVariants?.data?.selling_price
                ? productVariants?.data?.selling_price
                : productDetails?.data?.lowest_selling_price || productDetails?.data?.lowest_actual_cost,
            images:
              variants?.length === tempVariants?.length && productVariants?.data?.media?.length
                ? productVariants?.data?.media
                : productDetails?.data?.media,
            isTaxable: productVariants?.data?.taxable || productDetails?.data?.taxable,
          });
          //setIsButtonDisabled(variants?.length === tempVariants?.length ? false : true);
          if (variants?.length === tempVariants?.length) {
            setProductSelectedVariants(productVariants?.data.variant_id);
          } else {
            setProductSelectedVariants('');
          }
        }
      }
    }
  }, [productVariants?.data]);

  const checkIfProductIsAvailable = async (action?: string) => {
    setIsAvailabilityLoading(true);
    await checkProductAvailability({
      product_code: product?.product_code ? product?.product_code : '',
      variant_id: productSelectVariants,
      lmd_code: lmd_code.lmd_code,
      qty: quantity.value,
    })
      .then(response => {
        if (response?.isProductAvailable.is_successful) {
          if (action === 'quantity' && quantity.value > 1 && product?.product_code === '1992') {
            setAvailability(false);
            setIsButtonDisabled(true);
            setQuantityError(translate('shop.productPage.quantity_error_message') ?? '');
          } else if (quantity.value > 1 && product?.product_code === '1992') {
            setQuantityError('');
            setAvailability(false);
            setIsButtonDisabled(true);
          } else {
            setAvailability(true);
            setQuantityError('');
            setIsButtonDisabled(false);
          }
        } else {
          setAvailability(false);
          setIsButtonDisabled(true);
          if (action === 'quantity') {
            setQuantityError(translate('shop.productPage.quantity_error_message') ?? '');
          }
        }
        setIsAvailabilityLoading(false);

        if (
          !response?.isProductAvailable.is_successful &&
          response?.isProductAvailable.error_code &&
          response?.isProductAvailable.error_msg
        ) {
          mixpanelHelper.trackEvent(MixPanelEvents.cartError, {
            'Error Code': response.isProductAvailable.error_code,
            'Error Message': response.isProductAvailable.error_msg,
            'Product Code': product?.product_code,
          });

          logCustomEvent(BrazeCustomEvents.CartError, {
            'Error Code': response.isProductAvailable.error_code,
            'Error Message': response.isProductAvailable.error_msg,
            'Product Code': product?.product_code,
          });
        }
      })
      .catch(err => {
        setIsButtonDisabled(true);
        if (action === 'quantity') {
          setQuantityError(translate('shop.productPage.quantity_error_message') ?? '');
        }
        setIsAvailabilityLoading(false);
      });
    if (!action && !isPageLoaded) {
      setIsPageLoaded(true);
    }
  };

  useEffect(() => {
    if (productSelectVariants && lmd_code) {
      checkIfProductIsAvailable();
    }
  }, [productSelectVariants, lmd_code]);

  useEffect(() => {
    if (productSelectVariants && lmd_code) {
      checkIfProductIsAvailable('quantity');
    }
  }, [quantity.value]);

  useEffect(() => {
    /* This is for checking the product availability with no variant options */
    if (lmd_code && product?.variant_id && product?.variants?.length === 0) {
      checkProductAvailability({
        product_code: product?.product_code,
        variant_id: product.variant_id,
        lmd_code: lmd_code.lmd_code,
        qty: quantity.value,
      });
    }
  }, [lmd_code]);

  useEffect(() => {
    if (productID && productSelectVariants && productDetails.data && productDetails.data.is_digital === 0) {
      const numVariantId = parseInt(productSelectVariants);
      if (numVariantId) {
        productSpecifications.fetch({ variant_id: numVariantId });
      }
    }
  }, [productSelectVariants]);

  const [isSuccess, setIsSuccess] = useState(true);
  const [Message, setMessage] = useState('');

  const checkIsSuccess = (errorMessage: string) => {
    setIsSuccess(false);
    setMessage(errorMessage);
  };

  useEffect(() => {
    isLoggedIn && getAllWishlist({ direction: '', last_cursor: '' });
  }, [isLoggedIn]);

  useEffect(() => {
    //toast modal
    if (!Message) return;
    if (!isSuccess) {
      toast.error(<span className="not-italic font-medium leading-6 text-green text-fs-body">{Message}</span>, {
        position: 'top-center',
        hideProgressBar: true,
        closeButton: false,
        className: 'bg-white100 font-regular w-full SD:mt-0',
      });
    }
    setMessage('');
  }, [isSuccess, Message]);

  useTrackViewProduct(product, lmd_code, product?.variant_id ?? productSelectVariants, isAvailable);

  useEffect(() => {
    mixpanelHelper.trackPageView('SHOP - PRODUCT DETAILS PAGE', 'shop_pdp', window.location.pathname, document.referrer);
    brazeHelper.trackPageView('SHOP - PRODUCT DETAILS PAGE', 'shop_pdp', window.location.pathname, document.referrer);
  }, []);

  useEffect(() => {
    if (product && !isTrackedRef.current) {
      const hasDiscount = product.sellingPrice < product.price;

      const finalPrice = hasDiscount ? product.sellingPrice : product.price;

      const formattedPrice = convertPriceWithSymbol(finalPrice, 'commaDecimal', true);

      mixpanelHelper.trackEvent(MixPanelEvents.productViewed, {
        'Product Name': product.name.en,
        'Product Code': product.product_code,
        SKU: product.sku,
        Brand: product.brand.en,
        'Shop Type': decodedShopType,
        'Product Type': product.isPhysical ? 'Physical' : 'Digital',
        'Product Price': formattedPrice,
        Currency: currency,
      });

      logCustomEvent(BrazeCustomEvents.ProductViewed, {
        'Product Name': product.name.en,
        'Product Code': product.product_code,
        SKU: product.sku,
        Brand: product.brand.en,
        'Shop Type': decodedShopType,
        'Product Type': product.isPhysical ? 'Physical' : 'Digital',
        'Product Price': formattedPrice,
        Currency: currency,
      });

      isTrackedRef.current = true;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  return (
    <>
      <CustomHelmet
        pageTitle={translate(PAGE_TITLE.shop as TxKeyPath) ?? ''}
        metaDescription={translate(PAGE_DESCRIPTION.shop as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.shop as TxKeyPath) ?? ''}
      />
      <CountryDropDown classNames="z-[2]" />
      <main className="min-h-full w-full pt-[1.125rem] pb-[3.5rem] SD:pb-[5rem] flex flex-col gap-80 2K:gap-[142.22px] 4K:gap-[213.33px] 8K:gap-[426.66px]">
        {product && (
          <MainSection
            product={product}
            isAvailable={isAvailable ?? true}
            quantity={quantity}
            variants={variants}
            addToCart={handleAddToCart}
            navigateToSimilarProducts={similarProducts.navigate}
            buyNow={buyNow}
            setVaraints={addVariant}
            isButtonDisabled={isButtonDisabled}
            checkIsSuccess={checkIsSuccess}
            quantityErrorMessage={quantityError}
            isTaxable={product?.isTaxable as number}
            wishlistData={wishlistData}
            addToWishList={addToWishList}
            removeToWishList={removeToWishList}
            getAllWishlist={getAllWishlist}
          />
        )}

        <div className="relative">
          <BarSection
            productImage={barSectionMiniImage}
            price={barSectionPrice}
            switchToDelivery={switchToDelivery}
            switchToDetails={switchToDetails}
            switchToSpecification={switchToSpecification}
            isAvailable={isAvailable ?? true}
            isPhysical={product?.isPhysical}
            addToCart={handleAddToCart}
            isButtonDisabled={isButtonDisabled}
            changeBar={isPassedContainer}
          />

          <DescriptionSection
            chosenSection={descriptionSection}
            details={{ title: translate('shop.productPage.productDetails') ?? '', content: product?.details }}
            specifications={productSpecifications?.data}
          />

          <RecommendationSection
            ref={similarProducts.ref}
            related_products={related_product}
            more_products={more_product}
            wishlistData={wishlistData}
            checkIsSuccess={checkIsSuccess}
            addToWishList={addToWishList}
            removeToWishList={removeToWishList}
            getAllWishlist={getAllWishlist}
          />
        </div>
      </main>
      {!isPageLoaded ? <Loading /> : null}
    </>
  );
};

export default ProductDetailedPage;
