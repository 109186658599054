import { IconNames } from '@fe-monorepo/helper';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import useTheme from '@fe-web/hooks/useTheme';
import { useLocation, useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../app.routes.enum';

interface Props {
  header: string;
  caption: string;
  button: string;
}

const SignInStranger = (props: Props) => {
  const { btnClass } = usePageLayout();
  const navigate = useNavigate();
  const { isDarkTheme } = useTheme();
  const { pathname } = useLocation();

  return (
    <div className="flex flex-col SD:flex-row w-full gap-[1.5rem] justify-between border-[0.0625rem] rounded-lg border-zinc-800-gray-200 p-[1rem]">
      <div className="flex flex-row gap-[0.75rem]">
        <div
          className={`inline-flex justify-center items-center
            bg-secondary ${isDarkTheme ? 'bg-opacity-50' : 'bg-opacity-20'} w-[2.5rem] h-[2.5rem] rounded-full
            border border-neutral-300-zinc-700`}
        >
          <ResponsiveIcon iconClasses="fill-[#5D5C5E]" name={IconNames.questionBox} baseHeight={40} baseWidth={40} />
        </div>
        <div className="flex flex-col">
          <h2 className="font-medium text-secondary text-fs-body">{props.header}</h2>
          <p className="font-medium text-zinc-600-400 text-fs-caption">{props.caption}</p>
        </div>
      </div>
      <Button
        text={props.button}
        style="
          min-w-[6.5rem]
          h-[2.75rem]
          px-[1.5rem]
          py-[0.5rem]
          border-[0.063rem]
          border-sunset
          rounded-[0.175rem] 
          text-fs-body
          font-medium
          text-white100
          whitespace-nowrap"
        normalStyle={btnClass}
        onClick={() => navigate(`${AppRoutes.authSignIn}${pathname ? `?redirect_url=${pathname}` : ''}`)}
      />
    </div>
  );
};

export default SignInStranger;
