import { logCustomEvent } from '@braze/web-sdk';

const brazeHelper = {
  trackPageView: (pageName: any, pageId: any, urlPath: any, referrer: any) => {
    logCustomEvent('Page View', {
      'Page Name': pageName,
      'Page ID': pageId,
      'URL Path': urlPath,
      Referrer: referrer,
    });
  },
};

export default brazeHelper;
