import { Banner } from '@fe-web/types/bannerTypes';
import { Link } from 'react-router-dom';

import HoverText from '../../../components/Atoms/Text/HoverText';

interface SliderNavProps {
  list: Banner[];
  language: 'en' | 'ar';
  onNavClick: (identifier: string) => void;
}

const SliderNavigation = (props: SliderNavProps) => {
  const { list, language, onNavClick } = props;

  return (
    <nav className="md:gap-[1.875rem] flex mt-0 SD:mt-[0.5rem]">
      {list.map((item, index) => {
        const distanceFromMiddle = Math.floor(list.length / 2) - index;
        const isMiddleItem = distanceFromMiddle === 0;

        return (
          <Link
            key={index}
            to=""
            className={`group flex flex-col mdMaxxSD:w-[11.25rem] ${
              isMiddleItem ? 'text-secondary font-medium' : ' text-secondary/50 '
            } p-0 my-0 SD:mx-[2rem] SD:px-[2rem] items-center justify-center hover:cursor-pointer font-regular`}
          >
            <HoverText
              className={`
                font-regular
                text-fs-body
                ${isMiddleItem ? 'text-secondary font-medium' : 'text-secondary/50'}
              `}
              hover={{ color: '' }}
              alwaysShowUnderline={true}
              underlineStyle={`${
                isMiddleItem ? 'text-secondary z-1 static bg-secondary top-[0.625rem] h-[0.125rem]' : 'text-secondary/50'
              }`}
              text={item?.[`name_${language}`] ?? ''}
              onClick={() => onNavClick(item.name_en ?? '')}
            />
          </Link>
        );
      })}
    </nav>
  );
};

export default SliderNavigation;
