import { IconNames, TxKeyPath } from '@fe-monorepo/helper';
import { useAppState, useCheckProductAvailability, useInvoice, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { LMDModel } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import DropDown from '@fe-web/Molecules/InputFields/DropDownv2/DropDown';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

interface LMDOptions extends LMDModel {
  id: string;
  translatedCity: string;
}

interface CountryDropDownProps {
  classNames?: string;
  onLMDChange?: (code: LMDModel) => void;
  disabled?: boolean;
}

const DEFAULT_CITY = 'Riyadh';

const CountryDropDown = (props: CountryDropDownProps) => {
  const { onLMDChange, disabled } = props;
  const { getAllLmd, lmd_data } = useCheckProductAvailability();
  const { changeLMDCode } = useAppState();
  const { translate } = useTranslate();
  const { language } = usePageLayout();
  const { invoiceInfo, createInvoice } = useInvoice(true);
  const lmd_code = useSelector((state: RootState) => state.app.lmdCode);
  const { isLoggedIn } = useUserProfile();
  const [selectedRegion, setSelectedRegoin] = useState<LMDOptions>({
    id: '',
    city: '',
    region: '',
    lmd_code: '',
    translatedCity: '',
  });
  const countryOptions = useMemo(
    () =>
      lmd_data.map(item => {
        const formattedCity = `cities.${item.city.replace(/\s+|["']/g, '_').toLowerCase()}`;
        const _city = translate(`${formattedCity as TxKeyPath}`) ?? item.city;
        return {
          ...item,
          id: item.city + item.lmd_code,
          translatedCity: _city,
        };
      }),
    [lmd_data, language],
  );

  useEffect(() => {
    getAllLmd();
  }, []);

  useEffect(() => {
    if (lmd_data.length > 0 && (lmd_code.city === '' || lmd_code.lmd_code === '')) {
      let selectedLMDCode = '';
      let selectedCity;
      if (invoiceInfo?.lmd_code) {
        selectedLMDCode = invoiceInfo.lmd_code;
        selectedCity = countryOptions.find(
          item => (item.city === DEFAULT_CITY && selectedLMDCode === 'LM01') || item.lmd_code === selectedLMDCode,
        );
      } else {
        selectedCity = countryOptions.find(item => item.city === DEFAULT_CITY);
      }
      if (selectedCity) {
        changeLMDCode(selectedCity);
      }
    }
  }, [countryOptions]);

  const handleSelectedOption = (option: LMDOptions) => {
    const selectedCity = countryOptions.find(
      item => item.lmd_code === option.lmd_code && item.city === option.city && item.region === option.region,
    );
    if (selectedCity) {
      changeLMDCode(selectedCity);
    }
    setSelectedRegoin(option);
    if (onLMDChange) {
      onLMDChange?.(lmd_code);
    } else {
      if (isLoggedIn || (!isLoggedIn && invoiceInfo)) {
        createInvoice({ lmd_code: option?.lmd_code });
      }
    }
  };

  useEffect(() => {
    if (lmd_code.city !== '' && lmd_code.lmd_code !== '') {
      const selectedCity = countryOptions.find(
        item => item.lmd_code === lmd_code.lmd_code && item.city === lmd_code.city && item.region === lmd_code.region,
      );
      if (selectedCity) {
        setSelectedRegoin(selectedCity);
      }
    }
  }, [lmd_code, lmd_data]);

  useEffect(() => {
    if (selectedRegion) {
      setSelectedRegoin(prevState => {
        const formattedCity = `cities.${prevState.city.replace(/\s+|["']/g, '_').toLowerCase()}`;
        const _city = translate(`${formattedCity as TxKeyPath}`) || prevState.city;
        const newRegion = {
          ...prevState,
          translatedCity: _city,
        };
        return newRegion;
      });
    }
  }, [language]);

  return (
    <div className={`relative px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655 mt-[1.125rem] ${props.classNames}`}>
      <DropDown<LMDOptions>
        style={{
          container: 'justify-self-end py-[6px] 2K:py-[10.66px] 4K:py-16 8K:py-32',
          text: `
            relative
            font-regular
            text-sunset
            text-fs-body 2K:text-subtitle 4K:text-fourKSubtitle 8K:text-LPTitle
            flex flex-col
            justify-center
            gap-[8px]
            min-w-[8.9375rem]
            items-start
            z-[10000]
          `,
          icon: `${disabled ? '!fill-neutral-400-zinc-500' : '!fill-sunset'}`,
          baseHeight: 20,
          baseWidth: 20,
        }}
        disabled={disabled}
        selectedOption={selectedRegion}
        selectedIconPosition="start"
        selectedLabel={
          selectedRegion.lmd_code ? (
            <span className="flex gap-x-[8px]">
              <ResponsiveIcon
                name={IconNames.deliveryTruck}
                baseWidth={20}
                baseHeight={20}
                className={`${disabled ? 'fill-neutral-400-zinc-500' : 'fill-sunset'} relative`}
              />
              <p
                className={`text-fs-body-small font-regular 8K:ml-[.2rem] 
                    ${disabled ? 'text-neutral-400-zinc-500' : 'text-sunset'} pr-[0.125rem]`}
              >
                {translate('shop.country_label', { city: selectedRegion.translatedCity })}
              </p>
            </span>
          ) : null
        }
        options={countryOptions}
        getStringValue={(option: LMDOptions) => option.translatedCity}
        retreiveSelection={(option: LMDOptions) => handleSelectedOption(option)}
      />
    </div>
  );
};

export default CountryDropDown;
