import { gql } from '@apollo/client';

export const ACCOUNT_MUTATION = {
  editAccount: gql`
    mutation editAccount($details: AccountInput) {
      editAccount(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
  updateUsername: gql`
    mutation updateUsername($details: AccountInput) {
      updateUsername(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
  updatePassword: gql`
    mutation updatePassword($details: AccountInput) {
      updatePassword(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
  updateAvatar: gql`
    mutation updateAvatar($details: AvatarInput) {
      updateAvatar(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
  updateCoverBanner: gql`
    mutation updateCoverBanner($details: CoverBannerInput) {
      updateCoverBanner(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
  resetPassword: gql`
    mutation resetPassword($details: AccountInput) {
      resetPassword(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
  submitPersonalizationQuestionnaire: gql`
    mutation submitPersonalizationQuestionnaire($details: PersonalizeQuestionnaireInput) {
      submitPersonalizationQuestionnaire(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
  logout: gql`
    mutation logout {
      logout {
        error_msg
        error_code
        is_successful
      }
    }
  `,
  updateSettings: gql`
    mutation updateSettings($details: AccountInput) {
      updateSettings(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
  updateProfile: gql`
    mutation updateProfile($details: AccountInput) {
      updateProfile(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
  addDeliveryAddress: gql`
    mutation addDeliveryAddress($details: DeliveryAddressInput) {
      addDeliveryAddress(details: $details) {
        error_msg
        error_code
        is_successful
      }
    }
  `,
  updateDeliveryAddress: gql`
    mutation updateDeliveryAddress($details: DeliveryAddressInput) {
      updateDeliveryAddress(details: $details) {
        error_msg
        error_code
        is_successful
      }
    }
  `,
  removeDeliveryAddress: gql`
    mutation removeDeliveryAddress($details: AddressInput) {
      removeDeliveryAddress(details: $details) {
        error_msg
        error_code
        is_successful
      }
    }
  `,
  AcceptConsent: gql`
    mutation acceptConsent($details: ConsentInput) {
      acceptConsent(details: $details) {
        is_successful
        error_code
        error_msg
        response {
          success
        }
      }
    }
  `,
};
