import { gql } from '@apollo/client';

export const PREFERENCE_UPDATE_MUTATION = {
  preferenceUpdate: gql`
    mutation preferenceUpdate($details: PreferenceUpdateInput) {
      preferenceUpdate(details: $details) {
        is_successful
        error_msg
        error_code
      }
    }
  `,
};
