import { useLazyQuery } from '@apollo/client';
import { CONTENT_QUERY } from '@fe-monorepo/data-access';
import { FAQCategoryQueryResponse, FaqCategoryModel, FaqsModel } from '@fe-monorepo/models';
import { RootState, setFqaCategories, useAppDispatch } from '@fe-monorepo/store';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useAppState } from '../useAppState/useAppState';
import { useFetchQuery } from '../useFetchQuery';

interface FaqsPayload {
  category_id: number;
}

export const useSupport = () => {
  const [faqsData, setFaqsData] = useState<FaqsModel[]>();
  const [faqsCategoriesData, setFaqsCategoriesData] = useState<FaqCategoryModel[]>();
  const dispatch = useAppDispatch();
  const { changeLoadingState } = useAppState();

  const getFaqCategoriesResponse = useFetchQuery<FaqCategoryModel[]>(CONTENT_QUERY.getFaqCategories, []);
  const getFaqsResponse = useFetchQuery<FaqsModel[], FaqsPayload>(CONTENT_QUERY.getFAQs, []);
  const playConfig = useSelector((state: RootState) => state.app.playConfig);

  const checkIfSectionDisabled = (sectionName: string) => {
    if (sectionName === 'Play') {
      return !!(playConfig?.enabled && playConfig?.helpVisible);
    } else return true;
  };
  useEffect(() => {
    if (getFaqCategoriesResponse.data) {
      if (getFaqCategoriesResponse.data.length > 0) {
        setFaqsCategoriesData([]);
        let categories = getFaqCategoriesResponse?.data?.filter(item => checkIfSectionDisabled(item.name_en));

        dispatch(setFqaCategories(categories));
        setFaqsCategoriesData(categories);
      }
    }
  }, [getFaqCategoriesResponse.data]);

  useEffect(() => {
    if (getFaqsResponse.data) {
      if (getFaqsResponse.data.length > 0) {
        setFaqsData([]);
        setFaqsData(getFaqsResponse.data);
        changeLoadingState(false);
      }
    }
  }, [getFaqsResponse.data]);

  const getFaqCategories = () => {
    return getFaqCategoriesResponse.fetch();
  };

  const getFaqs = (category_id: FaqsPayload) => {
    return getFaqsResponse.fetch(category_id);
  };

  //New UseSupport
  const [getAllFAQCategoriesGql, { data: FAQCategoryData }] = useLazyQuery<FAQCategoryQueryResponse>(CONTENT_QUERY.getFaqCategories, {
    errorPolicy: 'all',
  });

  const [getAllFAQInfoGql, { data: FAQInfoData }] = useLazyQuery<FAQCategoryQueryResponse>(CONTENT_QUERY.getFAQs, {
    errorPolicy: 'all',
  });

  const getAllFAQCategories = async () => {
    const { data } = await getAllFAQCategoriesGql();
    return data;
  };
  const getAllFAQInfos = async (category_id: FaqsPayload) => {
    const { data } = await getAllFAQInfoGql({
      variables: {
        details: category_id,
      },
    });
    return data;
  };

  useEffect(() => {
    if (FAQCategoryData?.getFaqCategories.data) {
      dispatch(setFqaCategories(FAQCategoryData?.getFaqCategories.data));
    }
  }, [FAQCategoryData?.getFaqCategories]);

  return {
    getFaqCategories,
    getFaqs,
    faqsData,
    faqsCategoriesData,
    getAllFAQCategories,
    faqCategoryData: FAQCategoryData?.getFaqCategories,
    getAllFAQInfos,
    faqInfoData: FAQInfoData?.getFaqs,
  };
};