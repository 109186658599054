import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { NotificationMessageData } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import useOutsideClick from '@fe-web/hooks/useOutsideClick';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';

interface NotificationPopoverProps extends NotificationMessageData {
  showMore: boolean;
}

const NotificationPopover = ({ is_read, isMinimized, id, showMore }: NotificationPopoverProps) => {
  const isMobile = useMobileDetect();
  const { translate } = useTranslate();
  const { removeNotification, updateNotification } = useNotificationProvider();

  // Marks the notification as read or unread based on the is_read property
  const readUnread = () => {
    const isRead = is_read === 'true';
    updateNotification({ message_id: id as string, is_read: String(!isRead) });
  };

  // removes the notification from the list. Removed notifications will not be returned on subsequent calls of notificationGetAll
  const remove = () => {
    removeNotification(id as string);
  };

  const [isOpen, setIsOpen] = useState(false);
  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  const popOverRef = useOutsideClick(() => {
    setIsOpen(false);
  });

  return (
    <Popover
      isOpen={isOpen}
      ref={newRef => (popOverRef.current = newRef)}
      positions={['right', 'bottom']}
      padding={10}
      reposition={false}
      onClickOutside={() => setIsOpen(false)}
      containerClassName="bg-primary border-[0.0625rem] border-secondary/[0.2] rounded-[0.25rem] w-[12.5rem] "
      containerStyle={{
        top: '0',
        left: isRTL ? '0' : '-15rem',
      }}
      content={() => (
        <div>
          <div
            onClick={readUnread}
            className="text-secondary py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all"
          >
            <p className="font-regular text-fs-body">{translate('notifications.mark_as_unread')}</p>
          </div>
          <div
            onClick={remove}
            className="text-secondary py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all"
          >
            <p className="font-regular text-fs-body">{translate('notifications.remove_notification')}</p>
          </div>
        </div>
      )}
    >
      <div
        className="flex flex-row gap-[0.25rem] max-w-full"
        onClick={e => {
          e.stopPropagation();
          setIsOpen(prevOpen => !prevOpen);
        }}
      >
        <ResponsiveIcon
          name={IconNames.optionsRegular}
          baseHeight={24}
          baseWidth={24}
          iconClasses="hover:fill-zinc-400 dark:hover:fill-zinc-200 fill-zinc-600-400 rotate-90"
        />
      </div>
    </Popover>
  );
};

export default NotificationPopover;
