// TODO: Turn this into a generic form
import { FormData, useForm } from '@fe-monorepo/forms';
import { useOtp } from '@fe-monorepo/hooks';
import { OtpModel } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import { APIErrorType } from '@fe-web/hooks/useAPIError';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Oval } from 'react-loader-spinner';
import OtpInput from 'react-otp-input';
import { useSelector } from 'react-redux';

interface Props {
  dir: string;
  otpType: 'login' | 'signup' | 'reset_password' | 'guest';
  APIError: APIErrorType;
  setOtp: (value: string) => void;
  goNext: (form: FormData) => void;
  resetAPIError: (key: string) => void;
  requestOTPPayload: OtpModel;
  hideNoEmailSection?: boolean;
  resendCodeCls?: string;
  attempt?: number;
  isLoading?: boolean;
  resetAttempt?: (count: number) => void;
  goToOTPBlocked?: () => void;
}

const OTPPhase = (props: Props) => {
  const prefs = useSelector((state: RootState) => state.app);
  const { goNext, attempt, isLoading, resetAttempt, goToOTPBlocked } = props;
  const { form } = useForm();
  const { t } = useTranslation();
  const { requestOTP } = useOtp();
  const [timer, setTimer] = useState<number>(60);
  const [code, setCode] = useState<string>('');
  const [isTimer, setIsTimer] = useState<boolean>(true);
  const [showButton, setShowButton] = useState<boolean>(false);
  const [otpDisabled, setOtpDisabled] = useState<boolean>(true);

  const handleCodeOtp = (code: string) => setCode(code);

  const handleCodeReset = () => {
    setCode('');
    setTimer(60);
    setIsTimer(true);
    setShowButton(false);
    requestOTP(props.requestOTPPayload);
  };

  const formatTimer = () => {
    const zero = prefs.language === 'en' ? '0' : (0).toLocaleString('ar-u-nu-arab');
    const rawMinutes = Math.floor(timer / 60);
    let minutes = prefs.language === 'en' ? rawMinutes : rawMinutes.toLocaleString('ar-u-nu-arab');
    minutes = minutes.toString().padStart(2, zero);
    const rawSeconds = timer % 60;
    let seconds = prefs.language === 'en' ? rawSeconds : rawSeconds.toLocaleString('ar-u-nu-arab');
    seconds = seconds.toString().padStart(2, zero);
    return `${minutes}:${seconds}`;
  };

  useEffect(() => {
    requestOTP(props.requestOTPPayload);
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isTimer) {
      intervalId = setInterval(() => {
        setTimer(prevTimer => {
          if (prevTimer === 0) {
            clearInterval(intervalId);
            setIsTimer(false);
            setShowButton(true);
          }
          return prevTimer - 1;
        });
      }, 1000);
    }
    return () => {
      clearInterval(intervalId);
    };
  }, [isTimer]);

  useEffect(() => {
    if (code.length === 6) {
      props.setOtp(code);
      goNext(form);
    }
  }, [code]);

  useEffect(() => {
    if (code.length === 5) {
      props.resetAPIError('otp');
    }
  }, [code]);

  useEffect(() => {
    props.resetAPIError('otp');
  }, [prefs.language]);

  useEffect(() => {
    if (otpDisabled === true) {
      setTimer(30);
    } else {
      setTimer(60);
    }
  }, [otpDisabled]);

  useEffect(() => {
    if (attempt === 3) {
      setOtpDisabled(true);

      if (goToOTPBlocked) {
        goToOTPBlocked();
      }
    } else {
      setOtpDisabled(false);
    }
  }, [attempt]);

  useEffect(() => {
    if (timer <= 0 && attempt === 3) {
      setOtpDisabled(false);
      if (resetAttempt) resetAttempt(0);
      setShowButton(true);
    }
  }, [timer]);

  return (
    <div
      className="w-full animate__animated animate__fadeIn
        flex flex-col
        gap-20 2K:gap-[35.55px] 4K:gap-[53.33px] 8K:gap-[106.66px]"
    >
      <div className="flex flex-col gap-40 2K:gap-[71.11px] 4K:gap-[106.66px] 8K:gap-[213.33px]">
        <div className="flex flex-col">
          <div dir="ltr" className="relative">
            <OtpInput
              value={code}
              onChange={handleCodeOtp}
              numInputs={6}
              containerStyle="w-full grid gap-8 2K:gap-12 4K:gap-20 8K:gap-44"
              renderInput={props => {
                const newProps = { ...props, style: {} };
                return (
                  <div className="w-[15%] flex justify-center">
                    <input
                      {...newProps}
                      disabled={otpDisabled}
                      className={`
                        text-secondary
                        text-center w-full
                        text-[55px]/[60px] sm:text-huge 4xl:text-xlargeDynamic 5xl:text-fourKTitle 8xl:text-eightKTitle
                        p-0 border-b-2 4xl:border-b-[3.55px] 5xl:border-b-4 8xl:border-b-[11px] outline-none
                        focus:border-sunset
                        font-medium leading-3
                        ${otpDisabled === true && 'text-red'}
                        bg-transparent`}
                    />
                    {isLoading && <div className="absolute inset-0 bg-primary opacity-30 z-[1]" />}
                  </div>
                );
              }}
              shouldAutoFocus={true}
            />
            {isLoading && (
              <div className="absolute top-0 flex justify-center w-full items-center z-[9999]">
                <Oval
                  height={80}
                  width={80}
                  color="#E95F2A"
                  secondaryColor="#E95F2A"
                  visible={true}
                  ariaLabel="oval-loading"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            )}
          </div>
          {props.APIError?.otp && props.APIError?.otp.length > 0 && !otpDisabled && (
            <p className="mt-4 2K:mt-8 4K:mt-12 8K:mt-22 responsive-text-bodySmall font-regular text-red">{props.APIError?.otp}</p>
          )}
          {otpDisabled === true && (
            <p className="mt-4 2K:mt-8 4K:mt-12 8K:mt-22 responsive-text-bodySmall font-regular text-red">
              {t('shop.cart.continue_as_guest.too_many_requests')}
            </p>
          )}
        </div>
        <div
          className="responsive-text-body
            leading-6 font-light
            py-5 5xl:py-12"
        >
          {showButton && !otpDisabled ? (
            <button className={`${props.resendCodeCls} relative text-sunset`} onClick={handleCodeReset}>
              <span className="border-sunset">{t('common_otp_resend_code')}</span>
            </button>
          ) : otpDisabled ? (
            <span className={`${props.resendCodeCls}`}>{t('shop.cart.continue_as_guest.otp_try_again', { timer: formatTimer() })}</span>
          ) : (
            <span className={`text-secondary ${props.resendCodeCls}`}>
              {t('common_otp_resend_in')}
              {' ' + formatTimer()}
            </span>
          )}
        </div>
      </div>
      {!props.hideNoEmailSection && <p className="font-regular responsive-text-body text-secondary/70">{t('common_otp_no_email')}</p>}
    </div>
  );
};

export default OTPPhase;
