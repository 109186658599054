import { IMAGES } from '@fe-monorepo/assets';
import { useTranslate } from '@fe-monorepo/hooks';
import PrimaryButton from '@fe-web/Atoms/Buttons/PrimaryButton';
import Image from '@fe-web/Atoms/Media/Image';
import { APP_STORE_LINKS } from '@fe-web/constant/constants';
import { detectPlatform } from 'apps/fe-web/src/lib/helper';
import { Link } from 'react-router-dom';

const BrandBanner = () => {
  const { translate } = useTranslate();

  // Determine the platform-specific link
  const platformPath = () => {
    const platform = detectPlatform();
    if (platform === 'ios') {
      return APP_STORE_LINKS.iosXplay; // Replace with the correct path
    } else if (platform === 'android') {
      return APP_STORE_LINKS.androidXplay; // Replace with the correct path
    } else {
      console.warn('Platform not supported for redirection.');
      return '/unsupported'; // Fallback path for unsupported platforms
    }
  };

  return (
    <div className="bg-primary p-20 gap-20 flex items-center justify-between">
      {/* Image and Text */}
      <div className="flex items-center gap-8">
        <div className="w-[56px] h-[56px] flex-shrink-0">
          <Image img={IMAGES.StcplayBrand} divStyle="w-full h-full" imgStyle="object-contain w-full h-full" />
        </div>
        <span className="text-secondary font-medium text-base">{translate('brand_banner')}</span>
      </div>

      {/* Right Button */}
      <Link to={platformPath()}>
        <PrimaryButton
          className="w-[6.25rem] py-[0.5rem] rounded-[0.125rem]"
          text={translate('common_view') ?? ''}
          action={() => {}} // Pass an empty action since <Link> handles the navigation
        />
      </Link>
    </div>
  );
};

export default BrandBanner;
