import { gql } from '@apollo/client';

export const ORDER_MUTATION = {
  addProduct: gql`
    mutation addProduct($details: AddProductInput) {
      addProduct(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  removeProduct: gql`
    mutation removeProduct($details: RemoveProductInput) {
      removeProduct(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  selectProducts: gql`
    mutation selectProducts($details: SelectProductsInput) {
      selectProducts(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  checkout: gql`
    mutation checkout($details: CheckoutInput) {
      checkout(details: $details) {
        error_msg
        is_successful
        error_code
        data {
          invoice {
            order_id
            reference_user_id
            lmd_code
            invoice_number
            lang
            status_code
            payment_method_code
            cancel_reason_code
            is_paid
            is_sent
            is_checkout
            updated_at
            created_at
            amount_total
            amount_sub_total
            amount_discount
            amount_tax
            amount_grand_total
            wallet_discount
            final_amount
            status_en
            status_ar
          }
          itemReservations {
            product
            reserved_at
            reservation_status
          }
        }
      }
    }
  `,

  resendCode: gql`
    mutation resendCode($details: ResendCodeInput) {
      resendCode(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  createPhysical: gql`
    mutation createPhysical($details: OrderShipmentInput) {
      createPhysical(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  createDigital: gql`
    mutation createDigital($details: OrderShipmentInput) {
      createDigital(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  cancelOrder: gql`
    mutation cancelOrder($details: CheckoutInput) {
      cancelOrder(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,
};
