import { convertToTimezone } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { format as formatDateFns } from 'date-fns';
import i18next from 'i18next';
import Lottie from 'lottie-react';
import moment from 'moment';
import format from 'string-format';

import { LabelProps, OrderTrackingBarProps, StepProps } from './types';

const Label = ({
  text,
  icon,
  lottieicon,
  isDone,
  date,
  isLast,
  isRTL,
  isVertical,
  isLastLine,
  isFill,
  hasDescriptionIcon,
  hasEstimateText,
  isDateUnder,
  verticalHeight,
  isLineDashed,
  fontStyle,
  stepperIcon,
  stepperLine,
  isReturns,
}: LabelProps) => {
  const { translate } = useTranslate();
  const hw = isDone ? 15 : 7.5;
  const iconname = isDone ? stepperIcon?.done : isFill ? stepperIcon?.filled : stepperIcon?.default;
  return (
    <>
      <div className={`flex gap-x-[0.5rem] items-center ${isVertical ? 'w-full' : ''}`} dir={isVertical ? 'ltr' : isRTL ? 'rtl' : 'ltr'}>
        {isVertical ? (
          <>
            {stepperLine?.isHalved ? (
              <div className="">
                {isLastLine ? (
                  <>
                    <ResponsiveIcon //STEPPER LINE 1ST
                      name={stepperLine.isHalvedValue.startHalf}
                      baseWidth={5}
                      baseHeight={55}
                      className="relative pl-[.25rem] top-[3.5rem]"
                    />
                    <ResponsiveIcon //STEPPER LINE 1ST
                      name={stepperLine.isHalvedValue.startHalf}
                      baseWidth={5}
                      baseHeight={55}
                      className="relative pl-[.25rem] top-[3.5rem]"
                    />
                  </>
                ) : (
                  <ResponsiveIcon //STEPPER LINE 2ND
                    name={stepperLine.isHalvedValue.startEnd}
                    baseWidth={5}
                    baseHeight={100}
                    className={`${isLast ? 'invisible' : ''} relative pl-[.25rem] top-[3.5rem]`}
                  />
                )}
              </div>
            ) : (
              <div //STEPPER LINE DEFAULT
                className={`h-[4rem] ml-[.5rem] pl-[1rem] border-l-[0.0625rem] border-l-moonlight mt-0 
                  ${!isLastLine && isLineDashed ? 'border-dashed' : ''} 
                  ${isLast ? 'invisible' : ''} 
                  ${isLastLine ? ' border-l-moonlight' : 'border-l-secondary/[0.42]'}`}
              ></div>
            )}
            <ResponsiveIcon //STEPPER ICON
              name={iconname!}
              baseWidth={hw}
              baseHeight={hw}
              className={`fill-secondary opacity-[0.50] absolute pl-[.45rem] pt-[-3.1rem] 
                ${
                  isDone
                    ? stepperLine?.isHalved
                      ? 'left-[-.5rem]'
                      : 'left-[-.3rem]'
                    : stepperLine?.isHalved
                    ? 'left-[-.25rem]'
                    : 'left-[-.15rem]'
                }
                `}
              fill="fill-white"
            />
            <div
              className={`flex  ${stepperLine?.isHalved ? 'mt-[-1rem] ml-[.5rem]' : 'mt-[-.3rem]'} ${
                isDateUnder ? 'flex-col' : 'flex-row gap-[.5rem]'
              }`}
            >
              {isDone //ICON BESIDE TEXT
                ? lottieicon &&
                  hasDescriptionIcon && (
                    <div className={isReturns ? '' : 'mt-[-.25rem]'}>
                      <Lottie
                        animationData={lottieicon}
                        loop={false}
                        style={{
                          height: `${isReturns ? '1.25rem' : '1.688rem'}`,
                          width: `${isReturns ? '1.25rem' : '1.688rem'}`,
                          flexShrink: 0,
                        }}
                      />
                    </div>
                  )
                : icon &&
                  hasDescriptionIcon && (
                    <ResponsiveIcon name={icon} baseWidth={20} baseHeight={20} className="fill-secondary opacity-[0.50]  left-[1.7rem]" />
                  )}
              {/* TEXT BESIDE THE STEPPER */}
              <p //TEXT
                className={`${fontStyle} truncate
                                ${isDateUnder ? (date ? '' : 'mt-[.5rem]') : ''}
                                ${stepperLine?.isHalved ? '2K:pl-[.5rem]' : ''}  
                                ${hasDescriptionIcon ? (date ? '' : 'pt-[.25rem] left-[3.5rem]') : 'left-[2rem]'} 
                                ${isDone ? 'text-moonlight font-medium pt-[.25rem]' : 'text-[#454547] top-[-.25rem]'}`}
              >
                {text}
              </p>
              {!isReturns && isDateUnder && (
                <p //DATES UNDER
                  dir={i18next.language === 'ar' ? 'rtl' : 'ltr'}
                  className={` 
                    ${fontStyle}
                    ${hasDescriptionIcon ? 'pl-[1.5rem]' : '2K:pl-[.5rem]'} 
                    ${isDone ? 'text-[#B9B9BA]' : 'text-[#454547]'}`}
                >
                  {isLast && !isDone && hasEstimateText ? translate('shop.cart.stepper.estimate') : ''} {date?.toLocaleString()}
                </p>
              )}
            </div>
          </>
        ) : (
          <>
            {hasDescriptionIcon && (
              <div>
                {isDone //ICON BESIDE TEXT HORIZONTAL
                  ? lottieicon && (
                      <Lottie
                        animationData={lottieicon}
                        loop={false}
                        style={{
                          height: `${isReturns ? '1.25rem' : '1.688rem'}`,
                          width: `${isReturns ? '1.25rem' : '1.688rem'}`,
                          flexShrink: 0,
                          marginLeft: `${isReturns ? '0.1656rem' : 0}`,
                          padding: 0,
                        }}
                      />
                    )
                  : icon && (
                      <ResponsiveIcon
                        name={icon}
                        baseWidth={20}
                        baseHeight={20}
                        className={`fill-secondary/[.50] items-center ${isReturns ? '' : 'pt-[0rem]'}`}
                      />
                    )}
              </div>
            )}
            <p //TEXT
              className={`text-fs-body-small truncate ${!hasDescriptionIcon ? 'pl-[.5rem]' : ''} ${
                isDone ? 'text-moonlight font-medium' : 'text-secondary/[0.42] font-regular top-[-.25rem]'
              }`}
            >
              {text}
            </p>
          </>
        )}
      </div>
      {!isReturns && isDone && !isDateUnder && (
        <p
          //DATES
          className={`
          

          ${fontStyle} truncate
                ${isDone ? 'text-moonlight' : 'text-secondary/[0.42]'}
                ${
                  isRTL
                    ? isDone
                      ? hasDescriptionIcon
                        ? 'pr-[2rem]'
                        : 'pr-[0rem]'
                      : hasDescriptionIcon
                      ? 'pr-[1.8rem]'
                      : 'pr-[0rem]'
                    : ''
                }
                ${isVertical ? 'absolute right-0 top-0' : ''}
                ${
                  hasDescriptionIcon
                    ? isDone
                      ? isReturns
                        ? 'pl-[1.75rem]'
                        : 'pl-[2.2rem]'
                      : ' pl-[1.8rem]'
                    : isDone
                    ? 'pl-[.5rem]'
                    : 'pl-[.5rem]'
                }`}
        >
          {isLast && !isDone && hasEstimateText ? translate('shop.cart.stepper.estimate') : ''} {date}
        </p>
      )}
    </>
  );
};

const Step = ({
  label,
  labelArgs,
  isDone,
  isLast,
  icon,
  lottieicon,
  date,
  isLastLine,
  isRTL,
  isVertical,
  isFill,
  hasDescriptionIcon,
  hasEstimateText,
  isDateUnder,
  verticalHeight,
  isLineDashed,
  dateFormatInput,
  isCenter,
  fontStyle,
  stepperIcon,
  stepperLine,
  isReturns,
}: StepProps) => {
  const formatDate = (date: Date) => {
    return formatDateFns(date, 'dd MMM yyyy HH:mm');
  };

  const { translate } = useTranslate();
  const iconname = isDone ? stepperIcon?.done : isFill ? stepperIcon?.filled : stepperIcon?.default;

  let dateString = '';

  if (date && ((typeof date === 'string' && date !== '') || moment(date.toString()).isValid())) {
    const convertedDate = convertToTimezone(date.toString());

    dateString = convertedDate.format('DD MMM YYYY');
  }

  const shownDate = dateString ?? '';
  const hw = isDone ? 15 : 7.5;
  const stepCheck = isReturns && label.toString() !== 'shop.cart.stepper.refunded' && label.toString() !== 'shop.cart.stepper.rejected';

  return (
    <div className={`relative ${!isVertical ? 'pb-[0.75rem] self-end' : ''}`}>
      <Label
        text={format(translate(label) ?? '', ...(labelArgs || [])) || ''}
        icon={icon}
        lottieicon={lottieicon}
        isDone={isDone}
        date={`${
          isDone && hasEstimateText && label === 'shop.cart.stepper.delivered' ? translate('shop.cart.stepper.estimate') : ''
        } ${shownDate}`}
        isLast={isLast}
        isRTL={isRTL}
        isVertical={isVertical}
        isLastLine={isLastLine}
        isFill={isFill}
        hasDescriptionIcon={hasDescriptionIcon}
        hasEstimateText={hasEstimateText}
        isDateUnder={isDateUnder}
        verticalHeight={verticalHeight}
        isLineDashed={isLineDashed}
        fontStyle={fontStyle}
        stepperIcon={stepperIcon}
        stepperLine={stepperLine}
        isReturns={isReturns}
      />
      {!isVertical && (
        <>
          <span
            className={`absolute bottom-0 w-full mt-[.5rem]
                    ${isRTL ? '' : 'ml-[0.5rem]'} 
                    ${isCenter ? (isRTL ? 'right-[5rem]' : 'left-[4rem]') : isRTL ? 'right-[.5rem]' : 'left-0'} 
                    ${
                      isLastLine
                        ? 'h-[0.125rem] bg-moonlight'
                        : `h-[0.0625rem] 
                    ${stepCheck ? 'border-t-2 border-dashed border-secondary/[0.42]' : isLast ? '' : 'bg-secondary/[0.42]'}`
                    }`}
          ></span>
          <ResponsiveIcon
            name={iconname!}
            baseWidth={hw}
            baseHeight={hw}
            className={`absolute 
                        ${isRTL ? 'pr-[.3rem]' : 'pl-[.3rem]'} 
                        ${isCenter ? (isRTL ? 'right-[5rem]' : 'left-[4rem]') : isRTL ? 'right-0' : 'left-0'} 
                        ${isDone ? 'bottom-[-.4rem]' : 'bottom-[-.22rem] left-[0.1563rem]'}`}
          />
          {stepCheck && (
            <ResponsiveIcon
              name={stepperIcon?.default!}
              baseWidth={7.5}
              baseHeight={7.5}
              className={`absolute 
                        ${isRTL ? 'pr-[.3rem]' : 'pl-[.3rem]'}
                        ${isCenter ? (isRTL ? 'right-[5rem]' : 'left-[4rem]') : isRTL ? 'left-[-0.9375rem]' : 'right-[-0.9375rem]'}
                        bottom-[-.22rem] `}
            />
          )}
        </>
      )}
    </div>
  );
};

const Stepper = ({
  steps,
  currentStep,
  isRTL,
  isVertical,
  hasDescriptionIcon,
  hasEstimateText,
  isDateUnder,
  verticalHeight,
  isLineDashed,
  containerStyle,
  // dateFormatInput,
  title,
  isCenter,
  fontStyle,
  gridLength,
  stepperIcon,
  stepperLine,
  isReturns,
}: OrderTrackingBarProps) => {
  return (
    <div className={containerStyle}>
      {title?.hasTitle && <p className={`${title?.titleStyle}`}>{title?.titleText}</p>}
      <div className={`grid mt-[1.5rem] ${isVertical ? gridLength?.row : gridLength?.column} `}>
        {steps?.map(({ label, labelArgs, icon, lottieicon, date }, key) => (
          <Step
            key={key}
            label={label}
            labelArgs={labelArgs}
            icon={icon}
            lottieicon={lottieicon}
            isDone={key === currentStep}
            isFill={key <= currentStep}
            date={date}
            isLast={key === steps.length - 1}
            isLastLine={key + 1 <= currentStep}
            isRTL={isRTL}
            isVertical={isVertical}
            hasDescriptionIcon={hasDescriptionIcon}
            hasEstimateText={hasEstimateText}
            isDateUnder={isDateUnder}
            verticalHeight={verticalHeight}
            isLineDashed={isLineDashed}
            // dateFormatInput={dateFormatInput}
            isCenter={isCenter}
            fontStyle={fontStyle}
            stepperIcon={stepperIcon}
            stepperLine={stepperLine}
            isReturns={isReturns}
          />
        ))}
      </div>
    </div>
  );
};

export default Stepper;
