import { EwcProvider, useShopContent, useShopInfo } from '@fe-monorepo/hooks';
import { MenuItem } from '@fe-monorepo/models';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import useGetCurrentBreakPoint, { BreakPoints } from '@fe-web/hooks/useGetCurrentBreakPoint/useGetCurrentBreakPoint';
import { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useNavigate, useOutletContext, useParams } from 'react-router-dom';

import { SHOP_CART_PAGES, SHOP_PAGES } from '../../../lib/constant/constants';
import { AppRoutes } from '../../app.routes.enum';
import BreadCrumbs from '../../components/Atoms/BreadCrumb';
import ShopHeader from '../ShopMainPage/ShopHeader';

type ProductInfo = {
  code: string;
  name_ar: string;
  name_en: string;
};

export type SimplifiedShopCategory = {
  menu_code: string;
  name_en: string;
  name_ar: string;
  collection_id: number;
  collection_code: string;
};

interface SelectedCategories {
  main: SimplifiedShopCategory | undefined;
  sub: SimplifiedShopCategory | undefined;
}

type ContextType = {
  shopCategory: SelectedCategories;
  setProductInfo: (productInfo: ProductInfo) => void;
};

const useAllShopCategories = () => {
  const [value, setValue] = useState<SimplifiedShopCategory[]>([]);
  const { getAll, info, SHOP_MENU_CODE } = useShopInfo();
  const { getAllContent, content } = useShopContent();

  const getSectionsList = () => {
    let newList: any[] = [];
    if (content && content.getShopLanding && content.getShopLanding.data?.length > 0) {
      //SLIDER SECTION
      const slider_menu_item = content.getShopLanding.data.filter(item => item.section_type === 'slider');
      let sliderBannerList: any[] = [];
      const sliderMenuList = slider_menu_item.filter(
        bannerItem =>
          bannerItem.banner_info &&
          bannerItem.banner_info.banner_items &&
          bannerItem.banner_info.banner_items.length > 0 &&
          bannerItem.banner_info.banner_items.filter(item => item.reference_type === 'product' || item.reference_type === 'collection'),
      );
      sliderMenuList.forEach(sliderItem => {
        const filteredItem = sliderItem.banner_info.banner_items.filter(
          (item: any) => item.reference_type === 'product' || item.reference_type === 'collection',
        );
        if (filteredItem.length > 0) {
          filteredItem.map(slider => {
            return {
              name_ar: slider?.name_ar ?? '',
              name_en: slider?.name_en ?? '',
              menu_code: '',
              collection_code: slider.reference_type === 'collection' ? slider.reference_value ?? '' : '',
              collection_id: 0,
              media: [],
              product_list: [],
              menu_items: [],
            };
          });
          sliderBannerList = [...sliderBannerList, ...filteredItem];
        }
      });

      //CAROUSEL SECTION
      const carousel_menu_item = content.getShopLanding.data.filter(item => item.section_type === 'carousel');
      const carouselItemList: any[] = carousel_menu_item.map(menu => {
        return {
          name_ar: menu?.item_info?.name_ar ?? '',
          name_en: menu?.item_info?.name_en ?? '',
          menu_code: menu?.item_info?.menu_code ?? '',
          collection_code: '',
          collection_id: 0,
          media: [],
          product_list: [],
          menu_items: [],
        };
      });
      const carouselBannerList: any[] = carousel_menu_item
        .filter(
          menu =>
            menu.banner_info &&
            menu.banner_info.name_ar &&
            menu.banner_info.name_en &&
            menu.banner_info.reference_type &&
            menu.banner_info.reference_value,
        )
        .map(item => {
          return {
            name_ar: item?.item_info?.name_ar ?? '',
            name_en: item?.item_info?.name_en ?? '',
            menu_code:
              item?.banner_info?.reference_type === 'product' && item?.item_info?.menu_code ? item?.item_info?.menu_code ?? '' : '',
            collection_code: item?.banner_info?.reference_type === 'collection' ? item?.banner_info?.reference_value ?? '' : '',
            collection_id: 0,
            media: [],
            product_list: [],
            menu_items: [],
          };
        });
      //STATIC BANNER SECTION
      const static_banner_menu_item = content.getShopLanding.data.filter(item => item.section_type === 'static_banner');
      const staticBannerList: any[] = static_banner_menu_item
        .filter(staticBanner => staticBanner.banner_info)
        .map(item => {
          return {
            name_ar: item?.banner_info?.name_ar ?? '',
            name_en: item?.banner_info?.name_en ?? '',
            menu_code: '',
            collection_code: item?.banner_info?.reference_type === 'collection' ? item?.banner_info?.reference_value ?? '' : '',
            collection_id: 0,
            media: [],
            product_list: [],
            menu_items: [],
          };
        });
      //BRANDS SECTION
      const brand_menu_item = content.getShopLanding.data.filter(item => item.section_type === 'section');
      const brandSectionList: any[] = brand_menu_item
        .filter(brand => brand.banner_info)
        .map(item => {
          return {
            name_ar: item?.banner_info?.name_ar ?? '',
            name_en: item?.banner_info?.name_en ?? '',
            menu_code: '',
            collection_code: item?.banner_info?.reference_type === 'collection' ? item?.banner_info?.reference_value ?? '' : '',
            collection_id: 0,
            media: [],
            product_list: [],
            menu_items: [],
          };
        });
      newList = [...newList, ...sliderBannerList, ...carouselItemList, ...carouselBannerList, ...staticBannerList, ...brandSectionList];
    }
    return newList;
  };

  // Turns the categories tree into an array
  const flattenCategoriesTree = (shopCategories: MenuItem[]): SimplifiedShopCategory[] => {
    // Base case
    if (shopCategories.length === 0) {
      return [];
    }
    const mainCategories: SimplifiedShopCategory[] = shopCategories.flatMap(category => {
      const { name_ar, name_en, menu_code, collection_id, collection_code } = category;
      const shopCategory: SimplifiedShopCategory = {
        name_ar,
        name_en,
        menu_code,
        collection_id,
        collection_code,
      };
      // Recursive case
      return [shopCategory, ...flattenCategoriesTree(category.menu_items ?? [])];
    });
    return mainCategories;
  };

  const getMainCategory = (collection_code: string, shopCategories: MenuItem[]): MenuItem | undefined => {
    if (shopCategories?.length === 0) {
      return undefined;
    }
    const category = shopCategories.find(category => {
      if (category.collection_code === collection_code || category.menu_code === collection_code) {
        return category;
      }
      if (category.menu_items && category.menu_items.length > 0) {
        return getMainCategory(collection_code, category.menu_items) ? category : undefined;
      } else {
        return undefined;
      }
    });
    return category ?? undefined;
  };

  const getMainCategoryWrapper = (collection_code: string): MenuItem | undefined => {
    let category;
    if (info?.data && info?.data?.menu_items) {
      const navigation_menu = info.data.menu_items.filter(item => item.menu_items.length > 0);
      if (navigation_menu.length > 0) {
        category = getMainCategory(collection_code, navigation_menu);
      }
    }
    if (content && content.getShopLanding.data.length > 0 && !category) {
      const newList: any[] = getSectionsList();
      category = getMainCategory(collection_code, newList);
    }
    return category;
  };

  useEffect(() => {
    getAll({ menu_code: SHOP_MENU_CODE.SHOP_PAGE });
    getAllContent();
  }, []);

  useEffect(() => {
    let newValue: any = [];
    if (info?.data && info?.data.menu_items) {
      const navigation_menu = info.data.menu_items.filter(item => item.menu_items.length > 0);
      if (navigation_menu.length > 0) {
        const allShopCategories = flattenCategoriesTree(navigation_menu);
        newValue = [...allShopCategories];
      }
    }
    if (content && content.getShopLanding && content.getShopLanding.data?.length > 0) {
      const newList: any[] = getSectionsList();
      const allShopCategories = flattenCategoriesTree(newList);
      newValue = [...newValue, ...allShopCategories];
    }
    setValue(newValue);
  }, [info, content]);

  return { value, getMainCategory: getMainCategoryWrapper };
};

type BreadLink = {
  pageName: string;
  route: string;
};

const defaultList: BreadLink[] = [
  { pageName: 'home', route: AppRoutes.home },
  { pageName: 'shop.value', route: AppRoutes.shop },
];

const ShopLayout = () => {
  const { productID, collectionCode } = useParams();
  const translate = useTranslation();
  const navigate = useNavigate();
  const { value: allShopCategories, getMainCategory } = useAllShopCategories();
  const [mainCategory, setMainCategory] = useState<MenuItem>();
  const [isMegaMenuOpen, setIsMegaMenuOpen] = useState<boolean>(false);
  const [breadLinks, setBreadLinks] = useState<BreadLink[]>(defaultList);
  const [shopCategory, setShopCategory] = useState<SelectedCategories>({ main: undefined, sub: undefined });
  const [ProductInfo, setProductInfo] = useState<ProductInfo>();
  const { currentBreakPoint } = useGetCurrentBreakPoint();

  const staticShopCategory: SimplifiedShopCategory | null = null;

  useEffect(() => {
    let shopCategory: SimplifiedShopCategory | undefined;
    let newCode = collectionCode;

    if (newCode?.startsWith('c-') || newCode?.startsWith('m-')) {
      newCode = newCode.slice(2);
    }

    if (newCode) {
      shopCategory = allShopCategories.find(subCategory => subCategory.collection_code === newCode || subCategory.menu_code === newCode);

      if (shopCategory) {
        const mainCategory = getMainCategory(newCode);
        if (mainCategory) {
          setMainCategory(mainCategory);
        }
        setShopCategory({ main: mainCategory, sub: shopCategory });
      } else if (staticShopCategory) {
        shopCategory = staticShopCategory;
        setShopCategory({ main: undefined, sub: shopCategory });
      }
    }
  }, [collectionCode, allShopCategories]);

  useEffect(() => {
    if (shopCategory) {
      setBreadLinks(oldList => {
        const newList: BreadLink[] = [...oldList];
        const language = translate.i18n.language;
        const sub = shopCategory.sub ? shopCategory.sub : shopCategory.main;
        if (sub) {
          let subLink = '';
          if (sub.collection_code && collectionCode?.startsWith('c-')) {
            subLink = `c-${sub.collection_code}`;
          } else if (sub.menu_code && collectionCode?.startsWith('m-')) {
            subLink = `m-${sub.menu_code}`;
          }
          const subcategoryLink: BreadLink = {
            pageName: language === 'en' ? sub?.name_en : sub.name_ar,
            route: AppRoutes.shop + `/${subLink}/`,
          };
          newList[2] = subcategoryLink;
          if (ProductInfo) {
            const productLink: BreadLink = {
              pageName: language === 'en' ? ProductInfo.name_en : ProductInfo.name_ar,
              route: AppRoutes.shop + `/${subLink}/${ProductInfo.code}`,
            };
            newList[3] = productLink;
          }
        } else if (window.location.pathname.includes('cart')) {
          const cartLink: BreadLink = {
            pageName: 'shop.cart.cart_title',
            route: AppRoutes.shop + '/cart',
          };
          newList[2] = cartLink;
        }
        return newList;
      });
    } else if (SHOP_CART_PAGES.includes(window.location.pathname) || window.location.pathname.includes('payment')) {
      const newLinkList: BreadLink[] = [{ pageName: 'home', route: AppRoutes.home }];
      if (window.location.pathname === SHOP_PAGES.myorders) {
        newLinkList.push(
          { pageName: 'screen.profile', route: AppRoutes.useraccount },
          { pageName: 'screen.orders', route: AppRoutes.myorders },
        );
      } else {
        newLinkList.push({ pageName: 'screen.cart', route: AppRoutes.cart });
      }
      setBreadLinks(newLinkList);
    }
  }, [shopCategory, ProductInfo, translate.i18n.language, window.location.pathname]);

  const handleShowToastMessage = (message: string, type: 'error' | 'success') => {
    toastWrapper(type, message);
  };

  return (
    <EwcProvider showToastMessage={handleShowToastMessage}>
      <div className="flex flex-col overflow-clip">
        {!productID && !SHOP_CART_PAGES.includes(window.location.pathname) && !window.location.pathname.includes('payment') && (
          <ShopHeader isMegaMenuOpen={isMegaMenuOpen} setIsMegaMenuOpen={setIsMegaMenuOpen} staticShopCategory={staticShopCategory} />
        )}
        <div className="relative">
          {(breadLinks.length > 2 ||
            SHOP_CART_PAGES.includes(window.location.pathname) ||
            window.location.pathname.includes('payment')) && (
            <BreadCrumbs<BreadLink>
              className="z-[10] text-caption"
              list={currentBreakPoint === BreakPoints.mobile ? [breadLinks[breadLinks.length - 2]] : breadLinks}
              getString={(link: BreadLink) => t(link.pageName)}
              onClick={(link: BreadLink) => navigate(link.route)}
            />
          )}
          <div className="z-[1]">
            <Outlet context={{ shopCategory, setProductInfo } satisfies ContextType} />
          </div>
        </div>
      </div>
    </EwcProvider>
  );
};

export default ShopLayout;

export const useShopContext = () => {
  return useOutletContext<ContextType>();
};
