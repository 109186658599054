import { IMAGES, LottieIconAnimations } from '@fe-monorepo/assets';
import { IMAGE_ALT_LABELS, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import ButtonFooter from '@fe-web/Atoms/Buttons/ButtonFooter';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';

import { AppRoutes } from '../../../../../app.routes.enum';
import SectionTitle from '../../components/SectionTitle';

interface Props {
  sectionTitle: string;
  bannerImage?: string;
  handleClickPlay: () => void;
}

const MiniGamesBanner = ({ sectionTitle, bannerImage, handleClickPlay }: Props) => {
  const isRTL = useSelector((state: RootState) => state?.app.isRTL);
  const user = useSelector((state: RootState) => state?.user.userContext);
  const isMobile = useMobileDetect();
  const lottieRef = useRef<LottieRefCurrentProps>(null);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { translate } = useTranslate();

  return (
    <div className="inline-flex flex-col SD:gap-[2.5rem] gap-[1.5rem]">
      <SectionTitle>{sectionTitle}</SectionTitle>
      <div className="inline-flex SD:flex-row flex-col justify-center SD:gap-[1.5rem]">
        {bannerImage ? (
          <LazyLoadImage
            className="SD:w-[11.25rem] w-full SD:h-[19rem] h-[20.9375rem] SD:rounded-[0.5rem] rounded-t-[0.5rem] object-cover"
            src={bannerImage}
            alt={sectionTitle.replace(/\s/g, '_')}
          />
        ) : (
          <div
            className="relative overflow-hidden bg-black100 SD:w-[11.25rem] w-full SD:h-[19rem] h-[20.9375rem] SD:rounded-[0.5rem] rounded-t-[0.5rem]
              SD:border-[0.0625rem] border-t-[0.0625rem] border-l-[0.0625rem] border-r-[0.0625rem] border-gray-200-zinc-700"
          >
            <div
              className="absolute w-full h-full 
                SD:rounded-[0.5rem] rounded-t-[0.5rem]
                bg-gradient-to-t from-black/50 SD:to-50% to-[20%]"
            />
            <LazyLoadImage
              className="absolute w-full h-full SD:rounded-[0.5rem] rounded-t-[0.5rem]"
              alt={translate(IMAGE_ALT_LABELS.mini_game_banner_fallback_layer_1 as TxKeyPath) ?? ''}
              src={!isMobile ? IMAGES.MiniGameStaticLayer1.toString() : IMAGES.MiniGameMobileStaticLayer1.toString()}
            />
            <LazyLoadImage
              className={`w-full right-[0.75rem] absolute SD:top-[2.8125rem]`}
              alt={translate(IMAGE_ALT_LABELS.mini_game_banner_fallback_layer_2 as TxKeyPath) ?? ''}
              src={!isMobile ? IMAGES.MiniGameStaticLayer2.toString() : IMAGES.MiniGameMobileStaticLayer2.toString()}
            />
          </div>
        )}
        <div className="relative bg-neutral-800 w-full SD:h-[19rem] h-[18.125rem] SD:rounded-[0.5rem] rounded-b-[0.5rem] inline-flex justify-center items-center">
          <Lottie
            animationData={!isMobile ? LottieIconAnimations.Mini_Games_Cover : LottieIconAnimations.Mini_Games_Mobile_Cover}
            loop={true}
            style={{
              height: `${'100%'}`,
              width: `${'100%'}`,
            }}
            autoPlay={true}
            lottieRef={lottieRef}
          />
          <LazyLoadImage
            className="absolute h-full w-full"
            src={IMAGES.MiniGameBannerGlow.toString()}
            alt={translate(IMAGE_ALT_LABELS.mini_game_banner_glow_effect as TxKeyPath) ?? ''}
          />
          <div className="absolute inline-flex flex-col w-full h-full">
            <div className="inline-flex flex-col gap-[2.8125rem] h-full justify-center items-center mt-[2.5rem]">
              <p className="font-medium text-fs-big-title text-white text-center">{sectionTitle}</p>
              <ButtonFooter
                className={`SD:w-[11.25rem] w-[18.5rem] text-fs-body text-white font-medium rounded-sm px-[1.5rem] py-[0.5rem] ${
                  !isRTL ? `after:bg-sunset before:bg-white` : `before:bg-sunset after:bg-white`
                } hover:text-sunset`}
                text={translate('mini_game.play_now')}
                onClick={() => handleClickPlay()}
                iconColor="fill-white"
                iconColorHover="fill-sunset"
              />
            </div>
            {!user.username && (
              <div className="mt-auto rounded-b-[0.5rem] bg-black/40 w-full py-[0.75rem] px-[1.5rem] inline-flex flex-row justify-center gap-[0.5rem]">
                <p className="text-fs-body font-regular text-white/70">{translate('mini_game.dont_lose_progress')}</p>
                <HoverText
                  text={translate('action_signin') ?? 'Sign in'}
                  className="relative top-[0.25rem] font-regular text-fs-body-small"
                  textClassName="text-sunset"
                  hover={{ color: 'text-sunset' }}
                  underlineStyle="bg-sunset h-[0.0625rem]"
                  onClick={() => navigate(`${AppRoutes.authSignIn}${pathname ? `?redirect_url=${pathname}` : ''}`)}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MiniGamesBanner;
