import Leaderboard from '../../../../PlayPages/TournamentDetailPage/components/Main/Leaderboard';

interface Props {
  isGameOpen?: boolean;
}

const MiniGamesLeaderBoard = ({ isGameOpen }: Props) => {
  return <Leaderboard isMiniGame isGameOpen={isGameOpen} />;
};

export default MiniGamesLeaderBoard;
