export default function OptionsIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M10 16.6666C9.54171 16.6666 9.14935 16.5034 8.82296 16.177C8.49657 15.8506 8.33337 15.4583 8.33337 14.9999C8.33337 14.5416 8.49657 14.1492 8.82296 13.8228C9.14935 13.4964 9.54171 13.3333 10 13.3333C10.4584 13.3333 10.8507 13.4964 11.1771 13.8228C11.5035 14.1492 11.6667 14.5416 11.6667 14.9999C11.6667 15.4583 11.5035 15.8506 11.1771 16.177C10.8507 16.5034 10.4584 16.6666 10 16.6666ZM10 11.6666C9.54171 11.6666 9.14935 11.5034 8.82296 11.177C8.49657 10.8506 8.33337 10.4583 8.33337 9.99992C8.33337 9.54159 8.49657 9.14922 8.82296 8.82284C9.14935 8.49645 9.54171 8.33325 10 8.33325C10.4584 8.33325 10.8507 8.49645 11.1771 8.82284C11.5035 9.14922 11.6667 9.54159 11.6667 9.99992C11.6667 10.4583 11.5035 10.8506 11.1771 11.177C10.8507 11.5034 10.4584 11.6666 10 11.6666ZM10 6.66659C9.54171 6.66659 9.14935 6.50339 8.82296 6.177C8.49657 5.85061 8.33337 5.45825 8.33337 4.99992C8.33337 4.54159 8.49657 4.14922 8.82296 3.82284C9.14935 3.49645 9.54171 3.33325 10 3.33325C10.4584 3.33325 10.8507 3.49645 11.1771 3.82284C11.5035 4.14922 11.6667 4.54159 11.6667 4.99992C11.6667 5.45825 11.5035 5.85061 11.1771 6.177C10.8507 6.50339 10.4584 6.66659 10 6.66659Z"
        fill="var(--text-tertiary)"
      />
    </svg>
  );
}
