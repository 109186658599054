import { BrazeCustomEvents, MixPanelEvents } from '@fe-monorepo/helper';
import { BGType, useThemedBackground, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import quickplay from 'quickplay-shorts-js';
import { createRef, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import DownArrowGray from '../assets/icons/DownArrowGray';
import LeftArrowIcon from '../assets/icons/LeftArrowIcon';
import RightArrowIcon from '../assets/icons/RightArrowIcon';
import UpArrowGray from '../assets/icons/UpArrowGray';
import Bottom from '../components/Layouts/Bottom';
import LoginModal from '../components/Login-UI/LoginModal';
import VideoItem from '../components/VerticalPlayer/VideoItem';
import { getVideosData } from '../components/VerticalPlayer/helpers';
import useVerticalPlayer from '../components/VerticalPlayer/hooks/useVerticalPlayer';
import Loader from '../components/common/Loader';
import { CONTENT_TYPE, EVENTS } from '../constants';
import { useLoginModalContext } from '../contexts/LoginModal';
import useAnalytics from '../hooks/useAnalytics';

const userModule = new quickplay.QuickPlayUserModule();
const activityTimeline = new quickplay.QuickPlayActivityTimeline();
const videoModule = new quickplay.QuickPlayVideosModule();
const hashTagModule = new quickplay.QuickPlayHashTag();
const feedModule = new quickplay.QuickPlayFeedModule();
const soundModule = new quickplay.QuickPlaySoundModule();
const challengeModule = new quickplay.QuickPlayChallenges();

const fetchHashTagVideos = async (hashtagName: any, page: number) => {
  try {
    const limit = 13;
    const {
      status,
      data: { result: hashTagVideos },
    } = await hashTagModule.getHashTagVideoList({
      limit: limit,
      offset: page || 1,
      hashtagName: hashtagName,
    });

    if (status === 200) {
      return hashTagVideos;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
};

const fetchUserVideos = async (userId: any, page: number) => {
  try {
    const limit = 6;
    const {
      status,
      data: { result: videosList },
    } = await userModule.getUserVideoList({
      limit: limit,
      offset: page,
      userId,
    });
    if (status === 200) {
      return videosList;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
};

const fetchActivitTimeline = async (page: number) => {
  try {
    const limit = 6;
    let {
      status,
      data: { result: videosList },
    } = await activityTimeline.getActivityTimelineList({
      limit: limit,
      offset: page,
      event: 'like',
    });
    if (status === 200) {
      videosList = videosList?.map((item: any) => item.metakeys);
      return videosList;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
};

const fetchVideoById = async (contentId: any) => {
  try {
    const {
      status,
      data: { result: videoResult },
    } = await videoModule.getVideoById(contentId as string);
    if (status === 200) {
      const { videoData } = videoResult;
      return videoData;
    }
    return {};
  } catch (error) {
    console.error(error);
  }
};

const fetchVideoListBySound = async (soundId: any, page: number) => {
  try {
    const limit = 6;
    const {
      data: { statusCode, result: videosList },
    } = await soundModule.fetchVideoListBySoundId({
      limit: limit,
      offset: page || 1,
      soundId: soundId,
    });
    if (statusCode === 2004) {
      return videosList;
    }
  } catch (error) {
    console.error(error);
  }
};

const ContentDetailPage = () => {
  const defaultLanguage = localStorage.getItem('i18nextLng');
  const { translate } = useTranslate();
  const { search } = useLocation();
  const isMobile = useMobileDetect();
  const searchParams = new URLSearchParams(search);
  const contentType = searchParams.get('contentType');
  const commentId = searchParams.get('commentId');
  const replyId = searchParams.get('replyId');
  const offset = Number(searchParams.get('offset')) || 1;
  const { contentId } = useParams();
  const themeConfig = useSelector((state: RootState) => state.app.themeConfig);
  const id = searchParams.get('clickedVideoId') ?? contentId;
  const videoRefs = useRef<any>(null);
  const [page, setPage] = useState(offset);
  const verticalPlayerRef = useRef<HTMLDivElement>(null);
  const { videos, setVideos, currentVideoIndex, setCurrentVideoIndex } = useVerticalPlayer();
  const [scrollToVideo, setScrollToVideo] = useState(true);
  const { showLoginModal, setShowLoginModal } = useLoginModalContext();
  const sourceType = searchParams.get('sourceType');
  const { trackEvent } = useAnalytics();
  const [scrollDirection, setScrollDirection] = useState('');
  const lastScrollTop = useRef(0);
  const { backgroundImage } = useThemedBackground(BGType.landing, isMobile);
  const { user } = useUserProfile();
  let previousVideo: HTMLVideoElement | null = null;
  let nextVideo: HTMLVideoElement | null = null;
  let previousVideoObject: any = null;
  let nextVideoObject: any = null;

  const goBack = () => {
    window.history.back();
  };

  const fetchVideos = async () => {
    try {
      const limit = 6;
      const {
        data: { result: feeds = [] },
      } = await feedModule.getFeedList({
        limit: limit,
        offset: page,
        c_type: 'video',
      });
      if (feeds.length && feeds.filter((video: any) => video.contentType === 'video').length === 0) {
        setPage(prev => prev + 1);
      }
      return feeds;
    } catch (error) {
      console.error(error);
    }
  };

  const fetchChallengeData = async (contentId: any, page: number) => {
    try {
      const {
        data: {
          result: { videos: videosList },
        },
      } = await challengeModule.getChallengeDetails({
        limit: 6,
        offset: page || 1,
        hashtagId: contentId,
      });
      return videosList;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        let videosList: any = [];
        if (contentType === CONTENT_TYPE.HASHTAG) {
          videosList = await fetchHashTagVideos(contentId, page);
        }
        if (contentType === CONTENT_TYPE.PROFILE) {
          videosList = await fetchUserVideos(contentId, page);
        }
        if (contentType === CONTENT_TYPE.SOUND) {
          videosList = await fetchVideoListBySound(contentId, page);
        }
        if (contentType === CONTENT_TYPE.FAVOURITE) {
          videosList = await fetchActivitTimeline(page);
        }
        if (contentType === CONTENT_TYPE.CHALLENGE) {
          videosList = await fetchChallengeData(contentId, page);
        }
        if (
          contentType === CONTENT_TYPE.SHARE ||
          (contentType === CONTENT_TYPE.VIDEO && !sourceType) ||
          contentType === CONTENT_TYPE.ORIGINAL_VIDEO
        ) {
          if (page === 1) {
            const videoData = await fetchVideoById(contentId);
            const feedData = await fetchVideos();
            videosList = [videoData].concat(feedData);
          } else {
            const feedData = await fetchVideos();
            videosList = feedData;
          }
        }
        if (sourceType && contentType === CONTENT_TYPE.VIDEO) {
          videosList = await fetchHashTagVideos(sourceType, page);
        }
        if (!contentType) {
          // eslint-disable-next-line prefer-const
          let videoData = await fetchVideoById(contentId);
          videoData.deeplinkData = {
            commentId,
            replyId,
          };
          const feedData = await fetchVideos();
          videosList = [videoData].concat(feedData);
        }
        const videosData = [...videos, ...getVideosData(videosList, videos.length)];
        videoRefs.current = [...videosData.map(() => createRef<HTMLVideoElement>())];
        setVideos(videosData);
      } catch (error) {
        console.error(error);
      }
    }
    fetchData();
  }, [contentType, contentId, page]);

  useEffect(() => {
    // The Function will load more videos when user scroll //
    const handleLoadMore = () => {
      const verticalPlayerCurrent = verticalPlayerRef?.current;
      if (verticalPlayerCurrent) {
        const scrollTop = verticalPlayerCurrent.scrollTop;
        const scrollHeight = verticalPlayerCurrent.scrollHeight;
        const clientHeight = verticalPlayerCurrent.clientHeight;
        if (scrollTop + clientHeight > scrollHeight) {
          setPage(prev => prev + 1);
        }
        const currentScrollTop = scrollTop;
        if (currentScrollTop > lastScrollTop.current) {
          setScrollDirection('down');
        } else {
          setScrollDirection('up');
        }
      }
    };

    const scrollToElement = async (element: HTMLElement | null) => {
      return new Promise<void>(resolve => {
        if (element) {
          element.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
          // Resolve the promise after a delay to mimic the scroll duration
          setTimeout(resolve, 2000); // Adjust this delay as needed
        } else {
          resolve();
        }
      });
    };

    const executeScroll = async () => {
      if (scrollToVideo) {
        if (id && videos.length) {
          const clickedVideoElement = document.getElementById(`content-video-${id}`);
          await scrollToElement(clickedVideoElement);
          setScrollToVideo(false);
        }
      } else if (videoRefs.current && videoRefs.current.length > 0) {
        const currentVideo = videoRefs.current[currentVideoIndex].current;
        await scrollToElement(currentVideo);
      }
    };
    executeScroll();
    const currentVerticalPlayerRef = verticalPlayerRef?.current;
    currentVerticalPlayerRef?.addEventListener('scroll', handleLoadMore);
    return () => {
      currentVerticalPlayerRef?.removeEventListener('scroll', handleLoadMore);
    };
  }, [currentVideoIndex, id, videos.length]);

  // This useEffect will trigger video_stop_play event //
  useEffect(() => {
    // Ensure videoRefs are populated before accessing them
    if (videoRefs.current && videoRefs.current.length > 0) {
      previousVideo = videoRefs.current[currentVideoIndex - 1]?.current || null;
      nextVideo = videoRefs.current[currentVideoIndex + 1]?.current || null;

      previousVideoObject = videos[currentVideoIndex - 1] || null;
      nextVideoObject = videos[currentVideoIndex + 1] || null;
    }

    const handleTimeUpdate = () => {
      if (previousVideo) {
        const previousVideoPlaybackTime = previousVideo.currentTime;
        console.log('Previous Video Playback Time', previousVideoPlaybackTime);
      }
      if (nextVideo) {
        const nextVideoPlaybackTime = nextVideo.currentTime;
        console.log('Next Video Playback Time', nextVideoPlaybackTime);
      }
    };

    // Attach event listeners to previous and next videos
    if (previousVideo) {
      previousVideo.addEventListener('timeupdate', handleTimeUpdate);
    }
    if (nextVideo) {
      nextVideo.addEventListener('timeupdate', handleTimeUpdate);
    }

    if (scrollDirection === 'down' && previousVideo) {
      let playbackTime = parseFloat(previousVideo.currentTime.toFixed(2));

      trackEvent({
        event: EVENTS.CONTENT_STOP_PLAY,
        content: previousVideoObject,
        timeElapsed: playbackTime || 0,
      });

      if (playbackTime >= 3) {
        activityTimeline.activityTimelineView({ assetId: previousVideoObject.videoId, duration: playbackTime }).then((response: any) => {
          const { status } = response ?? {};
          if (status === 200) {
            trackEvent({
              event: EVENTS.VIEW_IMPRESSION,
              content: previousVideoObject,
            })
              .then(res => {
                const eventPayload = {
                  'Bit ID': previousVideoObject.videoId,
                  'Bit Title': previousVideoObject.title,
                  'Viewer Username': user?.username ?? 'GUEST',
                  'Bit Publisher Username': previousVideoObject.user.userName,
                };
                mixpanelHelper.trackEvent(MixPanelEvents.bitViewed, eventPayload);
                brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.BitViewed, eventPayload);
              })
              .catch(error => {
                console.error('Error tracking event:', error);
              });
          }
        });
      }
    } else if (scrollDirection === 'up' && nextVideo) {
      let playbackTime = parseFloat(nextVideo.currentTime.toFixed(2));
      trackEvent({
        event: EVENTS.CONTENT_STOP_PLAY,
        content: nextVideoObject,
        timeElapsed: playbackTime || 0,
      });
      if (playbackTime >= 3) {
        activityTimeline.activityTimelineView({ assetId: nextVideoObject.videoId, duration: playbackTime }).then((response: any) => {
          const { status } = response ?? {};
          if (status === 200) {
            trackEvent({
              event: EVENTS.VIEW_IMPRESSION,
              content: nextVideoObject,
            })
              .then(res => {
                const eventPayload = {
                  'Bit ID': nextVideoObject.videoId,
                  'Bit Title': nextVideoObject.title,
                  'Viewer Username': user?.username ?? 'GUEST',
                  'Bit Publisher Username': nextVideoObject.user.userName,
                };
                mixpanelHelper.trackEvent(MixPanelEvents.bitViewed, eventPayload);
                brazeHelper.logCustomBrazeEvent(BrazeCustomEvents.BitViewed, eventPayload);
              })
              .catch(error => {
                console.error('Error tracking event:', error);
              });
          }
        });
      }
    }

    // Cleanup event listeners on unmount or when dependencies change
    return () => {
      if (previousVideo) {
        previousVideo.removeEventListener('timeupdate', handleTimeUpdate);
      }
      if (nextVideo) {
        nextVideo.removeEventListener('timeupdate', handleTimeUpdate);
      }
    };
  }, [scrollDirection, currentVideoIndex]);

  //   Page will be added by 1, when we are on second last video //
  useEffect(() => {
    if (currentVideoIndex === videos.length - 2) {
      setPage(prev => prev + 1);
    }
  }, [currentVideoIndex, videos.length]);

  // Handle Next Video //
  const handleNext = () => {
    setScrollDirection('down');
    setCurrentVideoIndex(prev => prev + 1);
  };

  // Handle Previous Video //
  const handlePrev = () => {
    setScrollDirection('up');
    setCurrentVideoIndex(prev => prev - 1);
  };

  // Loader
  if (!videos.length) return <Loader />;

  return (
    <>
      <div className="main-wrapper">
        <div className="main-head">
          <button className="back-btn-a" onClick={goBack}>
            {defaultLanguage === 'en' ? <LeftArrowIcon /> : <RightArrowIcon />}
            {translate('quickplay.back-btn')}
          </button>
          {contentType === CONTENT_TYPE.HASHTAG && (
            <>
              <div className="main-head-title">#{contentId}</div>
              <span></span>
            </>
          )}
        </div>
        <div className="row-responsive">
          <div
            className="mid-content"
            style={
              !isMobile && themeConfig && backgroundImage
                ? {
                    backgroundImage: `url(${backgroundImage})`,
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                    backgroundSize: '100% auto',
                  }
                : {}
            }
          >
            <button onClick={handleNext} className="next-btn" disabled={!(currentVideoIndex !== videos.length - 1)}>
              <DownArrowGray />
              <span>{translate('quickplay.label-next-video')}</span>
            </button>
            <button onClick={handlePrev} className="prev-btn" disabled={!(currentVideoIndex > 0)}>
              <UpArrowGray />
              <span>{translate('quickplay.label-previous-video')}</span>
            </button>
            <div ref={verticalPlayerRef} id="vertical-player" className="player_wrapper player_viewport">
              {videos?.map((video: any, index: number) => (
                <VideoItem
                  key={video.videoId}
                  ref={videoRefs.current[index]}
                  video={video}
                  setVideos={setVideos}
                  setCurrentVideoIndex={setCurrentVideoIndex}
                />
              ))}
            </div>
            <Bottom />
          </div>
        </div>
      </div>
      <LoginModal show={showLoginModal} handleClose={() => setShowLoginModal(false)} />
    </>
  );
};

export default ContentDetailPage;
