import { UserModel } from '@fe-monorepo/models';
import { RootState, setUser } from '@fe-monorepo/store';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useAccount } from './useUser/useAccount';

export const useUserProfile = () => {
  const [loading, setLoading] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isAppLoaded, setIsAppLoaded] = useState<boolean>(false);
  const [isProfileLoaded, setIsProfileLoaded] = useState(false);

  const User = useSelector((state: RootState) => state.user.userContext);
  const dispatch = useDispatch();

  const user = useMemo(() => {
    if (User.username) {
      return User;
    }

    return null;
  }, [User.username, User.avatar_url, User.privacy_consent]);

  const { getUserProfile } = useAccount();

  const setUserToken = (token: string) => {
    if (!User.token) {
      dispatch(setUser({ token } as UserModel));
    }
  };

  const getUser = async () => {
    setLoading(true);
    await getUserProfile();
    setLoading(false);
    setIsProfileLoaded(true);
  };

  useEffect(() => {
    if (User.token && !User.username && User.isTokenVerified) {
      getUser();
    } else {
      setIsProfileLoaded(true);
    }
   
  }, [User.token]);

  useEffect(() => {
    setIsLoggedIn(!!(User.token && User.username));
  }, [User.token, User.username]);

  useEffect(() => {
    setIsAppLoaded(true);
  }, []);

  return {
    user,
    isLoggedIn,
    isAppLoaded,
    loading,
    isProfileLoaded,
    setUserToken,
  };
};
