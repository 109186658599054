import { Quantity } from '@fe-web/hooks/useQuantity';

interface QuantityProps {
  isAvailable: boolean;
  quantity: Quantity;
  quantityErrorMessage?: string;
  isPageLoaded?: boolean;
}

const QuantitySection = (props: QuantityProps) => {
  const { isAvailable, quantity, isPageLoaded, quantityErrorMessage } = props;

  return (
    <div>
      <div className="flex h-[44px] 2K:h-[78.22px] 4K:h-[117.33px] 8K:h-[234px] gap-20">
        <div className="flex items-center justify-around bg-secondary/[5%] h-full aspect-[38/11] rounded-[2px] 2K:rounded-[3.55px] 4K:rounded-[5.33px] 8K:rounded-[10.66px] font-regular responsive-text-body">
          <button
            className={`h-[52.54%] w-[21%] ${
              quantity.isMin ? 'cursor-not-allowed text-secondary/[30%]' : 'text-secondary hover:text-sunset'
            }`}
            disabled={quantity.isMin && isAvailable}
            onClick={quantity.decrement}
          >
            -
          </button>

          <div className="flex justify-center items-center h-[72.72%] w-[26.31%] bg-white100 select-none rounded-[2px] 2K:rounded-[3.55px] 4K:rounded-[5.33px] 8K:rounded-[10.66px]">
            <p>{quantity.value}</p>
          </div>

          <button
            className={`h-[52.54%] w-[21%] ${
              quantity.isMax || !isAvailable ? 'cursor-not-allowed text-secondary/[30%]' : 'text-secondary hover:text-sunset'
            }`}
            disabled={quantity.isMax && isAvailable}
            onClick={() => {
              if (isAvailable && quantityErrorMessage === '') {
                quantity.increment();
              }
            }}
          >
            +
          </button>
        </div>
      </div>
      {!isAvailable && quantityErrorMessage && (
        <div className="text-red font-regular text-fs-body-small mt-[0.5rem]">{quantityErrorMessage}</div>
      )}
    </div>
  );
};

export default QuantitySection;
