import { gql } from '@apollo/client';

export const COMMUNITY_FOLLOW_QUERIES = {
  getFollowers: gql`
    query getFollowers($details: GetFollowInput) {
      getFollowers(details: $details) {
        is_successful
        error_msg
        error_code
        app_code
        data {
          username
          last_cursor
          display_name
          avatar_url
          viewer_is_self_user
          viewer_is_following_user
          can_send_message
          viewer_has_blocked_user
        }
      }
    }
  `,
  getFollowings: gql`
    query getFollowings($details: GetFollowInput) {
      getFollowings(details: $details) {
        is_successful
        error_msg
        error_code
        app_code
        data {
          username
          last_cursor
          display_name
          avatar_url
          viewer_is_self_user
          viewer_is_following_user
          can_send_message
          viewer_has_blocked_user
        }
      }
    }
  `,
  lookup: gql`
    query lookup($details: GetFollowInput) {
      lookup(details: $details) {
        is_successful
        error_msg
        error_code
        app_code
        data {
          username
          last_cursor
          display_name
          avatar_url
          viewer_is_self_user
          viewer_is_following_user
          can_send_message
          viewer_has_blocked_user
        }
      }
    }
  `,
};
