import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';

interface RemoveAllProps {
  isHeaderDark?: boolean;
  dir?: string;
  handleAction: (action: string) => void;
  handleClose: () => void;
}

const RemoveAll = (props: RemoveAllProps) => {
  const { isHeaderDark, dir, handleAction, handleClose } = props;
  const { translate } = useTranslate();
  return (
    <div
      className="
        flex flex-col mt-[20px] justify-start items-center bg-primary border border-neutral-300-zinc-700
        rounded-lg p-[48px] pb-[72px] 2K:p-[80px] 4K:p-[96px] 8K:p-[196px] gap-[20px] max-h-[100vh] overflow-y-auto
        fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[406px] SD:w-[448px]
        2K:w-[796px] 4K:w-[1146px] 8K:w-[2296px]"
    >
      <div className="w-full">
        <ResponsiveIcon
          className="absolute right-[52px] 4K:right-[94px] 8K:right-[172px] justify-items-end"
          name={IconNames.close_xbutton}
          baseHeight={12}
          baseWidth={12}
          onClick={handleClose}
        />
      </div>
      <div>
        <p className="text-fs-big-title font-medium text-secondary">{translate('shop.cart.remove_all_modal_title')}</p>
      </div>
      <div>
        <p className="text-fs-body font-regular text-secondary text-opacity-[0.70]">{translate('shop.cart.remove_all_modal_message')}</p>
      </div>
      <div className="w-full">
        <Button
          text={translate('shop.cart.remove_all_yes_button') ?? ''}
          fullWidth={true}
          style={`
            text-fs-body font-medium mt-8 2K:py-[0.675rem] 2K:mt-[0.775rem] 8K:mt-[1rem]
            py-[8px]
            w-full gap-[8px] z-1 
            text-white100 border-[1px] border-transparent mb-[0px] 
            xsMax:mb-[0px] items-end
            rounded-sm ${
              isHeaderDark
                ? dir === 'ltr'
                  ? 'after:bg-sunset before:bg-white100'
                  : 'before:bg-sunset after:bg-white100'
                : `${dir === 'ltr' ? 'after:bg-sunset before:bg-white100' : 'before:bg-sunset after:bg-white100'}
                    hover:border-[1px] hover:border-sunset`
            } 
            hover:text-sunsetText rounded-sm`}
          disabled={false}
          onClick={() => {
            handleAction('yes');
          }}
        />
        <Button
          text={translate('shop.cart.remove_all_no_button') ?? ''}
          style={`
            text-fs-body font-medium border-[1px] border-sunset mt-8 2K:py-[0.675rem] 2K:mt-[0.775rem] 8K:mt-[1rem]
            w-full py-[8px] gap-[8px] z-1 
            text-white100 text-sunsetText mb-[0px] 
            xsMax:mb-[0px] items-end w-full
            rounded-sm ${
              isHeaderDark
                ? dir === 'ltr'
                  ? 'after:bg-white100 before:bg-sunset'
                  : 'before:bg-white100 after:bg-sunset'
                : `${dir === 'ltr' ? 'after:bg-white100 before:bg-sunset' : 'before:bg-white100 after:bg-sunset'}`
            } 
            hover:text-white100 rounded-sm
            4K:border-[2px] 8K:border-[4px]`}
          disabled={false}
          onClick={() => {
            handleAction('no');
          }}
        />
      </div>
    </div>
  );
};

export default RemoveAll;
