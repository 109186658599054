import { IconNames } from '@fe-monorepo/helper';
import { useBlock } from '@fe-monorepo/hooks';
import { GetListOfBlockedUsersData } from '@fe-monorepo/models';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { t } from 'i18next';
import { useEffect, useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';

interface BlockedListContainerProps {
  list: GetListOfBlockedUsersData[];
  toggleNextList: () => void;
  lastCursor?: number;
}

interface BlockedListProps {
  username: string;
  displayName: string;
  avatarUrl?: string;
  lastCursor: number;
}

const BlockedList = ({ username, avatarUrl, displayName, lastCursor }: BlockedListProps) => {
  const [isUnblock, setIsUnblock] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { crmCommunityBlock, crmCommunityUnblock } = useBlock();

  const toggleBlock = () => {
    setIsLoading(true);
    isUnblock
      ? crmCommunityUnblock({ username: username }).then(res => {
          if (res?.crmCommunityUnblock.is_successful) {
            setIsUnblock(false);
            setIsLoading(false);
            toastWrapper('success', t('msg_success_user_unblocked', { username: username }));
          } else {
            setIsLoading(false);
            toastWrapper('error', t('msg_failed_generic_try_again'));
          }
        })
      : crmCommunityBlock({ username: username }).then(res => {
          if (res?.crmCommunityBlock.is_successful) {
            setIsUnblock(true);
            setIsLoading(false);
            toastWrapper('success', t('msg_success_user_blocked', { username: username }));
          } else {
            setIsLoading(false);
            toastWrapper('error', t('msg_failed_generic_try_again'));
          }
        });
  };

  return (
    <div key={lastCursor} className="flex flex-row justify-between py-[0.5rem] border-b-[0.0625rem] border-secondary/10 items-center">
      <div className="flex flex-row gap-[0.5rem]">
        <Image
          img={avatarUrl}
          divStyle="h-[2rem] w-[2rem] bg-black/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden"
          imgStyle="h-[2rem] w-[2rem]"
          fallback={
            <ResponsiveIcon
              name={IconNames.avatar}
              baseHeight={32}
              baseWidth={32}
              className="h-[2rem] w-[2rem] bg-black/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden"
              iconClasses="h-full w-full p-[0.5rem] fill-gray"
            />
          }
        />
        <div className="flex flex-col">
          <p className="font-medium text-fs-body-small text-secondary">{username}</p>
          <p className="font-regular text-fs-caption text-zinc-600-400">{displayName}</p>
        </div>
      </div>
      {isLoading ? (
        <Oval
          height={32}
          width={32}
          color="#E95F2A"
          secondaryColor="#E95F2A"
          visible={true}
          ariaLabel="oval-loading"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : (
        <HoverText
          className={`
            font-regular
            text-${isUnblock ? 'sunset' : 'zinc-600-400'}
            text-body 2K:text-subtitle 4K:text-bigTitle 8K:text-LPTitle whitespace-nowrap flex
            tracking-[-0.0125rem] leading-[1.25rem] right-0
          `}
          hover={{ color: 'text-sunset' }}
          alwaysShowUnderline={false}
          underlineStyle={`bg-${isUnblock ? 'sunset' : 'zinc-600-400'}
          h-[1px] 2K:h-[1.77px] 4K:h-[2.66px] 8K:h-[5.33px] bottom-2`}
          text={isUnblock ? t('action_unblock') ?? '' : t('action_block') ?? ''}
          onClick={() => toggleBlock()}
        />
      )}
    </div>
  );
};

const BlockedListContainer = ({ list, toggleNextList, lastCursor }: BlockedListContainerProps) => {
  const navigate = useNavigate();
  const sentinelRef = useRef(null);
  const { isRtl } = usePageLayout();
  useEffect(() => {
    // loads new data if reach ref element
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            if (list && list.length > 0 && lastCursor) {
              toggleNextList();
            }
          }
        });
      });
      observer.observe(sentinelRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [list]);

  return (
    <div className="flex flex-col gap-[2.5rem] SD:gap-[1.5rem]">
      <div className="flex flex-row gap-[1rem]">
        <ResponsiveIcon
          name={IconNames.arrow}
          baseWidth={24}
          baseHeight={24}
          className={`fill-sunset ${!isRtl && 'rotate-180'} self-center SD:hidden`}
          onClick={() => navigate(AppRoutes.userProfile + '/settings')}
        />
        <p className="text-fs-subtitle SD:text-fs-title font-medium text-secondary">{t('settings.blocked_accounts')}</p>
      </div>
      <div>
        {list.map(data => (
          <BlockedList username={data.username} displayName={data.display_name} avatarUrl={data.avatar_url} lastCursor={data.last_cursor} />
        ))}
      </div>
      {lastCursor !== 0 && (
        <div ref={sentinelRef}>
          <Oval
            height={32}
            width={32}
            color="#E95F2A"
            secondaryColor="#E95F2A"
            visible={true}
            ariaLabel="oval-loading"
            strokeWidth={2}
            strokeWidthSecondary={2}
          />
        </div>
      )}
    </div>
  );
};

export default BlockedListContainer;
