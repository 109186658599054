import { useLazyQuery, useMutation } from '@apollo/client';
import { TMS_MANAGE_MUTATION, TMS_MANAGE_QUERY } from '@fe-monorepo/data-access';
import {
    ApproveInput,
    GetParticipantsForApprovalInput,
    GetParticipantsForSeedingInput,
    ManageMutationResponse,
    ManageQueryResponse,
    RejectInput,
    RemoveParticipantInput,
    SeedAutomaticallyInput,
    SeedManuallyInput,
    SortParticipantsInput,
} from './types';

export const useTmsManage = () => {
    const [getParticipantsForApprovalGql, { data: getParticipantsForApprovalData }] = useLazyQuery<ManageQueryResponse>(
        TMS_MANAGE_QUERY.getParticipantsForApproval,
        {
            errorPolicy: 'all',
        },
    );
    const [getParticipantsForSeedingGql, { data: getParticipantsForSeedingData }] = useLazyQuery<ManageQueryResponse>(
        TMS_MANAGE_QUERY.getParticipantsForSeeding,
        {
            errorPolicy: 'all',
        },
    );
    const [seedAutomaticallyGql, { data: seedAutomaticallyData }] = useMutation<ManageMutationResponse>(
        TMS_MANAGE_MUTATION.seedAutomatically,
        {
            errorPolicy: 'all',
        },
    );
    const [seedManuallyGql, { data: seedManuallyData }] = useMutation<ManageMutationResponse>(TMS_MANAGE_MUTATION.seedManually, {
        errorPolicy: 'all',
    });
    const [removeParticipantGql, { data: removeParticipantData }] = useMutation<ManageMutationResponse>(
        TMS_MANAGE_MUTATION.removeParticipant,
        {
            errorPolicy: 'all',
        },
    );

    const [approveGql, { data: approveData }] = useMutation<ManageMutationResponse>(TMS_MANAGE_MUTATION.approve, {
        errorPolicy: 'all',
    });

    const [sortParticipantsGql, { data: sortParticipantsData }] = useMutation<ManageMutationResponse>(
        TMS_MANAGE_MUTATION.sortParticipants,
        {
            errorPolicy: 'all',
        },
    );

    const [rejectGql, { data: rejectData }] = useMutation<ManageMutationResponse>(TMS_MANAGE_MUTATION.reject, {
        errorPolicy: 'all',
    });

    const getParticipantsForApproval = async (details: GetParticipantsForApprovalInput) => {
        const { data } = await getParticipantsForApprovalGql({ variables: { details } });
        return data;
    };

    const getParticipantsForSeeding = async (details: GetParticipantsForSeedingInput) => {
        const { data } = await getParticipantsForSeedingGql({ variables: { details } });
        return data;
    };

    const seedAutomatically = async (details: SeedAutomaticallyInput) => {
        const { data } = await seedAutomaticallyGql({ variables: { details } });
        return data;
    };

    const seedManually = async (details: SeedManuallyInput) => {
        const { data } = await seedManuallyGql({ variables: { details } });
        return data;
    };

    const removeParticipant = async (details: RemoveParticipantInput) => {
        const { data } = await removeParticipantGql({ variables: { details } });
        return data;
    };

    const approve = async (details: ApproveInput) => {
        const { data } = await approveGql({ variables: { details } });
        return data;
    };

    const sortParticipants = async (details: SortParticipantsInput) => {
        const { data } = await sortParticipantsGql({ variables: { details } });
        return data;
    };

    const reject = async (details: RejectInput) => {
        const { data } = await rejectGql({ variables: { details } });
        return data;
    };

    return {
        getParticipantsForApprovalData: getParticipantsForApprovalData?.tmsManageGetParticipantsForApproval,
        getParticipantsForSeedingData: getParticipantsForSeedingData?.tmsManageGetParticipantsForSeeding,
        seedAutomaticallyData: seedAutomaticallyData?.tmsManageSeedAutomatically,
        seedManuallyData: seedManuallyData?.tmsManageSeedManually,
        removeParticipantData: removeParticipantData?.tmsManageRemoveParticipant,
        approveData: approveData?.tmsManageApprove,
        sortParticipantsData: sortParticipantsData?.tmsManageSortParticipants,
        rejectData: rejectData?.tmsManageReject,
        getParticipantsForApproval,
        getParticipantsForSeeding,
        seedAutomatically,
        seedManually,
        removeParticipant,
        approve,
        sortParticipants,
        reject,
    };
};
