import { PRODUCT_DETAILED_QUERY } from '@fe-monorepo/data-access';
import { ProductDetailedData, ProductSpecifications } from '@fe-monorepo/models';

import { useFetchQuery } from '../useFetchQuery';

type ProductDetailedInput = {
  product_code: string;
  option_ids?: number[];
};

type ProductSpecificationsInput = {
  variant_id: number;
};

const defaultValue: ProductDetailedData = {
  name_ar: '',
  name_en: '',
  description_ar: '',
  description_en: '',
  product_code: '',
  metadata: '',
  brand: {
    name_ar: '',
    name_en: '',
    collection_code: '',
  },
  supplier: '',
  is_digital: 0,
  lowest_actual_cost: 0,
  highest_actual_cost: 0,
  lowest_selling_price: 0,
  highest_selling_price: 0,
  is_liked: 0,
  options: [],
  variant_id: '',
  sku: '',
  barcode: '',
  quantity: 1,
  is_preorder: 0,
  taxable: 0,
  selling_price: 0,
  original_cost: 0,
  usage_ar: '',
  usage_en: '',
  weight: 0,
  media: [],
  featured_img: '',
};

const specsDefaultVlaue: ProductSpecifications = {
  variant_id: 0,
  sku: '',
  barcode: '',
  specifications: [],
};

export const useProductDetails = () => {
  const productDetails = useFetchQuery<ProductDetailedData, ProductDetailedInput>(PRODUCT_DETAILED_QUERY.getProductDetails, defaultValue);

  const productVariants = useFetchQuery<ProductDetailedData, ProductDetailedInput>(PRODUCT_DETAILED_QUERY.getProductDetails, defaultValue);

  const productSpecifications = useFetchQuery<ProductSpecifications, ProductSpecificationsInput>(
    PRODUCT_DETAILED_QUERY.getProductSpecification,
    specsDefaultVlaue,
  );

  return { productDetails, productVariants, productSpecifications };
};
