import { gql } from '@apollo/client';

export const REMOVE_DEVICE_MUTATION = {
    removeDevice: gql`
        mutation removeDevice($details: RemoveDeviceInput) {
            removeDevice(details: $details) {
                is_successful
                error_code
                error_msg
            }
        }
    `,
};
