import { useTranslate } from '@fe-monorepo/hooks';
import { Link } from 'react-router-dom';

import DurationIcon from '../assets/icons/DurationIcon';
import RatioIcon from '../assets/icons/RatioIcon';
import SizeIcon from '../assets/icons/SizeIcon';
import Bottom from '../components/Layouts/Bottom';
import PostCreateStepOne from '../components/Post/Create/StepOne';

const PostCreateStepOnePage = () => {
  const { translate } = useTranslate();

  return (
    <>
      <div className="full-sec">
        <div className="w-full h-full px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655 rounded-none bg-primary transition-all duration-300 flex items-center justify-between gap-[30px] 4xl:gap-54 5xl:gap-80 8xl:gap-160 animate__animated animate__fadeIn">
          <div className="full-box create-full-box">
            <div className="back-header">
              <h2>{translate('quickplay.text-createContent')}</h2>
              <Link to="/bits">{translate('quickplay.label-close')}</Link>
            </div>
            <PostCreateStepOne />
            <div className="step_header">
              <ul className="step_lists">
                <li className="step_lists_item">
                  <DurationIcon />
                  <div className="step_info">
                    <h4>{translate('quickplay.label-duration')}</h4>
                    <p>{translate('quickplay.label-duration-info')}</p>
                  </div>
                </li>
                <li className="step_lists_item">
                  <SizeIcon />
                  <div className="step_info">
                    <h4>{translate('quickplay.label-size')}</h4>
                    <p>{translate('quickplay.label-size-info')}</p>
                  </div>
                </li>
                <li className="step_lists_item">
                  <RatioIcon />
                  <div className="step_info">
                    <h4>{translate('quickplay.label-aspect-ratio')}</h4>
                    <p>{translate('quickplay.label-ratio-info')}</p>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Bottom />
    </>
  );
};

export default PostCreateStepOnePage;
