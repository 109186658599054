import { IconNames, PAGE_DESCRIPTION, PAGE_KEYWORDS, PAGE_TITLE, TxKeyPath } from '@fe-monorepo/helper';
import { useBlock, useTranslate } from '@fe-monorepo/hooks';
import { GetListOfBlockedUsersData } from '@fe-monorepo/models';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { useEffect, useState } from 'react';
import { Oval } from 'react-loader-spinner';

import PhoneNumberEmpty from '../PhoneNumber/PhoneNumberEmpty';
import BlockedListContainer from './BlockedListContainer';

const BlockedAccounts = () => {
  const [list, setList] = useState<GetListOfBlockedUsersData[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [lastCursor, setLastCursor] = useState<number>();
  const { translate } = useTranslate();
  const { getListOfBlockedUsers } = useBlock();

  const toggleNextList = () => {
    lastCursor &&
      getListOfBlockedUsers({ last_cursor: lastCursor, direction: 'next' }).then(res => {
        const dataRes = res?.getListOfBlockedUsers?.data;
        setList(dataList => [...dataList, ...(dataRes || [])]);
        dataRes?.length ? setLastCursor(dataRes[dataRes.length - 1].last_cursor) : setLastCursor(0);
      });
  };

  useEffect(() => {
    getListOfBlockedUsers({})
      .then(res => {
        const dataRes = res?.getListOfBlockedUsers?.data;
        if (dataRes) {
          setList(dataRes);
          setLastCursor(dataRes[dataRes.length - 1].last_cursor);
        }
        setIsLoading(false);
      })
      .catch(err => setIsLoading(false));
  }, []);

  useEffect(() => {
    mixpanelHelper.trackPageView('SETTINGS - BLOCKED ACCOUNT PAGE', 'blocked_account_page', window.location.pathname, document.referrer);
    brazeHelper.trackPageView('SETTINGS - BLOCKED ACCOUNT PAGE', 'blocked_account_page', window.location.pathname, document.referrer);
  }, []);

  return (
    <>
      <CustomHelmet
        pageTitle={`${translate(PAGE_TITLE.settings as TxKeyPath) ?? ''} - ${
          translate(PAGE_TITLE.settings_security as TxKeyPath) ?? ''
        } - ${translate(PAGE_TITLE.settings_blocked_accounts as TxKeyPath) ?? ''}`}
        metaDescription={translate(PAGE_DESCRIPTION.settings as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.settings as TxKeyPath) ?? ''}
      />
      {isLoading ? (
        <Oval
          height={80}
          width={80}
          color="#E95F2A"
          secondaryColor="#E95F2A"
          visible={true}
          ariaLabel="oval-loading"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : list?.length ? (
        <BlockedListContainer list={list || []} toggleNextList={toggleNextList} lastCursor={lastCursor} />
      ) : (
        <PhoneNumberEmpty
          iconSource={IconNames.blockedEmpty}
          iconStyle="py-[1.5rem]"
          headerTitle={translate('settings.blocked_accounts') ?? ''}
          headerTitleStyle="SD:text-fs-title"
          headerMessage={translate('settings.blocked_accounts_page.no_blocked_accounts') ?? ''}
          message={translate('settings.blocked_accounts_page.block_somebody') ?? ''}
          hideButton
        />
      )}
    </>
  );
};

export default BlockedAccounts;
