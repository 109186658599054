import { initializeApp } from 'firebase/app';
import { Messaging, getMessaging, isSupported } from 'firebase/messaging';
import { getEnvironment } from 'libs/data-access/src/graphql/apiEnvironments';
import { useEffect, useState } from 'react';

export const useFirebaseMessaging = () => {
  const [messaging, setMessaging] = useState<Messaging>();

  const { firebaseConfig } = getEnvironment();

  useEffect(() => {
    const initFirebase = async () => {
      const app = initializeApp(firebaseConfig);
      if (await isSupported()) {
        // Messaging service
        setMessaging(getMessaging(app));
      }
    };
    initFirebase();
  }, []);

  return { messaging };
};
