import { MixPanelEvents } from '@fe-monorepo/helper';
import { useUserProfile } from '@fe-monorepo/hooks';
import { BannerInfoDataModel } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import LazyImage from '@fe-web/Atoms/Media/LazyImage';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { getURLForRedirection } from 'apps/fe-web/src/lib/helper';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface ShopMediumBannerProps {
  name: string;
  img: string;
  language: string;
  banner_info?: BannerInfoDataModel;
}

const Banner = (props: ShopMediumBannerProps) => {
  const isMobile = useMobileDetect();
  const navigate = useNavigate();
  const [onImgError, setOnImageError] = useState<boolean>(false);
  const { user, isLoggedIn } = useUserProfile();

  const isRTL = useSelector((state: RootState) => state.app.isRTL);

  const isClickable = props.banner_info?.reference_type !== 'none' && props.banner_info?.reference_type != null;
  const cursorStyle = isClickable ? 'cursor-pointer' : 'cursor-default';

  const handleRedirection = () => {
    if (props.banner_info?.reference_type && props.banner_info.reference_value) {
      const { url, options } = getURLForRedirection(
        props.banner_info?.reference_type,
        props.banner_info.reference_value,
        isLoggedIn,
        user,
        'm-',
        isRTL ? props.banner_info.name_ar : props.banner_info.name_en,
      );
      mixpanelHelper.trackEvent(MixPanelEvents.bannersButtonCTA, {
        'CTA Name': `Secondary Banner - ${props.banner_info.name_en}`,
        Referrer: window.location.href,
        'CTA Source': 'Shop Page',
        Username: isLoggedIn ? user?.username : 'Guest',
      });
      if (url) {
        navigate(url, options);
      }
    }
  };

  return (
    <>
      {!onImgError && (
        <div
          className={` 
            overflow-hidden justify-center items-center
            h-full md:mx-[1.25rem] 
            mx-0 md:w-auto w-full
            ${cursorStyle}
          `}
          onClick={isClickable ? handleRedirection : undefined}
        >
          <LazyImage
            imgStyle="w-full h-auto aspect-square md:aspect-14/3 object-contain object-center md:rounded-lg"
            divStyle="h-full"
            img={props.img}
            title={props.name}
            alt={props.name}
            onError={() => setOnImageError(true)}
          />
        </div>
      )}
    </>
  );
};

export default Banner;
