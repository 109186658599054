import { useLazyQuery } from '@apollo/client';
import { COMMUNITY_FOLLOW_QUERIES } from '@fe-monorepo/data-access';
import { useEffect, useState } from 'react';

import { GetFollowInfoParams, GetFollowersInfoResponse, GetFollowingInfoResponse, LookupResponse } from './types';

export const useGetFollowInfo = () => {
  const [isSuccessful, setStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [getFollowersInfoGql, { data: getFollowersResponse }] = useLazyQuery<GetFollowersInfoResponse>(
    COMMUNITY_FOLLOW_QUERIES.getFollowers,
    {
      errorPolicy: 'all',
    },
  );

  const [getFollowingsInfoGql, { data: getFollowingsResponse }] = useLazyQuery<GetFollowingInfoResponse>(
    COMMUNITY_FOLLOW_QUERIES.getFollowings,
    {
      errorPolicy: 'all',
    },
  );

  const [lookupGQL, { data: lookupResponse }] = useLazyQuery<LookupResponse>(COMMUNITY_FOLLOW_QUERIES.lookup, {
    errorPolicy: 'all',
  });

  const getFollowers = async (infoFollowersResult: GetFollowInfoParams) => {
    const { data } = await getFollowersInfoGql({
      variables: {
        details: infoFollowersResult,
      },
    });
    return data?.getFollowers;
  };

  const getFollowings = async (infoFollowingResult: GetFollowInfoParams) => {
    const { data } = await getFollowingsInfoGql({
      variables: {
        details: infoFollowingResult,
      },
    });
    return data?.getFollowings;
  };

  const lookup = async (lookupResult: GetFollowInfoParams) => {
    const { data } = await lookupGQL({
      variables: {
        details: lookupResult,
      },
    });
    return data?.lookup;
  };

  useEffect(() => {
    if (getFollowersResponse) {
      if (getFollowersResponse?.getFollowers?.is_successful) {
        setStatus(getFollowersResponse?.getFollowers?.is_successful);
      }
      if (getFollowersResponse?.getFollowers?.error_msg) {
        setErrorMessage(getFollowersResponse?.getFollowers?.error_msg);
      }
      setStatus(getFollowersResponse?.getFollowers?.is_successful);
    }
    setIsLoading(false);
  }, [getFollowersResponse]);

  useEffect(() => {
    if (getFollowingsResponse) {
      if (getFollowingsResponse?.getFollowings?.is_successful) {
        setStatus(getFollowingsResponse?.getFollowings?.is_successful);
      }
      if (getFollowingsResponse?.getFollowings?.error_msg) {
        setErrorMessage(getFollowingsResponse?.getFollowings?.error_msg);
      }
      setStatus(getFollowingsResponse?.getFollowings?.is_successful);
    }
    setIsLoading(false);
  }, [getFollowingsResponse]);

  useEffect(() => {
    if (lookupResponse) {
      if (lookupResponse?.lookup?.is_successful) {
        setStatus(lookupResponse?.lookup?.is_successful);
      }
      if (lookupResponse?.lookup?.error_msg) {
        setErrorMessage(lookupResponse?.lookup?.error_msg);
      }
      setStatus(lookupResponse?.lookup?.is_successful);
    }
    setIsLoading(false);
  }, [lookupResponse]);

  return {
    getFollowers,
    getFollowings,
    lookup,
    getFollowersData: getFollowersResponse?.getFollowers,
    getFollowingsData: getFollowingsResponse?.getFollowings,
    lookupData: lookupResponse?.lookup,
    isSuccessful,
    isLoading,
    errorMessage,
    getFollowersErrorMessage: getFollowersResponse?.getFollowers?.error_msg,
    getFollowingsErrorMessage: getFollowingsResponse?.getFollowings?.error_msg,
  };
};
