import { IMAGES } from '@fe-monorepo/assets';
import { IconNames } from '@fe-monorepo/helper';
import { RankingModel } from '@fe-monorepo/models';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import PlayerAvatar from '@fe-web/Molecules/PlayerAvatar';

interface PodiumProps {
  ranking?: RankingModel[];
  isMiniGame?: boolean;
}

const Podium = (props: PodiumProps) => {
  const { ranking, isMiniGame = false } = props;

  return (
    <div className="inline-flex flex-row items-end text-center gap-[1.5rem] mx-[1.5rem]">
      {ranking &&
        [...ranking].splice(0, 3).map((item, key) => (
          <div className={`inline-flex flex-col items-center ${key === 0 ? 'order-2' : key === 1 ? 'order-1' : 'order-3'}`}>
            <div className="inline-flex flex-col items-center">
              {!isMiniGame && key === 0 && <ResponsiveIcon name={IconNames.crown} baseHeight={9} baseWidth={14} />}
              <PlayerAvatar
                avatarUrl={item.userAvatar ?? null} //AVATAR INTEGRATION
                avatarSize={3}
                avatarStyle={`${key === 0 ? '!border-amber-300' : key === 1 ? '!border-gray-300' : key === 2 ? '!border-amber-800' : ''}`}
                dir="col"
                hasDisplayName={false}
                hasUserId
                userId={item.userName?.charAt(0) !== '@' ? `@${item.userName}` : item.userName}
                containerStyle="gap-[0.75rem] mb-[1rem] mt-[0.1875rem]"
                userIdStyle="font-regular text-fs-caption text-secondary/70"
                labelContainerStyle="hidden"
              />
              {!isMiniGame && key === 0 && (
                <ResponsiveIcon className="absolute" name={IconNames.spotLight} baseHeight={101} baseWidth={88} />
              )}
            </div>
            <div
              className={`w-[4.875rem] ${
                key === 0 ? 'h-[14.75rem] from-moonlight' : key === 1 ? 'h-[10.5rem] from-purple' : 'h-[7.5rem] from-purple'
              }  bg-gradient-to-b to-primary rounded-tl-lg rounded-tr-lg`}
            >
              <Image
                img={key === 0 ? IMAGES.GoldMedalImage : key === 1 ? IMAGES.SilverMedalImage : IMAGES.BronzeMedalImage}
                imgStyle="w-[3rem] h=[3rem]"
                divStyle="inline-flex justify-center mt-[0.8125rem] mx-[0.9375rem]"
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default Podium;
