import { IMAGE_ALT_LABELS, IconNames, TxKeyPath, convertToTimezone } from '@fe-monorepo/helper';
import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { ChatEventType } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import PopoverAbstract from '@fe-web/Molecules/Popover/PopoverAbstract';
import { useConnectConversationProvider } from '@fe-web/hooks/useConnect';
import { copyImageToClipboard } from 'copy-image-clipboard';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import moment from 'moment';
import { useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { ZIMConversationType } from 'zego-zim-web';

import DeleteMessageModal from './DeleteMessageModal';

interface CustomMessageProps {
  message: string;
  conversationType?: ZIMConversationType;
}

const CustomMessage = ({ message, conversationType }: CustomMessageProps) => {
  const { user } = useUserProfile();
  const { translate } = useTranslate();
  const [type, id] = useMemo(() => {
    return message.split(',');
  }, [message]);
  return (
    <>
      {type === 'DELETED' ? (
        <>
          {id === user?.username ? (
            <div className="border border-gray-200-zinc-800 py-[0.5rem] px-[0.75rem] font-regular text-fs-body-small text-secondary/[0.3] w-[16rem] max-w-full rounded-[0.25rem]">
              {translate('connect.removed_message')}
            </div>
          ) : conversationType === 2 && id === 'admin' ? (
            <div className="border border-gray-200-zinc-800 py-[0.5rem] px-[0.75rem] font-regular text-fs-body-small text-secondary/[0.3] w-[16rem] max-w-full rounded-[0.25rem]">
              {translate('chat.admin_removed_message')}
            </div>
          ) : null}
        </>
      ) : null}
    </>
  );
};

interface MessageBubbleProps {
  message: any;
  index: number;
  isMinimized?: boolean;
  onClickMediaMessageBubble?: (downloadUrl: string) => void;
  isRTL?: boolean;
  messageListLength?: number;
}

function MessageBubble({ message, index, isMinimized, onClickMediaMessageBubble, isRTL, messageListLength }: MessageBubbleProps) {
  const { translate } = useTranslate();
  const username = useSelector((state: RootState) => state.user.userContext.username);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>();
  const { messageList, avatarUrl, groupMembersList, conversationType, userAdmin, firstMessagesOnDate } = useConnectConversationProvider();
  const messageRef = useRef<HTMLDivElement>(null);
  const mediaMessageOptionRef = useRef<any>(null);
  const centeredChatEvents = [
    ChatEventType.DISMISS_GROUP,
    ChatEventType.KICKED_OUT,
    ChatEventType.LEFT_AS_ADMIN,
    ChatEventType.LEFT_GROUP,
    ChatEventType.NEW_JOINED,
    ChatEventType.SET_ADMIN,
  ];
  const showUserName = useMemo(() => {
    if (index !== 0 && messageList) {
      const previousMessage = messageList[index - 1];
      if (
        previousMessage.senderUserID === messageList[index].senderUserID &&
        !(previousMessage?.extendedData ?? '').includes('connect.event_message.joined_the_chat')
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [messageList, index]);

  const showAvatar = useMemo(() => {
    const nextIndex = index + 1;
    const len = messageList?.length || 0;
    if (messageList && nextIndex < len) {
      const previousMessage = messageList[nextIndex];
      if (previousMessage.senderUserID === messageList[index].senderUserID) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }, [messageList, index]);

  const tryParseJSONObject = (jsonString: string) => {
    try {
      const o = JSON.parse(jsonString);
      if (o && typeof o === 'object') {
        return true;
      }
    } catch (e) {}
    return false;
  };

  const extendedDataObj = tryParseJSONObject(message.extendedData);
  const extendedData = extendedDataObj ? JSON.parse(message.extendedData) : {};

  const dateCompute = (value: number, isDate: boolean) => {
    const date_from = convertToTimezone(moment(value).toString());
    let date;
    if (isDate) {
      date = date_from.format('DD MMM YYYY');
    } else {
      date = date_from.format('h:mm a');
    }
    return date;
  };

  const isToday = (value: number) => {
    const date_now = convertToTimezone(moment().toString());
    const date_from = convertToTimezone(moment(value).toString());
    const isToday = date_now.isSame(date_from, 'd');
    return isToday;
  };

  const readReceiptCount = () => {
    const data =
      messageList && messageList?.slice(messageList.length - 2, messageList.length).filter(item => item.senderUserID === username).length;
    return data === 1 ? 2 : 1;
  };

  const checkIfUnreadIsExisting = (index: number) => {
    if (index > 0) {
      return (
        messageList && messageList?.slice(0, index).filter(item => item.receiptStatus === 1 && item.senderUserID !== username).length <= 0
      );
    } else {
      return false;
    }
  };

  const renderMessage = (message: any) => {
    switch (extendedData.action) {
      case ChatEventType.NEW_JOINED:
        if (!extendedData.message || !extendedData.data) return message.message;
        return translate(extendedData.message, { users: extendedData.data });
    }
    if (extendedData && extendedData.action !== ChatEventType.SET_ADMIN) return message.message;
    const fullWord = message.message;
    const setAdminEn = 'is now assigned as an admin';
    const setAdminAr = 'أصبح الآن مشرفاً';
    const keyWord = fullWord.toLowerCase().includes(setAdminEn.toLowerCase()) ? setAdminEn : setAdminAr;
    const userName = fullWord.substring(0, fullWord.toLowerCase().indexOf(keyWord));
    const translatedMessage = `${userName}${isRTL ? setAdminAr : setAdminEn}`;
    return translatedMessage;
  };

  const matchingUser = groupMembersList?.find(user => user.userID === message.senderUserID);

  const renderLineIndicator = () => {
    return (
      <>
        {!!firstMessagesOnDate?.find(m => m.messageID === message.messageID) && (
          <div className="w-full flex flex-row relative pb-[1rem]">
            <p
              className={`absolute top-[.5rem] border-t-[.1rem] h-full border-neutral-300-zinc-700 dark:border-gray20
                ${
                  isMinimized
                    ? isToday(message.timestamp)
                      ? 'w-[40%]'
                      : 'w-[30%]'
                    : isToday(message.timestamp)
                    ? 'w-[45%] HD:w-[47%]'
                    : 'w-[40%] HD:w-[45%]'
                }`}
            ></p>
            <p className="absolute left-0 right-0 text-center whitespace-nowrap px-[.25rem] font-regular text-fs-caption text-otherGray">
              {isToday(message.timestamp) ? translate('connect.today') : dateCompute(message.timestamp, true)}
            </p>
            <p
              className={`absolute top-[.5rem]
                border-t-[.1rem]
                h-full border-neutral-300-zinc-700
                dark:border-gray20
                ${
                  isMinimized
                    ? isToday(message.timestamp)
                      ? 'w-[40%]'
                      : 'w-[30%]'
                    : isToday(message.timestamp)
                    ? 'w-[45%] HD:w-[47%]'
                    : 'w-[40%] HD:w-[45%]'
                }
                ${isRTL ? 'left-0' : 'right-0'}`}
            ></p>
          </div>
        )}
        {/*LINE UNREAD*/}
        {checkIfUnreadIsExisting(index) &&
          message?.senderUserID !== username &&
          (extendedData?.action ? !Object.values(ChatEventType).includes(extendedData?.action) : true) &&
          message.receiptStatus === 1 && (
            <div className="w-full flex flex-row relative pb-[1rem]">
              <p
                className={`absolute top-[.5rem] border-t-[.05rem] h-full border-moonlight
                  ${isMinimized ? 'w-[30%]' : 'w-[40%] HD:w-[45%]'}`}
              ></p>
              <p className="absolute left-0 right-0 text-center whitespace-nowrap px-[.25rem] font-regular text-fs-caption text-moonlight">
                {isToday(message.timestamp) ? translate('connect.unread') : dateCompute(message.timestamp, true)}
              </p>
              <p
                className={`absolute top-[.5rem] border-t-[.05rem] h-full border-moonlight
                  ${isMinimized ? 'w-[30%]' : 'w-[40%] HD:w-[45%]'}
                  ${isRTL ? 'left-0' : 'right-0'}`}
              ></p>
            </div>
          )}
      </>
    );
  };

  const renderAvatar = () => {
    return (
      message &&
      message.senderUserID !== username &&
      !(extendedData?.action && Object.values(ChatEventType).includes(extendedData?.action)) &&
      !showAvatar && (
        <div
          className={`flex items-center justify-center object-cover bg-lightBlue/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden min-w-[2rem] min-h-[2rem]`}
        >
          {conversationType === 2 ? (
            matchingUser && matchingUser?.memberAvatarUrl ? (
              <Image
                alt={translate(IMAGE_ALT_LABELS.chat_user_avatar as TxKeyPath) ?? ''}
                img={matchingUser?.memberAvatarUrl}
                divStyle="bg-black/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden w-[2rem] h-[2rem]"
                imgStyle="w-[2rem] h-[2rem] object-cover"
              />
            ) : (
              <ResponsiveIcon
                name={IconNames.robot}
                baseWidth={25}
                baseHeight={25}
                iconClasses={`fill-lightBlue`}
                className="flex items-center justify-center w-[2rem] h-[2rem] object-cover"
              />
            )
          ) : (
            avatarUrl && (
              <Image
                alt={translate(IMAGE_ALT_LABELS.chat_user_avatar as TxKeyPath) ?? ''}
                img={avatarUrl}
                divStyle={'bg-black/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden w-[2rem] h-[2rem]'}
                imgStyle="w-[2rem] h-[2rem] object-cover"
              />
            )
          )}
        </div>
      )
    );
  };

  const renderCaption = () => {
    return messageList && !(extendedData?.action && Object.values(ChatEventType).includes(extendedData?.action)) ? (
      <p
        className={`flex flex-row items-center font-regular text-fs-caption text-neutral-400-zinc-500 gap-[0.25rem] ${
          message?.senderUserID === username ? 'justify-end' : 'justify-start pl-[2.5rem]'
        }`}
      >
        {message?.senderUserID === username &&
          message.receiptStatus === 2 &&
          _.last(messageList.filter(message => message.receiptStatus === 2))?.messageID === message.messageID && (
            <span>{translate('connect.chat_read')}</span>
          )}
        {!showAvatar && messageList.length - readReceiptCount() <= index && (
          <span>
            {convertToTimezone(moment(message?.timestamp).toString()).format('h:mm')}{' '}
            {translate(moment(message?.timestamp).format('a') === 'am' ? 'tournament_time_am' : 'tournament_time_pm')}
          </span>
        )}
        {message?.senderUserID === username &&
          message.sentStatus === 1 &&
          _.last(messageList.filter(message => message.sentStatus === 1))?.messageID === message.messageID && (
            <ResponsiveIcon name={IconNames.sentCheck} baseWidth={11} baseHeight={11} iconClasses="fill-neutral-400-zinc-500" />
          )}
      </p>
    ) : null;
  };

  return (
    <div className={`${!showAvatar ? 'pb-[.75rem]' : 'pb-[0.25rem]'}`}>
      {message.type !== 31 && (
        <div
          className={`flex flex-col w-full gap-[0.25rem] ${
            extendedData?.action && centeredChatEvents.includes(extendedData?.action)
              ? 'items-center'
              : message?.senderUserID === username
              ? 'items-end'
              : ''
          }`}
          ref={messageRef}
        >
          {renderLineIndicator()}
          <div
            className={`relative flex items-center ${
              extendedData?.action && centeredChatEvents.includes(extendedData?.action)
                ? 'justify-center'
                : message?.senderUserID === username
                ? 'group justify-end max-w-[70%] flex-row-reverse gap-[0.5rem]'
                : 'group justify-start max-w-[70%] gap-[0.5rem]'
            }`}
          >
            <div className={`flex flex-col ${!showAvatar && 'gap-[.5rem]'}`}>
              <div
                className={`${
                  messageList && messageList.length - readReceiptCount() <= index ? 'items-center' : 'items-end'
                } flex flex-row gap-[.5rem]`}
              >
                {renderAvatar()}
                {message.type === 11 ? (
                  <div className="flex flex-col gap-[.25rem]">
                    <div className={`flex gap-[.25rem] ${showAvatar && 'ml-[2.5rem]'}`}>
                      <button
                        key={index}
                        onClick={() => {
                          onClickMediaMessageBubble?.(message.fileDownloadUrl);
                        }}
                        onContextMenu={e => {
                          e.preventDefault();
                          mediaMessageOptionRef.current?.click();
                        }}
                      >
                        <Image
                          img={message?.fileDownloadUrl}
                          imgStyle="object-cover h-full w-full"
                          divStyle="rounded-[0.25rem] overflow-hidden h-[5.3125rem] w-[5.3125rem]"
                        />
                      </button>
                    </div>
                    <PopoverAbstract buttonRef={mediaMessageOptionRef} containerClassName="absolute h-0 w-0 opacity-0 pointer-events-none">
                      <div className="flex flex-col items-start gap-[0.25rem] py-[0.5rem] w-[14rem] max-w-full text-secondary font-regular text-fs-body fill-secondary flex-start">
                        <button
                          className="flex w-full gap-[0.5rem] py-[0.5rem] px-[1rem] hover:text-sunset hover:bg-sunset/[0.2] hover:fill-sunset"
                          onClick={async () => {
                            try {
                              await copyImageToClipboard(message?.fileDownloadUrl);
                            } catch (err) {
                              await navigator.clipboard.writeText(message?.fileDownloadUrl);
                              console.error(err);
                            }
                          }}
                        >
                          <ResponsiveIcon name={IconNames.iconcopy} baseWidth={20} baseHeight={20} />
                          <span>{translate('action_copy')}</span>
                        </button>
                        <button
                          className="flex w-full gap-[0.5rem] py-[0.5rem] px-[1rem] hover:text-sunset hover:bg-sunset/[0.2] hover:fill-sunset"
                          onClick={() => {
                            try {
                              saveAs(message?.fileDownloadUrl);
                            } catch (err) {
                              console.error(err);
                            }
                          }}
                        >
                          <ResponsiveIcon name={IconNames.download} baseWidth={20} baseHeight={20} />
                          <span>{translate('action_download')}</span>
                        </button>
                        <button
                          className="flex w-full gap-[0.5rem] py-[0.5rem] px-[1rem] hover:text-sunset hover:bg-sunset/[0.2] text-sunset"
                          onClick={() => setIsDeleteModalOpen(true)}
                        >
                          <ResponsiveIcon name={IconNames.deleteOutline} iconClasses="fill-sunset" baseWidth={20} baseHeight={20} />
                          <span>{translate('action_delete')}</span>
                        </button>
                      </div>
                    </PopoverAbstract>
                  </div>
                ) : message.type === 1 ? (
                  <div className="flex gap-[.25rem] flex-col">
                    {!(message && message.senderUserID === username) &&
                      !showUserName &&
                      !(extendedData?.action && Object.values(ChatEventType).includes(extendedData?.action)) && (
                        <span className={`font-regular text-fs-caption text-neutral-400-zinc-500 ${showAvatar && 'ml-[2.5rem]'}`}>
                          {message?.senderUserID}
                        </span>
                      )}
                    <div className={`flex ${message.senderUserID === username ? 'flex-row-reverse' : 'flex-row'} gap-[.25rem]`}>
                      <p
                        key={index}
                        className={`${
                          extendedData?.action && Object.values(ChatEventType).includes(extendedData?.action)
                            ? 'self-center text-center font-regular text-fs-caption text-zinc-600-400 !w-full break-phrase px-0'
                            : message?.senderUserID === username
                            ? 'self-end font-regular text-fs-body-small bg-purple text-white py-[0.5rem] px-[0.75rem]'
                            : 'self-start font-regular bg-zinc-100-neutral-800 text-fs-body-small text-secondary py-[0.5rem] px-[0.75rem]'
                        } rounded-[.25rem] py-[.5rem] [word-break:break-word] ${
                          showAvatar && message.senderUserID !== username && 'ml-[2.5rem]'
                        }`}
                      >
                        {renderMessage(message)}
                      </p>
                      {/* DELETE MESSAGE */}
                      {(message?.senderUserID === username ||
                        (message?.senderUserID != username && userAdmin === 1 && conversationType === 2)) &&
                        (extendedData?.action ? !Object.values(ChatEventType).includes(extendedData?.action) : true) && (
                          <div className="flex grid content-center">
                            <button
                              className="relative opacity-0 pointer-events-none group-hover:opacity-100 group-hover:pointer-events-auto hover:opacity-100 hover:pointer-events-auto transition-all duration-300"
                              onClick={() => setIsDeleteModalOpen(true)}
                            >
                              <ResponsiveIcon name={IconNames.deleteOutline} iconClasses="fill-sunset" baseWidth={20} baseHeight={20} />
                            </button>
                          </div>
                        )}
                    </div>
                    {/*READ CAPTION */}
                  </div>
                ) : message.type === 2 ? (
                  <CustomMessage conversationType={conversationType} message={message?.message as string} />
                ) : null}
              </div>
              {renderCaption()}
            </div>
          </div>
          <DeleteMessageModal
            isOpen={isDeleteModalOpen}
            onClose={() => {
              setIsDeleteModalOpen(false);
            }}
            message={message}
          />
        </div>
      )}
    </div>
  );
}

export default MessageBubble;
