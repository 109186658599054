import { CaptionedImage, Card, ImageOnly, logContentCardClick, logContentCardImpressions } from '@braze/web-sdk';
import { MixPanelEvents } from '@fe-monorepo/helper';
import { useUserProfile } from '@fe-monorepo/hooks';
import { BrazeContentCardStaticBanner } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import LazyImage from '@fe-web/Atoms/Media/LazyImage';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { getURLForRedirection } from 'apps/fe-web/src/lib/helper';
import { useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface BrazeStaticBannerProps {
  card: Card | ImageOnly;
  ctaSource: string;
}

const BrazeStaticBanner = (props: BrazeStaticBannerProps) => {
  const { card, ctaSource } = props;

  const navigate = useNavigate();

  const isMobile = useMobileDetect();
  const { isLoggedIn, user } = useUserProfile();

  const bannerRef = useRef<HTMLDivElement | null>(null);
  const observerRef = useRef<IntersectionObserver | null>(null);
  const hasLoggedImpressionRef = useRef<boolean>(false);

  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  const language = useSelector((state: RootState) => state.app.language);

  const { extras, url } = card as CaptionedImage;

  const {
    name_ar,
    name_en,
    web_media_url_ar,
    web_media_url_en,
    mobile_media_url_ar,
    mobile_media_url_en,
    reference_type,
    reference_value,
    is_external,
  } = extras as unknown as BrazeContentCardStaticBanner;

  const isClickable: boolean = Boolean(url) || (reference_type !== 'none' && reference_type != null);
  const cursorStyle = isClickable ? 'cursor-pointer' : 'cursor-default';

  const bannerName = language === 'ar' ? name_ar : name_en;
  const webImageUrl = language === 'ar' ? web_media_url_ar : web_media_url_en;
  const mobileImageUrl = language === 'ar' ? mobile_media_url_ar : mobile_media_url_en;

  const handleBannerClick = useCallback(() => {
    const referenceType = reference_type ?? '';
    const referenceValue = reference_value ?? '';
    const sourceCode = 'm-';
    const bannerName = isRTL ? name_ar : name_en;
    const isExternal = is_external === 'true';

    const { url: redirectUrl, options } = getURLForRedirection(referenceType, referenceValue, isLoggedIn, user, sourceCode, bannerName);

    mixpanelHelper.trackEvent(MixPanelEvents.bannersButtonCTA, {
      'CTA Name': `Secondary Banner - ${name_en}`,
      Referrer: window.location.href,
      'CTA Source': ctaSource,
      Username: isLoggedIn ? user?.username : 'Guest',
    });

    logContentCardClick(card);

    if (url) {
      if (isExternal) {
        window.open(url, '_blank');
      } else {
        navigate(url);
      }

      return;
    }

    if (redirectUrl) {
      navigate(redirectUrl, options);
    }
  }, [card]);

  const handleLogImpression = useCallback(() => {
    logContentCardImpressions([card]);
  }, [card]);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      entries.forEach(entry => {
        if (entry.isIntersecting && !hasLoggedImpressionRef.current) {
          handleLogImpression();

          hasLoggedImpressionRef.current = true;

          if (observerRef.current) {
            observerRef.current.unobserve(entry.target);
          }
        }
      });
    },
    [handleLogImpression],
  );

  useEffect(() => {
    if (!observerRef.current) {
      observerRef.current = new IntersectionObserver(handleIntersection, {
        threshold: 1,
      });
    }

    const currentBannerElement = bannerRef.current;
    if (currentBannerElement) {
      observerRef.current.observe(currentBannerElement);
    }

    return () => {
      if (observerRef.current && currentBannerElement) {
        observerRef.current.unobserve(currentBannerElement);
      }
    };
  }, []);

  return (
    <div ref={bannerRef}>
      <LazyImage
        onClick={isClickable ? handleBannerClick : undefined}
        img={isMobile ? mobileImageUrl : webImageUrl}
        imgStyle="w-full h-auto aspect-square md:aspect-14/3 object-contain object-center rounded-lg"
        divStyle={`h-full ${cursorStyle}`}
        alt={bannerName}
      />
    </div>
  );
};

export default BrazeStaticBanner;
