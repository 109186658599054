interface Props {
  className?: string;
  content: React.ReactNode;
  containerClassname?: string;
  children?: React.ReactNode;
}

const ToolTip = (props: Props) => {
  return (
    <div className={`absolute ${props?.containerClassname ?? ''} z-[40] cursor-pointer bg-blue`}>
      <div className={`absolute ${props?.className} z-[40]`}>{props.content}</div>
    </div>
  );
};

export default ToolTip;
