function CopyIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.5 14.9998C7.04167 14.9998 6.64931 14.8366 6.32292 14.5103C5.99653 14.1839 5.83333 13.7915 5.83333 13.3332V3.33317C5.83333 2.87484 5.99653 2.48248 6.32292 2.15609C6.64931 1.8297 7.04167 1.6665 7.5 1.6665H15C15.4583 1.6665 15.8507 1.8297 16.1771 2.15609C16.5035 2.48248 16.6667 2.87484 16.6667 3.33317V13.3332C16.6667 13.7915 16.5035 14.1839 16.1771 14.5103C15.8507 14.8366 15.4583 14.9998 15 14.9998H7.5ZM7.5 13.3332H15V3.33317H7.5V13.3332ZM4.16667 18.3332C3.70833 18.3332 3.31597 18.17 2.98958 17.8436C2.66319 17.5172 2.5 17.1248 2.5 16.6665V4.99984H4.16667V16.6665H13.3333V18.3332H4.16667Z"
        fill="var(--secondary-black)"
      />
    </svg>
  );
}

export default CopyIcon;
