import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import Footer from '@fe-web/Organisms/ShopFooter/Footer';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../app.routes.enum';
import ErrorPageLayout from './ErrorPageLayout';

interface Props {
  hasHeader?: boolean;
  hasFooter?: boolean;
}

export function NotFoundPage({ hasHeader, hasFooter }: Props) {
  const { translate } = useTranslate();
  const refreshPage = () => {
    window.location.reload();
  };
  const navigate = useNavigate();

  const goToHomePage = () => {
    navigate(AppRoutes.home);
  };

  return (
    <ErrorPageLayout
      hasHeader={hasHeader}
      hasFooter={hasFooter}
      list={[{ label: translate('home') as string }, { label: '404' }]}
      footer={<Footer />}
    >
      <div className="flex-1 flex flex-col gap-[3rem] items-center text-center justify-center">
        <ResponsiveIcon
          name={IconNames.notFoundLight}
          baseWidth={412}
          baseHeight={260}
          className="dark:hidden"
          iconClasses="max-w-screen max-h-screen w-[18.625rem] h-[11.75rem] md:w-[25.75rem] md:h-[16.25rem]"
        />
        <ResponsiveIcon
          name={IconNames.notFoundDark}
          baseWidth={412}
          baseHeight={260}
          className=" hidden dark:block"
          iconClasses="max-w-screen max-h-screen w-[18.625rem] h-[11.75rem] md:w-[25.75rem] md:h-[16.25rem]"
        />
        <div className="flex flex-col gap-[2.5rem]">
          <div className="flex flex-col gap-[0.25rem] items-center">
            <p className="font-medium text-fs-title text-black-white">{translate('error_404')}</p>
            <p className="text-zinc-600-400 max-w-[15rem] font-regular text-fs-body-small">{translate('error_404_description')}</p>
          </div>
          <div className="flex flex-col gap-[1rem] md:gap-[2.5rem]">
            <Button
              text={translate('action_back_to_home')}
              style={`flex-1 w-full sm:w-full font-medium text-fs-body px-24 py-[0.5rem] font-medium gap-[8px] text-white100 border-[1px] border-transparent rounded-sm hover:bg-white100 bg-sunset hover:-border[1px] hover:border-sunset hover:text-sunsetText rounded-sm disabled:bg-gray-200-zinc-800 disabled:dark:bg-gray-200-zinc-800 disabled:before:bg-gray-200-zinc-800 disabled:before:dark:bg-gray-200-zinc-800 disabled:after:bg-gray-200-zinc-800 disabled:after:dark:bg-gray-200-zinc-800 disabled:text-zinc-600-400 disabled:border-gray-200-zinc-800`}
              onClick={goToHomePage}
            />
            <HoverText
              className={`font-regular text-sunset text-fs-body whitespace-nowrap flex m-auto`}
              hover={{ color: 'text-sunset' }}
              alwaysShowUnderline={false}
              underlineStyle="bg-sunset h-[0.0625rem] bottom-2"
              text={translate('action_refresh') ?? 'Refresh'}
              onClick={refreshPage}
            />
          </div>
        </div>
      </div>
    </ErrorPageLayout>
  );
}

export default NotFoundPage;
