import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RecentlyViewedModel } from '@fe-monorepo/models';

const RECENTLY_VIEWED_LIMIT = 10

export const recentlyViewedSlice = createSlice({
    name: 'recentlyViewed',
    initialState: {
        recentlyViewed: [] as RecentlyViewedModel[],
    },
    reducers: {
        insertRecentlyViewed: (state, action: PayloadAction<RecentlyViewedModel>) => {
            if(state.recentlyViewed?.length > RECENTLY_VIEWED_LIMIT){
                state.recentlyViewed.pop()
            }
            state.recentlyViewed = [ ...state.recentlyViewed, action.payload];
        },
        updateRecentlyViewed: (state, action: PayloadAction<RecentlyViewedModel>) => {
            state.recentlyViewed = [...state.recentlyViewed.filter(recentProduct => recentProduct.product_code != action.payload.product_code), action.payload];
        },
        removeAllRecentlyViewed: (state) => {
            state.recentlyViewed = [];
        },
        removeExpiredRecentlyViewed: (state, action: PayloadAction<RecentlyViewedModel[]>) => {
            state.recentlyViewed = action.payload;
        },
    },
});

export const { insertRecentlyViewed, updateRecentlyViewed, removeAllRecentlyViewed, removeExpiredRecentlyViewed } = recentlyViewedSlice.actions;
