import { MixPanelCustomEvents, convertToTimezone } from '@fe-monorepo/helper';
import { GetChatInfoDataModel, useAccount, useChatSearch, useGetFollowInfo, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { ChatEventType, EventType, NotificationMessageSendInput } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import { getEnvironment } from '@fe-web/constant/environment';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { t } from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  ZIMConversationType,
  ZIMGroupAdvancedConfig,
  ZIMGroupCreatedResult,
  ZIMGroupInfo,
  ZIMGroupMemberInfo,
  ZIMGroupOwnerTransferredResult,
  ZIMMediaMessageBase,
  ZIMMediaMessageSentResult,
  ZIMMessage,
  ZIMMessageBase,
  ZIMMessageDeletedResult,
  ZIMMessageReceiptsReadSentResult,
  ZIMMessageRevokedResult,
  ZIMMessageSendConfig,
  ZIMMessageSendNotification,
  ZIMMessageSentResult,
  ZIMPushConfig,
} from 'zego-zim-web';

import { useNotificationProvider } from '../useNotification';
import { useConnectProvider } from './useConnectProvider';

interface UseConnectConversationProvider {
  suggestions?: GetChatInfoDataModel[];
  filteredUsers?: GetChatInfoDataModel[];
  selectedUsers?: GetChatInfoDataModel[];
  messageList?: ZIMMessage[];
  conversationID?: string;
  conversationType?: number;
  displayName?: string;
  avatarUrl?: string;
  userAdmin?: number;
  isSearching?: boolean;
  lastReadMessage?: ZIMMessage;
  lastSentMessage?: ZIMMessage;
  isBlocking?: boolean;
  isBlocked?: boolean;
  groupMembersList?: ZIMGroupMemberInfo[] | undefined;
  groupInfo?: ZIMGroupInfo;
  groupMemberCount?: number;
  isMessageListLoading?: boolean;
  filteredMessageList?: ZIMMessage[];
  isProfileLoading?: boolean;
  firstMessagesOnDate?: ZIMMessage[];
  isLoadingGetFollowings?: boolean;
  setGroupOwner?: (userID: string, displayName: string) => Promise<ZIMGroupOwnerTransferredResult | undefined>;
  setGroupMembersList?: Dispatch<SetStateAction<ZIMGroupMemberInfo[]>>;
  search?: (query: string | undefined) => void;
  setMessageList?: Dispatch<SetStateAction<ZIMMessage[]>>;
  setSelectedUsers?: Dispatch<SetStateAction<GetChatInfoDataModel[]>>;
  setIsBlocking?: Dispatch<SetStateAction<boolean>>;
  isDismissed?: boolean;
  onRemoveSelectedUser?: (username: string | undefined) => void;
  onSendMessage?: (
    message: ZIMMessageBase,
    notification?: ZIMMessageSendNotification | undefined,
  ) => Promise<ZIMMessageSentResult | undefined>;
  createGroup?: (groupInfo: ZIMGroupInfo, userIDs: string[], config?: ZIMGroupAdvancedConfig) => Promise<ZIMGroupCreatedResult | undefined>;
  onSendMediaMessage?: (message: ZIMMediaMessageBase) => Promise<ZIMMediaMessageSentResult | undefined>;
  onSendMessageReceipt?: (messageList: ZIMMessage[]) => Promise<ZIMMessageReceiptsReadSentResult | undefined>;
  onDeleteMessages?: (messageList: ZIMMessage[]) => Promise<ZIMMessageDeletedResult | undefined>;
  onRevokeMessage?: (message: ZIMMessage) => Promise<ZIMMessageRevokedResult | undefined>;
  getMemberAvatar?: (userID: string) => string | undefined;
}

const ConnectConversationContext = createContext<UseConnectConversationProvider>({});

interface ConnectConversationProviderProps {
  children: ReactNode;
  conversationID?: string;
  conversationType?: ZIMConversationType;
  shouldCallGetFollowings?: boolean;
}

export const ConnectConversationProvider = ({
  children,
  conversationID,
  conversationType,
  shouldCallGetFollowings = true,
}: ConnectConversationProviderProps) => {
  const { translate } = useTranslate();
  const [messageList, setMessageList] = useState<ZIMMessage[]>([]);
  const [filteredUsers, setFilteredUsers] = useState<GetChatInfoDataModel[]>();
  const [selectedUsers, setSelectedUsers] = useState<GetChatInfoDataModel[]>([]);
  const { getSearch, getSearchResponse } = useChatSearch();
  const {
    queryHistoryMessage,
    sendMessage,
    sendMediaMessage,
    queryConversation,
    queryGroupMemberInfo,
    receivedPeerMessages,
    receivedGroupMessages,
    messageReceiptChangedList,
    messageRevokedList,
    clearChatRes,
    groupNameRes,
    inviteUsersRes,
    setInviteUsersRes,
    setReceivedPeerMessages,
    setReceivedGroupMessages,
    sendMessageReceipt,
    setMessageReceiptChangedList,
    deleteMessages,
    setMessageRevokedList,
    revokeMessage,
    queryGroupMemberList,
    queryGroupInfo,
    queryGroupMemberCount,
    transferGroupOwner,
    setClearChatRes,
    setGroupNameRes,
    groupMemberStateChangedList,
    setGroupMemberStateChangedList,
    groupAvatarChangeID,
    groupAvatarChange,
    groupMemberInfoUpdatedList,
    setGroupMemberInfoUpdatedList,
    queryMembersRes,
    setQueryMembersRes,
    blockTrigger,
    isConversationListLoaded,
  } = useConnectProvider();
  const [suggested, setSuggested] = useState<GetChatInfoDataModel[]>([]);
  const isRTL = useSelector((state: RootState) => state.app.isRTL);
  const { user } = useUserProfile();
  const { zegoCloudConfig } = getEnvironment();
  const { getUserInfoByUsername, userProfileInfo } = useAccount();
  const { getFollowingsData, getFollowings, isLoadingGetFollowings } = useGetFollowInfo();
  const [displayName, setDisplayName] = useState<string>();
  const [avatarUrl, setAvatarUrl] = useState<string>();
  const [userAdmin, setUserAdmin] = useState<number>();
  const [groupMembersList, setGroupMembersList] = useState<ZIMGroupMemberInfo[]>([]);
  const [groupInfo, setGroupInfo] = useState<ZIMGroupInfo>();
  const [groupMemberCount, setGroupMemberCount] = useState<number>();
  const [isSearching, setIsSearching] = useState<boolean>();
  const lastReadMessage = useMemo(() => {
    return _.findLast(messageList, message => message.senderUserID === user?.username && message.receiptStatus === 2);
  }, [messageList]);
  const lastSentMessage = useMemo(() => {
    return _.findLast(messageList, message => message.senderUserID === user?.username && message.sentStatus === 1);
  }, [messageList]);
  const [isBlocking, setIsBlocking] = useState<boolean>(false);
  const [isBlocked, setIsBlocked] = useState<boolean>();
  const [isDismissed, setIsDismissed] = useState<boolean>();
  const { sendNotification } = useNotificationProvider();

  const { GROUP_MEMBER_COUNT } = zegoCloudConfig;
  const suggestedData: GetChatInfoDataModel[] = [];
  const [isMessageListLoading, setIsMessageListLoading] = useState<boolean>(false);
  const [isProfileLoading, setIsProfileLoading] = useState<boolean>(false);

  const isBlockedOrBlocking = useCallback(async () => {
    if (conversationID && conversationType === 0) {
      const userInfo = await getUserInfoByUsername({ username: conversationID });
      const isBlocked = userInfo.data?.getInfoByUsername.data.is_blocked;
      const isBlocking = !!userInfo.data?.getInfoByUsername.data.is_blocking;
      setIsBlocked(isBlocked);
      setIsBlocking(isBlocking);
      return isBlocked || isBlocking;
    }
  }, [conversationID, conversationType]);

  const filteredMessageList = useMemo(() => {
    let newMessageList = messageList;
    if (newMessageList?.length) {
      newMessageList = newMessageList.map((message: any) => {
        if (
          message.type === 31 &&
          ((message?.operatedUserID === user?.username && userAdmin === 1) ||
            groupMembersList
              .filter(member => member.memberRole === 1)
              .map(member => member.userID)
              .includes(message?.operatedUserID))
        ) {
          return { ...message, type: 2, message: `DELETED,admin` };
        }
        return message;
      });
      newMessageList = newMessageList.filter(message => message.type != 31);
    }
    return newMessageList || [];
  }, [messageList, user?.username, userAdmin]);

  const firstMessagesOnDate = useMemo(() => {
    const messages: ZIMMessage[] = [];

    if (!filteredMessageList) return messages;
    filteredMessageList.forEach((message, index) => {
      if (index > 0) {
        const prevMessage = filteredMessageList[index - 1];

        const prevDate = convertToTimezone(moment(prevMessage.timestamp).toString());
        const currDate = convertToTimezone(moment(message.timestamp).toString());
        const isSame = prevDate.isSame(currDate, 'd');
        if (!isSame) {
          messages.push(message);
        }
      } else {
        messages.push(message);
      }
    });
    return messages;
  }, [filteredMessageList]);

  useEffect(() => {
    if (shouldCallGetFollowings) {
      getFollowings({ username: user?.username as string });
    }
  }, []);

  useEffect(() => {
    const loadProfileInfo = async () => {
      if (conversationID === 'newChat') return;
      setIsProfileLoading(true);
      try {
        if (conversationType === 0) {
          const response = await getUserInfoByUsername({ username: conversationID });
          const data = response.data?.getInfoByUsername.data;

          setDisplayName(data?.display_name);
          setAvatarUrl(data?.avatar_url);
        }
      } catch {}
      setIsProfileLoading(false);
    };
    loadProfileInfo();
  }, [conversationID, conversationType]);

  useEffect(() => {
    if (getFollowingsData?.data) {
      const getFollowersFiltered = getFollowingsData?.data.filter(users => users.viewer_has_blocked_user !== '1');
      getFollowersFiltered?.map(user => {
        return suggestedData?.push({
          username: user.username,
          last_cursor: user.last_cursor,
          avatar_url: user.avatar_url,
          display_name: user.display_name,
        });
      });
    }
    setSuggested(suggestedData);
  }, [getFollowingsData]);

  const suggestions = useMemo(() => {
    const selectedUsernames = selectedUsers.map(selectedUser => selectedUser.username);
    return suggested.filter(user => !selectedUsernames.includes(user.username));
  }, [selectedUsers, suggested]);

  const search = async (query: string | undefined) => {
    setFilteredUsers([]);
    setIsSearching(true);
    try {
      if (query) {
        const tempQuery = query.toLowerCase();
        await getSearch({ identifier: tempQuery });
      }
    } catch (err) {
      console.error(err);
    }
    setIsSearching(false);
  };

  const onSendMessage = async (message: ZIMMessageBase) => {
    try {
      if (!conversationID || typeof conversationType === 'undefined' || !sendMessage) return;
      let pushTitle = '';
      let pushContent = '';
      //Set push title and content for group chat or private chat
      if (conversationType === 2 && !message.extendedData) {
        //Group chat
        pushTitle =
          translate('chatOfflinePushNotification.groupTitle', {
            displayName: user?.display_name,
            groupName: groupInfo?.groupName,
          }) ?? '';
        pushContent = `${user?.display_name}: ${message.message}`;
      } else if (conversationType === 0) {
        //Private chat
        pushTitle = translate('chatOfflinePushNotification.privateTitle', { displayName: user?.display_name }) || '';
        pushContent = `${message.message}`;
      }

      const pushConfig: ZIMPushConfig = {
        title: pushTitle,
        content: pushContent,
      };
      const messageConfig: ZIMMessageSendConfig = {
        priority: 1,
        hasReceipt: true,
        pushConfig: pushConfig,
      };

      // Return if the user is blocking or blocked
      if (await isBlockedOrBlocking()) return;
      mixpanelHelper.trackEvent(MixPanelCustomEvents.NewMessageEvent, {
        'Chat ID': conversationID,
        'Chat Type': conversationType === 2 ? 'Group Chat' : 'Private',
        Username: user?.username ?? 'GUEST',
      });
      const response = await sendMessage(message, conversationID, conversationType, messageConfig);

      if (response?.message) {
        let recipients: any = [];
        let avatarURL = '';
        let body = '';
        if (conversationType === 2 && !message.extendedData) {
          recipients = groupMembersList.map(member => member.userID);
          const lastOtherSender = _.findLast(messageList, sender => sender.senderUserID !== user?.username);
          if (avatarUrl && avatarUrl?.length > 0) {
            avatarURL = avatarUrl;
          } else if (lastOtherSender?.senderUserID) {
            const otherSenderAvatar: string =
              groupMembersList.find(member => member.userID === lastOtherSender?.senderUserID)?.memberAvatarUrl || '';
            avatarURL = `${user?.avatar_url || ''},${otherSenderAvatar}`;
          }
          body = isRTL
            ? `${response.message.message.toString()} :${user?.username}` || ''
            : `${user?.username}: ${response.message.message.toString()}` || '';
        } else {
          recipients = [conversationID];
          avatarURL = user?.avatar_url || '';
          body = isRTL
            ? `${t('chatMobilePushNotification.privateTitle', {
                message: response.message.message.toString(),
                lng: 'ar',
              })}`
            : `${t('chatMobilePushNotification.privateTitle', {
                message: response.message.message.toString(),
                lng: 'en',
              })}`;
        }

        const messageNotificationInput: NotificationMessageSendInput = {
          event_type: conversationType === 2 ? EventType.chatGroupTextSent : EventType.chatPrivateTextSent,
          title: conversationType === 2 ? groupInfo?.groupName || '' : user?.username || '',
          body: body,
          body_en: `${t(response.message.message.toString() || '', { lng: 'en' })}`,
          body_ar: `${t(response.message.message.toString() || '', { lng: 'ar' })}`,
          bodyHTML: `${t(response.message.message.toString() || '', { lng: 'en' })}`,
          bodyHTML_en: `${t(response.message.message.toString() || '', { lng: 'en' })}`,
          bodyHTML_ar: `${t(response.message.message.toString() || '', { lng: 'ar' })}`,
          recipients: JSON.stringify(recipients),
          avatar_url: avatarURL,
          conversation_id: conversationType === 2 ? conversationID : user?.username || '',
          conversation_type: conversationType === 2 ? 'group' : 'private',
          conversation_name: conversationType === 2 ? groupInfo?.groupName || '' : user?.username || '',
          sender_username: user?.username || '',
          type: 'text',
        };
        sendNotification?.(messageNotificationInput);
      }
      setMessageList(prevMessageList => {
        const newMessageList = [...(prevMessageList || [])];
        if (response?.message) newMessageList.push(response?.message);
        return newMessageList;
      });
      return response;
    } catch (err) {
      console.error(err);
    }
  };

  const onSendMediaMessage = async (message: ZIMMediaMessageBase) => {
    try {
      if (!conversationID || typeof conversationType === 'undefined' || !sendMediaMessage) return;
      let pushTitle = '';
      let pushContent = '';
      //Set push title and content for group chat or private chat
      if (conversationType === 2 && !message.extendedData) {
        //Group chat
        pushTitle =
          translate('chatOfflinePushNotification.groupTitle', {
            displayName: user?.display_name,
            groupName: groupInfo?.groupName,
          }) || '';
        pushContent = `${user?.display_name}: ${translate('chatOfflinePushNotification.sentMedia', {
          media:
            message.type === 11 //IMAGE
              ? translate('chatOfflinePushNotification.photo')
              : message.type === 14 //VIDEO
              ? translate('chatOfflinePushNotification.video')
              : 'media',
        })}`;
      } else if (conversationType === 0) {
        //Private chat
        pushTitle = translate('chatOfflinePushNotification.privateTitle', { displayName: user?.display_name }) || '';
        pushContent = `${translate('chatOfflinePushNotification.sentMedia', {
          media:
            message.type === 11 //IMAGE
              ? translate('chatOfflinePushNotification.photo')
              : message.type === 14 //VIDEO
              ? translate('chatOfflinePushNotification.video')
              : 'media',
        })}`;
      }

      const pushConfig: ZIMPushConfig = {
        title: pushTitle,
        content: pushContent,
      };
      const messageConfig: ZIMMessageSendConfig = {
        priority: 1,
        hasReceipt: true,
        pushConfig: pushConfig,
      };

      // Return if the user is blocking or blocked
      if (await isBlockedOrBlocking()) return;

      const response = await sendMediaMessage(message, conversationID, conversationType, messageConfig);
      mixpanelHelper.trackEvent(MixPanelCustomEvents.NewMessageEvent, {
        'Chat ID': conversationID,
        'Chat Type': conversationType === 2 ? 'Group Chat' : 'Private',
        Username: user?.username ?? 'GUEST',
      });
      if (response?.message) {
        let recipients: any = [];
        let avatarURL = '';
        if (conversationType === 2 && !message.extendedData) {
          recipients = groupMembersList.map(member => member.userID);
          const lastOtherSender = _.findLast(messageList, sender => sender.senderUserID !== user?.username);
          if (avatarUrl && avatarUrl?.length > 0) {
            avatarURL = avatarUrl;
          } else if (lastOtherSender?.senderUserID) {
            const otherSenderAvatar: string =
              groupMembersList.find(member => member.userID === lastOtherSender?.senderUserID)?.memberAvatarUrl || '';
            avatarURL = `${user?.avatar_url ?? ''},${otherSenderAvatar}`;
          }
        } else {
          recipients = [conversationID];
          avatarURL = user?.avatar_url || '';
        }

        const mediaBodyEN: string = t('chatOfflinePushNotification.sentMedia', {
          media:
            message.type === 11 //IMAGE
              ? t('chatOfflinePushNotification.photo')
              : message.type === 14 //VIDEO
              ? t('chatOfflinePushNotification.video')
              : 'media',
          lng: 'en',
        });
        const mediaBodyAR: string = t('chatOfflinePushNotification.sentMedia', {
          media:
            message.type === 11 //IMAGE
              ? t('chatOfflinePushNotification.photo')
              : message.type === 14 //VIDEO
              ? t('chatOfflinePushNotification.video')
              : 'media',
          lng: 'ar',
        });
        const messageNotificationInput: NotificationMessageSendInput = {
          event_type:
            conversationType === 2
              ? message.type === 11
                ? EventType.chatGroupImageSent
                : message.type === 14
                ? EventType.chatGroupVideoSent
                : EventType.chatGroupImageSent
              : message.type === 11
              ? EventType.chatPrivateImageSent
              : message.type === 14
              ? EventType.chatPrivateVideoShared
              : EventType.chatPrivateImageSent,
          title: conversationType === 2 ? groupInfo?.groupName || '' : displayName || '',
          body: mediaBodyEN,
          body_en: mediaBodyEN,
          body_ar: mediaBodyAR,
          bodyHTML: mediaBodyEN,
          bodyHTML_en: mediaBodyEN,
          bodyHTML_ar: mediaBodyAR,
          recipients: JSON.stringify(recipients),
          avatar_url: avatarURL,
          conversation_id: conversationType === 2 ? conversationID : user?.username || '',
          conversation_type: conversationType === 2 ? 'group' : 'private',
          conversation_name: conversationType === 2 ? groupInfo?.groupName || '' : user?.username || '',
          sender_username: user?.username || '',
          media_url: `${message.fileDownloadUrl}`,
          type: message.type === 11 ? 'photo' : message.type === 14 ? 'video' : 'photo',
        };
        sendNotification?.(messageNotificationInput);
      }

      setMessageList(prevMessageList => {
        const newMessageList = [...(prevMessageList || [])];
        if (response?.message) newMessageList.push(response?.message);
        return newMessageList;
      });
      return response;
    } catch (err) {
      console.error('onSendMediaMessage =>', err);
    }
  };

  const onSendMessageReceipt = async (messageList: ZIMMessage[]) => {
    try {
      return await sendMessageReceipt?.(messageList, conversationID as string, conversationType as number);
    } catch (err) {
      console.error('error', err);
    }
  };

  const onDeleteMessages = async (messageList: ZIMMessage[]) => {
    try {
      const response = await deleteMessages?.(messageList, conversationID as string, conversationType as number, {
        isAlsoDeleteServerMessage: true,
      });

      messageList.forEach(message => {
        setMessageList(prevMessageList =>
          prevMessageList?.map(prevMessage => {
            if (prevMessage.messageID === message.messageID) {
              return { ...prevMessage, type: 2, message: `DELETED,${user?.username}` };
            }
            return prevMessage;
          }),
        );
      });
      return response;
    } catch (err) {
      console.error('error', err);
    }
  };

  const onRevokeMessage = async (message: ZIMMessage) => {
    try {
      const response = await revokeMessage?.(message, {});
      setMessageList(prevMessageList =>
        prevMessageList?.map(prevMessage => {
          if (prevMessage.messageID === message.messageID) {
            const newMessage = { ...prevMessage, type: 2, message: `DELETED,${userAdmin === 1 ? 'admin' : user?.username}` };
            const extendedDataAction = { action: ChatEventType.MESSAGE_REVOKED_BY_ADMIN };
            if (userAdmin === 1) {
              newMessage.extendedData = JSON.stringify(extendedDataAction);
            }
            return newMessage;
          }
          return prevMessage;
        }),
      );
      return response;
    } catch (err) {
      console.error('error', err);
    }
  };

  const setGroupOwner = async (userID: string, displayName: string) => {
    try {
      if (!conversationID || typeof conversationType === 'undefined') return;
      const response = await transferGroupOwner?.(userID, conversationID);
      if (response?.groupID) {
        const extendedDataAction = { action: ChatEventType.SET_ADMIN };
        const messageObj = {
          type: 1,
          message: translate('connect.user_assigned_admin', { user: displayName }) || `@${displayName} is now assigned as an Admin`,
          extendedData: JSON.stringify(extendedDataAction),
        };
        await onSendMessage(messageObj);
        if (user?.username) {
          const membersRes = await queryGroupMemberList?.(conversationID, { count: GROUP_MEMBER_COUNT, nextFlag: 0 });
          if (membersRes?.userList) {
            setGroupMembersList(membersRes.userList.filter(member => member.userID !== user?.username));
          }
          const currentAdminRes = await queryGroupMemberInfo?.(user?.username, conversationID);
          if (currentAdminRes?.userInfo) {
            setUserAdmin(currentAdminRes?.userInfo.memberRole);
          }
        }
      }
      return response;
    } catch (err) {
      console.error('setGroupOwner =>', err);
    }
  };

  const getMemberAvatar = useCallback(
    (userID: string) => {
      if (groupMembersList.length) {
        return groupMembersList.find(member => member.userID === userID)?.userAvatarUrl;
      }
    },
    [groupMembersList],
  );

  const errorHandler = async (err: { code?: number }) => {
    switch (err?.code) {
      case 6000603: // Conversation doesn't exists
        const response = await getUserInfoByUsername({ username: conversationID });
        const data = response.data?.getInfoByUsername.data;
        setDisplayName(data?.display_name);
        setAvatarUrl(data?.avatar_url);
        break;
    }
  };

  useEffect(() => {
    const selectedUsernames = selectedUsers.map(selectedUser => selectedUser.username);
    setFilteredUsers(getSearchResponse?.getSearch?.data?.filter(user => !selectedUsernames.includes(user.username)));
  }, [getSearchResponse]);

  const onRemoveSelectedUser = (username: string | undefined) => {
    if (username) {
      setSelectedUsers(selectedUsers.filter(user => user.username != username));
    }
  };

  useEffect(() => {
    const getHistoryMessage = async () => {
      if (conversationID === 'newChat' || !isConversationListLoaded) return;
      if (conversationID && typeof conversationType === 'number' && queryHistoryMessage && user?.username) {
        setIsMessageListLoading(true);
        try {
          const response = await queryHistoryMessage(conversationID, conversationType);
          let newMessageList = response?.messageList;
          if (newMessageList?.length) {
            newMessageList = newMessageList.map((message: any) => {
              if (
                message.type === 31 &&
                ((message?.operatedUserID === user.username && userAdmin === 1) ||
                  groupMembersList
                    .filter(member => member.memberRole === 1)
                    .map(member => member.userID)
                    .includes(message?.operatedUserID))
              ) {
                return { ...message, type: 2, message: 'DELETED,admin' };
              }
              return message;
            });
          }
          setMessageList(newMessageList || []);
        } catch (err: any) {
          errorHandler(err);
        }
        setIsMessageListLoading(false);
      }
    };
    getHistoryMessage();
  }, [conversationID, isConversationListLoaded]);

  useEffect(() => {
    const selectedUsernames = selectedUsers.map(selectedUser => selectedUser.username);
    setFilteredUsers(
      getSearchResponse?.getSearch?.data?.filter(users => !selectedUsernames.includes(users.username) && user?.username !== users.username),
    );
  }, [getSearchResponse]);

  useEffect(() => {
    const loadInfo = async () => {
      if (!isConversationListLoaded) return;
      try {
        conversationID &&
          conversationType !== undefined &&
          (await queryConversation?.(conversationID, conversationType).then(res => {
            const user = res?.conversation;
            setDisplayName(user?.conversationName);
            setAvatarUrl(user?.conversationAvatarUrl);
            if (conversationType !== 2) {
              getUserInfoByUsername({ username: conversationID });
            }
          }));
      } catch (err: any) {
        errorHandler(err);
      }
      if (conversationType === 2 && conversationID && user?.username) {
        queryGroupMemberInfo &&
          queryGroupMemberInfo(user?.username, conversationID).then(res => {
            if (res && res.userInfo) {
              setUserAdmin(res?.userInfo.memberRole);
            }
          });
        queryGroupMemberList &&
          queryGroupMemberList(conversationID, { count: GROUP_MEMBER_COUNT, nextFlag: 0 }).then(res => {
            if (res?.userList) {
              const memberList = res.userList.filter(member => member.userID !== user.username);
              setGroupMembersList(memberList);
            }
          });
        queryGroupInfo && queryGroupInfo(conversationID).then(res => setGroupInfo(res?.groupInfo.baseInfo));
        queryGroupMemberCount && queryGroupMemberCount(conversationID).then(res => setGroupMemberCount(res?.count));
      }
    };
    loadInfo();
  }, [conversationID, isConversationListLoaded]);

  useEffect(() => {
    if (conversationType !== 2 && conversationID && blockTrigger) {
      getUserInfoByUsername({ username: conversationID });
    }
  }, [blockTrigger]);

  useEffect(() => {
    groupAvatarChangeID &&
      conversationType === 2 &&
      groupAvatarChangeID === conversationID &&
      conversationID &&
      queryConversation?.(conversationID, conversationType)
        .then(res => {
          const user = res?.conversation;
          setAvatarUrl(user?.conversationAvatarUrl);
        })
        .catch(async (err: any) => {
          errorHandler(err);
        });
  }, [groupAvatarChangeID, groupAvatarChange]);

  useEffect(() => {
    setIsBlocked(userProfileInfo?.is_blocked);
    setIsBlocking(userProfileInfo?.is_blocking || false);
  }, [userProfileInfo]);

  useEffect(() => {
    if (conversationType === 0 && !!conversationID) {
      const newMessages = receivedPeerMessages?.filter(
        receivedPeerMessage =>
          receivedPeerMessage.conversationID === conversationID && receivedPeerMessage.conversationType === conversationType,
      );
      if (newMessages?.length) {
        setMessageList([...(messageList || []), ...newMessages]);
        setReceivedPeerMessages &&
          setReceivedPeerMessages(prevReceivedPeerMessages => {
            return prevReceivedPeerMessages?.filter(
              receivedPeerMessage =>
                receivedPeerMessage.conversationID != conversationID && receivedPeerMessage.conversationType != conversationType,
            );
          });
      }
    }
  }, [receivedPeerMessages]);

  useEffect(() => {
    if (conversationType === 2 && !!conversationID) {
      const newMessages = receivedGroupMessages?.filter(
        receivedGroupMessage =>
          receivedGroupMessage.conversationID === conversationID && receivedGroupMessage.conversationType === conversationType,
      );
      if (newMessages?.length) {
        setMessageList([...(messageList || []), ...newMessages]);
        setReceivedGroupMessages &&
          setReceivedGroupMessages(prevReceivedGroupMessages => {
            return prevReceivedGroupMessages?.filter(
              receivedGroupMessage =>
                receivedGroupMessage.conversationID != conversationID && receivedGroupMessage.conversationType != conversationType,
            );
          });
      }
    }
  }, [receivedGroupMessages]);

  useEffect(() => {
    if (messageReceiptChangedList?.length && !!conversationID) {
      const newMessages = messageReceiptChangedList?.filter(
        receivedGroupMessage =>
          receivedGroupMessage.conversationID === conversationID && receivedGroupMessage.conversationType === conversationType,
      );
      if (newMessages?.length) {
        newMessages.forEach(newMessage => {
          setMessageList(prevMessageList => {
            const newMessageList = [...prevMessageList];
            return newMessageList.map(message => {
              if (message.messageID === newMessage.messageID) {
                return { ...message, receiptStatus: newMessage.status };
              }
              return message;
            });
          });
        });
        setMessageReceiptChangedList &&
          setMessageReceiptChangedList(prevMessageReceiptChangedList => {
            return prevMessageReceiptChangedList?.filter(
              messageReceiptChanged =>
                messageReceiptChanged.conversationID != conversationID &&
                messageReceiptChanged.conversationType != conversationType &&
                !newMessages.map(newMessage => newMessage.messageID).includes(messageReceiptChanged.messageID),
            );
          });
      }
    }
  }, [messageReceiptChangedList]);

  useEffect(() => {
    if (messageRevokedList?.length && !!conversationID) {
      const revokedMessages = messageRevokedList?.filter(
        receivedGroupMessage =>
          receivedGroupMessage.conversationID === conversationID && receivedGroupMessage.conversationType === conversationType,
      );
      if (revokedMessages?.length) {
        revokedMessages.forEach(newMessage => {
          setMessageList(prevMessageList => {
            const newMessageList = [...prevMessageList];
            return newMessageList.map(message => {
              if (message.messageID === newMessage.messageID) {
                if (
                  message.conversationType === 2 &&
                  groupMembersList
                    .filter(member => member.memberRole === 1)
                    .map(member => member.userID)
                    .includes(newMessage.operatedUserID)
                ) {
                  return { ...message, type: 2, message: 'DELETED,admin' };
                }
              }
              return message;
            });
          });
        });
        setMessageRevokedList &&
          setMessageRevokedList(prevMessageReceiptChangedList => {
            return prevMessageReceiptChangedList?.filter(
              messageReceiptChanged =>
                messageReceiptChanged.conversationID != conversationID &&
                messageReceiptChanged.conversationType != conversationType &&
                !revokedMessages.map(newMessage => newMessage.messageID).includes(messageReceiptChanged.messageID),
            );
          });
      }
    }
  }, [messageRevokedList]);

  useEffect(() => {
    if (clearChatRes?.length && !!conversationID) {
      if (clearChatRes[clearChatRes.length - 1].conversationID === conversationID) {
        setMessageList([]);
      }
      setClearChatRes?.(prevState =>
        prevState?.filter(cleared => cleared.conversationID !== conversationID && cleared.conversationType !== conversationType),
      );
    }
  }, [clearChatRes]);

  useEffect(() => {
    if (groupNameRes?.length && !!conversationID) {
      groupNameRes.map(group => {
        if (group.groupID === groupInfo?.groupID) {
          setGroupInfo({ ...groupInfo, groupName: group.groupName });
          setDisplayName(group.groupName);
        }
      });
      setGroupNameRes?.(prevState => prevState?.filter(group => group.groupID !== conversationID));
    }
  }, [groupNameRes]);

  useEffect(() => {
    if (groupMemberStateChangedList?.length && !!conversationID && conversationType === 2 && !isDismissed) {
      groupMemberStateChangedList.forEach(({ userList, event, groupID }) => {
        if (event === 2 && userList.map(user => user.userID).includes(user?.username as string) && groupID === conversationID) {
          setIsDismissed(true);
        }
      });
      setGroupMemberStateChangedList?.(prevGroupMemberStateChangedList =>
        prevGroupMemberStateChangedList?.filter(state => state.groupID != conversationID),
      );
    }
  }, [groupMemberStateChangedList]);

  useEffect(() => {
    if (groupMemberInfoUpdatedList?.length && !!conversationID && conversationType === 2 && !isDismissed) {
      groupMemberInfoUpdatedList.forEach(({ userList, groupID }) => {
        if (groupID === conversationID) {
          userList.forEach(user => {
            setGroupMembersList(prevGroupMembersList =>
              prevGroupMembersList.map(groupMember => {
                setUserAdmin(groupMember.memberRole);
                return { ...groupMember, memberRole: user.memberRole };
              }),
            );
          });
        }
      });
      setGroupMemberInfoUpdatedList?.(prevGroupMemberInfoUpdatedList =>
        prevGroupMemberInfoUpdatedList?.filter(state => state.groupID != conversationID),
      );
    }
  }, [groupMemberInfoUpdatedList]);

  useEffect(() => {
    if (queryMembersRes?.length && !!conversationID) {
      queryMembersRes.map(members => {
        if (members.groupID === groupInfo?.groupID && user?.username) {
          const memberList = members.userList.filter(member => member.userID !== user.username);
          queryGroupMemberCount && queryGroupMemberCount(conversationID).then(res => setGroupMemberCount(res?.count));
          setGroupMembersList(memberList);
        }
      });
      setQueryMembersRes?.(prevState => prevState?.filter(member => member.groupID !== conversationID));
    }
  }, [queryMembersRes]);

  useEffect(() => {
    if (inviteUsersRes?.length && !!conversationID) {
      inviteUsersRes.map(members => {
        if (members.groupID === groupInfo?.groupID && user?.username) {
          const memberList = async () => {
            const membersRes = await queryGroupMemberList?.(conversationID, { count: GROUP_MEMBER_COUNT, nextFlag: 0 });
            if (membersRes?.userList) {
              setGroupMembersList(membersRes.userList.filter(member => member.userID !== user?.username));
            }
          };
          memberList();
          queryGroupMemberCount && queryGroupMemberCount(conversationID).then(res => setGroupMemberCount(res?.count));
        }
      });
      setInviteUsersRes?.(prevState => prevState?.filter(member => member.groupID !== conversationID));
    }
  }, [inviteUsersRes]);

  return (
    <ConnectConversationContext.Provider
      value={{
        suggestions,
        filteredUsers,
        selectedUsers,
        messageList,
        conversationID,
        conversationType,
        displayName,
        avatarUrl,
        userAdmin,
        isSearching,
        lastReadMessage,
        lastSentMessage,
        isBlocking,
        isBlocked,
        groupMembersList,
        groupInfo,
        groupMemberCount,
        isMessageListLoading,
        filteredMessageList,
        firstMessagesOnDate,
        isDismissed,
        isProfileLoading,
        isLoadingGetFollowings,
        setGroupOwner,
        setGroupMembersList,
        search,
        setMessageList,
        setSelectedUsers,
        onRemoveSelectedUser,
        onSendMessage,
        setIsBlocking,
        onSendMediaMessage,
        onSendMessageReceipt,
        onDeleteMessages,
        onRevokeMessage,
        getMemberAvatar,
      }}
    >
      {children}
    </ConnectConversationContext.Provider>
  );
};

export const useConnectConversationProvider = (): UseConnectConversationProvider => {
  const context = useContext(ConnectConversationContext);
  if (!context) throw 'Please use a provider to use this hook';
  return context;
};
