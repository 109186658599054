// import { useHeaderState } from '../../../../../lib/hooks/useHeaderState';
import { RootState } from '@fe-monorepo/store';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Button from '../../../../components/Atoms/Buttons/Button';
import useMobileDetect from '../../../../hooks/useMobileDetect';

interface ProductDetailsButtonsProps {
  bannerType?: string;
  isDisplayed?: boolean;
  addToCart: () => void;
  isButtonDisabled?: boolean;
  buyNow: () => void;
}

const BuyNowAddToCart = (props: ProductDetailsButtonsProps) => {
  const { t } = useTranslation();
  const isMobile = useMobileDetect();
  const isHeaderDark = useSelector((state: RootState) => state.app.headerColorChange);
  const themes = useSelector((state: RootState) => state.app.themes);
  const { dir } = useHeaderState();

  const addData = {
    bgImage: ``,
    imageIcon: ``,
    txtDescription: ``,
    txtTitle: ``,
    btnText: t('action_AddToCart'),
  };

  const buyData = {
    bgImage: ``,
    imageIcon: ``,
    txtDescription: ``,
    txtTitle: ``,
    btnText: t('buyButton'),
  };

  const { btnClass } = usePageLayout();

  return (
    <div>
      <div className={`flex ${isMobile ? 'flex-col' : ''} items-start gap-x-[24px] gap-y-[1rem]`}>
        <Button
          text={`${buyData.btnText}`}
          style={`text-fs-body max-h-[2.5rem] border-[0.063rem] border-sunset font-medium ${
            isMobile ? 'w-full px-[1rem] py-[0.5rem]' : 'w-[50%] max-w-[17.625rem] py-[0.5rem]'
          } min-w-[8.688rem] gap-[0.5rem] z-1 text-white100 text-sunsetText mb-[0px] xsMax:mb-[0px] xsMax:w-[335px] items-end rounded-[0.188rem] rounded-[0.188rem]`}
          normalStyle={`${
            isHeaderDark
              ? dir === 'ltr'
                ? `after:bg-white100 before:bg-sunset`
                : `before:bg-white100 after:bg-sunset`
              : `${dir === 'ltr' ? `after:bg-white100 before:bg-sunset` : `before:bg-white100 after:bg-sunset`}`
          }
      hover:text-white100`}
          disabled={props.isButtonDisabled}
          disabledStyle={`border-black20`}
          onClick={props.buyNow}
        />
        <Button
          text={`${addData.btnText}`}
          style={`text-fs-body max-h-[2.5rem] font-medium ${
            isMobile ? 'w-full px-[1rem] py-[0.5rem]' : 'w-[50%] max-w-[17.625rem] py-[0.5rem]'
          } min-w-[8.688rem] gap-[0.5rem] z-1 text-white100 border-[0.063rem] mb-[0px] xsMax:mb-[0px] xsMax:w-[335px] items-end rounded-[0.188rem] rounded-[0.188rem]`}
          disabledStyle={`bg-black10 border-0`}
          disabled={props.isButtonDisabled}
          normalStyle={btnClass}
          onClick={props.addToCart}
        />
      </div>
    </div>
  );
};

export default BuyNowAddToCart;
