export enum BrazeCustomEvents {
  AppOpen = 'App Open',
  PageView = 'Page View',
  ScreenView = 'screen_view',
  // Bits start
  BitViewed = 'Bit Viewed',
  BitCreationStarted = 'Bit Creation Started',
  BitSubmitted = 'Bit Submitted',
  BitsLiked = 'Bits Liked',
  BitsCommented = 'Bits Commented',
  BitsShared = 'Bits Shared',
  BitReported = 'Bits Reported',
  // Bits end
  ClickedAppLink = 'clicked_app_link',
  SignUp = 'signup',
  FailedSignUp = 'failed_signup',
  ConfirmedOTP = 'confirmed_otp',
  FailedOTP = 'failed_otp',
  Login = 'login',
  FailedLogin = 'failed_login',
  Search = 'search',
  SubscribedNewsLetter = 'subscribed_newsletter',
  ViewShopItemsList = 'view_items_list',
  ViewShopItem = 'view_item',
  AddedItemToCart = 'add_to_cart',
  ViewedCart = 'view_cart',
  RemovedItemFromCart = 'remove_from_cart',
  BeginsCheckout = 'begin_checkout',
  AddedAddress = 'add_shipping_info',
  AddedPaymentDetails = 'add_payment_info',
  PurchaseSuccessful = 'purchase',
  PurchaseFailed = 'failed_purchase',
  RequestForReturn = 'refund',
  ErrorEncountered = 'encountered_error',
  ViewedTournament = 'viewed_tournament',
  JoinedTournament = 'joined_tournament',
  CompletedTournament = 'completed_tournament',
  StartedMatchmaking = 'started_matchmaking',
  FoundMatch = 'found_match',
  FailedMatchmaking = 'failed_matchmaking',
  SelectedMiniGame = 'selected_minigame',
  EndedMiniGame = 'ended_minigame',
  SignupStarted = 'Sign Up Started',
  SignupMethodSubmitted = 'Signup Method Submitted',
  SignUpUsernameSubmitted = 'Sign Up Username Submitted',
  OtpSubmitted = 'OTP Submitted',
  LoginStarted = 'Login Started',
  LoginSubmitted = 'Login Submitted',
  //Games start
  GetMatched = 'Get Matched',
  MatchedGamer = 'Matched Gamer',
  MatchedRejected = 'Matched Rejected',
  GameViewed = 'Game Viewed',
  StartedMiniGame = 'Game Play Started',
  ClosedMiniGame = 'Game Play Closed',
  // Games end
  ProductViewed = 'Product Viewed',
  ProductBuyNow = 'Product Buy Now',
  ProductAddedToCart = 'Product Added To Cart',
  CartViewed = 'Cart Viewed',
  CartError = 'Cart Error',
  DeliveryAndPayment = 'Delivery & Payment',
  ProductAddedToWishlist = 'Product Added To Wishlist',
  AddressOnDeliveryAndPaymentPage = 'Address On Delivery & Payment Page',
  OrderPlaced = 'Order Placed',
}
