import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Radio from '@fe-web/Molecules/InputFields/Radio/Radio';
import Modal from '@fe-web/Templates/Modal/Modal';
import { useConnectConversationProvider } from '@fe-web/hooks/useConnect';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { ZIMMessage } from 'zego-zim-web';

enum DeleteMessageType {
  all = 'all',
  only_me = 'only_me',
}

interface DeleteMessageModalProps {
  isOpen?: boolean;
  onClose?: () => void;
  message?: ZIMMessage;
}

function DeleteMessageModal({ isOpen, onClose, message }: DeleteMessageModalProps) {
  const { translate } = useTranslate();
  const [type, setType] = useState<DeleteMessageType>();
  const [selectedDeleteType, setSelectedDeleteType] = useState<string>();
  const { onDeleteMessages, onRevokeMessage } = useConnectConversationProvider();
  const [isLoading, setIsLoading] = useState<boolean>();

  const handleOptionChange = (e: any) => {
    setSelectedDeleteType(e.target.value);
  };

  useEffect(() => {
    const time = moment(message?.timestamp);
    const currentTime = moment();
    if (currentTime.subtract(24, 'hours') > time) {
      setType(DeleteMessageType.only_me);
    }
  }, [message?.timestamp, isOpen]);

  const handleSubmit = async () => {
    if (!message) return;
    setIsLoading(true);
    switch (selectedDeleteType) {
      case DeleteMessageType.all:
        // delete all
        await onRevokeMessage?.(message);
        break;
      default:
        // delete only me
        await onDeleteMessages?.([message]);
        break;
    }
    setIsLoading(false);
    onClose?.();
  };

  return (
    <Modal backdropClassName="flex items-center" open={isOpen}>
      <div className="w-[27rem] max-w-full max-h-screen p-[3rem] bg-primary border border-neutral-400-zinc-700 rounded-[0.5rem] text-secondary">
        <div className="flex gap-[0.5rem] items-start mb-[2rem]">
          <p className="flex-1 font-medium text-fs-title">
            {translate(type === DeleteMessageType.only_me ? 'connect.delete_message_only_me' : 'connect.delete_message_all')}
          </p>
          <button onClick={() => onClose?.()}>
            <ResponsiveIcon name={IconNames.close1} baseWidth={20} baseHeight={20} iconClasses="fill-secondary" />
          </button>
        </div>
        {type === DeleteMessageType.only_me ? (
          <>
            <div>
              <p className="font-regular text-fs-body text-zinc-600-400">{translate('connect.delete_message_only_me_description')}</p>
            </div>

            <div className="flex gap-[1rem] mt-[1.25rem]">
              <Button
                text={translate('action_cancel')}
                style="flex-1 w-full sm:w-full not-italic font-medium leading-6 px-24 py-[0.5rem] font-medium gap-[8px] text-sunset border-[1px] border-sunset rounded-sm dark:after:bg-primary dark:before:bg-sunset dark:rtl:before:bg-primary dark:rtl:after:bg-sunset after:bg-primary before:bg-sunset rtl:before:bg-primary rtl:after:bg-sunset hover:-border[1px] hover:border-transparent hover:text-white100 rounded-sm 2K:border-[.1rem]"
                onClick={() => {
                  setSelectedDeleteType(undefined);
                  onClose?.();
                }}
              />
              <Button
                text={translate('common_yes')}
                style="flex-1 w-full sm:w-full not-italic font-medium leading-6 px-24 py-[0.5rem] font-medium gap-[8px] text-white100 border-[1px] border-transparent rounded-sm dark:after:bg-sunset dark:before:bg-white100 dark:rtl:before:bg-sunset dark:rtl:after:bg-white100 after:bg-sunset before:bg-white100 rtl:before:bg-sunset rtl:after:bg-white100 hover:-border[1px] hover:border-sunset hover:text-sunsetText rounded-sm 2K:border-[.1rem]"
                onClick={() => {
                  handleSubmit();
                }}
                disabled={isLoading}
              />
            </div>
          </>
        ) : (
          <>
            <div className="flex flex-col divide-y divide-gray-200-zinc-800">
              <label className="inline-flex items-center gap-[0.5rem] py-[0.75rem] first:pt-0 last:pb-0">
                <Radio
                  name="type"
                  value="all"
                  checked={selectedDeleteType === 'all'}
                  onChange={handleOptionChange}
                  className="ring-sunset accent-sunset"
                />
                <span className="text-secondary text-fs-body font-regular">{translate('connect.all_participants')}</span>
              </label>
              <label className="inline-flex items-center gap-[0.5rem] py-[0.75rem] first:pt-0 last:pb-0">
                <Radio
                  type="radio"
                  name="type"
                  value="only_me"
                  checked={selectedDeleteType === 'only_me'}
                  onChange={handleOptionChange}
                  className="ring-sunset accent-sunset"
                />
                <span className="text-secondary text-fs-body font-regular">{translate('connect.only_me')}</span>
              </label>
            </div>
            <div className="flex gap-[1rem] mt-[2.5rem]">
              <Button
                text={translate('action_cancel')}
                style="flex-1 w-full sm:w-full not-italic font-medium leading-6 px-24 py-[0.5rem] font-medium gap-[8px] text-sunset border-[1px] border-sunset rounded-sm dark:after:bg-primary dark:before:bg-sunset dark:rtl:before:bg-primary dark:rtl:after:bg-sunset after:bg-primary before:bg-sunset rtl:before:bg-primary rtl:after:bg-sunset hover:-border[1px] hover:border-transparent hover:text-white100 rounded-sm 2K:border-[.1rem]"
                onClick={() => {
                  setSelectedDeleteType(undefined);
                  onClose?.();
                }}
              />
              <Button
                text={translate('action_submit')}
                style="flex-1 w-full sm:w-full not-italic font-medium leading-6 px-24 py-[0.5rem] font-medium gap-[8px] text-white100 border-[1px] border-transparent rounded-sm dark:after:bg-sunset dark:before:bg-white100 dark:rtl:before:bg-sunset dark:rtl:after:bg-white100 after:bg-sunset before:bg-white100 rtl:before:bg-sunset rtl:after:bg-white100 hover:-border[1px] hover:border-sunset hover:text-sunsetText rounded-sm disabled:bg-gray-200-zinc-800 disabled:dark:bg-gray-200-zinc-800 disabled:before:bg-gray-200-zinc-800 disabled:before:dark:bg-gray-200-zinc-800 disabled:after:bg-gray-200-zinc-800 disabled:after:dark:bg-gray-200-zinc-800 disabled:text-neutral-400-zinc-500/40 disabled:border-gray-200-zinc-800 2K:border-[.1rem]"
                disabled={!selectedDeleteType || isLoading}
                onClick={() => {
                  handleSubmit();
                }}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
}

export default DeleteMessageModal;
