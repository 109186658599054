import { IMAGE_ALT_LABELS, IconNames, TxKeyPath, convertGMTToIana, getCurrentUserTimeZone } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import { format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import arLocale from 'date-fns/locale/ar';
import enLocale from 'date-fns/locale/en-US';
import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';

import { getReturnStatusStyles } from '../../../../../lib/GetReturnStatus';

interface DummyProduct {
  return_product_id: string;
  sku: string;
  amount: string;
  status_code: string;
  status_ar: string;
  status_en: string;
  return_reason_code: string;
  reject_reason_ar: string;
  reject_reason_en: string;
  product_media: string;
  return_media: [string];
  name_en: string;
  name_ar: string;
}

interface ProductItemsProps {
  idinfo: string;
  createdAtdate: string;
  updatedAtdate: string;
  total: number;
  productCount: number | undefined;
  status: string;
  status_code: string;
  products: string[];
  showPlus: boolean;
  isMobile?: boolean;
  handleDetailsButtonClick: () => void;
}

const ReturnsDetailsItems: React.FC<ProductItemsProps> = ({
  idinfo,
  total,
  status,
  status_code,
  products,
  createdAtdate,
  updatedAtdate,
  handleDetailsButtonClick,
  showPlus,
  isMobile,
  productCount,
}) => {
  const { translate } = useTranslate();
  const [isclicked, setIsclicked] = useState(false);

  const uniqueIdsCount = '+';
  const seeOrderDetails = (isclicked: boolean) => {
    handleDetailsButtonClick();
    setIsclicked(isclicked);
  };

  const colorClasses = getReturnStatusStyles(status_code);

  function formatStatus(status: any): any {
    if (status === 'Requested') {
      return translate('shop.cart.stepper.requested');
    } else if (status === 'Rejected (partially)') {
      return translate('shop.cart.stepper.rejected1');
    } else if (status === 'Rejected') {
      return translate('shop.cart.stepper.rejected2');
    } else if (status === 'Action required') {
      return translate('shop.cart.stepper.action_required');
    } else if (status === 'Approved_1') {
      return translate('shop.cart.stepper.approved1');
    } else if (status === 'Approved_2') {
      return translate('shop.cart.stepper.approved2');
    } else if (status === 'Courier_pickup') {
      return translate('shop.cart.stepper.in_transit');
    } else if (status === 'Refunded') {
      return translate('shop.cart.stepper.refunded');
    } else if (status === 'Under process') {
      return translate('shop.cart.stepper.under_process');
    } else {
      return status;
    }
  }

  const statusCode = formatStatus(status);

  const preferred_timezone = useSelector((state: RootState) => state.user.userContext.preferred_timezone);
  const language = useSelector((state: RootState) => state.app.language);

  const locale = language === 'ar' ? arLocale : enLocale;

  const updatedDate = parseISO(updatedAtdate);
  const createdDate = parseISO(createdAtdate);

  const finalTimezone = preferred_timezone ? convertGMTToIana(preferred_timezone) : getCurrentUserTimeZone();

  const updatedZonedDate = toZonedTime(updatedDate, finalTimezone);
  const createdZonedDate = toZonedTime(createdDate, finalTimezone);

  const updatedAtDateFormatted = format(updatedZonedDate, language === 'ar' ? 'dd MMMM yyyy' : 'dd MMM yyyy', { locale });
  const createdAtDateFormatted = format(createdZonedDate, language === 'ar' ? 'dd MMMM yyyy' : 'dd MMM yyyy', { locale });

  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1440);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1440);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return isMobile ? (
    <>
      <div
        className={`flex flex-row justify-between gap-[1rem] SD:mr-[1.25rem] mdxs:mt-[1rem] ${showPlus ? 'hidden' : ''}`}
        onClick={() => seeOrderDetails(!isclicked)}
      >
        <div className="flex flex-col gap-[1rem] w-full">
          <div className="flex flex-col col-span-2 gap-[.5rem] ">
            <p className="text-fs-caption font-regular text-secondary">{translate('return_id')}</p>
            <p className="text-fs-body-small font-regular text-secondary">{idinfo}</p>
          </div>
          <div className="flex flex-row gap-[1rem] justify-between mr-[5rem]">
            <div className="flex flex-col gap-[.5rem] font-regular">
              <p className="text-fs-caption text-black70">{translate('common_createdAt')}</p>
              <p className="text-fs-body-small text-secondary-100">{createdAtDateFormatted}</p>
            </div>
            <div className="flex flex-col gap-[.5rem] font-regular">
              <p className="text-fs-caption text-black70">{translate('common_updatedAt')}</p>
              <p className="text-fs-body-small text-secondary-100">{updatedAtDateFormatted}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`flex flex-col col-span-4 SD:mr-[1.25rem] gap-[1rem] ${
          showPlus ? 'border-b-[.25px] border-secondary/25 pb-[1.5rem]' : 'hidden'
        }`}
      >
        <div className="flex flex-col gap-[.5rem]">
          <div className="flex flex-row justify-between my-[.5rem]">
            <CurrencyText className="text-fs-body font-medium mt-[.25rem] text-secondary-100" currency={total | 0} />
            <span
              className={`py-[0.125rem] px-[0.5rem] border-[0.063rem] rounded-[0.125rem] font-medium h-[1.875rem] truncate ${colorClasses}`}
            >
              {statusCode || 'N/A'}
            </span>
          </div>
          <div className="flex flex-row justify-between">
            <div className="flex flex-col gap-[.5rem] font-regular">
              <p className="text-fs-body-small text-secondary opacity-[0.50]">{idinfo}</p>
              <p className="text-fs-caption text-secondary opacity-[0.50]">
                {products?.length || 0} {products && products.length > 1 ? 'products' : 'product'}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between" onClick={() => seeOrderDetails(!isclicked)}>
          <div className="flex flex-row gap-[1rem]">
            {products &&
              products.length > 0 &&
              products.slice(0, isLargeScreen ? 2 : 1).map((product, index) => (
                <div
                  key={index}
                  className="lazyimage rounded-[2px] bg-secondary/[0.06] flex gap-[0.625rem] h-[4rem] px-[0.938rem] py-[0.813rem] justify-center items-center"
                >
                  <LazyLoadImage
                    className="h-[1.875rem] w-[1.875rem]"
                    src={product}
                    alt={translate(IMAGE_ALT_LABELS.return_product_thumbnail as TxKeyPath) ?? ''}
                  />
                </div>
              ))}
            {!products && (
              <div className="uniqueIdsCount rounded-[0.125rem] bg-secondary/[0.06] flex w-[7rem] h-[7rem] gap-[0.625] px-[0.938rem] py-[0.813rem] justify-center items-center text-orange-500 font-medium mdxs:h-[5rem] mdxs:w-[5rem]">
                {`${uniqueIdsCount}`}
              </div>
            )}
          </div>
          <div className="flex items-center justify-center">
            <ResponsiveIcon
              className={`fill-sunset ${!showPlus ? 'rotate-90' : 'rotate-0'}`}
              name={IconNames.chevronRight}
              baseHeight={15}
              baseWidth={15}
            />
          </div>
        </div>
        <div className="flex flex-row gap-[1rem] justify-between mr-[5rem]">
          <div className="flex flex-col gap-[.5rem] font-regular">
            <p className="text-fs-caption text-secondary opacity-[0.70]">{translate('common_createdAt')}</p>
            <p className="text-fs-body-small text-gray90">{createdAtDateFormatted}</p>
          </div>
          <div className="flex flex-col gap-[.5rem] font-regular">
            <p className="text-fs-caption text-secondary opacity-[0.70]">{translate('common_updatedAt')}</p>
            <p className="text-fs-body-small text-gray90">{updatedAtDateFormatted}</p>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className="flex w-full border-t-[.25px] border-secondary/25">
      <div className="flex flex-row justify-between pb-[1.5rem] grid grid-cols-7 SD:grid-cols-12 pt-[1rem] w-[98%]">
        <div className="idinfo flex justify-start item-center font-regular text-fs-body mt-[1rem] mr-[.25rem] SD:col-span-2 col-span-1">
          <span className="truncate text-secondary">{idinfo}</span>
        </div>
        <div className="total flex justify-start item-center gap-[.4rem] mt-[1rem] mr-[.25rem] SD:col-span-1 col-span-1">
          <span className="bg-secondary/25 w-[0.063rem] h-[1.5rem] "></span>
          <CurrencyText className="truncate text-secondary text-fs-body font-regular" currency={total} />
        </div>
        <div className="total flex justify-start item-center gap-[1rem] mt-[1rem] mr-[.25rem ] SD:col-span-1 col-span-1">
          <span className="bg-secondary/25 w-[0.063rem] h-[1.5rem] "></span>
          <span className="text-secondary text-fs-body font-regular">{productCount}</span>
        </div>
        <div className="status flex justify-start item-center gap-[1rem] mt-[1rem] mr-[.25rem] SD:col-span-2 col-span-1">
          <span className="bg-secondary/25 w-[0.063rem] h-[1.5rem]"></span>
          <span
            className={`py-[0.125rem] px-[0.5rem] border-[0.063rem] rounded-[0.125rem] font-medium h-[1.875rem] truncate ${colorClasses}`}
          >
            {statusCode || 'N/A'}
          </span>
        </div>
        <div className="image flex justify-start items-center gap-[1rem] SD:col-span-2 col-span-1">
          <span className="bg-secondary/25 w-[0.063rem] h-[1.5rem]"></span>
          {products &&
            products.length > 0 &&
            products.slice(0, isLargeScreen ? 2 : 1).map((product, index) => (
              <div
                key={index}
                className="lazyimage rounded-[2px] border-[0.063rem] border-secondary/[0.06] rounded-sm flex gap-[0.625rem] h-[4rem] px-[0.938rem] py-[0.813rem] justify-center items-center"
              >
                <LazyLoadImage
                  className="h-[1.875rem] w-[1.875rem]"
                  src={product}
                  alt={translate(IMAGE_ALT_LABELS.return_product_thumbnail as TxKeyPath) ?? ''}
                />
              </div>
            ))}
          {((products && isLargeScreen ? products.length > 2 : products.length > 1) || !products) && (
            <div
              className="
                            flex  gap-[0.625]
                            justify-start items-center text-secondary font-medium"
            >
              {`+${isLargeScreen ? products.length - 2 : products.length - 1}`}
            </div>
          )}
        </div>
        <div className="orderdate flex justify-start item-center gap-[1rem] mt-[1rem] mr-[.25rem] SD:col-span-2 col-span-1">
          <span className="bg-secondary/25 w-[0.063rem] h-[1.5rem]"></span>
          <span className="truncate text-secondary text-fs-body font-regular">{updatedAtDateFormatted}</span>
        </div>
        <div className="orderdate flex justify-start item-center gap-[1rem] mt-[1rem] mr-[.25rem] SD:col-span-2 col-span-1">
          <span className="bg-secondary/25 w-[0.063rem] h-[1.5rem]"></span>
          <span className="truncate text-secondary text-fs-body font-regular">{createdAtDateFormatted}</span>
        </div>
      </div>
      <div
        className={`flex items-center justify-start ml-[-1rem] mdMaxMm:ml-[0.063rem] SD:col-span-1 w-[2%] text-fs-bodyLarge ${
          products.length > 0 ? 'mb-[1rem]' : 'mb-[0rem]'
        }`}
      >
        <p className="font-medium cursor-pointer text-sunsetText whitespace-nowrap" onClick={handleDetailsButtonClick}>
          {showPlus ? (
            <ResponsiveIcon name={IconNames.addPlus} baseHeight={20} baseWidth={20} />
          ) : (
            <ResponsiveIcon name={IconNames.minusReturns} baseHeight={20} baseWidth={20} />
          )}
        </p>
      </div>
    </div>
  );
};

export default ReturnsDetailsItems;
