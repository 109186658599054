import colors from './colors';
import { Style } from './globalStyleType';

export const globalStyle: Style = {
  light: {
    backgroundColor: colors.white100,
    drawerActiveTintColor: '#CCCCCC',
    textColor: colors.black100,
    textColor05: colors.black05,
    textColor10: colors.black10,
    textColor20: colors.black20,
    textColor30: colors.black30,
    textColor42: colors.black42,
    textColor50: colors.black50,
    textColor70: colors.black70,
    textColor90: colors.black90,
    textColorDisabled: colors.black42,
    textLabelColor: colors.black70,
    labelColor: colors.white100,
    labelColor50: colors.white50,
    primaryColor: colors.black100,
    secondaryColor: colors.black50,
    disabledColor: colors.black20,
    iconColor: colors.black100,
    iconLightColor: colors.black50,
    buttonSecondaryColor: colors.black100,
    buttonBorderColor: colors.black20,
    buttonLabelSecondaryColor: colors.black100,
    buttonDisabledColor: colors.black10,
    buttonLabelDisabledColor: colors.black30,
    sunsetColor: colors.lightSunset,
    itemBackgroundColor: colors.black06,
    borderGray: colors.surfaceGray,
    borderDarkGray: colors.gray90,
    logoCardBackGround: colors.gray05,
    warningOrange: colors.warningOrange,
  },

  dark: {
    backgroundColor: colors.black100,
    drawerActiveTintColor: '#CCCCCC',
    textColor: colors.white100,
    textColor05: colors.white10,
    textColor10: colors.white10,
    textColor20: colors.white20,
    textColor30: colors.white30,
    textColor42: colors.white42,
    textColor50: colors.white50,
    textColor70: colors.white70,
    textColor90: colors.white90,
    textColorDisabled: colors.white50,
    textLabelColor: colors.white70,
    labelColor: colors.black100,
    labelColor50: colors.black50,
    iconColor: colors.white100,
    iconLightColor: colors.white50,
    primaryColor: colors.white100,
    secondaryColor: colors.white50,
    disabledColor: colors.white20,
    buttonSecondaryColor: colors.white100,
    buttonBorderColor: colors.white100,
    buttonLabelSecondaryColor: colors.white100,
    buttonDisabledColor: colors.white10,
    buttonLabelDisabledColor: colors.white30,
    sunsetColor: colors.darkSunset,
    itemBackgroundColor: colors.white06,
    borderGray: colors.gray20,
    borderDarkGray: colors.gray90,
    logoCardBackGround: colors.white05,
    warningOrange: colors.warningOrange,
  },

  custom: {
    backgroundColor: colors.darkPurple,
    drawerActiveTintColor: '#CCCCCC',
    textColor: colors.black100,
    textColor05: colors.white05,
    textColor10: colors.white10,
    textColor20: colors.white20,
    textColor30: colors.white30,
    textColor42: colors.black42,
    textColor50: colors.black50,
    textColor70: colors.black70,
    textColor90: colors.black90,
    textColorDisabled: colors.black42,
    textLabelColor: colors.black70,
    labelColor: colors.white100,
    labelColor50: colors.white50,
    iconLightColor: colors.white50,
    iconColor: colors.sunset,
    primaryColor: colors.white100,
    secondaryColor: colors.white50,
    disabledColor: colors.white20,
    buttonSecondaryColor: colors.sunset,
    buttonBorderColor: colors.white100,
    buttonLabelSecondaryColor: colors.black100,
    buttonDisabledColor: colors.white10,
    buttonLabelDisabledColor: colors.white30,
    itemBackgroundColor: colors.black06,
    borderGray: colors.gray20,
    borderDarkGray: colors.gray90,
    logoCardBackGround: colors.white05,
    warningOrange: colors.warningOrange,
  },
};

export const getStyle = (theme: string) => {
  return globalStyle[theme];
};
