import { setCookieConsent, setShowCookieConsent, useAppDispatch } from '@fe-monorepo/store';

const COOKIE_NAME = 'stc_consent';
const COOKIE_EXPIRY_DAYS = 30;


function useCookieConsent() {
    const dispatch = useAppDispatch();



  // Helper function to get a cookie by name
  function getCookie(name: string): string | null {
    const matches = document.cookie.match(new RegExp(`(?:^|; )${name}=([^;]*)`));
    return matches ? decodeURIComponent(matches[1]) : null;
  }

  // Helper function to set a cookie
  function setCookie(name: string, value: string, days: number): void {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=${value};expires=${date.toUTCString()};path=/;`;
  }

  // Helper function to delete a cookie
  function deleteCookie(name: string): void {
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;`;
  }

  // Function to accept cookies
  const acceptCookies = () => {
    setCookie(COOKIE_NAME, 'accepted', COOKIE_EXPIRY_DAYS);
    dispatch(setCookieConsent("accepted"));
    dispatch(setShowCookieConsent(false));
  };

  // Function to reject cookies
  const rejectCookies = () => {
    setCookie(COOKIE_NAME, 'rejected', COOKIE_EXPIRY_DAYS);
    dispatch(setCookieConsent("rejected"));
    dispatch(setShowCookieConsent(false));
  };

  const getConsent = () => {
    return getCookie(COOKIE_NAME);
  }

  // Function to reset cookie consent
  const resetConsent = () => {
    deleteCookie(COOKIE_NAME);
    dispatch(setCookieConsent(null));
  };

  

  return { acceptCookies, rejectCookies, resetConsent, getConsent };
}

export default useCookieConsent;
