import { useLazyQuery } from '@apollo/client';
import { RETURN_DATA_QUERY } from '@fe-monorepo/data-access';
import { useEffect, useState } from 'react';

import { GetRetailInfoParamsModel, GetRetailInfoResponse } from './type';

export const useGetRetailInfo = () => {
  const [isSuccessful, setStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [getRetailInfoGql, { data: infoDataResult }] = useLazyQuery<GetRetailInfoResponse>(RETURN_DATA_QUERY.omsGetInfoReturn, {
    errorPolicy: 'all',
  });

  const getRetailInfo = async (infoTournamentResult: GetRetailInfoParamsModel) => {
    const { data } = await getRetailInfoGql({
      variables: {
        details: infoTournamentResult,
      },
    });
    return data?.omsGetInfoReturn;
  };

  useEffect(() => {
    if (infoDataResult) {
      if (infoDataResult?.omsGetInfoReturn?.is_successful) {
        setStatus(infoDataResult?.omsGetInfoReturn?.is_successful);
      }
      if (infoDataResult?.omsGetInfoReturn?.error_msg) {
        setErrorMessage(infoDataResult?.omsGetInfoReturn?.error_msg);
      }
      setStatus(infoDataResult?.omsGetInfoReturn?.is_successful);
    }
    setIsLoading(false);
  }, [infoDataResult]);

  return { getRetailInfo, getRetailInfoData: infoDataResult?.omsGetInfoReturn, isSuccessful, isLoading, errorMessage };
};
