import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import { useSelector } from 'react-redux';

import { IconNames } from './icons';

export enum SettingSection {
  ACCOUNT = 'account',
  LOCATION_AND_DISPLAY = 'location_display',
  NOTIFICATIONS = 'notifications',
  SECURITY = 'security',
  LOGIN = 'login',
}
export interface SettingsModel {
  key?: string;
  label: string;
  icon?: string;
  navigate?: any;
  isLogout?: boolean;
  rightValue?: string;
  isSwitch?: boolean;
  isVerifiable?: boolean;
  isEmail?: boolean;
  isPhone?: boolean;
}

type SettingsProps = {
  type: SettingSection;
  hideBottomBorder?: boolean;
  label: string;
  data: SettingsModel[];
  isShown?: boolean;
};

export type SettingsData = {
  settings: SettingsProps[];
};
export interface IMenuItems {
  icon: IconNames;
  menuTitle: string | null;
  hasArrow: boolean;
  path?: string[];
  isDisabled: boolean;
}

export interface ISettingsData {
  title: string | null;
  menuItems: Array<IMenuItems>;
}

export const SettingsData = () => {
  const { translate } = useTranslate();
  const hide2FA = useSelector((state: RootState) => state.app.hide2FA);
  const SETTINGS_DATA: Array<ISettingsData> = [
    {
      title: translate('profile.account'),
      menuItems: [
        {
          icon: IconNames.smartToy,
          menuTitle: translate('profile.my_profile'),
          hasArrow: true,
          path: ['/my-profile'],
          isDisabled: false,
        },
        {
          icon: IconNames.genderMaleFemale,
          menuTitle: translate('settings.gender'),
          hasArrow: true,
          path: ['/gender'],
          isDisabled: false,
        },
        {
          icon: IconNames.phoneDial,
          menuTitle: translate('settings.phone_number'),
          hasArrow: true,
          path: ['/phone-number'],
          isDisabled: false,
        },
        {
          icon: IconNames.email,
          menuTitle: translate('settings.email'),
          hasArrow: true,
          path: ['/email'],
          isDisabled: false,
        },
        {
          icon: IconNames.encrypted,
          menuTitle: translate('settings.password'),
          hasArrow: true,
          path: ['/password'],
          isDisabled: false,
        },
        {
          icon: IconNames.walletNew,
          menuTitle: translate('pageTitle_wallet'),
          hasArrow: true,
          path: ['/wallet'],
          isDisabled: false,
        },
        {
          icon: IconNames.orders,
          menuTitle: translate('pageTitle_myOrders'),
          hasArrow: true,
          path: ['/my-orders', '/my-returns'],
          isDisabled: false,
        },
        {
          icon: IconNames.homeEdit,
          menuTitle: translate('profile.saved_addresses'),
          hasArrow: true,
          path: ['/saved-addresses'],
          isDisabled: false,
        },
        {
          icon: IconNames.chat_process_outline,
          menuTitle: translate('chat_preference_page.chat_preferences'),
          hasArrow: true,
          path: ['/chat-preferences'],
          isDisabled: false,
        },
      ],
    },
    {
      title: translate('settings.location_display'),
      menuItems: [
        {
          icon: IconNames.language,
          menuTitle: translate('settingPage_language'),
          hasArrow: true,
          path: ['/language'],
          isDisabled: false,
        },
        {
          icon: IconNames.mapMarker,
          menuTitle: translate('settings.changeCountry'),
          hasArrow: true,
          path: ['/country'],
          isDisabled: false,
        },
        {
          icon: IconNames.pace,
          menuTitle: translate('settings.time_zone'),
          hasArrow: true,
          path: ['/timezone'],
          isDisabled: false,
        },
        {
          icon: IconNames.cash,
          menuTitle: translate('settingPage_currency'),
          hasArrow: true,
          path: ['/currency'],
          isDisabled: false,
        },
        {
          icon: IconNames.moon,
          menuTitle: translate('dark_mode'),
          hasArrow: false,
          isDisabled: false,
        },
      ],
    },
    {
      title: translate('profile.security'),
      menuItems: [
        {
          icon: IconNames.blocked_account,
          menuTitle: translate('settings.blocked_accounts'),
          hasArrow: true,
          path: ['/blocked-accounts'],
          isDisabled: false,
        },
        ...(hide2FA
          ? []
          : [
              {
                icon: IconNames.twoFactorShield,
                menuTitle: translate('settings.two_factor_authentication'),
                hasArrow: true,
                path: ['/2fa'],
                isDisabled: false,
              },
            ]),
        {
          icon: IconNames.fileLock,
          menuTitle: translate('account_security.two_auth.privacy_policy'),
          hasArrow: true,
          path: ['/help/privacy-policy'],
          isDisabled: false,
        },
        {
          icon: IconNames.fileLock,
          menuTitle: translate('account_security.two_auth.terms_and_conditions'),
          hasArrow: true,
          path: ['/help/terms-and-conditions'],
          isDisabled: false,
        },
      ],
    },
    {
      title: translate('action_login'),
      menuItems: [
        {
          icon: IconNames.logoutNew,
          menuTitle: translate('common_sign_out'),
          hasArrow: false,
          isDisabled: false,
        },
      ],
    },
  ];
  return SETTINGS_DATA;
};
