import { gql } from '@apollo/client';

export const FIREBASE_QUERY = {
  generateFirebaseToken: gql`
    query fetchFirebaseToken {
      generateFirebaseToken {
        error_msg
        error_code
        is_successful
        data {
          token
        }
      }
    }
  `,
};
