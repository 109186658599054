import { DeliveryAddressInput } from '@fe-monorepo/models';
import { GeocodeResult } from 'use-places-autocomplete';

import { AddAddressValues, MainAddAddressProps } from '../ShopMainAddAddress';
import AddAddressFullAddress from './AddAddressFullAddress';
import AddAddressMapView from './AddAddressMapView';

interface AddAddressViewSectionProps {
  chosenSection: AddAddressValues;
  funcHandleDataSavePerSection: (results: GeocodeResult[], lat: number, lng: number) => void;
  saveLocationDetails?: DeliveryAddressInput;
  mainAddAddressClassStyles?: MainAddAddressProps;
}

const AddAddressViewSection = (props: AddAddressViewSectionProps) => {
  const { chosenSection, funcHandleDataSavePerSection, saveLocationDetails, mainAddAddressClassStyles } = props;
  const AddAddressSections = {
    mapView: (
      <AddAddressMapView
        saveLocationDetails={saveLocationDetails}
        funcHandleDataSavePerSection={funcHandleDataSavePerSection}
        mainAddAddressClassStyles={mainAddAddressClassStyles}
      />
    ),
    fullAddress: (
      <AddAddressFullAddress saveLocationDetails={saveLocationDetails} funcHandleDataSavePerSection={funcHandleDataSavePerSection} />
    ),
  };
  return <div>{AddAddressSections[chosenSection]}</div>;
};

export default AddAddressViewSection;
