import { useLazyQuery, useMutation } from '@apollo/client';
import {
    GAME_QUERY,
    TOURNAMENT_MUTATION,
    STAGE_QUERY,
    TOURNAMENT_QUERY,
    TOURNAMENTS_QUERY,
    TOURNAMENT_INFO_QUERY,
} from '@fe-monorepo/data-access';
import {
    GamesResponseModel,
    PlatformResponseModel,
    StageResponseModel,
    UpdateTournamentInput,
    TournamentMutationResponse,
    CreateTournamentInput,
    ResponseModel,
    TournamentQueryResponse,
    TournamentQueryResult,
    TournamentIdInput,
    TournamentIdParams,
    InfoTournamentInput,
    TmsGetAllTournamentDataInput,
} from '@fe-monorepo/models';

interface PlatformInput {
    game_code: string;
}

export const useTournament = () => {
    const [getAllGamesGql, { data: gamesData }] = useLazyQuery<GamesResponseModel>(GAME_QUERY.getAllGames, {
        errorPolicy: 'all',
    });

    const [getPlatformGql, { data: platformData }] = useLazyQuery<PlatformResponseModel>(GAME_QUERY.getPlatform, {
        errorPolicy: 'all',
    });

    const [getAllStageGql, { data: stageData }] = useLazyQuery<StageResponseModel>(STAGE_QUERY.getAllStage, {
        errorPolicy: 'all',
    });

    const [getTournamentInfoGql, { data: infoData }] = useLazyQuery<TournamentQueryResponse>(TOURNAMENT_QUERY.getTournamentInfo, {
        errorPolicy: 'all',
    });

    //Version 2 of getTournamentInfo
    const [getTournamentInfoGqlV2, { data: infoDataResult }] = useLazyQuery<TournamentQueryResult>(
        TOURNAMENT_INFO_QUERY.getTournamentInfo,
        {
            errorPolicy: 'all',
        },
    );

    const [createTournamentGql, { data: createData }] = useMutation<TournamentMutationResponse>(TOURNAMENT_MUTATION.createTournament, {
        errorPolicy: 'all',
    });

    const [updateTournamentGql, { data: updateData }] = useMutation<TournamentMutationResponse>(TOURNAMENT_MUTATION.updateTournament, {
        errorPolicy: 'all',
    });

    const [getAllTournamentsGql, { data: allTournamentData }] = useLazyQuery<TournamentQueryResponse>(
        TOURNAMENTS_QUERY.TmsGetAllTournaments,
        {
            errorPolicy: 'all',
        },
    );

    const getAllGames = async () => {
        const { data } = await getAllGamesGql();
        return data;
    };

    const getPlatform = async (platformInput: PlatformInput) => {
        const { data } = await getPlatformGql({
            variables: {
                details: platformInput,
            },
        });
        return data && data;
    };

    const getAllStage = async () => {
        const { data } = await getAllStageGql();
        return data && data;
    };

    const getTournamentInfo = async (infoTournamentInput: TournamentIdInput) => {
        const { data } = await getTournamentInfoGql({
            variables: {
                details: infoTournamentInput,
            },
        });
        return data?.getTournamentInfo;
    };

    //Version 2 of getTournamentInfo
    const getTournamentInformation = async (infoTournamentResult: TournamentIdParams) => {
        const { data } = await getTournamentInfoGqlV2({
            variables: {
                details: infoTournamentResult,
            },
        });
        return data?.getTournamentInfo;
    };

    const createTournament = async (params: CreateTournamentInput) => {
        const { data } = await createTournamentGql({ variables: { details: params } });
        return data?.createTournament;
    };

    const updateTournament = async (params: UpdateTournamentInput, file?: File) => {
        const { data } = await updateTournamentGql({ variables: { details: params, file } });
        return data?.updateTournament;
    };

    const getAllTournaments = async (getAllTournamentsInput: TmsGetAllTournamentDataInput) => {
        const { data } = await getAllTournamentsGql({ variables: { details: getAllTournamentsInput } });
        return data?.TmsGetAllTournaments;
    };

    return {
        games: gamesData,
        platform: platformData,
        stage: stageData && stageData,
        create: createData?.createTournament,
        update: updateData?.updateTournament,
        tournamentInfo: infoData,
        tournamentInfoData: infoDataResult,
        allTournamentData: allTournamentData?.TmsGetAllTournaments,
        getAllGames,
        getPlatform,
        getAllStage,
        createTournament,
        updateTournament,
        getTournamentInfo,
        getTournamentInformation,
        getAllTournaments,
    };
};
