import { convivaAppTracker, setUserId } from '@convivainc/conviva-js-appanalytics';
import {
  LinkClickTrackingPlugin,
  enableButtonClickTracking,
  enableLinkClickTracking,
} from '@convivainc/conviva-js-appanalytics-click-tracking';
import { ErrorTrackingPlugin, enableErrorTracking } from '@convivainc/conviva-js-appanalytics-error-tracking';
import { PerformanceTimingPlugin } from '@convivainc/conviva-js-appanalytics-performance-timing';
import { RootState } from '@fe-monorepo/store';
import { CONVIVA_APP_DETAILS } from '@fe-web/constant/constants';
import { getEnvironment } from '@fe-web/constant/environment';
import React, { createContext, useContext, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

// Create context
const ConvivaAppContext = createContext<any>(null);

// Provider component
export const ConvivaAppProvider = ({ children }: { children: React.ReactNode }) => {
  const convivaEnabled = useSelector((state: RootState) => state.app.convivaEnabled);
  const { convivaAppTracker: convivaConfig } = getEnvironment();
  const isConvivaInitialized = useRef(false);

  const loadConvivaSDK = async () => {
    try {
      await new Promise(() => {
        const script = document.createElement('script');
        script.src = '../../conviva-core-sdk.js';
        script.async = true;
        script.onload = () => {
          initConvivaAppTracker();
        };
        script.onerror = error => {
          console.error('Failed to load Conviva SDK:', error);
        };
        document.body.appendChild(script);
      });
    } catch (error) {
      console.error('Error checking CMS settings for Conviva:', error);
    }
  };

  const initConvivaAppTracker = () => {
    convivaAppTracker({
      appId: CONVIVA_APP_DETAILS.id,
      convivaCustomerKey: convivaConfig.customerKey,
      appVersion: CONVIVA_APP_DETAILS.version,
      contexts: { performanceTiming: true },
      plugins: [PerformanceTimingPlugin(), ErrorTrackingPlugin(), LinkClickTrackingPlugin()],
    });
    isConvivaInitialized.current = true;
    enableLinkClickTracking();
    enableButtonClickTracking();
    enableErrorTracking();
  };

  const setIdentity = (userId: string) => {
    if (!convivaEnabled) return;
    setUserId(userId);
  };

  useEffect(() => {
    if (!convivaEnabled || isConvivaInitialized.current) {
      return;
    }
    loadConvivaSDK();
  }, [convivaEnabled]);

  const contextValue = React.useMemo(() => ({ setIdentity }), [setIdentity]);

  return <ConvivaAppContext.Provider value={contextValue}>{children}</ConvivaAppContext.Provider>;
};

// Custom hook to use context
export const useConviva = () => {
  const context = useContext(ConvivaAppContext);
  if (!context) {
    throw new Error('useConviva must be used within a ConvivaProvider');
  }
  return context;
};
