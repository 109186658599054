import styled from 'styled-components';
import tw from 'twin.macro';

const StyledContainer = styled.div`
  ${tw`
        text-secondary
        flex
        flex-col
        gap-[3.25rem]
        pt-[2rem] 
        pb-0
        SD:px-[7.5rem]
    `}
`;

const StyledHeader = styled.div`
  ${tw`
        flex
        flex-col
        justify-between
        px-[1.25rem]
        SD:flex-row
        SD:px-0
    `}
`;

const StyledButton = styled.button`
  ${tw`
        relative
        border
        border-secondary
        font-medium
        text-fs-body
        text-secondary
        px-[1.5rem]
        py-[0.5rem]
        rounded-[0.125rem]
        overflow-hidden
        transition
        transition-all
        duration-[600ms]
        z-1
        rtl:before:translate-x-[100%]
    `}

  &:hover {
    ${tw`
        text-primary
    `}
    &::before {
      ${tw`translate-x-[0%]`}
    }
  }

  &::before {
    ${tw`
        content-['']
        absolute
        left-0
        top-0
        w-full
        h-full
        bg-secondary
        translate-x-[-100%]
        transition
        transition-all
        duration-[600ms]
        z-[-1]
    `}
  }
`;

const StyledButtonPrimary = styled.button`
  ${tw`
        relative
        block
        border
        border-sunset
        bg-sunset
        font-medium
        text-fs-body
        text-white100
        px-[1.5rem]
        py-[0.5rem]
        rounded-[0.125rem]
        overflow-hidden
        transition
        transition-all
        duration-[600ms]
        z-1
        rtl:before:translate-x-[110%]
    `}

  &:hover {
    ${tw`
        text-sunset
    `}
    &::before {
      ${tw`translate-x-[0%]`}
    }
  }

  &::before {
    ${tw`
        content-['']
        absolute
        left-0
        top-0
        w-full
        h-full
        bg-white100
        translate-x-[-110%]
        transition
        transition-all
        duration-[600ms]
        z-[-1]
    `}
  }
`;

const StyledH1 = styled.h1`
  ${tw`
    font-medium
    text-fs-big-title-sm
    SD:text-fs-big-title
    `}
`;

const StyledBody = styled.div`
  ${tw`
    SD:grid
    SD:grid-cols-playerSelectionLayout
    `}
`;

export { StyledBody, StyledButton, StyledButtonPrimary, StyledContainer, StyledH1, StyledHeader };
