import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { useRef } from 'react';

interface RadioProps extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {}

const Radio = (props: RadioProps) => {
  const elementRef = useRef<HTMLInputElement>(null);

  return (
    <div className="inline-flex">
      <button onClick={() => elementRef?.current?.click()}>
        <ResponsiveIcon
          name={elementRef?.current?.checked ? IconNames.radioCheck : IconNames.radioUncheck}
          baseWidth={24}
          baseHeight={24}
          iconClasses={elementRef?.current?.checked ? 'fill-sunset' : 'fill-otherGray'}
        />
      </button>
      <input {...props} type="radio" ref={elementRef} className="absolute w-0 h-0 opacity-0 pointer-events-none" />
    </div>
  );
};

export default Radio;
