import { DELETED_BY_ADMIN_IDENTIFIER } from '@fe-monorepo/helper';
import { Chat, ChatGlobalVariable } from '@fe-monorepo/models';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
import { ZIM, ZIMConversation, ZIMMediaMessage, ZIMMessage } from 'zego-zim-web';

type FollowersFollowingModel = {
  isFollowersFollowingOpen: boolean;
  initialTab: string;
};

type SliceState = {
  followersFollowing: FollowersFollowingModel;
  zegoInstance?: ZIM;
  openChats: Chat[];
  chat: ChatGlobalVariable;
  conversationList: ZIMConversation[];
  messages: ZIMMessage[];
};

const initialState: SliceState = {
  followersFollowing: {
    isFollowersFollowingOpen: false,
    initialTab: 'following',
  },
  openChats: [],
  chat: {
    chat_type: 'single',
    conversation_id: '',
    name: '',
  },
  conversationList: [],
  messages: [],
};

type SetMessagePayload = {
  conversationID: string;
  messages: ZIMMessage[];
};

type NewMessagePayload = {
  message: ZIMMessage | ZIMMediaMessage;
  fromSelf?: boolean;
};

type ChatNotificationPayload = {
  conversationID: string;
  action: 'mute' | 'unmute';
};

type UpdateConversationPayload = {
  conversationID: string;
  action: 'avatar' | 'group_name';
  value: string;
};

export const connectSlice = createSlice({
  name: 'connect',
  initialState: initialState,
  reducers: {
    setFollowersFollowing: (state, action: PayloadAction<FollowersFollowingModel>) => {
      state.followersFollowing = action.payload;
    },
    // setZegoInstance: (state, action: PayloadAction<ZIM>) => {
    //     state.zegoInstance = action.payload;
    // },
    // openChat: (state, action: PayloadAction<Chat>) => {
    //     state.openChats = [...state.openChats, action.payload];
    // },
    // closeChat: (state, action: PayloadAction<{ index: number }>) => {
    //     state.openChats = state.openChats.filter((_, i) => i != action.payload.index);
    // },
    // closeAllChat: state => {
    //     state.openChats = state.openChats = [];
    // },
    setChatGlobalVariable: (state, action: PayloadAction<ChatGlobalVariable>) => {
      state.chat = action.payload;
    },
    setConversations: (state, action: PayloadAction<ZIMConversation[]>) => {
      state.conversationList = action.payload.sort((a, b) => a?.orderKey - b?.orderKey);
    },
    addNewConversation: (state, action: PayloadAction<ZIMConversation>) => {
      state.conversationList = [...state.conversationList, action.payload];
    },
    updateConversation: (state, action: PayloadAction<UpdateConversationPayload>) => {
      let conversationAvatarUrl: string;
      let conversationName: string;
      if (action.payload.action === 'avatar') {
        conversationAvatarUrl = action.payload.value;
      }
      if (action.payload.action === 'group_name') {
        conversationName = action.payload.value;
      }
      state.conversationList = state.conversationList.map(conversation => {
        return conversation.conversationID === action.payload.conversationID
          ? {
              ...conversation,
              conversationAvatarUrl: conversationAvatarUrl || conversation.conversationAvatarUrl,
              conversationName: conversationName || conversation.conversationName,
            }
          : conversation;
      });
    },
    updateReadConversation: (state, action: PayloadAction<string>) => {
      state.conversationList = state.conversationList.map(conversation =>
        conversation.conversationID === action.payload ? { ...conversation, unreadMessageCount: 0 } : conversation,
      );
      state.messages = state.messages.map(msg =>
        msg.conversationID === action.payload && msg.receiptStatus === 1 ? { ...msg, receiptStatus: 2 } : msg,
      );
    },
    updateConversationLastMessage: (state, action: PayloadAction<NewMessagePayload>) => {
      state.conversationList = state.conversationList.map(conversation =>
        conversation.conversationID === action.payload.message.conversationID
          ? {
              ...conversation,
              orderKey: (_.findLast(_.orderBy(state.conversationList, conversation => conversation?.orderKey, 'asc'))?.orderKey || 0) + 1,
              unreadMessageCount: action.payload?.fromSelf ? 0 : conversation.unreadMessageCount + 1,
              lastMessage: action.payload.message,
            }
          : conversation,
      );
    },
    updateConversationNotification: (state, action: PayloadAction<ChatNotificationPayload>) => {
      state.conversationList = state.conversationList.map(conversation => {
        return conversation.conversationID === action.payload.conversationID
          ? {
              ...conversation,
              notificationStatus: action.payload?.action === 'mute' ? 2 : 1,
            }
          : conversation;
      });
    },
    removeConversation: (state, action: PayloadAction<string>) => {
      state.conversationList = state.conversationList.filter(conversation => conversation.conversationID !== action.payload);
    },
    setChatHistory: (state, action: PayloadAction<SetMessagePayload>) => {
      const cleanMessages = state.messages?.filter(msg => msg?.conversationID !== action.payload.conversationID) || [];
      const lastMessages = state.messages?.filter(msg => msg?.conversationID === action.payload.conversationID) || [];
      const newMessages = action.payload.messages?.filter(
        msg => !lastMessages.find(lastMessage => lastMessage.messageID === msg.messageID),
      );
      //state.messages = [...cleanMessages, ...lastMessages, ...newMessages];
      /** This causes duplication of messages  */

      const messages = action.payload.messages?.filter(msg => msg?.conversationID === action?.payload?.conversationID);
      state.messages = messages;
    },
    addNewChat: (state, action: PayloadAction<NewMessagePayload>) => {
      state.messages = [
        ...state.messages,
        action.payload?.fromSelf ? { ...action.payload.message, sentStatus: 0 } : action.payload.message,
      ];
    },
    updateChatStatus: (state, action: PayloadAction<ZIMMessage>) => {
      state.messages = state.messages.map(msg => {
        if (msg?.localExtendedData === action?.payload?.localExtendedData) {
          return action.payload;
        }
        return msg;
      });
    },
    clearChat: (state, action: PayloadAction<string>) => {
      const cleanMessages = state.messages?.filter(msg => msg.conversationID !== action.payload) || [];
      state.conversationList = state.conversationList.map(conversation =>
        conversation.conversationID === action.payload
          ? {
              ...conversation,
              lastMessage: conversation.lastMessage ? { ...conversation.lastMessage, timestamp: Date.now(), type: 0 } : undefined,
            }
          : conversation,
      );
      state.messages = cleanMessages;
    },
    clearAll: () => {
      initialState;
    },
    deleteForSelfMessage: (state, action: PayloadAction<ZIMMessage>) => {
      state.messages = state.messages.map(msg => {
        if (msg.messageID === action.payload.messageID) {
          return { ...msg, localExtendedData: 'deletedForSelf' };
        }
        return msg;
      });
    },
    setRecallMessage: (state, action: PayloadAction<ZIMMessage>) => {
      state.messages = state.messages.map(msg => {
        if (msg.messageID === action.payload.messageID) {
          if (action.payload.message === DELETED_BY_ADMIN_IDENTIFIER) {
            return { ...msg, type: 2, message: `${DELETED_BY_ADMIN_IDENTIFIER} ${msg.message}` };
          }

          return { ...msg, type: 2 };
        }
        return msg;
      });
    },
    clearRevokedMessages: state => {
      state.messages = state.messages?.map(msg => {
        if (msg.type === 2) {
          return { ...msg, type: 31 };
        }
        return msg;
      });
    },
  },
});

export const {
  setFollowersFollowing,
  setChatGlobalVariable,
  setConversations,
  addNewConversation,
  updateConversation,
  removeConversation,
  setChatHistory,
  addNewChat,
  updateChatStatus,
  clearChat,
  updateConversationLastMessage,
  updateReadConversation,
  clearAll,
  deleteForSelfMessage,
  setRecallMessage,
  updateConversationNotification,
  clearRevokedMessages,
} = connectSlice.actions;
