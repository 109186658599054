//dummy data tailored
import { IMAGES } from '@fe-monorepo/assets';

import { AppRoutes } from '../../app/app.routes.enum';

export const TAILORED_DUMMY_DATA = {
  title: {
    en: 'Shopping tailored to you',
    ar: 'أضف إلى السلّة',
  },
  info: [
    {
      src: IMAGES.Icon_Shipping,
      title: {
        en: 'Delivery',
        ar: 'التوصيل',
      },
      description: {
        en: 'Enjoy one-day delivery from stc play, free delivery, and pickup',
        ar: 'استمتع بتوصيل خلال يوم واحد من متجر stc play، و بدون رسوم، واستلام بكل سهولة.',
      },
    },
    {
      src: IMAGES.Icon_PriceTag,
      title: {
        en: 'STC Points',
        ar: 'نقاط stc ',
      },
      description: {
        en: 'Trade in your gaming gear. Get stc points toward a new one.',
        ar: 'تبادل عدّة لعبك وجمّع نقاط stc للعدّة الجديدة',
      },
    },
    {
      src: IMAGES.Icon_Bill_v2,
      title: {
        en: 'Installments',
        ar: 'قسّطها',
      },
      description: {
        en: 'Pay in full or pay over time. Your choice.',
        ar: 'ادفع مرة وحدة أو قسّطها زي ما تبي.',
      },
    },
    {
      src: IMAGES.Icon_Box,
      title: {
        en: 'Returns',
        ar: 'الاسترجاع',
      },
      description: {
        en: 'Free returns and buyer protection program.',
        ar: 'رسوم استرجاع مجانية بالإضافة إلى برنامج حماية المشتري',
      },
    },
  ],
};

export const SHOP_CART_PAGES = ['/shop/cart', '/settings/my-orders', '/shop/checkout'];
export const SHOP_CART_PAGES_NO_UPPER_FOOTER = ['/shop/cart', '/shop/checkout'];
export const FOOTER_HIDE_SECTION = ['/shop/wishlist', '/shop/cart', '/settings/my-returns', '/play/matchmaking', '/contact-us'];

export enum SHOP_PAGES {
  cart = '/shop/cart',
  myorders = '/settings/my-orders',
}

export const LIST_OF_SHOP_PAGES = [AppRoutes.myorders as string, AppRoutes.myreturns as string];

export const BITS_DEFAULT_PAGN = {
  limit: 6,
  offset: 1,
};

export const DUEL_TYPE = 'duel';

export const GUEST_REMINDER_EXCLUDED_PATHS = ['/auth', '/shop', '/bits', '/bits-play']; // guest reminder dialog not needed for auth and shop pages
export const HARD_POPUP_EXCLUDE_PATHS = ['/auth', '/help']; // hard popup dialog not needed for auth pages
export const APP_STORE_LINKS = {
  iosXplay: 'https://apps.apple.com/us/app/stc-play/id1558331986',
  androidXplay: 'https://play.google.com/store/apps/details?id=com.stc.xplay&hl=en&gl=US',
};
