function RepostIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4.16667 8.33307V9.99974H2.5L2.5 8.33307C2.5 6.03188 4.36548 4.1664 6.66667 4.1664H12.9882L11.0774 2.25566L12.2559 1.07715L16.1785 4.99974L12.2559 8.92233L11.0774 7.74381L12.9882 5.83307H6.66667C5.28595 5.83307 4.16667 6.95236 4.16667 8.33307Z"
        fill="var(--secondary-black)"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M15.8333 11.6664V9.99974H17.5V11.6664C17.5 13.9676 15.6345 15.8331 13.3333 15.8331H7.01184L8.92259 17.7438L7.74408 18.9223L3.82149 14.9997L7.74408 11.0771L8.92259 12.2557L7.01184 14.1664L13.3333 14.1664C14.714 14.1664 15.8333 13.0471 15.8333 11.6664Z"
        fill="var(--secondary-black)"
      />
    </svg>
  );
}

export default RepostIcon;
