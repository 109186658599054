import { RootState } from '@fe-monorepo/store';
import { useSelector } from 'react-redux';


export const useShopConfig = () => {
  const shopConfig = useSelector((state: RootState) => state.app.shopConfig);

  const isShopDisabled = shopConfig?.disabled;

  const isLMDHidden = isShopDisabled || shopConfig?.lmdHidden;
  const isHomeSectionHidden = isShopDisabled || shopConfig?.homeSectionHidden;
  const isTailoredSectionHidden = isShopDisabled || shopConfig?.tailoredHidden;
  const isFooterLinkHidden = isShopDisabled || shopConfig?.footerLinkHidden;
  const isCartHidden = isShopDisabled || shopConfig?.cartHidden;
  const isWishlistHidden = isShopDisabled || shopConfig?.wishlistHidden;
  const isWalletHidden = isShopDisabled || shopConfig?.walletHidden;
  const isOrdersHidden = isShopDisabled || shopConfig?.ordersHidden;

  const isHelpShoppingHidden = isShopDisabled || shopConfig?.help?.shoppingHidden;
  const isHelpExchangeHidden = isShopDisabled || shopConfig?.help?.exchangeHidden;

  return {
    isShopDisabled,
    isLMDHidden,
    isHomeSectionHidden,
    isTailoredSectionHidden,
    isFooterLinkHidden,
    isCartHidden,
    isWishlistHidden,
    isWalletHidden,
    isOrdersHidden,
    isHelpShoppingHidden,
    isHelpExchangeHidden,
  };
};

export type UseShopConfigReturn = ReturnType<typeof useShopConfig>;