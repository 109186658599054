import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_INVOICE_INFO_QUERY, INVOICE_MUTATION } from '@fe-monorepo/data-access';
import { ASSETS_LINK, getAssetsLink, replaceString } from '@fe-monorepo/helper';
import { ClearInvoiceInput, InvoiceInfoModel, InvoiceMutationResponse, OrderInvoiceInput } from '@fe-monorepo/models';
import { RootState, setInvoice } from '@fe-monorepo/store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

interface GetInvoiceInfoResponse {
  getInvoiceInfo: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: InvoiceInfoModel;
  };
}

interface GetInvoiceInfoInput {
  details: { invoice_number: string };
}

export const useInvoice = (saveState = false) => {
  const invoiceInfo = useSelector((state: RootState) => state.cart.invoice);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateInvoiceLoading, setIsCreateInvoiceLoading] = useState(false);
  const [isClearInvoiceLoading, setIsClearInvoiceLoading] = useState(false);
  const [getInfo, { data: info, error: infoError }] = useLazyQuery<GetInvoiceInfoResponse, GetInvoiceInfoInput>(
    GET_INVOICE_INFO_QUERY.getInvoiceInfo,
    {
      errorPolicy: 'all',
    },
  );
  const [createInvoiceGql, { data: createInvoiceData }] = useMutation<InvoiceMutationResponse>(INVOICE_MUTATION.createInvoice, {
    errorPolicy: 'all',
  });
  const [clearInvoiceGql, { data: clearInvoiceData }] = useMutation<InvoiceMutationResponse>(INVOICE_MUTATION.clearInvoice, {
    errorPolicy: 'all',
  });

  const getInvoice = async (params: GetInvoiceInfoInput) => {
    setIsLoading(true);
    const { data } = await getInfo({
      variables: {
        ...params,
      },
    });
    setIsLoading(false);
    return data?.getInvoiceInfo;
  };

  const createInvoice = async (orderInvoiceInput: OrderInvoiceInput) => {
    setIsCreateInvoiceLoading(true);
    const { data } = await createInvoiceGql({ variables: { details: orderInvoiceInput } });
    setIsCreateInvoiceLoading(false);
    return data?.createInvoice;
  };

  const clearInvoice = async (clearInvoiceInput: ClearInvoiceInput) => {
    setIsClearInvoiceLoading(true);
    const { data } = await clearInvoiceGql({ variables: { details: clearInvoiceInput } });
    setIsClearInvoiceLoading(false);
    return data?.clearInvoice;
  };

  const downloadInvoice = (envCode: string, invoice_number?: string) => {
    const replacedString = replaceString(`${getAssetsLink(envCode)}${ASSETS_LINK.stcPlayInvoice}`, {
      invoice_number: invoice_number ? invoice_number : invoiceInfo?.invoice_number,
    });
    return replacedString;
  };

  useEffect(() => {
    if (createInvoiceData?.createInvoice && createInvoiceData?.createInvoice.is_successful) {
      getInvoice({ details: { invoice_number: createInvoiceData?.createInvoice?.data?.invoice_number } });
    }
  }, [createInvoiceData?.createInvoice]);

  useEffect(() => {
    if (saveState && info?.getInvoiceInfo?.data?.info) {
      dispatch(setInvoice(info?.getInvoiceInfo?.data?.info));
    }
  }, [info?.getInvoiceInfo?.data?.info]);

  // useEffect(() => {
  //     if (!isCreateInvoiceLoading && !isClearInvoiceLoading) {
  //         const invoiceNumber = invoiceInfo?.invoice_number;
  //         if (typeof invoiceNumber !== 'string') return;
  //         getInvoice({ details: { invoice_number: invoiceNumber || '' } });
  //     }
  // }, [isCreateInvoiceLoading, isClearInvoiceLoading]);

  return {
    getInvoice,
    createInvoice,
    clearInvoice,
    setIsLoading,
    isLoading,
    isCreateInvoiceLoading,
    isClearInvoiceLoading,
    invoiceInfo,
    createInvoiceData: createInvoiceData?.createInvoice,
    clearInvoiceData: clearInvoiceData?.clearInvoice,
    invoiceInfoData: info?.getInvoiceInfo,
    downloadInvoice,
  };
};
