import { DEFAULT_LOCATION, IconNames, Logger } from '@fe-monorepo/helper';
import { useGoogleMaps, useTranslate } from '@fe-monorepo/hooks';
import { DeliveryAddressInput } from '@fe-monorepo/models';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import { Combobox } from '@headlessui/react';
import { GoogleMap, Libraries, Marker, useJsApiLoader } from '@react-google-maps/api';
import { useEffect, useMemo, useState } from 'react';
import usePlacesAutocomplete, { GeocodeResult, getGeocode, getLatLng } from 'use-places-autocomplete';

import { MainAddAddressProps } from '../ShopMainAddAddress';
import MapHighlightResultList from './MapHighlightResultList';

const libraries = ['places'] as Libraries;

interface AddAddressMapViewProps {
  //addressForUpdate?: DeliveryAddressModel;
  funcHandleDataSavePerSection: (results: GeocodeResult[], lat: number, lng: number) => void;
  saveLocationDetails?: DeliveryAddressInput;
  mainAddAddressClassStyles?: MainAddAddressProps;
}

const AddAddressMapView = ({ funcHandleDataSavePerSection, saveLocationDetails, mainAddAddressClassStyles }: AddAddressMapViewProps) => {
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: 'AIzaSyCruNScWozL8c56MmExmqpgikFeTl7m2bs',
    libraries: libraries as Libraries,
  });
  const {
    ready,
    value,
    setValue,
    suggestions: { status, data },
    clearSuggestions,
  } = usePlacesAutocomplete();
  const { getReverseGeocode, address: addressFromMarker } = useGoogleMaps();
  const [shouldMapCollapse, setShouldMapCollapse] = useState<boolean>(false);
  const { translate } = useTranslate();

  const centerPin = useMemo(
    () => ({
      lat: saveLocationDetails?.location_lat || DEFAULT_LOCATION.latitude,
      lng: saveLocationDetails?.location_long || DEFAULT_LOCATION.longitude,
    }),
    [saveLocationDetails],
  );

  const [isMapCollapsed, setIsMapCollapsed] = useState<boolean>(false);
  const { error } = Logger();
  const mapOptions = {
    disableDefaultUI: true,
  };
  let clickTimer: any;

  const handleGetLocationOnMapClick = (event: google.maps.MapMouseEvent) => {
    clickTimer = setTimeout(() => {
      const latValue = event.latLng ? event.latLng?.lat() : 0;
      const lngValue = event.latLng ? event.latLng?.lng() : 0;
      getReverseGeocode(latValue, lngValue);
    }, 200);
  };

  const handleGetLocationOnMapDblClick = (event: google.maps.MapMouseEvent) => {
    clearTimeout(clickTimer);
  };

  const handleSetLocationChange = async (address: string) => {
    setValue(address, false);
    clearSuggestions();
    await getGeocode({ address })
      .then(response => {
        const { lat, lng } = getLatLng(response[0]);
        //Call function to set location data to save
        funcHandleDataSavePerSection(response, lat, lng);
      })
      .catch(err => {
        error('ERROR in fetching map location!', err);
      });
  };

  const handleMarkerOnDragEnd = (event: google.maps.MapMouseEvent) => {
    const latValue = event.latLng ? event.latLng?.lat() : 0;
    const lngValue = event.latLng ? event.latLng?.lng() : 0;
    getReverseGeocode(latValue, lngValue);
  };

  const handleOnClear = () => {
    setValue('', false);
    clearSuggestions();
  };

  const handleSearchBarPinIconClick = () => {
    setShouldMapCollapse(true);
  };

  useEffect(() => {
    if (addressFromMarker) {
      handleSetLocationChange(addressFromMarker);
    }
  }, [addressFromMarker]);

  useEffect(() => {
    if (saveLocationDetails) {
      setValue(saveLocationDetails.address ? saveLocationDetails.address : '', false);
    }
  }, []);

  useEffect(() => {
    if (typeof saveLocationDetails?.location_lat === 'number' && typeof saveLocationDetails?.location_long === 'number') {
      getReverseGeocode(saveLocationDetails?.location_lat, saveLocationDetails?.location_long);
    }
  }, [saveLocationDetails]);

  if (!isLoaded)
    return (
      <div className="flex items-center justify-center text-sunset text-body font-normal w-[100%] h-[20rem] rounded gap-[0.25rem] py-1 px-1">
        Loading Map...
      </div>
    );

  return (
    <div>
      <GoogleMap
        zoom={10}
        center={centerPin}
        mapContainerClassName={`${isMapCollapsed ? 'h-[4.375rem]' : 'h-[20rem]'} ${
          mainAddAddressClassStyles?.tabMapClassnames?.mapContainerClassName
        } w-[100%] rounded gap-[0.25rem] py-1 px-1`}
        options={mapOptions}
        onClick={handleGetLocationOnMapClick}
        onDblClick={handleGetLocationOnMapDblClick}
      >
        <div
          id={'MAP_INPUTBOX_CONTAINER'}
          className={`${mainAddAddressClassStyles?.tabMapClassnames?.mapInputBoxContainer ?? `w-[90%]`}
            relative flex flex-col rounded bg-white z-[5]
            mt-[0.5rem] ml-[1rem]
            h-[2.375rem]`}
        >
          <div
            id={'MAP_INPUTBOX_INNER_CONTAINER'}
            className={`${mainAddAddressClassStyles?.tabMapClassnames?.mapInputBoxInnerContainer ?? `SD:w-[34.688rem]`}
              flex h-[2.375rem] px-3 py-2 gap-[0.75rem] items-center`}
          >
            <ResponsiveIcon
              className="flex items-center justify-center"
              name={IconNames.iconPinLocationLight}
              baseHeight={20}
              baseWidth={20}
              onClick={handleSearchBarPinIconClick}
            />
            <div id="GOOGLE_MAPS_SEARCH_BOX_CONTAINER" className="relative flex flex-col w-[70%]">
              <Combobox value={value} onChange={handleSetLocationChange}>
                <Combobox.Input
                  value={value}
                  onChange={e => setValue(e.target.value)}
                  className={`${
                    mainAddAddressClassStyles?.tabMapClassnames?.mapComboBoxInput ?? `w-[90%]`
                  } h-[1.25rem] text-fs-body-small font-regular bg-transparent`}
                  placeholder={translate(`shop.cart.shop_add_address_modal_page.placeholder_search_by_address_or_short_address`) ?? ''}
                />
                <div id="COMBOBOX_OPTIONS_CONTAINER" className="absolute mt-[1.5rem] ml-[-2.75rem]">
                  <Combobox.Options
                    className={`${
                      mainAddAddressClassStyles?.tabMapClassnames?.mapComboBoxOptionsContainer ?? `SD:w-[34.688rem]`
                    } bg-white rounded gap-[0.25rem] py-2 px-2 mt-[0.938rem]`}
                  >
                    {status === 'OK' &&
                      data.map(({ place_id, description }) => (
                        <Combobox.Option key={place_id} value={description} className={'font-normal text-fs-body h-[1.5rem]'}>
                          <MapHighlightResultList valueToHighlight={value} location={description} />
                        </Combobox.Option>
                      ))}
                  </Combobox.Options>
                </div>
              </Combobox>
            </div>
            <HoverText
              className="w-[2.375rem] h-[1.25rem] font-regular text-sunset text-fs-body-small tracking-[-0.0125rem] leading-[1.25rem]"
              hover={{ color: 'text-sunset' }}
              alwaysShowUnderline={true}
              underlineStyle="bg-sunset h-[1px] 2K:h-[1.77px] 4K:h-[2.66px] 8K:h-[5.33px] bottom-2"
              text={translate('common_clear') ?? ''}
              onClick={() => handleOnClear()}
            />
          </div>
        </div>
        {saveLocationDetails && (
          <Marker
            draggable={true}
            onDragEnd={handleMarkerOnDragEnd}
            position={{ lat: saveLocationDetails?.location_lat || 0, lng: saveLocationDetails?.location_long || 0 }}
          />
        )}
      </GoogleMap>
    </div>
  );
};

export default AddAddressMapView;
