import { IMAGES } from '@fe-monorepo/assets';
import { useTranslate } from '@fe-monorepo/hooks';
import { RankingModel } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import Image from '@fe-web/Atoms/Media/Image';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import PlayerAvatar from '@fe-web/Molecules/PlayerAvatar';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

interface LeaderboardProps {
  ranking?: RankingModel[];
  userRank?: RankingModel;
  isMiniGame?: boolean;
}

const LeaderboardList = (props: LeaderboardProps) => {
  const { ranking, userRank, isMiniGame = false } = props;
  const { translate } = useTranslate();
  const user = useSelector((state: RootState) => state?.user.userContext);
  const language = useSelector((state: RootState) => state?.app.language);

  const headerTitle = !isMiniGame
    ? [translate('tournament_detail.place'), translate('tournament_detail.user')]
    : [translate('common_prize_rank'), translate('tournament_detail.user'), translate('mini_game.scores')];
  const navigate = useNavigate();

  const mapNumberToOrdinal = (rank: number, language: string) => {
    if (rank < 1 || !Number.isInteger(rank)) {
      return rank;
    }

    if (language !== 'en') {
      return rank;
    }

    let suffix = 'th';
    if (rank % 10 === 1 && rank % 100 !== 11) {
      suffix = 'st';
    } else if (rank % 10 === 2 && rank % 100 !== 12) {
      suffix = 'nd';
    } else if (rank % 10 === 3 && rank % 100 !== 13) {
      suffix = 'rd';
    }

    return rank + suffix;
  };

  return (
    <div className="inline-flex flex-col SD:w-[49.5625rem]">
      <div className="overflow-y-scroll h-[20.75rem] inline-flex flex-col gap-[0.5rem]">
        <div className="sticky bg-primary top-0 z-10 inline-flex flex-row py-[0.5rem] gap-[1.25rem]">
          {headerTitle.map((item, key) => (
            <div
              className={`font-regular text-fs-caption text-secondary/70 ${
                key === 0
                  ? 'min-w-[3.3125rem]'
                  : key === 1
                  ? 'SD:min-w-[13.875rem] ltr:mr-auto rtl:ml-auto'
                  : 'SD:w-full min-w-[3.5625rem] text-right SD:text-left'
              }`}
            >
              {item}
            </div>
          ))}
        </div>
        <div className="h-[0.0625rem] border-[0.05rem] border-secondary/10" />
        {ranking &&
          [...ranking].splice(0, 10).map((item, key) => (
            <>
              <div className="inline-flex w-full flex-row items-center gap-[1.25rem]">
                <div
                  className={`font-regular text-fs-caption text-secondary min-w-[3.3125rem] ${
                    language === 'ar' && item.rank !== 1 && item.rank !== 2 && item.rank !== 3 && 'pr-[0.5rem]'
                  }`}
                >
                  {item.rank === 1 || item.rank === 2 || item.rank === 3 ? (
                    <Image
                      img={item.rank === 1 ? IMAGES.GoldMedalImage : item.rank === 2 ? IMAGES.SilverMedalImage : IMAGES.BronzeMedalImage}
                      imgStyle="w-[1.5rem] h-[1.5rem]"
                      divStyle=""
                    />
                  ) : (
                    mapNumberToOrdinal(item.rank, language)
                  )}
                </div>
                <div className="SD:min-w-[13.875rem] ltr:mr-auto rtl:ml-auto inline-flex flex-row items-center gap-[1.25rem]">
                  <PlayerAvatar
                    avatarUrl={item.userAvatar ?? null} //AVATAR INTEGRATION
                    avatarSize={2.5}
                    avatarStyle={`${
                      key === 0 ? '!border-amber-300' : key === 1 ? '!border-gray-300' : key === 2 ? '!border-amber-800' : ''
                    }`}
                    dir="row"
                    hasDisplayName={false}
                    containerStyle=""
                    labelContainerStyle="hidden"
                  />
                  <HoverText
                    text={item.userName.charAt(0) !== '@' ? `@${item.userName}` : item.userName}
                    className="font-regular text-fs-body-small"
                    textClassName="text-secondary/70"
                    hover={{ color: 'text-secondary' }}
                    underlineStyle="bg-secondary h-[0.0625rem]"
                    onClick={() => navigate(`/users/${item.userName}`)}
                  />
                </div>
                {isMiniGame && (
                  <div className="SD:w-full min-w-[3.5625rem] text-right SD:text-left font-regular text-fs-caption text-secondary">
                    {item.score}
                  </div>
                )}
              </div>
              <div className="h-[0rem] border-[0.05rem] border-secondary/10" />
            </>
          ))}
      </div>
      {user.username && userRank && (
        <div className="relative pt-[0.25rem]">
          <div className="absolute ltr:left-[4.375rem] rtl:right-[4.375rem] top-0 w-[48.375rem] h-[3.125rem] opacity-40 ltr:bg-gradient-to-r rtl:bg-gradient-to-l from-moonlight SD:to-70% to-35% rounded-[1.6875rem]" />
          <div className="inline-flex w-full flex-row items-center gap-[1.25rem]">
            <div className={`font-regular text-fs-caption text-secondary min-w-[3.3125rem] ${language === 'ar' && 'pr-[0.5rem]'}`}>
              {mapNumberToOrdinal(userRank?.rank, language)}
            </div>
            <div className="SD:min-w-[13.875rem] ltr:mr-auto rtl:ml-auto inline-flex flex-row items-center gap-[1.25rem]">
              <PlayerAvatar
                avatarUrl={user.avatar_url ?? null} //AVATAR INTEGRATION
                avatarSize={2.5}
                avatarStyle="!border-moonlight !border-[0.125rem]"
                dir="row"
                hasDisplayName={false}
                containerStyle=""
                labelContainerStyle="hidden"
              />
              <HoverText
                text={user.username.charAt(0) !== '@' ? `@${user.username}` : user.username}
                className="font-regular text-fs-body-small"
                textClassName="text-secondary/70"
                hover={{ color: 'text-secondary' }}
                underlineStyle="bg-secondary h-[0.0625rem]"
                onClick={() => navigate(`/users/${user.username}`)}
              />
            </div>
            <div className="SD:w-full min-w-[3.5625rem] text-right SD:text-left font-regular text-fs-caption text-secondary">
              {userRank?.score}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaderboardList;
