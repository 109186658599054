import { DeliveryAddressModel } from '@fe-monorepo/models';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type SliceState = {
  deliveryAddress: DeliveryAddressModel[];
};

const initialState: SliceState = {
  deliveryAddress: [],
};

export const deliveryAddressSlice = createSlice({
  name: 'deliveryAddress',
  initialState,
  reducers: {
    clearDeliveryAddress: state => {
      state.deliveryAddress = [];
    },
    setDeliveryAddress: (state, action: PayloadAction<DeliveryAddressModel[]>) => {
      state.deliveryAddress = action.payload;
    },
    deleteDeliveryAddress: (state, action: PayloadAction<number>) => {
      state.deliveryAddress = state.deliveryAddress.filter(address => address.user_address_id !== action.payload);
    },
  },
});

export const { clearDeliveryAddress, setDeliveryAddress, deleteDeliveryAddress } = deliveryAddressSlice.actions;
