import { gql } from '@apollo/client';

export const TOURNAMENTS_QUERY = {
  TmsGetAllTournaments: gql`
    query TmsGetAllTournaments($details: TmsGetAllTournamentDataInput) {
      TmsGetAllTournaments(details: $details) {
        is_successful
        error_code
        error_msg
        app_code
        data {
          my_tournamanets {
            id
            discipline
            name
            full_name
            status
            scheduled_date_start
            scheduled_date_end
            timezone
            online
            public
            location
            country
            size
            platforms
            logo {
              logo_small
              logo_medium
              logo_large
              original
              id
            }
            registration_enabled
            registration_opening_datetime
            registration_closing_datetime
          }
          tournamanets {
            id
            discipline
            name
            full_name
            status
            scheduled_date_start
            scheduled_date_end
            timezone
            online
            public
            location
            country
            size
            platforms
            logo {
              logo_small
              logo_medium
              logo_large
              original
              id
            }
            registration_enabled
            registration_opening_datetime
            registration_closing_datetime
          }
        }
      }
    }
  `,
};
