import { gql } from '@apollo/client';

export const PRODUCT_DETAILED_QUERY = {
  getProductDetails: gql`
    query fetchProduct($details: ProductDetailsInput) {
      getProductDetails(details: $details) {
        is_successful
        data {
          name_ar
          name_en
          description_ar
          description_en
          product_code
          metadata
          brand {
            name_ar
            name_en
            collection_code
          }
          supplier
          is_digital
          lowest_actual_cost
          highest_actual_cost
          lowest_selling_price
          highest_selling_price
          options {
            name_en
            name_ar
            values {
              product_option_value_id
              name_en
              name_ar
            }
          }
          quantity
          variant_id
          sku
          barcode
          is_preorder
          taxable
          selling_price
          original_cost
          usage_ar
          usage_en
          weight
          media
        }
      }
    }
  `,
  getRelatedProducts: gql`
    query fetchRelatedProducts($details: RelatedProductsInput) {
      getRelatedProducts(details: $details) {
        is_successful
        error_msg
        error_code
        data {
          related_product {
            name_ar
            name_en
            description_ar
            description_en
            product_code
            metadata
            brand {
              name_ar
              name_en
              collection_code
            }
            lowest_actual_cost
            highest_actual_cost
            lowest_selling_price
            highest_selling_price
            last_cursor
            featured_img
          }
          more_product {
            name_ar
            name_en
            description_ar
            description_en
            product_code
            metadata
            brand {
              name_ar
              name_en
              collection_code
            }
            lowest_actual_cost
            highest_actual_cost
            lowest_selling_price
            highest_selling_price
            last_cursor
            featured_img
          }
        }
      }
    }
  `,
  getProductSpecification: gql`
    query fetchProductSpecifications($details: ProductSpecificationsInput) {
      getProductSpecification(details: $details) {
        is_successful
        error_msg
        error_code
        data {
          variant_id
          sku
          barcode
          specifications {
            name_ar
            name_en
            values {
              name_en
              name_ar
            }
          }
        }
      }
    }
  `,
};
