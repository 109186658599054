import { gql } from '@apollo/client';

const GET_INVOICE_INFO_QUERY = {
  getInvoiceInfo: gql`
    query fetchInvoiceInfo($details: GetInvoiceInfoInput) {
      getInvoiceInfo(details: $details) {
        data {
          info {
            order_id
            lmd_code
            invoice_number
            lang
            status_code
            payment_method_code
            cancel_reason_code
            is_paid
            is_sent
            is_checkout
            updated_at
            created_at
            amount_total
            promo_discount
            total_amount
            amount_sub_total
            amount_discount
            amount_tax
            amount_grand_total
            order_status_id
            name_en
            name_ar
            address_id
            billing_name
            billing_email
            billing_mobile
            billing_address
            billing_postal_code
            billing_longitude
            billing_latitude
            billing_city
            billing_country_code
            shipping_name
            shipping_email
            shipping_mobile
            shipping_address
            shipping_postal_code
            shipping_longitude
            shipping_latitude
            shipping_city
            shipping_country_code
            fulfilment_method_code
            fulfilment_digital_email
            fulfilment_digital_mobile
            fulfilment_digital_country_code
            wallet_discount
            status_en
            status_ar
            products {
              order_product_id
              product_code
              variant_id
              product_name
              description
              media
              weight
              SKU
              sku
              taxable
              is_digital
              is_active
              in_stock
              brand {
                name_en
                name_ar
                collection_code
              }
              seller
              qty
              unit_price
              unit_price_total_tax
              unit_price_grand_total
              discount_price
              subtotal
              total_tax
              grand_total
              is_delivered
            }
            shipments {
              shipment_status_en
              shipment_status_ar
              shipment_status
              reference_order_number
              created_at
              shipment_physical_id
              updated_at
              delivered_at
              product_name
              order_product_id
              reference_receipt_number
              sent_to
              is_digital
              sku
              products {
                serial_number
                tracking_number
                sku
                reference_shipment_physical_id
                reference_serial_value
              }
            }
            applied_promo_codes {
              name
              code
              percentage
              start_date
              end_date
            }
          }
        }
      }
    }
  `,
};

export { GET_INVOICE_INFO_QUERY };
