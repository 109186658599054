import { useLazyQuery } from '@apollo/client';
import { PARTICIPANTS_QUERY } from '@fe-monorepo/data-access';
import { useState } from 'react';

import { GetParticipantInput, GetParticipantsParams, GetParticipantsResponse } from './type';

export const useGetParticipants = () => {
  const [isSuccessful, setIsSuccessful] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  const [getParticipantsGql, { data: getParticipantsData, error: errorParticipants }] = useLazyQuery<
    GetParticipantsResponse,
    GetParticipantsParams
  >(PARTICIPANTS_QUERY.getAll, { errorPolicy: 'all' });

  const getParticipants = async (details: GetParticipantInput) => {
    setIsLoading(true);
    const response = await getParticipantsGql({ variables: { details } });
    setIsLoading(false);
    return response;
  };

  return {
    getParticipants,
    getParticipantsData: getParticipantsData?.tmsParticipantGetParticipants,
    isSuccessful,
    isLoading,
    error,
  };
};
