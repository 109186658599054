import { Element, HTMLReactParserOptions } from 'html-react-parser';
import { Link } from 'react-router-dom';

import { AppRoutes } from '../../app/app.routes.enum';

interface OptionList {
  id: string;
  route: string;
  className?: string;
}

// redirects for faq where id is the target of the parse
const faqRedirects: OptionList[] = [
  {
    id: '{{signin}}',
    route: AppRoutes.authSignIn,
  },
  {
    id: '{{createaccount}}',
    route: AppRoutes.authSignUp,
  },
  {
    id: '{{forgotpassword}}',
    route: AppRoutes.forgetpasswordRoute,
  },
  {
    id: '{{contactus}}',
    route: AppRoutes.contactUS,
  },
  {
    id: '{{viewprofile}}',
    route: AppRoutes.profile,
  },
  {
    id: '{{settings}}',
    route: AppRoutes.settings,
  },
  {
    id: '{{language}}',
    route: AppRoutes.settingsLanguage,
  },
  {
    id: '{{country}}',
    route: AppRoutes.settingsCountry,
  },
  {
    id: '{{timezone}}',
    route: AppRoutes.settingsTimezone,
  },
  {
    id: '{{currency}}',
    route: AppRoutes.settingsCurrency,
  },
  {
    id: '{{myprofile}}',
    route: AppRoutes.myprofile,
  },
  {
    id: '{{notification}}',
    route: AppRoutes.settings,
  },
  {
    id: '{{accountprivacy}}',
    route: AppRoutes.settingsAccountPrivacy,
  },
  {
    id: '{{twofactorauthentication}}',
    route: AppRoutes.settings2fa,
  },
  {
    id: '{{savedaddresses}}',
    route: AppRoutes.savedaddress,
  },
  {
    id: '{{addaccount}}',
    route: AppRoutes.authSignUp,
  },
  {
    id: '{{bits}}',
    route: AppRoutes.bits,
  },
  {
    id: '{{play}}',
    route: AppRoutes.tournaments,
  },
  {
    id: '{{games}}',
    route: AppRoutes.games,
  },
  {
    id: '{{matchmaking}}',
    route: AppRoutes.matchmaking,
  },
  {
    id: '{{shop}}',
    route: AppRoutes.shop,
  },
  {
    id: '{{myorders}}',
    route: AppRoutes.myorders,
  },
  {
    id: '{{chatcetting}}',
    route: AppRoutes.chat_preferences,
  },
  {
    id: '{{home}}',
    route: AppRoutes.home,
  },
  {
    id: '{{wallet}}',
    route: AppRoutes.walletSettings,
  },
];

const faqAnchorList = [
  {
    id: '{{tabby}}',
    route: 'https://tabby.ai/en-SA',
    className: 'text-sunset',
  },
];

// reusable function to generate the option of the parse function
const getOption = (list: OptionList[], paramReplace?: OptionList[], anchorList?: OptionList[]): HTMLReactParserOptions => {
  return {
    replace(domNode) {
      for (const data of list) {
        if (domNode instanceof Element && domNode.attribs && domNode.attribs[data.id] === '') {
          const replacement = [domNode.children[0]] as any;
          let route = data.route;
          const paramReplacement = paramReplace?.forEach(obj => {
            route = route.replace(obj.id, obj.route);
          });
          // RETURN REPLACEMENT
          return (
            <Link to={route} className={data.className || 'text-sunset'}>
              {replacement[0].data}
            </Link>
          );
        }
      }
      if (anchorList) {
        for (const data of anchorList) {
          if (domNode instanceof Element && domNode.attribs && domNode.attribs[data.id] === '') {
            const replacement = [domNode.children[0]] as any;
            let route = data.route;
            const paramReplacement = paramReplace?.forEach(obj => {
              route = route.replace(obj.id, obj.route);
            });
            // RETURN REPLACEMENT
            return (
              <a href={route} className={data.className || 'text-sunset'} target="_blank">
                {replacement[0].data}
              </a>
            );
          }
        }
      }
      return null;
    },
  };
};

export { faqAnchorList, faqRedirects, getOption, OptionList };
