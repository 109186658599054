import { useAccount, useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import PopoverAbstract from '@fe-web/Molecules/Popover/PopoverAbstract';
import { ReactNode, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

interface Props {
  chatType: 'group' | 'private';
  origin: 'side-bar-minimized' | 'side-bar-full' | 'full' | 'minimized' | 'preference';
  userType: 'admin' | 'regular';
  isIconDot?: boolean;
  isMuted?: boolean;
  onClickMute?: () => void;
  setOpenGroupPreferences?: () => void;
  setInviteUsers?: () => void;
  isChatEmpty?: boolean;
  setOptionActionType?: (type: 'clear' | 'block' | 'delete' | 'leave' | 'dismiss' | 'report') => void;
  setIsOptionOpened?: (value: boolean) => void;
  isUserAdmin?: boolean;
  setAdmin?: () => void;
  goToProfile?: () => void;
  onClickMoreOption?: () => void;
  openFullWindow?: () => void;
  isUserMember?: boolean;
  removeUser?: () => void;
  unblock?: (value: string) => void;
  checkBlocking?: boolean;
  returnCheckBlocking?: (value: boolean) => void;
  isBlocking?: boolean;
  conversationID?: string;
  handleInviteUser?: () => void;
  cta?: ReactNode;
}

const MoreOption = ({
  chatType,
  origin,
  userType,
  isIconDot,
  isMuted = false,
  onClickMute,
  setOpenGroupPreferences,
  isChatEmpty = true,
  setOptionActionType,
  goToProfile,
  onClickMoreOption,
  openFullWindow,
  isUserMember,
  removeUser,
  isBlocking,
  checkBlocking,
  returnCheckBlocking,
  conversationID,
  unblock,
  setInviteUsers,
  handleInviteUser,
  cta,
}: Props) => {
  const { translate } = useTranslate();
  const { getUserInfoByUsername } = useAccount();
  const [blockingStatus, setBlockingStatus] = useState<boolean>();
  const globalConfig = useSelector((state: RootState) => state.app.globalConfig);

  const toggleCheckBlocking = (isOpen: boolean) => {
    isOpen &&
      checkBlocking &&
      chatType === 'private' &&
      getUserInfoByUsername({ username: conversationID }).then(res => {
        res.data?.getInfoByUsername?.data?.is_blocking !== undefined && setBlockingStatus(res.data?.getInfoByUsername?.data?.is_blocking);
      });
  };

  useEffect(() => {
    returnCheckBlocking && blockingStatus && returnCheckBlocking(blockingStatus);
  }, [blockingStatus]);

  const OPTION_MOCK_DATA = [
    {
      menuLabel: translate('connect.more_options.open_full_window') ?? '',
      onClick: () => {
        openFullWindow && openFullWindow();
      }, //CHANGE ON INTEGRATION PASS CHAT ID
      properties: {
        chatType: ['group', 'private'],
        origin: ['side-bar-minimized', 'minimized'],
        userType: ['admin', 'regular'],
      },
    },
    {
      menuLabel: translate('connect.more_options.go_to_profile') ?? '',
      onClick: () => goToProfile && goToProfile(), // CHANGE ON INTEGRATION PASS username ${user?.username}
      properties: {
        chatType: ['private'],
        origin: ['side-bar-minimized', 'side-bar-full', 'full', 'minimized', 'preference'],
        userType: ['admin', 'regular'],
      },
    },
    {
      menuLabel: translate('connect.group_info_label'),
      onClick: () => {
        setOpenGroupPreferences && setOpenGroupPreferences();
      },
      properties: {
        chatType: ['group'],
        origin: ['side-bar-minimized', 'side-bar-full', 'full', 'minimized'],
        userType: ['admin', 'regular'],
      },
    },
    {
      menuLabel: translate('connect.more_options.invite_users'),
      onClick: origin === 'side-bar-full' ? () => handleInviteUser && handleInviteUser() : () => setInviteUsers && setInviteUsers(),
      properties: {
        chatType: ['group'],
        origin: ['side-bar-full', 'side-bar-minimized', 'full', 'minimized'],
        userType: ['admin'],
      },
    },
    {
      menuLabel: `${!isMuted ? translate('connect.more_options.mute') : translate('connect.more_options.unmute')}`,
      onClick: () => {
        onClickMute && onClickMute();
      },
      properties: {
        chatType: ['group'],
        origin: ['side-bar-minimized', 'side-bar-full', 'full', 'minimized'],
        userType: ['admin', 'regular'],
      },
    },
    {
      menuLabel: translate('connect.more_options.clear_chat'), //or Clear conversation??
      onClick: () => {
        setOptionActionType && setOptionActionType('clear');
      },
      properties: {
        chatType: ['group', 'private'],
        origin: ['side-bar-minimized', 'side-bar-full', 'full', 'minimized'],
        userType: ['admin', 'regular'],
      },
    },
    {
      menuLabel: translate('connect.more_options.report'),
      onClick: () => {
        setOptionActionType && setOptionActionType('report');
      },
      properties: {
        chatType: ['private'],
        origin: ['side-bar-minimized', 'side-bar-full', 'full', 'minimized', 'preference'],
        userType: ['admin', 'regular'],
      },
    },
    {
      menuLabel: isBlocking || blockingStatus ? translate('action_unblock') : translate('connect.more_options.block'),
      onClick: () => {
        isBlocking || blockingStatus ? unblock && unblock(conversationID || '') : setOptionActionType && setOptionActionType('block');
      },
      properties: {
        chatType: ['private'],
        origin: ['side-bar-minimized', 'side-bar-full', 'full', 'minimized', 'preference'],
        userType: ['admin', 'regular'],
      },
    },
    {
      menuLabel: translate('connect.more_options.remove_members'), //or Dismiss chat??
      onClick: () => {
        setOptionActionType && setOptionActionType('dismiss');
      },
      properties: {
        chatType: ['group'],
        origin: ['side-bar-minimized', 'side-bar-full', 'full', 'minimized'],
        userType: ['admin'],
      },
    },
    {
      menuLabel: translate('connect.more_options.leave_chat'), //or Leave group??
      onClick: () => {
        setOptionActionType && setOptionActionType('leave');
      },
      properties: {
        chatType: ['group'],
        origin: ['side-bar-minimized', 'side-bar-full', 'full', 'minimized'],
        userType: ['admin', 'regular'],
      },
    },
    {
      menuLabel: translate('connect.more_options.delete_chat'), //or Dismiss chat??
      onClick: () => {
        setOptionActionType && setOptionActionType('delete');
      },
      properties: {
        chatType: ['private'],
        origin: ['side-bar-minimized', 'side-bar-full', 'full', 'minimized'],
        userType: ['regular'],
      },
    },
    {
      menuLabel: translate('connect.more_options.remove'),
      onClick: () => removeUser?.(),
      properties: {
        chatType: ['private'],
        origin: ['preference'],
        userType: ['admin'],
      },
    },
  ];

  return (
    <PopoverAbstract
      onClickMoreOption={() => onClickMoreOption && onClickMoreOption()}
      isIconDot={isIconDot}
      isOpenCall={toggleCheckBlocking}
      cta={cta}
      containerClassName="flex flex-row gap-[0.25rem] max-w-full"
    >
      <div className="min-w-[13.875rem] py-[0.5rem] inline-flex flex-col gap-[0.25rem]">
        {isUserMember
          ? OPTION_MOCK_DATA.filter(
              data =>
                data.properties.chatType.includes(chatType) &&
                data.properties.userType.includes(userType) &&
                data.properties.origin.includes(origin),
            ).map((menuItem, key) => {
              if (menuItem.menuLabel === translate('connect.more_options.go_to_profile') && globalConfig.profileHidden) {
                return null;
              }
              return (
                <div
                  key={key.toString()}
                  onClick={menuItem.onClick}
                  className={`${
                    menuItem.menuLabel === translate('connect.more_options.clear_chat') && isChatEmpty
                      ? 'pointer-events-none text-gray'
                      : 'text-secondary'
                  } py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all`}
                >
                  <p className="font-regular text-fs-body">{menuItem.menuLabel}</p>
                </div>
              );
            })
          : OPTION_MOCK_DATA.filter(
              data =>
                (data.properties.chatType.includes(chatType) &&
                  data.properties.userType.includes(userType) &&
                  data.properties.origin.includes(origin) &&
                  (data.menuLabel?.includes(translate('connect.more_options.delete_chat') ?? '') ||
                    data.menuLabel?.includes(translate('connect.more_options.open_full_window') ?? ''))) ||
                data.menuLabel?.includes(translate('connect.more_options.delete_chat') ?? ''),
            ).map((menuItem, key) => {
              if (menuItem.menuLabel === translate('connect.more_options.go_to_profile') && globalConfig.profileHidden) {
                return null;
              }
              return (
                <div
                  key={key.toString()}
                  onClick={menuItem.onClick}
                  className={`${
                    menuItem.menuLabel === translate('connect.more_options.clear_chat') && isChatEmpty
                      ? 'pointer-events-none text-gray'
                      : 'text-secondary'
                  } py-[0.5rem] px-[1rem] cursor-pointer hover:text-sunset hover:bg-sunset/10 transition-all`}
                >
                  <p className="font-regular text-fs-body">{menuItem.menuLabel}</p>
                </div>
              );
            })}
      </div>
    </PopoverAbstract>
  );
};
export default MoreOption;
