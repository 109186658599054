import Button from './Button';

interface Props {
  newNormalStyle?: string;
  className?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  text: string;
  action: () => void;
}

const OutlineButton = (props: Props) => {
  const { newNormalStyle, className, isDisabled, isLoading, text, action } = props;

  return (
    <Button
      text={text}
      action={action}
      style={`${className} responsive-text-body font-medium border-[1px] 2K:border-[1.77px] 4K:border-[2.66px] 8K:border-[5.33px]`}
      normalStyle={`${newNormalStyle} hover:border-[1px] hover:2K:border-[1.77px] hover:4K:border-[2.66px] hover:8K:border-[5.33px] rounded-sm`}
      disabled={isDisabled}
      isLoading={isLoading}
    />
  );
};

export default OutlineButton;
