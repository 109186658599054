import { useProduct, useTranslate } from '@fe-monorepo/hooks';

import SectionHeaderContainer from '../../Atoms/SectionHeaderContainer';
import Container from '../../Templates/Container';
import MiniCard from '../MiniCard';

//create a type for RecentlyViewed
interface RecentlyViewed {
  image: string;
  productId: string;
  sectionId?: string;
  productName?: string;
  brand?: string;
}

interface RecentlyViewedProps {
  recentlyViewed: RecentlyViewed[];
  checkIsSuccess?: (errorMessage: string) => void;
}

const RecentlyViewed = ({ recentlyViewed, checkIsSuccess }: RecentlyViewedProps): JSX.Element => {
  const { translate } = useTranslate();
  const { wishlistData, getAllWishlist, addToWishList, removeToWishList } = useProduct();
  const recentlyViewedText = translate('form.placeholder.recently_viewed');

  return (
    <Container className="flex flex-col w-full h-full bg-transparent gap-0 pl-[20rem] lg:pl-[7.5rem] md:pl-[4rem] sm:pl-[2rem] xsMax:pl-[1.3rem] xs:pl-[1.3rem]">
      {recentlyViewed.length > 0 && (
        <SectionHeaderContainer title={recentlyViewedText} className="mb-[5rem]">
          {recentlyViewed.map(card => (
            <MiniCard
              image={card.image.toString()}
              key={card.productId + card.sectionId}
              productId={card.productId}
              sectionId={card.sectionId}
              checkIsSuccess={checkIsSuccess}
              wishlistData={wishlistData}
              addToWishList={addToWishList}
              removeToWishList={removeToWishList}
              getAllWishlist={getAllWishlist}
              productName={card.productName ?? ''}
              brand={card.brand ?? ''}
            />
          ))}
        </SectionHeaderContainer>
      )}
    </Container>
  );
};

export default RecentlyViewed;
