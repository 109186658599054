import { useUserProfile } from '@fe-monorepo/hooks';
import { GuestReminder } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import { GUEST_REMINDER_EXCLUDED_PATHS } from '@fe-web/constant/constants';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

interface UseGuestReminderReturn {
  isOpen: boolean;
  closeGuestReminder: () => void;
  openGuestReminder: () => void;
}

const DEFAULT_GUEST_REMINDER: GuestReminder = { load_timeout: 45000, interval: 180000, enabled: false };

const useGuestReminder = (): UseGuestReminderReturn => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const location = useLocation();
  const currentPath = location.pathname;

  const guestReminder = useSelector((state: RootState) => state.app.guestReminder);
  const hardPopup = useSelector((state: RootState) => state.app.hardPopup);
  const initTimerStatusRef = useRef(false); // to track first init timer running status
  const reappearTimerStatusRef = useRef(false); // to track reappear timer running status
  const initialTimerRef = useRef<NodeJS.Timeout | number | null>(null); // to store the initial timeout object
  const reappearTimerRef = useRef<NodeJS.Timeout | number | null>(null); // to store the reappearing timeout object
  const hardPopupEnabled = hardPopup?.enabled ?? false;
  const { load_timeout, interval, enabled } = guestReminder || DEFAULT_GUEST_REMINDER;
  const { isLoggedIn } = useUserProfile();

  useEffect(() => {
    if (!enabled || hardPopupEnabled) {
      resetTimeout();
      return;
    }
    if (isLoggedIn || GUEST_REMINDER_EXCLUDED_PATHS.some(page => currentPath.includes(page)) || initTimerStatusRef.current) {
      return;
    }

    /**
     * isOpen by default false so this will always execute first
     * GUEST_REMINDER_EXCLUDED_PATHS - added to re-initialize the timer
     * isLoggedIn - Logic is only for guest users
     * initTimerStatusRef - to skip 45sec timer on navigating to other pages
     */
    if (initialTimerRef.current) {
      clearTimeout(initialTimerRef.current as NodeJS.Timeout); // Cast for NodeJS environment
    }
    initialTimerRef.current = setTimeout(() => {
      setIsOpen(true);
      initTimerStatusRef.current = true; // set it to true to enable 3 mins timer
      clearTimeout(initialTimerRef.current as NodeJS.Timeout);
    }, load_timeout);

    return () => {
      if (initialTimerRef) {
        clearTimeout(initialTimerRef.current as NodeJS.Timeout);
      }
    };
  }, [isLoggedIn, currentPath, GUEST_REMINDER_EXCLUDED_PATHS, enabled, hardPopupEnabled]);

  useEffect(() => {
    if (!enabled || hardPopupEnabled) {
      resetTimeout();
      return;
    }
    if (
      isOpen ||
      isLoggedIn ||
      !initTimerStatusRef.current ||
      GUEST_REMINDER_EXCLUDED_PATHS.some(page => currentPath.includes(page)) ||
      reappearTimerStatusRef.current
    ) {
      return;
    }

    /**
     * GUEST_REMINDER_EXCLUDED_PATHS - added to re-initialize the timer
     * initTimerStatusRef -  added to disable timer for first 45 secs
     * isOpen - trigger timer only when dialog is closed  2.55 sec
     * isLoggedIn - Logic is only for guest users
     * reappearTimerStatusRef - need to disable the logic if timer is already running
     */
    reappearTimerStatusRef.current = true;
    if (reappearTimerRef.current) {
      clearTimeout(reappearTimerRef.current as NodeJS.Timeout);
    }
    reappearTimerRef.current = setTimeout(() => {
      setIsOpen(true);
      reappearTimerStatusRef.current = false;
      clearTimeout(reappearTimerRef.current as NodeJS.Timeout);
    }, interval);

    return () => {
      if (reappearTimerRef.current) {
        clearTimeout(reappearTimerRef.current as NodeJS.Timeout);
        reappearTimerStatusRef.current = false;
      }
    };
  }, [isOpen, isLoggedIn, currentPath, enabled, hardPopupEnabled]);

  const resetTimeout = () => {
    if (reappearTimerRef.current) {
      clearTimeout(reappearTimerRef.current as NodeJS.Timeout);
      reappearTimerStatusRef.current = false;
    }
    if (initialTimerRef) {
      clearTimeout(initialTimerRef.current as NodeJS.Timeout);
    }
  };

  /**
   * Enable guest reminder manually
   */
  const openGuestReminder = () => {
    setIsOpen(true);
  };
  /**
   * disable guest reminder manually
   */
  const closeGuestReminder = () => {
    setIsOpen(false);
  };

  return { isOpen, closeGuestReminder, openGuestReminder };
};

export default useGuestReminder;
