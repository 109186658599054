import { gql } from '@apollo/client';

export const COMMUNITY_QUERY = {
  isFollowing: gql`
    query fetchFollowedUser($details: IsFollowingInput) {
      isFollowingUser(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          is_followed
        }
      }
    }
  `,
};
