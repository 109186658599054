import React from "react";

function VideoLikeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M9 16.3465L7.9125 15.3715C6.65 14.234 5.60625 13.2527 4.78125 12.4277C3.95625 11.6027 3.3 10.8621 2.8125 10.2059C2.325 9.54961 1.98438 8.94648 1.79063 8.39648C1.59688 7.84648 1.5 7.28398 1.5 6.70898C1.5 5.53398 1.89375 4.55273 2.68125 3.76523C3.46875 2.97773 4.45 2.58398 5.625 2.58398C6.275 2.58398 6.89375 2.72148 7.48125 2.99648C8.06875 3.27148 8.575 3.65898 9 4.15898C9.425 3.65898 9.93125 3.27148 10.5188 2.99648C11.1063 2.72148 11.725 2.58398 12.375 2.58398C13.55 2.58398 14.5313 2.97773 15.3188 3.76523C16.1063 4.55273 16.5 5.53398 16.5 6.70898C16.5 7.28398 16.4031 7.84648 16.2094 8.39648C16.0156 8.94648 15.675 9.54961 15.1875 10.2059C14.7 10.8621 14.0437 11.6027 13.2188 12.4277C12.3938 13.2527 11.35 14.234 10.0875 15.3715L9 16.3465ZM9 14.3215C10.2 13.2465 11.1875 12.3246 11.9625 11.5559C12.7375 10.7871 13.35 10.1184 13.8 9.54961C14.25 8.98086 14.5625 8.47461 14.7375 8.03086C14.9125 7.58711 15 7.14648 15 6.70898C15 5.95898 14.75 5.33398 14.25 4.83398C13.75 4.33398 13.125 4.08398 12.375 4.08398C11.7875 4.08398 11.2437 4.24961 10.7437 4.58086C10.2437 4.91211 9.9 5.33398 9.7125 5.84648H8.2875C8.1 5.33398 7.75625 4.91211 7.25625 4.58086C6.75625 4.24961 6.2125 4.08398 5.625 4.08398C4.875 4.08398 4.25 4.33398 3.75 4.83398C3.25 5.33398 3 5.95898 3 6.70898C3 7.14648 3.0875 7.58711 3.2625 8.03086C3.4375 8.47461 3.75 8.98086 4.2 9.54961C4.65 10.1184 5.2625 10.7871 6.0375 11.5559C6.8125 12.3246 7.8 13.2465 9 14.3215Z"
        fill="white"
      />
    </svg>
  );
}

export default VideoLikeIcon;
