import { gql } from '@apollo/client';

export const PAYMENT_MUTATION = {
  updatePaymentMethod: gql`
    mutation updatePaymentMethod($details: PaymentInput) {
      updatePaymentMethod(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  createPayment: gql`
    mutation createPayment($details: PaymentInput) {
      createPayment(details: $details) {
        error_msg
        is_successful
        error_code
        data {
          form
        }
      }
    }
  `,

  verifyStcpayPayment: gql`
    mutation verifyStcpayPayment($details: PaymentVerificationInput) {
      verifyStcpayPayment(details: $details) {
        error_msg
        is_successful
        error_code
      }
    }
  `,

  startSession: gql`
    mutation startSession($details: ApplePaySessionInput) {
      startSession(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          epochTimestamp
          expiresAt
          merchantSessionIdentifier
          nonce
          merchantIdentifier
          domainName
          displayName
          signature
          operationalAnalyticsIdentifier
          retries
          pspId
        }
      }
    }
  `,
};
