import { IconNames } from '@fe-monorepo/helper';
import { ContentType, useMediaUpload, useReturnProduct, useToastMessage, useTranslate } from '@fe-monorepo/hooks';
import PrimaryButton from '@fe-web/Atoms/Buttons/PrimaryButton';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import ToastMessage from '@fe-web/Atoms/ToastMessage';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { generateRandomId } from 'apps/fe-web/src/lib/helper';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';

import Photos from '../../ReturnOrExchangePage/components/Photos';

interface ProductPhotos {
  name: string;
  imageUrl: string;
  reason: string;
  product_code: string;
  images: any[];
}
interface EditPhotosProps {
  close: () => void;
  product: ProductPhotos;
  reason_code?: string;
  return_product_id?: string;
  setOpenEditPhotosModal?: (value: boolean) => void;
}

const EditPhotos = ({ close, product, reason_code, return_product_id, setOpenEditPhotosModal }: EditPhotosProps) => {
  const { translate } = useTranslate();
  const { language } = usePageLayout();
  const { dir } = useHeaderState();
  const [imagesFiles, setImagesFiles] = useState<any[]>([]);
  const data = {
    file: '',
  };
  const imageUpload = useMediaUpload();
  const { successToastMessage, errorToastMessage } = useToastMessage();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [imageUrls, setImageUrls] = useState<any[]>([]);
  const { createAddDataWithResult, addData, createUpdateDataWithResult } = useReturnProduct();
  const [uploadSuccess, setUploadSuccess] = useState<boolean>(false);
  const [index, setIndex] = useState<number>();
  const [file, setFile] = useState<File>();
  const prevMediaLinksLengthRef = useRef(imageUpload.mediaLinks.length);

  //create a useEffect that will detect if imagesFiles has changed
  // This useEffect will set the imageUrls state base from the imagesFiles state
  // This will just set the imageURls to an array of image URLs base from the imagesFiles state
  useEffect(() => {
    if (imagesFiles.length === 0) return;
    const temp_imageUrls = imagesFiles.filter(item => item?.img_url).map(item => item?.img_url);
    setImageUrls(temp_imageUrls);
  }, [imagesFiles]);

  useEffect(() => {
    if (product.images && product.images.find(item => item || item?.file !== '')) {
      //create a new array of imagesFiles base from the product.images
      //add img_url which will be equal to file
      const newImagesModified = product.images.map(item => {
        return { ...item, img_url: item?.file };
      });
      const newImages = [...newImagesModified, { file: '', id: generateRandomId(5), img_url: '' }];
      setImagesFiles(newImages);
    } else {
      setImagesFiles([
        ...Array(4).map(() => {
          return { file: '', id: generateRandomId(5), img_url: '' };
        }),
      ]);
    }
  }, [product.images]);

  const deleteFileById = (activate: boolean, product_code: string, optionIndex: number, fileId: string) => {
    if (!activate) return;
    let temp_images = _.cloneDeep(imagesFiles);
    if (fileId) {
      temp_images = temp_images.filter(item => item && item.id !== fileId);
      const withImages = temp_images.filter(item => item && item?.file !== '');
      const new_images = withImages.map(item => {
        return {
          file: item.file,
          id: generateRandomId(5),
          img_url: '',
        };
      });
      updateImages(new_images);
    }
  };

  useEffect(() => {
    let temp_images = _.cloneDeep(imagesFiles);
    if (index === undefined || file === undefined) return;
    const lastIndexImageURL = imageUpload.mediaLinks[imageUpload.mediaLinks.length - 1];

    if (imageUpload.mediaLinks.length > prevMediaLinksLengthRef.current) {
      const newImage = {
        file: file,
        id: generateRandomId(5),
        img_url: lastIndexImageURL,
      };

      if (temp_images[index] !== undefined) {
        temp_images[index] = newImage;
      } else {
        temp_images.push(newImage);
      }

      let withImages = temp_images.filter(item => item && item.file !== '');
      updateImages(withImages);
      setIsLoading(false);
    }

    // Update the previous length for the next render
    prevMediaLinksLengthRef.current = imageUpload.mediaLinks.length;
  }, [imageUpload.mediaLinks, isLoading]);

  const addFileToProduct = (reset: boolean, product_code: string, file: File, optionIndex: number, index: number) => {
    if (file) {
      setIsLoading(true);
      setIndex(index);
      setFile(file);

      try {
        imageUpload.getGeneratedLinks([{ file: file, path: '' }], Date.now().toString(), ContentType.return);
      } catch (error) {
        console.error('An error occurred during image upload:', error);
        errorToastMessage({
          message: <ToastMessage type="error">{translate('error.please_try_again')}</ToastMessage>,
        });
        setIsLoading(false);
      }
    }
  };

  const updateImages = (images: any[]) => {
    if (images.length >= 4) {
      images.push({ file: '', id: generateRandomId(5), img_url: '' });
      setImagesFiles(images);
    } else {
      const additional_empty_images = [
        ...Array(4 - images.length).map(() => {
          return { file: '', id: generateRandomId(5), img_url: '' };
        }),
      ];
      const temp_images = [...images, ...additional_empty_images];
      setImagesFiles(temp_images);
    }
  };

  async function handleUploadImages() {
    setUploadSuccess(false);
    try {
      const result = await createUpdateDataWithResult({
        img_urls: imageUrls as [string],
        reason_code: reason_code as string,
        return_product_id: Number(return_product_id),
      });
      if (result.data?.omsReturnProdUpdate.error_msg) {
        errorToastMessage({
          message: <ToastMessage type="error">{result.data?.omsReturnProdUpdate.error_msg}</ToastMessage>,
        });
      } else {
        setUploadSuccess(true);
        setOpenEditPhotosModal && setOpenEditPhotosModal(false);
        successToastMessage({
          message: <ToastMessage type="success">{translate('msg_success_request_update')}</ToastMessage>,
        });
      }
    } catch (error) {
      errorToastMessage({
        message: <ToastMessage type="error">{`${translate('error.please_try_again')} ${error}`}</ToastMessage>,
      });
    }
  }

  return (
    <div
      className={`
                flex flex-col mt-[1.25rem] justify-start items-center bg-white
                rounded-lg p-[1.563rem] SD:p-[3rem] 2K:p-[2.65rem] 4K:p-[6rem] 8K:p-[3.25rem] gap-[1.25rem] max-h-[100vh] overflow-y-auto
                fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[23.125rem] SD:w-[39.75rem]
                2K:w-[38.75rem] 4K:w-[71.625rem] 8K:w-[38.5rem]
            `}
    >
      <div className="flex flex-col w-full gap-[0.25rem]">
        <div className="flex gap-[0.5rem]">
          <span className={`text-fs-subtitle font-medium text-secondary`}>{translate('shop.returns_page.edit_photos')}</span>
          <ResponsiveIcon
            className={`
                            absolute
                            ${
                              dir === 'ltr'
                                ? 'right-[2.313rem] SD:right-[3.25rem] 2K:right-[2.75rem] justify-items-end'
                                : 'left-[2.313rem] SD:left-[3.25rem] 2K:left-[2.75rem] justify-items-end'
                            }
                            top-[2rem] SD:top-[3.2625rem] 2K:top-[2.9626rem] 8K:top-[3.5626rem]`}
            name={IconNames.close_xbutton}
            baseHeight={12}
            baseWidth={12}
            onClick={close}
          />
        </div>
        <div>
          <p className={`text-fs-body-small font-regular text-gray90`}>{translate('shop.returns_page.edit_photos_modal_title')}</p>
        </div>
      </div>
      <div>
        <Photos
          imgUrl={product.imageUrl}
          name={product.name}
          reason={product.reason}
          imageFiles={[...imagesFiles]}
          code={product.product_code}
          index={0}
          deleteFileById={deleteFileById}
          addFileToProduct={addFileToProduct}
          cols={4}
          isLoading={isLoading}
        />
      </div>
      <div className={`w-full ${dir === 'ltr' ? 'text-right' : 'text-left'} mt-[1.5rem]`}>
        <PrimaryButton
          action={handleUploadImages}
          text={translate('shop.returns_page.upload_request_btn') ?? ''}
          className={`px-[1.5rem] py-[0.5rem] text-fs-body`}
          isDisabled={false}
        />
      </div>
    </div>
  );
};

export default EditPhotos;
