import { useLazyQuery } from '@apollo/client';
import { PRODUCT_DETAILED_QUERY } from '@fe-monorepo/data-access';
import { RelatedProductModel } from '@fe-monorepo/models';
import { useState } from 'react';

interface RelatedProductResponse {
  getRelatedProducts: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: RelatedData;
  };
}

interface RelatedData {
  related_product: RelatedProductModel[];
  more_product: RelatedProductModel[];
}

interface RelatedPayload {
  product_code: string;
  last_cursor?: number;
  direction?: string;
}

const defaultValue: RelatedProductModel = {
  name_ar: '',
  name_en: '',
  description_ar: '',
  description_en: '',
  product_code: '',
  metadata: '',
  brand: {
    name_ar: '',
    name_en: '',
    collection_code: '',
    collection_id: 0,
  },
  lowest_actual_cost: 0,
  highest_actual_cost: 0,
  lowest_selling_price: 0,
  highest_selling_price: 0,
  is_liked: 0,
  last_cursor: 0,
  featured_img: '',
};

export const useRelatedProducts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [getRelatedProducts, { data: relatedproducts, error: infoError }] = useLazyQuery<RelatedProductResponse>(
    PRODUCT_DETAILED_QUERY.getRelatedProducts,
    {
      errorPolicy: 'all',
    },
  );

  const getAll = async (params: RelatedPayload) => {
    setIsLoading(true);
    const { data } = await getRelatedProducts({
      variables: { details: params },
    });
    setIsLoading(false);
    return {
      related_product: data?.getRelatedProducts?.data?.related_product,
      more_product: data?.getRelatedProducts?.data?.more_product,
    };
  };

  return {
    getAll,
    related_product: relatedproducts?.getRelatedProducts?.data?.related_product,
    more_product: relatedproducts?.getRelatedProducts?.data?.more_product,
    isLoading,
    setIsLoading,
  };
};
