import { gql } from '@apollo/client';

export const PARTICIPANTS_QUERY = {
  getAll: gql`
    query tmsParticipantGetParticipants($details: TmsParticipantGetParticipantsInput) {
      tmsParticipantGetParticipants(details: $details) {
        is_successful
        error_msg
        error_code
        app_code
        data {
          type
          tournament_id
          id
          name
          email
          custom_user_identifier
          checked_in
          created_at
          user_id
          # custom_fields
        }
      }
    }
  `,

  registrationInfo: gql`
    query registrationInfo($details: RegInfoInput) {
      registrationInfo(details: $details) {
        is_successful
        error_msg
        error_code
        app_code
        data {
          id
          tournament_id
          participant_id
          custom_user_identifier
          email
          type
          status
          name
          created_at
          user_id
        }
      }
    }
  `,
};
