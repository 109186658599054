import { useEffect, useState } from 'react';

export enum BreakPoints {
  mobile = 'mobile',
  'XGA' = 'XGA',
  normal = 'normal',
  Ll = 'Ll',
  FHD = 'FHD',
  '2k' = '2k',
  '4k' = '4k',
  '8k' = '8k',
}
const useGetCurrentBreakPoint = () => {
  const [innerWidth, setInnerWidth] = useState(window?.innerWidth);
  const [currentBreakPoint, setCurrentBreakPoint] = useState<BreakPoints>(BreakPoints.normal);

  useEffect(() => {
    const handleResize = () => setInnerWidth(window.innerWidth);
    window.addEventListener('scroll', handleResize);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('scroll', handleResize);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let breakpoint: BreakPoints = BreakPoints.normal;
    if (innerWidth >= 7680) {
      breakpoint = BreakPoints['8k'];
    } else if (innerWidth >= 3840) {
      breakpoint = BreakPoints['4k'];
    } else if (innerWidth >= 2560) {
      breakpoint = BreakPoints['2k'];
    } else if (innerWidth >= 1920) {
      breakpoint = BreakPoints.FHD;
    } else if (innerWidth >= 1440) {
      breakpoint = BreakPoints.Ll;
    } else if (innerWidth >= 1024) {
      breakpoint = BreakPoints.normal;
    } else if (innerWidth >= 640) {
      breakpoint = BreakPoints.XGA;
    } else {
      breakpoint = BreakPoints.mobile;
    }
    setCurrentBreakPoint(breakpoint);
  }, [innerWidth]);

  return { currentBreakPoint };
};

export default useGetCurrentBreakPoint;
