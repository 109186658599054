import { convertPriceWithSymbol } from '@fe-monorepo/helper';
import { useGetReason, useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import Checkbox from '@fe-web/Molecules/InputFields/Checkbox';
import DropDown from '@fe-web/Molecules/InputFields/DropDownv2/DropDown';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { v4 as uuidv4 } from 'uuid';

import { AppRoutes } from '../../../../app.routes.enum';
import { InvoiceProduct, OptionSort2, OptionSortRevised, OptionSortRevisedSerial, ReturnProducts } from './Model/InvoiceProduct';
import ProductImageLayered from './ProductImageLayered/ProductImageLayered';

interface ReturnsOverviewProps {
  products: InvoiceProduct[];
  returnProducts?: ReturnProducts[];
  handleContinue: (data: { [key: string]: OptionSort2 }) => void;
  setIsTotalRefundVisible: (value: boolean) => void;
  setReturnData: (value: { [key: string]: OptionSort2 }) => void;
  children: React.ReactNode;
}

interface reasonObjectFormat {
  return_reason_code: string;
  return_reason_ar: string;
  return_reason_en: string;
}

const ReturnsOverview = ({
  products,
  returnProducts,
  handleContinue,
  setIsTotalRefundVisible,
  setReturnData,
  children,
}: ReturnsOverviewProps) => {
  const isHeaderDark = useSelector((state: RootState) => state.app.headerColorChange);
  const language = useSelector((state: RootState) => state.app.language);
  const { dir } = useHeaderState();
  const { translate } = useTranslate();
  const [isContinueButtonEnabled, setIsContinueButtonEnabled] = useState<boolean>(false);
  const [isChecked, setIsChecked] = useState(new Array(returnProducts?.length).fill(false));
  const [reason, setReasons] = useState<reasonObjectFormat[]>([]);
  const { getReason, getReasonData } = useGetReason();
  const [reasonSort, setReasonSort] = useState<OptionSortRevised[]>([]);
  const [hiddenIndices, setHiddenIndices] = useState<number[][]>([[-1, -1, -1, -1, -1]]);
  const isMobile = useMobileDetect();
  const navigate = useNavigate();

  useEffect(() => {
    getReason();
  }, []);

  useEffect(() => {
    setReasons(getReasonData?.data as reasonObjectFormat[]);
  }, [getReasonData]);

  useEffect(() => {
    if (!reason) return;
    const newReasonSort: OptionSortRevised[] = reason.map((item: any) => ({
      id: item.return_reason_code,
      label: item[`return_reason_${language}`] || '',
      serial: translate('shop.cart.returns_or_exchange.serial_number') ?? '',
      tracking_number: 'TEST',
      return_product_id: '1',
    }));

    setReasonSort(newReasonSort);
  }, [reason, language]);

  // using reasonSort as basis, create another array similar to reasonSort that will only contain an array of objects, it will have 3 list

  //create a useEffect that will create the serialSort object base from returnProducts, serialSort will be an object with product_code as its unique keys
  const [serialSort, setSerialSort] = useState<{ [key: string]: OptionSortRevisedSerial[] }>({});
  useEffect(() => {
    if (returnProducts) {
      const newSerialSort: { [key: string]: OptionSortRevisedSerial[] } = {};

      returnProducts.forEach(product => {
        newSerialSort[product.product_code] = product.serials
          .filter(serial => serial.is_return_requested !== '1')
          .map(serial => ({
            id: uuidv4(),
            label: serial.serial_number as string,
            serial: serial.serial_number as string,
          }));
      });

      Object.keys(newSerialSort).length > 0 &&
        setHiddenIndices(Array.from({ length: Object.keys(newSerialSort).length }, () => Array(5).fill(-1)));
      setSerialSort(newSerialSort);
    }
  }, [returnProducts]);

  //create a function that will check the serialSort object, the parameters is product_code only, this function will just check if all serial is null for all the index
  function checkSerial(productCode: string) {
    if (!productCode) return;
    const serials = serialSort[productCode];
    if (!serials) return;
    const isAllSerialNull = serials.every(serial => serial.serial === null);
    return isAllSerialNull;
  }

  const [selectedReasons, setSelectedReasons] = useState<{ [key: string]: OptionSort2 }>({});

  useEffect(() => {
    if (returnProducts) {
      const initialReasons: {
        [key: string]: {
          option: Array<{ id: string; label: string; serial: string; tracking_number: string; return_product_id: string }>;
          quantity: number;
          imageURL: string;
          isSelected: boolean;
          name: string;
          price: number;
        };
      } = {};
      returnProducts.forEach(product => {
        initialReasons[product.product_code] = {
          option: Array(Number(product.qty)).fill({
            id: '0',
            label: '',
            serial: '',
            tracking_number: '',
            return_product_id: '',
          }),
          quantity: 1,
          imageURL: product.media,
          isSelected: false,
          name: product.name_en,
          price: Number(product.selling_cost_grand_total),
        };
      });
      setSelectedReasons(initialReasons);
    }
  }, [returnProducts]);

  //create a useEffect that will set the isChecked array to false if the products array changes

  useEffect(() => {
    if (returnProducts) {
      setIsChecked(new Array(products?.length).fill(false));
    }
  }, [returnProducts?.length]);

  function handleGoToDetailsPage(product_code: string | undefined) {
    navigate(`${AppRoutes.shopGeneric}/${product_code}`);
  }

  const handleReasonChange = (option: OptionSortRevised, productCode: string | undefined, index: number, tracking_number: string) => {
    if (!productCode) return;
    setSelectedReasons(prevSelectedReasons => {
      // Create a copy of the previous state
      const updatedReasons = { ...prevSelectedReasons };
      // Check if the product exists in the state
      if (updatedReasons[productCode]) {
        // Create a copy of the options array
        const updatedOptions = [...updatedReasons[productCode].option];
        // Update the option and tracking number
        updatedOptions[index] = { ...option, tracking_number };
        // Update the options array in the state
        updatedReasons[productCode] = {
          ...updatedReasons[productCode],
          option: updatedOptions,
          isSelected: true,
        };
      }
      return updatedReasons;
    });
  };

  function handleSerialChange(option: OptionSortRevised, productCode: string | undefined, index: number, tracking_number: string) {
    if (!productCode) return;

    setSelectedReasons(prevSelectedReasons => {
      // Create a copy of the previous state
      const updatedReasons = { ...prevSelectedReasons };
      // Check if the product exists in the state
      if (updatedReasons[productCode]) {
        // Create a copy of the options array
        const updatedOptions = [...updatedReasons[productCode].option];
        // Update the option and tracking number
        updatedOptions[index] = { ...option, tracking_number };
        // Update the options array in the state
        updatedReasons[productCode] = {
          ...updatedReasons[productCode],
          option: updatedOptions,
        };
      }
      return updatedReasons;
    });
  }

  function handleIncrement(productCode: string | undefined, qty: number) {
    if (!productCode) return;
    const newSelectedReasons = { ...selectedReasons };
    //do not update selectedReasons when the value is of quantity is greater than qty
    if (newSelectedReasons[productCode].quantity >= qty) return;
    newSelectedReasons[productCode].quantity += 1;
    setSelectedReasons(newSelectedReasons);
  }

  function handleDecrement(productCode: string | undefined) {
    if (!productCode) return;
    const newSelectedReasons = { ...selectedReasons };
    //do not update selectedReasons when the value is of quantity is less than 1
    if (newSelectedReasons[productCode].quantity <= 1) return;
    newSelectedReasons[productCode].quantity -= 1;
    setSelectedReasons(newSelectedReasons);
  }

  //Create a useEffect that will monitor isChecked array, if all values are false then disable the continue button

  useEffect(() => {
    if (returnProducts) {
      const newIsContinueButtonEnabled = isChecked.every(value => false);
      setIsContinueButtonEnabled(newIsContinueButtonEnabled);
      //add a code here so that it will also add to set the selectedReasons to its initial value onload
      //add a condition so below code will not execute when isChecked has some values of true
      const isSomeAreTrue = isChecked.some(value => value === true);
      if (isSomeAreTrue) return;
      const initialReasons: {
        [key: string]: {
          option: Array<{ id: string; label: string; serial: string; tracking_number: string; return_product_id: string }>;
          quantity: number;
          imageURL: string;
          isSelected: boolean;
          name: string;
          price: number;
        };
      } = {};
      returnProducts.forEach(product => {
        initialReasons[product.product_code] = {
          option: product.serials.map((serial, index) => ({
            id: '0',
            label: '',
            serial: serial.serial_number as string,
            tracking_number: serial.tracking_number, // replace with actual value if available
            return_product_id: '',
            is_return_requested: serial.is_return_requested,
          })),
          quantity: 1,
          imageURL: product.media[0],
          isSelected: false,
          name: product.name_en,
          price: Number(product.selling_cost_grand_total),
        };
      });
      setSelectedReasons(initialReasons);
    }
  }, [isChecked]);

  useEffect(() => {
    if (returnProducts) {
      const newIsContinueButtonEnabled = isChecked.every(value => false);
      setIsContinueButtonEnabled(newIsContinueButtonEnabled);
      //add a code here so that it will also add to set the selectedReasons to its initial value onload
      //add a condition so below code will not execute when isChecked has some values of true
      const isSomeAreTrue = isChecked.some(value => value === true);
      if (isSomeAreTrue) return;
      const initialReasons: {
        [key: string]: {
          option: Array<{ id: string; label: string; serial: string; tracking_number: string; return_product_id: string }>;
          quantity: number;
          imageURL: string;
          isSelected: boolean;
          name: string;
          price: number;
        };
      } = {};
      returnProducts.forEach(product => {
        initialReasons[product.product_code] = {
          option: product.serials.map((serial, index) => ({
            id: '0',
            label: '',
            serial: serial.serial_number as string,
            tracking_number: serial.tracking_number, // replace with actual value if available
            return_product_id: '',
          })),
          quantity: 1,
          imageURL: product.media[0],
          isSelected: false,
          name: product.name_en,
          price: Number(product.selling_cost_grand_total),
        };
      });
      setSelectedReasons(initialReasons);
    }
  }, [isChecked]);

  // create a useEffect that will monitor the values of selectedReasons specifically the id under the option object, if id is not equal to 0 then enable the continue button
  useEffect(() => {
    if (returnProducts) {
      let newIsContinueButtonEnabled = Object.values(selectedReasons).every(
        (selectedReason, index) =>
          !isChecked[index] || selectedReason.option.slice(0, selectedReason.quantity).every(option => option.id !== '0'),
      );
      //if isChecked values are all false the continue button is still disabled
      const allAreFalse = isChecked.every(value => value === false);
      if (allAreFalse) {
        newIsContinueButtonEnabled = false;
      }
      setIsContinueButtonEnabled(newIsContinueButtonEnabled);
    }
  }, [selectedReasons, isChecked]);

  function getOrdinalNumberForGreaterThan10(number: number) {
    const s = ['th', 'st', 'nd', 'rd'],
      v = number % 100;
    return number + (s[(v - 20) % 10] || s[v] || s[0]);
  }

  //create a function that will received a string, if 0 is received the it will output First, if 1 is received then it will output Second, if 2 is received then it will output Third, if 3 is received then it will output Fourth, if 4 is received then it will output Fifth, if 5 is received then it will output Sixth
  function getOrdinalNumber(number: number) {
    switch (number) {
      case 0:
        return translate('shop.cart.returns_or_exchange.first');
      case 1:
        return translate('shop.cart.returns_or_exchange.second');
      case 2:
        return translate('shop.cart.returns_or_exchange.third');
      case 3:
        return translate('shop.cart.returns_or_exchange.fourth');
      case 4:
        return translate('shop.cart.returns_or_exchange.fifth');
      case 5:
        return translate('shop.cart.returns_or_exchange.sixth');
      case 6:
        return translate('shop.cart.returns_or_exchange.seventh');
      case 7:
        return translate('shop.cart.returns_or_exchange.eighth');
      case 8:
        return translate('shop.cart.returns_or_exchange.ninth');
      case 9:
        return translate('shop.cart.returns_or_exchange.tenth');
      default:
        return getOrdinalNumberForGreaterThan10(number);
    }
  }

  useEffect(() => {
    if (returnProducts) {
      const newSelectedReasons = { ...selectedReasons };
      isChecked.forEach((value, index) => {
        if (!value) {
          newSelectedReasons[returnProducts[index]?.product_code] = {
            option: Array(Number(returnProducts[index]?.qty) || 0)
              .fill(undefined)
              .map((_, idx) => ({
                id: '0',
                label: '',
                serial: String(returnProducts[index]?.serials[idx].serial_number),
                tracking_number: returnProducts[index].serials[idx].tracking_number, // replace with actual value if available
                return_product_id: '',
                is_return_requested: returnProducts[index].serials[idx].is_return_requested,
              })),
            quantity: 1,
            imageURL: returnProducts[index]?.media,
            isSelected: false,
            name: returnProducts[index]?.name_en,
            price: Number(returnProducts[index]?.selling_cost_grand_total),
          };
        }
      });
      setSelectedReasons(newSelectedReasons);
    }
  }, [isChecked]);

  useEffect(() => {
    if (isContinueButtonEnabled) {
      setIsTotalRefundVisible(true);
      setReturnData(selectedReasons);
    } else {
      setIsTotalRefundVisible(false);
    }
  }, [isContinueButtonEnabled]);

  useEffect(() => {
    mixpanelHelper.trackPageView(
      'ORDER DETAILS PAGE - RETURN ORDER PAGE - RETURNED PRODUCTS SELECTION PAGE',
      'my_orders_order_tab_odp_return_order_select_product_page',
      window.location.pathname,
      document.referrer,
    );
    brazeHelper.trackPageView(
      'ORDER DETAILS PAGE - RETURN ORDER PAGE - RETURNED PRODUCTS SELECTION PAGE',
      'my_orders_order_tab_odp_return_order_select_product_page',
      window.location.pathname,
      document.referrer,
    );
  }, []);

  return (
    <div className="col-span-2">
      <div className="mdxs:w-[20rem] SD:w-[43.125rem] 2K:w-[43rem]">
        <h1 className="text-secondary text-fs-subtitle not-italic font-medium leading-8 tracking-[-0.015rem]">
          {translate('shop.cart.returns_or_exchange.what_items')}
        </h1>
        <h2 className="text-secondary/60 text-fs-body not-italic font-regular leading-6 mdxs:w-[20rem] SD:w-full">
          {translate('shop.cart.returns_or_exchange.make_sure_to_return')}
        </h2>
        {returnProducts &&
          returnProducts?.map((product, index) => {
            let product_code = product.product_code;
            if (!product_code) {
              product_code = '';
            }
            return (
              <div className="mt-[2.5rem]" key={index}>
                <div className="flex mdxs:items-start SD:items-center gap-[1rem]">
                  <div className="ml-[0.75rem]">
                    <Checkbox
                      isChecked={isChecked[index]}
                      classNames="!w-[1.125rem] h-[1.125rem] accent-sunset"
                      disabled={serialSort[product_code]?.length === 0}
                      onClick={() => {
                        const newIsChecked = [...isChecked];
                        newIsChecked[index] = !newIsChecked[index];
                        setIsChecked(newIsChecked);
                      }}
                      width={isChecked[index] ? 1.3 : 1.125}
                      height={isChecked[index] ? 1.3 : 1.125}
                      defaultClassName="mr-2 flex items-center justify-center"
                      imageWidth="1.125rem"
                      imageHeight="1.125rem"
                      boxBorderStyle="border-transparent"
                    />
                  </div>
                  <div onClick={() => handleGoToDetailsPage(product.product_code)}>
                    {products && (
                      <ProductImageLayered product={product} isImageClickable={true} isDisabled={serialSort[product_code]?.length === 0} />
                    )}
                  </div>
                  {/* Div for the product name / SKU / Quantity Price */}
                  <div className="flex items-start flex-grow mb-auto">
                    <div className="flex mdxs:flex-col mdxs:gap-[0.5rem] SD:flex-row items-start justify-between w-full">
                      <div className="flex flex-col gap-[0.5rem]">
                        <div
                          className={`${
                            serialSort[product_code]?.length === 0 ? 'text-neutral-300-zinc-700' : 'text-secondary'
                          } mdxs:fs-body-small SD:text-fs-body-large cursor-pointer not-italic font-medium leading-6`}
                          onClick={() => handleGoToDetailsPage(product.product_code)}
                        >
                          {product[`name_${language}`]}
                        </div>
                        <div
                          className={`${serialSort[product_code]?.length === 0 ? 'text-neutral-300-zinc-700' : 'text-secondary/70'}
                            text-fs-caption not-italic font-regular leading-4`}
                        >
                          {translate('shop_sku')?.toUpperCase()}: {product.sku}
                        </div>
                        {serialSort[product_code]?.length === 0 && (
                          <div className="mt-[1rem] text-secondary text-fs-caption not-italic font-medium leading-4">
                            {translate('shop.cart.returns_or_exchange.this_item_has')}
                          </div>
                        )}
                      </div>
                      <div className="flex flex-col gap-[0.5rem] items-end mdxs:w-full">
                        <div className="text-secondary text-fs-body-large not-italic font-medium leading-6">
                          {serialSort[product_code]?.length !== 0 &&
                            convertPriceWithSymbol(Number(product?.selling_cost_grand_total), 'commaDecimal')}
                        </div>
                        {Number(product.qty) > 1 && (
                          <>
                            {!isChecked[index] && serialSort[product_code]?.length !== 0 && (
                              <div className="text-secondary/70 text-fs-caption not-italic font-regular leading-4 text-right">
                                {product.qty}x {convertPriceWithSymbol(Number(product?.selling_cost_grand_total), 'commaDecimal')}
                              </div>
                            )}
                            {isChecked[index] && serialSort[product_code]?.length !== 0 && (
                              <div className="flex items-center gap-[0.5rem]">
                                <div className="flex items-center justify-center gap-[0.5rem] bg-[#0F0E100D] py-[0.25rem] px-[0.56rem]">
                                  <div
                                    onClick={() => handleDecrement(product.product_code)}
                                    className="text-secondary text-fs-body-small not-italic font-regular leading-5 cursor-pointer"
                                  >
                                    -
                                  </div>
                                  <div className="w-[2rem] text-center bg-white">{selectedReasons[product.product_code]?.quantity}</div>
                                  <div
                                    onClick={() => handleIncrement(product.product_code, Number(serialSort[product_code]?.length || 0))}
                                    className="text-secondary text-fs-body-small not-italic font-regular leading-5 cursor-pointer"
                                  >
                                    +
                                  </div>
                                </div>
                                <div className="text-secondary/70 text-fs-body-small not-italic font-regular leading-5">
                                  out of {serialSort[product_code]?.length || 0}
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-[2.8rem] mt-[1.25rem]">
                  <div className="w-full flex flex-col gap-[1.5rem]">
                    {Array(selectedReasons[product.product_code]?.quantity || 0)
                      .fill(null)
                      .map(
                        (_, index2) =>
                          isChecked[index] && (
                            <div
                              className="mdxs:mb-[1.5rem] flex mdxs:gap-[1.5rem] SD:gap-[1.5rem] mdxs:flex-col SD:flex-col XGA:flex-row justify-between mdxs:items-end SD:items-start XGA:items-end"
                              key={index2}
                            >
                              <div className="flex flex-col">
                                <h4 className="hidden SD:block 2K:block text-secondary/50 text-fs-body-small not-italic font-regular leading-5">
                                  {translate('shop.cart.returns_or_exchange.reason')}
                                </h4>
                                {/* Below text will only show in mobile screen */}
                                <h4 className="mdxs:block SD:hidden text-secondary text-fs-body-small not-italic font-regular leading-5">
                                  {getOrdinalNumber(index2)} {translate('shop.cart.order_details_page.product')}
                                </h4>
                                <div className="relative w-[19rem]">
                                  {/* Reason DropDown */}
                                  <DropDown<OptionSortRevised>
                                    key={index}
                                    style={{
                                      container: `
                                        justify-self-end
                                        py-[6px] 2K:py-[10.66px] 4K:py-16 8K:py-32
                                        bg-transparent
                                        border-solid border-b-[0.063rem] border-secondary/20 hover:border-secondary`,
                                      text: `
                                        truncate
                                        font-regular
                                        text-secondary
                                        text-fs-body-large
                                        flex flex-col
                                        justify-center
                                        gap-[8px]
                                        w-full
                                        4K:w-full
                                        8K:w-full
                                        items-start`,
                                      icon: `!fill-secondary/50`,
                                    }}
                                    selectedOption={selectedReasons[product.product_code]?.option[index2]}
                                    selectedIconPosition="start"
                                    options={reasonSort}
                                    getStringValue={
                                      (option: OptionSortRevised) =>
                                        option?.label
                                          ? option?.label
                                          : isMobile // Check if isMobile is true
                                          ? (translate('shop.cart.returns_or_exchange.reason') as string) // If true, translate
                                          : '' // If false, return an empty string
                                    }
                                    retreiveSelection={(option: OptionSortRevised) => {
                                      const serialsWithReturnNotRequested = product.serials.filter(
                                        serial => serial.is_return_requested === '0',
                                      );
                                      if (Number(product.qty) === 1 && serialsWithReturnNotRequested.length > 0) {
                                        handleReasonChange(
                                          option,
                                          product.product_code,
                                          index2,
                                          serialsWithReturnNotRequested[0].tracking_number,
                                        );
                                      } else if (Number(product.qty) > 1) {
                                        const serialWithIndex = serialsWithReturnNotRequested[index2];
                                        if (serialWithIndex) {
                                          handleReasonChange(option, product.product_code, index2, serialWithIndex.tracking_number);
                                        }
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="relative w-[19rem]">
                                {/* Serial Number DropDown */}
                                {!checkSerial(product_code) && (
                                  <DropDown<OptionSortRevised>
                                    key={index}
                                    style={{
                                      container: `
                                        justify-self-end
                                        py-[6px] 2K:py-[10.66px] 4K:py-16 8K:py-32
                                        bg-transparent
                                        border-solid border-b-[0.063rem] border-secondary/20 hover:border-secondary
                                      `,
                                      text: `
                                        truncate
                                        font-regular
                                        ${
                                          selectedReasons[product.product_code]?.option[index2].serial ===
                                            translate('shop.cart.returns_or_exchange.serial_number') ?? ''
                                            ? 'text-secondary/40'
                                            : 'text-secondary'
                                        }
                                        text-fs-body-large
                                        flex flex-col
                                        justify-center
                                        gap-[8px]
                                        w-full
                                        4K:w-full
                                        8K:w-full
                                        items-start
                                      `,
                                      icon: `!fill-secondary/50`,
                                    }}
                                    selectedOption={selectedReasons[product.product_code]?.option[index2] as OptionSortRevisedSerial}
                                    selectedIconPosition="start"
                                    options={serialSort[product_code]}
                                    getStringValue={(option: OptionSortRevised) =>
                                      option?.serial === 'null'
                                        ? translate('shop.cart.returns_or_exchange.serial_number') ?? ''
                                        : (option.serial as string)
                                    }
                                    retreiveSelection={(option: OptionSortRevised) => {
                                      //add a code here that will modify the option to retain the previous values of label and just updated the serial
                                      const updatedOption = {
                                        ...option,
                                        id: selectedReasons[product.product_code]?.option[index2]?.id,
                                        label: selectedReasons[product.product_code]?.option[index2]?.label,
                                      };
                                      setHiddenIndices(prevHiddenIndices => {
                                        const newHiddenIndices = [...prevHiddenIndices];
                                        // Update the selected index for the specific dropdown and set
                                        newHiddenIndices[index][index2] = serialSort[product_code].findIndex(data => data.id === option.id);
                                        return newHiddenIndices;
                                      });
                                      handleSerialChange(
                                        updatedOption,
                                        product.product_code,
                                        index2,
                                        product.serials.find(data => data.serial_number === option.serial)?.tracking_number || '',
                                      );
                                    }}
                                    hiddenIndices={hiddenIndices[index]}
                                  />
                                )}
                              </div>
                            </div>
                          ),
                      )}
                  </div>
                </div>
                {products.length > 1 && index !== products.length - 1 && (
                  <hr className="w-full text-secondary/10 bg-secondary/10 border-[none] my-[1.5rem]" />
                )}
              </div>
            );
          })}
        <div className="mdxs:flex SD:justify-center SD:flex SD:mt-[2.5rem] XGA:hidden 2K:hidden">{children}</div>
        <div className="w-[20rem] mdxs:w-[20rem] SD:w-full XGA:w-[43rem] 2K:w-[43rem] flex justify-end mt-[2.5rem] mb-[20rem]">
          {/* Enabled Continue Button Here */}
          {isContinueButtonEnabled && (
            <Button
              text={translate('account_security.two_auth.continue')}
              style={`flex items-center justify-center mdxs:w-full mdMaxxS:w-full text-fs-body not-italic font-medium leading-6 px-[1.5rem] py-[0.5rem] text-white100 border-[1px] border-transparent rounded-sm ${
                isHeaderDark
                  ? dir === 'ltr'
                    ? 'after:bg-sunset before:bg-white100'
                    : 'before:bg-sunset after:bg-white100'
                  : `${dir === 'ltr' ? 'after:bg-sunset before:bg-white100' : 'before:bg-sunset after:bg-white100'}
                  hover:-border[1px] hover:border-sunset`
              } hover:text-sunsetText rounded-sm`}
              disabled={false}
              onClick={() => handleContinue(selectedReasons)}
            />
          )}
          {!isContinueButtonEnabled && (
            <div className="mdxs:w-full cursor-not-allowed flex items-center justify-center text-fs-body not-italic font-medium leading-6 text-secondary/30 bg-secondary/10 py-[0.5rem] px-[1.5rem]">
              {translate('shop.cart.returns_or_exchange.continue')}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReturnsOverview;
