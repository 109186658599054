import React from "react";

function VideoPlayIcon() {
  return (
    <svg
      width="32"
      height="33"
      viewBox="0 0 32 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.5" width="32" height="32" rx="16" fill="#232225" />
      <path
        d="M12.6667 10.667V22.3337L21.8334 16.5003L12.6667 10.667Z"
        fill="#E95F2A"
      />
    </svg>
  );
}

export default VideoPlayIcon;
