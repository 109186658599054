import { useLazyQuery } from '@apollo/client';
import { CHAT_SEARCH_QUERIES } from '@fe-monorepo/data-access';
import { useEffect, useState } from 'react';

import { GetChatInfoResponse, GetSearchInfoParams } from './types';

export const useChatSearch = () => {
  const [isSuccessful, setStatus] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [getSearchInfoGql, { data: getSearchResponse }] = useLazyQuery<GetChatInfoResponse>(CHAT_SEARCH_QUERIES.getSearch, {
    errorPolicy: 'all',
  });

  const getSearch = async (chatInput: GetSearchInfoParams) => {
    setIsLoading(true);
    const { data } = await getSearchInfoGql({
      variables: {
        details: chatInput,
      },
    });
    setIsLoading(false);

    return data?.getSearch?.data;
  };

  useEffect(() => {
    if (getSearchResponse?.getSearch?.is_successful) {
      setStatus(getSearchResponse?.getSearch?.is_successful);
    }

    if (getSearchResponse?.getSearch?.error_msg) {
      setErrorMessage(getSearchResponse?.getSearch?.error_msg);
    }
  }, [getSearchResponse]);

  return {
    getSearch,
    getSearchResponse,
    isSuccessful,
    isLoading,
    errorMessage,
  };
};
