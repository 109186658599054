import { gql } from '@apollo/client';

export const PROMO_WALLET_APPLY_MUTATION = {
  promoWalletApplyMutation: gql`
    mutation applyPromoWallet($details: PromoWalletInput) {
      applyPromoWallet(details: $details) {
        is_successful
        error_code
        error_msg
        response {
          promo_code_applied_successfully {
            is_successful
            error_code
            error_msg
          }
          wallet_discount_applied_successfully {
            is_successful
            error_code
            error_msg
          }
        }
      }
    }
  `,
};
