import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import { NEWSLETTER_MUTATION } from '@fe-monorepo/data-access';
import { SubscribeNewsLetterParamsModel } from './type';

export const useSubscribe = () => {
    const [isSuccessful, setStatus] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const [subscribeNewsLetter, { data: subscribeNewsLetterResponse, error: errorSubscribeNewsLetter }] = useMutation(
        NEWSLETTER_MUTATION.subscribeNewsLetter,
        { errorPolicy: 'all' },
    );

    useEffect(() => {
        if (subscribeNewsLetterResponse) {
            if (subscribeNewsLetterResponse.subscribeNewsLetter.is_successful) {
                setStatus(subscribeNewsLetterResponse.subscribeNewsLetter.is_successful);
            }
            setStatus(subscribeNewsLetterResponse.subscribeNewsLetter.is_successful);
        }
        setIsLoading(false);
    }, [subscribeNewsLetterResponse]);

    useEffect(() => {
        if (errorSubscribeNewsLetter) {
            setError(errorSubscribeNewsLetter.message);
        }
        setIsLoading(false);
    }, [errorSubscribeNewsLetter]);

    const subscribeToNewsLetter = (params: SubscribeNewsLetterParamsModel) => {
        subscribeNewsLetter({ variables: { details: params } });
        setIsLoading(true);
    };

    return {
        subscribeToNewsLetter,
        setStatus,
        isSuccessful,
        isLoading,
        error,
    };
};
