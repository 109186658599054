import { useTranslate } from '@fe-monorepo/hooks';
import Button from '@fe-web/Atoms/Buttons/Button';
import MaskedInput from 'react-text-mask';

interface VerificationScreenProps {
  resend: () => void;
  mobileNumber: string;
  isHeaderDark?: boolean;
  dir?: string;
  verify: () => void;
  updateOTP: (otp: string) => void;
  otp: string;
  errorMessage?: string;
  close: () => void;
}

const STCPayVerificationScreen = ({
  resend,
  mobileNumber,
  isHeaderDark,
  dir,
  verify,
  updateOTP,
  otp,
  errorMessage,
  close,
}: VerificationScreenProps) => {
  const { translate } = useTranslate();
  return (
    <div
      className="
        flex flex-col mt-[20px] justify-start items-center bg-white
        rounded-lg p-[48px] pb-[72px] 2K:p-[80px] 4K:p-[96px] 8K:p-[196px] gap-[20px] max-h-[100vh] overflow-y-auto
        fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[22.375rem] SD:w-[30.31rem]
        2K:w-[30.25rem] 4K:w-[28.625rem] 8K:w-[29.25rem]"
    >
      <div>
        <div>
          <p className="text-fs-big-title font-medium text-black100">{translate('shop.cart.verify_stc_pay.modal_headers')}</p>
        </div>
        <div className="mt-[1rem]">
          <p className="text-fs-body font-medium text-black70">
            {translate('shop.cart.verify_stc_pay.modal_description', { mobile: mobileNumber })}
          </p>
        </div>
      </div>
      <div className="flex flex-col gap-[0.688rem]">
        <div className="flex gap-12 item-center w-full">
          <MaskedInput
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]}
            minLength={4}
            maxLength={6}
            className="w-fit h-[2rem] border-b-[1px] border-[#171619]/20 outline-none text-fs-body-large font-regular text-center bg-transparent"
            guide={false}
            id="stcpayotp"
            onChange={e => updateOTP(e.target.value)}
            value={otp}
          />
          <p className="mt-[0.5rem] text-fs-body text-sunset text-center hover:underline hover:cursor-pointer" onClick={resend}>
            {translate('shop.cart.order_main.resend')}
          </p>
        </div>
        {errorMessage && <span className="font-regular text-fs-body-small text-red">{errorMessage}</span>}
      </div>
      <div className="flex w-full gap-[1rem]">
        <Button
          text={translate('common_cancel') ?? ''}
          style={`
                        text-fs-body font-medium border-[1px] border-sunset mt-8 py-8 2K:mt-[0.775rem] 8K:mt-[24px] 2K:py-[0.675rem]
                        w-full gap-[8px] z-1
                        text-white100 text-sunsetText mb-[0px]
                        xsMax:mb-[0px] items-end w-full
                        rounded-sm ${
                          isHeaderDark
                            ? dir === 'ltr'
                              ? `after:bg-white100 before:bg-sunset`
                              : `before:bg-white100 after:bg-sunset`
                            : `${dir === 'ltr' ? `after:bg-white100 before:bg-sunset` : `before:bg-white100 after:bg-sunset`}`
                        }
                        hover:text-white100 rounded-sm`}
          disabled={false}
          onClick={close}
        />
        <Button
          text={translate('shop.cart.verify_stc_pay.submit_btn') ?? ''}
          fullWidth={true}
          style={`
            text-fs-body font-medium mt-8 2K:py-[0.675rem] 2K:mt-[0.775rem] 8K:mt-[1rem]
            py-[8px]
            w-full gap-[8px] z-1 
            text-white100 border-[1px] border-transparent mb-[0px] 
            xsMax:mb-[0px] items-end
            rounded-sm ${
              otp
                ? isHeaderDark
                  ? dir === 'ltr'
                    ? 'after:bg-sunset before:bg-white100 hover:border-[1px] hover:border-sunset hover:text-sunsetText'
                    : 'before:bg-sunset after:bg-white100 hover:border-[1px] hover:border-sunset hover:text-sunsetText'
                  : `${
                      dir === 'ltr'
                        ? 'after:bg-sunset before:bg-white100 hover:border-[1px] hover:border-sunset hover:text-sunsetText'
                        : 'before:bg-sunset after:bg-white100 hover:border-[1px] hover:border-sunset hover:text-sunsetText'
                    }
                    `
                : 'disabled:bg-secondary/[0.1]'
            } 
            rounded-sm`}
          disabled={!otp}
          onClick={verify}
        />
      </div>
    </div>
  );
};

export default STCPayVerificationScreen;
