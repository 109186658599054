export default function CommentIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M1.66666 18.3334V3.33341C1.66666 2.87508 1.82986 2.48272 2.15625 2.15633C2.48264 1.82994 2.875 1.66675 3.33333 1.66675H16.6667C17.125 1.66675 17.5174 1.82994 17.8437 2.15633C18.1701 2.48272 18.3333 2.87508 18.3333 3.33341V13.3334C18.3333 13.7917 18.1701 14.1841 17.8437 14.5105C17.5174 14.8369 17.125 15.0001 16.6667 15.0001H5L1.66666 18.3334ZM3.33333 14.3126L4.3125 13.3334H16.6667V3.33341H3.33333V14.3126Z"
        fill="var(--secondary-black)"
      />
    </svg>
  );
}
