import { useLazyQuery, useMutation } from '@apollo/client';
import { TMS_MATCHES_MUTATION, TMS_MATCHES_QUERY } from '@fe-monorepo/data-access';
import {
    MatchesQueryResponse,
    MatchesMutationResponse,
    GetMatchesInput,
    SubmitScoreInput,
    ResetMatchInput,
    RemoveRefereeInput,
    ResolveConflictInput,
    AdvanceInput,
    ReportScoreInput,
} from './types';
import { useEffect, useState } from 'react';

export const useMatches = () => {
    const [resetErrorMessage, setResetErrorMessage] = useState<string>('');
    const [submitScoreErrorMessage, setSubmitScoreErrorMessage] = useState<string>('');
    const [reportScoreErrorMessage, setReportScoreErrorMessage] = useState<string>('');
    const [resolveConflictErrorMessage, setResolveConflictErrorMessage] = useState<string>('');
    const [advanceErrorMessage, setAdvanceErrorMessage] = useState<string>('');

    const [getMatchesGql, { data: getMatchesData }] = useLazyQuery<MatchesQueryResponse>(TMS_MATCHES_QUERY.getMatches, {
        errorPolicy: 'all',
    });
    const [submitScoreGql, { data: submitScoreData }] = useMutation<MatchesMutationResponse>(TMS_MATCHES_MUTATION.submitScore, {
        errorPolicy: 'all',
    });
    const [reportScoreGql, { data: reportScoreData, error: reportScoreError }] = useMutation<MatchesMutationResponse>(
        TMS_MATCHES_MUTATION.reportScore,
        {
            errorPolicy: 'all',
        },
    );
    const [resetMatchGql, { data: resetMatchData, error: resetErrorResponse }] = useMutation<MatchesMutationResponse>(
        TMS_MATCHES_MUTATION.resetMatch,
        {
            errorPolicy: 'all',
        },
    );
    const [removeRefereeGql, { data: removeRefereeData }] = useMutation<MatchesMutationResponse>(TMS_MATCHES_MUTATION.removeReferee, {
        errorPolicy: 'all',
    });

    const [resolveConflictGql, { data: resolveConflictData }] = useMutation<MatchesMutationResponse>(TMS_MATCHES_MUTATION.resolveConflict, {
        errorPolicy: 'all',
    });

    const [advanceGql, { data: advanceData }] = useMutation<MatchesMutationResponse>(TMS_MATCHES_MUTATION.advance, {
        errorPolicy: 'all',
    });

    const getMatches = async (details: GetMatchesInput) => {
        const { data } = await getMatchesGql({ variables: { details } });
        return data;
    };

    const submitScore = async (details: SubmitScoreInput, file?: File) => {
        const { data } = await submitScoreGql({ variables: { details, file } });
        if (!data?.tmsMatchesSubmitScore?.is_successful) {
            setSubmitScoreErrorMessage(data?.tmsMatchesSubmitScore?.error_msg || '');
            return;
        }
        return data;
    };

    const reportScore = async (details: ReportScoreInput) => {
        const { data, errors } = await reportScoreGql({ variables: { details } });
        if (!data?.tmsMatchesReportScore?.is_successful) {
            setReportScoreErrorMessage(data?.tmsMatchesReportScore?.error_msg || '');
        }
        return { data, errors };
    };

    const resetMatch = async (details: ResetMatchInput) => {
        const { data } = await resetMatchGql({ variables: { details } });
        if (!data?.tmsMatchesResetMatch?.is_successful) {
            setResetErrorMessage(data?.tmsMatchesResetMatch?.error_msg || '');
            return;
        }
        return data;
    };

    const removeReferee = async (details: RemoveRefereeInput) => {
        const { data } = await removeRefereeGql({ variables: { details } });
        return data;
    };

    const resolveConflict = async (details: ResolveConflictInput) => {
        const { data } = await resolveConflictGql({ variables: { details } });
        if (!data?.tmsMatchesResolveConflict?.is_successful) {
            setResolveConflictErrorMessage(data?.tmsMatchesResolveConflict?.error_msg || '');
            return;
        }
        return data;
    };

    const advance = async (details: AdvanceInput) => {
        const { data } = await advanceGql({ variables: { details } });
        if (!data?.tmsMatchesAdvance?.is_successful) {
            setAdvanceErrorMessage(data?.tmsMatchesAdvance?.error_msg || '');
            return;
        }
        return data;
    };

    return {
        getMatchesData: getMatchesData?.tmsMatchesGetMatches,
        submitScoreData: submitScoreData?.tmsMatchesSubmitScore,
        reportScoreData: reportScoreData?.tmsMatchesReportScore,
        resetMatchData: resetMatchData?.tmsMatchesResetMatch,
        removeRefereeData: removeRefereeData?.tmsMatchesRemoveReferee,
        resolveConflictData: resolveConflictData?.tmsMatchesResolveConflict,
        advanceData: advanceData?.tmsMatchesAdvance,
        resetErrorMessage,
        submitScoreErrorMessage,
        reportScoreErrorMessage,
        resolveConflictErrorMessage,
        advanceErrorMessage,
        getMatches,
        submitScore,
        reportScore,
        resetMatch,
        removeReferee,
        resolveConflict,
        advance,
    };
};
