import { useTranslate } from '@fe-monorepo/hooks';
import { RootHashtag } from '@fe-web/types/bitTypes';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import { useNavigate } from 'react-router-dom';

export type TagType = {
  id: string | number;
  name?: string;
  label: string;
};

const HashtagResult = (props: { hashtags: RootHashtag[] }) => {
  const { hashtags } = props;
  const navigate = useNavigate();
  const { translate } = useTranslate();

  const Item = (props: {
    label: string;
    onClick: (label: string, hashtagChallenge: number, hashtagId: string) => void;
    videoCount: number;
    hashtagChallenge: number;
    hashtagId: string;
  }) => {
    const { label, onClick, videoCount, hashtagChallenge, hashtagId } = props;

    return (
      <li
        className="
          select-none cursor-pointer
          font-regular
          flex
          gap-[0.75rem]
          whitespace-nowrap
          responsive-text-bodySmall
          py-4 2K:py-8 4K:py-12 8K:py-20
          px-8 2K:px-12 4K:px-20 8K:px-44
          rounded-[2px] 2K:rounded-[3.55px] 4K:rounded-[5.33px] 8K:rounded-[10.66px]"
        onClick={() => onClick(label, hashtagChallenge, hashtagId)}
      >
        <div className="w-[2rem] h-[2rem] rounded-full text-bodyLarge flex items-center justify-center bg-secondary/40">#</div>
        <div className="flex flex-col">
          <div className="text-moonlight">{`#${label.charAt(0).toUpperCase() + label.substring(1)}`}</div>
          <div className="text-secondary text-caption">{`${videoCount} ${translate('quickplay.search-video')}`}</div>
        </div>
      </li>
    );
  };

  const hashtagClick = (tagParam: string, hashtagChallenge: number, hashtagId: string) => {
    navigate(`${AppRoutes.bits}/${hashtagChallenge === 1 ? `challenge/${hashtagId}` : `hashtag/${tagParam}`}`);
  };

  return (
    <ul className="flex w-full gap-[1.5rem] 2K:gap-[28px] 4K:gap-[42px] 8K:gap-81 overflow-x-scroll">
      {hashtags.map(hashtag => (
        <Item
          key={hashtag._id}
          label={hashtag.title}
          onClick={hashtagClick}
          videoCount={hashtag.videoCount}
          hashtagChallenge={hashtag.isChallenge}
          hashtagId={hashtag._id}
        />
      ))}
    </ul>
  );
};

export default HashtagResult;
