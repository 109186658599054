import { IMAGES } from '@fe-monorepo/assets';
import { IMAGE_ALT_LABELS, IconNames, MixPanelEvents, TxKeyPath, convertToTimezone } from '@fe-monorepo/helper';
import { useBlock, useChatSearch, useReport, useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import ReportModal from '@fe-web/Organisms/ReportModal/ReportModal';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { ConnectConversationProvider, useConnectProvider } from '@fe-web/hooks/useConnect';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import i18next from 'i18next';
import _ from 'lodash';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { ZIMConversation, ZIMConversationNotificationStatus, ZIMGroupMemberInfoQueriedResult } from 'zego-zim-web';

import GroupPreferenceFullView from '../GroupPreference/GroupPreferenceFullView';
import MoreOption from '../MoreOption/MoreOption';
import OptionsActionModal from '../OptionsActionModal/OptionsActionModal';
import SideBarChatEmpty from './SideBarChatEmpty';

interface ConversationItemProps {
  index: number;
  item: ZIMConversation;
  sortedChatHistory?: ZIMConversation[];
  isRTL?: boolean;
  isFullView?: boolean;
  handleClick?: (conversationID: string, conversationType: number) => void;
  handleInviteUser?: (conversationID: string, conversationType: number) => void;
  handleInviteUserMiniSideBar?: (conversationID: string, conversationType: number) => void;
  isOptionOpenedOnClick?: ((value: boolean) => void) | undefined;
  setIsActionOptionOpen?: (value: boolean) => void;
  setOptionActionType?: (value: 'clear' | 'block' | 'delete' | 'leave' | 'dismiss' | 'report') => void;
  setUserName?: (value: string) => void;
  setIsGroupChat?: (value: boolean) => void;
  setShowReportModal?: (value: boolean) => void;
  setOpenGroupPreferences?: (value: boolean) => void;
  setConversationID?: (value: string) => void;
}

const ConversationItem = ({
  item,
  index,
  sortedChatHistory,
  isRTL,
  isFullView,
  handleClick,
  handleInviteUser,
  handleInviteUserMiniSideBar,
  isOptionOpenedOnClick,
  setIsActionOptionOpen,
  setOptionActionType,
  setUserName,
  setIsGroupChat,
  setShowReportModal,
  setOpenGroupPreferences,
  setConversationID,
}: ConversationItemProps) => {
  const [isShowMoreOptions, setIsShowMoreOptions] = useState(-1);
  const [isChatEmpty, setIsChatEmpty] = useState(false);
  const [groupData, setGroupData] = useState<GroupData>();
  const [isUserMember, setIsUserMember] = useState<ZIMGroupMemberInfoQueriedResult | undefined>();
  const [isBlocking, setIsBlocking] = useState<boolean>(false);
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const { crmCommunityUnblock } = useBlock();
  const { queryGroupMemberInfo, queryConversationList, conversationListModified, queryConversationNotificationStatus, setBlockTrigger } =
    useConnectProvider();
  const userState = useSelector((state: RootState) => state.user.userContext);
  const sentinelRef = useRef(null);
  const [isReachedEnd, setIsReachedEnd] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const language = usePageLayout();

  const dateCompute = (value: number) => {
    const date_now = convertToTimezone(moment().toString());
    const date_from = convertToTimezone(moment(value).toString());
    const checkIfMoreThanADay = !date_now.isSame(date_from, 'd');
    let date;
    if (checkIfMoreThanADay) {
      date = language.language === 'en' ? date_from.format('D MMM') : date_from.locale(i18next.language).format('D MMM');
    } else {
      date = language.language === 'en' ? date_from.format('h:mm a') : date_from.locale(i18next.language).format('h:mm a');
    }
    return date;
  };

  const handleClickMoreOption = async (groupID: string, chatType: number) => {
    if (chatType === 2) {
      let isUserAdmin = queryGroupMemberInfo && (await queryGroupMemberInfo(userState.username, groupID));
      setGroupData({
        conversationID: groupID,
        isUserAdmin: (isUserAdmin && isUserAdmin.userInfo.memberRole === 1) || false,
      });
      setConversationID?.(groupID);
      setIsUserMember(isUserAdmin);
      setIsGroupChat?.(chatType === 2);
    }
  };

  const goToProfile = (userID: string) => {
    navigate(AppRoutes.users + `/${userID}`);
  };

  const unblock = (username: string, displayName: string) => {
    setTimeout(() => {
      crmCommunityUnblock({ username: username }).then(res => {
        if (res?.crmCommunityUnblock.is_successful) {
          toastWrapper('success', translate('msg_success_user_unblocked', { username: displayName }) ?? '');
          setIsBlocking?.(false);
          setBlockTrigger?.(blockTrigger => !blockTrigger);
        } else {
          toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
        }
      });
    }, 500);
  };

  useEffect(() => {
    // loads new data if reach ref element
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(async entry => {
          if (entry.isIntersecting && !isReachedEnd && !isLoading) {
            setIsLoading(true);
            if (queryConversationList) {
              const response = await queryConversationList(_.last(conversationListModified));
              setIsReachedEnd(!response || response?.conversationList?.length <= 0);
            }
            setIsLoading(false);
          }
        });
      });
      observer.observe(sentinelRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [sentinelRef.current, isReachedEnd, isLoading, sortedChatHistory]);

  const handleBlockCheck = (value: boolean) => {
    setIsBlocking(value);
  };

  const handleClickMute = (status: ZIMConversationNotificationStatus, conversationID: string, conversationType: number) => {
    if (conversationID) {
      queryConversationNotificationStatus?.(status, conversationID, conversationType);
    }
  };

  return (
    <div
      className="flex flex-row rounded-[.25rem] p-[.25rem] gap-[.5rem] hover:cursor-pointer hover:bg-zinc-100-neutral-800"
      onMouseEnter={() => setIsShowMoreOptions(index + 1)}
      onMouseLeave={() => setIsShowMoreOptions(-1)}
    >
      <div
        className="relative"
        onClick={event => {
          if (item.type !== 2) {
            navigate(AppRoutes.users + `/${item.conversationID}`);
          } else {
            handleClick?.(item.conversationID, item.type);
          }
        }}
      >
        {item.type === 0 || (item.type === 2 && item.conversationAvatarUrl) ? (
          <Image
            img={item.conversationAvatarUrl ? item.conversationAvatarUrl : IMAGES.Avatar}
            divStyle="flex items-center justify-center object-cover bg-black/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden h-40 w-40 2K:h-80 2K:w-80 4K:h-100 4K:w-100 8K:h-200 8K:w-200"
            imgStyle="h-40 w-40 2K:h-80 2K:w-80 4K:h-100 4K:w-100 8K:h-180 8K:w-200 object-cover"
            alt={translate(IMAGE_ALT_LABELS.conversation_list_chat_avatar as TxKeyPath) ?? ''}
          />
        ) : item.type === 2 ? (
          <ResponsiveIcon
            name={IconNames.icon_chat_group}
            baseWidth={25}
            baseHeight={25}
            iconClasses="fill-lightBlue"
            className="flex items-center justify-center object-cover bg-lightBlue/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden h-40 w-40 2K:h-80 2K:w-80 4K:h-100 4K:w-100 8K:h-200 8K:w-200"
          />
        ) : null}
      </div>
      {/*CHAT DETAILS*/}
      <div className="flex flex-col min-w-[85%] w-[85%] text-start pr-[1rem]" onClick={() => handleClick?.(item.conversationID, item.type)}>
        {/*CHAT CONVERSATION NAME/GROUP ICON*/}
        <div
          className={`flex flex-row text-fs-body-small text-secondary truncate pr-[.5rem] gap-[.5rem] w-[90%] items-center 
            ${item.unreadMessageCount > 0 ? 'font-medium' : ''}`}
        >
          <p className="max-w-[78%] truncate">{item.conversationName}</p>
          {item.type === 2 && (
            <ResponsiveIcon
              name={IconNames.icon_chat_group}
              baseWidth={20}
              baseHeight={20}
              iconClasses="fill-lightBlue"
              className="items-end pb-[.25rem]"
            />
          )}
        </div>
        {/*CHAT CONVERSATION*/}
        <div className="relative flex flex-row text-neutral-400-zinc-500 gap-[.25rem] items-start text-fs-caption">
          <p className={`max-w-[50%] truncate ${item.unreadMessageCount > 0 ? 'text-secondary dark:text-white100 font-medium' : ''}`}>
            {item.lastMessage?.message}
          </p>
          <p>&middot;</p>
          <p className="w-[30%] pr-[5%] 4K:w-[40%]">{dateCompute(item?.lastMessage?.timestamp || item.orderKey)}</p>
          {item.notificationStatus === 1 ? (
            //IS SEEN
            item.unreadMessageCount > 0 && (
              <ResponsiveIcon
                name={IconNames.iconcirclefilledv2}
                baseWidth={10}
                baseHeight={10}
                iconClasses="fill-moonlight"
                className={`
                  absolute ${item.type === 0 ? 'bottom-[70%]' : 'bottom-[85%]'}
                  ${
                    isRTL && isShowMoreOptions === index + 1
                      ? 'left-[10%]'
                      : !isRTL && isShowMoreOptions === index + 1
                      ? 'right-[10%]'
                      : isRTL
                      ? 'left-4'
                      : 'right-4'
                  }
                `}
              />
            )
          ) : (
            //IS MUTED
            <ResponsiveIcon
              name={IconNames.muted_icon}
              baseWidth={20}
              baseHeight={20}
              iconClasses="fill-neutral-400-zinc-500"
              className={`
                absolute bottom-[60%]
                ${
                  isRTL && isShowMoreOptions === index + 1
                    ? 'left-[10%]'
                    : !isRTL && isShowMoreOptions === index + 1
                    ? 'right-[10%]'
                    : isRTL
                    ? 'left-0'
                    : 'right-0'
                }
              `}
            />
          )}
          {/*CHAT 3 DOTS*/}
          {isShowMoreOptions === index + 1 && (
            <div
              onClick={event => {
                isOptionOpenedOnClick && isOptionOpenedOnClick(true);
                event.stopPropagation();
              }}
              onMouseLeave={() => isOptionOpenedOnClick && isOptionOpenedOnClick(false)}
              className={`${isShowMoreOptions === index + 1 ? 'opacity-100' : 'opacity-0'} absolute ${
                item.type === 2 ? 'bottom-[50%]' : 'bottom-[30%]'
              } z-100 ${isRTL ? 'left-0' : 'right-0'}`}
            >
              {isShowMoreOptions && (
                <MoreOption
                  chatType={`${item.type === 2 ? 'group' : 'private'}`}
                  origin={isFullView ? 'side-bar-full' : 'side-bar-minimized'}
                  userType={item.type !== 2 ? 'regular' : groupData?.isUserAdmin ? 'admin' : 'regular'}
                  isMuted={item.notificationStatus === 2}
                  onClickMute={() => handleClickMute(item.notificationStatus === 1 ? 2 : 1, item.conversationID, item.type)}
                  setOpenGroupPreferences={() => setOpenGroupPreferences?.(true)}
                  isChatEmpty={isChatEmpty}
                  setOptionActionType={type => {
                    setUserName?.(item.conversationName);
                    setConversationID?.(item.conversationID);
                    setOptionActionType?.(type);
                    if (type === 'report') {
                      setShowReportModal?.(true);
                    } else if (type === 'leave' && item.type === 2) {
                      setIsActionOptionOpen?.(true);
                    } else {
                      setIsActionOptionOpen?.(true);
                    }
                  }}
                  goToProfile={() => goToProfile(item.conversationID)}
                  onClickMoreOption={() => handleClickMoreOption(item.conversationID, item.type)}
                  openFullWindow={() => {
                    navigate(AppRoutes.messages + `/${item.conversationID}/${item.type}`);
                  }}
                  isUserMember={item.type === 2 ? (isUserMember ? true : false) : true}
                  conversationID={item.conversationID}
                  checkBlocking
                  returnCheckBlocking={handleBlockCheck}
                  unblock={value => unblock(value, item.conversationName)}
                  handleInviteUser={() => handleInviteUser?.(item.conversationID, item.type)}
                  setInviteUsers={() => handleInviteUserMiniSideBar?.(item.conversationID, item.type)}
                />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

interface SideBarChatProps {
  isFullView?: boolean;
  onSearch: boolean;
  isRTL: boolean;
  searchQuery: string;
  isOptionOpenedOnClick?: (value: boolean) => void;
  setIsInviteUser?: (value: boolean) => void;
  chatHistoryList: ZIMConversation[];
  onClickChat?: (conversationID: string, conversationType: number) => void;
  isFromFullViewEmpty?: boolean;
  inviteFromMiniSideBar?: (value: boolean) => void;
}

export type GroupData = {
  conversationID: string;
  isUserAdmin: boolean;
};

const SideBarChat = ({
  isFullView = false,
  onSearch,
  searchQuery,
  isOptionOpenedOnClick,
  isRTL,
  chatHistoryList,
  onClickChat,
  isFromFullViewEmpty,
  setIsInviteUser,
  inviteFromMiniSideBar,
}: SideBarChatProps) => {
  const [isChatEmpty, setIsChatEmpty] = useState(false); //modify for integration of clear chat
  const [isActionOptionOpen, setIsActionOptionOpen] = useState(false); //open clear chat modal
  const [optionActionType, setOptionActionType] = useState<'clear' | 'block' | 'delete' | 'leave' | 'dismiss' | 'report'>('clear');
  const [userName, setUserName] = useState<string>('');
  const [isGroupChat, setIsGroupChat] = useState<boolean>(false);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [openGroupPreferences, setOpenGroupPreferences] = useState<boolean>(false);
  const [conversationID, setConversationID] = useState<string>('');
  const location = useLocation();
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const { createTheReport } = useReport();
  const { queryConversationList, conversationList, setBlockTrigger } = useConnectProvider();
  const sentinelRef = useRef(null);
  const [isReachedEnd, setIsReachedEnd] = useState<boolean>();
  const [isLoading, setIsLoading] = useState<boolean>();
  const { setIsModalOpen } = useNotificationProvider();
  const user = useSelector((state: RootState) => state.user.userContext);

  const sortedChatHistory = _.orderBy(chatHistoryList, o => o.lastMessage?.timestamp || o.orderKey, 'desc');

  const handleClick = (conversationID: string, conversationType: number) => {
    const isGroup = conversationType === 2;
    mixpanelHelper.trackEvent(MixPanelEvents.chatWindowOpened, {
      'Chat ID': conversationID,
      'Chat Type': isGroup ? 'Group Chat' : 'Private',
      Username: user.username ?? 'GUEST',
    });
    if (
      location.pathname.includes(AppRoutes.messages) &&
      location.pathname !== AppRoutes.messages + `/${conversationID}/${conversationType}`
    ) {
      navigate(AppRoutes.messages + `/${conversationID}/${conversationType}`, { replace: true });
    } else {
      onClickChat?.(conversationID, conversationType);
    }
  };

  const handleInviteUser = (conversationID: string, conversationType: number) => {
    if (isFromFullViewEmpty) {
      navigate(AppRoutes.messages + `/${conversationID}/${conversationType}`, { replace: true });
    }
    setIsInviteUser && setIsInviteUser(true);
  };

  const handleInviteUserMiniSideBar = (conversationID: string, conversationType: number) => {
    onClickChat?.(conversationID, conversationType);
    inviteFromMiniSideBar?.(true);
  };

  const handleAction = () => {
    setIsActionOptionOpen(false);
    isOptionOpenedOnClick && isOptionOpenedOnClick(false);
  };

  const cLoseAction = () => {
    isOptionOpenedOnClick && isOptionOpenedOnClick(false);
    setIsActionOptionOpen(false);
  };

  const handleSubmitReport = (code: string, content: string) => {
    createTheReport({
      flag_code: code,
      reference_content_id: conversationID, //Change conversation ID here on API Integration.
      reference_content_type: 'chat',
      flag_content: content,
      reported_username: userName,
    }).then(res => {
      res?.createReport.is_successful
        ? toastWrapper('success', translate('msg_success_user_report', { username: userName }) ?? '')
        : toastWrapper('error', translate('msg_failed_generic_try_again') ?? '');
    });
    setShowReportModal(false);
  };

  useEffect(() => {
    // loads new data if reach ref element
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(async entry => {
          if (entry.isIntersecting && !isReachedEnd && !isLoading) {
            setIsLoading(true);
            if (queryConversationList) {
              const response = await queryConversationList(_.last(conversationList));
              setIsReachedEnd(!response || response?.conversationList?.length <= 0);
            }
            setIsLoading(false);
          }
        });
      });
      observer.observe(sentinelRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [sentinelRef.current, isReachedEnd, isLoading, sortedChatHistory]);

  useEffect(() => {
    const modalCheck = isActionOptionOpen || showReportModal;
    setIsModalOpen?.(modalCheck);
  }, [isActionOptionOpen, showReportModal]);

  return (
    <>
      <div className="flex flex-col h-full gap-[.25rem] px-[1rem] pb-[1rem] overflow-y-scroll">
        {!onSearch ? (
          sortedChatHistory.map((item, index) => {
            return (
              <ConversationItem
                key={index}
                index={index}
                item={item}
                sortedChatHistory={sortedChatHistory}
                isRTL={isRTL}
                isFullView={isFullView}
                handleClick={handleClick}
                handleInviteUser={handleInviteUser}
                handleInviteUserMiniSideBar={handleInviteUserMiniSideBar}
                isOptionOpenedOnClick={isOptionOpenedOnClick}
                setIsActionOptionOpen={setIsActionOptionOpen}
                setOptionActionType={setOptionActionType}
                setUserName={setUserName}
                setIsGroupChat={setIsGroupChat}
                setShowReportModal={setShowReportModal}
                setOpenGroupPreferences={setOpenGroupPreferences}
                setConversationID={setConversationID}
              />
            );
          })
        ) : (
          <SideBarChatSearchResult searchQuery={searchQuery} onClickChat={onClickChat} />
        )}
        <div className="absolute bottom-0 opacity-0" ref={sentinelRef}></div>
      </div>
      <ConnectConversationProvider conversationID={conversationID} conversationType={isGroupChat ? 2 : 1}>
        <GroupPreferenceFullView
          isGroupPreferenceOpen={openGroupPreferences}
          isGroupPreferenceClose={() => setOpenGroupPreferences(false)}
        />
        <OptionsActionModal
          isOpen={isActionOptionOpen}
          isClose={cLoseAction}
          type={optionActionType}
          handleAction={handleAction}
          userName={userName}
          isGroupChat={isGroupChat}
          conversationID={conversationID}
          setIsChatEmpty={() => setIsChatEmpty(true)}
          isMinimized={!isFullView}
          setIsBlocking={() => setBlockTrigger?.(blockTrigger => !blockTrigger)}
          userID={conversationID}
        />
      </ConnectConversationProvider>
      <ReportModal
        setIsReportModalOpen={setShowReportModal}
        handleSubmitReportButton={handleSubmitReport}
        title={translate('chat.report_user.header_title', { name: userName }) ?? ''}
        isOpen={showReportModal}
        subtitle={translate('chat.report_user.subtitle') ?? ''}
      />
    </>
  );
};

interface SideBarChatSearchResultProps {
  searchQuery: string;
  onClickChat?: (conversationID: string, conversationType: number) => void;
}

const SideBarChatSearchResult = ({ searchQuery, onClickChat }: SideBarChatSearchResultProps) => {
  const { translate } = useTranslate();
  const { conversationList } = useConnectProvider();
  const [chatSearchResult, setChatSearchResult] = useState<ZIMConversation[]>();
  const { isLoading } = useChatSearch();
  const navigate = useNavigate();

  useEffect(() => {
    setChatSearchResult(
      conversationList
        ?.filter(item => {
          return (
            item.conversationName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            item.conversationID.toLowerCase().includes(searchQuery.toLowerCase())
          );
        })
        .sort((a: any, b: any) => {
          return a.conversationName.localeCompare(b.conversationName);
        }),
    );
  }, [searchQuery]);

  const highlightedUsername = (username: string) => {
    const styledUsername = (
      <Highlighter
        highlightClassName="dark:text-white text-black bg-transparent"
        unhighlightClassName="text-otherGray"
        searchWords={[searchQuery]}
        autoEscape={true}
        textToHighlight={username}
      />
    );
    return styledUsername;
  };

  const onChatClick = (conversationID: string, conversationType: number) => {
    if (
      location.pathname.includes(AppRoutes.messages) &&
      location.pathname !== AppRoutes.messages + `/${conversationID}/${conversationType}`
    ) {
      navigate(AppRoutes.messages + `/${conversationID}/${conversationType}`, { replace: true });
    } else {
      onClickChat?.(conversationID, conversationType);
    }
  };

  return (
    <div className="flex flex-col text-fs-body-small font-regular h-full gap-[.75rem] overflow-y-scroll">
      <p className="text-zinc-600-400">
        {translate('chat.sidebar.search.users') + ' (' + (searchQuery.length >= 3 && chatSearchResult ? chatSearchResult.length : 0) + ')'}
      </p>
      {isLoading ? (
        <div className="text-secondary w-full flex flex-col col-span-4 justify-center items-center">
          <span className="text-fs-body-large font-regular">{translate('common_loading') ?? 'Loading'}...</span>
        </div>
      ) : chatSearchResult && chatSearchResult.length > 0 && searchQuery.length >= 3 ? (
        chatSearchResult.map(item => {
          return (
            <div
              className="flex flex-col rounded-[.25rem] p-[.25rem] gap-[.5rem] hover:cursor-pointer hover:bg-zinc-100-neutral-800"
              onClick={() => onChatClick(item.conversationID, +item.type)}
            >
              <div className="flex flex-row gap-[.5rem]">
                {item.type === 2 && !item.conversationAvatarUrl ? (
                  <div className="h-40 w-40 bg-lightBlue/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden 2K:h-80 2K:w-80 4K:h-140 4K:w-140 8K:h-200 8K:w-200">
                    <ResponsiveIcon
                      name={IconNames.icon_chat_group}
                      baseWidth={25}
                      baseHeight={25}
                      iconClasses="fill-lightBlue"
                      className="flex items-center justify-center h-40 w-40 2K:h-80 2K:w-80 4K:h-140 4K:w-140 8K:h-200 8K:w-200"
                    />
                  </div>
                ) : (
                  <Image
                    img={item.conversationAvatarUrl ? item.conversationAvatarUrl : IMAGES.Avatar}
                    alt={translate(IMAGE_ALT_LABELS.conversation_avatar as TxKeyPath) ?? ''}
                    divStyle="h-40 w-40 bg-black/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden 2K:h-80 2K:w-80 4K:h-140 4K:w-140 8K:h-200 8K:w-200"
                    imgStyle="h-full w-full object-cover"
                  />
                )}
                <div className="flex flex-col gap-[.25rem] max-w-[75%] ">
                  <p className="truncate">{highlightedUsername(item.conversationName)}</p>
                  <p className="text-zinc-600-400 truncate">@{highlightedUsername(item.conversationID)}</p>
                </div>
              </div>
            </div>
          );
        })
      ) : (
        <SideBarChatEmpty isFromSearchbar={true} />
      )}
    </div>
  );
};

export default SideBarChat;
