import { RootState } from '@fe-monorepo/store';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

export enum theme {
  dark = 'dark',
  light = 'defaultTheme',
}

const useTheme = () => {
  const location = useLocation();
  const themes = useSelector((state: RootState) => state.app.themes);

  const setTheme = (value: theme) => {
    window.localStorage.setItem('theme', value);
    window.document.body.className = '';
    if (
      (location.pathname.includes('shop') && !location.pathname.includes('wishlist') && !location.pathname.includes('shopping')) ||
      location.pathname.includes('payment')
    ) {
      window.document.body.classList.add('light');
    } else {
      window.document.body.classList.add(value);
    }
  };

 

  return {
    setTheme,
    theme: themes,
    isDarkTheme: themes === theme.dark,
  };
};

export default useTheme;
