import { gql } from '@apollo/client';

export const PROMO_INFO_QUERY = {
  getPromoInfo: gql`
    query getPromoInfo($details: PromoInfoInput) {
      getPromoInfo(details: $details) {
        is_successful
        error_code
        error_msg
        response {
          name
          code
          percentage
          start_date
          end_date
          name_en
          name_ar
          collection_code
        }
      }
    }
  `,
};
