import { IconNames } from '@fe-monorepo/helper';
import { GetChatInfoDataModel, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import { useConnectConversationProvider, useConnectProvider } from '@fe-web/hooks/useConnect';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Oval } from 'react-loader-spinner';
import { useSelector } from 'react-redux';
import { ZIMMessage } from 'zego-zim-web';

import Greetings from './Greetings';
import MessageBubble from './MessageBubble';

interface MessageContentProps {
  greetings?: string;
  users?: GetChatInfoDataModel[];
  isMinimized?: boolean;
  isInviteUsers?: boolean;
}

interface ImageViewerProps {
  imageUrl?: string;
  isOpen?: boolean;
  onClose?: () => void;
}

const ImageViewer = ({ imageUrl, isOpen, onClose }: ImageViewerProps) => (
  <>
    {isOpen && (
      <div className="fixed flex items-center justify-center h-full w-full top-0 left-0 z-[1] pt-[5rem] bg-primary/[0.8] backdrop-blur">
        <div className="flex flex-col gap-[1.125rem] items-end">
          <button onClick={() => onClose?.()}>
            <ResponsiveIcon name={IconNames.close1} baseHeight={20} baseWidth={20} iconClasses="fill-secondary" />
          </button>

          <Image
            img={imageUrl}
            imgStyle="w-auto min-w-[21rem] h-auto max-w-screen max-h-[70vh] rounded-[0.25rem] oveflow-hidden"
            divStyle=""
          />
        </div>
      </div>
    )}
  </>
);

const MessageContent: React.FC<MessageContentProps> = ({ users, isMinimized, isInviteUsers }) => {
  const { translate } = useTranslate();
  const messagesEndRef = useRef<HTMLDivElement>(null);
  const isRtl = useSelector((state: RootState) => state.app.isRTL);
  const {
    filteredMessageList,
    displayName,
    avatarUrl,
    conversationType,
    isMessageListLoading,
    isProfileLoading,
    conversationID,
    onSendMessageReceipt,
  } = useConnectConversationProvider();
  const { clearConversationUnreadMessageCount, isConversationListLoaded } = useConnectProvider();
  const { user } = useUserProfile();
  const [isShowImageViewer, setIsShowImageViewer] = useState<boolean>();
  const [imageViewerImage, setImageViewerImage] = useState<string>();

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  const usersText = useMemo(() => {
    const usersDisplayName = users?.slice(0, 3).map(user => user.display_name);
    const usersDisplayNameText =
      usersDisplayName && usersDisplayName?.length > 0
        ? usersDisplayName?.join(', ').substring(0, 29) + (usersDisplayName && usersDisplayName?.join(', ').length > 29 ? '...' : '')
        : undefined;
    const name = displayName ?? usersDisplayNameText;
    return (
      <div className="flex flex-col text-center">
        {name && <p className="text-secondary">{name}</p>}
        {users && users?.length > 3 && <p className="flex justify-center items-center">{`+${users?.length - 3}`}</p>}
      </div>
    );
  }, [users, displayName]);

  const onClickMediaMessageBubble = (downloadUrl: string) => {
    setImageViewerImage(downloadUrl);
    setIsShowImageViewer(true);
  };

  const readAllMessages = useCallback(() => {
    const unreadMessagesList = filteredMessageList?.filter(item => item.receiptStatus === 1 && item.senderUserID != user?.username);
    conversationID && clearConversationUnreadMessageCount?.(conversationID, conversationType || 0);
    unreadMessagesList && unreadMessagesList?.length > 0 && onSendMessageReceipt?.(unreadMessagesList);
  }, [filteredMessageList, conversationID, conversationType, user?.username]);

  useEffect(scrollToBottom, [filteredMessageList]);

  useEffect(() => {
    // loads new data if reach ref element
    if (messagesEndRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(async entry => {
          if (entry.isIntersecting) {
            readAllMessages();
          }
        });
      });
      observer.observe(messagesEndRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [messagesEndRef.current, readAllMessages]);

  return (
    <div className="px-[1rem] pt-[1rem] flex flex-col-reverse custom-scroll overflow-y-auto w-full h-full pb-[.5rem]">
      {isInviteUsers ? (
        <div className="w-full h-[15rem]"></div>
      ) : filteredMessageList && filteredMessageList?.length > 0 ? (
        <div className="p-[0.25rem] space-y-4">
          <div className="flex flex-col">
            {filteredMessageList.map((message: ZIMMessage, index: number) => (
              <MessageBubble
                message={message}
                index={index}
                key={index}
                onClickMediaMessageBubble={onClickMediaMessageBubble}
                isMinimized={isMinimized}
                isRTL={isRtl}
              />
            ))}
          </div>
          <div ref={messagesEndRef}></div>
        </div>
      ) : (
        <>
          {isMessageListLoading || isProfileLoading || !isConversationListLoaded ? (
            <div className="w-full h-full grid items-center justify-center">
              <div className="w-full inline-flex justify-center">
                <Oval
                  height={40}
                  width={40}
                  color="#E95F2A"
                  secondaryColor="#E95F2A"
                  visible={true}
                  ariaLabel="oval-loading"
                  strokeWidth={2}
                  strokeWidthSecondary={2}
                />
              </div>
            </div>
          ) : (
            <div className="flex flex-col gap-[1rem] justify-center items-center h-full">
              <div className="flex flex-col gap-[0.5rem] items-center">
                <div className="h-40 w-40 bg-lightBlue/[0.2 border border-gray-200-zinc-700 rounded-full overflow-hidden">
                  {(users && users.length > 1) || conversationType === 2 ? (
                    <Image
                      img={avatarUrl}
                      divStyle="h-full w-full"
                      imgStyle="h-full w-full object-cover"
                      fallback={
                        <ResponsiveIcon
                          name={IconNames.icon_chat_group}
                          baseWidth={22}
                          baseHeight={22}
                          className="flex items-center justify-center h-full w-full bg-lightBlue/[0.2]"
                          iconClasses="fill-lightBlue"
                        />
                      }
                    />
                  ) : (
                    <Image
                      img={avatarUrl ? avatarUrl : users && users?.length ? users[0].avatar_url : ''}
                      divStyle="h-full w-full"
                      imgStyle="h-full w-full object-cover"
                      fallback={
                        <ResponsiveIcon
                          name={IconNames.avatar}
                          baseHeight={24}
                          baseWidth={24}
                          className="h-full w-full fill-gray flex items-center justify-center"
                        />
                      }
                    />
                  )}
                </div>
                {usersText && <p className="font-medium text-fs-body-small">{usersText}</p>}
              </div>
              {((users && users.length < 2) || (!filteredMessageList?.length && conversationType === 0)) && (
                <Greetings greetings={translate('empty_greet') ?? ''} />
              )}
            </div>
          )}
        </>
      )}
      {!isInviteUsers && (
        <ImageViewer
          isOpen={isShowImageViewer}
          imageUrl={imageViewerImage}
          onClose={() => {
            setImageViewerImage(undefined);
            setIsShowImageViewer(false);
          }}
        />
      )}
    </div>
  );
};

export default MessageContent;
