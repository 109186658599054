import styled from 'styled-components';
import tw from 'twin.macro';

const StyledMainSection = styled.div`
  ${tw`SD:col-span-3 flex flex-col gap-y-32 SD:gap-y-40`}
`;

const StyledSideSection = styled.div`
  ${tw`SD:col-span-1`}
`;

const StyledH2 = styled.h2`
  ${tw`font-medium text-fs-title text-secondary`}
`;

const StyledH3 = styled.h2`
  ${tw`font-medium text-lg text-secondary`}
`;

const StyledHeaderContainer = styled.div`
  ${tw`relative SD:col-span-4`}
`;

export { StyledH2, StyledH3, StyledHeaderContainer, StyledMainSection, StyledSideSection };
