import { OrderQueryResponse, ShopWishlistInput, WishlistData, WishlistMutationResponse } from '@fe-monorepo/models';
import { Quantity } from '@fe-web/hooks/useQuantity';
import { useTranslation } from 'react-i18next';

import ProductDetail from '../../../../../../lib/types/productDetailedPage';
import ProductComponent from '../BrandNameDetails';
import BuyNowAddToCart from '../BuyNowAddToCart';
import OutOfStockSection from './Components/OutOfStockSection';
import ProductImages from './Components/ProductImages/ProductImages';
import ProductVariants from './Components/ProductVariants';
import QuantitySection from './Components/QuantitySection';
import StaticDetails from './Components/StaticDetails';
import { StyledMainSectionContainer } from './MainSection.styled';

interface Props {
  product: ProductDetail;
  variants: number[];

  isAvailable: boolean;
  quantity: Quantity;

  navigateToSimilarProducts: () => void;
  setVaraints: (index: number, number: number) => void;
  addToCart: () => void;
  buyNow: () => void;
  isButtonDisabled?: boolean;
  checkIsSuccess?: (errorMessage: string) => void;
  isPageLoaded?: boolean;
  quantityErrorMessage?: string;
  isTaxable: number;
  wishlistData: WishlistData[] | undefined;
  addToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  removeToWishList: (shopWishlistInput: ShopWishlistInput) => Promise<WishlistMutationResponse | null | undefined>;
  getAllWishlist: (getAllWishlistInput: { last_cursor: string; direction: string }) => Promise<OrderQueryResponse | undefined>;
}

type DigitalProduct = {
  brand: { en: string; ar: string };
  name: { en: string; ar: string };
  price: number;
  isPhysical: any;
  variants: { value: { id: number; name: { en: string; ar: string } }[] };
  images: string[];
};

const MainSection = (props: Props) => {
  const { t } = useTranslation();
  const { product, isAvailable, isPageLoaded, quantityErrorMessage, wishlistData, getAllWishlist, addToWishList, removeToWishList } = props;
  const translate = useTranslation();
  const language = translate.i18n.language;
  const producName = language === 'en' ? product.name['en'] : product.name['ar'];
  const productBrand = language === 'en' ? product.brand['en'] : product.brand['ar'];
  const collection_code = product.brand['collection_code'];

  const variantsLength = product?.variants?.length ?? 0;

  return (
    <StyledMainSectionContainer>
      <ProductImages
        isAvailable={isAvailable}
        name={product.name['en']}
        media={product.images ?? []}
        tag={'Random Tag!'}
        sellingPrice={product.sellingPrice}
        price={product.price}
        product_code={props.product.product_code}
        checkIsSuccess={props.checkIsSuccess}
        quantityErrorMessage={quantityErrorMessage}
        wishlistData={wishlistData}
        addToWishList={addToWishList}
        removeToWishList={removeToWishList}
        getAllWishlist={getAllWishlist}
        brand={product.brand.en}
      />

      <div className="flex flex-col gap-24 2K:gap-[42.66px] 4K:gap-64 8K:gap-[128px]">
        <ProductComponent
          brandName={productBrand}
          productName={producName}
          price={product.price}
          sellingPrice={product.sellingPrice}
          vatIncluded={t('VATincluded')}
          ctaLink=""
          collection_code={collection_code}
          isTaxable={props.isTaxable}
        />

        {variantsLength > 0 && <ProductVariants variants={props?.variants} setVaraints={props?.setVaraints} product={product} />}

        {product.product_code && product.isPhysical && (
          <QuantitySection
            isAvailable={isAvailable}
            quantity={props.quantity}
            quantityErrorMessage={quantityErrorMessage}
            isPageLoaded={isPageLoaded}
          />
        )}

        {isAvailable && <BuyNowAddToCart addToCart={props.addToCart} isButtonDisabled={props.isButtonDisabled} buyNow={props.buyNow} />}
        {(isAvailable || (!isAvailable && quantityErrorMessage !== '')) && (
          <>
            <StaticDetails price={product.price} isPhysical={product.isPhysical} />

            {/* UNCOMMENT FOR BUYER PROTECTION COMPONENT */}
            {/* <BuyerProtection /> */}
          </>
        )}
        {!isAvailable && quantityErrorMessage === '' && <OutOfStockSection navigateToSimilarProducts={props.navigateToSimilarProducts} />}
      </div>
      <div id="DetailsScroll"></div>
    </StyledMainSectionContainer>
  );
};

export default MainSection;
