import { useLazyQuery } from '@apollo/client';
import { COLLECTION_PRODUCTS_QUERY } from '@fe-monorepo/data-access';
import { BannerInfoDataModel, BrandCollectionInput, ProductCollectionInput, ShopBrandModel, ShopProductsModel } from '@fe-monorepo/models';
import { useState } from 'react';

interface DataResponse {
  count: number;
  list: ShopProductsModel[];
  banner_info: BannerInfoDataModel;
}
export interface ProductResponse {
  getAllProducts: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: DataResponse;
  };
}
export interface BrandResponse {
  getAllBrands: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: ShopBrandModel[];
  };
}

export const useShopListingProducts = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [getAllProducts, { data: products, error: sectionsError }] = useLazyQuery<ProductResponse>(
    COLLECTION_PRODUCTS_QUERY.getAllProducts,
    { errorPolicy: 'all' },
  );
  const [getAllBrands] = useLazyQuery<BrandResponse>(COLLECTION_PRODUCTS_QUERY.getAllBrands, { errorPolicy: 'all' });

  const getAll = async (params: ProductCollectionInput) => {
    setIsLoading(true);
    const { data } = await getAllProducts({
      variables: { details: params },
    });

    setIsLoading(false);
    return data?.getAllProducts;
  };

  const getAllBrand = async (params: BrandCollectionInput) => {
    const { data } = await getAllBrands({
      variables: { details: params },
    });
    return data?.getAllBrands;
  };

  return {
    getAll,
    getAllBrand,
    isLoading,
    setIsLoading,
    dataProducts: products?.getAllProducts?.data?.list,
    count: products?.getAllProducts?.data?.count,
    banner_info: products?.getAllProducts?.data?.banner_info,
  };
};
