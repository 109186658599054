import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type SliceState = {
    previousLocation: string;
    currentLocation: string;
    menuTitle: string;
    selectedMenu: string;
    menuObject: any;
};

export const locationSlice = createSlice({
    name: 'location',
    initialState: {
        previousLocation: '/profile/settings',
        currentLocation: '',
        menuTitle: '',
        selectedMenu: '',
        menuObject: {},
    } as SliceState,
    reducers: {
        setCurrentLocation: (state, action) => {
            state.previousLocation = state.currentLocation;
            state.currentLocation = action.payload;
        },
        setMenuTitle: (state, action) => {
            state.menuTitle = action.payload;
        },
        setSelectedMenu: (state, action) => {
            state.selectedMenu = action.payload;
        },
        setMenuObject: (state, action) => {
            state.menuObject = action.payload;
        },
    },
});

export const { setCurrentLocation, setMenuTitle, setSelectedMenu, setMenuObject } = locationSlice.actions;
