import { IMAGES } from '@fe-monorepo/assets';
import { IconNames, PAGE_DESCRIPTION, PAGE_KEYWORDS, PAGE_TITLE, TxKeyPath } from '@fe-monorepo/helper';
import { useProduct, useShopInfo, useShopListingProducts, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { BannerInfoDataModel, ShopProductsModel } from '@fe-monorepo/models';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation, useParams } from 'react-router-dom';

import { CategoryData, ProductListTracker, createProductListTracker } from '../../../../../../helper/webAnalytics';
import HorizontalLine from '../../../../../app/components/Atoms/HorizontalLine';
import ResponsiveIcon from '../../../../../app/components/Atoms/Icon/ResponsiveIcon';
import DropDown from '../../../../../app/components/Molecules/InputFields/DropDown/DropDown';
import ProductListingCard from '../../../../../app/components/Organisms/Product/ProductListingCard';
import useMobileDetect from '../../../../hooks/useMobileDetect';
import usePageLayout from '../../../../hooks/usePageLayout';
import { useShopContext } from '../../ShopLayout';

export type Layout = 'grid' | 'list';
export type ProductSort = { id: string; label: string };

interface ListingBannerProps {
  title: string;
  code: string;
  description: string;
  img: string;
  language: any;
  discount: string;
  isMobile: boolean;
  bannerImg: string;
}

interface ShopBannerInfo extends BannerInfoDataModel {
  title_en?: string;
  title_ar?: string;
  description_en?: string;
  description_ar?: string;
  code_en?: string;
  code_ar?: string;
  discount?: string;
  img?: string;
}

// TODO: What is the aspect ratio of this banner???
const ListingBanner = (props: ListingBannerProps) => {
  return (
    <div className="flex flex-col items-start justify-center self-stretch h-fit bg-darkPurple rounded-[0.5rem] px-[1rem] SD:px-[2.5rem] py-[1rem] SD:py-[1.5rem] gap-y-[0.625rem text-white100">
      {props.title && props.code && props.img ? (
        <>
          <div>
            <p className="text-fs-body-small font-regular">{props.title}</p>
          </div>
          <div>
            <span className="font-medium text-fs-body-large SD:text-fs-subtitle">
              Use this code <span className="text-moonlight">{props.code}</span> {` for ${props.discount} ${props.description}`}
            </span>
          </div>
          {!props.isMobile && (
            <div
              className={`absolute w-fit h-fit ${
                props.language === 'en'
                  ? 'right-[150px] 4xl:right-[200px] 2K:right[220px] 4K:right-[375px] 8K:right-[780px]'
                  : 'left-[150px] 4xl:left-[200px] 4K:left-[375px] 8K:left-[780px]'
              } mix-blend-screen`}
            >
              <LazyLoadImage className="w-[5rem]" src={props.img} />
            </div>
          )}
        </>
      ) : props.bannerImg ? (
        <LazyLoadImage className="w-[3.875rem]" src={props.img} />
      ) : null}
    </div>
  );
};

export const LayoutOptions = (props: { currentLayout: Layout; select: (layout: Layout) => void }) => {
  const { currentLayout, select: selectLayout } = props;

  const layoutOptions: { name: Layout; icon: IconNames }[] = [
    {
      name: 'grid',
      icon: IconNames.grid,
    },
    {
      name: 'list',
      icon: IconNames.list,
    },
  ];

  return (
    <div className="flex items-center py-[4px] gap-[15px] 2K:gap-[26.66px] 4K:gap-40 8K:gap-80">
      {layoutOptions.map(layout => {
        return (
          <ResponsiveIcon
            className={`cursor-pointer w-fit ${currentLayout === layout.name ? 'fill-secondary' : 'fill-secondary/[0.42]'}`}
            name={layout.icon}
            key={layout.name}
            baseHeight={24}
            baseWidth={24}
            onClick={() => selectLayout(layout.name)}
          />
        );
      })}
    </div>
  );
};

const ResultsText = (props: { amount: number; category: string }) => {
  const { t } = useTranslation();

  const { amount, category } = props;

  return (
    <p
      className={`
        text-secondary
        font-regular
        text-bodyLarge 2K:text-title 4K:text-fourKSubtitle 8K:text-eightKSubtitle
      `}
    >
      <span className="font-medium">{amount} </span>

      <span>{t('shop.itemsIn')}</span>

      <span className="font-medium"> {category}</span>
    </p>
  );
};

interface ShopCompaignInt {
  title_en: string;
  code_en: string;
  title_ar: string;
  code_ar: string;
  discount: string;
  description_en: string;
  description_ar: string;
  img: string;
}

const ShopCampaign: ShopCompaignInt = {
  title_en: 'Build your own PlayStation® bundle',
  code_en: 'BUNDLE',
  title_ar: 'مفتاح مدمج بدون كيبورد',
  code_ar: 'مفتاح مدمج بدون كيبورد',
  discount: '15% off',
  description_en: 'any 3 Sony products in cart',
  description_ar: 'مفتاح مدمج بدون كيبورد',
  img: IMAGES.PlayStation.toString(),
};

const Content = () => {
  const { t } = useTranslation();
  const { translate } = useTranslate();
  const location = useLocation();
  const { collectionCode } = useParams();
  const { shopCategory } = useShopContext();

  const [layout, setLayout] = useState<Layout>('grid');
  const { language } = usePageLayout();

  const { getAll } = useShopListingProducts();
  const { getMenuProducts } = useShopInfo();

  const shopSort: ProductSort[] = [
    { id: 'asc', label: 'shop.filters.price.low-to-high' },
    { id: 'desc', label: 'shop.filters.price.high-to-low' },
  ];

  const [selectedSort, setSelectedSort] = useState<ProductSort>(shopSort[0]);

  const [products, setProducts] = useState<ShopProductsModel[]>([]);
  const [bannerInfo, setBannerInfo] = useState<ShopBannerInfo | undefined>();
  const [lastProductList, setLastProductList] = useState<ShopProductsModel[]>([]);

  const isMobile = useMobileDetect();

  const { wishlistData, getAllWishlist, addToWishList, removeToWishList } = useProduct();
  const productListTracker = useRef<ProductListTracker>();

  const sentinelRef = useRef(null);
  const [lastCursor, setLastCursor] = useState<number>(0);
  const [count, setCount] = useState<number>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [hasReachedEnd, setHasReachedEnd] = useState<boolean>(false);
  const { isLoggedIn } = useUserProfile();

  useEffect(() => {
    isLoggedIn && getAllWishlist({ direction: '', last_cursor: '' });
  }, [isLoggedIn]);

  useEffect(() => {
    setLastCursor(0);
    setIsLoading(true);
  }, [collectionCode, selectedSort]);

  useEffect(() => {
    if (!shopCategory.main?.menu_code || !collectionCode) return;

    const categoryName = shopCategory.main ? shopCategory.main.name_en : '';
    const subCategoryName = shopCategory.sub ? shopCategory.sub.name_en : '';

    const categoryInfo: CategoryData = {
      categoryID: shopCategory.main?.menu_code ?? '',
      categoryName: categoryName,

      subCategoryID: collectionCode,
      subCategoryName: subCategoryName,
    };

    productListTracker.current = createProductListTracker(categoryInfo);
  }, [shopCategory.main?.menu_code]);

  useEffect(() => {
    const init = async () => {
      const newCode = collectionCode?.slice(2);
      let bannerInfo;
      let dataProducts: any[] = [];
      let count = 0;
      if (collectionCode?.startsWith('c-') && newCode) {
        const params = { collection_code: '', sort_type: '', last_cursor: lastCursor, direction: 'next' };
        if (collectionCode) {
          params['collection_code'] = newCode;
        }
        if (selectedSort.id) {
          params['sort_type'] = selectedSort.id;
        }

        const getAllProductsData = await getAll(params);
        dataProducts = getAllProductsData?.data?.list ?? [];
        count = getAllProductsData?.data?.count ?? 0;
        /** GET BANNER INFO FOR PLP **/
        if (getAllProductsData?.data?.banner_info && getAllProductsData?.data?.banner_info.reference_type) {
          bannerInfo = getAllProductsData.data.banner_info;
        }
      } else if (newCode) {
        const params = { menu_code: '', sort_type: '', last_cursor: lastCursor, direction: 'next' };
        if (collectionCode) {
          params['menu_code'] = newCode;
        }
        if (selectedSort.id) {
          params['sort_type'] = selectedSort.id;
        }
        const getAllProductsData = await getMenuProducts(params);
        dataProducts = getAllProductsData?.data?.list ?? [];
        count = getAllProductsData?.data?.count ?? 0;
        /** GET BANNER INFO FOR PLP **/
        if (getAllProductsData?.data?.banner_info && getAllProductsData?.data?.banner_info.reference_type) {
          bannerInfo = getAllProductsData.data.banner_info;
        }
      }
      if (dataProducts && dataProducts?.length > 0) {
        setProducts([...(lastCursor > 0 ? products : []), ...dataProducts]);
        setLastProductList(dataProducts);
        setCount(count);
        const lastProduct = _.last(dataProducts);
        setLastCursor(lastProduct?.last_cursor || 0);
      }
      if (count > 0 && dataProducts && dataProducts?.length === 0) {
        setHasReachedEnd(true);
      }
      /** GET BANNER INFO FOR PLP **/
      if (bannerInfo && bannerInfo.reference_type) {
        setBannerInfo(bannerInfo);
      }
      setIsLoading(false);
    };
    if (isLoading) init();
  }, [isLoading]);

  useEffect(() => {
    if (productListTracker.current && lastProductList && lastProductList.length > 0) {
      productListTracker.current.track(lastProductList);
    }
  }, [lastProductList, productListTracker.current]);

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !hasReachedEnd) {
            setIsLoading(true);
          }
        });
      });

      observer.observe(sentinelRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [hasReachedEnd, setIsLoading, sentinelRef.current]);

  return (
    <>
      <CustomHelmet
        pageTitle={translate(PAGE_TITLE.shop as TxKeyPath) ?? ''}
        metaDescription={translate(PAGE_DESCRIPTION.shop as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.shop as TxKeyPath) ?? ''}
      />
      <main className="w-full flex flex-col gap-[1.5rem]">
        {bannerInfo?.reference_type ? (
          <ListingBanner
            title={bannerInfo[`title_${language}`] ?? ''}
            code={bannerInfo[`code_${language}`] ?? ''}
            description={bannerInfo[`description_${language}`] ?? ''}
            img={bannerInfo['img'] ?? ''}
            discount={bannerInfo['discount'] ?? ''}
            language={language}
            isMobile={isMobile}
            bannerImg={isMobile ? bannerInfo[`mobile_media_url_${language}`] : bannerInfo[`web_media_url_${language}`]}
          />
        ) : null}

        <div className="flex flex-col justify-between gap-24 SD:flex-row SD:gap-0">
          <div className="flex items-center gap-16 2K:gap-[28.44px] 4K:gap-[42.66px] 8K:gap-[85.33px]">
            <ResultsText amount={count || 0} category={shopCategory.sub ? shopCategory.sub[`name_${language}`] : ''} />
          </div>

          <div className="flex justify-between w-full sm:w-fit">
            <LayoutOptions currentLayout={layout} select={setLayout} />

            <div className="flex items-center gap-[24px] px-[24px]">
              <div className="h-[18px] 2K:h-32 4K:h-[48px] 8K:h-[96px] w-[1px] 2K:w-[1.77px] 4K:w-[2.66px] 8K:w-[5.33px] bg-secondary/20" />
            </div>

            <DropDown<ProductSort>
              style={{
                container:
                  'justify-self-end rounded-[4px] 2K:rounded-[7.11px] 4K:rounded-[10.66px] 8K:rounded-[21.33px] px-[12px] SD:px-insent 4K:px-24 8K:px-48 py-[6px] 2K:py-[10.66px] 4K:py-16 8K:py-32 bg-black05',
                text: 'relative flex flex-col justify-center items-start font-regular text-secondary responsive-text-body gap-[0.5rem] w-[10.75rem]',
                listContainer: 'w-[13.75rem]',
              }}
              selectedOption={selectedSort}
              selectedIcon={
                <ResponsiveIcon
                  className={`z-[1] fill-sunset absolute ${language === 'en' ? 'right-[10px]' : 'left-[10px]'}`}
                  name={IconNames.checkFill}
                  baseHeight={16.67}
                  baseWidth={16.67}
                />
              }
              selectedIconPosition="start"
              options={shopSort}
              getStringValue={(option: ProductSort) => t(option.label)}
              retreiveSelection={(option: ProductSort) => setSelectedSort(option)}
            />
          </div>
        </div>

        <div>
          <section
            className={`w-full flex min-h-[140px] 2K:min-h-[248.89px] 4K:min-h-[373.33px] 8K:min-h-[746.67px] ${
              layout === 'grid' ? `flex-wrap gap-y-40 SD:gap-y-[4rem] gap-x-[1rem] SD:gap-x-[2%]` : 'flex-col gap-y-12 SD:gap-y-0'
            }`}
          >
            {products?.length > 0 &&
              products?.map((product, index) => {
                const isLastProduct = products.length - 1 === index;
                const isWishList = wishlistData
                  ? wishlistData.find(item => item.product_code === product.product_code)
                    ? true
                    : false
                  : false;

                return (
                  <>
                    <ProductListingCard
                      key={index}
                      collectionCode={collectionCode}
                      layout={layout}
                      product={product}
                      isMobile={isMobile}
                      language={language}
                      containerClass={`${isMobile && '!w-[47.5%]'} SD:w-[23.5%]`}
                      twoColMobile={true}
                      isWishlist={isWishList}
                      addToWishList={addToWishList}
                      removeToWishList={removeToWishList}
                      getAllWishlist={getAllWishlist}
                    />

                    {!isLastProduct && layout === 'list' && (
                      <HorizontalLine
                        style={{
                          container: 'flex justify-center',
                          line: 'flex my-[24px] SD:my-[24px] 2K:my-[35.55px] 4K:my-[53.33px] 8K:my-[106.66px] border-t-0 border-b-[1px] SD:border-b-[1px] 2K:border-b-[1.77px] 4K:border-b-[2.66px] 8K:border-b-[5.33px] border-secondary/20',
                        }}
                      />
                    )}
                  </>
                );
              })}
          </section>
          <div className="absolute bottom-0 w-0 h-0 opacity-0" ref={sentinelRef}></div>
        </div>
      </main>
    </>
  );
};

export default Content;
