import { useUserProfile } from '@fe-monorepo/hooks';
import { NotificationMessageData } from '@fe-monorepo/models';
import Skeleton from '@fe-web/Atoms/Skeleton/Skeleton';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import { t } from 'i18next';
import useVerifyDialogToggle from 'libs/hooks/src/useVerifyDialogToggle';
import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';

import NotificationCard from '../EcommerceAndChat/NotificationCard';
import BitsModerationDetails from './BitsModerationDetails';
import VerifyUserNotification from './VerifyUserNotification';

interface Props {
  isMinimized?: boolean;
}

const SidebarNotificationList = ({ isMinimized = false }: Props) => {
  const location = useLocation();
  const { user } = useUserProfile();
  const { toggleVerifyDialog } = useVerifyDialogToggle();
  const isMaximized = location.pathname.includes('/notifications');
  const sentinelRef = useRef(null);
  const [selectedModerationCtx, setSelectedModerationCtx] = useState<any>(null);
  const [showLoadingText, setShowLoadingText] = useState<boolean>(true);
  const [isMomentumReachEnd, setIsMomentumReachEnd] = useState<boolean>(false);
  const { notificationList, nextCursor, getAllNextList } = useNotificationProvider();
  const [stateList, setStateList] = useState<NotificationMessageData[]>([]);

  const NotificationSkeleton = () => (
    <div className="w-full flex items-center justify-between gap-[0.5rem]">
      {/* Left Section: Avatar + Text */}
      <div className="w-full flex items-center justify-start gap-[0.5rem]">
        {/* Avatar */}
        <div className="w-fit self-start">
          <Skeleton width="w-[2.5rem]" height="h-[2.5rem]" className="rounded-full" />
        </div>

        {/* Text Section */}
        <div className="inline-flex flex-col self-start gap-[0.25rem]">
          <Skeleton width="w-[8rem]" height="h-[1rem]" />
          <Skeleton width="w-[12rem]" height="h-[1rem]" />
          <div className="flex items-center gap-[0.25rem]">
            <Skeleton width="w-[6rem]" height="h-[0.75rem]" />
          </div>
        </div>
      </div>

      {/* Right Section: Action Button / Thumbnail */}
      <div className="flex flex-row items-center gap-[0.5rem]">
        <Skeleton width="w-[2.5rem]" height="h-[2.5rem]" className="rounded-[0.125rem]" />
        <div className="w-[1.5rem] h-[1.5rem] flex items-center justify-center">
          <Skeleton width="w-[.5rem]" height="h-[1.5rem]" />
        </div>
      </div>
    </div>
  );

  // Groups the notification on the list based on their unix epoch timestamp
  const groupByDate = (date: string) => {
    const today = Date.now();
    const unixDate = parseInt(date);
    const yesterday = today - 1 * 24 * 60 * 60 * 1000;
    const sevenDaysAgo = today - 7 * 24 * 60 * 60 * 1000;
    if (unixDate > yesterday) {
      return t('wallet_new_account');
    }
    if (unixDate > sevenDaysAgo) {
      return t('common_this_week');
    }
    return t('common_older');
  };

  useEffect(() => {
    setStateList(notificationList);
  }, [notificationList]);

  useEffect(() => {
    // loads new data if reach ref element
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && !isMomentumReachEnd) {
            if (!nextCursor) {
              // stop load when end
              setIsMomentumReachEnd(true);
              setShowLoadingText(false);
            }
            if (nextCursor) {
              // sets last cursor for pagination then load more
              getAllNextList?.();
            }
          }
        });
      });
      observer.observe(sentinelRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [isMomentumReachEnd]);

  const isVerifyMotivationNeeded = !user?.email || !user?.is_email_verified || !user?.mobile || !user?.is_mobile_verified;

  const clearSelectedModerationCtx = () => {
    setSelectedModerationCtx(null);
  };

  return (
    <div className="flex flex-col w-full h-full gap-[1.25rem] overflow-y-scroll">
      {selectedModerationCtx ? (
        <BitsModerationDetails
          time={selectedModerationCtx?.time}
          image={selectedModerationCtx.image}
          body={selectedModerationCtx?.body}
          title={selectedModerationCtx?.title}
          asset_title={selectedModerationCtx?.asset_title}
          status={selectedModerationCtx?.status}
          goBack={clearSelectedModerationCtx}
        />
      ) : (
        <>
          {isVerifyMotivationNeeded && <VerifyUserNotification onClick={() => toggleVerifyDialog(true)} />}
          {stateList.map((data, i) => {
            const group = groupByDate(data.created as string);
            return (
              <div key={i}>
                {group !== groupByDate(stateList[i - 1]?.created as string) && (
                  <div className={`pb-[1.25rem] font-regular text-fs-caption text-neutral-400-zinc-500 ${isMinimized && 'px-[1.25rem]'}`}>
                    {group}
                  </div>
                )}
                <div className={`${!isMaximized && 'px-[1.25rem]'}`}>
                  <NotificationCard
                    isMinimized={isMinimized}
                    id={data?.id}
                    title={data?.title}
                    body_en={data?.body_en}
                    body_ar={data?.bodyHTML_ar}
                    bodyHTML_en={data?.bodyHTML_en}
                    bodyHTML_ar={data?.bodyHTML_ar}
                    image={data?.image}
                    event_type={data.event_type}
                    is_read={data.is_read}
                    created={data.created}
                    notification_type={data.notification_type}
                    conversation_id={data.conversation_id}
                    conversation_type={data?.conversation_type}
                    type={data?.type}
                    avatar_url={data?.avatar_url}
                    conversation_name={data?.conversation_name}
                    invoice_number={data?.invoice_number}
                    return_number={data?.return_number}
                    collection_code={data?.collection_code}
                    product_code={data?.product_code}
                    mini_game_code={data?.mini_game_code}
                    game_code={data?.game_code}
                    tournament_id={data?.tournament_id}
                    username={data?.username}
                    currentTime={data?.currentTime}
                    order_id={data?.order_id}
                    sender_username={data?.sender_username}
                    media_url={data?.media_url}
                    image_url={data?.image_url}
                    follower_notification_type={data?.follower_notification_type}
                    follower_display_name={data?.follower_display_name}
                    follower_username={data?.follower_username}
                    is_following={data?.is_following}
                    title_ar={data?.title_ar}
                    title_en={data?.title_en}
                    message={data?.message}
                    asset_id={data?.asset_id}
                    description={data?.description}
                    asset_type={data?.asset_type}
                    comment_parent_id={data?.comment_parent_id}
                    comment_reply_id={data?.comment_reply_id}
                    video_thumbnail={data?.video_thumbnail}
                    setSelectedModerationCtx={setSelectedModerationCtx}
                    asset_title={data?.asset_title}
                    status={data?.status}
                  />
                </div>
              </div>
            );
          })}
        </>
      )}
      {!selectedModerationCtx && (
        <div
          className={`text-secondary w-full flex flex-col col-span-4 gap-[1.25rem] justify-center items-center ${
            isMinimized && 'px-[1.25rem]'
          }`}
          ref={sentinelRef}
        >
          {showLoadingText && [...Array(8)].map((_, i) => <NotificationSkeleton key={i} />)}
        </div>
      )}
    </div>
  );
};

export default SidebarNotificationList;
