import './Skeleton.scss';

import { ClassNameValue, twMerge } from 'tailwind-merge';

interface SkeletonProps {
  width: ClassNameValue;
  height: ClassNameValue;
  variant?: 'text' | 'circle' | 'box';
  className?: ClassNameValue;
  children?: React.ReactNode;
}

const Skeleton = ({
  width,
  height,
  variant = 'box',
  className = '',
  children,
}: SkeletonProps) => {
  return (
    <div
      className={twMerge(
        'bg-secondary/20 opacity-50 relative overflow-hidden',
        variant === 'circle' ? 'rounded-full' : 'rounded-md',
        width,
        height,
        className,
      )}>
      <div className="skeleton-shimmer" />
      {children}
    </div>
  );
};

export default Skeleton;
