import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { toast } from 'react-toastify';

const Toast = (props: { message: string; type: 'success' | 'error' }) => {
  return (
    <div className="h-fit w-full SD:w-fit bg-white rounded-[4px] 2K:rounded-[7.11px] 4K:rounded-[10.66px] 8K:rounded-[21.33px] overflow-hidden">
      <div
        className={`h-fit bg-green/20 ${
          props.type === 'success' ? 'bg-green/20' : 'bg-red/20'
        } flex items-center py-12 2K:py-[21.33px] 4K:py-32 8K:py-64 px-16 2K:px-[28.44px] 4K:px-[42.66px] 8K:px-[85.33px] gap-8 2K:gap-[14.22px] 4K:gap-[21.33px] 8K:gap-[42.66px] min-w-[335px] XGA:min-w-[588px] 2K:min-w-[1045px] 4K:min-w-[1568px] 8K:min-w-[3136px]`}
      >
        <ResponsiveIcon
          iconClasses={`${props.type === 'error' && 'fill-red'}`}
          name={props.type === 'success' ? IconNames.checkCircle : IconNames.error}
          mobileSize={{ height: 20, width: 20 }}
          baseHeight={24}
          baseWidth={24}
        />

        <p className={`${props.type === 'success' ? 'text-green' : 'text-red'} text-fs-body-small XGA:text-fs-body not-italic font-medium`}>
          {props.message}
        </p>
      </div>
    </div>
  );
};

export const toastWrapper = (type: 'success' | 'error', message: string) => {
  toast(<Toast type={type} message={message} />, {
    position: 'top-center',
    hideProgressBar: true,
    closeButton: false,
    style: { padding: 0 },
  });
};
