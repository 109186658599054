import { IconNames } from '@fe-monorepo/helper';
import PrimaryButton from '@fe-web/Atoms/Buttons/PrimaryButton';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import useOutsideClick from '@fe-web/hooks/useOutsideClick';
import { t } from 'i18next';
import { ChangeEvent, DragEvent, useEffect, useRef, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type ImageShape = 'circle' | 'rectangle';

const ImageInput = (props: { changeImageFile: (imageFile: File) => void }) => {
  const imageInputRef = useRef<HTMLInputElement>(null);

  const handleDrop = (e: DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    props.changeImageFile(file);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files ?? null;
    if (files && files.length > 0) {
      props.changeImageFile(files[0]);
    }
  };

  return (
    <div
      className="border-dashed border-zinc-600 border-[0.0625rem]
            rounded-[0.25rem]
            w-full
            flex flex-col items-center
            py-24 2K:py-[42.66px] 4K:py-64 8K:py-[128px]
            gap-8 2K:gap-[14.22px] 4K:gap-[21.33px] 8K:gap-[42.66px]
            font-regular"
      onDragOver={e => e.preventDefault()}
      onDrop={handleDrop}
    >
      <p className="text-gray90 font-regular text-fs-body-small">{t('formAttribute_image_input')}</p>
      <HoverText
        className="text-sunset text-fs-body py-[0.125rem]"
        hover={{ color: 'text-sunset' }}
        alwaysShowUnderline={false}
        underlineStyle="z-1 static bg-sunset h-[0.0625rem]"
        text={t('action_browse_files') ?? ''}
        onClick={() => imageInputRef.current?.click()}
      />

      <input ref={imageInputRef} className="hidden" type="file" accept={imageTypes.join(',')} onChange={onInputChange} />
    </div>
  );
};

const PreviewImage = (props: { imageShape: ImageShape; isLoading: boolean; fileName: string; src: string; remove: () => void }) => {
  return (
    <div className="mt-[1rem] mb-[1rem] w-full flex flex-col XGA:flex-row items-center justify-between">
      <div className="inline-flex SD:flex-row flex-col items-center gap-[1.5rem] w-full SD:w-[22.5rem]">
        <LazyLoadImage
          className={`
            object-cover
            ${
              props.imageShape === 'circle'
                ? 'grow-0 shrink-0 aspect-square rounded-full h-[108px] 2K:h-[192px] 4K:h-[288px] 8K:h-[576px]'
                : 'h-[64px] 2K:h-[113.77px] 4K:h-[170.66px] 8K:h-[341.33px] aspect-[27/16] rounded-[5px] 2K:rounded-[8.88px] 4K:rounded-[13.33px] 8K:rounded-[26.66px]'
            }`}
          src={props.src}
        />

        <p className="text-ellipsis w-[inherit] overflow-x-hidden font-regular text-fs-body-small text-zinc-600-400">{props.fileName}</p>
      </div>

      <ResponsiveIcon
        iconClasses={`fill-sunset cursor-pointer ${props.isLoading && 'invisible'}`}
        name={IconNames.deleteOutline}
        baseHeight={16}
        baseWidth={16}
        onClick={() => !props.isLoading && props.remove()}
      />
    </div>
  );
};

const imageTypes = ['image/png', 'image/jpeg'];

interface Props {
  imageShape?: ImageShape;
  isLoading: boolean;
  title: string;
  subtitle: string;
  close: () => void;
  save: (imageFile: File, path: string) => void;
}

const ImagePickerModal = (props: Props) => {
  const modalRef = useOutsideClick(props.close);
  const saveButtonRef = useRef<HTMLButtonElement | null>(null);
  const clickEnter = () => saveButtonRef.current?.click();
  const [imageFile, setImageFile] = useState<File>();
  const [previewURL, setPreviewURL] = useState<string>('');

  const handleFileChange = (file: File) => {
    if (file && imageTypes.includes(file.type)) {
      setImageFile(file);
    }
  };

  const removeFile = () => setImageFile(undefined);

  useEffect(() => {
    if (!imageFile) {
      return;
    }
    const objectURL = URL.createObjectURL(imageFile);
    setPreviewURL(objectURL);
    return () => {
      if (objectURL) {
        URL.revokeObjectURL(objectURL);
      }
    };
  }, [imageFile]);

  useEffect(() => {
    const keyDownHandler = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        clickEnter();
      }
    };
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, []);

  return (
    <div className="fixed top-0 left-0 z-50 flex items-center justify-center w-screen h-screen bg-black100/50">
      <div
        ref={ref => (modalRef.current = ref)}
        className="
          bg-primary
          min-w-[375px]
          w-[39.75rem] 2K:w-[960px] 4K:w-[1440px] 8K:w-[2880px]
          rounded-[8px] 2K:rounded-[14.22px] 4K:rounded-[20px] 8K:rounded-[43px]
          border-[1px] 2K:border-[1.77px] 4K:border-[2.66px] 8K:border-[5.33px]
          border-neutral-300-zinc-700
          p-24 XGA:p-48 2K:p-[85.33px] 4K:p-[128px] 8K:p-[256px]
          flex flex-col
          gap-[1.25rem]"
      >
        <div className="flex flex-col gap-4 2K:gap-[7.1px] 4K:gap-[10.66px] 8K:gap-[21.33px]">
          <div className="flex items-start justify-between">
            <p className="font-medium text-secondary responsive-text-subtitle">{props.title}</p>

            <ResponsiveIcon
              className="cursor-pointer fill-secondary"
              name={IconNames.close1}
              baseHeight={20}
              baseWidth={20}
              onClick={props.close}
            />
          </div>

          <p className="font-regular responsive-text-bodySmall text-zinc-600-400">{props.subtitle}</p>
        </div>

        {!imageFile ? (
          <ImageInput changeImageFile={handleFileChange} />
        ) : (
          <PreviewImage
            imageShape={props.imageShape ?? 'rectangle'}
            isLoading={props.isLoading}
            fileName={imageFile?.name ?? ''}
            src={previewURL}
            remove={removeFile}
          />
        )}

        <div className="flex justify-end w-full">
          <PrimaryButton
            ref={saveButtonRef}
            isDisabled={!imageFile}
            isLoading={props.isLoading}
            className="
              w-full XGA:w-fit
              py-8 2K:py-[14.22px] 4K:py-[21.33px] 8K:py-[42.66px]
              px-24 2K:px-[42.66px] 4K:px-64 8K:px-[128px]
              rounded-[2px] 2K:rounded-[3.55px] 4K:rounded-[5.33px] 8K:rounded-[10.66px]"
            disabledStyle="bg-zinc-100-neutral-800"
            text={t('action.save')}
            action={() => imageFile && previewURL && props.save(imageFile, previewURL)}
          />
        </div>
      </div>
    </div>
  );
};

export default ImagePickerModal;
