export const generateFingerprint = async () => {
  const data = {
    userAgent: window.navigator.userAgent,
    // language: window.navigator.language,
    screenResolution: `${window.screen.width}x${window.screen.height}`,
    timeZoneOffset: new Date().getTimezoneOffset(),
  };
  const jsonString = JSON.stringify(data);
  const encoder = new TextEncoder();
  const dataBuffer = encoder.encode(jsonString);
  const fingerprintBuffer = await crypto.subtle.digest('SHA-256', dataBuffer);
  const fingerprintArray = Array.from(new Uint8Array(fingerprintBuffer));
  const fingerprintHex = fingerprintArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
  return fingerprintHex;
};
