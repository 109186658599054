export type FeatureFlag = {
    key: string;
    value: boolean;
};

export enum FEATURE_FLAG_KEYS {
    FB_LOGIN = 'facebookLogin',
    GOOGLE_LOGIN = 'googleLogin',
    APPLE_LOGIN = 'appleLogin',
    TWITTER_LOGIN = 'twitterLogin',
}
