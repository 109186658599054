import { gql } from '@apollo/client';

export const NOTIFICATION_MESSAGE_MUTATION = {
  notificationMessageUpdate: gql`
    mutation notificationMessageUpdate($details: MessageUpdateInput) {
      notificationMessageUpdate(details: $details) {
        error_msg
        error_code
        is_successful
        data {
          id
          title
          body_en
          body_ar
          bodyHTML_en
          bodyHTML_ar
          image
          event_type
          is_read
          created
          notification_type
          conversation_id
          conversation_type
          type
          avatar_url
          conversation_message
          conversation_name
          invoice_number
          shipment_number
          return_number
          collection_code
          product_code
          mini_game_code
          tournament_id
          username
          currentTime
          order_id
          cursor
          media_url
          sender_username
          is_following
          follower_username
          follower_display_name
          follower_notification_type
          game_code
          title_en
          title_ar
          opponent_display_name
        }
      }
    }
  `,

  notificationMessageSend: gql`
    mutation notificationMessageSend($details: MessageSendInput) {
      notificationMessageSend(details: $details) {
        error_msg
        error_code
        is_successful
      }
    }
  `,

  notificationMessageRemoveAll: gql`
    mutation notificationMessageRemoveAll {
      notificationMessageRemoveAll {
        error_msg
        error_code
        is_successful
      }
    }
  `,

  notificationMessageMarkReadAll: gql`
    mutation notificationMessageMarkReadAll {
      notificationMessageMarkReadAll {
        error_msg
        error_code
        is_successful
      }
    }
  `,

  notificationMessageRemove: gql`
    mutation notificationMessageRemove($details: RemoveInput) {
      notificationMessageRemove(details: $details) {
        error_msg
        error_code
        is_successful
      }
    }
  `,
};
