import { useTranslate } from '@fe-monorepo/hooks';
import { InvoiceProduct } from '@fe-monorepo/models';
import OutlineButton from '@fe-web/Atoms/Buttons/OutlineButton';
import PrimaryButton from '@fe-web/Atoms/Buttons/PrimaryButton';
import Products from '@fe-web/Organisms/OrderProduct/OrderProduct';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import usePageLayout from '@fe-web/hooks/usePageLayout';

interface CartPageProductAvailabilityModalProps {
  handleAction: (action: string) => void;
  unavailableProducts?: InvoiceProduct[];
  action: string;
}

const CartPageProductAvailabilityModal = (props: CartPageProductAvailabilityModalProps) => {
  const { handleAction, action, unavailableProducts } = props;
  const { dir } = useHeaderState();
  const { translate } = useTranslate();
  const { language } = usePageLayout();
  return (
    <div
      className="
        flex flex-col mt-[20px] justify-start items-center bg-primary border border-neutral-300-zinc-700
        rounded-lg p-[25px] SD:p-[3rem] 2K:p-[80px] 4K:p-[96px] 8K:p-[196px] gap-[1.25rem] max-h-[100vh] overflow-y-auto
        fixed top-[50%] left-[50%] translate-y-[-50%] translate-x-[-50%] w-[370px] SD:w-[32.25rem]
        2K:w-[796px] 4K:w-[1146px] 8K:w-[2296px]"
    >
      <div className="flex flex-col w-full gap-[2rem]">
        <div>
          <p className="text-fs-title font-medium text-secondary">
            {action === 'lmd'
              ? translate('shop.productPage.outOfStock.SomeItemsCannotBeDeliver')
              : translate('shop.productPage.outOfStock.notAvailProducts')}
          </p>
        </div>
        {action === 'pay' && unavailableProducts && unavailableProducts?.length > 0 && (
          <Products
            products={unavailableProducts || []}
            language={language}
            showCaption={true}
            hideTotalPrice={true}
            showCaptionForUnavailableProduct={true}
          />
        )}
        <div>
          <p className={`${action === 'lmd' ? 'text-fs-body text-opacity-[0.70]' : 'text-fs-body-large'} text-secondary font-regular`}>
            {action === 'lmd'
              ? translate('shop.productPage.outOfStock.continueWithNewLocation')
              : translate('shop.productPage.outOfStock.continueWithoutProduct')}
          </p>
        </div>
      </div>
      <div className="w-full flex flex-col gap-[1.25rem]">
        <PrimaryButton
          className="!text-fs-body px-[1.5rem] py-[0.5rem]"
          text={translate('shop.productPage.outOfStock.yesContinue') ?? ''}
          action={() => handleAction('yes')}
        />
        <OutlineButton
          className="text-fs-body text-secondary font-medium border border-secondary rounded-sm px-[1.5rem] py-[0.5rem]"
          newNormalStyle={`${
            dir === 'ltr' ? 'before:bg-sunset after:bg-white100' : 'after:bg-sunset before:bg-white100'
          } border-sunset text-sunsetText hover:text-white100`}
          text={(action === 'lmd' ? translate('common_cancel') : translate('shop.productPage.outOfStock.backToCart')) ?? ''}
          action={() => handleAction('close')}
        />
      </div>
    </div>
  );
};

export default CartPageProductAvailabilityModal;
