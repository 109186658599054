import React from "react";

function VideoUploadIcon() {
  return (
    <svg
      width="120"
      height="120"
      viewBox="0 0 120 120"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="60" cy="60" r="60" fill="#232225" />
      <path
        d="M57.3332 71.667V49.9337L50.3998 56.867L46.6665 53.0003L59.9998 39.667L73.3332 53.0003L69.5998 56.867L62.6665 49.9337V71.667H57.3332ZM43.9998 82.3337C42.5332 82.3337 41.2776 81.8114 40.2332 80.767C39.1887 79.7225 38.6665 78.467 38.6665 77.0003V69.0003H43.9998V77.0003H75.9998V69.0003H81.3332V77.0003C81.3332 78.467 80.8109 79.7225 79.7665 80.767C78.7221 81.8114 77.4665 82.3337 75.9998 82.3337H43.9998Z"
        fill="#5D5C5E"
      />
    </svg>
  );
}

export default VideoUploadIcon;
