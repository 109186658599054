import { useTranslate } from '@fe-monorepo/hooks';
import { MatchmakingLanguageResponse } from '@fe-monorepo/models';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { useState } from 'react';

interface PlatformProps {
  gameLanguage?: string;
  setGameLanguage: (val: string) => void;
  gameCode?: string;
  existingLanguage?: string;
  languageData?: MatchmakingLanguageResponse[];
}
// language radio button
const Language = (props: PlatformProps) => {
  const [localPlatformData, setLocalLanguageData] = useState<string>('');
  const { translate } = useTranslate();
  const { language } = usePageLayout();

  // highlights the selected language
  let platformToHighlight = '';
  if (props.gameLanguage) {
    platformToHighlight = props.gameLanguage;
  } else {
    platformToHighlight = props.existingLanguage ?? '';
  }

  return (
    <div className="flex flex-col gap-[1rem]">
      <p className="text-secondary responsive-text-bodyLarge font-medium">{translate('menu_changeLanguage') ?? ''}</p>

      <div className="grid grid-cols-2 SD:grid-cols-3 gap-[1rem]">
        {props.languageData?.map((languages, index) => {
          return (
            <button
              key={index}
              className={`cursor-pointer w-full h-[4.25rem] ${
                props.languageData?.length && props.languageData?.length % 2 !== 0 ? 'last:col-span-2 last:SD:col-span-1' : 'col-span-1'
              }
                            responsive-text-bodySmall font-normal text-secondary
                            border-[0.063rem] rounded whitespace-nowrap
                            ${
                              platformToHighlight === languages.lang_code
                                ? `border-sunset bg-sunset/10`
                                : `border-secondary/20 bg-zinc-100-neutral-800`
                            }`}
              onClick={e => {
                setLocalLanguageData(languages.lang_code);
                props?.setGameLanguage(languages.lang_code);
              }}
            >
              <label className="responsive-text-bodySmall font-normal text-secondary  cursor-pointer">
                {languages[`name_${language}`]}
              </label>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Language;
