import { CATEGORY_QUERY, PRODUCT_QUERY } from '@fe-monorepo/data-access';
import { ProductInput, ShopCategoryDataModel, ShopProductDataModel } from '@fe-monorepo/models';

import { useFetchQuery } from '../useFetchQuery';

export const useShop = () => {
  const categories = useFetchQuery<ShopCategoryDataModel[]>(CATEGORY_QUERY.getAllCategory, []);
  const products = useFetchQuery<ShopProductDataModel[], ProductInput>(PRODUCT_QUERY.getAllProduct, []);
  return { categories, products };
};
