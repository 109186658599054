import { IconNames } from '@fe-monorepo/helper';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';

const Loading = () => (
  <div className="z-[9999] fixed w-full h-full bg-primary flex justify-center items-center top-0 left-0">
    <ResponsiveIcon name={IconNames.loading} baseWidth={140} baseHeight={140} iconClasses="h-[8.75rem] w-[8.75rem]" />
  </div>
);

export default Loading;
