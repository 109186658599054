import { useLazyQuery } from '@apollo/client';
import { SHOP_SECTIONS_QUERY } from '@fe-monorepo/data-access';
import { ShopInfoDataModel, ShopProductMenuModel } from '@fe-monorepo/models';
import { RootState, useAppDispatch } from '@fe-monorepo/store';
import { addShopInfo, addShopMenu } from 'libs/store/src/lib/shopSlice/shopInfo';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { useFetchQuery } from '../useFetchQuery';

interface ShopInfoResponse {
  getShopDataInfo: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: ShopInfoDataModel;
  };
}

interface ShopeMenuProductResponse {
  getShopProductMenu: {
    is_successful: boolean;
    error_code: string;
    error_msg: string;
    data: ShopProductMenuModel;
  };
}

interface ShopMenuPayload {
  menu_code: string;
}

const defaultValue: ShopInfoDataModel = {
  menu_code: '',
  name_en: '',
  name_ar: '',
  menu_items: [],
};

export const useShopInfo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();
  const shopInfo = useSelector((state: RootState) => state.shopInfo.shopInfo);
  const [getInfo, { data: info, error: infoError }] = useLazyQuery<ShopInfoResponse>(SHOP_SECTIONS_QUERY.getShopDataInfo, {
    errorPolicy: 'all',
  });

  const [getMenuList, { data: products, error: productsError }] = useLazyQuery<ShopeMenuProductResponse>(
    SHOP_SECTIONS_QUERY.getShopProductMenu,
    {
      errorPolicy: 'all',
    },
  );

  const getShopMenuResponse = useFetchQuery<ShopInfoDataModel, ShopMenuPayload>(SHOP_SECTIONS_QUERY.getShopMenu, defaultValue);

  const getShopProductResponse = useFetchQuery<ShopProductMenuModel, ShopMenuPayload>(
    SHOP_SECTIONS_QUERY.getShopProductMenu,
    {} as ShopProductMenuModel,
  );

  enum SHOP_MENU_CODE {
    HOT_STUFF_FROM_STCPLAY = 'hot_stuff_from_stcplay',
    LIMITED_APP_OFFERS = 'limited_app_offers',
    BEST_FOR_THE_BEST = 'the_best_for_the_best',
    GET_IT_BEFORE_ITS_TOO_LATE = 'get_it_before_its_to_late',
    SHOP_PAGE = 'shop_page',
    SHOP_CARD = 'shop_card',
  }

  const getAll = async (params: ShopMenuPayload) => {
    setIsLoading(true);
    const { data } = await getInfo({
      variables: {
        ...params,
      },
    });
    setIsLoading(false);
    if (data?.getShopDataInfo?.data) {
      dispatch(addShopInfo(data?.getShopDataInfo?.data));
    }
    return data?.getShopDataInfo;
  };

  const getMenuProducts = async (params: ShopMenuPayload) => {
    setIsLoading(true);
    const { data } = await getMenuList({
      variables: {
        details: params,
      },
    });
    setIsLoading(false);
    return data?.getShopProductMenu;
  };

  const findArrayByCollectionCode = (arrayName: any, name: string) => {
    return arrayName.find((data: { collection_code: string }) => data.collection_code === name);
  };

  const getShopContentByMenuCode = (menuCode: string) => {
    return shopInfo?.menu_items?.find(menuItem => menuItem.menu_code === menuCode);
  };

  useEffect(() => {
    if (getShopMenuResponse.data) {
      dispatch(addShopMenu(getShopMenuResponse.data));
    }
  }, [getShopMenuResponse.data]);

  const getProductMenu = async (params: ShopMenuPayload) => {
    setIsLoading(true);
    return getShopProductResponse.fetch(params);
  };

  useEffect(() => {
    if (getShopProductResponse.data) {
      setIsLoading(false);
    }
  }, [getShopProductResponse.data]);

  return {
    getAll,
    getProductMenu,
    getShopContentByMenuCode,
    SHOP_MENU_CODE,
    isLoading,
    setIsLoading,
    getShopMenuResponse,
    getShopProductResponse,
    info: info?.getShopDataInfo,
    findArrayByCollectionCode,
    getMenuProducts,
  };
};
