export const spacing = {
    //COMPONENTS
    0: 0,
    1: 1,
    2: 2,
    3: 3,
    4: 4,
    5: 5,
    6: 6,
    8: 8,
    10: 10,
    12: 12,
    16: 16,
    18: 18,
    20: 20,
    22: 22,
    24: 24,
    28: 28,
    32: 32,
    36: 36,
    38: 38,

    //SECTIONS
    40: 40,
    44: 44,
    48: 48,
    50: 50,
    54: 54,
    56: 56,
    64: 64,
    72: 72,
    80: 80,
    81: 81,
    100: 100,
    120: 120,
    140: 140,
    150: 150,
    160: 160,
    180: 180,
    200: 200,
    203: 203,
    220: 220,
    240: 240,
    260: 260,
    280: 280,
    300: 300,
    320: 320,
    370: 370,
    490: 490,
    500: 500,
    655: 655,
    765: 765,
    1050: 1050,
    2319: 2319,
    4585: 4585,
};

export default spacing;
