import { gql } from '@apollo/client';

export const GENG_CHECKUSER_MUTATION = {
  CheckUser: gql`
    mutation CheckUser($details: CheckUserInput) {
      CheckUser(details: $details) {
        is_successful
        error_code
        error_msg
        app_code
        response
      }
    }
  `,
};

export const GENG_SUBMIT_FORM_MUATTION = {
  SubmitGenGForm: gql`
    mutation SubmitGenGForm($details: GenGUserInput) {
      SubmitGenGForm(details: $details) {
        is_successful
        error_code
        error_msg
        app_code
      }
    }
  `,
};
