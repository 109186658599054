import { IMAGES } from '@fe-monorepo/assets';
import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import Button from '@fe-web/Atoms/Buttons/Button';
import Icon from '@fe-web/Atoms/Icon/Icon';
import LazyImage from '@fe-web/Atoms/Media/LazyImage';
import Modal from '@fe-web/Templates/Modal/Modal';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import useOutsideClick from '@fe-web/hooks/useOutsideClick';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import { useNavigate } from 'react-router-dom';

interface VerifyPhoneModalProps {
  type: 'add' | 'verify' | null;
  open: boolean;
  onClose: () => void;
}

const VerifyPhoneModal = (props: VerifyPhoneModalProps) => {
  const { open, onClose, type } = props;
  const { translate } = useTranslate();
  const { dir } = useHeaderState();
  const navigate = useNavigate();
  const isMobile = useMobileDetect();

  const elementRef = useOutsideClick(() => {
    onClose();
  });

  if (!type) return null;

  return (
    <Modal open={open} backdropClassName="backdrop-blur">
      <div
        ref={ref => (elementRef.current = ref)}
        className="flex flex-col items-center w-[90%] h-[31.25rem] SD:w-[36.75rem] SD:h-[28.4375rem]
          bg-[#4F008C] self-center px-[2rem] py-[1.5rem]"
      >
        <div>
          {type === 'add' ? (
            <Icon name={IconNames.phoneEmpty} width={150} height={180} />
          ) : (
            <LazyImage img={IMAGES.VerifyPhone} alt="No fav" divStyle="" imgStyle="h-[11.25rem] object-cover" />
          )}
        </div>
        <div className="flex flex-col w-full pt-[12px] pb-[2rem] gap-[1.5rem] text-center">
          <h1 className="font-medium text-white text-fs-subtitle SD:text-fs-big-title">
            {translate(type === 'add' ? 'bits_creator.verify_motivation.add_title' : 'bits_creator.verify_motivation.verify_title')}
          </h1>
          <p className="text-white font-regular text-fs-body-large self-center SD:w-[25.9375rem]">
            {translate(
              type === 'add' ? 'bits_creator.verify_motivation.add_description' : 'bits_creator.verify_motivation.verify_description',
            )}
          </p>
        </div>
        <div className="flex flex-col-reverse w-full md:flex-row justify-center gap-[1.25rem] pt-[1rem] pb-[2.5rem]">
          <Button
            text={translate('bits_creator.verify_motivation.cancel')}
            style={`text-base not-italic max-h-[2.5rem] border-[0.063rem] border-white font-medium
                ${isMobile ? 'w-full px-[1rem] py-[0.5rem]' : 'w-fit px-[1.5rem] py-[0.5rem]'}  gap-[0.5rem] z-1
                text-white mb-[0px]
                xsMax:mb-[0px] items-end
                rounded-[0.188rem]
                rounded-[0.188rem]`}
            normalStyle={`
                ${dir === 'ltr' ? 'after:bg-[#4F008C] before:bg-sunset' : 'before:bg-[#4F008C] after:bg-sunset'}
                hover:border-sunset`}
            onClick={onClose}
          />
          <Button
            text={translate(type === 'add' ? 'bits_creator.verify_motivation.add_cta' : 'bits_creator.verify_motivation.verify_cta')}
            style={`text-base not-italic max-h-[2.5rem] font-medium
                ${isMobile ? 'w-full px-[1rem] py-[0.5rem]' : 'w-fit px-[1.5rem] py-[0.5rem]'}  gap-[0.5rem] z-1
                text-white border-sunset border-[0.063rem] mb-[0px]
                xsMax:mb-[0px] items-end
                rounded-[0.188rem] rounded-[0.188rem]`}
            normalStyle={`
                ${dir === 'ltr' ? 'after:bg-sunset before:bg-[#4F008C]' : 'before:bg-sunset after:bg-[#4F008C]'}
                hover:border-white`}
            onClick={() => {
              navigate(`${AppRoutes.settingsRoot}/phone-number`);
              onClose();
            }}
          />
        </div>
      </div>
    </Modal>
  );
};

export default VerifyPhoneModal;
