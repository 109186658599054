import { Logger } from '@fe-monorepo/helper';
import { useEffect, useRef } from 'react';

const useOutsideClick = (callback: () => void, delay?: number) => {
  const ref = useRef<HTMLDivElement | HTMLElement | null>();

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      if (ref.current && !ref.current?.contains(e.target as Node)) {
        setTimeout(() => callback(), delay);
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref, callback]);
  return ref;
};

export default useOutsideClick;

export const useOutsideClickMultiple = (callback: () => void) => {
  const refs = useRef<Set<HTMLDivElement | null>>(new Set());
  const { log } = Logger();

  useEffect(() => {
    const handleClick = (e: MouseEvent) => {
      log('refs: ', refs);
      const result = Array.from(refs.current.values()).every(ref => !ref?.contains(e.target as Node));
      log('result: ', result);
      if (result) {
        callback();
      }
    };
    document.addEventListener('click', handleClick, true);
    return () => {
      document.removeEventListener('click', handleClick, true);
    };
  }, [refs, callback]);

  return {
    addRef: (newRef: HTMLDivElement | null) => {
      if (newRef) {
        refs.current.add(newRef);
      }
    },
  };
};
