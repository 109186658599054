import { PAGE_DESCRIPTION, PAGE_KEYWORDS, PAGE_TITLE, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import CustomHelmet from '@fe-web/Organisms/CustomHelmet/CustomHelmet';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import mixpanelHelper from '@fe-web/helpers/mixpanelHelper';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { useEffect, useState } from 'react';

import SidebarNotificationFilters from '../NotificationFilters/SidebarNotificationFilters';
import SidebarNotificationEmpty from '../components/SidebarNotification/SidebarNotificationEmpty';
import SidebarNotificationHeader from '../components/SidebarNotification/SidebarNotificationHeader';
import SidebarNotificationList from '../components/SidebarNotification/SidebarNotificationList';

// notification dedicated page which reuses the components used in the sidebar
const FullViewNotification = () => {
  const { isRtl } = usePageLayout();
  const { user } = useUserProfile();
  const { isNotificationEmpty } = useNotificationProvider();
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const { translate } = useTranslate();

  const isVerifyMotivationNeeded = !user?.email || !user?.is_email_verified || !user?.mobile || !user?.is_mobile_verified;

  useEffect(() => {
    mixpanelHelper.trackPageView(
      'PROFILE LANDING PAGE - NOTIFICATION PAGE',
      'notification_page',
      window.location.pathname,
      document.referrer,
    );
    brazeHelper.trackPageView('PROFILE LANDING PAGE - NOTIFICATION PAGE', 'notification_page', window.location.pathname, document.referrer);
  }, []);

  return (
    <>
      <CustomHelmet
        pageTitle={translate(PAGE_TITLE.notifications as TxKeyPath) ?? ''}
        metaDescription={translate(PAGE_DESCRIPTION.notifications as TxKeyPath) ?? ''}
        metaKeyWords={translate(PAGE_KEYWORDS.notifications as TxKeyPath) ?? ''}
      />
      <div className="w-full mt-20 SD:mt-[1.625rem] SD:px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655 2K:mb-[1.5rem]">
        <div className="grid grid-cols-5 SD:grid-cols-4 h-full gap-[1.5rem]">
          <div className="col-span-5 SD:col-span-2 SD:col-start-2">
            <div
              className="SD:border-[0.0625rem] SD:border-neutral-300-zinc-700 rounded-[0.5rem] p-[1.25rem] 
                flex flex-col gap-[1.25rem]"
            >
              {openFilter ? (
                <SidebarNotificationFilters closeFilters={() => setOpenFilter(false)} />
              ) : (
                <>
                  <SidebarNotificationHeader
                    isNotificationEmpty={true}
                    showFullViewIcon={false}
                    isRTL={isRtl}
                    openFilter={() => setOpenFilter(true)}
                  />
                  {isNotificationEmpty && !isVerifyMotivationNeeded ? <SidebarNotificationEmpty /> : <SidebarNotificationList />}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FullViewNotification;
