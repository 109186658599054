import { notificationFilterField, useForm } from '@fe-monorepo/forms';
import { useTranslate } from '@fe-monorepo/hooks';
import '@fe-monorepo/models';
import Button from '@fe-web/Atoms/Buttons/Button';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import RadioButtonsV2 from '@fe-web/Molecules/InputFields/Radio/RadioGroupv2';
import { useNotificationProvider } from '@fe-web/hooks/useNotification/useNotificationProvider';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { NotificationFilterType, featureFilter, statusFilter } from '@fe-web/types/notificationFilterTypes';
import { useEffect, useRef, useState } from 'react';

import FilterOptions from './components/FIlterOptions';
import FilterHeader from './components/FilterHeader';

interface Props {
  closeFilters: () => void;
}

interface RadioButtonsRef {
  setSelection: (value: string) => void;
}

const SidebarNotificationFilters = ({ closeFilters }: Props) => {
  const { translate } = useTranslate();
  const {
    setSelectedFilters,
    getAllNotification,
    selectedFilters,
    setFilterValues,
    setSavedFeature,
    setSavedStatus,
    savedFeature,
    savedStatus,
    setPeriodCode,
    periodCode,
  } = useNotificationProvider();
  const [selectedStatus, setSelectedStatus] = useState<NotificationFilterType[]>(savedStatus ? savedStatus : []);
  const [selectedFeature, setSelectedFeature] = useState<NotificationFilterType[]>(savedFeature ? savedFeature : []);
  const [selectedPeriod, setSelectedPeriod] = useState<NotificationFilterType[]>([]);
  const [selectedOptions, setSelectedOptions] = useState<NotificationFilterType[]>(selectedFilters ? selectedFilters : []);
  const [isApplyLoading, setIsApplyLoading] = useState<boolean>(false);
  const { form, initializeForm, editForm } = useForm();
  const { btnClass } = usePageLayout();
  const radioButtonsRef = useRef<RadioButtonsRef | null>(null);

  const formValue = form?.period_code?.value;

  const today = Date.now();
  const thirtyDaysAgo = today - 30 * 24 * 60 * 60 * 1000;
  const sevenDaysAgo = today - 7 * 24 * 60 * 60 * 1000;
  const yesterday = today - 1 * 24 * 60 * 60 * 1000;

  // calls the getAllNotification API with the selected filters as payload
  const newCall = (isClear?: boolean) => {
    setIsApplyLoading(true);
    if (isClear) {
      getAllNotification({}).then(res => {
        setIsApplyLoading(false);
      });
    } else
      getAllNotification({
        is_read: isReadCheck() !== undefined ? String(isReadCheck()) : '',
        event_group_type: eventGroupCheck(),
        period_start: periodStartCheck(),
        period_end: periodEndCheck(),
      }).then(res => {
        setIsApplyLoading(false);
        closeFilters();
      });
  };

  // used for clear all CTA; clears the selected filters
  const clearSelectedFilter = () => {
    setSelectedStatus([]);
    setSelectedFeature([]);
    setSelectedPeriod([]);
    setSelectedOptions([]);
    setSavedFeature?.([]);
    setSavedStatus?.([]);
    setSelectedFilters?.([]);
    setPeriodCode?.('none');
    setFilterValues?.({});
    newCall(true);
    if (radioButtonsRef.current) {
      radioButtonsRef.current.setSelection('');
    }
  };

  // payload for is_read
  const isReadCheck = () => {
    const isReadSelected = selectedStatus.some(data => data.id === 'read');
    const isUnreadSelected = selectedStatus.some(data => data.id === 'unread');

    switch (true) {
      case isReadSelected && isUnreadSelected:
        return undefined;
      case isReadSelected && !isUnreadSelected:
        return true;
      case !isReadSelected && isUnreadSelected:
        return false;
      default:
        return undefined;
    }
  };

  // payload for period_check
  const periodStartCheck = (): number | undefined => {
    const timestampLookup: Record<string, number> = {
      new: yesterday,
      last7days: sevenDaysAgo,
      last30days: thirtyDaysAgo,
      older: 1543536000000,
    };

    return timestampLookup[formValue] || undefined;
  };

  // payload for period_end
  const periodEndCheck = () => {
    return formValue === 'older' ? thirtyDaysAgo : undefined;
  };

  // payload for event_group_type
  const eventGroupCheck = () => {
    const mappedEvents = selectedFeature.map(data => {
      return data.id;
    });
    return mappedEvents.join(',');
  };

  // saves the selected filters to the provider
  const sendSelectedFilters = () => {
    setSelectedFilters?.(selectedOptions);
    setSavedFeature?.(selectedFeature);
    setSavedStatus?.(selectedStatus);
    setPeriodCode?.(form.period_code.value || 'none');
    newCall();
  };

  // checks the total number of selected filters
  const totalFiltersCheck = () => {
    if (form?.period_code?.value) {
      return selectedOptions.length + 1;
    } else return selectedOptions.length;
  };

  useEffect(() => {
    if (selectedStatus.length > 0 || selectedFeature.length > 0) {
      setSelectedOptions([...selectedStatus, ...selectedFeature, ...selectedPeriod]);
    }
  }, [selectedStatus, selectedFeature, selectedPeriod]);

  return (
    <div className="flex flex-col justify-between overflow-y-scroll w-full h-full">
      <div className="flex flex-col w-full">
        <div className="flex flex-col gap-[1.25rem]">
          <FilterHeader closeFilters={closeFilters} filterCount={totalFiltersCheck()} />
          <div className="w-full border-b-[0.0625rem] border-neutral-300-zinc-700" />
        </div>
        <div className="overflow-y-scroll flex flex-col gap-[1.25rem] pt-[1.25rem]">
          <FilterOptions
            filterOptions={statusFilter}
            header={translate('common_status') ?? ''}
            selectedOptions={options => setSelectedStatus(options)}
            selectedFilterOptions={selectedStatus}
          />
          <div className="w-full border-b-[0.0625rem] border-neutral-300-zinc-700" />
          <FilterOptions
            filterOptions={featureFilter}
            header={translate('common_feature') ?? ''}
            selectedOptions={options => setSelectedFeature(options)}
            selectedFilterOptions={selectedFeature}
          />
          <div className="w-full border-b-[0.0625rem] border-neutral-300-zinc-700" />
          <h2 className="font-medium text-fs-body-small text-secondary">{translate('common_period')}</h2>
          <RadioButtonsV2
            ref={radioButtonsRef}
            initialSelection={periodCode ?? 'none'}
            initializeForm={initializeForm}
            fieldDefinition={notificationFilterField}
            onChange={editForm}
          />
        </div>
      </div>
      <div className="flex w-full">
        <div className="flex items-center justify-center w-[50%]">
          <HoverText
            className="
              font-regular
              text-sunset
              text-fs-body-small whitespace-nowrap flex
              tracking-[-0.0125rem]"
            hover={{ color: 'text-sunset' }}
            alwaysShowUnderline={false}
            underlineStyle="bg-sunset h-[0.0625rem] bottom-[0.125rem]"
            text={translate('common_notification_clearAll') ?? 'Clear all'}
            onClick={clearSelectedFilter}
          />
        </div>
        <Button
          text={translate('action_apply')}
          style="text-base not-italic max-h-[2.5rem] border-[0.063rem] border-sunset font-medium
            w-[50%] py-[0.5rem] gap-[0.5rem] z-1
            mb-[0px]
            xsMax:mb-[0px]  items-end
            rounded-[0.188rem]"
          normalStyle={` 
            ${`${btnClass}
            text-white100 hover:text-sunset`}
          `}
          onClick={() => sendSelectedFilters()}
          isLoading={isApplyLoading}
          loaderClassName="!h-full align-middle"
        />
      </div>
    </div>
  );
};

export default SidebarNotificationFilters;
