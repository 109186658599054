import { useTranslate } from '@fe-monorepo/hooks';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import parse from 'html-react-parser';

export const getVideosData = (videos: any, len: any = 0) => {
  try {
    return videos
      .filter((video: any) => video.contentType === 'video')
      .map((video: any, index: number) => {
        const {
          videoId,
          thumbnailUrl,
          contentUrls: [{ urls: [smallVideoUrl = '', largeVideoUrl = ''] } = { urls: [] }] = [],
        } = video ?? {};

        const isYoutubeVideo = smallVideoUrl.includes('youtube') || largeVideoUrl.includes('youtube');

        let youtubeVideoId = '';
        if (isYoutubeVideo) {
          const splittedUrl = smallVideoUrl.split('/');
          youtubeVideoId = splittedUrl[splittedUrl.length - 1];
        }
        return {
          ...video,
          indexNo: len + index,
          id: `${videoId}-${len + index}`,
          playing: false,
          muted: false, // All Videos UnMuted //
          url: video?.contentUrls[0]?.urls?.find((url: string) => url.includes('.m3u8')),
          thumbnailUrl: thumbnailUrl,
          isYoutubeVideo: isYoutubeVideo,
          youtubeVideoId: isYoutubeVideo ? youtubeVideoId : '',
        };
      });
  } catch (error) {
    console.error(error);
  }
};

export const parseDescription = (description: string, taggedUsers: any, hashtags: any) => {
  const regex = /(#[^\s]+)|(@[^\s]+)/g;
  let hasTags = false;
  const descriptionWithAnchorTags = description.replace(regex, match => {
    if (match.startsWith('#')) {
      hasTags = true;
      if (match.endsWith('.')) {
        match = match.slice(0, -1);
      }

      const hashtagTitle = match.slice(1); // Remove the # symbol to get the actual hashtag text
      const hashtag = hashtags?.find((hashtag: any) => hashtag?.title === hashtagTitle);

      // If hashtag is found and it's a challenge, create the challenge link
      if (hashtag && hashtag?.isChallenge === 1) {
        return `<a href="${AppRoutes.bits}/challenge/${hashtag?.hashtagId}">${match}</a>`;
      }
      // If hashtag is found and it's not a challenge, create the hashtag link
      else if (hashtag) {
        return `<a href="${AppRoutes.bits}/hashtag/${hashtagTitle}">${match}</a>`;
      }
      // If no hashtag is found, just return the match as plain text
      else {
        return match;
      }
    } else if (match.startsWith('@')) {
      hasTags = true;

      // Extract the userId from the match and find the corresponding user
      const userId = match.slice(1); // Remove the @ symbol
      const taggedUser = taggedUsers?.find((user: any) => user?.userId.trim() === userId);

      // If a tagged user is found, return the user link, otherwise return the match as plain text
      if (taggedUser?.userId) {
        return `<a href="/users/${taggedUser?.userId}">${match}</a>`;
      } else {
        return match;
      }
    } else {
      return match;
    }
  });

  if (hasTags) {
    return parse(descriptionWithAnchorTags);
  }

  return description;
};

export const convertKToNumeric = (kValue: any) => {
  if (typeof kValue === 'string' && kValue.match(/(\d+(\.\d+)?)(K|M|B)?/)) {
    let match: any = kValue.match(/(\d+(\.\d+)?)(K|M|B)?/);
    let numericValue = parseFloat(match[1]);
    let modifier = match[3];
    if (modifier) {
      if (modifier === 'K') {
        numericValue *= 1000;
      } else if (modifier === 'M') {
        numericValue *= 1000000;
      } else if (modifier === 'B') {
        numericValue *= 1000000000;
      }
    }
    return numericValue;
  }
  return null;
};

export const formatLargeNumber = (count: number) => {
  if (count < 1000) {
    return count.toString();
  } else if (count < 1000000) {
    return (count / 1000).toFixed(1) + 'K';
  } else {
    return (count / 1000000).toFixed(1) + 'M';
  }
};

// Helper function to handle both ISO string and timestamp formats
export const getTimeAgo = (createdTimestamp: string | number, lang: 'en' | 'ar'): string => {
  const now = new Date().getTime(); // Current time in milliseconds

  // Convert ISO string to timestamp if necessary
  const createdTime = typeof createdTimestamp === 'string' ? new Date(createdTimestamp).getTime() : createdTimestamp;

  const difference = now - createdTime;

  const seconds = Math.floor(difference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  const { translate } = useTranslate();

  if (seconds < 60) {
    return `${translate('quickplay.label-posted-now')}`;
  } else if (minutes === 1) {
    return `1 ${translate('quickplay.label-minute')} ${translate('quickplay.label-ago')}`;
  } else if (minutes < 60) {
    return `${minutes} ${translate('quickplay.label-minutes')} ${translate('quickplay.label-ago')}`;
  } else if (hours === 1) {
    return `1 ${translate('quickplay.label-hour')} ${translate('quickplay.label-ago')}`;
  } else if (hours < 24) {
    return `${hours} ${translate('quickplay.label-hours')} ${translate('quickplay.label-ago')}`;
  } else if (days === 1) {
    return `1 ${translate('quickplay.label-day')} ${translate('quickplay.label-ago')}`;
  } else if (days >= 11 && lang !== 'en') {
    return ` ${translate('quickplay.label-ago')} ${days} ${translate('quickplay.label-day')}`;
  } else if (lang !== 'en') {
    return `${translate('quickplay.label-ago')} ${days} ${translate('quickplay.label-days')}`;
  } else {
    return `${days} ${translate('quickplay.label-days')} ${translate('quickplay.label-ago')}`;
  }
};
