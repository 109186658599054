import { IMAGES } from '@fe-monorepo/assets';
import { useTranslate } from '@fe-monorepo/hooks';
import Image from '@fe-web/Atoms/Media/Image';

// Empty screen
const SidebarNotificationEmpty = () => {
  const { translate } = useTranslate();

  return (
    <div className="flex flex-col justify-center items-center h-full gap-[1.25rem] p-[1rem]">
      <Image
        img={IMAGES.EmptyStates}
        divStyle="w-[6rem] sm:h-[4.24575rem] object-contain overflow-hidden"
        imgStyle="object-contain transition transition-all duration-300 w-full h-full"
      />
      <div className="flex flex-col gap-[.25rem] font-regular text-fs-body-small text-secondary/70 text-center">
        <p>{translate('notification.no_recent')}</p>
        <p className="max-w-[12.0625rem]">{translate('notification.search_topics')}</p>
      </div>
    </div>
  );
};

export default SidebarNotificationEmpty;
