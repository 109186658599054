import { RootState, setIsDrawerOpen } from '@fe-monorepo/store';
import { useDispatch, useSelector } from 'react-redux';

const useDrawerToggle = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector((state: RootState) => state.app.isDrawerOpen);

  return {
    isOpen,
    toggleDrawer: (value: boolean) => dispatch(setIsDrawerOpen(value)),
  };
};

export default useDrawerToggle;
