import { useTranslate } from '@fe-monorepo/hooks';

import Greetings from './Greetings';

const NoMessage = () => {
  const { translate } = useTranslate();
  return (
    <div className="flex justify-center items-center p-[1rem] h-full border-[.1rem] rounded-[.5rem] border-secondary/25">
      <Greetings greetings={translate('meet_greet') ?? ''} />
    </div>
  );
};

export default NoMessage;
