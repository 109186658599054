import OutlineButton from '@fe-web/Atoms/Buttons/OutlineButton';
import { t } from 'i18next';

interface Props {
  className?: string;
  dir: 'ltr' | 'rtl';
  action: () => void;
}

const LookSimilarButton = (props: Props) => {
  return (
    <OutlineButton
      className={`mt-24 2K:mt-[42.66px] 4K:mt-64 8K:mt-[128px] w-full SD:w-fit px-40 2K:px-[71.11px] 4K:px-[106.66px] 8K:px-[213.33px] py-8 2K:py-16 4K:py-20 8K:py-44 text-btn-primary border-btn-primary ${props.className}`}
      newNormalStyle={`${
        props.dir === 'ltr' ? `after:bg-sunset before:bg-primary` : `before:bg-sunset after:bg-primary`
      } hover:border-sunset hover:text-sunsetText hover:text-white100`}
      text={t('shop.productPage.outOfStock.lookSimilarButton')}
      action={props.action}
    />
  );
};

export default LookSimilarButton;
