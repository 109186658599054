import { useTranslate } from '@fe-monorepo/hooks';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import quickplay from 'quickplay-shorts-js';
import { useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate, useSearchParams } from 'react-router-dom';

import VideoErrorIcon from '../../../assets/icons/VideoErrorIcon';
import VideoUploadIcon from '../../../assets/icons/VideoUploadIcon';
import VideoWaitIcon from '../../../assets/icons/VideoWaitIcon';
import Stepper from '../Stepper';

type VideoContent = {
  icon: JSX.Element;
  text: string;
};

function PostCreateStepOne() {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const [videoError, setVideoError] = useState('');
  const inputRef = useRef(null);
  const [isUploadingVideo, setIsUploadingVideo] = useState(false);
  const [searchParams] = useSearchParams();
  const isHashtag = searchParams.get('hashtag') === 'true';

  const handleFileUploadChange = (files: any) => {
    setVideoError(''); // Reset the error state
    const fileUploaded = files[0];
    if (fileUploaded.type !== 'video/mp4' && fileUploaded.type !== 'video/mpeg') {
      setVideoError('type'); // Set error type for file format
    } else {
      const fileSize = fileUploaded.size / 1024 / 1024;
      if (fileSize > 50) {
        setIsUploadingVideo(false);
        setVideoError('size'); // Set error type for file size
        inputRef.current = null;
        return;
      }
      const videoElement = document.createElement('video');
      videoElement.preload = 'metadata';
      videoElement.onloadedmetadata = () => {
        window.URL.revokeObjectURL(videoElement.src);
        const duration = videoElement.duration;
        if (duration > 60 || duration < 6) {
          setVideoError('duration');
          inputRef.current = null;
          return;
        } else {
          readFile(fileUploaded);
        }
      };
      videoElement.onerror = () => {
        setIsUploadingVideo(false);
        inputRef.current = null;
      };
      videoElement.src = URL.createObjectURL(fileUploaded);
    }
    files = null;
  };

  const readFile = (inputFile: any) => {
    if (inputFile) {
      setIsUploadingVideo(true);
      const reader = new FileReader();
      reader.onload = async function () {
        const {
          status,
          data: {
            result: { uploadUrl: signedUrl, url: videoUrl },
          },
        } = await getSignedUrl();
        if (status === 200) {
          const file = inputFile;
          const requestOptions: any = {
            method: 'PUT',
            body: file,
            redirect: 'follow',
          };
          fetch(signedUrl, requestOptions)
            .then(response => {
              setIsUploadingVideo(false);
              const file1 = inputFile;
              const videoUrlBlob = URL.createObjectURL(file1);
              sessionStorage.setItem('videoUrls', JSON.stringify([videoUrl]));
              sessionStorage.setItem('blobUrls', JSON.stringify([videoUrlBlob]));
              if (isHashtag) {
                navigate(`${AppRoutes.bits}/create-post/step-2?hashtag=true`);
              } else {
                navigate(`${AppRoutes.bits}/create-post/step-2`);
              }
            })
            .then(() => {
              setIsUploadingVideo(false);
            })
            .catch(() => {
              setIsUploadingVideo(false);
            });
        } else if (status === 401) {
          localStorage.clear();
          sessionStorage.clear();
          // navigate to Signin
          navigate('/sign-in');
        }
      };

      reader.readAsDataURL(inputFile);
    }
  };

  const getSignedUrl = async () => {
    const feedModuleObj = new quickplay.QuickPlayFeedModule();
    const feedModuleResponse = await feedModuleObj.getSignedUrl({
      type: 'video',
    });
    return feedModuleResponse;
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleFileUploadChange,
    accept: {
      'video/mp4': ['.mp4'],
    },
  });

  const getVideoContent = (): VideoContent => {
    if (!isUploadingVideo && !videoError) {
      return {
        icon: <VideoUploadIcon />,
        text: `${translate('quickplay.select-video')}`,
      };
    }

    if (isUploadingVideo && !videoError) {
      return {
        icon: <VideoWaitIcon />,
        text: `${translate('quickplay.label-uploading')}`,
      };
    }

    if (videoError) {
      switch (videoError) {
        case 'type':
          return {
            icon: <VideoErrorIcon />,
            text: `${translate('quickplay.label-type-error')}`,
          };
        case 'size':
          return {
            icon: <VideoErrorIcon />,
            text: `${translate('quickplay.label-size-error')}`,
          };
        case 'duration':
          return {
            icon: <VideoErrorIcon />,
            text: `${translate('quickplay.label-duration-error')}`,
          };
        default:
          return {
            icon: <VideoErrorIcon />,
            text: `${translate('quickplay.label-default-error')}`,
          };
      }
    }
    return {
      icon,
      text,
    };
  };

  const { icon, text } = getVideoContent();

  return (
    <>
      <Stepper />
      <div className="creat-full uploadInputWrapper">
        <div
          data-isDragActive={isDragActive}
          className="creat-box w-full flex flex-col justify-center items-center rounded-md data-[isDragActive=true]:border-active py-16"
          {...getRootProps()}
        >
          <div className="content-sec dropzone-wrapper flex flex-col items-center justify-center">
            {icon}
            <div className="c-text">{text}</div>
            {!isUploadingVideo && (
              <>
                <div className="text-sm text-g">{translate('quickplay.drag-drop-text')}</div>
                <div className="file-upload-btn-parent">
                  <input {...getInputProps()} />
                  <button
                    disabled={isUploadingVideo}
                    className="btn_primary mt-t-30 file-upload-btn disabled:opacity-50 flex items-center gap-2"
                  >
                    {translate('quickplay.select-file-btn')}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PostCreateStepOne;
