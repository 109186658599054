import { IMAGES } from '@fe-monorepo/assets';
import { IMAGE_ALT_LABELS, IconNames, TxKeyPath } from '@fe-monorepo/helper';
import { useCommunityNew, useTranslate } from '@fe-monorepo/hooks';
import { EventType, NotificationMessageData } from '@fe-monorepo/models';
import { store } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import PlayerAvatar from '@fe-web/Molecules/PlayerAvatar';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { chatEventCheck, eventCheck1, eventCheck2, eventCheck3, qpNotifications } from '@fe-web/types/notificationFilterTypes';
import parse, { Element, HTMLReactParserOptions } from 'html-react-parser';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Link, useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../../app.routes.enum';
import NotificationContent from '../NotificationContent';
import NotificationPopover from '../NotificationPopover/NotificationPopover';
import NotificationGroupAvatar from './NotificationGroupAvatar';
import NotificationTimeStamp from './NotificationTimeStamp';
import ReplyCTA from './ReplyCTA';

const NotificationCard = ({
  isMinimized,
  id,
  bodyHTML_en,
  bodyHTML_ar,
  image,
  event_type,
  is_read,
  created,
  conversation_id,
  conversation_type,
  conversation_name,
  avatar_url,
  collection_code,
  product_code,
  tournament_id,
  username,
  sender_username,
  media_url,
  image_url,
  is_following,
  follower_username,
  follower_display_name,
  title_ar,
  title_en,
  game_code,
  comment_parent_id,
  comment_reply_id,
  asset_id,
  msg_id,
}: NotificationMessageData) => {
  const { language } = usePageLayout();
  const [showMore, setShowMore] = useState<boolean>(false);
  const [parseBody, setParseBody] = useState<string>('');
  const [isFullMessageShown, setIsFullMessageShown] = useState(false);
  const [avatarUrlArray, setAvatarUrlArray] = useState<string[]>([]);
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const { updateNotification } = useNotificationProvider();
  const { userFollow } = useCommunityNew();

  // Function used to call the follow API; Used for notifications with follow functionality
  const toggleFollow = (username: string) => {
    const req = userFollow.mutate({ username });

    req.then(res => {
      if (res?.data?.followUser?.is_successful === true) {
        toastWrapper('success', translate('msg_success_follow', { username: username }) as string);
      } else toastWrapper('error', translate('error_generic') as string);
    });
  };

  //If the message is text → display the content, max characters (35). The rest shows by tapping on more CTA.
  const formatChatMessage = (message: string) => {
    if (message === 'sent a photo') {
      const text =
        conversation_type === 'group' ? `${translate('notification.sent_a_photo_lower')}.` : `${translate('notification.sent_a_photo')}.`;
      return text;
    } else if (message === 'sent a video') {
      const text =
        conversation_type === 'group' ? `${translate('notification.sent_a_video_lower')}.` : `${translate('notification.sent_a_video')}.`;
      return text;
    } else if (message === 'shared a bits') {
      return translate('notification.shared_a_bits');
    } else if (isFullMessageShown) {
      return message;
    }

    let new_message = message;
    if (message.includes('Replied to you:')) {
      new_message = message?.replace('Replied to you: ', '');
    } else if (message.includes('رد عليك')) {
      new_message = message?.replace(`:رد عليك`, '');
    }
    if (message.includes(`${sender_username}:`)) {
      new_message = message?.replace(`${sender_username}: `, '');
    }

    return new_message.length > 35 ? (
      <>
        {new_message.substring(0, 35)}
        ...{' '}
        <span
          className="text-sunset cursor-pointer"
          onClick={(event: React.MouseEvent) => {
            event.stopPropagation();
            setIsFullMessageShown(true);
          }}
        >
          {translate('notification.more')}
        </span>
      </>
    ) : (
      new_message
    );
  };

  const privateChatFormatMessage = (message: string) => {
    // if message is Sent a photo or Sent a video, or Shared a bits then return an empty string
    // if none of the above add Replied to you: at the beginning of the message by using the translate function
    if (message === 'sent a photo' || message === translate('notification.sent_a_photo_lower')) {
      return '';
    } else if (message === 'sent a video' || message === translate('notification.sent_a_video_lower')) {
      return '';
    } else if (message === 'shared a bits' || message === translate('notification.shared_a_bits')) {
      return '';
    } else {
      return translate('notification.replied_to_you') + ': ';
    }
  };

  // placeHolderImage image is from FIGMA Design
  const placeHolderImage = IMAGES.NotificationUserAvatar as string;

  const avatarPicker = (avatarUrl: string) => {
    const avatarUrlArray = avatarUrl.split(',');
    //check the length if its equal to 1
    if (avatarUrlArray.length === 1) {
      if (avatarUrl === '') {
        return [placeHolderImage];
      } else {
        return [avatarUrl];
      }
    }
    //if avatar at index 0 is not empty and avatar at index 1 is not empty return both avatar1 and avatar2 as an array
    else if (avatarUrlArray[0] !== '' && avatarUrlArray[1] !== '') {
      return [avatarUrlArray[0], avatarUrlArray[1]];
    }
    //if avatarUrlArray[0] is not empty and avatarUrlArray[1] is empty return avatarUrlArray[0] and a placeholder image
    else if (avatarUrlArray[0] !== '') {
      return [avatarUrlArray[0], placeHolderImage];
    }
    //if avatarUrlArray[0] is empty and avatarUrlArray[1] is not empty return a placeholder image and avatarUrlArray[1]
    else if (avatarUrlArray[1] !== '') {
      return [placeHolderImage, avatarUrlArray[1]];
    }
    //if both avatarUrlArray[0] and avatarUrlArray[1] are empty return 2 placeholder images
    else {
      return [placeHolderImage, placeHolderImage];
    }
  };

  //useEffect for the avatarURL
  useEffect(() => {
    //if avatar_url is not empty then call the avatarPicker function and set the avatarUrlArray
    avatar_url && setAvatarUrlArray(avatarPicker(avatar_url) ?? '');
  }, [avatar_url]);

  const bodyHtml = {
    en: bodyHTML_en,
    ar: bodyHTML_ar,
  };

  const titleCheck = {
    en: title_en,
    ar: title_ar,
  };

  // checks where the user will be redirected when thy click the notification
  const redirectCheck = (input: string) => {
    switch (input) {
      case EventType.orderSingle:
      case EventType.orderMultiple:
      case EventType.orderDispatched:
      case EventType.orderShipmentDispatched:
      case EventType.orderDelivered:
      case EventType.orderPlaced:
      case EventType.orderDeliveredShop:
      case EventType.orderItemPlaced:
      case EventType.orderItemDispatched:
      case EventType.orderItemDelivered:
        return AppRoutes.myorders;
      case EventType.returnSingle:
      case EventType.returnMultiple:
      case EventType.returnPlaced:
      case EventType.returnDispatched:
      case EventType.returnDelivered:
      case EventType.returnRejected:
      case EventType.returnCompleted:
      case EventType.returnItemPlaced:
      case EventType.returnItemActionRequired:
      case EventType.returnItemDispatched:
      case EventType.returnItemCompleted:
      case EventType.returnItemReject:
      case EventType.returnItemClosed:
        return AppRoutes.myreturns;
      case EventType.plp:
        return AppRoutes.shop + '/c-' + collection_code;
      case EventType.pdp:
        return AppRoutes.shop + '/c-' + collection_code + '/' + product_code;
      default:
        return '';
    }
  };
  function surroundWithSpan(input: string): string {
    const regex = /#\d+/g; // This regular expression matches a '#' followed by one or more digits
    const matches = input.match(regex); // Find all matches in the input string

    // If more than one match was found, replace the first one with the same text surrounded by a span element
    if (matches && matches.length > 1) {
      const replacement = `<span class="text-sunset">${matches[0]}</span>`;
      return input.replace(matches[0], replacement);
    }

    // If only one match or no match was found, return the input string unchanged
    return input;
  }

  // replaces the {{href}} and {{className}} of the bodyHTML_ar and bodyHTML_en so that it can be targeted by by the parse function
  const parser = () => {
    const replacedClassName = bodyHtml[language]?.replace(new RegExp('}}>', 'g'), '}} >');
    const splitBody = replacedClassName?.split(' ');
    const indicesHref: number[] = [];
    const indicesClass: number[] = [];
    splitBody?.forEach((data, index) => {
      if (data === '{{href}}' || data === '{{href_return}}' || data === '{{href_invoice}}') {
        indicesHref.push(index);
      } else if (data === '{{className}}') {
        indicesClass.push(index);
      }
    });
    const replacedHref = splitBody?.map((data, index) => {
      if (index === indicesHref[0]) {
        return `to="${redirectCheck(event_type || '')}" id="1"`;
      } else if (index === indicesHref[1]) {
        return `to="${redirectCheck(event_type || '')}" id="1"`;
      } else if (index === indicesClass[0]) {
        return `className="text-sunset"  ${indicesHref.length === 0 && `id="1"`}`;
      } else if (index === indicesClass[1]) {
        return `className="text-sunset"  ${indicesHref.length === 0 && `id="2"`}`;
      } else return data;
    });
    setParseBody(surroundWithSpan(replacedHref?.join(' ') ?? ''));
  };

  useEffect(() => {
    parser();
  }, [id, language]);

  // replaces the anchor tags of the bodyHTML_ar and bodyHTML_en to Link component
  const option: HTMLReactParserOptions = {
    replace(domNode) {
      if (domNode instanceof Element && domNode.attribs && domNode.attribs.id === '1') {
        const replacement = [domNode.children[0]] as any;
        return (
          <Link to={domNode.attribs.to} className={domNode.attribs.classname}>
            {replacement[0].data}
          </Link>
        );
      }
      if (domNode instanceof Element && domNode.attribs && domNode.attribs.id === '2') {
        const replacement = [domNode.children[0]] as any;
        return (
          <Link to={domNode.attribs.to} className={domNode.attribs.classname}>
            {replacement[0].data}
          </Link>
        );
      }
    },
  };

  // Checks what Icon is used for the notification based on the event_type
  function renderIcon() {
    switch (event_type) {
      case EventType.orderSingle:
      case EventType.orderMultiple:
      case EventType.orderDispatched:
      case EventType.orderShipmentDispatched:
      case EventType.orderDelivered:
      case EventType.orderPlaced:
      case EventType.orderDeliveredShop:
      case EventType.orderItemPlaced:
      case EventType.orderItemDispatched:
      case EventType.orderItemDelivered:
        return (
          <div className="p-[0.5rem] flex items-center justify-center">
            <ResponsiveIcon name={IconNames.deliveryTruck} baseWidth={24} baseHeight={24} iconClasses="fill-secondary" />
          </div>
        );
      case EventType.returnSingle:
      case EventType.returnMultiple:
      case EventType.returnPlaced:
      case EventType.returnDispatched:
      case EventType.returnDelivered:
      case EventType.returnRejected:
      case EventType.returnCompleted:
      case EventType.returnItemPlaced:
      case EventType.returnItemActionRequired:
      case EventType.returnItemDispatched:
      case EventType.returnItemCompleted:
      case EventType.returnItemReject:
      case EventType.returnItemClosed:
        return (
          <ResponsiveIcon name={IconNames.alertAddCheck} baseWidth={40} baseHeight={40} iconClasses="stroke-secondary fill-transparent" />
        );
      case EventType.plp:
      case EventType.pdp:
        return (
          <>
            {image ? (
              <div className="rounded-[0.1875rem] bg-secondary/[0.06] min-h-[2.5rem] min-w-[2.5rem] w-[2.5rem] h-[2.5rem]">
                <LazyLoadImage
                  alt={translate(IMAGE_ALT_LABELS.notification_product_thumbnail as TxKeyPath) ?? ''}
                  src={image}
                  className=""
                />
              </div>
            ) : (
              <ResponsiveIcon name={IconNames.alertFlame} baseWidth={40} baseHeight={40} iconClasses="stroke-secondary fill-transparent" />
            )}
          </>
        );
      case EventType.chat:
      case EventType.chatPrivateTextSent:
      case EventType.chatPrivateImageSent:
      case EventType.chatPrivateVideoSent:
      case EventType.chatPrivateVideoShared:
      case EventType.chatGroupTextSent:
      case EventType.chatGroupTextReceived:
      case EventType.chatGroupImageSent:
      case EventType.chatGroupVideoSent:
      case EventType.chatGroupLeft:
      case EventType.chatGroupUpdate:
      case EventType.qpComment:
      case EventType.qpReply:
      case EventType.qpLike:
      case EventType.qpMention:
      case EventType.qpModeration:
        switch (conversation_type) {
          case 'group':
            if (avatarUrlArray.length === 2) {
              return (
                <NotificationGroupAvatar
                  avatarSize="2.5rem"
                  dualAvatarSize="1.667rem"
                  avatarUrl={avatarUrlArray[0] || ''}
                  avatarUrl2={avatarUrlArray[1] || ''}
                  avatarStyle="w-[1.667rem] h-[1.667rem]"
                />
              );
            } else if (avatarUrlArray[0] !== '') {
              return (
                <Image
                  alt={translate(IMAGE_ALT_LABELS.notification_group_avatar as TxKeyPath) ?? ''}
                  img={avatarUrlArray[0] || IMAGES.NotificationUserAvatar}
                  divStyle="flex items-center justify-center object-cover bg-black/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden h-40 w-40 2K:h-80 2K:w-80 4K:h-100 4K:w-100 8K:h-200 8K:w-200"
                  imgStyle="h-40 w-40 2K:h-80 2K:w-80 4K:h-100 4K:w-100 8K:h-180 8K:w-200 object-cover"
                />
              );
            } else {
              return (
                <ResponsiveIcon
                  name={IconNames.icon_chat_group}
                  baseWidth={25}
                  baseHeight={25}
                  iconClasses="fill-lightBlue"
                  className="flex items-center justify-center object-cover bg-lightBlue/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden h-40 w-40 2K:h-80 2K:w-80 4K:h-100 4K:w-100 8K:h-200 8K:w-200"
                />
              );
            }
          case 'private':
          default:
            return (
              <div className="relative h-[2.5rem]">
                <PlayerAvatar
                  altLabel={translate(IMAGE_ALT_LABELS.notification_user_avatar as TxKeyPath) ?? ''}
                  isGroupAvatar
                  avatarUrl={avatar_url ?? (IMAGES.NotificationUserAvatar as string)}
                  avatarSize={4}
                  dir="row"
                  avatarStyle="!bg-[#2388FF33] max-w-[2.5rem] max-h-[2.5rem] !border-gray-200-zinc-700 object-cover"
                />
                <ResponsiveIcon
                  name={IconNames.icon_chat_circle}
                  baseWidth={12}
                  baseHeight={12}
                  iconClasses="fill-gray"
                  className="absolute bottom-[.1rem] right-[.1rem]"
                />
              </div>
            );
        }
      case EventType.matchmakingUserFollowed:
      case EventType.profileUserFollowed:
        return (
          <div className="relative h-[2.5rem]">
            <PlayerAvatar
              altLabel={translate(IMAGE_ALT_LABELS.notification_user_avatar as TxKeyPath) ?? ''}
              isGroupAvatar
              avatarUrl={avatar_url ?? (IMAGES.NotificationUserAvatar as string)}
              avatarSize={4}
              dir="row"
              avatarStyle="!bg-[#2388FF33] max-w-[2.5rem] max-h-[2.5rem] !border-gray-200-zinc-700 object-cover"
            />
            <ResponsiveIcon
              name={IconNames.icon_chat_circle}
              baseWidth={12}
              baseHeight={12}
              iconClasses="fill-gray"
              className="absolute bottom-[.1rem] right-[.1rem]"
            />
          </div>
        );
      case EventType.minigamesNewUser:
      case EventType.minigamesReturningUser:
      case EventType.minigamesHighscoreSet:
      case EventType.minigamesHighscoreBeaten:
      case EventType.minigamesDone:
      case EventType.minigamesOtherGames:
      case EventType.minigamesUpdate:
      case EventType.minigamesNewGames:
        return (
          <>
            {image_url ? (
              <div className="bg-secondary/[0.06] min-h-[3.125rem] min-w-[2.5rem] w-[2.5rem] h-[2.5rem]">
                <LazyLoadImage
                  src={image_url}
                  className="h-full object-cover rounded-[0.125rem] border-[0.0625rem] border-neutral-300-zinc-700"
                />
              </div>
            ) : (
              <ResponsiveIcon name={IconNames.alertFlame} baseWidth={40} baseHeight={40} iconClasses="stroke-secondary fill-transparent" />
            )}
          </>
        );
      case EventType.matchmakingPreferences:
      case EventType.matchmakingSearch:
      case EventType.matchmakingFound:
        return <Image img={IMAGES.NotificationVs} divStyle="" imgStyle="h-[2.5rem] w-[2.5rem] object-cover" />;
      case EventType.tournamentList:
      case EventType.tournamentDetails:
      case EventType.tournamentCheckinOpen:
      case EventType.tournamentCheckinMissed:
      case EventType.tournamentCheckinClosed:
      case EventType.tournamentScoreReported:
      case EventType.tournamentEnded:
        return (
          <div className="rounded-[0.25rem] bg-secondary/[0.06] min-h-[2.25rem] min-w-[2.5rem] w-[2.5rem] h-[2.25rem]">
            <LazyLoadImage src={image_url} className="h-full" />
          </div>
        );
      case EventType.tournamentCancelled:
        return (
          <div className="relative h-[2.5rem]">
            <PlayerAvatar
              isGroupAvatar
              avatarUrl={avatar_url ?? (IMAGES.NotificationUserAvatar as string)}
              avatarSize={4.25}
              dir="row"
              avatarStyle="!bg-[#2388FF33] max-w-[2.5rem] max-h-[2.5rem] !border-gray-200-zinc-700 object-cover"
            />
            <ResponsiveIcon
              name={IconNames.icon_chat_circle}
              baseWidth={12}
              baseHeight={12}
              iconClasses="fill-gray"
              className="absolute bottom-[.1rem] right-[.1rem]"
            />
          </div>
        );
      default:
        return null;
    }
  }

  // Checks what translation is used for the CTAs
  const labelCheck = () => {
    switch (event_type) {
      case EventType.matchmakingPreferences:
        return translate('notifications.matchmaking.btn_complete_preferences');
      case EventType.matchmakingFound:
        return translate('notifications.matchmaking.btn_open_chat');
      case EventType.tournamentCheckinOpen:
        return translate('action_checkin');
      default:
        return null;
    }
  };

  // Calls the updateNotification to mark the notification as read before redirecting
  const markAsReadAndRedirect = () => {
    const messageId = event_type?.startsWith('qp_') ? msg_id : id;
    updateNotification({ message_id: messageId as string, is_read: 'true' }).then(res => {
      if (res) {
        if (chatEventCheck.includes(event_type as EventType)) {
          navigate(
            conversation_type === 'private' ? `${AppRoutes.messages}/${sender_username}/0` : `${AppRoutes.messages}/${conversation_id}/2`,
          );
        }
        if (
          event_type?.includes('matchmaking') &&
          event_type !== EventType.matchmakingUserFollowed &&
          event_type !== EventType.matchmakingFound
        ) {
          navigate(AppRoutes.matchmaking);
        }
        if (
          event_type === EventType.matchmakingUserFollowed ||
          event_type === EventType.matchmakingFound ||
          event_type === EventType.profileUserFollowed
        ) {
          navigate(AppRoutes.users + `/${follower_username}`);
        }
        if (event_type?.includes('tournament')) {
          navigate(AppRoutes.tournaments + `/${tournament_id}`);
        }
        if (event_type?.includes('minigames')) {
          navigate(event_type.includes('receive') ? AppRoutes.games : `${AppRoutes.games}/${game_code}`);
        }
        if (event_type === EventType.qpLike || event_type === EventType.qpMention || event_type === EventType.qpModeration) {
          navigate(`${AppRoutes.bitsPlay}/content/${asset_id}`);
        }
        if (event_type === EventType.qpComment) {
          navigate(`${AppRoutes.bitsPlay}/content/${asset_id}?commentId=${comment_parent_id}&sourceType=notification`);
        }
        if (event_type === EventType.qpReply) {
          navigate(
            `${AppRoutes.bitsPlay}/content/${asset_id}?commentId=${comment_parent_id}&replyId=${comment_reply_id}&sourceType=notification`,
          );
        }
      }
    });
  };

  //a function that will format 7:19 pm to 7:19 p.m.
  function formatTime(time: string) {
    const timeArray = time.split(' ');
    const timeArray2 = timeArray[0].split(':');
    const period = timeArray[1] === 'a.m' ? 'a.m.' : 'p.m.';
    return `${timeArray2[0]}:${timeArray2[1]} ${period}`;
  }

  // formats the date
  function formatTimestamp(timestamp: number) {
    const timezone = store.getState().user.userContext.preferred_timezone;
    const convertedDate = moment(timestamp).utcOffset(timezone);
    const nowMoment = moment().utcOffset(timezone);

    const diffInMilliseconds = Math.abs(nowMoment.diff(convertedDate));
    const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
    const diffInMinutes = Math.floor(diffInMilliseconds / (60 * 1000));

    function formatNumber(datenum: string) {
      return datenum?.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d)?.toString());
    }

    if (diffInSeconds < 60) {
      return translate('common_justNow') ?? '';
    } else if (diffInMinutes < 60) {
      return `${
        diffInMinutes === 1 ? translate('notification.minute_ago') : translate('notification.minutes_ago', { minutes: diffInMinutes })
      }`;
    } else if (nowMoment.isSame(convertedDate, 'day')) {
      const formattedTime = convertedDate.locale(language).format('h:mm a');
      return language === 'en' ? formatTime(formattedTime) : formatNumber(formattedTime);
    } else {
      const formattedDate = convertedDate.locale(language).format('MMM. Do, YYYY');
      return language === 'en' ? formattedDate : formatNumber(formattedDate);
    }
  }
  return (
    <NotificationContent
      handleShowMore={show => setShowMore(show)}
      notificationIcon={renderIcon()}
      content={
        eventCheck1.includes(event_type as EventType) ? (
          <>
            <p className="text-secondary text-fs-body-small not-italic font-regular leading-5 mb-[0.25rem]">{parse(parseBody, option)}</p>
            <NotificationTimeStamp timeStamp={formatTimestamp(Number(created))} />
          </>
        ) : eventCheck3.includes(event_type as EventType) ? (
          <>
            <p className="text-secondary text-fs-body-small not-italic font-regular leading-5">{follower_display_name}</p>
            <div className="flex flex-col gap-[0.25rem] text-gray text-fs-caption not-italic font-regular leading-4 w-full">
              <p>{translate('notifications.matchmaking.followed_you')}</p>
              <div className="flex items-center gap-[0.25rem] text-secondary">
                <NotificationTimeStamp timeStamp={formatTimestamp(Number(created))} />
              </div>
            </div>
          </>
        ) : eventCheck2.includes(event_type as EventType) ? (
          <>
            <p
              className={`${
                event_type === EventType.tournamentCancelled ? 'text-red' : 'text-secondary'
              }  text-fs-body-small not-italic font-regular leading-5 `}
            >
              {titleCheck[language]}
            </p>
            <p className="flex gap-[0.25rem] text-gray text-fs-caption not-italic font-regular leading-4 max-w-[14.375rem] mb-[0.25rem]">
              {parseBody}
            </p>
            <div className="flex items-center gap-[0.25rem] text-secondary">
              <NotificationTimeStamp timeStamp={formatTimestamp(Number(created))} />
              {(event_type === EventType.matchmakingPreferences || event_type === EventType.matchmakingFound) && (
                <>
                  <p className="text-otherGray text-fs-caption">•</p>
                  <ReplyCTA markAsReadAndRedirect={markAsReadAndRedirect} label={labelCheck() as string} />
                </>
              )}
            </div>
          </>
        ) : qpNotifications.includes(event_type as EventType) ? (
          <>
            <p className="text-secondary text-fs-body-small not-italic font-regular leading-5">{follower_username || username}</p>
            <p className="webkit-truncate-2 text-gray text-fs-caption not-italic font-regular leading-4 w-full mb-[0.25rem]">{parseBody}</p>
            <div className="flex items-center gap-[0.25rem]">
              <NotificationTimeStamp timeStamp={formatTimestamp(Number(created))} />
              {event_type !== EventType.qpLike && event_type !== EventType.qpModeration && (
                <ReplyCTA markAsReadAndRedirect={markAsReadAndRedirect} />
              )}
            </div>
          </>
        ) : (
          chatEventCheck.includes(event_type as EventType) && (
            <>
              <p className="text-secondary text-fs-body-small not-italic font-regular leading-5">
                {conversation_type === 'group' ? conversation_name : sender_username}
              </p>
              <p className="text-gray text-fs-caption not-italic font-regular leading-4 w-full mb-[0.25rem]">
                {conversation_type === 'group' ? `${sender_username}: ` : privateChatFormatMessage(parseBody)}
                {formatChatMessage(parseBody)}
              </p>
              <div className="flex items-center gap-[0.25rem]">
                <NotificationTimeStamp timeStamp={formatTimestamp(Number(created))} />
                <ReplyCTA markAsReadAndRedirect={markAsReadAndRedirect} />
              </div>
            </>
          )
        )
      }
      endContent={
        <div className="flex flex-row items-center gap-[0.5rem]">
          {chatEventCheck.includes(event_type as EventType) && media_url && (
            <LazyLoadImage
              src={media_url}
              alt={translate(IMAGE_ALT_LABELS.notification_chat_image_thumbnail as TxKeyPath) ?? ''}
              className="w-[2.5rem] h-[2.5rem] border-secondary/10 border-[0.0313rem] rounded-[0.125rem] object-cover"
            />
          )}
          {qpNotifications.includes(event_type as EventType) && (image || media_url) && (
            <LazyLoadImage
              src={image !== null ? image : media_url}
              alt={translate(IMAGE_ALT_LABELS.notification_chat_image_thumbnail as TxKeyPath) ?? ''}
              className="w-[2.5rem] h-[2.5rem] border-secondary/10 border-[0.0313rem] rounded-[0.125rem] object-cover"
            />
          )}
          {event_type === EventType.matchmakingUserFollowed && !is_following && (
            <ReplyCTA
              markAsReadAndRedirect={() => toggleFollow(follower_username as string)}
              label={translate('notifications.matchmaking.follow_back') as string}
              containerClass="w-max"
            />
          )}
          {is_read === 'false' && (
            <ResponsiveIcon name={IconNames.iconcirclefilledv2} baseWidth={10} baseHeight={10} iconClasses="fill-moonlight" className="" />
          )}
          <NotificationPopover isMinimized={isMinimized} is_read={is_read} id={id} showMore={showMore} />
        </div>
      }
      mousePointerAndRedirectEnabled={
        chatEventCheck.includes(event_type as EventType) ||
        eventCheck2.includes(event_type as EventType) ||
        eventCheck3.includes(event_type as EventType) ||
        (qpNotifications.includes(event_type as EventType) && (event_type === EventType.qpLike || event_type === EventType.qpModeration))
      }
      markAsReadAndRedirect={markAsReadAndRedirect}
    />
  );
};
export default NotificationCard;
