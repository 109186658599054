import { useLazyQuery } from '@apollo/client';
import { IS_PRODUCT_AVAILABLE_QUERY, LMD_QUERY } from '@fe-monorepo/data-access';
import { LMDModel } from '@fe-monorepo/models';
import { RootState, setLMDCode, setLmds } from '@fe-monorepo/store';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFetchQuery } from '../useFetchQuery';
import { useInvoice } from './useInvoice';

export interface ProductAvailabilityInput {
  product_code: string;
  variant_id: string;
  lmd_code: string;
  qty: number;
}

export interface ProductAvailabilityResponse {
  isProductAvailable: {
    error_code: string;
    error_msg: string;
    is_successful: boolean;
  };
}

export const useCheckProductAvailability = () => {
  const User = useSelector((state: RootState) => state.user.userContext);
  const lmdCode = useSelector((state: RootState) => state.app.lmdCode);
  const { invoiceInfo, createInvoice } = useInvoice(true);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const getAllLmdResponse = useFetchQuery<LMDModel[]>(LMD_QUERY.getAllLmd, []);
  const [getIsProductAvailability, { data: productAvailability, error: infoError }] = useLazyQuery<ProductAvailabilityResponse>(
    IS_PRODUCT_AVAILABLE_QUERY.isProductAvailable,
    {
      errorPolicy: 'all',
    },
  );

  useEffect(() => {
    const LMDData = getAllLmdResponse?.data;
    if (LMDData) {
      dispatch(setLmds(LMDData));
      if (User.token && User.username && LMDData.length > 0) {
        if (invoiceInfo === null || invoiceInfo === undefined) {
          if (lmdCode.lmd_code) {
            createInvoice({
              lmd_code: lmdCode.lmd_code,
            });
          } else if (LMDData.length > 0) {
            const defaultCity = LMDData.find(city => city.city === 'Riyadh');
            if (defaultCity) {
              createInvoice({
                lmd_code: defaultCity.lmd_code,
              });
            }
          }
        }
      }
    }
    setIsLoading(false);
  }, [getAllLmdResponse?.data]);

  const selectLmdCode = (code: LMDModel) => {
    dispatch(setLMDCode(code));
  };

  const checkProductAvailability = async (params: ProductAvailabilityInput) => {
    setIsLoading(true);
    const { data } = await getIsProductAvailability({
      variables: { details: params },
    });
    setIsLoading(false);
    return data;
  };

  const getAllLmd = async () => {
    setIsLoading(true);
    return getAllLmdResponse.fetch();
  };

  return {
    getAllLmd,
    selectLmdCode,
    lmd_data: getAllLmdResponse?.data,
    isLoading,
    setIsLoading,
    checkProductAvailability,
    isProductAvailableData: productAvailability,
  };
};
