import { gql } from '@apollo/client';

export const RETURN_PRODUCT_QUERY = {
  // 4. Return Reasons: goods {{base_url_oms}}/return/product/getReason
  omsReturnProdGetReason: gql`
    query omsReturnProdGetReason {
      omsReturnProdGetReason {
        is_successful
        error_code
        error_msg
        data {
          return_reason_code
          return_reason_ar
          return_reason_en
        }
      }
    }
  `,

  //5. Retrieve Delivered Physical Products in the Invoice: {{base_url_oms}}/return/product/getProducts
  omsReturnProdGetProducts: gql`
    query omsReturnProdGetProducts($details: OmsReturnProdGetProductInput) {
      omsReturnProdGetProducts(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          invoice_number
          updated_at
          created_at
          list {
            is_delivered
            is_return_requested
            media
            variant_id
            name_en
            name_ar
            barcode
            sku
            selling_cost_grand_total
            qty
            product_code
            serials {
              serial_number
              tracking_number
              is_return_requested
            }
          }
        }
      }
    }
  `,
  omsReturnProductGetInfo: gql`
    query omsReturnProductGetInfo($details: OmsReturnProdGetInfoInput) {
      omsReturnProductGetInfo(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          info {
            refund_method
            amount
            status_code
            created_at
            updated_at
            reject_reason_ar
            reject_reason_en
            media
            reason_en
            reason_ar
          }
        }
      }
    }
  `,
};
