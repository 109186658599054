import { gql } from '@apollo/client';

export const SHOP_SECTIONS_QUERY = {
  getShopDataInfo: gql`
    query fetchShopDataInfo($menu_code: String) {
      getShopDataInfo(menu_code: $menu_code) {
        is_successful
        error_code
        error_msg
        data {
          menu_code
          name_en
          name_ar
          menu_items {
            menu_code
            name_en
            name_ar
            media
            collection_id
            collection_code
            products_list {
              product_code
              name_en
              name_ar
              featured_img
              brand {
                name_en
                name_ar
                collection_code
              }
              metadata
              lowest_actual_cost
              highest_actual_cost
              lowest_selling_price
              highest_selling_price
              lastCursor
            }
            menu_items {
              menu_code
              name_en
              name_ar
              media
              collection_id
              collection_code
              products_list {
                product_code
                name_en
                name_ar
                featured_img
                brand {
                  name_en
                  name_ar
                  collection_code
                }
                metadata
                lowest_actual_cost
                highest_actual_cost
                lowest_selling_price
                highest_selling_price
                lastCursor
              }
              menu_items {
                menu_code
                name_en
                name_ar
                media
                collection_id
                collection_code
                products_list {
                  product_code
                  name_en
                  name_ar
                  featured_img
                  brand {
                    name_en
                    name_ar
                    collection_code
                  }
                  metadata
                  lowest_actual_cost
                  highest_actual_cost
                  lowest_selling_price
                  highest_selling_price
                  lastCursor
                }
              }
            }
          }
        }
      }
    }
  `,
  getShopMenu: gql`
    query fetchShopMenu($details: ShopMenuInput) {
      getShopMenu(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          menu_code
          name_en
          name_ar
          menu_items {
            menu_code
            name_en
            name_ar
            media
            collection_id
            collection_code
            products_list {
              product_code
              name_en
              name_ar
              featured_img
              brand {
                name_en
                name_ar
                collection_code
              }
              metadata
              lowest_actual_cost
              highest_actual_cost
              lowest_selling_price
              highest_selling_price
              lastCursor
            }
            menu_items {
              menu_code
              name_en
              name_ar
              media
              collection_id
              collection_code
              products_list {
                product_code
                name_en
                name_ar
                featured_img
                brand {
                  name_en
                  name_ar
                  collection_code
                }
                metadata
                lowest_actual_cost
                highest_actual_cost
                lowest_selling_price
                highest_selling_price
                lastCursor
              }
              menu_items {
                menu_code
                name_en
                name_ar
                media
                collection_id
                collection_code
                products_list {
                  product_code
                  name_en
                  name_ar
                  featured_img
                  brand {
                    name_en
                    name_ar
                    collection_code
                  }
                  metadata
                  lowest_actual_cost
                  highest_actual_cost
                  lowest_selling_price
                  highest_selling_price
                  lastCursor
                }
              }
            }
          }
        }
      }
    }
  `,

  getShopProductMenu: gql`
    query fetchShopProductMenu($details: ShopMenuInput) {
      getShopProductMenu(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          count
          list {
            product_code
            name_en
            name_ar
            featured_img
            metadata
            lowest_actual_cost
            highest_actual_cost
            highest_selling_price
            lowest_selling_price
            lastCursor
            last_cursor
            description_en
            description_ar
            brand {
              name_en
              name_ar
              collection_code
            }
          }
          banner_info {
            name_en
            name_ar
            reference_type
            reference_value
            web_media_url_ar
            web_media_url_en
            mobile_media_url_ar
            mobile_media_url_en
            banner_type
            expire_on
            carousel_type
          }
        }
      }
    }
  `,
};
