import { toast } from 'react-toastify';
import React from 'react';

export const useToastMessage = () => {
    type ShowToastMessage = {
        className?: string;
        message?: React.ReactNode;
        closeToast?: boolean;
        useDefaultClassNames?: boolean;
    };

    function successToastMessage({ className, message, closeToast, useDefaultClassNames = true }: ShowToastMessage) {
        toast.dismiss();
        toast.success(message, {
            position: 'top-center',
            hideProgressBar: true,
            closeButton: false,
            className: `${
                className && className
            } ${ useDefaultClassNames ? 'SD:w-[31.25rem] 2K:w-[31.25rem] 4K:w-[31.25rem] 8K:w-[31.25rem] SD:h-[2rem] 2K:h-[1rem] 4K:h-[2rem] 8K:h-[3rem]' : ''}`,
            icon: false,
            theme: 'colored',
            autoClose: closeToast ? 1 : 5000,
        });
    }

    function errorToastMessage({ className, message, closeToast, useDefaultClassNames = true }: ShowToastMessage) {
        toast.dismiss();
        toast.error(message, {
            position: 'top-center',
            hideProgressBar: true,
            closeButton: false,
            className: `${
                className && className
            } ${ useDefaultClassNames ? 'SD:w-[31.25rem] 2K:w-[31.25rem] 4K:w-[31.25rem] 8K:w-[31.25rem] SD:h-[2rem] 2K:h-[1rem] 4K:h-[2rem] 8K:h-[3rem]' : ''}`,
            icon: false,
            theme: 'colored',
            autoClose: closeToast ? 1 : 5000,
        });
    }

    return {
        successToastMessage,
        errorToastMessage,
    };
};