import { gql } from '@apollo/client';

const TMS_MANAGE_QUERY = {
  getParticipantsForApproval: gql`
    query tmsManageGetParticipantsForApproval($details: TmsManageGetParticipantsForApprovalInput) {
      tmsManageGetParticipantsForApproval(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          list {
            participant_id
            reference_participant_username
            reference_participant_display_name
            reference_participant_avatar_url
            is_official_account
            final_rank
            is_checked_in
            reference_participant_total_members
            last_cursor
          }
        }
      }
    }
  `,

  getParticipantsForSeeding: gql`
    query tmsManageGetParticipantsForSeeding($details: TmsManageGetParticipantsForSeedingInput) {
      tmsManageGetParticipantsForSeeding(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          list {
            reference_participant_username
            reference_participant_display_name
            reference_participant_avatar_url
            is_official_account
            participant_id
          }
        }
      }
    }
  `,
};

export { TMS_MANAGE_QUERY };
