import { Carousel, CarouselProps } from 'react-responsive-carousel';
import styled from 'styled-components';
import tw from 'twin.macro';

const StyledProductImagesContainer = styled.div`
    ${tw`
        relative
        mb-32

        SD:mb-0
        SD:grid
        SD:grid-cols-productImages
        SD:gap-x-24
        SD:aspect-[588/700]
    `}
`;

const StyledProductImagesList = styled.ul`
    ${tw`
        hidden
        flex-col
        gap-x-16

        SD:flex
        SD:overflow-scroll
    `}
`;

const StyledProductImagesListItem = styled.li`
    ${tw`
        border-0
        border-b-2
        border-transparent

        hover:border-sunset
    `}
`;

const StyledTag = styled.span`
    ${tw`
        font-medium
        text-bodySmall
        absolute
        px-8
        py-4
        bg-secondary
        text-primary
        top-[24px]
        left-[24px]
        z-10
        rounded-[2px]
    `}
`;

const StyledCarousel = styled(Carousel)<Partial<CarouselProps>>`
    .carousel {
        ${tw`
            overflow-hidden
            rounded-[6px]
            SD:rounded-[0.5rem]
        `}
    }

    .thumbs-wrapper {
        ${tw`
            m-0
        `}
    }

    .slider-wrapper {
        ${tw`
            border-[0.0625rem]
            border-secondary/20
            rounded-[0.5rem]
        `}
    }

    ul.control-dots {
        ${tw`
            absolute
            flex
            gap-x-[10px]
            bottom-[37px]
            w-full
            justify-center
            z-10
        `}

        li.dot {
            ${tw`
                bg-secondary/[0.2]
                ease-linear
                h-[5px]
                w-[5px]
                transition
                transition-all
                shadow-none
                opacity-100
                rounded-none
                m-0
            `}

            &.selected {
                ${tw`
                bg-sunset
                w-[27px]
            `}
            }
        }
    }
`;

export {
    StyledProductImagesContainer,
    StyledProductImagesList,
    StyledProductImagesListItem,
    StyledTag,
    StyledCarousel,
};
