import { IMAGES } from '@fe-monorepo/assets';
import { useCommunity, useTranslate } from '@fe-monorepo/hooks';
import { UserSearchModel } from '@fe-monorepo/models';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Image from '../../../components/Atoms/Media/Image';

interface UserResultProps {
  user: UserSearchModel;
  isLoading?: boolean;
  shouldShowFollow?: boolean;
  isMobile?: boolean;
  isOnAllTab?: boolean;
}

const UserResult = (props: UserResultProps) => {
  const navigate = useNavigate();
  const { follow, unfollow } = useCommunity();
  const { translate } = useTranslate();
  const [isFollowing, setIsFollowing] = useState<boolean>(props.user.viewer_is_following === 1);

  const onFollowClick = async () => {
    if (isFollowing) {
      await unfollow(props.user.id).then(response => {
        if (response.data?.unfollowUser.is_successful) {
          setIsFollowing(false);
        }
      });
    } else {
      await follow(props.user.id).then(response => {
        if (response.data?.followUser.is_successful) {
          setIsFollowing(true);
        }
      });
    }
  };

  return (
    <div
      className={`flex flex-row justify-between items-center cursor-pointer ${props.isOnAllTab ? 'py-[.5rem]' : 'py-[1rem]'}`}
      onClick={() => navigate(`/users/${props.user.id}`)}
    >
      <div className="flex flex-row gap-[.5rem] items-center">
        <Image
          img={props?.user?.img ? props?.user?.img : IMAGES?.DefaultPlaceholder?.toString()}
          divStyle=""
          imgStyle="border-[.075rem] border-neutral-300-zinc-700 rounded-full aspect-square w-[2rem] h-[2rem]"
          alt=""
          fallback={
            <Image
              img={IMAGES?.DefaultPlaceholder?.toString()}
              alt=""
              divStyle=""
              imgStyle="border-[.075rem] border-neutral-300-zinc-700 rounded-full aspect-square w-[2rem] h-[2rem]"
            />
          }
        />
        <p className="font-regular flex flex-col gap-[.1rem]">
          <span className={`text-secondary ${props.isMobile ? 'text-fs-body' : 'text-fs-body-small'}`}>{props?.user?.title}</span>
          <span className="text-fs-caption text-secondary/50">@{props?.user?.id}</span>
        </p>
      </div>
      {((props.isOnAllTab && props.isMobile) || (props.isMobile && props?.shouldShowFollow)) && (
        <p
          className={`text-fs-body-small font-regular cursor-pointer
                    ${!isFollowing ? `text-sunset` : `text-outline`}`}
          onClick={e => {
            onFollowClick();
            e.stopPropagation();
          }}
        >
          {isFollowing ? translate('common_following') : translate('common_follow')}
        </p>
      )}
    </div>
  );
};

export default UserResult;
