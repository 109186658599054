import { gql } from '@apollo/client';

export const MATCHES_GETINFO_QUERY = {
  getMatchInfo: gql`
    query getMatchInfo($details: GetMatchInfoInput) {
      getMatchInfo(details: $details) {
        is_successful
        error_msg
        error_code
        app_code
        data {
          tournament_match_score_id
          reference_match_id
          reference_participant_id
          opponent_a_id
          opponent_a_score
          opponent_b_id
          opponent_b_score
          img_url
          comment
          report_type
          created_at
        }
      }
    }
  `,
};
