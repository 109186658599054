import React from 'react';

interface DiscountCodeProps {
  title?: string | null;
  code: string;
}

const DiscountUsed: React.FC<DiscountCodeProps> = ({ title, code }) => {
  return (
    <div className="discount__main flex flex-col items-start p-[1.125rem] pb-[1rem] gap-[1rem] self-stretch rounded-md border border-black-black-20 font-regular">
      <span className="text-secondary text-fs-body-small">{title}</span>
      <span className="text-secondary text-fs-body">{code}</span>
    </div>
  );
};

export default DiscountUsed;
