function ReplyIcon() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.8333 15.8332V12.4998C15.8333 11.8054 15.5903 11.2151 15.1042 10.729C14.6181 10.2429 14.0278 9.99984 13.3333 9.99984H5.6875L8.6875 12.9998L7.5 14.1665L2.5 9.1665L7.5 4.1665L8.6875 5.33317L5.6875 8.33317H13.3333C14.4861 8.33317 15.4688 8.73942 16.2812 9.55192C17.0938 10.3644 17.5 11.3471 17.5 12.4998V15.8332H15.8333Z"
        fill="var(--secondary-black)"
      />
    </svg>
  );
}

export default ReplyIcon;
