import styled from 'styled-components';
import tw from 'twin.macro';

// TODO: the horizontal padding is a common style
// Find a way to put it in the tailwind config file

// Make the gap responsive
const StyledMainSectionContainer = styled.section`
    ${tw`
        SD:grid
        SD:grid-cols-productDetailsMainSection
        SD:gap-x-[2%]
        px-20 md:px-64 lg:px-120 2K:px-160 4K:px-320 8K:px-655
    `}
`;

export { StyledMainSectionContainer };
