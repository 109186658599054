import { IMAGE_ALT_LABELS, IconNames, TxKeyPath, convertGMTToIana, getCurrentUserTimeZone } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import CurrencyText from '@fe-web/Atoms/Text/CurrencyText';
import { format, parseISO } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import arLocale from 'date-fns/locale/ar';
import enLocale from 'date-fns/locale/en-US';
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';

interface ProductItemsProps {
  idinfo: string;
  orderdate: string;
  total: number;
  status: string;
  products: string[];
  showPlus: boolean;
  isMobile?: boolean;
  handleDetailsButtonClick: () => void;
}

const ProductDetailsItems: React.FC<ProductItemsProps> = ({
  idinfo,
  orderdate,
  total,
  status,
  products,
  handleDetailsButtonClick,
  showPlus,
  isMobile,
}) => {
  const { translate } = useTranslate();
  const [isClicked, setIsclicked] = useState(false);

  const uniqueIdsCount = products ? products.length - 2 : 0;
  const seeOrderDetails = (isClicked: boolean) => {
    handleDetailsButtonClick();
    setIsclicked(isClicked);
  };

  const preferred_timezone = useSelector((state: RootState) => state.user.userContext.preferred_timezone);
  const language = useSelector((state: RootState) => state.app.language);
  const locale = language === 'ar' ? arLocale : enLocale;
  const orderedDate = parseISO(orderdate);
  const finalTimezone = preferred_timezone ? convertGMTToIana(preferred_timezone) : getCurrentUserTimeZone();
  const orderedZonedDate = toZonedTime(orderedDate, finalTimezone);
  const orderDateFormatted = format(orderedZonedDate, language === 'ar' ? 'dd MMMM yyyy' : 'dd MMM yyyy', { locale });

  return isMobile ? (
    <>
      <div
        className={`flex flex-row justify-between gap-[1rem] SD:mr-[1.25rem] mdxs:mt-[1rem] ${showPlus ? 'hidden' : ''}`}
        onClick={() => seeOrderDetails(!isClicked)}
      >
        <div className="flex flex-col col-span-2 gap-[.5rem] ">
          <p className="text-fs-caption font-regular text-secondary">{translate('shop.cart.order_details_page.order_id')}</p>
          <p className="text-fs-body-small font-regular text-secondary-100">{idinfo}</p>
        </div>
        <div className="flex flex-col col-span-2 gap-[.5rem]">
          <p className="text-fs-caption font-regular text-secondary">{translate('shop.cart.order_details_page.order_date')}</p>
          <p className="text-fs-body-small font-regular text-secondary-100">{orderDateFormatted}</p>
        </div>
      </div>
      <div
        className={`flex flex-col col-span-4 SD:mr-[1.25rem] ${showPlus ? 'border-b-[.25px] border-secondary/25 pb-[1.5rem]' : 'hidden'}`}
      >
        <div className="flex flex-row justify-between my-[.5rem]">
          <CurrencyText className="text-fs-body font-medium mt-[.25rem] text-secondary" currency={total} />
          <div
            className={`text-fs-small font-medium text-center rounded-[2px] border mt-[.10rem] py-[.13rem] px-8 truncate mb-[.5rem] w-[8rem] SD:w-auto ${
              status === 'Delivered'
                ? 'border-[#108715]/[0.4] bg-[#108715]/[0.08] text-[#108715]'
                : 'border-moonlight/[0.4] bg-moonlight/[0.08] text-moonlight'
            }`}
          >
            {status || 'N/A'}
          </div>
        </div>
        <div className="flex flex-row  justify-between mb-[1rem]">
          <div className="flex flex-col gap-[.5rem]">
            <p className="text-fs-caption font-regular text-secondary opacity-[0.50]">{idinfo}</p>
            <p className="text-fs-caption font-regular text-secondary opacity-[0.50]">
              {(products && products.length) || 0} {products && products.length > 1 ? 'products' : 'product'}
            </p>
          </div>
          <p className="text-fs-caption font-regular text-secondary opacity-[0.50]">{orderDateFormatted}</p>
        </div>
        <div className="flex flex-row justify-between" onClick={() => seeOrderDetails(!isClicked)}>
          <div className="flex flex-row gap-[1rem]">
            {products &&
              products.slice(0, 2).map(item => (
                <div className="lazyimage rounded-[2px] bg-secondary/[0.06] flex gap-[0.625rem] h-[7rem] w-[7rem] px-[0.938rem] py-[0.813rem] justify-center items-center mdxs:h-[5rem] mdxs:w-[5rem] ">
                  <LazyLoadImage className="h-[4.5rem] w-[4.5rem] mdxs:h-[3rem] mdxs:w-[3rem] " src={item} alt="" />
                </div>
              ))}
            {((products && (products.length > 2 || products.length === 0)) || !products) && (
              <div
                className="
                  uniqueIdsCount rounded-[0.125rem] bg-secondary/[0.06] flex w-[7rem] h-[7rem] gap-[0.625] px-[0.938rem] py-[0.813rem]
                  justify-center items-center text-orange-500 font-medium mdxs:h-[5rem] mdxs:w-[5rem]"
              >
                {`${uniqueIdsCount === 0 ? '' : '+'} ${uniqueIdsCount}`}
              </div>
            )}
          </div>
          <div className="flex items-center justify-center">
            <ResponsiveIcon
              className={`fill-sunset ${!showPlus ? 'rotate-90' : 'rotate-0'}`}
              name={IconNames.chevronRight}
              baseHeight={15}
              baseWidth={15}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <div
      className={`flex flex-row justify-between ${isClicked ? '' : 'border-b-[.25px] border-secondary/25 pb-[1.5rem]'} grid grid-cols-8`}
    >
      <div className="idinfo flex justify-start item-center font-medium text-fs-body mt-[1rem] mr-[.25rem]">
        <span className="truncate text-secondary">{idinfo}</span>
      </div>
      <div className="orderdate flex justify-start item-center gap-[1rem] mt-[1rem] mr-[.25rem]">
        <span className="bg-secondary/25 w-[0.063rem] h-[1.5rem]"></span>
        <span className="truncate text-secondary text-fs-body font-regular">{orderDateFormatted}</span>
      </div>
      <div className="total flex justify-start item-center gap-[1rem] mt-[1rem] mr-[.25rem]">
        <span className="bg-secondary/25 w-[0.063rem] h-[1.5rem] "></span>

        <CurrencyText className="text-secondary text-fs-body font-regular" currency={total} />
      </div>
      <div className="status flex justify-start item-center gap-[1rem] mt-[1rem] mr-[.25rem] col-span-2">
        <span className="bg-secondary/25 w-[0.063rem] h-[1.5rem]"></span>
        <span
          className={`purple py-[0.125rem] px-[0.5rem] border-[0.063rem] rounded-[0.125rem] font-medium h-[1.875rem] truncate  ${
            status === translate('shop.cart.order_status.delivered')
              ? 'border-[#108715]/[0.4] bg-[#108715]/[0.08] text-[#108715]'
              : 'border-moonlight/[0.4] bg-moonlight/[0.08] text-moonlight'
          }`}
        >
          {status || 'N/A'}
        </span>
      </div>
      <div className="image flex justify-start items-center gap-[1rem] col-span-2">
        <span className="bg-secondary/25 w-[0.063rem] h-[1.5rem]"></span>
        {products?.slice(0, 2).map((item, i) => (
          <div
            key={i}
            className="lazyimage rounded-[2px] bg-secondary/[0.06] flex gap-[0.625rem] h-[4rem] px-[0.938rem] py-[0.813rem] justify-center items-center"
          >
            <LazyLoadImage
              className="h-[1.875rem] w-[1.875rem]"
              src={item}
              alt={translate(IMAGE_ALT_LABELS.ordered_product_thumbnail as TxKeyPath) ?? ''}
            />
          </div>
        ))}
        {((products && (products.length > 2 || products.length === 0)) || !products) && (
          <div
            className="
              uniqueIdsCount rounded-[0.125rem] bg-secondary/[0.06] flex w-[4rem] gap-[0.625] h-[4rem] px-[0.938rem] py-[0.813rem]
              justify-center items-center text-orange-500 font-medium"
          >
            {`${uniqueIdsCount === 0 ? '' : '+'} ${uniqueIdsCount}`}
          </div>
        )}
      </div>
      <div className="flex ml-[1rem] mt-[1rem] ">
        <p
          className="font-medium cursor-pointer text-sunsetText whitespace-nowrap"
          onClick={() => {
            handleDetailsButtonClick();
            setIsclicked(!isClicked);
          }}
        >
          {showPlus ? translate('pageTitle_details') + ' +' : translate('pageTitle_details') + ' -'}
        </p>
      </div>
    </div>
  );
};

export default ProductDetailsItems;
