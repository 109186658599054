import { IconNames } from '@fe-monorepo/helper';
import { useUserProfile } from '@fe-monorepo/hooks';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import { useConnectProvider } from '@fe-web/hooks/useConnect';
import { useNotificationProvider } from '@fe-web/hooks/useNotification';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { SidebarNotificationContainer } from '../../../pages/NotificationCenter/SidebarNotifOverview';

const NavNotification = () => {
  const { setNotificationSidebarStatus, notificationSidebarStatus } = useConnectProvider();
  const { notificationLength } = useNotificationProvider();
  const { user } = useUserProfile();
  const location = useLocation();
  const [isNotificationOpen, setIsNotificationOpen] = useState<boolean>(false);

  const handleNavChatClick = () => {
    const isMaximized = location.pathname.includes('/notifications');
    if (!isMaximized) {
      setNotificationSidebarStatus?.(status => !status);
    } else setNotificationSidebarStatus?.(false);
  };

  useEffect(() => {
    setNotificationSidebarStatus?.(isNotificationOpen);
  }, [isNotificationOpen]);

  const verifyNotificationCount = !user?.email || !user?.is_email_verified || !user?.mobile || !user?.is_mobile_verified ? 1 : 0;

  return (
    <div className="relative overflow-visible">
      <div className="relative hover:cursor-pointer" onClick={handleNavChatClick}>
        <ResponsiveIcon
          name={IconNames.bellNotification}
          baseHeight={25}
          baseWidth={25}
          iconClasses={`${notificationSidebarStatus ? 'fill-secondary' : 'fill-secondary/50'} hover:fill-secondary`}
        />
      </div>
      {notificationLength !== undefined && (notificationLength > 0 || verifyNotificationCount > 0) && (
        <span
          onClick={handleNavChatClick}
          className="flex justify-center hover:cursor-pointer
            absolute bottom-[0.5rem] left-[0.75rem]
            rounded-[1.875rem]
            w-[1.5rem] text-fs-body-small
            bg-red text-white100 font-regular"
        >
          {notificationLength > 9 ? '9+' : notificationLength + verifyNotificationCount}
        </span>
      )}
      <SidebarNotificationContainer
        isNotificationOpen={notificationSidebarStatus}
        closeSidebar={() => setNotificationSidebarStatus?.(false)}
        showFullViewIcon={true}
        fixedPosition={true}
        isFromChat={true}
      />
    </div>
  );
};

export default NavNotification;
