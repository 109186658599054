import { useBitsConfig, useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Spinner from '@fe-web/Atoms/Load/Spinner';
import LazyImage from '@fe-web/Atoms/Media/LazyImage';
import VerifyPhoneModal from '@fe-web/Molecules/SuperPlus/VerifyPhoneModal';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import quickplay from 'quickplay-shorts-js';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';

import { ReactComponent as VideoLikeIcon } from '../../assets/icons/VideoLikeIcon.svg';
import { ReactComponent as VideoViewsIcon } from '../../assets/icons/VideoViewsIcon.svg';
import { CONTENT_TYPE, EVENTS } from '../../constants';
import { useLoginModalContext } from '../../contexts/LoginModal';
import useAnalytics from '../../hooks/useAnalytics';
import LoginModal from '../Login-UI/LoginModal';
import { formatLargeNumber } from '../VerticalPlayer/helpers';
import './Challenge.css';

function Challenge() {
  const [challengeDetail, setChallengeDetail]: any = useState([]);
  const [challengeTotal, setChallengeTotal] = useState(0);
  const [challenge, setChallenge]: any = useState({});
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const { challengeName } = useParams();
  const navigate = useNavigate();
  const { trackEvent } = useAnalytics();
  const { translate } = useTranslate();
  const [isVerifyOpen, setIsVerifyOpen] = useState<boolean>(false);
  const { user, isLoggedIn } = useUserProfile();
  const { showLoginModal, setShowLoginModal } = useLoginModalContext();
  const isPhoneNotAdded = !user?.mobile;
  const isPhoneNotVerified = user?.mobile && !user?.is_mobile_verified;
  const { showBitsCreator } = useBitsConfig();
  const globalConfig = useSelector((state: RootState) => state.app.globalConfig);

  const handlePlusClick = () => {
    if (isLoggedIn) {
      if (isPhoneNotAdded || isPhoneNotVerified) {
        setIsVerifyOpen(true);
        return;
      }
      navigate(`${AppRoutes.bits}/create-post/step-1`);
    } else {
      setShowLoginModal(true);
    }
  };

  const challengeModule = new quickplay.QuickPlayChallenges();

  useEffect(() => {
    async function fetchChallengeData() {
      setIsLoading(true);
      try {
        let limit = 12;
        let offset = page;
        const challengeResponse = await challengeModule.getChallengeDetails({
          limit: limit,
          offset: offset,
          hashtagId: challengeName,
        });
        if (challengeResponse?.status === 200) {
          setChallengeDetail((prevDetails: any) => [...prevDetails, ...challengeResponse.data.result.videos]);
          setChallenge(challengeResponse.data.result.challenge);
          setChallengeTotal(challengeResponse.data.total);
          if (challengeResponse.data.result.videos.length < limit) {
            setHasMore(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
      setIsLoading(false);
    }
    fetchChallengeData();
  }, [page]);

  const handleContentDetail = (contentInfo: any, index: number) => {
    trackEvent({
      event: EVENTS.THUMBNAIL_CLICK,
      content: { ...contentInfo, horizontalIndex: index, verticalIndex: 0 },
    });
    navigate(
      `${AppRoutes.bitsPlay}/content/${challenge?.hashtagId}?contentType=${CONTENT_TYPE.CHALLENGE}&clickedVideoId=${contentInfo._id}`,
    );
  };

  const handleChallengeLeaderBoard = () => {
    navigate(`${AppRoutes.bits}/challengeleaderboard/${challenge?.hashtagId}`);
  };

  const handleLoadMore = () => {
    setPage(prev => prev + 1);
  };

  return (
    <>
      <section className="main-section">
        {/* Section Title Start */}
        <div className="section-head">
          <div className="section-head-title">
            <h2>#{challenge?.title}</h2>
            <p>
              <span>{formatLargeNumber(challengeTotal || 0)} Bits &nbsp;</span> {translate('quickplay.label-use-hashtag-description')}
            </p>
          </div>
          <div className="flex gap-16">
            {challengeDetail?.length >= 3 && (
              <button onClick={handleChallengeLeaderBoard} className="leaderboard-btn">
                {translate('quickplay.label-view-leaderboard')}
              </button>
            )}
            {showBitsCreator && (
              <button
                onClick={handlePlusClick}
                className="join-btn flex items-center text-base not-italic max-h-[2.5rem] font-medium gap-[0.5rem] z-1 text-white border-sunset border-[0.063rem] mb-[0px] px-[1rem] py-[0.5rem] rounded-[0.188rem] bg-sunset disabled:bg-secondary/[0.1] after:bg-sunset before:bg-white100 cursor-pointer hover:border-[0.0625rem] hover:border-sunset hover:text-sunsetText button-animation-ltr"
              >
                Join
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M11 17H13V13H17V11H13V7H11V11H7V13H11V17ZM5 21C4.45 21 3.97917 20.8042 3.5875 20.4125C3.19583 20.0208 3 19.55 3 19V5C3 4.45 3.19583 3.97917 3.5875 3.5875C3.97917 3.19583 4.45 3 5 3H19C19.55 3 20.0208 3.19583 20.4125 3.5875C20.8042 3.97917 21 4.45 21 5V19C21 19.55 20.8042 20.0208 20.4125 20.4125C20.0208 20.8042 19.55 21 19 21H5ZM5 19H19V5H5V19Z"
                    fill="white"
                  />
                </svg>
              </button>
            )}
          </div>
        </div>
        {/* Section Title End */}

        {/* Video List - Grid View Start */}
        <div className="flex flex-col gap-y-[2rem] justify-center items-center">
          <div className="grid grid-cols-12 gap-y-[2rem] gap-x-[1rem] w-[100%]" id="tab1">
            {challengeDetail?.map((video: any, index: number) => {
              let thumbnailUrls = video.thumbnailUrls ? video.thumbnailUrls[0] : video.thumbnailUrl;
              return (
                <div className="col-span-6 lg:col-span-2" key={video.videoId}>
                  <div className="relative cursor-pointer" id={video.videoId}>
                    {/* User Profile */}
                    {globalConfig?.profileHidden ? (
                      <>
                        <img src={video.user.profileImageUrl} alt={video.user.userName} className="video_profile--image" />
                        <p className="video_profile--name">{video.user.userName}</p>
                      </>
                    ) : (
                      <Link to={`/users/${video.user.userId}`} className="video_profile">
                        <img src={video.user.profileImageUrl} alt={video.user.userName} className="video_profile--image" />
                        <p className="video_profile--name">{video.user.userName}</p>
                      </Link>
                    )}
                    {/* Video Image */}
                    <LazyImage
                      img={thumbnailUrls}
                      imgStyle="w-full h-full aspect-[9/16] object-cover object-center rounded-md"
                      divStyle=""
                      onClick={() => handleContentDetail(video, index)}
                    />
                    {/* Video Views & Like Count */}
                    <div className="video_detail">
                      <div className="video_info videoViews">
                        <VideoViewsIcon />
                        <span className="video_info--count">{video?.viewsCount ? formatLargeNumber(video.viewsCount) : 0}</span>
                      </div>

                      <div className="video_info videoLike">
                        <VideoLikeIcon />
                        <span className="video_info--count">{video?.likeCount ? formatLargeNumber(video.likeCount) : 0}</span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        {/* Video List - Grid View End */}

        <div className="flex items-center justify-center mt-32">
          {isLoading && <Spinner className="h-[50px] w-[50px] mx-auto my-0" />}
          {!isLoading && hasMore && challengeDetail.length > 0 && (
            <button
              onClick={handleLoadMore}
              className="py-[0.5rem] px-[1.5rem] w-fit mx-auto my-0 text-sunset responsive-text-body font-medium border-[1px] 2K:border-[1.77px] 4K:border-[2.66px] 8K:border-[5.33px] hover:border-[1px] hover:2K:border-[1.77px] hover:4K:border-[2.66px] hover:8K:border-[5.33px] rounded-sm button-animation-ltr"
            >
              Show more
            </button>
          )}
        </div>
      </section>

      <VerifyPhoneModal
        type={isPhoneNotAdded ? 'add' : isPhoneNotVerified ? 'verify' : null}
        open={isVerifyOpen}
        onClose={() => setIsVerifyOpen(false)}
      />
      <LoginModal show={showLoginModal} handleClose={() => setShowLoginModal(false)} />
    </>
  );
}

export default Challenge;
