import styled from 'styled-components';
import tw from 'twin.macro';

const StyledProductsCarouselContainer = styled.div`
  ${tw`relative `}

  .swiper {
    ${tw`overflow-visible`}
  }
`;

const ButtonNext = styled.button`
  ${tw`absolute `}
  right: 80px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
  &:disabled {
    opacity: 0;
    pointer-events: none;
  }
  svg {
    transform: rotate(-90deg);
    ${tw` fill-outline`}
  }
`;

const ButtonPrevious = styled.button`
  ${tw`absolute `}
  left: 80px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 20;
  &:disabled {
    opacity: 0;
    pointer-events: none;
  }
  svg {
    transform: rotate(90deg);
    ${tw` fill-outline`}
  }
`;

const StyledHeader = styled.div`
  ${tw`
    flex
    justify-between
    items-end
    mb-[2rem]
    px-20

    md:px-64
    lg:px-120
    2K:px-160
    4K:px-320
    8K:px-655
  `}
`;

export { ButtonNext, ButtonPrevious, StyledHeader, StyledProductsCarouselContainer };
