import { gql } from '@apollo/client';

export const RETURN_DATA_QUERY = {
  omsGetAllReturn: gql`
    query omsGetAllReturn($details: OmsReturnGetAllInput) {
      omsGetAllReturn(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          return_number
          refunded_amount
          total_amount
          last_cursor
          status_code
          created_at
          updated_at
          media
          items {
            name_en
            name_ar
            return_product_id
            tracking_number
            created_at
            updated_at
            sku
            amount
            status_code
            status_ar
            status_en
            return_reason_code
            reject_reason_ar
            reject_reason_en
            product_media
            return_media
          }
        }
      }
    }
  `,
  omsGetInfoReturn: gql`
    query omsGetInfoReturn($details: OmsReturnGetInfoInput) {
      omsGetInfoReturn(details: $details) {
        is_successful
        error_code
        error_msg
        data {
          return_number
          status_code
          created_at
          updated_at
          refunded_amount
          total_amount
          products {
            return_product_id
            sku
            amount
            status_code
            status_ar
            status_en
            return_reason_code
            reject_reason_ar
            reject_reason_en
            product_media
            return_media
            name_en
            name_ar
          }
        }
      }
    }
  `,
};
