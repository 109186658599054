import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ParticipantsModel } from '@fe-monorepo/models';

type SliceState = {
    participantsList: ParticipantsModel;
};

type ParticipantsModel2 = {
    participant_id: number;
    game_id: string;
    reference_participant_username: string;
    reference_participant_display_name: string;
    reference_participant_avatar_url: string;
    is_official_account: number;
    final_rank: number;
    is_checked_in: number;
    reference_participant_total_members: number;
    last_cursor: number;
    sort_id: number;
};

type MyStandingModel = {
    username: string;
    display_name: string;
    avatar_url: string;
    is_official_account: number;
    is_checked_in: number;
    final_rank: number;
};

type GetParticipantsModel = {
    my_standing: MyStandingModel;
    list: ParticipantsModel2[];
}

export const participantSlice = createSlice({
    name: 'participants',
    initialState: {participantsList: {} as GetParticipantsModel},
    reducers: {
        setParticipantsList: (state, action: PayloadAction<GetParticipantsModel>) => {
            state.participantsList = action.payload
        },
    },
});

export const { setParticipantsList } = participantSlice.actions;
