import { IMAGES } from '@fe-monorepo/assets';
import { ShopModel } from '@fe-monorepo/models';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../app.routes.enum';
import Image from '../../../components/Atoms/Media/Image';

type ProductResultProps = {
  product: ShopModel;
  isLoading?: boolean;
  searchValue: string;
  isRTL?: boolean;
};

const ProductResult = (props: ProductResultProps) => {
  const navigate = useNavigate();

  const highlightedSearchTerm = (searchValue: string, item: string) => {
    const styledUsername = (
      <Highlighter
        highlightClassName="dark:text-white text-black bg-transparent"
        unhighlightClassName="text-otherGray"
        searchWords={searchValue.split(' ')}
        autoEscape={true}
        textToHighlight={item}
      />
    );
    return styledUsername;
  };

  const productTitle = props.isRTL ? props.product.title_ar : props.product.title_en;

  return (
    <div
      className="flex flex-row gap-[1rem] py-[1rem] items-center cursor-pointer"
      onClick={() => navigate(`${AppRoutes.shop}/c-${props.product.collection_code}/${props.product.id}`)}
    >
      <Image
        img={props?.product?.img ? props?.product?.img : IMAGES?.DefaultPlaceholder?.toString()}
        divStyle="flex items-center justify-center aspect-square w-[1.5rem] h-[1.5rem]"
        imgStyle="h-full object-center"
        alt={productTitle?.replace(/\s/g, '_')}
      />
      <div className="flex flex-col gap-[.25rem] w-[80%]">
        <p className={`text-fs-body font-regular truncate text-secondary`}>
          {highlightedSearchTerm(props.searchValue, productTitle || '')}
        </p>
        <div className={`text-caption 4xl:text-mobileSubtitle 5xl:text-title 8xl:text-huge flex`}>
          {props.isRTL
            ? props.product.breadcrumbs_ar?.map((item, index) => {
                return (
                  <p
                    className={`${
                      index === (props.product.breadcrumbs_ar && props.product.breadcrumbs_ar?.length - 1)
                        ? `text-btn-primary`
                        : `text-secondary/40`
                    }`}
                    key={index}
                  >
                    {item}

                    {index !== (props.product.breadcrumbs_ar && props.product.breadcrumbs_ar?.length - 1) && (
                      <span className={`mx-4 4xl:mx-8 5xl:mx-12 8xl:mx-20`}>{'>'}</span>
                    )}
                  </p>
                );
              })
            : props.product.breadcrumbs_en?.map((item, index) => {
                return (
                  <p
                    className={`${
                      index === (props.product.breadcrumbs_en && props.product.breadcrumbs_en?.length - 1)
                        ? `text-btn-primary`
                        : `text-secondary/40`
                    }`}
                    key={index}
                  >
                    {item}

                    {index !== (props.product.breadcrumbs_en && props.product.breadcrumbs_en?.length - 1) && (
                      <span className={`mx-4 4xl:mx-8 5xl:mx-12 8xl:mx-20`}>{'>'}</span>
                    )}
                  </p>
                );
              })}
        </div>
      </div>
    </div>
  );
};

export default ProductResult;
