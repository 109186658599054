import { EwcProvider, useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import { toastWrapper } from '@fe-web/Atoms/Toast';
import brazeHelper from '@fe-web/helpers/brazeHelper';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import { AppRoutes } from 'apps/fe-web/src/app/app.routes.enum';
import mixpanelHelper from 'apps/fe-web/src/app/helpers/mixpanelHelper';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import TopBanner from '../../../../Home/Sections/GenGEntryPoints/TopBanner';

interface SubNavBarProps {
  CTASource: string;
}

const SubNavBar = (props: SubNavBarProps) => {
  const { CTASource } = props;

  const navigate = useNavigate();
  const { translate } = useTranslate();
  const { dir } = useHeaderState();
  const location = useLocation();
  const prefs = useSelector((state: RootState) => state.app);

  const handleShowToastMessage = (message: string, type: 'error' | 'success') => {
    toastWrapper(type, message);
  };

  return (
    <>
      <EwcProvider showToastMessage={handleShowToastMessage}>
        <TopBanner CTASource={CTASource} />
      </EwcProvider>
      <div className="flex flex-row gap-[1.5rem] px-[1.25rem] SD:px-[7.5rem] pt-[0.5938rem] items-center border-b-[0.0625rem] border-zinc-800-gray-200 bg-primary">
        <div
          onClick={() => {
            mixpanelHelper.trackPageView(
              'PLAY - TOURNAMENTS TAB',
              'play_page_tournaments_tab',
              window.location.pathname,
              document.referrer,
            );
            brazeHelper.trackPageView('PLAY - TOURNAMENTS TAB', 'play_page_tournaments_tab', window.location.pathname, document.referrer);

            window.location.replace(`https://tournament.stcplay.gg/${prefs?.language === 'ar' ? 'ar' : 'en_US'}/`);
          }}
          className={`font-regular pb-[0.5938rem] 
                    text-fs-body-small  cursor-pointer hover:cursor-pointer  after:bottom-[-0rem] 
                    after:bg-secondary w-fit hover:text-secondary 
                    ${
                      location.pathname === AppRoutes.tournaments
                        ? `border-b-[0.125rem] border-secondary text-secondary`
                        : `underline-${dir}-animation text-neutral-400-zinc-500`
                    }
                `}
        >
          {translate('tabTitle_tournaments')}
        </div>
        <div
          onClick={() => navigate(AppRoutes.games)}
          className={`font-regular pb-[0.5938rem] 
                    text-fs-body-small cursor-pointer hover:cursor-pointer after:bottom-[-0rem] 
                    after:bg-secondary w-fit hover:text-secondary
                    ${
                      location.pathname === AppRoutes.games
                        ? `border-b-[0.125rem] border-secondary text-secondary`
                        : `underline-${dir}-animation text-neutral-400-zinc-500`
                    }
                `}
        >
          {translate(`pageTitle_games`)}
        </div>
        <div
          onClick={() => navigate(`${AppRoutes.matchmaking}`)}
          className={`font-regular pb-[0.5938rem] 
                    text-fs-body-small text-neutral-400-zinc-500 cursor-pointer hover:cursor-pointer underline-${dir}-animation after:bottom-[-0rem] 
                    after:bg-secondary w-fit hover:text-secondary
                `}
        >
          {translate(`top_tab_matchmaking`)}
        </div>
      </div>
    </>
  );
};

export default SubNavBar;
