// eslint-disable-next-line simple-import-sort/imports
import {
  add,
  addDays,
  addMinutes,
  fromUnixTime as dateFnsFromUnixTime,
  differenceInHours,
  differenceInMilliseconds,
  differenceInMinutes,
  differenceInWeeks,
  endOfDay,
  endOfWeek,
  endOfYear,
  format,
  formatDistanceToNow,
  formatISO,
  getDate,
  getDay,
  getMonth,
  getTime,
  isAfter,
  isBefore,
  isEqual,
  isPast,
  isSameDay,
  isToday,
  isTomorrow,
  isYesterday,
  parse,
  parseISO,
  startOfDay,
  startOfWeek,
  startOfYear,
  sub,
  subDays,
  subHours,
  subMonths,
  subYears,
  toDate,
} from 'date-fns';
import { formatInTimeZone, toZonedTime } from 'date-fns-tz';
import { ar, enUS } from 'date-fns/locale';

export const utcToZonedTime = (date: string | number | Date, timeZone: string) => {
  return toZonedTime(date, timeZone);
};

export const formattedTimeUTC = (parsedTime: any) => {
  return formatInTimeZone(parsedTime, 'Asia/Riyadh', 'yyyy-MM-dd kk:mm:ss');
};

export const timestamp = (date: any) => {
  return getTime(date);
};

//moment().format('YYYY-MM-DD')
// formatString must be in 'yyyy-MM-dd'

const convertToArabicNumerals = (str: string) => {
  const arabicNumerals = '٠١٢٣٤٥٦٧٨٩';
  return str.replace(/\d/g, match => arabicNumerals[parseInt(match, 10)]);
};

export const dateFormatted = (date: any, formatString: string, lang?: any) => {
  if (!date) {
    return '';
  }
  const formattedDate = format(date, formatString, {
    locale: lang === 'ar' ? ar : enUS,
  });
  const localizedDate = lang === 'ar' ? convertToArabicNumerals(formattedDate) : formattedDate;

  return localizedDate;
};

export const parsedate = (dateString: any, formatString: string) => {
  return parse(dateString, formatString, new Date());
};

//moment().toDate()
export const toDayDate = () => {
  return toDate(new Date());
};

//const date = moment();
//const formattedDate = date.add(5, 'days').format('YYYY-MM-DD');
export const addDayDate = (date: any, numberOfDay: number) => {
  return add(date, { days: numberOfDay });
};

//moment().subtract(1, 'months')
export const subtractDate = (date: any, subString: object) => {
  return sub(date, subString);
};

export const subYear = (numberOfYear: number) => {
  return subYears(new Date(), numberOfYear);
};

export const subMonth = (date: any, amount: any, formatStr: string, lang?: any) => {
  return format(subMonths(date, amount), formatStr, {
    locale: lang === 'ar' ? ar : enUS,
  });
};

export const subDay = (date: number | Date, amount: number) => {
  return subDays(date, amount);
};

export const subYearsWithFormat = (date: any, amount: any, formatStr: string, lang?: any) => {
  return format(subYears(date, amount), formatStr, {
    locale: lang === 'ar' ? ar : enUS,
  });
};

export const year_start = (date: any, formatStr: string, lang?: any) => {
  return format(startOfYear(date), formatStr, {
    locale: lang === 'ar' ? ar : enUS,
  });
};

export const year_end = (date: any, formatStr: string, lang?: any) => {
  return format(endOfYear(date), formatStr, {
    locale: lang === 'ar' ? ar : enUS,
  });
};

//moment().isBefore('2025-01-01');
export const isBeforeDate = (date: any, dateToCompare: any) => {
  return isBefore(date, dateToCompare);
};

export const isSame = (leftDate: any, rightDate: any) => {
  return isEqual(leftDate, rightDate);
};

export const isSameday = (dateLeft: number | Date, dateRight: number | Date) => {
  return isSameDay(dateLeft, dateRight);
};

export const isAfterDate = (date: number | Date, dateToCompare: number | Date): boolean => {
  return isAfter(date, dateToCompare);
};

export const isSameOrAfter = (date: any, dateToCompare: any) => {
  return isAfter(date, dateToCompare) || isEqual(date, dateToCompare); // date1 --> new Date()
};

//moment().add(1, 'days')
export const addDaysToDate = (date: any, days: any) => {
  return addDays(date, days); // Returns a new date
};

export const inMillisecondsDifference = (dateLeft: any, dateRight: any) => {
  return differenceInMilliseconds(dateLeft, dateRight);
};
//moment().startOf('day')
export const dayofstart = (day: any) => {
  return startOfDay(day);
};

//moment().endOf('day')
export const dayofEnd = (day: any) => {
  return endOfDay(day);
};

export const dateIsPast = (date: number | Date) => {
  return isPast(new Date(date));
};

export const subOfHours = (_date: any, hour: number) => {
  return subHours(new Date(_date), hour);
};

//moment().fromNow()
export const formatDistanceFromNow = (day: any) => {
  return formatDistanceToNow(day);
};

//moment().toNow()
export const distanceformatToNow = (day: any) => {
  return formatDistanceToNow(day, { addSuffix: true });
};

//similarly to moment().diff() in the hours unit.
export const hoursDifference = (now: any, targetTimeStamp: any) => {
  return differenceInHours(now, targetTimeStamp);
};

export const differenceInMinutesBetween = (date1: number | Date, date2: number | Date): number => {
  return differenceInMinutes(date1, date2);
};

export const WeeksDifference = (now: any, targetTimeStamp: any) => {
  return differenceInWeeks(now, targetTimeStamp);
};

export const minutesAdd = (date: any, amount: number) => {
  return addMinutes(date, amount);
};

export const ISOparse = (argument: string) => {
  return parseISO(argument);
};

export const get_day = (date: number | Date) => {
  return getDay(date);
};

export const get_month = (date: number | Date) => {
  return getMonth(date);
};
export const get_date = (date: number | Date) => {
  return getDate(date);
};

export const dateToString = (date = new Date()) => {
  return date.toString();
};

// Convert UNIX timestamp to Date
export const fromUnixTime = (timestamp: number | string) => {
  return dateFnsFromUnixTime(Number(timestamp));
};

export const convertToDateTimezone = (date: number | Date) => {
  // Here, assuming you have a function that handles the timezone conversion
  // Replace this with your actual timezone conversion logic
  return toDate(date);
};

/** Get the relative time (like '5 minutes ago') in the desired locale */
export const getFormattedDistanceToNow = (date: any, isArabic?: boolean) => {
  const parsedDate = convertToDateTimezone(date);
  return formatDistanceToNow(parsedDate, {
    addSuffix: true,
    locale: isArabic ? ar : enUS,
  });
};

/** Check if the provided date is today */
export const isDateSameDay = (date: any) => {
  const parsedDate = typeof date === 'string' ? new Date(date) : date;
  return isToday(parsedDate);
};

/** Check if the provided date is yesterday */
export const isDateYesterday = (date: any) => isYesterday(date);

/** Check if the provided date is tomorrow */
export const isDateTomorrow = (date: any) => isTomorrow(date);

/** Get the start of the current week */
export const getStartOfThisWeek = () => startOfWeek(new Date());

/** Get the end of the current week */
export const getEndOfThisWeek = () => endOfWeek(new Date());

/** Format date in 'D MMM YYYY' format */
export const formatDateToDMY = (value: Date | string | number) => {
  return format(new Date(value), 'd MMM yyyy');
};

/** Parse date to ISO format and return Date object */
export const parseToDate = (dateString: string) => {
  return parseISO(dateString);
};

/** Format Date object to 'YYYY-MM-DD' */
export const formatToYMD = (date: Date) => {
  return format(date, 'yyyy-MM-dd');
};

export const formattedLocalTime = () => {
  return format(new Date(), 'yyyy-MM-dd HH:mm:ss');
};

export const parseDateString = (dateString: string) => {
  return new Date(dateString);
};

export const formattedISO = (date: number | Date) => {
  return formatISO(date);
};
