import { useForm } from '@fe-monorepo/forms';
import { IconNames, TxKeyPath } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { DeliveryAddressInput, DeliveryAddressModel } from '@fe-monorepo/models';
import { RootState, useAppSelector } from '@fe-monorepo/store';
import Button from '@fe-web/Atoms/Buttons/Button';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import HoverText from '@fe-web/Atoms/Text/HoverText';
import Checkbox from '@fe-web/Molecules/InputFields/Checkbox';
import { InputFieldStyle } from '@fe-web/Molecules/InputFields/CombinedInputField';
import InputField from '@fe-web/Molecules/InputFields/InputField/InputField';
import PhoneNumberField from '@fe-web/Molecules/InputFields/PhoneNumberField';
import useHeaderState from '@fe-web/hooks/useHeaderState';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { GeocodeResult } from 'use-places-autocomplete';

import useMobileDetect from '../../../hooks/useMobileDetect';
import AddAddressList from './Components/AddAddressList';
import AddAddressViewSection from './Components/AddAddressViewSection';
import { authInputFieldStyleObj } from './commonStyles';
import { validateAddressName, validateEmailSaudiArabia, validateNameSaudiArabia } from './validation';

export type HeaderSectionName = {
  id: number;
  name: string;
  actionName: string;
};

export type MainAddAddressProps = {
  mainContainerClassName?: string;
  headerClassname?: string;
  tabHeaderClassname?: string;
  tabMapCaption?: TxKeyPath;
  tabShortCodeCaption?: TxKeyPath;
  tabFullAddressCaption?: TxKeyPath;
  tabMapClassnames?: {
    mapContainerClassName?: string;
    mapInputBoxContainer?: string;
    mapInputBoxInnerContainer?: string;
    mapComboBoxInput?: string;
    mapComboBoxOptionsContainer?: string;
  };
};

interface ShopMainAddAddressProps {
  setModalClose?: () => void;
  addressList?: DeliveryAddressModel[];
  isAddNewAddress?: boolean;
  addressForUpdate?: DeliveryAddressModel;
  onClickExistingAddressButton: (id: number) => void;
  onClickAddAddressNameButton: () => void;
  onClickSaveAddressAction: (addresInput?: DeliveryAddressInput) => void;
  mainAddAddressClassStyles?: MainAddAddressProps;
  isForSettingsView?: boolean;
  inputFieldsStyleObj?: InputFieldStyle;
}

export type AddAddressValues = 'mapView' | 'fullAddress';

const ShopMainAddAddress = (props: ShopMainAddAddressProps) => {
  const isHeaderDark = useAppSelector((state: RootState) => state.app.headerColorChange);
  const isMobile = useMobileDetect();
  const { translate } = useTranslate();
  const ColorAnimation = `#171619`;
  const { dir } = useHeaderState();

  const chosenDefaultTab =
    (props.mainAddAddressClassStyles?.tabMapCaption
      ? translate(props.mainAddAddressClassStyles?.tabMapCaption)
      : translate('shop.cart.shop_add_address_modal_page.map_view') ?? '') ?? '';

  //Field values
  const [newAddressName, setNewAddressName] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [phoneNumberIsoCode, setPhoneNumberIsoCode] = useState<string>('');
  const [phoneNumberInput, setPhoneNumberInput] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [isFeatured, setIsFeatured] = useState<boolean>(false);
  const [saveLocationDetails, setSaveLocationDetails] = useState<DeliveryAddressInput>();

  const [addAddressViewSection, setAddAddressViewSection] = useState<AddAddressValues>('mapView');
  const [hoveredTab, setHoveredTab] = useState<string>('');
  const [chosenTab, setChosenTab] = useState<string>(chosenDefaultTab);

  //Validations
  const [addressNameError, setAddressNameError] = useState<string>('');
  const [fullNameError, setFullNameError] = useState<string>('');
  const [phoneNumberError, setPhoneNumberError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [isValidForm, setIsValidForm] = useState<boolean>(false);

  const { form, isFormValid, editForm } = useForm();

  const [isAddressNameTouched, setIsAddressNameTouched] = useState(false);
  const [isFullNameTouched, setIsFullNameTouched] = useState(false);
  const [isEmailTouched, setIsEmailTouched] = useState(false);
  const [isPhoneNumberTouched, setIsPhoneNumberTouched] = useState(false);
  const [currentLongitude, setCurrentLongitude] = useState<number>();
  const [currentLatitude, setCurrentLatitude] = useState<number>();

  // Validation functions
  const validateForm = () => {
    const isAddressNameVald = validateAddressName(newAddressName);
    const isFullNameValid = validateNameSaudiArabia(fullName);
    const isEmailValid = validateEmailSaudiArabia(email);
    const isPhoneNumberHasContent = form.phoneNumber?.value.length > 0 || phoneNumberInput?.length > 0;
    // Update error state variables
    setAddressNameError(isAddressNameVald ? '' : 'Invalid address name');
    setFullNameError(isFullNameValid ? '' : 'Invalid full name');
    setPhoneNumberError(isFormValid && isPhoneNumberHasContent ? '' : 'Invalid phone number');
    setEmailError(isEmailValid ? '' : 'Invalid email');
    const isValid =
      isFullNameValid &&
      isEmailValid &&
      isAddressNameVald &&
      isFormValid &&
      isPhoneNumberHasContent &&
      (saveLocationDetails ? true : false);
    setIsValidForm(isValid);
    return isValid;
  };

  //Trigger useEffect on load
  useEffect(() => {
    const setValues = () => {
      let dataInfoToUpdate: DeliveryAddressInput; //Map data that will be saved
      dataInfoToUpdate = {
        country_code: '',
        email: '',
        label: '',
        address: '',
        city: '',
        country: '',
        state: '',
        street: '',
        zip_code: undefined,
        district: '',
        location_long: undefined,
        location_lat: undefined,
        is_featured: undefined,
      };
      if (props.isAddNewAddress) {
        setSaveLocationDetails({ ...dataInfoToUpdate, location_lat: currentLatitude, location_long: currentLongitude });
        return;
      }
      if (props.addressForUpdate) {
        dataInfoToUpdate.address_id = props.addressForUpdate.user_address_id;
        dataInfoToUpdate.country_code = props.addressForUpdate.country_code;
        dataInfoToUpdate.email = props.addressForUpdate.email;
        dataInfoToUpdate.label = props.addressForUpdate.label;
        dataInfoToUpdate.address = props.addressForUpdate.address;
        dataInfoToUpdate.city = props.addressForUpdate.city;
        dataInfoToUpdate.country = props.addressForUpdate.country;
        dataInfoToUpdate.state = 'na'; //Currently no state in DeliveryAddressModel
        dataInfoToUpdate.zip_code = props.addressForUpdate.zip_code;
        dataInfoToUpdate.district = props.addressForUpdate.district;
        dataInfoToUpdate.location_long = props.addressForUpdate.location_long;
        dataInfoToUpdate.location_lat = props.addressForUpdate.location_lat;
        dataInfoToUpdate.is_featured = props.addressForUpdate.is_featured;
        dataInfoToUpdate.mobile_code = props.addressForUpdate.mobile_code;
        dataInfoToUpdate.mobile = props.addressForUpdate.mobile;
        dataInfoToUpdate.street = props.addressForUpdate.street;
        setSaveLocationDetails(dataInfoToUpdate);
        setNewAddressName(props.addressForUpdate?.name ?? '');
        setFullName(props.addressForUpdate?.name ?? '');
        setEmail(props.addressForUpdate?.email ?? '');
        setIsFeatured(Boolean(props.addressForUpdate?.is_default));
        if (props.addressForUpdate.mobile && props.addressForUpdate.mobile_code) {
          const parsedMobileNumber = props.addressForUpdate.mobile.toString().replace(props.addressForUpdate.mobile_code.toString(), '');
          setPhoneNumberIsoCode(props.addressForUpdate?.mobile_code ?? '');
          setPhoneNumberInput(parsedMobileNumber);
          editForm('phoneNumber', { value: parsedMobileNumber, isValid: true });
        }
      }
    };
    setValues();
  }, [props.addressForUpdate, props.isAddNewAddress, currentLatitude, currentLongitude]);

  // TODO: Might be implementing this in the future. This sets the default location to the current location of the user
  // Set current location as the default location on the map
  useEffect(() => {
    function successCallback(position: GeolocationPosition) {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      setCurrentLatitude(latitude);
      setCurrentLongitude(longitude);
    }

    function errorCallback() {
      console.error('Unable to retrieve your location');
    }

    const getCurrentLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      } else {
        console.error('Geolocation not supported');
      }
    };

    getCurrentLocation();
  }, []);

  useEffect(() => {
    validateForm();
  }, [fullName, isFormValid, phoneNumberInput, email, newAddressName, phoneNumberIsoCode, saveLocationDetails]);

  useEffect(() => {
    setPhoneNumberInput(form.phoneNumber?.value);
  }, [form.phoneNumber?.value]);

  const tabHeaderListSectionData = {
    headerListSection: [
      {
        id: 1,
        name:
          (props.mainAddAddressClassStyles?.tabMapCaption
            ? translate(props.mainAddAddressClassStyles?.tabMapCaption)
            : translate('shop.cart.shop_add_address_modal_page.map_view') ?? '') ?? '',
        actionName: 'mapView',
      },
      {
        id: 3,
        name:
          (props.mainAddAddressClassStyles?.tabFullAddressCaption
            ? translate(props.mainAddAddressClassStyles?.tabFullAddressCaption)
            : translate('shop.cart.shop_add_address_modal_page.full_address') ?? '') ?? '',
        actionName: 'fullAddress',
      },
    ],
  };

  const actionTabHover = (currentTab: HeaderSectionName) => {
    if (chosenTab !== currentTab?.name) {
      setHoveredTab(currentTab?.name);
    }
  };

  const actionLeaveHover = () => {
    setHoveredTab('');
  };

  const onTabClick = (currentTab: HeaderSectionName) => {
    setChosenTab(currentTab?.name);
    if (currentTab && currentTab.actionName) {
      switch (currentTab.actionName) {
        case 'mapView':
          setAddAddressViewSection('mapView');
          break;
        case 'fullAddress':
          setAddAddressViewSection('fullAddress');
          break;
        default:
          setAddAddressViewSection('mapView');
          break;
      }
    }
  };

  const onClickCloseAction = () => {
    if (props.setModalClose) {
      props.setModalClose();
    }
  };

  const funcHandleDataSavePerSection = (results: GeocodeResult[], lat: number, lng: number) => {
    let locDataToSave: DeliveryAddressInput;
    locDataToSave = {
      country_code: '',
      email: '',
      label: '',
      address: '',
      city: '',
      country: '',
      state: '',
      street: '',
      zip_code: undefined,
      district: '',
      location_long: undefined,
      location_lat: undefined,
      is_featured: undefined,
    };
    if (saveLocationDetails?.address_id) {
      locDataToSave['address_id'] = saveLocationDetails?.address_id;
    }
    if (results && lat && lng) {
      const resultFirstElement = results[0];
      //latitude
      locDataToSave.location_lat = lat;
      //longitude
      locDataToSave.location_long = lng;
      //full address name
      locDataToSave.address = resultFirstElement.formatted_address;
      //address components
      if (resultFirstElement) {
        const addressResultComponents = results[0]['address_components'];
        //Others//
        //Country
        const contryNameList = addressResultComponents.filter(adr => adr.types.includes('country'));
        if (contryNameList.length > 0) {
          locDataToSave.country = contryNameList[0].long_name;
        } else {
          locDataToSave.country = 'na';
        }
        //State
        const stateNameList = addressResultComponents.filter(adr => adr.types.includes('administrative_area_level_1'));
        if (stateNameList.length > 0) {
          locDataToSave.state = stateNameList[0].long_name;
        } else {
          locDataToSave.state = 'na';
        }
        const districtNameList = addressResultComponents.filter(
          adr =>
            adr.types.includes('administrative_area_level_7') ||
            adr.types.includes('administrative_area_level_6') ||
            adr.types.includes('administrative_area_level_5') ||
            adr.types.includes('administrative_area_level_4') ||
            adr.types.includes('administrative_area_level_3') ||
            adr.types.includes('administrative_area_level_2') ||
            adr.types.includes('administrative_area_level_1'),
        );
        if (districtNameList.length > 0) {
          locDataToSave.district = districtNameList[0].long_name;
        } else {
          locDataToSave.district = 'na';
        }
        //City
        const cityNameList = addressResultComponents.filter(adr => adr.types.includes('locality'));
        if (cityNameList.length > 0) {
          locDataToSave.city = cityNameList[0].long_name;
        } else {
          locDataToSave.city = 'na';
        }
        //Zip Code
        const zipCodeList = addressResultComponents.filter(adr => adr.types.includes('postal_code'));
        if (zipCodeList.length > 0) {
          locDataToSave.zip_code = Number(zipCodeList[0].long_name);
        } else {
          locDataToSave.zip_code = -1;
        }
        //Short address//
        //Short address
        const shortAddressList = addressResultComponents.filter(adr => adr.types.includes('premise') || adr.types.includes('subpremise'));
        if (shortAddressList.length > 0) {
          locDataToSave.country_code = shortAddressList[0].long_name;
        } else {
          locDataToSave.country_code = 'na';
        }
        //Street
        if (addressResultComponents && addressResultComponents[0] && addressResultComponents[1]) {
          locDataToSave.street = `${addressResultComponents[0].long_name} ${addressResultComponents[1].long_name}`;
        } else {
          locDataToSave.street = saveLocationDetails?.street;
        }
      }
    }
    setSaveLocationDetails(locDataToSave);
  };

  const onClickSaveAction = () => {
    const addressModelToSave = Object.assign({}, saveLocationDetails);
    addressModelToSave.email = email;
    addressModelToSave.label = newAddressName;
    addressModelToSave.name = fullName;
    addressModelToSave.mobile = form.phone_code_iso.value.toString() + form.phoneNumber.value.toString();
    addressModelToSave.mobile_code = form.phone_code_iso.value;
    addressModelToSave.is_default = +isFeatured;
    setSaveLocationDetails(addressModelToSave);
    props.onClickSaveAddressAction(addressModelToSave);
  };
  const defaultMainContainerCss = `flex flex-col mt-[1.25rem] w-[39.688rem] justify-start items-center bg-primary border border-neutral-300-zinc-700
    rounded-lg p-[1.5rem] gap-[1.5rem] max-h-[100vh] overflow-y-auto`;
  return (
    <div
      id="SHOP_MAIN_ADD_ADDRESS"
      className={`${props.mainAddAddressClassStyles?.mainContainerClassName ?? defaultMainContainerCss} max-h-[95%] overflow-x-hidden`}
    >
      {/* header section*/}
      <header
        className={`flex w-[100%] h-[2rem] gap-[0.625rem] items-center justify-between ${props.mainAddAddressClassStyles?.headerClassname}`}
      >
        <p className="text-fs-subtitle font-medium text-secondary">
          {translate('shop.cart.shop_add_address_modal_page.new_address') ?? ''}
        </p>

        {!props.isForSettingsView && (
          <ResponsiveIcon
            className="flex fill-secondary z-[1] items-center justify-center hover:cursor-pointer"
            name={IconNames.close_xbutton}
            baseWidth={16}
            baseHeight={16}
            onClick={() => onClickCloseAction()}
          />
        )}
      </header>
      {/* list of address names section*/}
      <div className="flex flex-col w-[100%] gap-[0.938rem]">
        <p className="font-medium text-fs-body text-secondary">{translate('shop.cart.shop_add_address_modal_page.address_name') ?? ''}</p>
        <AddAddressList
          addressList={props.addressList}
          isAddNewAddress={props.isAddNewAddress}
          addressForUpdate={props.addressForUpdate}
          onClickExistingAddressButton={props.onClickExistingAddressButton}
          onClickAddAddressNameButton={props.onClickAddAddressNameButton}
        />

        {/* New Address name field with placeholder*/}
        {props.isAddNewAddress && (
          <div className="mt-0">
            <InputField
              label={translate('shop.cart.shop_add_address_modal_page.address_name') ?? ''}
              isSecret={false}
              hasFocused={false}
              errorMessage={isAddressNameTouched ? addressNameError : ''}
              isUserAllowedToType={(value: string) => true}
              retrieveValue={(value: any) => setNewAddressName(value)}
              retrieveFocus={(value: boolean) => {
                if (value && !isAddressNameTouched) {
                  setIsAddressNameTouched(true);
                }
              }}
              style={authInputFieldStyleObj}
              existingValue={newAddressName}
              maxLength={20}
            />
          </div>
        )}
      </div>

      <div className="w-full">
        {/* tab header list section.  Map view | Short address | Full address*/}
        <div>
          <header onMouseLeave={() => actionLeaveHover()} className="h-[2.75rem] gap-[1rem] sticky z-[1]">
            <div
              className={`flex items-start justify-start w-full SD:w-[36.688rem] h-[2.75rem] ${props.mainAddAddressClassStyles?.tabHeaderClassname}`}
            >
              <div className="flex gap-[1rem] overflow-x-scroll items-start justify-start">
                {tabHeaderListSectionData?.headerListSection
                  ?.sort((a, b) => a.id - b.id)
                  ?.map((tab, index) => {
                    if (tab.id === 3) {
                      return null; // Hide full address for now 04-24-2024
                    }
                    return (
                      <div
                        onMouseLeave={() => actionLeaveHover()}
                        className={`relative`}
                        key={index}
                        onMouseEnter={() => actionTabHover(tab)}
                        onClick={() => onTabClick(tab)}
                      >
                        <HoverText
                          className={`
                            whitespace-nowrap text-fs-body font-regular ${chosenTab === tab?.name ? 'text-onyx' : 'text-secondary/50'}
                          `}
                          hover={{ color: 'text-secondary' }}
                          alwaysShowUnderline={false}
                          underlineStyle=""
                          text={tab?.name}
                        />
                        <motion.div
                          initial={{ width: '0px' }}
                          animate={{
                            backgroundColor: hoveredTab === tab?.name ? ['#E95F2A', '#E95F2A', ColorAnimation] : ColorAnimation,
                            width: hoveredTab === tab?.name ? '100%' : '0%',
                          }}
                          transition={{ duration: 0.3 }}
                          className="relative bg-secondary h-[2px] w-full 4xl:h-[5px] 8K:h-[10px] duration-[0.3] mt-[18px]"
                        />

                        <div
                          className={`${
                            chosenTab === tab?.name
                              ? 'mt-[-2px] 2K:mt-[] 4K:mt-[] 8K:mt-[] relative bg-secondary h-[2px] 2K:h-[3.56px] 4K:h-[5.3px] 8K:h-[10.67px] w-full'
                              : 'hidden'
                          }`}
                        ></div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="border-b-[1px] z-[1] border-secondary/20 4xl:border-b-[3px] 8xl:border-b-[5.33px] " />
          </header>
        </div>

        {/* view details section for map view, short address or full address */}
        <div className="mt-[20px]">
          {/* import sections here */}
          <AddAddressViewSection
            chosenSection={addAddressViewSection}
            funcHandleDataSavePerSection={funcHandleDataSavePerSection}
            saveLocationDetails={saveLocationDetails}
            mainAddAddressClassStyles={props.mainAddAddressClassStyles}
          ></AddAddressViewSection>
        </div>
      </div>

      {/* tab header list section bottom border */}
      <div className="border-b-[1px] w-full 4xl:border-b-[3px] 8xl:border-b-[5.33px] border-secondary/20" />

      {/* bottom section details section */}
      <div className="flex flex-col w-[100%] h-[18.938rem]">
        <p className="font-medium text-fs-body text-secondary">{translate('shop.cart.shop_add_address_modal_page.personal_details')}</p>
        <div className="flex flex-col gap-[1rem] mt-[1.5rem]">
          {/* full name field with placeholder*/}
          <div className="mt-0">
            <InputField
              label={translate('shop.cart.shop_add_address_modal_page.placeholder_full_name') ?? ''}
              isSecret={false}
              hasFocused={false}
              errorMessage={isFullNameTouched ? fullNameError : ''}
              isUserAllowedToType={(value: string) => true}
              retrieveValue={(value: any) => setFullName(value)}
              retrieveFocus={(value: boolean) => {
                if (value && !isFullNameTouched) {
                  setIsFullNameTouched(true);
                }
              }}
              style={authInputFieldStyleObj}
              existingValue={fullName}
            />
          </div>

          {/* mobile number field with placeholder */}
          {/* Further configure component to handle dynamic data / placeholder and sizing*/}
          <div className="mt-0 SD:w-[23.938rem]">
            <PhoneNumberField
              label={translate('settings.phone_number') ?? ''}
              apiErrorMessage={isPhoneNumberTouched ? phoneNumberError : ''}
              onCountryCode={e => {
                if (e?.country_code_iso_2) {
                  editForm('country_code_iso', { value: e.country_code_iso_2, isValid: true });
                  setPhoneNumberIsoCode(e.country_code_iso_2);
                }
                if (e?.phone_code_iso) {
                  editForm('phone_code_iso', { value: e.phone_code_iso, isValid: true });
                  setPhoneNumberIsoCode(e.phone_code_iso);
                }
              }}
              retrieveFocus={(value: boolean) => {
                if (value && !isPhoneNumberTouched) {
                  setIsPhoneNumberTouched(true);
                }
              }}
              onPhoneNumberChange={editForm}
              customPlaceholder={`${translate('shop.cart.shop_add_address_modal_page.placeholder_mobile_number')}`}
              existingPhoneObj={{ dropDownValueCode: phoneNumberIsoCode, inputValue: phoneNumberInput }}
            />
          </div>

          {/* email field with placeholder*/}
          <div className="mt-0">
            <InputField
              label={translate('shop.cart.shop_add_address_modal_page.placeholder_email') ?? ''}
              isSecret={false}
              hasFocused={false}
              errorMessage={isEmailTouched ? emailError : ''}
              isUserAllowedToType={(value: string) => true}
              retrieveValue={(value: any) => setEmail(value)}
              retrieveFocus={(value: boolean) => {
                if (value && !isEmailTouched) {
                  setIsEmailTouched(true);
                }
              }}
              style={authInputFieldStyleObj}
              existingValue={email}
            />
          </div>
        </div>
      </div>
      {props.isForSettingsView && (
        <div className="flex flex-col gap-[0.3125rem] w-[100%]">
          <div className="flex gap-x-[0.75rem] items-center">
            <div className="flex w-[1.5rem] h-[1.5rem] items-center justify-center">
              <Checkbox
                isChecked={Boolean(isFeatured)}
                onClick={() => setIsFeatured(!isFeatured)}
                classNames="w-[1.125rem] h-[1.125rem] items-center justify-center"
                width={1.125}
                height={1.125}
              />
            </div>
            <p className="text-fs-body-small font-medium text-secondary-100">{translate('config.savedAddresses.use_as_default_address')}</p>
          </div>
          <p className="text-fs-body-small w-[18.6875rem] pl-[2.25rem] text-secondary opacity-[0.70]">
            {translate('config.savedAddresses.address_will_be_used_as_default')}
          </p>
        </div>
      )}

      {/* buttons section */}
      <div className="flex flex-col justify-center items-center gap-[1rem] mt-[1.5rem] w-full SD:flex-row">
        <Button
          text={translate(`action.cancel`) ?? ''}
          action={() => onClickCloseAction()}
          style={`text-center text-base not-italic text-fs-body
            max-h-[2.5rem] border-[0.063rem] border-sunset
            w-full
            gap-[0.625rem] z-1
            text-white100 text-sunsetText mb-[0px]
            xsMax:mb-[0px] mdMaxxSS:w-full items-end
            rounded-sm font-medium text-sunset 
            ${isMobile ? 'w-full px-[1rem] py-[0.5rem]' : 'w-full px-auto py-[0.5rem]'}
            ${
              isHeaderDark
                ? dir === 'ltr'
                  ? 'after:bg-white100 before:bg-sunset'
                  : 'before:bg-white100 after:bg-sunset'
                : `${dir === 'ltr' ? 'after:bg-white100 before:bg-sunset' : 'before:bg-white100 after:bg-sunset'}
                `
            }
            hover:text-white100 rounded-sm`}
          disabled={false}
        />
        <Button
          text={translate('action.save') ?? ''}
          action={() => onClickSaveAction()}
          style={`text-base not-italic text-fs-body 
            max-h-[2.5rem] ${isMobile ? 'w-full px-[1rem] py-[0.5rem]' : 'w-full px-auto py-[0.5rem]'} 
            w-full gap-10px] z-1 
            text-white100 border-[0.063rem] border-transparent mb-[0px] 
            xsMax:mb-[0px] mdMaxxSS:w-full items-end
            rounded-sm font-medium text-white100 ${
              isValidForm
                ? isHeaderDark
                  ? dir === 'ltr'
                    ? 'after:bg-sunset before:bg-white100'
                    : 'before:bg-sunset after:bg-white100'
                  : `${dir === 'ltr' && isValidForm ? 'after:bg-sunset before:bg-white100' : 'before:bg-sunset after:bg-white100'}`
                : 'disabled:bg-secondary/[0.1]'
            }
            ${isValidForm && 'hover:border-[0.063rem] hover:border-sunset hover:text-sunsetText'}
            rounded-sm`}
          disabled={!isValidForm}
        />
      </div>
    </div>
  );
};

export default ShopMainAddAddress;
