import { IconNames } from '@fe-monorepo/helper';
import { useTranslate } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import Icon from '@fe-web/Atoms/Icon/Icon';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import useOutsideClick from '@fe-web/hooks/useOutsideClick';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'react-tiny-popover';

interface BitActionsProps {
  repost: boolean;
  onDelete: () => void;
}

const BitActions = ({ repost, onDelete }: BitActionsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { translate } = useTranslate();
  const isRTL = useSelector((state: RootState) => state.app.isRTL);

  const popOverRef = useOutsideClick(() => {
    setIsOpen(false);
  });

  return (
    <Popover
      isOpen={isOpen}
      ref={newRef => (popOverRef.current = newRef)}
      positions={['bottom', 'left']}
      padding={10}
      reposition={false}
      onClickOutside={() => setIsOpen(false)}
      containerClassName="bg-primary border-[0.0625rem] border-secondary/[0.2] rounded-[0.25rem]"
      containerStyle={{
        top: '0px',
        left: isRTL ? '3.5rem' : '-3.5rem',
      }}
      content={() => (
        <div className="py-[0.5rem] inline-flex flex-col gap-[0.25rem]">
          <div
            id="delete-bit"
            className="
              flex gap-[0.25rem]
              items-center
              text-secondary
              py-[0.5rem] px-[1rem]
              cursor-pointer hover:text-sunset hover:bg-sunset/10
              transition-all"
            onClick={e => {
              e.stopPropagation();
              onDelete();
            }}
          >
            <Icon name={IconNames.deleteOutline} iconClasses="fill-red" width={18} height={18} />
            <p className="font-regular text-fs-body text-red">
              {repost ? translate('quickplay.btn-undo-repost') : translate('quickplay.btn-delete-bits')}
            </p>
          </div>
        </div>
      )}
    >
      <div
        className="flex flex-row gap-[0.25rem] max-w-full"
        onClick={e => {
          e.stopPropagation();
          setIsOpen(prevOpen => !prevOpen);
        }}
      >
        <ResponsiveIcon
          name={IconNames.optionsRegular}
          baseHeight={24}
          baseWidth={24}
          iconClasses="hover:fill-zinc-400 dark:hover:fill-zinc-200 fill-zinc-600-400 rotate-90"
        />
      </div>
    </Popover>
  );
};
export default BitActions;
