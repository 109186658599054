import { useLocation } from 'react-router-dom';

import VideoPlayer from './Player';

const PlayerWrapper = () => {
  const location = useLocation();
  const { mediaUrl, contentTitle, isFullScreen } = location.state;

  return <VideoPlayer mediaUrl={mediaUrl} contentTitle={contentTitle} isFullScreen={isFullScreen} />;
};

export default PlayerWrapper;
