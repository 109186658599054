import { useTranslate } from '@fe-monorepo/hooks';
import { MatchmakingPlatformResponse } from '@fe-monorepo/models';
import usePageLayout from '@fe-web/hooks/usePageLayout';
import { useState } from 'react';

interface PlatformProps {
  gamePlatform?: string;
  setGamePlatform: (val: string) => void;
  gameCode?: string;
  existingPlatforms?: string;
  platformData?: MatchmakingPlatformResponse[];
}
// game platform radio buttons
const Platform = (props: PlatformProps) => {
  const { translate } = useTranslate();
  const [localPlatformData, setLocalPlatformData] = useState<string>('');
  const { language } = usePageLayout();

  // highlights the selected platform button
  let platformToHighlight = '';
  if (props.gamePlatform) {
    platformToHighlight = props.gamePlatform;
  } else {
    platformToHighlight = props.existingPlatforms ?? '';
  }

  return (
    <div className="flex flex-col gap-[1rem]">
      <p className="text-secondary responsive-text-bodyLarge font-medium">
        {translate('create_tournament_page.tournament_platform') ?? ''}
      </p>

      <div className="grid grid-cols-2 SD:grid-cols-3 gap-[1rem]">
        {props.platformData?.map((platform, index) => {
          return (
            <button
              key={index}
              className={`cursor-pointer w-full h-[4.25rem] ${
                props.platformData?.length && props.platformData?.length % 2 !== 0 ? 'last:col-span-2 last:SD:col-span-1' : 'col-span-1'
              }
                            responsive-text-bodySmall font-normal text-secondary
                            border-[0.063rem] rounded whitespace-nowrap
                            ${
                              platformToHighlight === platform.platform_code
                                ? `border-sunset bg-sunset/10`
                                : `border-secondary/20 bg-zinc-100-neutral-800`
                            }`}
              onClick={e => {
                setLocalPlatformData(platform.platform_code);
                props?.setGamePlatform(platform.platform_code);
              }}
            >
              <label className="responsive-text-bodySmall font-normal text-secondary cursor-pointer">
                {platform[`platform_name_${language}`]}
              </label>
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default Platform;
