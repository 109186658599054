import { IconNames } from '@fe-monorepo/helper';
import { UserListItemModel } from '@fe-monorepo/models';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import Image from '@fe-web/Atoms/Media/Image';

interface UserListItemProps extends UserListItemModel {
  onSelectItem?: () => void;
  searchValue?: string;
}

const UserListItem = ({ avatar, username, displayName, onSelectItem, searchValue }: UserListItemProps) => {
  const searchCheck = searchValue && searchValue?.length > 2;

  const highlightSearchTerm = (name: string, searchTerm: string) => {
    const parts = name.split(new RegExp(`(${searchTerm})`, 'gi'));
    return (
      <span>
        {parts.map(part => (part.toLowerCase() === searchTerm.toLowerCase() ? <span className="text-secondary">{part}</span> : part))}
      </span>
    );
  };

  return (
    <div
      className="flex items-center gap-[0.5rem]"
      onClick={() => {
        onSelectItem && onSelectItem();
      }}
    >
      <Image
        img={avatar}
        divStyle="h-[2rem] w-[2rem] bg-black/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden"
        imgStyle="h-[2rem] w-[2rem]"
        fallback={
          <ResponsiveIcon
            name={IconNames.avatar}
            baseHeight={32}
            baseWidth={32}
            className="h-[2rem] w-[2rem] bg-black/[0.2] border border-gray-200-zinc-700 rounded-full overflow-hidden"
            iconClasses="h-full w-full p-[0.5rem] fill-gray"
          />
        }
      />
      <div className="flex flex-col items-start flex-1 overflow-hidden text-secondary/50 font-regular text-fs-body-small">
        {displayName && (
          <span className="overflow-hidden truncate whitespace-nowrap">
            {searchCheck ? highlightSearchTerm(displayName as string, searchValue) : displayName}
          </span>
        )}
        {username && (
          <span className="overflow-hidden truncate whitespace-nowrap">
            @{searchCheck ? highlightSearchTerm(username as string, searchValue) : username}
          </span>
        )}
      </div>
    </div>
  );
};

export default UserListItem;
