import { RootState } from "@fe-monorepo/store";
import { useMemo } from "react";
import { useSelector } from "react-redux";

export const useBitsConfig = () => {
    const bitsConfig = useSelector(
        (state: RootState) => state.app.bitsConfig,
    );

    return useMemo(() => {
        if (!bitsConfig) return {};
        const {
            createVisible,
            enabled,
            favouritesVisible,
            forYouVisible,
            helpVisible,
            profileVisible,
            screenVisible,
            searchVisible
        } = bitsConfig;

        return {
            bitsEnabled: enabled,
            showBitsForYouSection: enabled && forYouVisible,
            showBitsHelpSection: enabled && helpVisible,
            showBitsProfileSection: enabled && profileVisible,
            showBitsFavoritesSection: enabled && favouritesVisible,
            showBitsCreator: enabled && createVisible,
            showBitsSearchSection: enabled && searchVisible,
            showBitsScreen: enabled && screenVisible,
        };
    }, [bitsConfig]); 
};
