export type ShopModel = {
    id: string,
    type: string,
    title: string,
    combination_id: number,
    img: string,
    breadcrumbs: [string],
    grand_total?: number,
    total_price?: number,
    product_rating?: number,
    lowest_selling_price?: number,
    total_raters?: number,
    pagination?: string,
    title_en?: string,
    title_ar?: string,
    breadcrumbs_en?: [string],
    breadcrumbs_ar?: [string],
    brand_name_en?: string,
    brand_name_ar?: string,
    brand_code?: string,
    collection_code?: string,
}

export enum CONTINUE_AS_GUEST_PHASES {
    EMAIL = "EMAIL_INPUT",
    OTP = "OTP_INPUT"
}
