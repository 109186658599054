import { useLazyQuery } from '@apollo/client';
import { MINI_GAMES_QUERY } from '@fe-monorepo/data-access';
import {
  TmsGetAllMiniGamesInput,
  TmsGetAllMiniGamesResponse,
  TmsGetMiniGameHighlightedResponse,
  TmsGetMiniGameInfoInput,
  TmsGetMiniGameInfoResponse,
  TmsGetMiniGameLeaderboardInput,
  TmsGetMiniGameLeaderboardResponse,
} from '@fe-monorepo/models';
import { useState } from 'react';

export const useMiniGames = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [getAllMiniGamesGql, { data: getAllMiniGamesData }] = useLazyQuery<TmsGetAllMiniGamesResponse>(
    MINI_GAMES_QUERY.TmsGetAllMiniGames,
    { errorPolicy: 'all' },
  );

  const [getMiniGameInfoGql, { data: getMiniGameInfoData }] = useLazyQuery<TmsGetMiniGameInfoResponse>(
    MINI_GAMES_QUERY.TmsGetMiniGameInfo,
    { errorPolicy: 'all' },
  );

  const [getMiniGameHighlightedGql, { data: getMiniGameHighlightedData }] = useLazyQuery<TmsGetMiniGameHighlightedResponse>(
    MINI_GAMES_QUERY.TmsGetMiniGameHighlighted,
    { errorPolicy: 'all' },
  );

  const [getMiniGameLeaderboardGql, { data: getMiniGameLeaderboardData }] = useLazyQuery<TmsGetMiniGameLeaderboardResponse>(
    MINI_GAMES_QUERY.TmsGetMiniGameLeaderboard,
    { errorPolicy: 'all' },
  );

  const getAllMiniGames = async (details?: TmsGetAllMiniGamesInput) => {
    setIsLoading(true);
    const { data } = await getAllMiniGamesGql({ variables: { details } });
    setIsLoading(false);
    return data;
  };

  const getMiniGameInfo = async (details: TmsGetMiniGameInfoInput) => {
    setIsLoading(true);
    const { data } = await getMiniGameInfoGql({ variables: { details } });
    setIsLoading(false);
    return data;
  };

  const getMiniGameHighlighted = async () => {
    setIsLoading(true);
    const { data } = await getMiniGameHighlightedGql();
    setIsLoading(false);
    return data;
  };

  const getMiniGameLeaderboard = async (details: TmsGetMiniGameLeaderboardInput) => {
    setIsLoading(true);
    const { data } = await getMiniGameLeaderboardGql({ variables: { details } });
    setIsLoading(false);
    return data;
  };

  return {
    isLoading,
    miniGamesData: getAllMiniGamesData?.TmsGetAllMiniGames,
    miniGameData: getMiniGameInfoData?.TmsGetMiniGameInfo,
    highlightedMiniGameData: getMiniGameHighlightedData?.TmsGetMiniGameHighlighted,
    miniGameLeaderboardData: getMiniGameLeaderboardData?.TmsGetMiniGameLeaderboard,
    getAllMiniGames,
    getMiniGameInfo,
    getMiniGameHighlighted,
    getMiniGameLeaderboard,
  };
};
