import { InvoiceModel } from './InvoiceInfoModel';
import { BrandData } from './ProductDetailedModel';

enum StatusType {
  order_placed = 'order_placed',
  pending = 'pending',
  received = 'received',
  complete = 'complete',
  delivered = 'delivered',
  partially_delivered = 'partially_delivered',
  requested = 'requested',
  rejected_partial = 'rejected_by_stcplay_partial',
  rejected_by_stcplay = 'rejected_by_stcplay',
  rejected_by_channels = 'rejected_by_channels',
  rejected = 'rejected',
  rejected_by_dal = 'rejected_by_dal',
  submitted = 'submitted',
  action_required = 'action_required',
  refunded = 'refunded',
  refund_accepted = 'refund_accepted',
  accepted = 'accepted',
  passed_screening = 'passed_screening',
  return_accepted = 'return_accepted',
  under_process = 'under_process',
  undergoing_inspection = 'undergoing_inspection',
  in_transit = 'in_transit',
  closed = 'closed',
  shipment_created = 'shipment_created',
  pending_clarification = 'pending_clarification',
  failed_screening = 'failed_screening',
  return_rejected = 'return_rejected',
  return_dispatched = 'return_dispatched',
  order_dispatched = 'order_dispatched',
  // COMPLETE = 'complete',
  // CANCELLED = 'cancelled',
  // ON_HOLD = 'on_hold',
  // REFUNDED = 'refunded',
  // ODER_DISPATCHED = 'order_dispatched',
  // ORDER_RECEIVED = 'order_received',
  // ORDER_PREPARED = 'order_prepared',
  // ORDER_INVOICE = 'order_invoiced',
  // SHIPMENT_DELIVERED = 'shipment_delivered',
  // RETURN_ACCEPTED = 'return_accepted',
  // RETURN_REJECTED = 'return_rejected',
  // RETURN_DISPATCHED = 'return_dispatched',
  // PRODUCT_RETURNED = 'product_returned',
  // DELIVERY_FAILED = 'delivery_failed',
  // SUBMITTED = 'submitted',
}

enum ReturnStatusType {
  requested = 'Requested',
  rejected1 = 'Rejected (Partial)',
  rejected2 = 'Rejected',
  action_required = 'Action required',
  approved = 'Approved',
  courier_pickup = 'Courier pickup',
  refunded = 'Refunded',
  under_process = 'Under Process',
}

enum StatusTypeDisplay {
  order_placed = 'order_placed',
  pending = 'order_placed',
  received = 'on_its_way',
  partially_delivered = 'on_its_way_partial_delivered',
  delivered = 'delivered',
  complete = 'delivered',
  Requested = 'requested',
  // delivered = 'delivered',
  // cancelled = 'delivered',
  // on_hold = 'on_its_way',
  // refunded = 'refunded',
  // order_dispatched = 'on_its_way',
  // order_received = 'order_placed',
  // order_prepared = 'order_placed',
  // order_invoiced = 'on_its_way_partial_delivered',
  // shipment_delivered = 'delivered',
  // return_accepted = 'return_placed',
  // return_rejected = 'ineligible_return',
  // return_dispatched = 'return_placed',
  // product_return = 'returned',
  // delivery_failed = 'on_its_way',
  // submitted = 'order_placed',
}

interface ResponseModel {
  is_successful: boolean;
  error_code: string;
  error_msg: string;
}

interface ResponseModelWithAppCode {
  is_successful: boolean;
  error_code: string;
  error_msg: string;
  app_code: string;
}

interface OrderModel {
  invoice_number: string;
  status_code: StatusType;
  order_id: number;
  amount_grand_total: number;
  total_amount: number;
  created_at?: string;
  updated_at: string;
  last_cursor: number;
  product: string[];
}

export interface ReturnOrderModel {
  invoice_number: string;
  status_code: ReturnStatusType;
  order_id: number;
  amount_grand_total: number;
  created_at?: string;
  updated_at: string;
  last_cursor: number;
  product: string[];
}
interface DummyProduct {
  product_name: string;
  qty: number;
  id: number;
  unit_price: number;
  media: string;
  grand_total: number;
  medias: string[];
  status: string;
}

interface DummyOrderFilteredModel {
  invoice_number: string;
  status_code: ReturnStatusType;
  total_amount: number;
  amount_grand_total: number;
  orders_date: string;
  createdAt_date: string;
  updatedAt_date: string;
  created_at?: string;
  updated_at: string;
  last_cursor: number;
  product: DummyProduct[];
}
interface OrderFilteredModel {
  invoice_number: string;
  status_code: StatusType;
  total_amount?: number;
  amount_grand_total: number;
  created_at?: string;
  updated_at: string;
  last_cursor: number;
  product: string[];
}

interface Item {
  name_en: string;
  name_ar: string;
  return_product_id: number;
  tracking_number: string;
  created_at: string;
  updated_at: string;
  sku: string;
  amount: number;
  status_code: string;
  status_ar: string;
  status_en: string;
  return_reason_code: string;
  reject_reason_ar: string | null;
  reject_reason_en: string | null;
  product_media: string;
  return_media: string[];
}
interface OrderReturnModel {
  return_number: string;
  refunded_amount: string;
  total_amount: string;
  last_cursor: number;
  status_code: string;
  created_at: string;
  updated_at: string;
  media: string[];
  items: Item[];
}

type ReturnProductModel = {
  return_product_id: string;
  sku: string;
  amount: string;
  status_code: string;
  status_ar: string;
  status_en: string;
  return_reason_code: string;
  reject_reason_ar: string;
  reject_reason_en: string;
  product_media: string;
  return_media: string[];
  name_en: string;
  name_ar: string;
};

type ReturnNumModel = {
  return_number: string;
  status_code: string;
  created_at: string;
  updated_at: string;
  refunded_amount: string;
  total_amount: string;
  products: ReturnProductModel[];
};

export interface ReturnOrderFilteredModel {
  invoice_number: string;
  status_code: ReturnStatusType;
  amount_grand_total: number;
  created_at?: string;
  updated_at: string;
  last_cursor: number;
  product: string[];
}

interface CheckoutModel {
  invoice: InvoiceModel;
  itemReservations: ItemReservationModel[];
}

interface ItemReservationModel {
  product: string;
  reserved_at: string;
  reservation_status: string;
}

interface GetAllOrdersResponse extends ResponseModel {
  data: OrderModel[];
}

interface CheckoutResponse extends ResponseModel {
  data: CheckoutModel;
}

interface OrderQueryResponse {
  getAllOrders: GetAllOrdersResponse;
  getFilteredOrders: OrderFilteredQueryResponse;
  getAllWishlist: GetAllWishlistResponse;
}

interface OrderFilteredQueryResponse extends ResponseModel {
  data: { count: number; orders: OrderFilteredModel[] };
}
interface WishlistData {
  product_code: string;
  description_ar: string;
  description_en: string;
  featured_img: string;
  name_ar: string;
  name_en: string;
  metadata: string;
  lowest_actual_cost: number;
  highest_actual_cost: number;
  highest_selling_price: number;
  lowest_selling_price: number;
  brand: BrandData;
  last_cursor: string;
}

interface GetAllWishlistResponse {
  is_successful: boolean;
  error_code: string;
  error_msg: string;
  data: WishlistData[];
}

interface AddProductResponse extends ResponseModel { }

interface RemoveProductResponse extends ResponseModel { }

interface SelectProductsResponse extends ResponseModel { }

interface AddWishlistResponse extends ResponseModel { }

interface RemoveWishlistResponse extends ResponseModel { }

interface SelectProductsInput {
  invoice_number: string;
  order_product_ids: number[];
}

interface ActionProductInput {
  invoice_number: string;
  variant_id: number;
  product_code: string;
  qty: number;
}

interface CheckoutInput {
  invoice_number: string;
}
interface OrderFilteredInput {
  details: {
    last_cursor?: number;
    direction?: string;
    invoice_number?: string;
    date_from?: string;
    date_to?: string;
    price_from?: number;
    price_to?: number;
    status_code?: string;
    sort_type?: string;
    sort_value?: string;
  };
}

interface AddProductInput extends ActionProductInput { }

interface RemoveProductInput extends ActionProductInput { }

interface OrderMutationResponse {
  addProduct?: AddProductResponse;
  removeProduct?: RemoveProductResponse;
  selectProducts?: SelectProductsResponse;
  checkout?: CheckoutResponse;
}

interface WishlistMutationResponse {
  shopWishlistAdd?: AddWishlistResponse;
  shopWishlistRemove?: RemoveWishlistResponse;
}

interface ResendCodeInput {
  invoice_number: string;
}

interface ResendCodeResponse extends ResponseModel { }

interface ShopWishlistInput {
  product_code: string;
}

interface GetAllWishlistInput {
  details: {
    last_cursor: string;
    direction: string;
  };
}

enum ReturnItemStatus {
  pending_clarification = 'returns_action_required',
  action_required = 'returns_action_required',
  accepted = 'returns_action_approved',
  passed_screening = 'returns_action_approved',
  return_accepted = 'returns_action_approved',
  refunded = 'returns_action_refunded',
  refund_accepted = 'returns_action_refunded',
  rejected = 'returns_action_rejected',
  rejected_by_stcplay = 'returns_action_rejected',
  rejected_by_channels = 'returns_action_rejected',
  rejected_by_dal = 'returns_action_rejected',
  failed_screening = 'returns_action_rejected',
  return_rejected = 'returns_action_rejected',
  closed = 'returns_action_rejected',
  in_transit = 'returns_action_in_transit',
  return_dispatched = 'returns_action_in_transit',
  submitted = 'returns_action_in_submitted',
  shipment_created = 'returns_action_in_submitted',
  undergoing_inspection = 'returns_action_under_process',
  pending = 'returns_action_in_pending',
}

export {
  AddProductInput,
  CheckoutInput,
  DummyOrderFilteredModel,
  GetAllWishlistInput,
  OrderFilteredInput,
  OrderFilteredModel,
  OrderModel,
  OrderMutationResponse,
  OrderQueryResponse,
  OrderReturnModel,
  RemoveProductInput,
  ResendCodeInput,
  ResendCodeResponse,
  ResponseModel,
  ResponseModelWithAppCode,
  ReturnItemStatus,
  ReturnNumModel,
  ReturnStatusType,
  SelectProductsInput,
  ShopWishlistInput,
  StatusType,
  StatusTypeDisplay,
  WishlistData,
  WishlistMutationResponse
};

