import { useTranslate } from '@fe-monorepo/hooks';
import { TmsGetMiniGameHighlightedDetails } from '@fe-monorepo/models';
import { RootState } from '@fe-monorepo/store';
import ButtonFooter from '@fe-web/Atoms/Buttons/ButtonFooter';
import Spinner from '@fe-web/Atoms/Load/Spinner';
import useMobileDetect from '@fe-web/hooks/useMobileDetect';
import { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { AppRoutes } from '../../../../../app.routes.enum';
import SectionTitle from '../../components/SectionTitle';

interface Props {
  data: TmsGetMiniGameHighlightedDetails | undefined;
  isLoading?: boolean;
}

const HighlightedMiniGame = ({ data, isLoading }: Props) => {
  const isRTL = useSelector((state: RootState) => state?.app.isRTL);
  const isMobile = useMobileDetect();
  const { translate } = useTranslate();
  const selectedLanguage = useSelector((state: RootState) => state.app.language);
  const navigate = useNavigate();
  const [gameData, setGameData] = useState<TmsGetMiniGameHighlightedDetails | undefined>();

  useEffect(() => {
    setGameData(data);
  }, [data]);

  return (
    <div className="inline-flex SD:flex-row flex-col gap-[1.5rem] SD:justify-between">
      <div className="w-full inline-flex flex-col justify-center gap-[1rem]">
        <SectionTitle>{translate('mini_game.landing_header')}</SectionTitle>
        {!isMobile && <p className="font-regular text-fs-subtitle text-zinc-400">{translate('mini_game.play_trending_game')}</p>}
      </div>
      {!isLoading ? (
        <div className="inline-flex SD:flex-row flex-col SD:gap-[1.5rem] SD:h-[19rem] SD:bg-[unset] bg-zinc-100-neutral-800 rounded-[0.5rem]">
          <LazyLoadImage
            src={gameData?.img}
            alt={gameData?.name_en?.replace(/\s/g, '_')}
            className="SD:max-w-[11.25rem] SD:h-full h-[14rem] SD:rounded-[0.5rem] rounded-t-[0.5rem] object-cover"
          />
          <div className="SD:w-[36.75rem] gap-[1.5rem] SD:gap-0 p-[1.5rem] md:bg-zinc-100-neutral-800 rounded-[0.5rem] inline-flex flex-col justify-between">
            <div className={`inline-flex flex-col ${!isMobile ? 'gap-[1.5rem]' : 'gap-[0.75rem]'}`}>
              <div className={`inline-flex flex-row`}>
                <p className={`font-medium ${!isMobile ? 'text-fs-title' : 'text-fs-body-large'} text-secondary`}>
                  {selectedLanguage === 'en' ? gameData?.name_en : gameData?.name_ar}
                </p>
              </div>
              <div className="self-baseline bg-sunset100 py-[0.125rem] px-[0.5rem] text-white font-regular text-fs-body-small rounded-[0.125rem]">
                {translate('mini_game.trending_tag')}
              </div>
              <div className="inline-flex flex-col gap-[0.5rem]">
                <p className="font-regular text-fs-body-small text-zinc-600-400">
                  {selectedLanguage === 'en' ? gameData?.description_en : gameData?.description_ar}
                </p>
              </div>
            </div>
            {gameData?.mini_game_code && (
              <ButtonFooter
                className={`text-fs-body text-primary font-medium rounded-sm px-[1.5rem] py-[0.5rem] ${
                  !isRTL ? `after:bg-secondary before:bg-sunset` : `before:bg-secondary after:bg-sunset`
                } hover:text-white`}
                text={translate('mini_game.play_now')}
                onClick={() => navigate(`${AppRoutes.games}/${gameData?.mini_game_code}`)}
                iconColor="fill-primary"
                iconColorHover="fill-white"
              />
            )}
          </div>
        </div>
      ) : (
        <span>
          <Spinner className="text-secondary" />
        </span>
      )}
    </div>
  );
};

export default HighlightedMiniGame;
