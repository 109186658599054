import { IconNames } from '@fe-monorepo/helper';
import { useTranslate, useUserProfile } from '@fe-monorepo/hooks';
import { RootState } from '@fe-monorepo/store';
import ResponsiveIcon from '@fe-web/Atoms/Icon/ResponsiveIcon';
import useTheme from '@fe-web/hooks/useTheme';
import { ReactNode } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { AppRoutes } from '../../app.routes.enum';

interface Props {
  hasLabels?: boolean;
  hasDisplayName?: boolean;
  displayName?: string;
  hasUserId?: boolean;
  userId?: string;
  hasVerified?: boolean;
  avatarUrl: string;
  avatarSize: number;
  avatarStyle?: string;
  avatarCTA?: () => void;
  verifiedSize?: number;
  containerStyle?: string;
  labelContainerStyle?: string;
  displayNameStyle?: string;
  userIdStyle?: string;
  dir: 'row' | 'col';
  hasGoToProfile?: boolean;
  isAdmin?: boolean;
  isGroupAvatar?: boolean;
  customCTA?: ReactNode;
  openUploadGroupAvatar?: () => void;
  isAdminLabelBottom?: boolean;
  altLabel?: string;
}

const PlayerAvatar: React.FC<Props> = ({
  hasLabels = true,
  hasDisplayName = true,
  displayName,
  hasUserId = true,
  userId,
  hasVerified = false,
  avatarUrl,
  avatarSize,
  avatarStyle,
  avatarCTA,
  verifiedSize = 20,
  containerStyle,
  labelContainerStyle,
  displayNameStyle,
  userIdStyle,
  dir,
  hasGoToProfile = false,
  isAdmin = false,
  isGroupAvatar = false,
  customCTA,
  openUploadGroupAvatar,
  isAdminLabelBottom = false,
  altLabel = '',
}) => {
  const { isDarkTheme } = useTheme();
  const { user } = useUserProfile();
  const isRTL = useSelector((state: RootState) => state?.app.isRTL);
  const { translate } = useTranslate();
  const location = useLocation();

  const stcSVGSize = avatarSize * 16 * 0.75;

  return (
    <div className={`${containerStyle} relative inline-flex ${dir == 'col' && 'flex-col'} items-center`}>
      {avatarUrl ? (
        <>
          <img
            onClick={() => avatarCTA?.()}
            alt={altLabel}
            className={`${avatarStyle} ${
              avatarCTA ? 'cursor-pointer' : ''
            } w-[${avatarSize}rem] h-[${avatarSize}rem] rounded-full border-[0.0625rem] border-neutral-300-zinc-700`}
            src={avatarUrl}
          />
          {/* GROUP CHAT AVATAR PENCIL ICON */}
          {isAdmin && isGroupAvatar && (
            <div
              onClick={openUploadGroupAvatar}
              className="cursor-pointer inline-flex justify-center items-center absolute right-0 bottom-0 
                h-[2rem] w-[2rem] p-[0.375rem] bg-sunset 
                rounded-[2.3125rem] border-[0.0625rem] border-gray-200-zinc-700"
            >
              <ResponsiveIcon name={IconNames.editOutline2} iconClasses="fill-white" baseHeight={20} baseWidth={20} />
            </div>
          )}
        </>
      ) : (
        <>
          <div
            onClick={() => avatarCTA?.()}
            className={`${avatarStyle} ${avatarCTA ? 'cursor-pointer' : ''} inline-flex justify-center items-center
              bg-secondary ${
                isDarkTheme && !location.pathname.includes('shop') && !location.pathname.includes('payment')
                  ? 'bg-opacity-50'
                  : 'bg-opacity-20'
              } rounded-full
              border-[0.0625rem] border-neutral-300-zinc-700 w-[${avatarSize}rem] h-[${avatarSize}rem]`}
          >
            <ResponsiveIcon
              className={`${isGroupAvatar ? 'fill-none' : 'fill-black'}`}
              name={isGroupAvatar ? IconNames.group_icon : IconNames.stc}
              baseHeight={isGroupAvatar ? 68 : stcSVGSize}
              baseWidth={isGroupAvatar ? 68 : stcSVGSize}
            />
          </div>
          {/* GROUP CHAT AVATAR PENCIL ICON */}
          {isAdmin && isGroupAvatar && (
            <div
              onClick={openUploadGroupAvatar}
              className="cursor-pointer inline-flex justify-center items-center absolute right-0 bottom-0 
                h-[2rem] w-[2rem] p-[0.375rem] bg-sunset 
                rounded-[2.3125rem] border-[0.0625rem] border-gray-200-zinc-700"
            >
              <ResponsiveIcon name={IconNames.editOutline2} iconClasses="fill-white" baseHeight={20} baseWidth={20} />
            </div>
          )}
        </>
      )}
      {hasLabels && (displayName || userId) && (
        <div>
          <div className={`${labelContainerStyle} justify-center items-center inline-flex`}>
            {hasDisplayName && displayName && (
              <div
                dir="ltr"
                className={`${user?.is_official_account === 1 && (!isRTL ? 'mr-[0.5rem]' : 'ml-[0.5rem]')} ${displayNameStyle}`}
              >
                {displayName}
              </div>
            )}
            {hasVerified && user?.is_official_account === 1 && (
              <ResponsiveIcon className="fill-secondary" name={IconNames.verified3} baseHeight={verifiedSize} baseWidth={verifiedSize} />
            )}
          </div>
          {hasUserId && userId && <div className={`${userIdStyle}`}>{userId}</div>}
          {isAdmin && isAdminLabelBottom && <div className="font-regular text-fs-caption text-moonlight">{translate('connect.admin')}</div>}
        </div>
      )}
      {hasGoToProfile && (
        <div className="ltr:ml-auto rtl:mr-auto">
          <Link to={AppRoutes.users + `/${user?.username}`}>
            <ResponsiveIcon
              className="fill-secondary cursor-pointer ltr:rotate-[270deg] rtl:rotate-90 animate__animated animate__fadeIn"
              name={IconNames.chevron}
              baseHeight={16}
              baseWidth={16}
            />
          </Link>
        </div>
      )}
      {customCTA && (
        <div className="flex flex-row ltr:ml-auto rtl:mr-auto">
          {isAdmin && !isAdminLabelBottom && (
            <div className="font-regular text-fs-caption text-moonlight pt-[.25rem] items-end">{translate('connect.admin')}</div>
          )}
          {customCTA}
        </div>
      )}
    </div>
  );
};

export default PlayerAvatar;
